import React from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, Stack, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import StepperStepButtons from 'common/StepperStepButtons/StepperStepButtons';
import AmountMaskInput from 'common/MaskInputs/AmountMaskInput';
import resourceRequestsBillingOptions from 'helpers/resourceRequestBillingOptions';
import {
  ResourceBudgetAndAllocationFormProps,
  FormPayload,
} from './interfaces';
import schema from './ResourceBudgetAndAllocationForm.schema';

const validate = zodResolver(schema);

export default function ResourceBudgetAndAllocationForm({
  onSubmit,
  activeStep,
  handleBackStep,
  initFormValues,
}: ResourceBudgetAndAllocationFormProps) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initFormValues,
  });

  const { t } = useTranslation();
  const handleOnSubmit = handleSubmit(onSubmit);
  const formValues = watch();

  return (
    <Stack justifyContent="space-between" height="100%" p={6}>
      <Stack spacing={6}>
        <Typography variant="h5">
          {t('human_resources.budget_and_allocation')}
        </Typography>
        <Divider />
        <form noValidate>
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}
          >
            <Stack spacing={3}>
              <Typography variant="h6">
                {t('human_resources.salary_range')}
              </Typography>
              <Stack direction="row" spacing={3}>
                <ControlTextField
                  control={control}
                  errors={errors}
                  label="label.salaryRangeMin"
                  name="salaryRangeMin"
                  InputProps={{
                    inputComponent: AmountMaskInput as any,
                  }}
                />
                <ControlTextField
                  control={control}
                  errors={errors}
                  label="label.salaryRangeMax"
                  name="salaryRangeMax"
                  InputProps={{
                    inputComponent: AmountMaskInput as any,
                  }}
                />
                <ControlSelect
                  control={control}
                  errors={errors}
                  label="label.billing"
                  name="billingType"
                  selectOptions={resourceRequestsBillingOptions}
                  optional
                />
              </Stack>
            </Stack>
          </FormControl>
        </form>
      </Stack>
      <StepperStepButtons
        handleBackStep={() => handleBackStep(formValues)}
        handleNextStep={handleOnSubmit}
        activeStep={activeStep}
      />
    </Stack>
  );
}
