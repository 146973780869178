import api from 'store/api';
import { ApiResponse } from 'store/interfaces';

export interface Currency {
  ['@id']: string;
  id: string;
  name: string;
}

export interface CurrenciesResponseDto extends ApiResponse {
  'hydra:member': Currency[];
}

export interface ConversionRate {
  rate: number;
  date: string;
}

export interface ConversionRateQueries {
  sourceCurrency: string;
  targetCurrency: string;
  date: string;
}

export const currenciesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query<Currency[], void>({
      query: () => '/currencies',
      providesTags: ['Currencies'],
      transformResponse: (response: CurrenciesResponseDto) =>
        response['hydra:member'],
    }),
    getCurrency: builder.query<Currency, string>({
      query: (id) => `/currencies/${id}`,
      providesTags: ['Currencies'],
    }),
    addCurrency: builder.mutation({
      query: (body) => ({
        url: '/currencies',
        method: 'POST',
        headers: { 'content-type': 'application/ld+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Currencies'],
    }),
    getCurrencyRate: builder.query<ConversionRate, ConversionRateQueries>({
      query: ({ sourceCurrency, targetCurrency, date }) =>
        `/currencies/${sourceCurrency}/rates/${targetCurrency}/${date}`,
    }),
  }),
});

export const {
  useGetCurrenciesQuery,
  useAddCurrencyMutation,
  useGetCurrencyRateQuery,
} = currenciesApi;
