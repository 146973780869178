import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Box } from '@mui/material';
import useSubscriptionContext from 'pages/Subscription/context/useSubscriptionContext';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import theme from 'config/material-ui/theme';
import { featureWidth } from '../consts';
import features, { Feature } from '../features';

interface FeatureTileProps {
  feature: Feature;
}

export default function FeatureTile({ feature }: FeatureTileProps) {
  const { t } = useTranslation();
  const { selectedFeatures, toggleFeature } = useSubscriptionContext();
  const formatAmount = useCurrencyFormat();

  const isSelected = selectedFeatures.includes(feature.name);

  const notSelectedDependingFeatures = feature.requiredFeatures?.filter(
    (requiredFeature) => !selectedFeatures.includes(requiredFeature),
  );

  const dependingFeatures = [...features].filter((featureItem) =>
    featureItem.requiredFeatures?.includes(feature.name),
  );
  const selectedDependingFeatures = dependingFeatures.filter((featureItem) =>
    selectedFeatures.includes(featureItem.name),
  );

  const handleOnClick = () => {
    toggleFeature(feature.name);

    if (!isSelected && notSelectedDependingFeatures?.length) {
      notSelectedDependingFeatures.forEach((featureItem) => {
        toggleFeature(featureItem);
      });
    }
    if (isSelected) {
      selectedDependingFeatures.forEach((featureItem) => {
        toggleFeature(featureItem.name);
      });
    }
  };

  return (
    <Stack
      spacing={3}
      p={3}
      sx={{
        ...feature.sx,
        cursor: 'pointer',
        border: '0.2rem solid',
        borderColor: isSelected ? 'primary.main' : 'rating.empty',
        borderRadius: '1.2rem',
        backgroundColor: 'main.white',
        position: 'absolute',
        width: featureWidth,
        minHeight: '17.1rem',
        userSelect: 'none',
      }}
      onClick={handleOnClick}
      id={feature.name}
    >
      <Box
        sx={{
          '& svg': {
            fontSize: '2.4rem',
            '& path': {
              fill: isSelected ? theme.palette.primary.main : theme.palette.icon.dark,
            },
          },
        }}
      >
        {feature.icon}
      </Box>
      <Typography variant="subtitle1">{t(feature.label)}</Typography>
      <Stack spacing={0.5}>
        {feature.price && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1" color="text.secondary">
              {formatAmount(feature.price)}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              / {t('label.monthly')}
            </Typography>
          </Stack>
        )}
        {feature.pricePerUser && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1" color="text.secondary">
              {feature.price && '+ '}
              {formatAmount(feature.pricePerUser)}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              / {t('label.user')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
