import { TagDefinition } from 'store/tagDefinitions/interfaces';

export default function getTagDefinitionsOptions(
  tagDefinitions: TagDefinition[],
  tagDefinitionNamesToSkip: string[],
) {
  const tagDefinitionsOptions: TagDefinition[] = [];

  tagDefinitions.forEach((tagDefinition) => {
    if (tagDefinitionNamesToSkip.includes(tagDefinition.name)) {
      return;
    }

    tagDefinitionsOptions.push(tagDefinition);
  });

  return tagDefinitionsOptions;
}
