import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Pagination, Grid, Stack, Typography, MenuItem } from '@mui/material';
import getPagesCount from 'helpers/getPagesCount';
import StyledSelect from './Select.styled';
import { CustomPaginationProps } from './interfaces';

export default function CustomPagination({
  apiData,
  page,
  itemsPerPage,
  handleOnPageChange,
  handleOnItemsPerPageChange,
  showRowsSelect = true,
}: CustomPaginationProps) {
  const { t } = useTranslation();

  const itemsPerPageOptions = [10, 20, 30, 40, 50];
  const resultsFrom = page * itemsPerPage - itemsPerPage + 1;
  const resultsTo = page * itemsPerPage;

  return (
    <Grid container alignItems="center" pt={3}>
      <Grid item xs={4}>
        <Stack direction="row">
          <Trans
            i18nKey="label.pagination_of"
            values={{
              from: resultsFrom,
              to: resultsTo,
              total: apiData?.['hydra:totalItems'],
            }}
            components={{
              bold: (
                <Typography
                  color="text.primary"
                  variant="body1"
                  marginRight={0.25}
                />
              ),
              span: (
                <Typography
                  color="text.secondary"
                  variant="body1"
                  marginRight={0.25}
                />
              ),
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Pagination
          count={getPagesCount(apiData['hydra:view']['hydra:last'])}
          page={page}
          onChange={handleOnPageChange}
          color="primary"
        />
      </Grid>
      {showRowsSelect && (
        <Grid item xs={4}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Typography variant="body1">{t('label.rows_per_page')}</Typography>
            <StyledSelect
              value={itemsPerPage}
              onChange={handleOnItemsPerPageChange}
            >
              {itemsPerPageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
