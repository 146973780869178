import React from 'react';
import { createSvgIcon } from '@mui/material';

const UserPlus = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99971 3.33341C8.38887 3.33341 7.08304 4.63925 7.08304 6.25008C7.08304 7.86091 8.38887 9.16675 9.99971 9.16675C11.6105 9.16675 12.9164 7.86091 12.9164 6.25008C12.9164 4.63925 11.6105 3.33341 9.99971 3.33341ZM5.41637 6.25008C5.41637 3.71878 7.4684 1.66675 9.99971 1.66675C12.531 1.66675 14.583 3.71878 14.583 6.25008C14.583 8.78139 12.531 10.8334 9.99971 10.8334C7.4684 10.8334 5.41637 8.78139 5.41637 6.25008ZM14.9997 11.6667C15.4599 11.6667 15.833 12.0398 15.833 12.5001V14.1667H17.4997C17.9599 14.1667 18.333 14.5398 18.333 15.0001C18.333 15.4603 17.9599 15.8334 17.4997 15.8334H15.833V17.5001C15.833 17.9603 15.4599 18.3334 14.9997 18.3334C14.5395 18.3334 14.1664 17.9603 14.1664 17.5001V15.8334H12.4997C12.0395 15.8334 11.6664 15.4603 11.6664 15.0001C11.6664 14.5398 12.0395 14.1667 12.4997 14.1667H14.1664V12.5001C14.1664 12.0398 14.5395 11.6667 14.9997 11.6667ZM8.23657 12.5001C8.26825 12.5001 8.3004 12.5001 8.33304 12.5001H9.16637C9.62661 12.5001 9.99971 12.8732 9.99971 13.3334C9.99971 13.7937 9.62661 14.1667 9.16637 14.1667H8.33304C7.51867 14.1667 7.23318 14.1708 7.01198 14.2148C6.02025 14.4121 5.24501 15.1873 5.04774 16.179C5.00374 16.4002 4.99971 16.6857 4.99971 17.5001C4.99971 17.9603 4.62661 18.3334 4.16637 18.3334C3.70613 18.3334 3.33304 17.9603 3.33304 17.5001C3.33304 17.4674 3.33303 17.4353 3.33302 17.4036C3.33285 16.7223 3.33274 16.2579 3.4131 15.8539C3.74188 14.201 5.03395 12.9089 6.68683 12.5801C7.09083 12.4998 7.55524 12.4999 8.23657 12.5001Z"
      fill="currentColor"
    />
  </svg>,
  'UserPlus',
);

export default UserPlus;
