import React, { useState } from 'react';
import { Grid, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import OverviewForm from 'components/EditCostForm/OverviewForm';
import { useParams } from 'react-router-dom';
import { Cost, useGetCostQuery } from 'store/costs/costs';
import Loader from 'common/Loader';
import Error from 'common/Error';
import Documents from 'components/EditCostForm/Documents';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import CostTransactions from 'components/EditCostForm/CostTransactions/CostTransactions';
import getBgColorForTab from 'helpers/getBgColorForTab';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import { useGetCostGroupsQuery } from 'store/costGroups/costGroups';
import useBudgetPreview from 'hooks/useBudgetPreview';
import { useGetBudgetContractorsQuery } from 'store/budget/budget';

export default function EditCostForm() {
  const { id } = useParams<{ id: any }>();
  const [tabValue, setTabValue] = useState('1');
  const listingTabs = ['2', '3'];
  const { t } = useTranslation();
  const isTransactionsManager = useCheckRoles(roles.transactionsManager);

  const {
    data: cost = {} as Cost,
    isLoading: costFetching,
    isError: costFetchError,
  } = useGetCostQuery(id ?? '', { skip: !id });
  const {
    data: employees = [],
    isLoading: employeesFetching,
    isError: employeesFetchError,
  } = useGetEmployeesPreview();
  const {
    data: fetchedCostGroups = [],
    isLoading: costGroupsFetching,
    isError: costGroupsFetchError,
  } = useGetCostGroupsQuery();

  const {
    budgetPreviews,
    isLoading: budgetsLoading,
    isError: budgetsError,
  } = useBudgetPreview();

  const {
    data: budgetContractors = {
      'hydra:member': [],
    },
    isLoading: budgetContractorsLoading,
    isError: budgetContractorsError,
  } = useGetBudgetContractorsQuery();

  if (
    costFetching ||
    employeesFetching ||
    costGroupsFetching ||
    budgetsLoading ||
    budgetContractorsLoading
  ) {
    return <Loader />;
  }

  if (
    costFetchError ||
    employeesFetchError ||
    costGroupsFetchError ||
    budgetsError ||
    budgetContractorsError
  ) {
    return <Error />;
  }

  return (
    <Grid item xs={10} bgcolor={getBgColorForTab(tabValue, listingTabs)}>
      <NavBarContainer>
        <NavBarTitle>
          {t('costs.edit_cost')} {cost.name}
        </NavBarTitle>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={(_, newValue: string) => {
            setTabValue(newValue);
          }}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('tab.overview')} value="1" />
          <Tab label={t('tab.documents')} value="2" />
          {isTransactionsManager && (
            <Tab label={t('menu.bank_accounts')} value="3" />
          )}
        </StyledTabList>
        <TabPanel value="1">
          <OverviewForm
            budgetContractors={budgetContractors['hydra:member']}
            costGroups={fetchedCostGroups}
            budgetPreviews={budgetPreviews}
            employees={employees}
            cost={cost}
          />
        </TabPanel>
        <TabPanel value="2">
          <Documents />
        </TabPanel>
        <TabPanel value="3">
          <CostTransactions />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}
