import React, { useState, useEffect } from 'react';
import { Grid, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { useGetOrganizationMailFooterQuery } from 'store/organizations/organizations';
import { OrganizationMailFooterData } from 'store/organizations/interfaces';
import StyledPreviewWrapper from './PreviewWrapper.styled';

export default function MailFooter() {
  const { t } = useTranslation();
  const [mailFooter, setMailFooter] = useState('');

  const {
    data: mailFooterData = {} as OrganizationMailFooterData,
    isLoading: mailFooterLoading,
    isError: mailFooterError,
  } = useGetOrganizationMailFooterQuery();

  useEffect(() => {
    if (mailFooterData.mailFooter) {
      setMailFooter(mailFooterData.mailFooter);
    }
  }, [mailFooterData]);

  if (mailFooterLoading) {
    return <Loader />;
  }

  if (mailFooterError) {
    return <Error />;
  }

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('settings.mail_footer')}</NavBarTitle>
      </NavBarContainer>
      <Stack mt={3} spacing={2} p={3} direction="row" alignItems="flex-start">
        <TextField
          value={mailFooter}
          onChange={(e) => setMailFooter(e.target.value)}
          label={t('settings.mail_footer')}
          multiline
          fullWidth
          sx={{
            textarea: {
              fontSize: '1rem',
            },
          }}
        />
        <StyledPreviewWrapper>
          {parse(mailFooter, {
            replace: (node) => {
              if (node.type === 'tag') {
                // eslint-disable-next-line no-param-reassign
                node.attribs.class = 'initial-css-styles-for-mail-footer';
              }
              return node;
            },
          })}
        </StyledPreviewWrapper>
      </Stack>
    </Grid>
  );
}
