import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import CurrenciesList from 'components/CurrenciesList/CurrenciesList';
import AddCurrencyForm from 'components/AddCurrencyForm';

function Currencies() {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <NavBarTitle>{t('currencies.header')}</NavBarTitle>
        <Button
          variant="contained"
          onClick={() => setIsAddFormOpen(!isAddFormOpen)}
          endIcon={<AddCircleOutlineIcon />}
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <CurrenciesList />
      <Dialog
        open={isAddFormOpen}
        onClose={() => setIsAddFormOpen(!isAddFormOpen)}
        fullWidth
      >
        <AddCurrencyForm setIsOpen={setIsAddFormOpen} />
      </Dialog>
    </Grid>
  );
}

export default Currencies;
