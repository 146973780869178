import { HolidayTypes } from 'enums';
import { z } from 'zod';

const schema = z.object({
  type: z.nativeEnum(HolidayTypes, {
    errorMap: () => ({ message: 'errors.field_required' }),
  }),
  description: z.string().min(1, { message: 'errors.field_required' }),
  dateFrom: z.date(),
  dateTo: z.date(),
  hours: z.string(),
});

export default schema;
