import React from 'react';
import { Stack, Typography, IconButton } from '@mui/material';
import MockTechSkillIcon from 'assets/icons/MockTechSkillIcon';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import RatingStarIcon from 'assets/icons/RatingStarIcon';
import BinIcon from 'assets/icons/BinIcon';
import { Tag } from 'store/tags/interfaces';

interface TagTileProps {
  tag: Tag;
  handleOnDelete?: (tagId: string) => void;
}

export default function TagTile({ tag, handleOnDelete }: TagTileProps) {
  return (
    <Stack flexBasis="calc(33% - 1.4rem)">
      <StyledContentBox px={3} py={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <MockTechSkillIcon sx={{ fontSize: '2.4rem' }} />
            <Stack>
              <Typography variant="body1">{tag.tagDefinition.name}</Typography>
              <Typography variant="caption" color="text.secondary">
                {tag.tagDefinition.label}
              </Typography>
            </Stack>
          </Stack>
          {handleOnDelete && (
            <IconButton onClick={async () => handleOnDelete(tag.id)}>
              <BinIcon
                sx={{
                  color: 'icon.dark',
                  fontSize: '2rem',
                }}
              />
            </IconButton>
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          mt={3}
          sx={{ overflowX: 'hidden' }}
        >
          {Array.from({ length: tag.tagDefinition.level }, (_, index) => (
            <RatingStarIcon
              key={index}
              sx={{
                color: 'rating.filled',
                fontSize: '2rem',
              }}
            />
          ))}
        </Stack>
      </StyledContentBox>
    </Stack>
  );
}
