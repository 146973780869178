import React from 'react';
import roles from 'config/roles/roles';
import { MeetingStatuses } from 'enums';
import useCheckRoles from 'hooks/useCheckRoles';
import { useGetMeetingsQuery } from 'store/meetings/meetings';
import { Meeting } from 'store/meetings/interfaces';
import { Box } from '@mui/material';
import Link from 'common/Link/Link';
import VideoChatIcon from '@mui/icons-material/VideoChat';

const emptyMeetings: Meeting[] = [];

interface EmployeeMeetingsProps {
  employeeIri: string;
}

export default function EmployeeMeetings({
  employeeIri,
}: EmployeeMeetingsProps) {
  const isMeetingManager = useCheckRoles(roles.meetingManager);
  const { meetings } = useGetMeetingsQuery(
    { status: [MeetingStatuses.requested, MeetingStatuses.planned] },
    {
      skip: !isMeetingManager,
      selectFromResult: ({ data }) => ({
        meetings:
          data?.filter((item) => item.employee === employeeIri) ?? emptyMeetings,
      }),
    },
  );

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {meetings.map((meeting) => (
        <Link key={meeting.id} to={`/meeting/${meeting.id}`}>
          <VideoChatIcon color="primary" fontSize="large" />
        </Link>
      ))}
    </Box>
  );
}
