import React from 'react';
import {
  Scheduler,
  Appointments,
  MonthView,
  DateNavigator,
  Toolbar,
  TodayButton,
  DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  AppointmentModel,
  EditingState,
  IntegratedEditing,
  ViewState,
} from '@devexpress/dx-react-scheduler';
import { Stack, Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppointmentContainerComponent from 'components/BookProperties/BookingForm/MinutesBookingScheduler/Appointments/AppointmentContainerComponent/AppointmentContainerComponent';
import DragDropContainerComponent from 'components/BookProperties/BookingForm/MinutesBookingScheduler/DragDropProvider/ContainerComponent/ContainerComponent';
import useBookingSchedulerContext from 'components/BookProperties/BookingForm//context/useBookingScheduler.context';
import SchedulerScrollWrapper from 'components/BookProperties/BookingForm/SchedulerScrollWrapper/SchedulerScrollWrapper';
import useOrganizationStartWeekDay from 'hooks/useOrganizationStartWeekDay';
import AppointmentContentComponent from './Appointments/AppointmentContentComponent';
import TimeTableCellComponent from './MonthView/TimeTableCellComponent/TimeTableCellComponent';
import TimeTableLayoutComponent from './MonthView/TimeTableLayoutComponent/TimeTableLayoutComponent';
import DraftAppointmentComponent from './DragDropProvider/DraftAppointmentComponent/DraftAppointmentComponent';
import SourceAppointmentComponent from './DragDropProvider/SourceAppointmentComponent/SourceAppointmentComponent';
import { createBookingId } from '../consts';

interface DaysBookingSchedulerProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const allowEdit = (data: AppointmentModel) =>
  !!data.id && createBookingId === data.id;

export default function DaysBookingScheduler({
  setIsOpen,
}: DaysBookingSchedulerProps) {
  const { t } = useTranslation();
  const currentDate = new Date();

  const { bookingsInState, onCommitChanges } = useBookingSchedulerContext();
  const startWeekDay = useOrganizationStartWeekDay();

  return (
    <SchedulerScrollWrapper>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'border.main',
        }}
      >
        <Scheduler data={bookingsInState} firstDayOfWeek={startWeekDay}>
          <EditingState onCommitChanges={onCommitChanges} />
          <IntegratedEditing />
          <ViewState defaultCurrentDate={currentDate} />
          <MonthView
            timeTableCellComponent={TimeTableCellComponent}
            timeTableLayoutComponent={TimeTableLayoutComponent}
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments
            appointmentContentComponent={
              AppointmentContentComponent as React.ComponentType<Appointments.AppointmentContentProps>
            }
            containerComponent={
              AppointmentContainerComponent as React.ComponentType<Appointments.ContainerProps>
            }
          />
          <DragDropProvider
            allowDrag={allowEdit}
            allowResize={allowEdit}
            containerComponent={DragDropContainerComponent}
            draftAppointmentComponent={
              DraftAppointmentComponent as React.ComponentType<DragDropProvider.DraftAppointmentProps>
            }
            sourceAppointmentComponent={
              SourceAppointmentComponent as React.ComponentType<DragDropProvider.SourceAppointmentProps>
            }
          />
        </Scheduler>
      </Box>
      <Stack direction="row" justifyContent="flex-end" p={3}>
        <Button onClick={() => setIsOpen(false)}>
          <Typography variant="buttonMedium" color="text.secondary">
            {t('label.back')}
          </Typography>
        </Button>
      </Stack>
    </SchedulerScrollWrapper>
  );
}
