import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import BuildingIcon from 'assets/icons/BuildingIcon';
import EditButton from 'common/EditButton/EditButton';
import StyledMenuItem from './MenuItem.styled';
import { InvoiceClientProps } from './interfaces';
import getClientSetters from './getClientSetters';

const maxClientNameLength = 30;

function InvoiceClient({
  setDueDate,
  clientIri,
  setClientIri,
  setCurrencyIri,
  clients,
  setInternalComments,
  isEditForm,
}: InvoiceClientProps) {
  const { t } = useTranslation();
  const invoiceClient = clients.find((item) => item['@id'] === clientIri);

  const {
    setSelectedClientCurrency,
    setSelectedClientDueDays,
    setSelectedInvoiceComment,
  } = getClientSetters({
    setCurrencyIri,
    setDueDate,
    setInternalComments,
    clients,
  });

  const getShortedClientName = (clientName: string) =>
    clientName.length > maxClientNameLength
      ? `${clientName.substring(0, maxClientNameLength)}...`
      : clientName;

  const handleOnClientChange = (event: SelectChangeEvent<string>) => {
    const newSelectedClientIri = event.target.value;

    setClientIri(newSelectedClientIri);
    setSelectedClientCurrency(newSelectedClientIri);
    setSelectedClientDueDays(newSelectedClientIri);
    setSelectedInvoiceComment(newSelectedClientIri);
  };

  const renderInputValue = (value: string) => {
    const selectedClient = clients.find((item) => item['@id'] === value);
    return selectedClient ? getShortedClientName(selectedClient.name) : '';
  };

  return (
    <Stack width="33.3%">
      <Stack spacing={3}>
        <Typography variant="subtitle1">{t('label.client')}</Typography>
        <FormControl required fullWidth>
          <InputLabel id="client-select">{t('invoices.client')}</InputLabel>
          <Select
            id="client-select"
            label={t('invoices.client')}
            value={clientIri}
            renderValue={renderInputValue}
            onChange={handleOnClientChange}
            data-testid="invoice-form-client-select"
            fullWidth
            disabled={isEditForm}
          >
            {clients.map((item) => (
              <StyledMenuItem key={item.id} value={item['@id']}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <BuildingIcon
                    sx={{ fontSize: '2rem', color: 'icon.lightDark' }}
                  />
                  <Stack>
                    <Typography fontWeight={700}>
                      {getShortedClientName(item.name)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.addressStreetLine}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.addressPostCode} {item.addressCity}
                    </Typography>
                  </Stack>
                </Stack>
                <EditButton v2 navigateTo={`/clients/${item.id}`} />
              </StyledMenuItem>
            ))}
          </Select>
        </FormControl>
        {invoiceClient && (
          <>
            <Stack>
              <Typography color="primary.main">{invoiceClient.name}</Typography>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {invoiceClient.addressStreetLine}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {invoiceClient.addressPostCode} {invoiceClient.addressCity}
              </Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary">
              {t('costs.tin_label')}:{' '}
              <Typography
                variant="body2"
                component="span"
                fontWeight={700}
                color="text.primary"
              >
                {invoiceClient.vatNumber}
              </Typography>
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default InvoiceClient;
