import React from 'react';
import { Box, TextField } from '@mui/material';
import { useValidate } from 'hooks/useValidate';
import { useTranslation } from 'react-i18next';
import AttachmentSelect from 'components/AddAttachmentForm/AttachmentSelect/AttachmentSelect';
import { useGetCostsQuery } from 'store/costs/costs';

export interface BaseDataProps {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  cost: string;
  setCost: React.Dispatch<React.SetStateAction<string>>;
}

interface Errors {
  name: boolean;
}

function BaseData({ name, setName, cost, setCost }: BaseDataProps) {
  const { t } = useTranslation();
  const { errors, validate } = useValidate<Errors>();
  const { data: costs = [] } = useGetCostsQuery({ properties: ['id', 'name'] });

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <TextField
        data-testid="name"
        error={errors.name}
        fullWidth
        helperText={errors.name && t('errors.field_required')}
        label={t('label.name')}
        name="transactionName"
        onBlur={(event) => validate('name', event.target.value.length >= 2)}
        onChange={(event) => setName(event.target.value)}
        required
        value={name}
      />
      <AttachmentSelect
        label="inputs.cost"
        selectId="cost"
        value={cost}
        onChangeFn={(event) => setCost(event.target.value)}
        selectOptions={costs.map((costData) => ({
          id: costData['@id'],
          description: costData.name,
        }))}
        width="80%"
      />
    </Box>
  );
}

export default BaseData;
