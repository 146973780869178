import { useGetCurrenciesQuery } from 'store/currencies/currencies';

export default function useGetCurrenciesOptions() {
  const { data: currencies, isLoading, isError } = useGetCurrenciesQuery();

  const currencyIdOptions =
    currencies
      ?.map((item) => ({
        value: item['@id'],
        label: item.id,
      }))
      ?.sort((currentCurrency, nextCurrency) =>
        currentCurrency.label.localeCompare(nextCurrency.label),
      ) ?? [];

  const currencyNameOptions =
    currencies
      ?.map((item) => ({
        value: item['@id'],
        label: item.name,
      }))
      ?.sort((currentCurrency, nextCurrency) =>
        currentCurrency.label.localeCompare(nextCurrency.label),
      ) ?? [];

  return { currencyIdOptions, currencyNameOptions, isLoading, isError };
}
