import { ResourceRequestBillingType } from 'store/resourceRequests/interfaces';
import { z } from 'zod';

const schema = z.object({
  salaryRangeMin: z.string(),
  salaryRangeMax: z.string(),
  billingType: z.nativeEnum(ResourceRequestBillingType).or(z.literal('')),
});

export default schema;
