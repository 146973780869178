import React from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { RangeConditions } from 'store/condition/interfaces';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { getChipColor } from 'components/ProjectsCondition/utils';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';
import createApiIri from 'helpers/createApiIri';
import StyledBadge from '../Badge.styled';

interface EmployeeProps {
  employeeId: number;
  dateRange: string[];
  rangeConditions: RangeConditions;
  projectId: number;
}

export default function Employee({
  employeeId,
  dateRange,
  projectId,
  rangeConditions,
}: EmployeeProps) {
  const { selectedEmployee } = useGetSelectedEmployeePreview({
    employeeIri: createApiIri('employees', employeeId),
    filters: { isActive: true },
  });
  const formatAmount = useCurrencyFormat();

  const calculateBalance = (range: string) => {
    if (
      !rangeConditions[range] ||
      !rangeConditions[range][projectId] ||
      !rangeConditions[range][projectId][employeeId]
    ) {
      return 0;
    }
    const employeeValues = rangeConditions[range][projectId][employeeId];

    return employeeValues.income - employeeValues.cost;
  };

  const calculateBalancePercent = (range: string) => {
    if (
      !rangeConditions[range] ||
      !rangeConditions[range][projectId] ||
      !rangeConditions[range][projectId][employeeId]
    ) {
      return 0;
    }
    const employeeValues = rangeConditions[range][projectId][employeeId];
    const { cost, income } = employeeValues;

    if (cost === 0) {
      return 0;
    }

    if (income === 0) {
      return -100;
    }

    if (cost > income) {
      return ((cost * -100) / income).toFixed(0);
    }

    return ((income * 100) / cost).toFixed(0);
  };

  return (
    <Grid container columns={13} alignItems="center">
      <Grid item xs={1}>
        <Typography variant="smallBody" pl={1}>
          {`${selectedEmployee?.lastname} ${selectedEmployee?.firstname}`}
        </Typography>
      </Grid>
      {dateRange.map((range) => (
        <React.Fragment key={range}>
          <Grid item xs={0.9}>
            <Typography variant="smallBody">
              {formatAmount(
                rangeConditions[range]?.[projectId]?.[employeeId]?.cost,
              )}
            </Typography>
          </Grid>
          <Grid item xs={0.9}>
            <Typography variant="smallBody">
              {formatAmount(
                rangeConditions[range]?.[projectId]?.[employeeId]?.income,
              )}
            </Typography>
          </Grid>
          <Grid item xs={1.2}>
            <StyledBadge
              badgeContent={
                <Typography variant="smallBody">{`${calculateBalancePercent(
                  range,
                )}%`}</Typography>
              }
              color={getChipColor(range, calculateBalance(range))}
            >
              <Chip
                color={getChipColor(range, calculateBalance(range))}
                label={
                  <Typography variant="chipLabel">
                    {formatAmount(calculateBalance(range))}
                  </Typography>
                }
                size="small"
              />
            </StyledBadge>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
