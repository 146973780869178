import { Stack, StackProps, styled } from '@mui/material';

const StyledAppointmentWrapper = styled(Stack)<StackProps>(() => ({
  padding: '0 1.6rem',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
}));

export default StyledAppointmentWrapper;
