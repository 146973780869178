import { useContext } from 'react';
import {
  SubscriptionContext,
  SubscriptionContextProps,
} from './Subscription.context';

function useSubscriptionContext(): SubscriptionContextProps {
  const context = useContext(SubscriptionContext);

  if (!context) {
    throw new Error(
      'useSubscriptionContext must be used within a SubscriptionProvider',
    );
  }

  return context;
}

export default useSubscriptionContext;
