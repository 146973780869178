import React from 'react';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import roles from 'config/roles/roles';
import features from 'config/features/features';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FeedbackIcon from '@mui/icons-material/Feedback';
import BookIcon from '@mui/icons-material/Book';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import TimerIcon from '@mui/icons-material/Timer';
import UserSearchIcon from 'assets/icons/UserSearchIcon';

export interface Link {
  to: string;
  name: string;
  icon: JSX.Element;
  displayRoles: string[];
  displayFeature?: string;
}

export const linkIcons = {
  dashboard: <ExploreOutlinedIcon />,
  employees: <AccountCircleOutlinedIcon />,
  hr: <UserSearchIcon />,
  agreements: <FilePresentOutlinedIcon />,
  benefits: <LoyaltyIcon />,
  holidays: <BeachAccessOutlinedIcon />,
  holidaysManagement: <HolidayVillageOutlinedIcon />,
  workingHours: <TimerIcon />,
  projects: <DesignServicesOutlinedIcon />,
  clients: <FilePresentOutlinedIcon />,
  transactions: <AccountBalanceOutlinedIcon />,
  invoices: <FilePresentOutlinedIcon />,
  contractors: <PaymentOutlinedIcon />,
  paymentDue: <AccountBalanceWalletOutlinedIcon />,
  budget: <TrendingUpIcon />,
  transactionAttachments: <AttachFileOutlinedIcon />,
  responsibilities: <AssignmentIndIcon />,
  feedback: <FeedbackIcon />,
  propertyBooking: <BookIcon />,
  accountancy: <CardTravelIcon />,
};

const menuLinks: Link[] = [
  {
    to: '/',
    name: 'menu.dashboard',
    icon: linkIcons.dashboard,
    displayRoles: roles.user,
  },
  {
    to: '/employees',
    name: 'menu.employees',
    icon: linkIcons.employees,
    displayRoles: [...roles.employees, ...roles.employeesViewer],
    displayFeature: features.employees,
  },
  {
    to: '/human-resources',
    name: 'menu.human_resources',
    icon: linkIcons.hr,
    displayRoles: roles.humanResources,
  },
  {
    to: '/agreements',
    name: 'menu.agreements',
    icon: linkIcons.agreements,
    displayRoles: roles.agreements,
    displayFeature: features.agreements,
  },
  {
    to: '/benefits',
    name: 'menu.benefits',
    icon: linkIcons.benefits,
    displayRoles: [...roles.benefitsManager, ...roles.benefitsViewer],
    displayFeature: features.benefits,
  },
  {
    to: '/holidays/me',
    name: 'menu.holidays_employee',
    icon: linkIcons.holidays,
    displayRoles: roles.user,
    displayFeature: features.holidays,
  },
  {
    to: '/holidays-management',
    name: 'menu.holidays_management',
    icon: linkIcons.holidaysManagement,
    displayRoles: roles.holidays,
    displayFeature: features.holidays,
  },
  {
    to: '/project-tracking',
    name: 'menu.working_hours',
    icon: linkIcons.workingHours,
    displayRoles: roles.user,
    displayFeature: features.projects,
  },
  {
    to: '/projects',
    name: 'menu.projects',
    icon: linkIcons.projects,
    displayRoles: roles.projects,
    displayFeature: features.projects,
  },
  {
    to: '/clients',
    name: 'menu.clients',
    icon: linkIcons.clients,
    displayRoles: roles.clients,
    displayFeature: features.clients,
  },
  {
    to: '/transactions',
    name: 'menu.bank_accounts',
    icon: linkIcons.transactions,
    displayRoles: [
      ...roles.banksManager,
      ...roles.transactionsManager,
      ...roles.accountancy,
    ],
    displayFeature: features.transactions,
  },
  {
    to: '/invoices',
    name: 'menu.invoices',
    icon: linkIcons.invoices,
    displayRoles: roles.invoices,
  },
  {
    to: '/contractors',
    name: 'menu.costs',
    icon: linkIcons.contractors,
    displayRoles: roles.contractors,
  },
  {
    to: '/payment-due',
    name: 'menu.payment_due',
    icon: linkIcons.paymentDue,
    displayRoles: roles.paymentDue,
  },
  {
    to: '/budget',
    name: 'menu.budget',
    icon: linkIcons.budget,
    displayRoles: roles.budgetManager,
  },
  {
    to: '/transaction-attachments',
    name: 'menu.transaction_attachments',
    icon: linkIcons.transactionAttachments,
    displayRoles: [...roles.transactionsManager, ...roles.accountancy],
    displayFeature: features.transactions,
  },
  {
    to: '/responsibilities',
    name: 'menu.responsibilites',
    icon: linkIcons.responsibilities,
    displayRoles: roles.responsibilitiesManager,
    displayFeature: features.responsibilities,
  },
  {
    to: '/feedback',
    name: 'menu.feedback',
    icon: linkIcons.feedback,
    displayRoles: roles.user,
    displayFeature: features.feedback,
  },
  {
    to: '/property-booking',
    name: 'menu.property_booking',
    icon: linkIcons.propertyBooking,
    displayRoles: roles.properties,
    displayFeature: features.bookings,
  },
  {
    to: '/accountancy',
    name: 'menu.accountancy',
    icon: linkIcons.accountancy,
    displayRoles: roles.accountancy,
  },
];

export default menuLinks;
