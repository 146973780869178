import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Button,
  Stack,
  Dialog,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import UserPlus from 'assets/icons/UserPlus';
import { useAppDispatch } from 'store/hooks';
import { setSuccessStatus, setErrorCatch } from 'store/status/actions';
import { useAddProjectMemberMutation } from 'store/projectMembers/projectMembers';
import { FormTypes } from 'enums';
import useProjectMemberEmployees from 'hooks/useProjectMemberEmployee/useProjectMemberEmployees';
import ProjectMemberPosition from './interfaces';
import tableHeaders from './tableHeaders';
import ProjectEmployeeRow from './ProjectEmployeeRow/ProjectEmployeeRow';
import ProjectEmployeeForm from './ProjectEmployeeForm/ProjectEmployeeForm';
import { FormPayload } from './ProjectEmployeeForm/interfaces';
import useEditProjectFormContext from '../context/useEditProjectFormContext';

export default function ProjectEmployees() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { project } = useEditProjectFormContext();

  const {
    employeesLoading,
    employeesError,
    projectMemberEmployees,
    isProjectMemberEmployee,
    employees,
  } = useProjectMemberEmployees(project);

  const activeEmployees = employees.filter((employee) => employee.isActive);

  const [addProjectMember, { isLoading: addProjectMemberLoading }] =
    useAddProjectMemberMutation();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const projectMemberPositionsCount = Object.keys(ProjectMemberPosition).length;

  const employeesThatAreNotAssignedToWholeRoles = activeEmployees.filter(
    (employee) =>
      projectMemberEmployees.filter(
        (projectEmployee) =>
          projectEmployee.employee?.['@id'] === employee['@id'],
      ).length < projectMemberPositionsCount,
  );

  const handleAddProjectMember = async ({ employee, role }: FormPayload) => {
    try {
      await addProjectMember({
        project: project['@id'],
        position: role,
        employee,
      });
      setIsAddModalOpen(false);
      dispatch(setSuccessStatus(true));
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Stack alignItems="flex-end" pb={3}>
          <Button
            variant="contained"
            endIcon={<UserPlus />}
            onClick={() => setIsAddModalOpen(true)}
          >
            <Typography variant="buttonMedium">
              {t('transactions.transaction_employee')}
            </Typography>
          </Button>
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell
                    key={header.label}
                    align={header.align}
                    width={header.width}
                  >
                    <Typography variant="tableHeader" color="text.secondary">
                      {t(header.label)}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell colSpan={tableHeaders.length} />
              </TableRow>
            </TableHead>
            <TableBodyContent
              isError={employeesError}
              isFetching={employeesLoading}
              tableHeaders={tableHeaders}
            >
              {projectMemberEmployees.map(
                (projectMemberEmployee) =>
                  isProjectMemberEmployee(projectMemberEmployee) && (
                    <ProjectEmployeeRow
                      key={projectMemberEmployee.id}
                      projectMemberEmployee={projectMemberEmployee}
                    />
                  ),
              )}
            </TableBodyContent>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        fullWidth
      >
        <ProjectEmployeeForm
          formType={FormTypes.add}
          setIsDialogOpen={setIsAddModalOpen}
          selectEmployees={employeesThatAreNotAssignedToWholeRoles}
          onSubmit={handleAddProjectMember}
          isSubmitting={addProjectMemberLoading}
        />
      </Dialog>
    </>
  );
}
