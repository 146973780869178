import React from 'react';
import { Typography, TypographyOwnProps } from '@mui/material';

interface WidgetContainerProps {
  children: React.ReactNode;
  noMargin?: boolean;
  size?: 'md' | 'lg';
}

const sizeMap = {
  md: 'body2',
  lg: 'h6',
};

export default function WidgetTitle({
  children,
  noMargin = false,
  size = 'md',
}: WidgetContainerProps) {
  const variant = sizeMap[size] as TypographyOwnProps['variant'];

  return (
    <Typography
      textTransform="uppercase"
      variant={variant}
      mb={noMargin ? 0 : 2}
    >
      {children}
    </Typography>
  );
}
