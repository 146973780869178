import { DateRange } from 'react-date-range';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import useOrganizationStartWeekDay from 'hooks/useOrganizationStartWeekDay';
import StyledDateRangePicker from 'config/material-ui/styled/DateRangePicker.styled';
import theme from 'config/material-ui/theme';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { FormPayload } from '../interfaces';

interface PeriodRangePickerProps {
  setValue: UseFormSetValue<FormPayload>;
  getValues: UseFormGetValues<FormPayload>;
}

export default function PeriodRangePicker({
  setValue,
  getValues,
}: PeriodRangePickerProps) {
  const startWeekDay = useOrganizationStartWeekDay();

  const ranges = [
    {
      startDate: getValues('dateFrom'),
      endDate: getValues('dateTo'),
      key: 'selection',
    },
  ];

  return (
    <StyledDateRangePicker>
      <DateRange
        onChange={(item) => {
          setValue('dateFrom', item.selection.startDate);
          setValue('dateTo', item.selection.endDate);
        }}
        weekStartsOn={startWeekDay}
        rangeColors={[theme.palette.primary.main]}
        showSelectionPreview={false}
        moveRangeOnFirstSelection
        showDateDisplay={false}
        ranges={ranges}
      />
    </StyledDateRangePicker>
  );
}
