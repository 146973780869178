import React from 'react';
import { Typography, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';
import { useUpdateProjectMutation } from 'store/projects/projects';
import NotesForm from 'common/NotesForm/NotesForm';
import { FormPayload } from 'common/NotesForm/interfaces';
import useEditProjectFormContext from 'components/EditProjectForm/context/useEditProjectFormContext';

export default function OverviewNotes() {
  const { project } = useEditProjectFormContext();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  const handleUpdateProjectNotes = async (data: FormPayload) => {
    try {
      await updateProject({
        id: project.id,
        notes: data.note,
      }).unwrap();
      dispatch(setSuccessStatus(true));
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <Grid item xs={4}>
      <StyledContentBox py={3}>
        <Typography px={3} variant="h6">
          {t('projects.notes')}
        </Typography>
        <Stack px={3}>
          <NotesForm
            initValues={{ note: project?.notes || '' }}
            onSubmit={handleUpdateProjectNotes}
            isSubmitting={isLoading}
          />
        </Stack>
      </StyledContentBox>
    </Grid>
  );
}
