import roles from 'config/roles/roles';

const getTabs = (showDocuments: boolean) => [
  {
    description: 'tab.overview',
    displayRoles: roles.employees,
    value: '1',
    visible: true,
  },
  {
    description: 'employees.permissions',
    displayRoles: roles.rolesManager,
    value: '2',
    visible: true,
  },
  {
    description: 'employees.edit_tab_annual_leave',
    displayRoles: roles.agreements,
    value: '3',
    visible: true,
  },
  {
    description: 'employees.tab_agreements',
    displayRoles: roles.agreements,
    value: '4',
    visible: true,
  },
  {
    description: 'tab.documents',
    displayRoles: roles.documentsViewer,
    value: '5',
    visible: showDocuments,
  },
  {
    description: 'employees.tab_rates',
    displayRoles: roles.invoices,
    value: '6',
    visible: true,
  },
  {
    description: 'menu.benefits',
    displayRoles: roles.benefitsManager,
    value: '7',
    visible: true,
  },
];

export default getTabs;
