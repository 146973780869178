import React from 'react';
import { Typography } from '@mui/material';

export interface NavBarSubtitleProps {
  children: React.ReactNode;
}

export default function NavBarSubtitle({ children }: NavBarSubtitleProps) {
  return (
    <Typography variant="body2" color="text.secondary">
      {children}
    </Typography>
  );
}
