import { Typography, Stack, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ColumnHeaderProps {
  count: number;
  column: string;
}

export default function ColumnHeader({ count, column }: ColumnHeaderProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="space-between" pb={3}>
      <Typography variant="h6">
        {t(`resource_request.${column}`)}
        <Tooltip
          title={<Typography variant="body2">{t(`resource_request_description.${column}`)}</Typography>}
          placement="top"
          sx={{
            width: '2rem',
            height: '2rem'
        }}
        >
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Typography>

      <Stack direction="row" alignItems="center" spacing={0.25}>
        <Typography variant="body1" fontWeight={700}>
          {count}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t('resource_request.applicants')}
        </Typography>

      </Stack>
    </Stack>
  );
}
