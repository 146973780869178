import _ from 'lodash';
import { StorageSubscription } from './interfaces';

const name = 'subscription';

export default function getStorageSubscription() {
  const storageSubscription: StorageSubscription | null = JSON.parse(
    localStorage.getItem(name) || '{}',
  );

  return storageSubscription;
}

export function updateStorageSubscription<K extends keyof StorageSubscription>(
  field: K,
  value: StorageSubscription[K],
) {
  const storageSubscription = getStorageSubscription();
  const storageSubscriptionKeyValue = storageSubscription?.[field];

  const updatedStorageSubscription = {
    ...storageSubscription,
    [field]: value,
  };

  if (storageSubscriptionKeyValue === undefined) {
    localStorage.setItem(name, JSON.stringify(updatedStorageSubscription));
  }

  const isChanged = !_.isEqual(storageSubscriptionKeyValue, value);

  if (!isChanged) {
    return;
  }

  localStorage.setItem(name, JSON.stringify(updatedStorageSubscription));
}

export function clearStorageSubscription() {
  localStorage.removeItem(name);
}
