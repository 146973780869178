import React from 'react';
import {
  Checkbox,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dateFormat from 'dateformat';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { Invoice } from 'store/incomes/interfaces';
import InvoiceComponent from '../Invoice/InvoiceComponent';

interface MonthlyAmountsProps {
  monthlySum: MonthlySum;
  headersLength: number;
  isSelected: (value: string) => boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  incomes: Invoice[];
  isMonthSelected: (value: string) => boolean;
  handleMonthChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedMonths: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface MonthlySum {
  month: string;
  total: number;
  netValue: number;
  vat: number;
}

export default function MonthlyAmounts({
  monthlySum,
  headersLength,
  isSelected,
  handleChange,
  setSelected,
  incomes,
  isMonthSelected,
  handleMonthChange,
  setSelectedMonths,
}: MonthlyAmountsProps) {
  const formatAmount = useCurrencyFormat();

  const monthlyIncomes = incomes.filter(
    (income) =>
      dateFormat(new Date(income.saleDate), 'yyyy-mm') === monthlySum.month,
  );
  const monthlyIncomesIds = monthlyIncomes.map((income) => String(income.id));

  const handleCheckMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isMonthSelected(event.target.value)) {
      setSelected((prevState) => [
        ...new Set(monthlyIncomesIds.concat(prevState)),
      ]);
      setSelectedMonths((prevState) => [...prevState, monthlySum.month]);
    } else {
      setSelected((prevState) =>
        prevState.filter(
          (item) => !monthlyIncomesIds.some((id) => id === item),
        ),
      );
      handleMonthChange(event);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Checkbox
              checked={isMonthSelected(monthlySum.month)}
              onChange={handleCheckMonth}
              value={monthlySum.month}
              sx={{ '& .MuiSvgIcon-root': { fontSize: '2.4rem' } }}
            />
            <Typography variant="h6">{monthlySum.month}</Typography>
            <Chip
              label={
                <Typography variant="chipLabel">
                  {monthlyIncomes.length}
                </Typography>
              }
            />
          </Stack>
        </TableCell>
        <TableCell align="right" colSpan={headersLength - 1}>
          <Typography fontWeight={700} variant="body1">
            {formatAmount(monthlySum.netValue)}
            <Typography component="span" variant="body1" color="text.secondary">
              &nbsp;netto
            </Typography>
          </Typography>
          <Typography color="text.secondary" fontWeight={700} variant="body2">
            {formatAmount(monthlySum.total)}
            <Typography component="span" variant="body2" color="text.secondary">
              &nbsp;brutto
            </Typography>
          </Typography>
        </TableCell>
        <TableCell colSpan={2} />
      </TableRow>
      {monthlyIncomes.map((income) => (
        <InvoiceComponent
          key={income.id}
          handleChange={handleChange}
          income={income}
          isSelected={isSelected}
        />
      ))}
    </>
  );
}
