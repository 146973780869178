/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface ClientFormSelectProps {
  selectId: string;
  label: string;
  isRequired?: boolean;
  value: string;
  onChangeFn: (event: SelectChangeEvent) => void;
  selectOptions: Array<{
    id: string | number;
    name: string;
  }>;
  placeholder?: string;
}

function ClientFormSelect({
  selectId,
  label,
  isRequired = true,
  value,
  onChangeFn,
  selectOptions,
  placeholder,
}: ClientFormSelectProps) {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth required={isRequired}>
      <InputLabel id={selectId}>{label}</InputLabel>
      <Select
        id={selectId}
        label={label}
        value={value}
        onChange={onChangeFn}
        data-testid={selectId}
      >
        {!isRequired && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {selectOptions.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {t(item.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ClientFormSelect;
