import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { ChartIncome } from 'pages/IncomeChart/useGetChartIncome';

interface IncomeTableProps {
  displayedPeriod: string[];
  incomesGroupedByClient: Record<string, ChartIncome[]>;
  getTotalNetAmountFromClientPeriod: (client: string, period: string) => number;
}

function IncomeTable({
  displayedPeriod,
  incomesGroupedByClient,
  getTotalNetAmountFromClientPeriod,
}: IncomeTableProps) {
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();

  const sortedClientGroups = Object.keys(incomesGroupedByClient).sort(
    (group, comparedGroup) => group.localeCompare(comparedGroup),
  );

  const getFormattedTotalAmountForClientPeriod = (
    client: string,
    period: string,
  ) => {
    const amountForPeriod = getTotalNetAmountFromClientPeriod(client, period);

    return amountForPeriod !== 0 ? formatAmount(amountForPeriod) : '-';
  };

  return (
    <Box pl={3}>
      <TableContainer sx={{ pr: 3, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  minWidth: '25rem',
                  position: 'sticky',
                  left: 0,
                  zIndex: 1,
                  borderTopLeftRadius: '1.2rem',
                  borderBottomLeftRadius: '1.2rem',
                  boxShadow: `-1.2rem 0 0 0 ${theme.palette.background.list}`,
                })}
              >
                <Typography variant="tableHeader" color="text.secondary">
                  {t('label.client')}
                </Typography>
              </TableCell>
              {displayedPeriod.map((period) => (
                <TableCell key={period} sx={{ minWidth: '10rem' }}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {period}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={displayedPeriod.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedClientGroups.map((client) => (
              <TableRow key={client}>
                <TableCell
                  sx={{
                    width: '20rem',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    backgroundColor: 'white',
                  }}
                >
                  <TitleTooltip title={client}>
                    <Typography variant="body2">
                      {client.length > 20
                        ? `${client.slice(0, 20)}...`
                        : client}
                    </Typography>
                  </TitleTooltip>
                </TableCell>
                {displayedPeriod.map((period) => (
                  <TableCell key={period}>
                    <Typography variant="body2">
                      {getFormattedTotalAmountForClientPeriod(client, period)}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell colSpan={displayedPeriod.length} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default IncomeTable;
