import { Budget } from 'store/budget/interfaces';

export enum BudgetDetailsType {
  tile = 'tile',
  preview = 'preview'
}

export interface BudgetDetailsProps {
  budget: Budget,
  type: BudgetDetailsType.tile | BudgetDetailsType.preview
}
