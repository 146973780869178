import React from 'react';
import { Box, FormControl, DialogContent, Stack } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Error from 'common/Error';
import Loader from 'common/Loader';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import useGetCountriesOptions from 'hooks/useGetCountriesOptions';
import useGetCurrenciesOptions from 'hooks/useGetCurrenciesOptions';
import { useAddClientMutation } from 'store/clients/clients';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';
import { AddClientFormProps, FormPayload } from './interfaces';
import defaultValues from './defaultValues';
import schema from './AddClientForm.schema';
import VatNumberCountryInputs from './VatNumberCountryInputs/VatNumberCountryInputs';
import LocationInputs from './LocationInputs/LocationInputs';

const validate = zodResolver(schema);

export default function AddClientForm({
  setIsSuccess,
  setIsError,
  setIsOpen,
  setErrorMessage,
}: AddClientFormProps) {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    trigger,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues,
  });

  const { name, country, currency } = watch();

  const {
    currencyIdOptions,
    isError: currenciesError,
    isLoading: currenciesLoading,
  } = useGetCurrenciesOptions();
  const {
    countryIdOptions,
    countryNameOptions,
    isLoading: countriesLoading,
    isError: countriesError,
  } = useGetCountriesOptions();
  const [addClient, { isLoading: addClientLoading }] = useAddClientMutation();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const disableSubmission = () =>
    !name ||
    !country ||
    !currency ||
    Object.values(errors).some((error) => error);

  const handleAddClient = async (payloadData: FormPayload) => {
    try {
      const payloadWithoutEmptyFields = Object.fromEntries(
        Object.entries(payloadData).filter(([, value]) => value),
      );
      const addedClient = await addClient(payloadWithoutEmptyFields).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
      navigate(`/clients/${addedClient.id}`);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  if (currenciesLoading || countriesLoading) {
    return <Loader />;
  }

  if (currenciesError || countriesError) {
    return <Error />;
  }

  return (
    <>
      <DialogHeader title={t('clients.add_client')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddClient)} noValidate>
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 4 }}
          >
            <VatNumberCountryInputs
              control={control}
              errors={errors}
              countries={countryIdOptions}
              setError={setError}
              setValue={setValue}
              watch={watch}
              trigger={trigger}
            />
            <Stack spacing={2} direction="row">
              <Box flexBasis="75%">
                <ControlTextField
                  control={control}
                  errors={errors}
                  label="label.name"
                  name="name"
                  required
                />
              </Box>
              <Box flexBasis="25%">
                <ControlSelect
                  control={control}
                  name="currency"
                  errors={errors}
                  label="clients.currency"
                  selectOptions={currencyIdOptions}
                  required
                />
              </Box>
            </Stack>
            <LocationInputs
              control={control}
              errors={errors}
              countryNameOptions={countryNameOptions}
            />
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              loading={addClientLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
