import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import api from 'store/api';
import reducers from 'store/reducers';
import rootSaga from 'store/sagas';
import unauthorizedErrorHandler from 'store/authorization/unauthorizedErrorHandler';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      sagaMiddleware,
      api.middleware,
      unauthorizedErrorHandler,
    ]),
});

sagaMiddleware.run(rootSaga);
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
export default store;
