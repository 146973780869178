import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import getProgressChartTheme from './getProgressChartTheme';
import getReversedProgressChartTheme from './getReversedProgressChartTheme';

interface ProgressChartProps {
  percentageValue: number;
  circleSize?: number;
  fontSize?: string;
  reversed?: boolean;
}

export default function ProgressChart({
  percentageValue,
  circleSize = 48,
  fontSize = '1rem',
  reversed = false
}: ProgressChartProps) {
  const roundedPercentageValue = Math.round(percentageValue);
  const chartTheme = reversed ? getReversedProgressChartTheme(roundedPercentageValue) : getProgressChartTheme(roundedPercentageValue);

  return (
    <Box
      sx={{
        position: 'relative',
        width: circleSize,
        height: circleSize,
      }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        size={circleSize}
        thickness={6}
        sx={{
          color: chartTheme.borderColor,
        }}
      />
      <CircularProgress
        variant="determinate"
        value={percentageValue}
        sx={{
          color: chartTheme.color,
          position: 'absolute',
          left: 0,
          rotate: '180deg',
          circle: {
            strokeLinecap: 'round',
          },
        }}
        size={circleSize}
        thickness={6}
      />
      <Typography
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: chartTheme.color,
          fontWeight: 700,
          fontSize,
        }}
      >
        {`${roundedPercentageValue}%`}
      </Typography>
    </Box>
  );
}
