import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  Button,
  CardMedia,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import MastercardIconUrl from 'assets/img/mastercard.svg';
import { clearStorageSubscription } from 'pages/Subscription/storageSubscription';
import StripeInput from 'common/StripeInput/StripeInput';
import { useAppDispatch } from 'store/hooks';
import {
  setErrorMessage,
  setErrorStatus,
  setSuccessStatus,
} from 'store/status/actions';

export default function SubscriptionPaymentMethod() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (elements == null || stripe == null) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (!submitError) {
      // TODO: when backend is ready, send the payment method to the server
      clearStorageSubscription();
      navigate('/');
      dispatch(setSuccessStatus(true));
      return;
    }

    dispatch(setErrorStatus(true));
    if (submitError.message) {
      dispatch(setErrorMessage(submitError.message));
    }
  };

  return (
    <Stack width="68.8rem" mx="auto" my={8} spacing={8} alignItems="flex-start">
      <Typography variant="h5">
        {t('subscription.payment_method_title')}
      </Typography>
      <form noValidate style={{ width: '100%' }} onSubmit={handleSubmit}>
        <FormControl
          fullWidth
          sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}
        >
          <TextField
            label={t('label.cardholder_name')}
            name="cardholder_name"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label={t('label.card_number')}
            name="ccnumber"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
              },
              startAdornment: (
                <CardMedia
                  component="img"
                  loading="lazy"
                  src={MastercardIconUrl}
                  alt="Mastercard"
                  sx={{
                    width: '2rem',
                    mr: '1rem',
                  }}
                />
              ),
            }}
          />
          <Stack direction="row" spacing={3}>
            <TextField
              label={t('label.expiration_date')}
              name="ccexp"
              variant="outlined"
              sx={{ width: '50%' }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                },
              }}
            />
            <TextField
              label="CVC"
              name="cvc"
              variant="outlined"
              sx={{ width: '50%' }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                },
              }}
            />
          </Stack>
          <Typography variant="caption" color="text.secondary">
            {t('subscription.payment_method_subscription_description')}
          </Typography>
        </FormControl>
        <Button
          disabled={!stripe || !elements}
          variant="contained"
          type="submit"
          sx={{ height: '4.74rem', px: 2.75, my: 8 }}
        >
          {t('label.end_configuration')}
        </Button>
      </form>
    </Stack>
  );
}
