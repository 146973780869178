import React from 'react';
import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

interface SaveBookingButtonProps {
  isDraftBookingPossibleToAdd: boolean;
  handleAddPropertyBooking: () => void;
}

export default function SaveBookingButton({
  isDraftBookingPossibleToAdd,
  handleAddPropertyBooking,
}: SaveBookingButtonProps) {

  return (
    <IconButton
      sx={{
        width: '2rem',
        height: '2rem',
        cursor: isDraftBookingPossibleToAdd ? 'pointer' : 'not-allowed',
      }}
      disabled={!isDraftBookingPossibleToAdd}
      onClick={handleAddPropertyBooking}
    >
      <SaveIcon
        sx={{
          color: isDraftBookingPossibleToAdd
            ? 'main.white'
            : 'status.notActive.main',
          fontSize: '2rem',
        }}
      />
    </IconButton>
  );
}
