import React, { useState } from 'react';
import { Box, Dialog, Grid, Stack, Tab } from '@mui/material';
import { TabContext } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import ProjectsList from 'components/ProjectsList/ProjectsList';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddProjectForm from 'components/AddProjectForm/AddProjectForm';
import { ProjectStatuses } from 'enums';
import { useGetProjectsQuery } from 'store/projects/projects';
import EmptyProjectsList from 'components/EmptyProjectsList/EmptyProjectsList';
import { Project } from 'store/projects/interfaces';
import { useSearchParams } from 'react-router-dom';
import usePagination from 'hooks/usePagination';
import CustomPagination from 'common/CustomPagination/CustomPagination';
import emptyApiResponse from 'store/emptyApiResponse';
import AddButton from 'common/AddButton/AddButton';

export enum ProjectsTabValue {
  active = '1',
  inactive = '0',
}

export default function Projects() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(ProjectsTabValue.active);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { page, itemsPerPage, handleOnPageChange, handleOnItemsPerPageChange } =
    usePagination({ searchParams, setSearchParams });

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: ProjectsTabValue,
  ) => setTabValue(newValue);

  const {
    data: projects = {
      'hydra:member': [] as Project[],
      ...emptyApiResponse,
    },
    isError: projectsFetchError,
    isFetching: projectsFetching,
  } = useGetProjectsQuery({
    page,
    itemsPerPage,
    status:
      tabValue === ProjectsTabValue.active
        ? ProjectStatuses.active
        : ProjectStatuses.finished,
  });

  const noProjects = !projects['hydra:member'].length && !projectsFetching;

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <Stack>
          <NavBarTitle>{t('projects.header')}</NavBarTitle>
        </Stack>
        <AddButton
          label="projects.add_project"
          onClick={() => setIsOpen(!isOpen)}
        />
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('label.active')} value={ProjectsTabValue.active} />
          <Tab label={t('label.inactive')} value={ProjectsTabValue.inactive} />
        </StyledTabList>
        <ProjectsList
          projects={projects['hydra:member']}
          projectsFetchError={projectsFetchError}
          projectsFetching={projectsFetching}
        />
        {noProjects && <EmptyProjectsList onClick={() => setIsOpen(true)} />}
        {projects['hydra:view']?.['hydra:last'] && (
          <Box px={4} pb={4}>
            <CustomPagination
              apiData={projects}
              page={page}
              itemsPerPage={10}
              handleOnPageChange={handleOnPageChange}
              handleOnItemsPerPageChange={handleOnItemsPerPageChange}
              showRowsSelect={false}
            />
          </Box>
        )}
      </TabContext>
      <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} fullWidth>
        <AddProjectForm setIsOpen={setIsOpen} />
      </Dialog>
    </Grid>
  );
}
