import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { setIsAuthorized, setToken } from 'store/authorization/actions';
import { getIsAuthorized, getToken } from 'store/authorization/selectors';

export interface AuthProps {
  setToken: (token: string | null) => string | null;
  setIsAuthorized: (isAuthorized: boolean) => boolean;
  token: string | null;
  isAuthorized: boolean;
}

export function withAuth<BaseProps extends AuthProps>(
  BaseComponent: ComponentType<BaseProps>,
) {
  const mapStateToProps = (state: RootState) => ({
    isAuthorized: getIsAuthorized(state),
    token: getToken(state),
  });

  const mapDispatchToProps = (dispatch: any) => ({
    setIsAuthorized: (status: boolean) => dispatch(setIsAuthorized(status)),
    setToken: (status: string | null) => dispatch(setToken(status)),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
    // @ts-ignore
  )(BaseComponent);
}
