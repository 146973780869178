/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Stack, StackOwnProps, Theme, useMediaQuery } from '@mui/material';
import { v4 as uuid } from 'uuid';

interface MasonryElementsProps {
  columnsElements: React.ReactNode[][];
  spacing?: StackOwnProps['spacing'];
  p?: StackOwnProps['p'];
}

// use when you want to decide how many elements a given column should have
export default function MasonryElements({
  columnsElements,
  spacing = 3,
  p = 3,
}: MasonryElementsProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  if (isMobile || columnsElements.length === 1) {
    return (
      <Stack direction="column" spacing={spacing} p={p}>
        {columnsElements.map((columnElements) => (
          <Stack key={uuid()} spacing={spacing}>
            {columnElements.map((child) => (
              <div key={uuid()}>{child}</div>
            ))}
          </Stack>
        ))}
      </Stack>
    );
  }

  const columnFlexBasis = `${100 / columnsElements.length}%`;

  return (
    <Stack direction="row" spacing={spacing} p={p}>
      {columnsElements.map((columnElements) => (
        <Stack key={uuid()} spacing={spacing} flexBasis={columnFlexBasis}>
          {columnElements.map((child) => (
            <div key={uuid()}>{child}</div>
          ))}
        </Stack>
      ))}
    </Stack>
  );
}
