import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCheckRoles from 'hooks/useCheckRoles';

interface ConfigurationTileProps {
  header: string;
  description: string;
  navigateTo: string;
  role: string[];
}

function ConfigurationTile({
  header,
  description,
  navigateTo,
  role,
}: ConfigurationTileProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAllowedToManage = useCheckRoles(role);

  if (!isAllowedToManage) {
    return null;
  }

  return (
    <Grid
      item
      xs={3}
      onClick={() => navigate(navigateTo)}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box
        p={3}
        bgcolor="main.white"
        border="0.1rem solid"
        borderColor="secondary.light"
        borderRadius="1.2rem"
      >
        <Typography variant="bold">{t(header)}</Typography>
        <Typography variant="body1">{t(description)}</Typography>
      </Box>
    </Grid>
  );
}

export default ConfigurationTile;
