import React from 'react';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';
import { WidgetCaption } from 'common/Widget';

export interface EmployeeProps {
  employeeIri: string;
}

function Employee({ employeeIri }: EmployeeProps) {
  const { selectedEmployee } = useGetSelectedEmployeePreview({
    employeeIri,
    filters: { isActive: true },
  });

  return (
    <WidgetCaption>
      {selectedEmployee?.lastname} {selectedEmployee?.firstname}
    </WidgetCaption>
  );
}

export default Employee;
