/* eslint-disable no-param-reassign */

import { createReducer } from '@reduxjs/toolkit';
import {
    setAuthorization,
    storeInit
} from './actions';
import initialAppState from './defaultState';
import {StoreInitActionPayload} from './interfaces';

export default createReducer(initialAppState, (builder) => {
  builder
    .addCase(storeInit, (draft, { payload }: { payload: StoreInitActionPayload }) => {
      const { config } = payload;
      draft.config = config;
    })
    .addCase(setAuthorization, (draft, { payload }: { payload: string|undefined }) => {
        if (typeof payload === 'string') {
            draft.config.apiToken = `Bearer ${payload}`;
        }
        else {
            draft.config.apiToken = undefined;
        }
    })
  ;
});
