export interface ClientStatuses {
  id: string;
  description: string;
}

const clientStatuses = [
  { id: 'active', description: 'clients.active' },
  { id: 'inactive', description: 'clients.inactive' },
];

export default clientStatuses;
