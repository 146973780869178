import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledForwardLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',

  '&:visited': {
    color: theme.palette.primary.main,
  },
}));

export default StyledForwardLink;
