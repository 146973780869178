import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  useGetPropertiesQuery,
  useGetPropertyTypesQuery,
} from 'store/properties/properties';
import { useGetPropertyBookingsQuery } from 'store/propertyBooking/propertyBooking';
import formatDateToUtcFormat from 'helpers/formatDateToUtcFormat';
import { useTranslation } from 'react-i18next';
import headers from 'components/Properties/headers';
import { isAfter, isBefore } from 'date-fns';
import useCurrentDateEvery from 'hooks/useCurrentDateEvery';
import PropertiesAccordion from './PropertiesAccordion/PropertiesAccordion';


function PropertiesList() {
  const { data: properties = [] } = useGetPropertiesQuery({});
  const { data: types = [] } = useGetPropertyTypesQuery();

  const oneMinuteInMs= 60000
  const currentDateEveryMinute = useCurrentDateEvery(oneMinuteInMs);

  const { data: bookings = [] } = useGetPropertyBookingsQuery(
    {
      'startDate[before]': formatDateToUtcFormat(currentDateEveryMinute),
      'dateTo[after]': formatDateToUtcFormat(currentDateEveryMinute),
    }
  );
  const activeBookingsFilteredByStartDateTime = bookings.filter((booking) => {

    const startDate = new Date(booking.startDate);
    const endDate = new Date(booking.dateTo);
    // in case one booking ends when other starts
    const endDateMinusSecond = new Date(endDate.setSeconds(endDate.getSeconds() - 1));

    return (
      !isAfter(startDate, currentDateEveryMinute) && !isBefore(endDateMinusSecond, currentDateEveryMinute) &&
      !booking.cancelledAt
    );
  });

  const { t } = useTranslation();

  const getTypeProperties = (type: string) =>
    properties.filter((property) => property.type === type);

  return (
    <>
      <Grid container alignItems="center" px={4} py={2}>
        {headers.map((header) => (
          <Grid key={header.label} item xs={header.columns}>
            <Typography
              color="text.secondary"
            >
              {t(header.label)}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container gap={2} px={3} pb={3}>
        {types.map((type) => (
          <PropertiesAccordion
            key={type}
            activeBookingsFilteredByStartDateTime={activeBookingsFilteredByStartDateTime}
            properties={getTypeProperties(type)}
            type={type}
          />
        ))}
      </Grid>
    </>
  );
}

export default PropertiesList;
