import { FormPayload } from './interfaces';

const defaultValues: FormPayload = {
  days: '',
  dateFrom: '',
  variant: '',
  holidayType: '',
};

export default defaultValues;
