function createCalendar(year: number, month: number, startWeek: number) {
  if (![0,1,6].includes(startWeek)) {
    throw new Error('Invalid startWeek value. Must be 0, 1, or 6.');
  }

  const dates: Date[] = [];

  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1);

  // Find first and last day frm prev month
  const prevMonthLastDay = new Date(year, month, 0);
  const prevMonthStartDay = (firstDayOfMonth.getDay() - startWeek + 7) % 7;

  // Add days from previous month
  for (let day = prevMonthLastDay.getDate() - prevMonthStartDay + 1; day <= prevMonthLastDay.getDate(); day++) {
    const date = new Date(prevMonthLastDay);
    date.setDate(day);
    dates.push(date);
  }

  // Add days from current month
  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, month, day);
    dates.push(date);
  }

  const nextMonthStartDay = (7 - firstDayOfMonth.getDay() + startWeek) % 7;

  // Add days from next month
  for (let day = 1; day <= nextMonthStartDay; day++) {
    const date = new Date(firstDayOfMonth);
    date.setDate(date.getDate() + daysInMonth + day);
    dates.push(date);
  }

  return dates;
}

export default createCalendar;
