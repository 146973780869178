import React from 'react';
import Loader from 'common/Loader';
import Error from 'common/Error';
import StyledChipWrapper from 'config/material-ui/styled/StyledChipWrapper';
import Grid from '@mui/material/Grid';
import { Chip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ResourceRequestsData } from 'store/resourceRequests/interfaces';
import { useGetResourceRequestsQuery } from 'store/resourceRequests/resourceRequests';
import { useTranslation } from 'react-i18next';
import { CandidateFormResourcesProps } from './interfaces';

export default function CandidateFormResources({
  selectedResources,
  setSelectedResources
}: CandidateFormResourcesProps) {
  const { t } = useTranslation();
  const {
    data: resourceRequestsData = {} as ResourceRequestsData,
    isLoading: resourcesRequestsLoading,
    isError: resourcesRequestsError,
  } = useGetResourceRequestsQuery({});
  const { 'hydra:member': resourceRequests } = resourceRequestsData;
  const handleSelectionChanged = (iriId: string) => {
    const newSet = new Set(selectedResources);
    if (newSet.has(iriId)) {
      newSet.delete(iriId);
    } else {
      newSet.add(iriId);
    }
    if (setSelectedResources) {
      setSelectedResources(newSet);
    }
  };

  if (resourcesRequestsLoading) {
    return <Loader />
  }
  if (resourcesRequestsError) {
    return <Error />;
  }
  return (
    <>
      <Grid container pb={2}>
        <Typography variant="body2">
          {t('human_resources.resources')}
        </Typography>
      </Grid>
      {resourceRequests.map((resource) => (
        <StyledChipWrapper key={resource.id}>
          <Stack spacing={0.5} direction="row" useFlexGap flexWrap="wrap">
            <Chip
              {...(selectedResources?.has(resource['@id']) && { color: 'primary'})}
              onClick={() => handleSelectionChanged(resource['@id'])}
              label={
                <Typography variant="chipLabel">
                  {resource.position}
                </Typography>
              }
            />
          </Stack>
        </StyledChipWrapper>
      ))}
    </>
  )
}
