import React from 'react';
import { Box, Typography } from '@mui/material';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import dateFormat from 'dateformat';

export default function TimeScaleLabelComponent({
  time,
  formatDate,
}: WeekView.TimeScaleLabelProps) {
  const formattedTime = dateFormat(time, 'HH:MM');

  if (!formatDate) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        borderBottom: 'none',
        height: '4.8rem'
      }}
    >
      <Typography variant="caption" color="text.secondary" mt={2}>
        {formattedTime}
      </Typography>
    </Box>
  );
}
