import store from 'store';

const downloadFileWithAuth = (
  fileLink: string,
  fileId: string,
  isPDF = false,
) => {
  const state = store.getState();
  const { organization } = state.organization;
  const token = localStorage?.token as string;

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  const headers = new Headers();
  headers.append('authorization', `Bearer ${token}`);

  if (isPDF) {
    headers.append('accept', 'application/pdf');
  }

  if (organization.instance) {
    headers.append('instance', organization.instance);
  }

  fetch(fileLink, { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      const objectURL = window.URL.createObjectURL(blobby);
      anchor.href = objectURL;
      anchor.download = fileId;
      anchor.click();

      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(objectURL);
    });
};

export default downloadFileWithAuth;
