import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useTranslation } from 'react-i18next';

function UserStatus() {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        <Typography variant="body1">{t('employees.active_status')}</Typography>
      }
      placement="right-start"
      componentsProps={{
        tooltip: {
          sx: {
            color: 'text.primary',
            bgcolor: 'main.white',
            boxShadow: 5,
          },
        },
      }}
    >
      <PersonOffIcon fontSize="large" sx={{ color: 'icon.light' }} />
    </Tooltip>
  );
}

export default UserStatus;
