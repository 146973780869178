import React from 'react';
import { Box } from '@mui/material';
import { useGetHolidaysQuery } from 'store/holidays/holidays';
import { getHolidayName } from 'helpers/getHolidayData';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { useTranslation } from 'react-i18next';
import fullDateFormat from 'helpers/fullDateFormat';
import { QuestionMark } from '@mui/icons-material';
import holidayTypes from 'components/EmployeeHolidayForm/holidayTypes';
import {
  WidgetCaption,
  WidgetCaptionContainer,
  WidgetContainer,
} from 'common/Widget';
import replaceApiIri from 'helpers/replaceApiIri';
import useMeetingContext from 'pages/Meeting/context/useMeetingContext';
import HolidaysTitle from './HolidaysTitle/HolidaysTitle';

export default function Holidays() {
  const { t } = useTranslation();
  const { meeting, meetingLoading, meetingError } = useMeetingContext();
  const {
    data: holidays = {
      'hydra:member': [],
    },
    isLoading: holidaysLoading,
    isError: holidaysError,
  } = useGetHolidaysQuery(
    {
      employee: replaceApiIri(meeting.employee ?? '', 'employees'),
      itemsPerPage: 10,
    },
    { skip: !meeting.employee },
  );

  const getHolidayTypeIcon = (type: string) => {
    const matchedType = holidayTypes.find(
      (holidayType) => holidayType.type === type,
    );

    if (!matchedType) {
      return <QuestionMark />;
    }

    return matchedType.icon;
  };

  return (
    <WidgetContainer
      isLoading={holidaysLoading || meetingLoading}
      isError={holidaysError || meetingError}
      title={<HolidaysTitle holidays={holidays['hydra:member']} />}
    >
      {holidays['hydra:member'].map((holiday) => (
        <WidgetCaptionContainer key={holiday.id}>
          <Box display="flex" gap={2} alignItems="center">
            <TitleTooltip title={t(getHolidayName(holiday.type))}>
              {getHolidayTypeIcon(holiday.type)}
            </TitleTooltip>
            <WidgetCaption size="lg">
              {t(getHolidayName(holiday.type))}
            </WidgetCaption>
          </Box>
          <WidgetCaption size="lg">
            {`${fullDateFormat(holiday.dateFrom)}/${fullDateFormat(
              holiday.dateTo,
            )}`}
          </WidgetCaption>
        </WidgetCaptionContainer>
      ))}
    </WidgetContainer>
  );
}
