import { Typography, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import MockTechSkillIcon from 'assets/icons/MockTechSkillIcon';
import useCandidateContext from 'pages/HumanResourceCandidate/context/useCandidateContext';
import RecruitmentTile from './RecruitmentTile/RecruitmentTile';

export default function ActiveRecruitments() {
  const { t } = useTranslation();

  const { resourceRequestsForCandidate } = useCandidateContext();

  return (
    <StyledContentBox p={3} mt={3}>
      <Typography variant="h6">
        {t('human_resources.active_recruitments', {
          count: resourceRequestsForCandidate.length,
        })}
      </Typography>
      <Stack spacing={3} mt={3}>
        {resourceRequestsForCandidate.map((resourceRequestForCandidate) => (
          <RecruitmentTile
            key={resourceRequestForCandidate.id}
            resourceRequestForCandidate={resourceRequestForCandidate}
            icon={
              <MockTechSkillIcon
                sx={{
                  color: 'primary.main',
                  fontSize: '3.2rem',
                }}
              />
            }
          />
        ))}
      </Stack>
    </StyledContentBox>
  );
}
