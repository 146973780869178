import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Employee } from 'store/employee/interfaces';
import {
  useAddUserForEmployeeMutation,
  useGetEmployeeQuery,
} from 'store/employee/employees';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { useValidate } from 'hooks/useValidate';
import { useTranslation } from 'react-i18next';
import checkEmail from 'helpers/validators/checkEmail/checkEmail';
import { StatusProps } from 'hoc/Status/Status';
import CloseButton from 'common/CloseButton/CloseButton';

export interface AddUserFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeeId: number;
}

interface Errors {
  firstname: boolean;
  surname: boolean;
  companyEmail: boolean;
}

function AddUserForm({
  setIsOpen,
  employeeId,
  setIsSuccess,
  setIsError,
}: AddUserFormProps) {
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const {
    data: employee = {} as Employee,
    isLoading,
    isError,
    isSuccess,
  } = useGetEmployeeQuery(employeeId);
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();
  const [addUser, { isSuccess: userAdded, isError: userAddError }] =
    useAddUserForEmployeeMutation();

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    setFirstname(employee.firstname);
    setSurname(employee.lastname);
    setCompanyEmail(employee.companyEmail);
  }, [isSuccess, employee]);

  const disableSubmission = () =>
    Object.values(errors).some((error) => error) ||
    !firstname ||
    !surname ||
    !companyEmail;

  const handleAddUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addUser({
      id: employee.id,
      email: companyEmail,
      firstname,
      lastname: surname,
    });
  };

  useEffect(() => {
    setIsError(userAddError);
    setIsSuccess(userAdded);
    if (!userAdded) {
      return;
    }
    setIsOpen(false);
  }, [setIsError, userAddError, setIsSuccess, userAdded, setIsOpen]);

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">{t('employees.add_user')}</Typography>
        <CloseButton setIsOpen={setIsOpen} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddUser}>
          <Box display="flex" flexDirection="column" rowGap={3} mt={4}>
            <TextField
              error={errors.firstname}
              helperText={errors.firstname && t('errors.too_short_name')}
              fullWidth
              required
              label={t('label.firstname')}
              value={firstname}
              onChange={(event) => setFirstname(event.target.value)}
              onBlur={(event) =>
                validate('firstname', event.target.value.length >= 2)
              }
            />
            <TextField
              error={errors.surname}
              helperText={errors.surname && t('errors.too_short_name')}
              fullWidth
              required
              label={t('label.lastname')}
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
              onBlur={(event) =>
                validate('firstname', event.target.value.length >= 2)
              }
            />
            <TextField
              error={errors.companyEmail}
              helperText={errors.companyEmail && t('errors.wrong_mail')}
              fullWidth
              required
              label={t('inputs.company_email')}
              value={companyEmail}
              onChange={(event) => setCompanyEmail(event.target.value)}
              onBlur={(event) =>
                validate('companyEmail', checkEmail(event.target.value))
              }
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              variant="contained"
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              type="submit"
            >
              {t('employees.add_user')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default AddUserForm;
