import { Balance } from 'store/condition/defaultState';

interface BalanceProps {
  property: string;
  value: number;
}

export const getCollectionBalance = (
  collection: string,
  data: Balance[],
  month: string,
) => {
  const matchedBalance = data.find(
    (item) => item.collection === collection && item.month === month,
  );

  if (!matchedBalance) {
    return 0;
  }

  return matchedBalance.value;
};

export const getPropertyBalance = (data: BalanceProps[], property: string) => {
  const matchedPropertyBalance = data.find(
    (item) => item.property === property,
  );

  if (!matchedPropertyBalance) {
    return 0;
  }

  return matchedPropertyBalance.value;
};
