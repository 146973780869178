import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  {
    align: 'left',
    label: 'payments.contractor',
  },
];

export default headers;
