import React, { useState } from 'react';
import {
  Box,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { useTranslation } from 'react-i18next';
import { StatusProps } from 'hoc/Status/Status';
import { useGetBenefitsQuery } from 'store/benefits/benefits';
import { EmployeeBenefit } from 'store/employeeBenefit/interfaces';
import fullDateFormat from 'helpers/fullDateFormat';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useUpdateEmployeeBenefitMutation } from 'store/employeeBenefit/employeeBenefit';
import { CustomError } from 'store/api';

export interface EditBenefitProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  benefit: EmployeeBenefit;
}

export default function EditBenefit({
  benefit,
  setIsOpen,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: EditBenefitProps) {
  const { t } = useTranslation();
  const { data: benefits = [] } = useGetBenefitsQuery();
  const [benefitIri, setBenefitIri] = useState(benefit.benefit);
  const [dateFrom, setDateFrom] = useState(fullDateFormat(benefit.dateFrom));
  const [dateTo, setDateTo] = useState(
    benefit.dateTo ? fullDateFormat(benefit.dateTo) : '',
  );
  const [updateBenefit, { isLoading }] = useUpdateEmployeeBenefitMutation();

  const handleEditBenefit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateBenefit({
        id: benefit.id,
        benefit: benefitIri,
        dateFrom: new Date(dateFrom),
        dateTo: dateTo ? new Date(dateTo) : null,
        employee: benefit.employee,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('benefits.edit')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleEditBenefit}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={3}
            mt={3}
          >
            <FormControl fullWidth required>
              <InputLabel id="benefit">{t('label.benefit')}</InputLabel>
              <Select
                id="benefit"
                label={t('label.benefit')}
                onChange={(event) => setBenefitIri(event.target.value)}
                value={benefitIri}
              >
                {benefits.map((benefitData) => (
                  <MenuItem key={benefitData.id} value={benefitData['@id']}>
                    {benefitData.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label={t('label.date_from')}
              name="dateFrom"
              onChange={(event) => setDateFrom(event.target.value)}
              required
              type="date"
              value={dateFrom}
            />
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: dateFrom,
              }}
              label={t('label.date_to')}
              name="dateTo"
              onChange={(event) => setDateTo(event.target.value)}
              type="date"
              value={dateTo}
            />
            <LoadingButton
              disabled={!benefitIri || !dateFrom}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
