import React from 'react';
import {
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from 'assets/icons/CheckCircleIcon';
import { NestedMenuItem } from 'mui-nested-menu';
import ArrowsSwapIcon from 'assets/icons/ArrowsSwapIcon';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import api from 'store/api';
import { setOrganization } from 'store/organizations/actions';
import { OrganizationReduxState } from 'store/organizations/defaultState';
import theme from 'config/material-ui/theme';

interface OrganizationsNestedMenuProps {
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  organizations: OrganizationReduxState[];
}

export default function OrganizationsNestedMenu({
  handleClose,
  anchorEl,
  organizations,
}: OrganizationsNestedMenuProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(
    (state: RootState) => state.organization.organization,
  );

  const handleSwitchOrganization = (organization: OrganizationReduxState) => {
    handleClose();
    if (selectedOrganization.id === organization.id) {
      return;
    }
    localStorage.setItem('selectedOrganizationId', organization.id);
    dispatch(
      setOrganization({
        roles: organization.roles,
        possibleRoles: organization.possibleRoles,
        instance: organization.instance,
        name: organization.name,
        defaultCurrency: organization.defaultCurrency,
        features: organization.features,
        config: organization.config,
        id: organization.id,
      }),
    );
    dispatch(api.util.resetApiState());
  };

  return (
    <NestedMenuItem
      sx={{
        px: 2,
        py: 0.75,
      }}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            width: '26rem',
            maxWidth: '100%',
            borderRadius: theme.spacing(1.5),
            transform: `translate(${theme.spacing(1)}, -${theme.spacing(
              0.75,
            )}) !important`,
          },
          '& .MuiList-root.MuiList-padding.MuiMenu-list': {
            py: 0.75,
          },
        },
      }}
      renderLabel={() => (
        <Typography variant="menuItemDefault">
          {t('menu.change_organization')}
        </Typography>
      )}
      parentMenuOpen={Boolean(anchorEl)}
      leftIcon={
        <ListItemIcon>
          <ArrowsSwapIcon
            sx={{
              fontSize: '2rem',
              color: 'icon.dark',
              alignSelf: 'center',
            }}
          />
        </ListItemIcon>
      }
      rightIcon={<ChevronRight sx={{ fontSize: '2rem', color: 'icon.dark' }} />}
    >
      {organizations.map((organization: any) => (
        <MenuItem
          key={organization.id}
          onClick={() => handleSwitchOrganization(organization)}
        >
          <ListItemText>{organization.name}</ListItemText>
          {selectedOrganization.id === organization.id && (
            <CheckCircleIcon sx={{ fontSize: '2rem', color: 'icon.main' }} />
          )}
        </MenuItem>
      ))}
    </NestedMenuItem>
  );
}
