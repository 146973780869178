import React, { useEffect } from 'react';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Rows from 'components/InvoiceForm/Rows/Rows';
import InvoiceFormSelect from 'components/AddInvoiceForm/InvoiceFormSelect/InvoiceFormSelect';
import { useGetCurrencyRateQuery } from 'store/currencies/currencies';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Currencies } from 'enums';
import replaceApiIri from 'helpers/replaceApiIri';
import { useGetIncomeNameQuery } from 'store/incomes/incomes';
import Loader from 'common/Loader';
import { InvoiceFormProps } from './interfaces';
import InvoiceName from './InvoiceName/InvoiceName';
import InvoiceSummary from './InvoiceSummary/InvoiceSummary';
import InvoiceClient from './InvoiceClient/InvoiceClient';

function InvoiceForm({
  name,
  setName,
  issueDate,
  setIssueDate,
  saleDate,
  setSaleDate,
  dueDate,
  setDueDate,
  clientIri,
  setClientIri,
  accountIri,
  setAccountIri,
  currencyIri,
  setCurrencyIri,
  conversionRate,
  setConversionRate,
  incomeRows,
  setIncomeRows,
  clients,
  currencies,
  accounts,
  disableSubmission,
  projects,
  handleFormSubmit,
  notes,
  setNotes,
  internalComments,
  setInternalComments,
  isEditForm,
  isLoading,
  handleUpdateInternalComments,
}: InvoiceFormProps) {
  const { t } = useTranslation();
  const defaultCurrency = useSelector(
    (state: RootState) => state.organization.organization.defaultCurrency,
  );

  const { data: currencyRate } = useGetCurrencyRateQuery(
    {
      sourceCurrency: replaceApiIri(currencyIri, 'currencies'),
      targetCurrency:
        (defaultCurrency && replaceApiIri(defaultCurrency, 'currencies')) ||
        Currencies.pln,
      date: dateFormat(new Date(), 'yyyy-mm-dd'),
    },
    {
      skip: !currencyIri,
    },
  );

  const { data: generatedName, isLoading: generatedNameLoading } =
    useGetIncomeNameQuery(saleDate, {
      skip: !saleDate || isEditForm,
    });

  useEffect(() => {
    if (!currencyRate || isEditForm) {
      return;
    }
    setConversionRate(String(currencyRate.rate));
  }, [currencyRate, setConversionRate, isEditForm]);

  useEffect(() => {
    if (!generatedName) {
      return;
    }
    setName(generatedName.name);
  }, [generatedName, setName]);

  useEffect(() => {
    const matchedAccount = accounts.find(
      (item) => item.currency === currencyIri && item.default,
    );
    if (!matchedAccount) {
      return;
    }
    setAccountIri(matchedAccount['@id']);
  }, [accounts, currencies, currencyIri, setAccountIri]);

  if (generatedNameLoading) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Stack bgcolor="main.white" pb={6} pt={4} spacing={6}>
        <InvoiceName name={name} setName={setName} />
        <Stack
          direction="row"
          justifyContent="space-between"
          px={6}
          spacing={6}
        >
          <InvoiceClient
            setDueDate={setDueDate}
            clientIri={clientIri}
            setClientIri={setClientIri}
            setCurrencyIri={setCurrencyIri}
            clients={clients}
            setInternalComments={setInternalComments}
            isEditForm={isEditForm}
          />
          <Stack width="33.3%">
            <Stack spacing={3}>
              <Typography variant="subtitle1">
                {t('invoices.deadlines')}
              </Typography>
              <TextField
                id="issue-date"
                label={t('invoices.issue_date')}
                type="date"
                data-testid="invoice-form-issue-date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={issueDate}
                onChange={(event) => setIssueDate(event.target.value)}
                required
              />
              <TextField
                label={t('invoices.sale_date')}
                type="date"
                id="sale-date"
                data-testid="invoice-form-sale-date"
                onChange={({ target }) => setSaleDate(target.value)}
                value={saleDate}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label={t('invoices.due_date')}
                type="date"
                id="due-date"
                onChange={(event) => setDueDate(event.target.value)}
                value={dueDate}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
          </Stack>
          <Stack width="33.3%">
            <Stack spacing={3}>
              <Typography variant="subtitle1">{t('label.payments')}</Typography>
              <InvoiceFormSelect
                selectId="account-select"
                label={t('employees.header_bank_account')}
                value={accountIri}
                onChangeFn={(event) => {
                  setAccountIri(event.target.value);
                }}
                selectOptions={accounts.map((item) => ({
                  id: item['@id'],
                  name: `${item.bank.name} ${item.name}`,
                }))}
              />
              <Stack spacing={2} direction="row">
                <InvoiceFormSelect
                  selectId="currency-select"
                  label={t('invoices.currency')}
                  value={currencyIri}
                  onChangeFn={(event) => {
                    setCurrencyIri(event.target.value);
                  }}
                  selectOptions={currencies.map((item) => ({
                    id: item['@id'],
                    name: item.id.toUpperCase(),
                  }))}
                />

                <TextField
                  fullWidth
                  required
                  type="number"
                  id="conversion-rate"
                  label={t('invoices.conversion_rate')}
                  onChange={(event) => setConversionRate(event.target.value)}
                  value={conversionRate}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack px={6}>
          <Rows
            incomeRows={incomeRows}
            setIncomeRows={setIncomeRows}
            projects={projects}
          />
        </Stack>
        <Divider />
        <InvoiceSummary
          incomeRows={incomeRows}
          disableSubmission={disableSubmission}
          isLoading={isLoading}
          currencyIri={currencyIri}
        />
        <Stack direction="row" justifyContent="space-between" px={6}>
          <TextField
            id="notes"
            label={t('inputs.notes')}
            multiline
            minRows={5}
            onChange={(event) => setNotes(event.target.value)}
            sx={{ width: '30%' }}
            value={notes}
          />
          <TextField
            id="internalComments"
            label={t('inputs.internal_comments')}
            multiline
            minRows={5}
            onChange={(event) =>
              setInternalComments((prev) => ({
                ...prev,
                current: event.target.value,
              }))
            }
            onBlur={handleUpdateInternalComments}
            sx={{ width: '30%' }}
            value={internalComments.current}
          />
        </Stack>
      </Stack>
    </form>
  );
}

export default InvoiceForm;
