import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  { label: 'agreements.header_employee', align: 'left' },
  { label: 'agreements.header_position', align: 'left' },
  { label: 'agreements.header_variant', align: 'center' },
  { label: 'label.type', align: 'center' },
  { label: 'agreements.header_start_end', align: 'center' },
  { label: 'label.status', align: 'center' },
  { label: 'agreements.header_file', align: 'center' },
];

export default tableHeaders;
