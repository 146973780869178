import { Typography, FormControl, Box, Stack } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useForm } from 'react-hook-form';
import dateFormat from 'dateformat';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import useCandidateContext from 'pages/HumanResourceCandidate/context/useCandidateContext';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import PhoneMaskInput from 'common/MaskInputs/PhoneMaskInput';
import { useUpdateCandidateMutation } from 'store/candidates/candidates';
import { LoadingButton } from '@mui/lab';
import formatPhoneNumber from 'helpers/formatToPhoneMask';
import { CustomError } from 'store/api';
import schema from './PersonalInformationsForm.schema';
import { FormPayload, PersonalInformationsFormProps } from './interfaces';

const validate = zodResolver(schema);

export default function PersonalInformationsForm({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: PersonalInformationsFormProps) {
  const { t } = useTranslation();
  const { candidate } = useCandidateContext();
  const initFormValues = {
    email: candidate?.email || '',
    phoneNumber: candidate?.phoneNumber
      ? formatPhoneNumber(candidate.phoneNumber)
      : '',
    location: candidate?.location || '',
    birthDate: candidate?.birthDate
      ? dateFormat(candidate.birthDate, 'yyyy-mm-dd')
      : '',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onBlur',
    defaultValues: initFormValues,
  });
  const [updateCandidate, { isLoading: candidateUpdateLoading }] =
    useUpdateCandidateMutation();

  const isFormValuesChanged = !!Object.keys(dirtyFields).length;
  const disableSubmission = () => Object.values(errors).some((error) => error);

  const handleUpdateCandidate = async (data: FormPayload) => {
    try {
      const { phoneNumber, birthDate, ...restData } = data;
      const payload = {
        ...restData,
        id: candidate.id,
        birthDate: birthDate || null,

        phoneNumber: phoneNumber?.replace(/[^0-9]/g, '') || '',
      };
      await updateCandidate(payload).unwrap();
      reset(data);
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <StyledContentBox p={3}>
      <Typography variant="h6">{t('label.personal_informations')}</Typography>
      <form onSubmit={handleSubmit(handleUpdateCandidate)} noValidate>
        <FormControl
          fullWidth
          sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 4 }}
        >
          <Stack direction="row" spacing={2}>
            <ControlTextField
              control={control}
              errors={errors}
              label="label.email"
              name="email"
              required
            />
            <ControlTextField
              control={control}
              errors={errors}
              name="phoneNumber"
              label="label.phoneNumber"
              InputProps={{
                inputComponent: PhoneMaskInput as any,
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <ControlTextField
              control={control}
              errors={errors}
              label="label.location"
              name="location"
            />
            <ControlTextField
              control={control}
              errors={errors}
              label="label.birth_date"
              name="birthDate"
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
            />
          </Stack>
        </FormControl>
        {isFormValuesChanged && (
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={disableSubmission()}
              loading={candidateUpdateLoading}
              endIcon={<CheckIcon />}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save_changes')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </StyledContentBox>
  );
}
