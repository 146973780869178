import React from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'store/transactions/transactions';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { Invoice } from 'store/incomes/interfaces';
import { Client } from 'store/clients/clients';

interface BalanceProps {
  notAssignedTransactions: Transaction[];
  client: Client;
  invoices: Invoice[];
}

function Balance({ notAssignedTransactions, client, invoices }: BalanceProps) {
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();
  let invoicesAmountSum = 0;
  let matchedPaidAmount = 0;

  invoices.forEach((invoice) => {
    invoicesAmountSum += invoice.amount;
    const paidAmount = invoice.incomeTransactions?.reduce(
      (acc, { amount }) => acc + amount / 100,
      0,
    );
    matchedPaidAmount += paidAmount;
  });

  invoicesAmountSum = Math.round(invoicesAmountSum * 100) / 100;

  const notMatchedSum = () => {
    if (!notAssignedTransactions) {
      return 0;
    }
    return notAssignedTransactions.reduce((acc, { amount }) => acc + amount, 0);
  };

  const getPaidAmount = (): number => {
    if (!notAssignedTransactions) {
      return 0;
    }
    return notAssignedTransactions.reduce((acc, { amount }) => acc + amount, 0);
  };

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body1">
          {formatAmount(invoicesAmountSum, client.currency)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="500" variant="h5">
          {t('label.balance')}
        </Typography>
        <Typography fontWeight="500" variant="h5">
          {formatAmount(matchedPaidAmount-invoicesAmountSum, client.currency)}
        </Typography>
      </TableCell>
      <TableCell colSpan={3}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography sx={{ bgcolor: 'success.light', p: 1 }} variant="body1">
              {formatAmount(matchedPaidAmount, client.currency)}
            </Typography>
            <Typography variant="body1">{t('label.matched')}</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography sx={{ bgcolor: 'error.light', p: 1 }} variant="body1">
              {formatAmount(notMatchedSum() - matchedPaidAmount, client.currency)}
            </Typography>
            <Typography variant="body1">{t('label.not_matched')}</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography sx={{ bgcolor: 'primary.light', p: 1 }} variant="body1">
              {formatAmount(getPaidAmount(), client.currency)}
            </Typography>
            <Typography variant="body1">{t('label.sum_on_account')}</Typography>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default Balance;
