import React from 'react';
import {
  CostCharts,
  useGetCostsChartsQuery,
} from 'store/costGroups/costGroups';
import ReactEChart from 'echarts-for-react';
import { Box } from '@mui/material';
import getAmountMonthsBack from 'helpers/getAmountMonthsBack';
import { SeriesChart } from 'components/CostsChart/interfaces';
import { useTranslation } from 'react-i18next';
import CostsTable from 'components/CostsChart/CostsTable/CostsTable';

function CostsChart() {
  const { t } = useTranslation();
  const { data: costChart = {} as CostCharts } = useGetCostsChartsQuery();
  const { groupedTransactions, costGroups, incomes } = costChart;
  const displayedPeriod = getAmountMonthsBack(new Date(), 24);
  const seriesData: SeriesChart[] = [];
  const defaultVat = 0.15;
  const monthlyIncomesValue = displayedPeriod.map(
    (period) => incomes[period] ?? 0,
  );
  const monthlyTransactionsValue = displayedPeriod.map((period) => {
    if (!groupedTransactions[period]) {
      return 0;
    }
    return +Object.values(groupedTransactions[period])
      .reduce((acc, value) => acc + Math.abs(value), 0)
      .toFixed(2);
  });
  const monthlyExpenseWithVat: number[] = [];
  for (let i = 0; i < displayedPeriod.length; i += 1) {
    const monthlyDifference =
      monthlyIncomesValue[i] - monthlyTransactionsValue[i];
    if (monthlyDifference < 0) {
      monthlyExpenseWithVat.push(0);
    } else {
      monthlyExpenseWithVat.push(
        +(monthlyTransactionsValue[i] + monthlyDifference * defaultVat).toFixed(
          2,
        ),
      );
    }
  }
  seriesData.push({
    name: t('label.total'),
    type: 'line',
    areaStyle: { opacity: 0.3 },
    symbol: 'circle',
    symbolSize: 7,
    step: 'middle',
    z: 1,
    data: monthlyTransactionsValue,
  });
  seriesData.push({
    name: t('label.income'),
    type: 'line',
    areaStyle: { opacity: 0.3 },
    symbol: 'circle',
    symbolSize: 7,
    step: 'middle',
    z: 1,
    data: monthlyIncomesValue,
  });
  seriesData.push({
    name: t('label.with_vat'),
    type: 'line',
    areaStyle: { opacity: 0.3 },
    symbol: 'circle',
    symbolSize: 7,
    step: 'middle',
    z: 1,
    data: monthlyExpenseWithVat,
  });
  Object.keys(costGroups).forEach((costGroup) => {
    const values: number[] = [];
    displayedPeriod.forEach((month) => {
      if (
        !groupedTransactions[month] ||
        !groupedTransactions[month][costGroup]
      ) {
        values.push(0);
      } else {
        values.push(Math.abs(groupedTransactions[month][costGroup]));
      }
    });
    seriesData.push({
      name: costGroups[costGroup].name,
      type: 'bar',
      stack: 'stack',
      z: 2,
      data: values,
    });
  });
  const chartOptions = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '5%',
      bottom: '5%',
    },
    xAxis: {
      axisTick: {
        alignWithLabel: true,
      },
      type: 'category',
      data: displayedPeriod,
    },
    yAxis: {
      type: 'value',
    },
    series: seriesData,
  };

  return (
    <>
      <Box m={2} bgcolor="main.white" borderRadius={4} height="75vh">
        <ReactEChart
          option={chartOptions}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <CostsTable displayedPeriod={displayedPeriod} />
    </>
  );
}

export default CostsChart;
