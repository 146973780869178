import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PipeDriveLeads } from 'store/pipeDriveLead/interfaces';
import { useTranslation } from 'react-i18next';

interface LeadsDataProps {
  leadsInfo: PipeDriveLeads[];
}

function LeadsData({ leadsInfo }: LeadsDataProps) {
  const { t } = useTranslation();
  const headers = ['label.title', 'label.type', 'id'];

  return (
    <TableContainer sx={{ px: 1.5, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {leadsInfo.map((lead) => (
            <TableRow key={lead.id}>
              <TableCell>
                <Typography variant="body2">{lead.title}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{lead.type}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{lead.id}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LeadsData;
