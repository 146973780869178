/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {
  agreementType,
  agreementVariant,
} from 'components/AddAgreementForm/selectData';
import { AgreementsDetails } from 'components/AddAgreementForm/interfaces';
import { useTranslation } from 'react-i18next';

function AgreementDetails({
  errors,
  type,
  variant,
  setType,
  setVariant,
  handleChange,
}: AgreementsDetails) {
  const { t } = useTranslation();

  return (
    <>
      <FormControl error={errors.type} required fullWidth>
        <InputLabel id="type-select">
          {t('label.type')}
        </InputLabel>
        <Select
          id="type-select"
          value={type}
          label={t('label.type')}
          onChange={(event) => handleChange(event, setType, 'type')}
        >
          {agreementType.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        {errors.type && (
          <FormHelperText>{t('errors.field_required')}</FormHelperText>
        )}
      </FormControl>
      <FormControl error={errors.variant} required fullWidth>
        <InputLabel id="variant-select">{t('agreements.variant')}</InputLabel>
        <Select
          id="variant-select"
          value={variant}
          label={t('agreements.variant')}
          onChange={(event) => handleChange(event, setVariant, 'variant')}
        >
          {agreementVariant.map((variant) => (
            <MenuItem key={variant} value={variant}>
              {variant}
            </MenuItem>
          ))}
        </Select>
        {errors.variant && (
          <FormHelperText>{t('errors.field_required')}</FormHelperText>
        )}
      </FormControl>
    </>
  );
}

export default AgreementDetails;
