import { useContext } from 'react';
import {
  BudgetPeriodContext,
  BudgetPeriodContextProps,
} from './BudgetPeriod.context';

function useBudgetPeriodContext(): BudgetPeriodContextProps {
  const context = useContext(BudgetPeriodContext);

  if (!context) {
    throw new Error(
      'useBudgetPeriodContext must be used within a BudgetPeriodProvider',
    );
  }

  return context;
}

export default useBudgetPeriodContext;
