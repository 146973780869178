import React from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import { Transaction } from 'store/transactions/transactions';
import { useTranslation } from 'react-i18next';

interface BalanceProps {
  transaction: Transaction;
  size: 'md' | 'lg';
}
const sizeMap = {
  md: 1,
  lg: 3,
};

function Balance({ transaction, size }: BalanceProps) {
  const { t } = useTranslation();

  const widthSize = sizeMap[size];

  return (
    <Grid
      item
      xs={widthSize}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        color={transaction.amount > 0 ? 'success.main' : 'error'}
        variant="bold"
      >
        {transaction.amount.toFixed(2)}
      </Typography>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'main.white',
              boxShadow: 5,
              color: 'text.primary',
            },
          },
        }}
        title={
          <Typography variant="body1">
            {t('transactions.ending_balance')}
          </Typography>
        }
      >
        <Typography variant="body1">
          {transaction.endingBalance.toFixed(2)}
        </Typography>
      </Tooltip>
    </Grid>
  );
}

export default Balance;
