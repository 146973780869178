import React, { useState } from 'react';
import {
  Box,
  Button,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'hooks/useValidate';
import { StatusProps } from 'hoc/Status/Status';
import { usePasswordSetupWithTokenMutation } from 'store/authorization/authorization';
import { useParams } from 'react-router-dom';
import checkPassword from 'helpers/validators/checkPassword';
import Link from 'common/Link/Link';
import Logo from 'assets/img/logo.svg';
import RouterHelper from 'helpers/RouterHelper';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { CustomError } from 'store/api';

export interface Errors {
  newPassword: boolean;
  passwordRepeated: boolean;
  token: boolean;
}

export interface PageResetWithTokenProps extends StatusProps {}

export default function ResetWithToken({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: PageResetWithTokenProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [isFormHidden, setIsFormHidden] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordRepeated, setPasswordRepeated] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const [setupWithToken, { isSuccess }] = usePasswordSetupWithTokenMutation();

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) ||
    !checkPassword(newPassword, passwordRepeated);

  const handleRegisterSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (!token) {
      return;
    }
    try {
      await setupWithToken({
        password: newPassword,
        confirm: passwordRepeated,
        token,
      }).unwrap();
      setIsSuccess(true);
      setIsFormHidden(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Grid container height="100vh" justifyContent="center">
      <Grid item xs={12} display="flex" justifyContent="center">
        <CardMedia
          component="img"
          loading="lazy"
          src={Logo}
          alt={t('menu.logo')}
          sx={{ width: { xs: '15rem', md: '30rem' }, objectFit: 'contain' }}
        />
      </Grid>
      <Grid item xs={10} md={3}>
        {!isFormHidden && (
          <form onSubmit={handleRegisterSubmit}>
            <FormControl fullWidth>
              <Box display="flex" flexDirection="column" rowGap={2.5}>
                <TextField
                  required
                  type={showPassword ? 'text' : 'password'}
                  id="new_password"
                  label={t('authorization.new_password_label')}
                  autoComplete="off"
                  defaultValue={newPassword}
                  error={errors.newPassword}
                  helperText={errors.newPassword && t('errors.password_error')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                        >
                          <RemoveRedEyeIcon fontSize="large" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => setNewPassword(event.target.value)}
                  onBlur={() =>
                    validate('newPassword', checkPassword(newPassword))
                  }
                />
                <TextField
                  required
                  type={showPassword ? 'text' : 'password'}
                  id="repeat_password"
                  label={t('authorization.repeat_password_label')}
                  autoComplete="off"
                  defaultValue={passwordRepeated}
                  error={errors.passwordRepeated}
                  helperText={
                    errors.passwordRepeated && t('errors.repeat_password_error')
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                        >
                          <RemoveRedEyeIcon fontSize="large" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => setPasswordRepeated(event.target.value)}
                  onBlur={() =>
                    validate(
                      'passwordRepeated',
                      checkPassword(newPassword, passwordRepeated),
                    )
                  }
                />
                <Box display="flex" justifyContent="center">
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={disableSubmission()}
                  >
                    {t('button.save')}
                  </Button>
                </Box>
                <Box display="flex" justifyContent="center">
                  <Typography variant="body1">
                    {t('authorization.return_to_login')}
                    <Link
                      to={RouterHelper.generate('route_login')}
                      underline="none"
                      pl={1}
                    >
                      {t('button.sign_in')}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </FormControl>
          </form>
        )}
        {isSuccess && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap={3}
          >
            <TaskAltIcon color="success" sx={{ fontSize: '10rem' }} />
            <Link to={RouterHelper.generate('route_login')} underline="none">
              <Typography variant="h5" align="center">
                {t('button.go_to_login_page')}
              </Typography>
            </Link>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
