import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useAddBankHolidayMutation } from 'store/bankHolidays/bankHolidays';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { Close, Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';

export interface AddHolidayFormProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddHolidayForm({ isOpen, setIsOpen }: AddHolidayFormProps) {
  const dispatch = useAppDispatch();

  const [date, setDate] = useState(dateFormat(new Date(), 'yyyy-mm-dd'));
  const [yearSymbol, setYearSymbol] = useState('*');
  const [addHoliday, { isLoading }] = useAddBankHolidayMutation();
  const { t } = useTranslation();

  const handleAddHoliday = async () => {
    const body = {
      date: yearSymbol.concat('-', dateFormat(date, 'mm-dd')),
    };
    try {
      await addHoliday(body).unwrap();
      dispatch(setSuccessStatus(true));
      setIsOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  const handleOnDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timestamp = Date.parse(event.target.value);
    if (Number.isNaN(timestamp)) {
      return;
    }
    setDate(event.target.value);
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} fullWidth>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <Typography variant="h5">{t('bank_holidays.add_new')}</Typography>
          <IconButton
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              bgcolor: 'secondary.light',
              width: '4rem',
              height: '4rem',
              borderRadius: '50%',
            }}
          >
            <Close fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <TextField
            type="date"
            id="date"
            value={date}
            onChange={handleOnDateChange}
            fullWidth
          />
          <Select
            labelId="repeatable-event"
            id="repeatable-event"
            value={yearSymbol}
            onChange={(event) => setYearSymbol(event.target.value)}
            sx={{ marginY: '2.4rem' }}
          >
            <MenuItem value="*">{t('bank_holidays.repeatable')}</MenuItem>
            <MenuItem value={dateFormat(new Date(date), 'yyyy')}>
              {t('bank_holidays.not_repeatable')}
            </MenuItem>
          </Select>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            endIcon={<Add />}
            loading={isLoading}
            onClick={handleAddHoliday}
            variant="contained"
          >
            <span>{t('button.save')}</span>
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddHolidayForm;
