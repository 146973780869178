import { getCollectionBalance } from 'common/ConditionBalance/utils';
import { ConditionProperties } from 'enums';
import ConditionSections from 'pages/Condition/enums';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const useConditionBalance = (month: string) => {
  const conditionBalances = useSelector((state: RootState) => state.balance);
  const projectsBalance = getCollectionBalance(
    ConditionProperties.projects,
    conditionBalances,
    month,
  );
  const holidaysBalance = getCollectionBalance(
    ConditionProperties.holidays,
    conditionBalances,
    month,
  );
  const requiredCostsBalance = getCollectionBalance(
    ConditionSections.requiredCosts,
    conditionBalances,
    month,
  );
  const internalProjectsBalance = getCollectionBalance(
    ConditionSections.internalProjects,
    conditionBalances,
    month,
  );
  const necessaryCostsBalance = getCollectionBalance(
    ConditionSections.necessaryCosts,
    conditionBalances,
    month,
  );
  const nonBillableProjectsBalance = getCollectionBalance(
    ConditionSections.nonBillableProjects,
    conditionBalances,
    month,
  );
  const slightlyCostsBalance = getCollectionBalance(
    ConditionSections.slightCosts,
    conditionBalances,
    month,
  );
  const startBalance = projectsBalance - holidaysBalance;

  const balances = [
    {
      property: ConditionSections.requiredCosts,
      value: requiredCostsBalance,
    },
    {
      property: ConditionSections.internalProjects,
      value: internalProjectsBalance,
    },
    {
      property: ConditionSections.necessaryCosts,
      value: necessaryCostsBalance,
    },
    {
      property: ConditionSections.nonBillableProjects,
      value: nonBillableProjectsBalance,
    },
    {
      property: ConditionSections.slightCosts,
      value: slightlyCostsBalance,
    },
  ];

  return { balances, startBalance };
};

export default useConditionBalance;
