import { Typography, FormControl, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useCandidateContext from 'pages/HumanResourceCandidate/context/useCandidateContext';
import CheckIcon from '@mui/icons-material/Check';
import { zodResolver } from '@hookform/resolvers/zod';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import { useForm } from 'react-hook-form';
import { useUpdateCandidateMutation } from 'store/candidates/candidates';
import { CustomError } from 'store/api';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import { LoadingButton } from '@mui/lab';
import schema from './ResumeForm.schema';
import { FormPayload, ResumeFormProps } from './interfaces';
import ResumeUpload from './ResumeUpload';

const validate = zodResolver(schema);

export default function ResumeForm({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: ResumeFormProps) {
  const { t } = useTranslation();
  const { candidate } = useCandidateContext();
  const initFormValues = {
    linkedinProfile: candidate?.linkedinProfile || '',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onBlur',
    defaultValues: initFormValues,
  });
  const [updateCandidate, { isLoading: candidateUpdateLoading }] =
    useUpdateCandidateMutation();

  const isFormValuesChanged = !!Object.keys(dirtyFields).length;

  const handleUpdateCandidate = async (data: FormPayload) => {
    try {
      const payload = {
        ...data,
        id: candidate.id,
      };
      await updateCandidate(payload).unwrap();
      reset(data);
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <StyledContentBox p={3}>
      <Typography variant="h6">{t('human_resources.resume')}</Typography>
      <form onSubmit={handleSubmit(handleUpdateCandidate)} noValidate>
        <FormControl
          fullWidth
          sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 4 }}
        >
          <ControlTextField
            control={control}
            errors={errors}
            name="linkedinProfile"
            label="linkedIn"
          />
          <ResumeUpload />
        </FormControl>
        {isFormValuesChanged && (
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              loading={candidateUpdateLoading}
              endIcon={<CheckIcon />}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save_changes')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </StyledContentBox>
  );
}
