const roles = {
  accountancy: ['ROLE_ACCOUNTANCY_VIEWER'],
  admin: ['ROLE_ADMIN'],
  agreements: ['ROLE_AGREEMENTS_MANAGER'],
  banksManager: ['ROLE_BANKS_MANAGER'],
  bankHolidays: ['ROLE_HOLIDAYS_MANAGER'],
  banks: ['ROLE_BANKS_MANAGER'],
  benefitsManager: ['ROLE_BENEFITS_MANAGER'],
  benefitsViewer: ['ROLE_BENEFITS_VIEWER'],
  budgetManager: ['ROLE_BUDGETS_MANAGER'],
  clients: ['ROLE_CLIENTS_MANAGER'],
  debtCollection: ['ROLE_DEBT_COLLECTIONS_MANAGER'],
  contractors: ['ROLE_COSTS_MANAGER'],
  costs: ['ROLE_ACCOUNT_MANAGER', 'ROLE_BANKS_MANAGER'],
  costsAnalyser: ['ROLE_COSTS_ANALYSER'],
  countries: ['ROLE_COUNTRIES_MANAGER'],
  currencies: ['ROLE_CURRENCIES_MANAGER'],
  documents: ['ROLE_DOCUMENTS_MANAGER'],
  documentsViewer: ['ROLE_DOCUMENTS_VIEWER'],
  employees: ['ROLE_EMPLOYEES_MANAGER'],
  employeesViewer: ['ROLE_EMPLOYEES_VIEWER'],
  feedbackManager: ['ROLE_FEEDBACK_MANAGER'],
  holidays: ['ROLE_HOLIDAYS_MANAGER', 'ROLE_HOLIDAYS_VERIFICATOR'],
  holidaysManager: ['ROLE_HOLIDAYS_MANAGER'],
  invoices: ['ROLE_INCOMES_MANAGER'],
  locations: ['ROLE_LOCATIONS_MANAGER'],
  meetingManager: ['ROLE_MEETING_MANAGER'],
  paymentDue: ['ROLE_PAYMENT_DUE_VIEWER'],
  pipeDrive: ['ROLE_PIPEDRIVE_MANAGER'],
  positions: ['ROLE_POSITIONS_MANAGER'],
  projects: ['ROLE_PROJECTS_MANAGER'],
  properties: ['ROLE_PROPERTIES_MANAGER'],
  responsibilitiesManager: ['ROLE_RESPONSIBILITIES_MANAGER'],
  rolesManager: ['ROLE_ROLES_MANAGER'],
  salesManager: ['ROLE_SALES_MANAGER'],
  transactionsManager: ['ROLE_TRANSACTIONS_MANAGER'],
  user: ['ROLE_USER'],
  workingHoursManager: ['ROLE_WORKING_HOURS_MANAGER'],
  workingHoursViewer: ['ROLE_WORKING_HOURS_VIEWER'],
  humanResources: ['ROLE_CANDIDATES_MANAGER'],
  organizationUsers: ['ROLE_ORGANIZATION_USERS_MANAGER'],
};

export default roles;
