import { Stack, StackProps, styled } from '@mui/material';

const StyledPreviewWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'transparent',
  borderRadius: '1.6rem',
  justifyContent: 'flex-start',
  border: `0.1rem solid ${theme.palette.border.veryLight}`,
}));

export default StyledPreviewWrapper;
