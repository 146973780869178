import { combineReducers } from 'redux';
import status from 'store/status/reducers';
import authorization from 'store/authorization/reducers';
import organization from 'store/organizations/reducers';
import api from 'store/api';
import app from 'store/app/reducer';
import balance from 'store/condition/reducers';

export default combineReducers({
  [api.reducerPath]: api.reducer,
  app,
  status,
  authorization,
  organization,
  balance,
});
