/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useState } from 'react';

export function useValidate<T>() {
  const [errors, setErrors] = useState<T>({} as T);
  const validate = (field: keyof T, condition: boolean) => {
    if (!condition) {
      setErrors({ ...errors, [field]: true } as T);
    } else {
      setErrors({ ...errors, [field]: false } as T);
    }
  };
  return { errors, validate };
}
