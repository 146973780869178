import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import DefaultAnnualLeaveList from 'components/DefaultAnnualLeaveList/DefaultAnnualLeaveList';

export default function DefaultAnnualLeave() {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={10} bgcolor="background.list">
      <NavBarContainer>
        <NavBarTitle>{t('settings.default_annual_leave')}</NavBarTitle>
      </NavBarContainer>
      <DefaultAnnualLeaveList />
    </Grid>
  );
}
