import React from 'react';
import { IconButton } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { useTranslation } from 'react-i18next';

interface TooltipIconButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  translationKey: string;
  icon: React.ReactNode;
}

export default function TooltipIconButton({
  translationKey,
  onClick,
  icon,
}: TooltipIconButtonProps) {
  const { t } = useTranslation();

  return (
    <TitleTooltip
      title={t(translationKey)}
    >
      <IconButton
        onClick={onClick}
        sx={{
          bgcolor: 'secondary.light',
          width: '3.2rem',
          height: '3.2rem',
          borderRadius: '0.8rem',
        }}
      >
        {icon}
      </IconButton>
    </TitleTooltip>
  );
}
