import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import AccountancyList from 'components/AccountancyList/AccountancyList';

export default function Accountancy() {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={10} bgcolor="main.white">
      <NavBarContainer>
        <NavBarTitle>{t('menu.accountancy')}</NavBarTitle>
      </NavBarContainer>
      <AccountancyList />
    </Grid>
  );
}
