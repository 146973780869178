import React from 'react';
import { createSvgIcon } from '@mui/material';

const UserIcon = createSvgIcon(
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.24"
      d="M16.5 8C16.5 10.4853 14.4853 12.5 12 12.5C9.51472 12.5 7.5 10.4853 7.5 8C7.5 5.51472 9.51472 3.5 12 3.5C14.4853 3.5 16.5 5.51472 16.5 8Z"
      fill="#613CEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.5C10.067 4.5 8.50004 6.067 8.50004 8C8.50004 9.933 10.067 11.5 12 11.5C13.933 11.5 15.5 9.933 15.5 8C15.5 6.067 13.933 4.5 12 4.5ZM6.50004 8C6.50004 4.96243 8.96247 2.5 12 2.5C15.0376 2.5 17.5 4.96243 17.5 8C17.5 11.0376 15.0376 13.5 12 13.5C8.96247 13.5 6.50004 11.0376 6.50004 8ZM9.88428 15.5C9.9223 15.5 9.96088 15.5 10 15.5H14C14.0392 15.5 14.0778 15.5 14.1158 15.5C14.9334 15.4998 15.4907 15.4996 15.9755 15.5961C17.9589 15.9906 19.5094 17.5411 19.904 19.5245C20.0004 20.0093 20.0003 20.5666 20.0001 21.3842C20 21.4223 20 21.4608 20 21.5C20 22.0523 19.5523 22.5 19 22.5C18.4478 22.5 18 22.0523 18 21.5C18 20.5228 17.9952 20.1802 17.9424 19.9147C17.7057 18.7247 16.7754 17.7944 15.5853 17.5576C15.3199 17.5048 14.9773 17.5 14 17.5H10C9.0228 17.5 8.68021 17.5048 8.41477 17.5576C7.22469 17.7944 6.2944 18.7247 6.05768 19.9147C6.00488 20.1802 6.00004 20.5228 6.00004 21.5C6.00004 22.0523 5.55232 22.5 5.00004 22.5C4.44775 22.5 4.00004 22.0523 4.00004 21.5C4.00004 21.4608 4.00003 21.4223 4.00002 21.3842C3.99982 20.5666 3.99968 20.0094 4.09611 19.5245C4.49064 17.5411 6.04113 15.9906 8.02459 15.5961C8.50939 15.4996 9.06668 15.4998 9.88428 15.5Z"
      fill="#613CEF"
    />
  </svg>,
  'UserIcon',
);

export default UserIcon;
