import { styled, TableCell, TableCellProps } from '@mui/material';

interface StyledCellProps extends TableCellProps {
  isActive?: boolean;
}

const StyledCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<StyledCellProps>(({ isActive, theme }) => ({
  background: isActive
    ? theme.palette.primary.light
    : theme.palette.background.list,
}));

export default StyledCell;
