import { Budget } from 'store/budget/interfaces';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import { Transaction } from 'store/transactions/transactions';

function calculateSums(transactions: Transaction[]): {
  amount: string;
  targetAmount: string;
} {
  if (!transactions || transactions.length === 0) {
    return { amount: '', targetAmount: '' };
  }
  const totals = transactions.reduce(
    (sums, transaction) => ({
      amount: sums.amount + transaction.amount,
      targetAmount: sums.targetAmount + transaction.targetAmount,
    }),
    { amount: 0, targetAmount: 0 },
  );

  return {
    amount: totals.amount.toFixed(2),
    targetAmount: totals.targetAmount.toFixed(2),
  };
}

export default function getMergedBudgetCollections(budget: Budget) {
  const unifiedBudgetContractors = budget?.budgetContractors.map(
    (contractor) => {
      const { createdAt, name, id } = contractor.contractor;
      const { amount, targetAmount } = calculateSums(contractor.transactions);
      return {
        type: 'contractor',
        description: name,
        execDate: createdAt,
        amount,
        targetAmount,
        employee: '',
        contractor: name,
        project: '',
        id: `contractor-${id}`,
        currency: '',
      };
    },
  );

  const unifiedBudgetProjects = budget?.budgetProjects.map((project) => {
    const { name, id, createdAt } = project.project;
    const amount = Object.values(project.data)
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2)
      .toString();
    return {
      type: 'project',
      description: name,
      execDate: createdAt,
      amount,
      targetAmount: amount,
      employee: '',
      contractor: '',
      project: name,
      id: `project-${id}`,
      currency: '',
    };
  });

  const unifiedBudgetTransactions = budget?.budgetTransactions.map(
    (transaction) => {
      const { description, execDate, targetAmount, amount, id, currency } =
        transaction.transaction;

      const transactionTargetAmount = transaction.targetAmount
        ? transaction.targetAmount / BACKEND_AMOUNT_MODIFIER
        : targetAmount;

      return {
        type: 'transaction',
        description,
        execDate,
        amount: amount.toString(),
        targetAmount: transactionTargetAmount.toString(),
        contractor: '',
        employee: transaction?.employee || '',
        project: '',
        id: `transaction-${id}`,
        currency,
      };
    },
  );

  return [
    ...unifiedBudgetProjects,
    ...unifiedBudgetContractors,
    ...unifiedBudgetTransactions,
  ];
}
