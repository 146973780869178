import api from 'store/api';
import {
  ResourceRequestsData,
  ResourceRequest,
  ResourceRequestMutationRequest,
  ResourceRequestFilters,
} from './interfaces';

export const resourceRequestsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getResourceRequests: builder.query<ResourceRequestsData, Partial<ResourceRequestFilters>>({
      query: (params) => ({
        url: '/resource-requests',
        params
      }),
      transformResponse: (response: ResourceRequestsData) => ({
        ...response,
        'hydra:member': response['hydra:member']
          .sort(
          (resourceRequest, comparedResourceRequest) =>
            new Date(comparedResourceRequest.createdAt).getTime() -
            new Date(resourceRequest.createdAt).getTime(),
        ),
      }),
      providesTags: ['ResourceRequests'],
    }),
    getResourceRequest: builder.query<ResourceRequest, string>({
      query: (id) => ({ url: `/resource-requests/${id}` }),
      providesTags: ['ResourceRequests'],
    }),
    addResourceRequest: builder.mutation<
      ResourceRequest,
      ResourceRequestMutationRequest
    >({
      query: (payload) => ({
        url: '/resource-requests',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        const { data: createdResourceRequest } = await queryFulfilled;
        const resourceRequestsCache = api.util.selectInvalidatedBy(getState(), [
          { type: 'ResourceRequests' },
        ]);
        resourceRequestsCache
          .filter(({ endpointName }) => endpointName === 'getResourceRequests')
          .forEach(({ originalArgs }) => {
            dispatch(
              resourceRequestsApi.util.updateQueryData(
                'getResourceRequests',
                originalArgs,
                (draft) => {
                  draft?.['hydra:member']?.unshift(createdResourceRequest);
                },
              ),
            );
          });
      },
    }),
    updateResourceRequest: builder.mutation<
      ResourceRequest,
      ResourceRequestMutationRequest & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/resource-requests/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        const { data: updatedResourceRequest } = await queryFulfilled;
        const resourceRequestsCache = api.util.selectInvalidatedBy(getState(), [
          { type: 'ResourceRequests' },
        ]);
        resourceRequestsCache
          .filter(({ endpointName }) => endpointName === 'getResourceRequests')
          .forEach(({ originalArgs }) => {
            dispatch(
              resourceRequestsApi.util.updateQueryData(
                'getResourceRequests',
                originalArgs,
                (draft) => {
                  const resourceRequests = draft?.['hydra:member'];

                  const resourceRequestIndex = resourceRequests?.findIndex(
                    (item: ResourceRequest) => item.id === id,
                  );
                  if (resourceRequestIndex !== -1) {
                    resourceRequests?.splice(
                      resourceRequestIndex,
                      1,
                      updatedResourceRequest,
                    );
                  }
                },
              ),
            );
          });

        resourceRequestsCache
          .filter(
            ({ endpointName, originalArgs }) =>
              endpointName === 'getResourceRequest' && originalArgs === id,
          )
          .forEach(({ originalArgs }) => {
            dispatch(
              resourceRequestsApi.util.updateQueryData(
                'getResourceRequest',
                originalArgs,
                () => updatedResourceRequest,
              ),
            );
          });
      },
    }),
  }),
});

export const {
  useGetResourceRequestsQuery,
  useGetResourceRequestQuery,
  useAddResourceRequestMutation,
  useUpdateResourceRequestMutation,
} = resourceRequestsApi;
