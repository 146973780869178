import { Stack, StackProps, styled } from '@mui/material';

const StyledUploadFileWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  backgroundColor: theme.palette.main.white,
  borderRadius: '0.4rem',
  width: '100%',
  height: '32rem',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: `0.1rem dashed ${theme.palette.border.veryLight}`,
}));

export default StyledUploadFileWrapper;
