import React, { useState } from 'react';
import { Dialog, Grid } from '@mui/material';
import BanksList from 'components/BanksList/BanksList';
import { useTranslation } from 'react-i18next';
import EmptyBanksList from 'components/EmptyBanksList/EmptyBanksList';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddBankForm from 'components/AddBankForm';
import { useGetBanksQuery } from 'store/bank/bank';
import AddButton from 'common/AddButton/AddButton';

export default function Banks() {
  const [isAddBankOpen, setIsAddBankOpen] = useState(false);

  const { t } = useTranslation();
  const {
    data: banks = [],
    isError: banksError,
    isLoading: banksLoading,
  } = useGetBanksQuery();

  const noBanks = !banks.length && !banksLoading;

  return (
    <Grid item xs={10} bgcolor="background.main">
      <NavBarContainer>
        <NavBarTitle>{t('banks.header')}</NavBarTitle>
        {!noBanks && !banksLoading && (
          <AddButton
            label="banks.add_bank"
            onClick={() => setIsAddBankOpen(!isAddBankOpen)}
          />
        )}
      </NavBarContainer>
      {noBanks ? (
        <EmptyBanksList setIsAddBankOpen={setIsAddBankOpen} />
      ) : (
        <BanksList
          banks={banks}
          banksError={banksError}
          banksLoading={banksLoading}
        />
      )}
      <Dialog
        open={isAddBankOpen}
        onClose={() => setIsAddBankOpen(!isAddBankOpen)}
        fullWidth
      >
        <AddBankForm setIsAddOpen={setIsAddBankOpen} />
      </Dialog>
    </Grid>
  );
}
