import React, { useState, useRef } from 'react';
import {
  Stack,
  TextField,
  Autocomplete,
  IconButton,
  InputAdornment,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGetCandidatesByPhraseQuery } from 'store/candidates/candidates';
import CandidateAutocompletePopper from 'components/CandidateAutocompletePopper/CandidateAutocompletePopper';
import AutocompleteOption from './AutocompleteOption/AutocompleteOption';

interface ResourceCandidateAutocompleteProps {
  handleAddResourceRequestCandidate: (candidateIri: string) => Promise<void>;
}

export default function ResourceCandidateAutocomplete({
  handleAddResourceRequestCandidate,
}: ResourceCandidateAutocompleteProps) {
  const { t } = useTranslation();
  const [searchPhrase, setSearchPhrase] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const shouldFetchCandidates = searchPhrase.length >= 2;

  const {
    data: candidatesDataByPhrase,
    isFetching: candidatesDataByPhraseFetching,
  } = useGetCandidatesByPhraseQuery(searchPhrase, {
    skip: !shouldFetchCandidates,
  });

  const handleClearInput = () => {
    setSearchPhrase('');
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setSearchPhrase(event.target.value);

  const handleOnOptionButtonClick = async (candidateIri: string) => {
    await handleAddResourceRequestCandidate(candidateIri);
    handleClearInput();
  };

  const handleSearchPhrase = debounce(handleChangeInput, 250);

  const candidatesOptions =
    candidatesDataByPhrase?.['hydra:member'] && shouldFetchCandidates
      ? candidatesDataByPhrase['hydra:member']
      : [];

  return (
    <Stack spacing={2} p={3} bgcolor="main.white">
      <Autocomplete
        id="resource-candidate-autocomplete"
        sx={{
          width: '36rem',
        }}
        freeSolo
        disableClearable
        filterOptions={(options) => options}
        loading={candidatesDataByPhraseFetching}
        options={candidatesOptions}
        ref={inputRef}
        value={searchPhrase}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            return;
          }
          handleClearInput();
        }}
        PopperComponent={CandidateAutocompletePopper}
        renderOption={(props, candidateOption) => (
          <Box component="li" {...props} key={candidateOption.id}>
            <AutocompleteOption
              candidateOption={candidateOption}
              onClick={handleOnOptionButtonClick}
            />
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleSearchPhrase}
            label={t('label.search')}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClearInput}
                    sx={{
                      width: '3rem',
                      height: '3rem',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
}
