import React, { useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import { RangeConditions } from 'store/condition/interfaces';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import { StyledAccordion } from 'components/ResponsibilityGroup/Accordion.styled';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import Employee from './Employee/Employee';
import StyledBadge from './Badge.styled';
import {
  calculateMonthlyBalance,
  calculatePropertySum,
  getChipColor,
} from '../utils';

interface ProjectProps {
  rangeConditions: RangeConditions;
  projectId: number;
  dateRange: string[];
}

export default function Project({
  rangeConditions,
  projectId,
  dateRange,
}: ProjectProps) {
  const [expanded, setExpanded] = useState(false);
  const { data: projects = [] } = useGetProjectsPreviewQuery({});
  const formatAmount = useCurrencyFormat();

  const projectEmployees = [
    ...new Set(
      Object.values(rangeConditions).flatMap((projectsCondition) =>
        Object.keys(projectsCondition[projectId] || {}),
      ),
    ),
  ];

  const getProjectName = (id: number) => {
    const projectData = projects.find((project) => project.id === id);

    if (!projectData) {
      return '';
    }

    return projectData.name;
  };

  const getPropertySum = (month: string, property: string) =>
    calculatePropertySum(rangeConditions, month, property, projectId);

  const getMonthBalance = (month: string) =>
    calculateMonthlyBalance(
      getPropertySum(month, 'cost'),
      getPropertySum(month, 'income'),
    );

  const calculateBalancePercent = (range: string) => {
    const incomesSum = getPropertySum(range, 'income');
    const costsSum = getPropertySum(range, 'cost');

    if (costsSum === 0) {
      return 0;
    }

    if (incomesSum === 0) {
      return -100;
    }

    if (costsSum > incomesSum) {
      return ((costsSum * -100) / incomesSum).toFixed(0);
    }

    return ((incomesSum * 100) / costsSum).toFixed(0);
  };

  return (
    <Grid item xs={13}>
      <StyledAccordion disableGutters expanded={expanded} square>
        <AccordionSummary
          onClick={() => setExpanded((prevState) => !prevState)}
          sx={{ px: 0, m: 0 }}
        >
          <Grid container columns={13} alignItems="center">
            <Grid item xs={1}>
              <Typography variant="body2" pl={1}>
                {getProjectName(projectId)}
              </Typography>
            </Grid>
            {dateRange.map((range) => (
              <React.Fragment key={range}>
                <Grid item xs={0.9}>
                  <Typography variant="smallBody">
                    {formatAmount(getPropertySum(range, 'cost'))}
                  </Typography>
                </Grid>
                <Grid item xs={0.9}>
                  <Typography variant="smallBody">
                    {formatAmount(getPropertySum(range, 'income'))}
                  </Typography>
                </Grid>
                <Grid item xs={1.2}>
                  <StyledBadge
                    color={getChipColor(range, getMonthBalance(range))}
                    badgeContent={
                      <Typography variant="smallBody">{`${calculateBalancePercent(
                        range,
                      )}%`}</Typography>
                    }
                  >
                    <Chip
                      color={getChipColor(range, getMonthBalance(range))}
                      label={
                        <Typography variant="chipLabel">
                          {formatAmount(getMonthBalance(range))}
                        </Typography>
                      }
                      size="small"
                    />
                  </StyledBadge>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            px: 0,
            py: 1,
            bgcolor: 'secondary.light',
          }}
        >
          {projectEmployees.map((employee) => (
            <Employee
              key={employee}
              dateRange={dateRange}
              employeeId={+employee}
              projectId={projectId}
              rangeConditions={rangeConditions}
            />
          ))}
        </AccordionDetails>
      </StyledAccordion>
    </Grid>
  );
}
