import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'label.name',
    align: 'left',
  },
  {
    label: 'invoices.quantity',
    align: 'left',
  },
  {
    label: 'invoices.units',
    align: 'left',
  },
  {
    label: 'invoices.net_price',
    align: 'right',
  },
  {
    label: 'invoices.net_value',
    align: 'right',
  },
  {
    label: 'VAT',
    align: 'right',
  },
  {
    label: 'invoices.total_vat',
    align: 'right',
  },
  {
    label: 'invoices.gross_value',
    align: 'right',
  },
];

export default tableHeaders;
