import { Grid, Typography } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import React from 'react';
import { TaxesCondition } from 'store/condition/interfaces';
import { Cost, useGetCostsQuery } from 'store/costs/costs';

interface ContractorTaxesProps {
  contractorId: string;
  dateRange: string[];
  taxes: TaxesCondition;
}

const emptyContractors: Cost[] = [];

export default function ContractorTaxes({
  contractorId,
  dateRange,
  taxes,
}: ContractorTaxesProps) {
  const { contractor } = useGetCostsQuery({}, {
    selectFromResult: ({ data }) => ({
      contractor:
        data?.filter((item) => item.id === +contractorId) ?? emptyContractors,
    }),
  });
  const formatAmount = useCurrencyFormat();

  const getContractorData = (month: string) => {
    const costAmount = taxes[month]?.[contractorId]?.cost ?? 0;

    return formatAmount(costAmount);
  };

  return (
    <Grid container columns={13}>
      <Grid item xs={2.8}>
        <Typography variant="h6">{contractor[0]?.name}</Typography>
      </Grid>
      {dateRange.map((month) => (
        <React.Fragment key={month}>
          <Grid item xs={1.2}>
            <Typography variant="smallBody">
              {getContractorData(month)}
            </Typography>
          </Grid>
          <Grid item xs={1.8} />
        </React.Fragment>
      ))}
    </Grid>
  );
}
