import React from 'react';
import { StatusProps } from 'hoc/Status/Status';
import { EmployeeParticipant } from 'store/employee/interfaces';
import {
  Avatar,
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export interface ResultProps extends StatusProps {
  participant: EmployeeParticipant;
  handleDelete: (participant: EmployeeParticipant) => void;
}

export default function Result({ participant, handleDelete }: ResultProps) {
  return (
    <TableRow key={participant.id}>
      <TableCell>
        <Box display="flex" alignItems="center" columnGap={2}>
          <Avatar src={participant.avatarUrl} />
          <Typography variant="body2">
            {participant.lastname} {participant.firstname}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="right">
        <IconButton color="error" onClick={() => handleDelete(participant)}>
          <DeleteIcon fontSize="large" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
