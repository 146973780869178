import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import store from 'store';
import { holidaysApi } from 'store/holidays/holidays';
import { Holiday } from 'store/holidays/interfaces';
import { useAppDispatch } from 'store/hooks';

const useHolidayEvent = () => {
  const dispatch = useAppDispatch();

  const handleHolidaysUpdate = (
    data: Holiday,
    holidays: MaybeDrafted<Holiday[]>,
  ) => {
    const changedHolidayIndex = holidays.findIndex(
      (holiday) => holiday.id === data.id,
    );

    if (changedHolidayIndex === -1) {
      return [...holidays, data].sort(
        (holiday, comparedHoliday) =>
          new Date(comparedHoliday.dateFrom).getTime() -
          new Date(holiday.dateFrom).getTime(),
      );
    }

    const updatedHolidays = [...holidays];
    updatedHolidays.splice(changedHolidayIndex, 1, data);

    return updatedHolidays;
  };

  const handleHolidaysServerEvents = (data: Holiday) => {
    const cachedArgs = holidaysApi.util.selectInvalidatedBy(store.getState(), [
      { type: 'Holidays' },
    ]);

    cachedArgs.forEach((args) => {
      dispatch(
        holidaysApi.util.updateQueryData(
          'getHolidays',
          args.originalArgs,
          (draft) => ({
            ...draft,
            'hydra:member': handleHolidaysUpdate(data, draft['hydra:member']),
          }),
        ),
      );
    });
  };

  return handleHolidaysServerEvents;
};

export default useHolidayEvent;
