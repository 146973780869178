import React from 'react';
import { createSvgIcon } from '@mui/material';

const CheckSmallIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7861 6.94262C14.4782 6.60053 13.9513 6.57279 13.6092 6.88068L14.7861 6.94262ZM13.6092 6.88068L8.61112 11.379L13.6092 6.88068Z"
      fill="currentColor"
    />
    <path
      d="M14.7241 8.1195C15.0662 7.81162 15.094 7.28471 14.7861 6.94262C14.4782 6.60053 13.9513 6.57279 13.6092 6.88068L8.61112 11.379L6.39081 9.38068C6.04872 9.07279 5.52181 9.10053 5.21393 9.44262C4.90605 9.78471 4.93378 10.3116 5.27587 10.6195L7.77491 12.8686C8.25029 13.2965 8.97195 13.2965 9.44732 12.8686L14.7241 8.1195Z"
      fill="currentColor"
    />
  </svg>,
  'CheckSmallIcon',
);

export default CheckSmallIcon;
