export default function getProgressChartTheme(percentageValue: number) {
  switch (percentageValue) {
    case 100:
      return {
        color: 'success.main',
        borderColor: 'border.successOutlined',
      };
    case 0:
      return {
        color: 'error.main',
        borderColor: 'border.errorOutlined',
      };
    default:
      return {
        color: 'warning.main',
        borderColor: 'warning.light',
      };
  }
}
