import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  Box,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import checkEmail from 'helpers/validators/checkEmail/checkEmail';
import checkPassword from 'helpers/validators/checkPassword';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'hooks/useValidate';
import { useRegisterUserMutation } from 'store/register/register';
import { CustomError } from 'store/api';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { StatusProps } from 'hoc/Status/Status';

export interface Errors {
  organization: boolean;
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  password: boolean;
}

export interface PageRegisterProps extends StatusProps {
  isDemo: boolean;
}

export default function RegistrationForm({
  setIsError,
  setErrorMessage,
  isDemo,
}: PageRegisterProps) {
  const [isFormHidden, setIsFormHidden] = useState(false);
  const [organization, setOrganization] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();
  const [register, { isSuccess }] = useRegisterUserMutation();

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) ||
    !organization ||
    !firstName ||
    !lastName ||
    !checkEmail(email) ||
    !checkPassword(password);

  const handleRegisterSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await register({
        organizationName: organization,
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        demo: isDemo,
      }).unwrap();
      setIsFormHidden(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Grid item xs={10}>
      {!isFormHidden && (
        <form onSubmit={handleRegisterSubmit}>
          <FormControl fullWidth>
            <Box display="flex" flexDirection="column" rowGap={2.5} mt={8}>
              <TextField
                required
                id="organization"
                label={t('register.organization_name_label')}
                autoComplete="off"
                defaultValue={organization}
                error={errors.organization}
                helperText={
                  errors.organization && t('authorization.username_error')
                }
                onChange={(event) => setOrganization(event.target.value)}
              />
              <TextField
                required
                id="firstName"
                label={t('label.firstname')}
                autoComplete="off"
                defaultValue={firstName}
                error={errors.firstName}
                helperText={
                  errors.firstName && t('authorization.username_error')
                }
                onChange={(event) => setFirstName(event.target.value)}
              />
              <TextField
                required
                id="lastName"
                label={t('label.lastname')}
                autoComplete="off"
                defaultValue={lastName}
                error={errors.lastName}
                helperText={
                  errors.lastName && t('authorization.username_error')
                }
                onChange={(event) => setLastName(event.target.value)}
              />
              <TextField
                required
                id="e-mail"
                label={t('register.email_label')}
                autoComplete="off"
                defaultValue={email}
                error={errors.email}
                helperText={errors.email && t('errors.email_error')}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={() => validate('email', checkEmail(email))}
              />
              <TextField
                type="password"
                required
                id="password"
                label={t('register.password_label')}
                autoComplete="off"
                defaultValue={password}
                error={errors.password}
                helperText={errors.password && t('errors.password_error')}
                onChange={(event) => setPassword(event.target.value)}
                onBlur={() => validate('password', checkPassword(password))}
              />
              <Button
                disabled={disableSubmission()}
                fullWidth
                variant="contained"
                type="submit"
                sx={{ width: 'fit-content', mt: 8 }}
              >
                {t('button.sign_up')}
              </Button>
            </Box>
          </FormControl>
        </form>
      )}
      {isSuccess && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          rowGap={3}
        >
          <TaskAltIcon color="success" sx={{ fontSize: '10rem' }} />
          <Typography variant="h5" align="center">
            {t('authorization.register_success')}
          </Typography>
        </Box>
      )}
    </Grid>
  );
}
