import api from 'store/api';
import {
  ContractorGroupsCondition,
  ContractorGroupsFilters,
  HolidaysCondition,
  ProjectConditionFilters,
  RangeConditions,
  TaxesCondition,
  TaxesFilters,
} from './interfaces';

export const conditionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjectsCondition: builder.query<
      RangeConditions,
      ProjectConditionFilters
    >({
      query: ({ date, ...params }) => ({
        url: `/condition/${date}/projects`,
        params,
      }),
      providesTags: ['ConditionProjects'],
    }),
    getHolidaysCondition: builder.query<HolidaysCondition, string>({
      query: (month) => `/condition/${month}/holidays`,
      providesTags: ['HolidaysCondition'],
    }),
    getTaxes: builder.query<TaxesCondition, Partial<TaxesFilters>>({
      query: ({ date, ...params }) => ({
        url: `/condition/${date}/contractors`,
        params,
      }),
      providesTags: ['TaxesCondition'],
    }),
    getContractorGroupsCondition: builder.query<
      ContractorGroupsCondition,
      Partial<ContractorGroupsFilters>
    >({
      query: ({ month, ...params }) => ({
        url: `/condition/${month}/contractor-groups`,
        params,
      }),
      providesTags: ['ContractorGroupsCondition'],
    }),
  }),
});

export const {
  useGetProjectsConditionQuery,
  useGetHolidaysConditionQuery,
  useGetTaxesQuery,
  useGetContractorGroupsConditionQuery,
} = conditionApi;
