import React from 'react';
import { Stack, StackOwnProps, Theme, useMediaQuery } from '@mui/material';
import { v4 as uuid } from 'uuid';

interface MasonryColumnsProps {
  children: React.ReactNode;
  columns: number;
  spacing?: StackOwnProps['spacing'];
  p?: StackOwnProps['p'];
}

// use when you don't know how many components there will be to render
export default function MasonryColumns({
  children,
  columns,
  spacing = 3,
  p = 3,
}: MasonryColumnsProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const columnChildren: React.ReactNode[][] = Array.from(
    { length: columns },
    () => [],
  );

  React.Children.toArray(children).forEach((child, index) => {
    const columnIndex = index % columns;
    columnChildren[columnIndex].push(child);
  });

  const columnFlexBasis = `${100 / columns}%`;

  if (isMobile || columns === 1) {
    return (
      <Stack direction="column" spacing={spacing} p={p}>
        {columnChildren.map((columnElements) => (
          <Stack key={uuid()} spacing={spacing}>
            {columnElements.map((child) => (
              <div key={uuid()}>{child}</div>
            ))}
          </Stack>
        ))}
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={spacing} p={p}>
      {columnChildren.map((columnElements) => (
        <Stack key={uuid()} spacing={spacing} flexBasis={columnFlexBasis}>
          {columnElements.map((child) => (
            <div key={uuid()}>{child}</div>
          ))}
        </Stack>
      ))}
    </Stack>
  );
}
