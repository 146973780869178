import React from 'react';
import {
  Divider,
  Select,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';
import { budgetPeriod } from 'components/BudgetForm/budgetSelectData';
import useBudgetPeriodContext from 'pages/BudgetPeriod/context/useBudgetPeriodContext';
import CustomLocalizationProvider from 'common/CustomLocalizationProvider/CustomLocalizationProvider';

export default function BudgetPeriodForm() {
  const { t } = useTranslation();
  const { periodState, handleOnSelectChange, handleOnDatePickerChange } =
    useBudgetPeriodContext();

  return (
    <Stack
      width="25%"
      minWidth="36rem"
      height="calc(100vh - 8rem)"
      position="sticky"
      top="8rem"
      sx={{
        borderRight: '0.1rem solid',
        borderColor: 'border.divider',
        '& .MuiTypography-root': {
          userSelect: 'none',
        },
        '& .MuiFormLabel-root': {
          userSelect: 'none',
        },
      }}
    >
      <Stack spacing={1} p={6}>
        <Typography variant="h5">{t('budget.time_frame')}</Typography>
        <Typography color="text.secondary">
          {t('budget.time_frame_description')}
        </Typography>
      </Stack>
      <Divider />
      <Stack p={6}>
        <Stack spacing={1}>
          <Typography variant="h6">{t('budget.granularity')}</Typography>
          <Typography color="text.secondary" pb={6}>
            {t('budget.granularity_description')}
          </Typography>
        </Stack>
        <FormControl fullWidth>
          <InputLabel id="granularity">{t('budget.granularity')}</InputLabel>
          <Select
            labelId="granularity"
            label={t('budget.granularity')}
            name="period"
            onChange={handleOnSelectChange}
            value={periodState.period}
            sx={{ userSelect: 'none' }}
          >
            {budgetPeriod.map((period) => (
              <MenuItem key={period.value} value={period.value}>
                {t(period.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Divider />
      <Stack p={6}>
        <Stack spacing={1}>
          <Typography variant="h6">{t('budget.period')}</Typography>
          <Typography color="text.secondary" pb={6}>
            {t('budget.period_description')}
          </Typography>
        </Stack>
        <Stack spacing={3}>
          <CustomLocalizationProvider>
            <DateTimePicker
              label={t('label.from')}
              views={['year', 'month']}
              ampm={false}
              value={periodState.dateFrom}
              onChange={(newDate) => {
                handleOnDatePickerChange(
                  newDate ? startOfMonth(newDate) : newDate,
                  'dateFrom',
                );
              }}
            />
          </CustomLocalizationProvider>
          <CustomLocalizationProvider>
            <DateTimePicker
              label={t('label.to')}
              views={['year', 'month']}
              ampm={false}
              value={periodState.dateTo}
              onChange={(newDate) => {
                handleOnDatePickerChange(
                  newDate ? endOfMonth(newDate) : newDate,
                  'dateTo',
                );
              }}
            />
          </CustomLocalizationProvider>
        </Stack>
      </Stack>
    </Stack>
  );
}
