import { Box, BoxProps, styled } from '@mui/material';

const BottomResize = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  cursor: 'row-resize',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '4rem',
    height: '0.4rem',
    bottom: '0.4rem',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '0.4rem',
    backgroundColor: theme.palette.other.resizeIndicator,
  },
}));

export default BottomResize;
