import { useContext } from 'react';
import {
  EditProjectFormContext,
  EditProjectFormContextProps,
} from './EditProjectForm.context';

function useEditProjectFormContext(): EditProjectFormContextProps {
  const context = useContext(EditProjectFormContext);

  if (!context) {
    throw new Error(
      'useEditProjectFormContext must be used within a EditProjectFormProvider',
    );
  }

  return context;
}

export default useEditProjectFormContext;
