import React, { useState, useEffect } from 'react'
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Box,
  Typography,
  TextField
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import {
  useGetCompanyStartDateQuery,
  useUpdateCompanyStartDateMutation,
} from 'store/config/config';
import { CompanyStartDateResponse } from 'store/config/interfaces';
import Error from 'common/Error';
import { CustomError } from 'store/api';
import { useValidate } from 'hooks/useValidate';
import { CompanyStartDateProps, Errors } from './interfaces'

function CompanyStartDate({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: CompanyStartDateProps) {
  const { t } = useTranslation();
  const {
    data: companyStartDate = {} as CompanyStartDateResponse,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
  } = useGetCompanyStartDateQuery();

  const [updateCompanyStartDate, { isLoading: isMutationLoading }] = useUpdateCompanyStartDateMutation();

  const { errors, validate } = useValidate<Errors>();

  const [selectedStartDate, setSelectedStartDate] = useState('');
  const isStartDateChanged = selectedStartDate !== dateFormat(companyStartDate?.value?.date, 'yyyy-mm-dd');

  const handleUpdateCompanyStartDate = async () => {
    try {
      await updateCompanyStartDate({
        value: selectedStartDate,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const isUpdateButtonShouldBeVisible = isStartDateChanged && selectedStartDate;

  useEffect(() => {
    if (isQuerySuccess) {
      setSelectedStartDate(dateFormat(companyStartDate.value.date, 'yyyy-mm-dd'));
    }
  }, [companyStartDate, isQuerySuccess, setSelectedStartDate]);

  if (isQueryError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.company_start_date')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('label.start_date')}
              error={errors.selectedStartDate}
              id="company-start-date"
              helperText={errors.selectedStartDate && t('errors.field_required')}
              name="companyStartDate"
              onBlur={(event) =>
                validate('selectedStartDate', event.target.value !== '')
              }
              onChange={(event) => setSelectedStartDate(event.target.value)}
              type="date"
              value={selectedStartDate}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          {isUpdateButtonShouldBeVisible && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isMutationLoading}
                onClick={handleUpdateCompanyStartDate}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

export default CompanyStartDate;
