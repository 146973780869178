import React, { useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { StorageResponse, useDeleteFileMutation } from 'store/storage/storage';
import AddFileDialog from 'components/EditAgreementForm/Files/AddFileDialog';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { CustomError } from 'store/api';

export interface FilesProps extends StatusProps {
  files: StorageResponse[];
}

function Files({
  files,
  setIsError,
  setIsSuccess,
  setErrorMessage,
}: FilesProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteFile] = useDeleteFileMutation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const { t } = useTranslation();

  const handleDeleteFile = async (fileId: string) => {
    try {
      await deleteFile(fileId).unwrap();
      setIsSuccess(true);
      setIsConfirmationDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <TableContainer sx={{ mt: 3 }}>
        <Table>
          <TableBody>
            <RowAddButton colSpan={2} onClick={() => setIsOpen(!isOpen)}>
              <Typography variant="body2" color="secondary">
                {t('agreement.add_new_file')}
              </Typography>
            </RowAddButton>
            {files?.map((file) => (
              <TableRow key={file.id}>
                <TableCell>
                  <Typography
                    variant="body2"
                    onClick={() => downloadFileWithAuth(file.url, file.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {file.id}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => {
                      setSelectedFile(file.id);
                      setIsConfirmationDialogOpen(true);
                    }}
                  >
                    <DeleteIcon color="error" fontSize="large" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddFileDialog isOpen={isOpen} setIsOpen={setIsOpen} />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleAccept={() => handleDeleteFile(selectedFile)}
      />
    </>
  );
}

export default Files;
