import { FormControl, FormControlProps, styled } from '@mui/material';

const StyledFormControlCountry = styled(FormControl)<FormControlProps>(() => ({
  flexBasis: '25%',
  '& .MuiInputBase-root': {
    borderRadius: 0,
    borderTopLeftRadius: '1.2rem',
    borderBottomLeftRadius: '1.2rem',
  },
}));

export default StyledFormControlCountry;
