import { FormPayload } from './interfaces';

const defaultValues: FormPayload = {
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  source: '',
};

export default defaultValues;
