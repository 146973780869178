import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import OrganizationUsersList from 'common/OrganizationUsersList/OrganizationUsersList';

export default function OrganizationUsers() {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={10} bgcolor="main.white">
      <NavBarContainer>
        <NavBarTitle>{t('organization_users.title')}</NavBarTitle>
      </NavBarContainer>
      <OrganizationUsersList />
    </Grid>
  );
}
