import { Holiday, HolidayRequest } from 'store/holidays/interfaces';
import { t } from 'i18next';

const getHolidayDuration = ({
  holidayItem,
  displayShort = false,
}: {
  holidayItem: HolidayRequest | Holiday;
  displayShort?: boolean;
}) => {
  const isHoursRequest = holidayItem.hours && holidayItem.hours % 8 !== 0;

  if (displayShort) {
    return isHoursRequest
      ? `${holidayItem.hours}h`
      : `${holidayItem.requestedDays}d`;
  }

  if (isHoursRequest) {
    return holidayItem.hours === 1
      ? t('holidays.one_hour_duration')
      : t('holidays.some_hours_duration', { hours: holidayItem.hours });
  }

  return holidayItem.requestedDays === 1
    ? t('holidays.one_day_duration')
    : t('holidays.some_days_duration', { days: holidayItem.requestedDays });
};

export default getHolidayDuration;
