import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    align: 'left',
    label: 'label.name',
  },
  {
    align: 'left',
    label: 'costs.header_cost_group',
  },
  {
    align: 'left',
    label: 'label.description',
  },
];

export default tableHeaders;
