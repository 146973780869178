import React from 'react';
import { differenceInDays, differenceInSeconds } from 'date-fns';
import useBookingSchedulerContext from 'components/BookProperties/BookingForm/context/useBookingScheduler.context';
import {
  useCancelPropertyBookingMutation,
  useAddPropertyBookingMutation,
} from 'store/propertyBooking/propertyBooking';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';
import { createBookingId } from 'components/BookProperties/BookingForm/consts';
import { CustomError } from 'store/api';
import useElementWidth from 'hooks/useElementWidth';
import dateFormat from 'dateformat';
import endOfPrevDay from 'helpers/endOfPrevDay';
import formatDateToUtcFormat from 'helpers/formatDateToUtcFormat';
import { AppointmentContentProps } from './interfaces';
import StyledAppointmentWrapper from './AppointmentWrapper.styled';
import StateAppointment from './StateAppointment/StateAppointment';
import DraftAppointment from './DraftAppointment/DraftAppointment';

const oneDayWrapperMaxWidth = 128;

export default function AppointmentContentComponent({
  data,
  setErrorMessage,
  setIsError,
  setIsSuccess,
}: AppointmentContentProps) {
  const { removeBookingFromState, property, addBookingToState } =
    useBookingSchedulerContext();

  const { elementWidth: containerWidth, elementRef: containerRef } =
    useElementWidth();
  const isMultipleDaysWidthSize = containerWidth
    ? containerWidth > oneDayWrapperMaxWidth
    : false;

  const isInDraftMode = data.id === createBookingId;
  const dateFrom = dateFormat(data.startDate, 'd');
  const dateTo = dateFormat(endOfPrevDay(data.endDate as string), 'd');
  const bookingDaysCount = differenceInDays(
    data.endDate as string,
    data.startDate,
  );

  const [cancelPropertyBooking] = useCancelPropertyBookingMutation();
  const [addBooking] = useAddPropertyBookingMutation();

  const { selectedEmployee } = useGetSelectedEmployeePreview({
    employeeIri: data.employee,
    filters: { isActive: true },
  });

  const handleCancelPropertyBooking = async () => {
    try {
      if (!data.id) {
        return;
      }
      if (isInDraftMode) {
        removeBookingFromState(data.id as string);
        return;
      }

      await cancelPropertyBooking({ id: data.id });
      removeBookingFromState(data.id as string);
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleAddPropertyBooking = async () => {
    try {
      const newBooking = await addBooking({
        employee: data.employee,
        property: property['@id'],
        startDate: formatDateToUtcFormat(data.startDate as Date),
        duration: differenceInSeconds(data.endDate as Date, data.startDate),
      }).unwrap();
      removeBookingFromState(data.id as string);

      addBookingToState({
        startDate: new Date(newBooking.startDate),
        endDate: new Date(newBooking.dateTo),
        title: newBooking.property.name,
        id: newBooking.id.toString(),
        employee: newBooking.employee,
      });
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (isInDraftMode) {
    return (
      <StyledAppointmentWrapper ref={containerRef}>
        <DraftAppointment
          data={data}
          handleAddPropertyBooking={handleAddPropertyBooking}
          employeeData={selectedEmployee}
          isMultipleDaysWidthSize={isMultipleDaysWidthSize}
          dateFrom={dateFrom}
          dateTo={dateTo}
          bookingDaysCount={bookingDaysCount}
        />
      </StyledAppointmentWrapper>
    );
  }

  return (
    <StyledAppointmentWrapper ref={containerRef}>
      <StateAppointment
        data={data}
        handleCancelPropertyBooking={handleCancelPropertyBooking}
        employeeData={selectedEmployee}
        isMultipleDaysWidthSize={isMultipleDaysWidthSize}
        dateFrom={dateFrom}
        dateTo={dateTo}
        bookingDaysCount={bookingDaysCount}
      />
    </StyledAppointmentWrapper>
  );
}
