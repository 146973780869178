import React from 'react';
import { Box, DialogContent, FormControl } from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddProjectMutation } from 'store/projects/projects';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';
import {
  useAddBudgetProjectsMutation,
  useGetBudgetsQuery,
} from 'store/budget/budget';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import useCheckBudgetIsEnabled from 'hooks/useCheckBudgetIsEnabled';
import { AddProjectFormProps, FormPayload } from './interfaces';
import schema from './AddProjectForm.schema';
import getSelectOptions from './getSelectOptions';

const validate = zodResolver(schema);

const initValues = {
  name: '',
  type: '',
  status: '',
  budget: '',
};

export default function AddProjectForm({ setIsOpen }: AddProjectFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isBudgetEnabled = useCheckBudgetIsEnabled();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initValues,
  });

  const { name, type, status } = watch();

  const { data: budgets } = useGetBudgetsQuery({}, { skip: !isBudgetEnabled });
  const [addProject, { isLoading }] = useAddProjectMutation();
  const [addBudgetProjects, { isLoading: isAddBudgetProjectLoading }] =
    useAddBudgetProjectsMutation();

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) || !name || !type || !status;

  const handleAddProject = handleSubmit(async (data: FormPayload) => {
    try {
      const addedProject = await addProject({
        name: data.name,
        type: data.type,
        status: data.status,
      }).unwrap();

      if (data.budget) {
        const newBudgetProject = {
          budget: data.budget,
          project: addedProject['@id'],
        };
        await addBudgetProjects(newBudgetProject).unwrap();
      }
      dispatch(setSuccessStatus(true));
      setIsOpen(false);
      navigate(`/projects/${addedProject.id}`);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  });

  const { projectTypesOptions, projectStatusesOptions, projectBudgetOptions } =
    getSelectOptions(budgets?.['hydra:member'] ?? []);

  return (
    <>
      <DialogHeader title={t('projects.new_project')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleAddProject}>
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 4 }}
          >
            <ControlTextField
              control={control}
              label="label.name"
              errors={errors}
              name="name"
              required
            />
            <ControlSelect
              control={control}
              label="label.type"
              name="type"
              errors={errors}
              selectOptions={projectTypesOptions}
              required
            />
            <ControlSelect
              control={control}
              label="label.status"
              name="status"
              errors={errors}
              selectOptions={projectStatusesOptions}
              required
            />
            {budgets && isBudgetEnabled && (
              <ControlSelect
                control={control}
                label="projects.budget_label"
                name="budget"
                errors={errors}
                selectOptions={projectBudgetOptions}
              />
            )}
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <SubmitButton
              disabled={disableSubmission()}
              isLoading={isLoading || isAddBudgetProjectLoading}
            />
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
