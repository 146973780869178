import React from 'react';
import { Stack, Typography, Button, Chip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import useResourceRequestContext from 'pages/HumanResourceResource/context/useResourceRequestContext';
import { Candidate } from 'store/candidates/interfaces';

interface AutocompleteOptionProps {
  candidateOption: Candidate;
  onClick: (candidateIri: string) => Promise<void>;
}

export default function AutocompleteOption({
  candidateOption,
  onClick,
}: AutocompleteOptionProps) {
  const { t } = useTranslation();

  const { resourceRequestCandidates } = useResourceRequestContext();

  const isCandidateAlreadyAdded = (candidateId: string) =>
    resourceRequestCandidates.some(
      (resourceRequestCandidate) =>
        resourceRequestCandidate.candidate.id === candidateId,
    );

  const handleOnClick = async () => {
    await onClick(candidateOption['@id']);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      sx={{
        cursor: isCandidateAlreadyAdded(candidateOption.id)
          ? 'not-allowed'
          : 'pointer',
      }}
    >
      <Stack flexBasis="80%">
        <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
          {candidateOption.firstname} {candidateOption.lastname}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ wordBreak: 'break-all' }}
        >
          {candidateOption.email}
        </Typography>
        <Stack direction="row" gap={1} flexWrap="wrap">
          {candidateOption.tags?.map((tag) => (
            <Chip
              variant="outlined"
              size="small"
              label={
                <Typography variant="chipLabel">
                  {tag.tagDefinition.name}
                </Typography>
              }
              key={tag.id}
            />
          ))}
        </Stack>
      </Stack>
      {isCandidateAlreadyAdded(candidateOption.id) ? (
        <Typography color="text.secondary" variant="caption">
          {t('human_resources.already_added')}
        </Typography>
      ) : (
        <Button
          variant="contained"
          type="button"
          endIcon={<AddCircleOutlineIcon />}
          onClick={handleOnClick}
        >
          {t('label.add')}
        </Button>
      )}
    </Stack>
  );
}
