import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BankAccount, useGetAccountsQuery } from 'store/accounts/accounts';
import tableHeaders from 'components/BanksList/tableHeaders';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import { BanksListProps } from './interfaces';
import BankRow from './BankRow/BankRow';

export default function BanksList({
  banks,
  banksError,
  banksLoading,
}: BanksListProps) {
  const {
    data: accounts = [] as BankAccount[],
    isLoading: accountsLoading,
    isError: accountsError,
  } = useGetAccountsQuery();

  const { t } = useTranslation();

  const accountsGroupedByBank: Record<string, BankAccount[]> = banks.reduce(
    (acc, bank) => {
      const bankAccounts = accounts.filter(
        (account) => account.bank['@id'] === bank['@id'],
      );
      return { ...acc, [bank['@id']]: bankAccounts };
    },
    {},
  );

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.label}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={tableHeaders.length} />
          </TableRow>
        </TableHead>
        <TableBodyContent
          isError={banksError || accountsError}
          isFetching={banksLoading || accountsLoading}
          tableHeaders={tableHeaders}
        >
          {banks.map((bank) => (
            <BankRow
              key={bank.id}
              bank={bank}
              bankAccounts={accountsGroupedByBank[bank['@id']]}
            />
          ))}
        </TableBodyContent>
      </Table>
    </TableContainer>
  );
}
