const colors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  PRIMARY_LIGHT: '#532FD314',
  PRIMARY_MAIN: '#613CEF',
  INFO_MAIN: '#3B97FA',
  INFO_SELECTED: '#1E5AA114',
  INFO_OUTLINED_BORDER: '#1E5DA13D',
  PRIMARY_OUTLINED_BORDER: '#403DC53D',
  VENETIAN_NIGHTS_100: '#F0EFFE',
  VENETIAN_NIGHTS_200: '#E6E4FD',
  VENETIAN_NIGHTS_700: '#715DFA',
  VENETIAN_NIGHTS_800: '#5E4CD2',
  VENETIAN_NIGHTS_1200: '#171334',
  GREY_50: '#F7F7F8',
  GREY_100: '#EEEEF0',
  GREY_200: '#D9D9DE',
  GREY_300: '#B9BAC0',
  GREY_400: '#92929E',
  GREY_500: '#757682',
  GREY_600: '#5E5E6B',
  GREY_900: '#3A3A40',
  GREY_AGATE_100: '#F5F4F8',
  GREY_AGATE_200: '#EEEDF3',
  GREY_AGATE_300: '#DCDBE6',
  GREY_AGATE_400: '#CBC9DA',
  GREY_AGATE_500: '#BAB7CD',
  GREY_AGATE_800: '#78739E',
  GREY_AGATE_900: '#5E5981',
  GREY_AGATE_1000: '#474360',
  GREY_AGATE_1100: '#2F2D40',
  GREY_AGATE_1200: '#171620',
  TEXT_DISABLED: '#18181C52',
  TEXT_SECONDARY: '#18181c8f',
  TEXT_PRIMARY: '#18181CE0',
  ACTION_ACTIVE: '#18181c7a',
  SECONDARY_OUTLINED_BORDER: '#625e973d',
  SECONDARY_SELECTED: '#625e9714',
  ENABLED_BORDER_VERY_LIGHT: '#27292e14',
  ENABLED_BORDER_LIGHT: '#27292e29',
  DIVIDER: '#27292e14',
  ENABLED_BORDER_MAIN: '#D9D9DE',
  FLORIDA_KEYS_200: '#D5ECE6',
  FLORIDA_KEYS_500: '#4DB29D',
  FLORIDA_KEYS_800: '#2E6C5F',
  HALF_SMOKE_200: '#FCEFEB',
  HALF_SMOKE_500: '#F3B7A4',
  HALF_SMOKE_700: '#EE8659',
  HALF_SMOKE_1100: '#894D33',
  ZUCCHINI_FLOWER_300: '#F7E4D5',
  ZUCCHINI_FLOWER_700: '#E9A44F',
  ZUCCHINI_FLOWER_1200: '#5E4220',
  KHMER_CURRY_200: '#FCEBEB',
  KHMER_CURRY_700: '#EE5959',
  KHMER_CURRY_900: '#C24848',
  RATING_ACTIVE_FILL: '#FFB400',
  BG_OTHER_BACKLIGHT: '#00000009',
  ERROR_MAIN: '#B61F1F',
  ERROR_OUTLINED_BORDER: '#B61F1F3D',
  SUCCESS_MAIN: '#388373',
  SUCCESS_OUTLINED_BORDER: '#3883733D',
  WARNING_BACKGROUND: '#F9E6CC',
  WARNING_MAIN: '#A3381A',
  WARNING_LIGHT: '#DE7022',
  WARNING_OUTLINED_BORDER: '#DE70223D',
  WARNING_SELECTED: '#DE702214',
  PALE_BLUE: '#DEE1FF',
  BLUE_500: '#715DFA',
  RESIZE_INDICATOR: '#0000004D',
  ORANGE_300: '#E9A44F',
  ORANGE_500: '#DE7022',
  DEEP_PURPLE_50: '#EDE7F6',
  DEEP_PURPLE_100: '#D1C4E9',
  AVATAR_FILL: '#DEE1FF'
};

export default colors;
