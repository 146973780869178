import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Box, Typography, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  useGetCompanyTaxIdNumberQuery,
  useUpdateCompanyTaxIdNumberMutation,
} from 'store/config/config';
import { ConfigResponse } from 'store/config/interfaces';
import { useValidate } from 'hooks/useValidate';
import Error from 'common/Error';
import { CustomError } from 'store/api';
import { CompanyTaxIdNumberProps, Errors } from './interfaces';

function CompanyTaxIdNumber({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: CompanyTaxIdNumberProps) {
  const { t } = useTranslation();
  const {
    data: companyTaxIdNumber = {} as ConfigResponse<string>,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
  } = useGetCompanyTaxIdNumberQuery();

  const [updateCompanyTaxIdNumber, { isLoading: isMutationLoading }] =
    useUpdateCompanyTaxIdNumberMutation();

  const { errors, validate } = useValidate<Errors>();

  const [selectedTaxIdNumber, setSelectedTaxIdNumber] = useState('');
  const isCompanyTaxIdNumberChanged =
    selectedTaxIdNumber !== companyTaxIdNumber?.value;

  const handleUpdateCompanyTaxIdNumber = async () => {
    try {
      await updateCompanyTaxIdNumber({
        value: selectedTaxIdNumber,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const isUpdateButtonShouldBeVisible =
    isCompanyTaxIdNumberChanged && selectedTaxIdNumber;

  useEffect(() => {
    if (isQuerySuccess) {
      setSelectedTaxIdNumber(companyTaxIdNumber.value);
    }
  }, [isQuerySuccess, companyTaxIdNumber, setSelectedTaxIdNumber]);

  if (isQueryError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.company_tax_id_number')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('label.tax_id_number')}
              error={errors.selectedTaxIdNumber}
              id="company-tax-id-number"
              helperText={
                errors.selectedTaxIdNumber && t('errors.field_required')
              }
              name="companyTaxIdNumber"
              onBlur={(event) =>
                validate('selectedTaxIdNumber', event.target.value !== '')
              }
              onChange={(event) => setSelectedTaxIdNumber(event.target.value)}
              value={selectedTaxIdNumber}
              fullWidth
            />
          </Grid>
          {isUpdateButtonShouldBeVisible && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isMutationLoading}
                onClick={handleUpdateCompanyTaxIdNumber}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default CompanyTaxIdNumber;
