import React from 'react';
import { Box } from '@mui/material';
import { DragDropProvider } from '@devexpress/dx-react-scheduler-material-ui';

export default function ContainerComponent(
  props: DragDropProvider.ContainerProps,
) {
  return (
    <Box sx={{
      '&.MuiBox-root .Appointment-appointment': {
        borderRadius: '0.8rem',
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.main',
        },
      },
    }}>
      <DragDropProvider.Container {...props} />
    </Box>
  );
}
