import React, { useEffect } from 'react';
import {
  Box,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAddHolidayRequestMutation } from 'store/holidays/holidays';
import { useForm } from 'react-hook-form';
import dateFormat from 'dateformat';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import CancelButton from 'common/CancelButton/CancelButton';
import { useAppDispatch } from 'store/hooks';
import { setSuccessStatus, setErrorCatch } from 'store/status/actions';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import useWorkingDaysBetweenRange from 'hooks/useWorkingDaysBetweenRange';
import { getHolidayIcon } from 'helpers/getHolidayData';
import useHolidayTypes from 'hooks/useHolidayTypes';
import holidaysSettings from 'helpers/holidaysSettings';
import schema from './EmployeeHolidayForm.schema';
import HolidaySummary from './HolidaySummary/HolidaySummary';
import { FormPayload } from './interfaces';
import defaultValues from './defaultValues';
import hoursOptions from './hoursOptions';
import PeriodRangePicker from './PeriodRangePicker/PeriodRangePicker';

const validate = zodResolver(schema);

export interface EmployeeHolidayFormProps {
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EmployeeHolidayForm({
  setIsDialogOpen,
}: EmployeeHolidayFormProps) {
  const dispatch = useAppDispatch();
  const [addHolidayRequest, { isLoading }] = useAddHolidayRequestMutation();
  const {
    activeHolidayTypes,
    holidayTypesLoading,
    holidayTypesError,
    holidayTypesSuccess,
  } = useHolidayTypes();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues,
  });
  const { getWorkingDaysCount } = useWorkingDaysBetweenRange();
  const { t } = useTranslation();
  const { type, description, dateFrom, dateTo, hours } = watch();

  const handleRequestHoliday = async (data: FormPayload) => {
    try {
      const {
        dateFrom: formDateFrom,
        dateTo: formDateTo,
        hours: formHours,
        ...restPayload
      } = data;
      await addHolidayRequest({
        dateFrom: dateFormat(formDateFrom, 'yyyy-mm-dd'),
        ...(formHours &&
          +formHours >= holidaysSettings.minLimitHolidayHours &&
          +formHours <= holidaysSettings.maxLimitHolidayHours && {
            hours: +formHours,
          }),
        dateTo: dateFormat(formDateTo, 'yyyy-mm-dd'),
        ...restPayload,
      }).unwrap();
      dispatch(setSuccessStatus(true));
      setIsDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  const workingDaysDifferenceInDates = getWorkingDaysCount(dateFrom, dateTo);
  const workingHoursDifferenceInDates =
    workingDaysDifferenceInDates * holidaysSettings.workingHoursInDay;

  const isDisableSubmission =
    !!Object.keys(errors).length ||
    !type ||
    !description ||
    !dateFrom ||
    !dateTo ||
    workingDaysDifferenceInDates < 1;

  const hoursOptionsToDisplay =
    workingHoursDifferenceInDates === holidaysSettings.workingHoursInDay
      ? hoursOptions
      : [
          {
            label: workingHoursDifferenceInDates.toString(),
            value: workingHoursDifferenceInDates.toString(),
          },
        ];

  useEffect(() => {
    if (workingHoursDifferenceInDates > holidaysSettings.workingHoursInDay) {
      setValue('hours', workingHoursDifferenceInDates.toString());
    } else if (
      workingHoursDifferenceInDates !== holidaysSettings.workingHoursInDay
    ) {
      setValue('hours', '');
    }
  }, [setValue, workingHoursDifferenceInDates]);

  const leavePeriod = `${dateFormat(dateFrom, 'dd.mm.yyyy')} - ${dateFormat(
    dateTo,
    'dd.mm.yyyy',
  )}`;

  return (
    <ContentSuspense
      isError={holidayTypesLoading}
      isLoading={holidayTypesError}
    >
      <DialogHeader
        title={t('holidays.new_leave_request')}
        setIsOpen={setIsDialogOpen}
      />
      {holidayTypesSuccess && (
        <DialogContent sx={{ px: 0 }}>
          <form onSubmit={handleSubmit(handleRequestHoliday)} noValidate>
            <Box display="flex" flexDirection="column" pt={4} gap={3}>
              <Box sx={{ px: 3 }}>
                <ControlSelect
                  control={control}
                  name="type"
                  errors={errors}
                  label="label.type"
                  required
                  renderOptions={activeHolidayTypes.map((holidayType) => (
                    <MenuItem key={holidayType.id} value={holidayType.id}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          '& svg': {
                            fontSize: '2rem',
                          },
                        }}
                      >
                        {getHolidayIcon(holidayType.id)}
                        <Typography ml={1} variant="body1">
                          {holidayType.name}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                />
              </Box>
              <Box sx={{ px: 3 }}>
                <ControlTextField
                  control={control}
                  errors={errors}
                  name="description"
                  label={t('label.description')}
                  required
                />
              </Box>
              <Divider />
              <Stack direction="row" spacing={3} px={3}>
                <Stack width="58%" spacing={3}>
                  <TextField
                    label={t('holidays.leave_period')}
                    InputLabelProps={{ shrink: true }}
                    value={leavePeriod}
                  />
                  <ControlSelect
                    control={control}
                    name="hours"
                    errors={errors}
                    label="label.hours"
                    selectOptions={hoursOptionsToDisplay}
                    disabled={workingDaysDifferenceInDates !== 1}
                    optional
                  />
                  <Divider />
                  <HolidaySummary
                    requestedHours={
                      hours ? +hours : workingHoursDifferenceInDates
                    }
                    type={type}
                  />
                </Stack>
                <Stack width="42%" alignItems="flex-end">
                  <PeriodRangePicker
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Stack>
              </Stack>
            </Box>
            <Stack
              direction="row"
              justifyContent="flex-end"
              mt={3}
              px={3}
              spacing={2}
            >
              <CancelButton onClick={() => setIsDialogOpen(false)} />
              <SubmitButton
                disabled={isDisableSubmission}
                isLoading={isLoading}
                label="holidays.create_leave_request"
                noIcon
              />
            </Stack>
          </form>
        </DialogContent>
      )}
    </ContentSuspense>
  );
}
