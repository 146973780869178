import React from 'react';
import { Stack } from '@mui/material';
import { createSearchParams } from 'react-router-dom';
import replaceApiIri from 'helpers/replaceApiIri';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { useTranslation } from 'react-i18next';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Link from 'common/Link/Link';
import { WidgetTitle } from 'common/Widget';
import { Holiday } from 'store/holidays/interfaces';

interface HolidaysTitleProps {
  holidays: Holiday[];
}

export default function HolidaysTitle({ holidays }: HolidaysTitleProps) {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row">
      <WidgetTitle size="lg">{t('menu.holidays_management')}</WidgetTitle>
      {!!holidays.length && (
        <TitleTooltip
          title={t('label.employees_holidays', {
            employee: holidays[0].employeeReversedFullname,
          })}
        >
          <Link
            to={{
              pathname: '/holidays-management',
              search: createSearchParams({
                employee: replaceApiIri(
                  String(holidays[0].employee),
                  'employees',
                ),
              }).toString(),
            }}
          >
            <PermContactCalendarIcon color="primary" fontSize="large" />
          </Link>
        </TitleTooltip>
      )}
    </Stack>
  );
}
