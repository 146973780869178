import { z } from 'zod';
import { BudgetType, BudgetPeriodType } from 'store/budget/interfaces';

const schema = z.object({
  name: z.string().min(1, { message: 'errors.field_required' }),
  type: z
    .nativeEnum(BudgetType, {
      errorMap: () => ({ message: 'errors.field_required' }),
    })
    .or(z.literal('')),
  period: z
    .nativeEnum(BudgetPeriodType, {
      errorMap: () => ({ message: 'errors.field_required' }),
    })
    .or(z.literal(''))
    .optional(),
  amount: z.string().min(1, { message: 'errors.field_required' }).optional(),
  dateFrom: z.string().optional().or(z.literal('')),
  dateTo: z.string().optional().or(z.literal('')),
  budgetGroup: z.string().optional().or(z.literal('')),
});

export default schema;
