import React from 'react';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetHolidayRequestsQuery } from 'store/holidays/holidays';
import { getHolidayIcon } from 'helpers/getHolidayData';
import { WidgetCaption, WidgetContainer, WidgetTitle } from 'common/Widget';
import StyledForwardLink from 'pages/HolidaysManagement/Link.styled';

function RequestedHolidaysWidget() {
  const { t } = useTranslation();
  const {
    data: requests = [],
    isLoading: requestsLoading,
    isError: requestsError,
    isSuccess: requestSuccess,
  } = useGetHolidayRequestsQuery({});

  return (
    <WidgetContainer
      title={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <WidgetTitle>{t('dashboard.holiday_requests')}</WidgetTitle>
          <StyledForwardLink to="/holidays-management">
            <WidgetTitle>{t('dashboard.manage_requests')}</WidgetTitle>
          </StyledForwardLink>
        </Stack>
      }
      isError={requestsError}
      isLoading={requestsLoading}
    >
      {requestSuccess &&
        requests.map((request) => (
          <Grid
            container
            key={request.id}
            alignItems="center"
            justifyContent="space-between"
            pb={1}
          >
            <Grid item xs={8}>
              <WidgetCaption>
                {request.employee.lastname} {request.employee.firstname}
              </WidgetCaption>
            </Grid>
            <Grid item xs={1}>
              <WidgetCaption>{request.requestedDays}</WidgetCaption>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              {getHolidayIcon(request.type)}
            </Grid>
          </Grid>
        ))}
    </WidgetContainer>
  );
}

export default RequestedHolidaysWidget;
