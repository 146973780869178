import api from 'store/api';

import {
  BudgetGroup,
  BudgetGroupPostMutation,
  BudgetGroupsFilters,
  BudgetGroupsResponse,
} from './interfaces';

export const budgetGroupsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBudgetGroups: builder.query<BudgetGroup[], Partial<BudgetGroupsFilters>>(
      {
        query: (filters) => ({
          url: 'budget-groups',
          params: filters,
        }),
        providesTags: ['BudgetGroups'],
        transformResponse: (response: BudgetGroupsResponse) =>
          response['hydra:member'].sort((budgetGroup, comparedBudgetGroup) =>
            budgetGroup.name.localeCompare(comparedBudgetGroup.name),
          ),
      },
    ),
    addBudgetGroup: builder.mutation<BudgetGroup, BudgetGroupPostMutation>({
      query: (body) => ({
        url: 'budget-groups',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BudgetGroups'],
    }),
  }),
});

export const { useGetBudgetGroupsQuery, useAddBudgetGroupMutation } =
  budgetGroupsApi;
