import React, { useState } from 'react';
import { AccordionDetails, Avatar, Box, Grid, Typography } from '@mui/material';
import { useGetLocationsQuery } from 'store/locations/locations';
import {
  StyledAccordion,
  StyledSummary,
} from 'components/ResponsibilityGroup/Accordion.styled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Property } from 'store/properties/interfaces';
import EditButton from 'common/EditButton/EditButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getPropertyBookingCurrentReservedUser from 'helpers/getPropertyBookingCurrentReservedUser';
import { PropertyBooking } from 'store/propertyBooking/interfaces';
import { propertyTypes } from './propertyTypes';

interface PropertiesAccordionProps {
  type: string;
  properties: Property[];
  activeBookingsFilteredByStartDateTime: PropertyBooking[];
}

export default function PropertiesAccordion({
  type,
  properties,
  activeBookingsFilteredByStartDateTime,
}: PropertiesAccordionProps) {
  const [expanded, setExpanded] = useState(false);
  const { data: locations = [] } = useGetLocationsQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getEmployeeObject = getPropertyBookingCurrentReservedUser(
    activeBookingsFilteredByStartDateTime,
  );

  const getPropertyLocation = (locationId: string) => {
    const location = locations.find(
      (locationData) => locationData['@id'] === locationId,
    );

    if (!location) {
      return '';
    }

    return location.name;
  };

  const getTypeIcon = () => {
    const matchedType = propertyTypes.find(
      (property) => property.type === type,
    );

    if (!matchedType) {
      return <QuestionMarkIcon />;
    }

    return matchedType.icon;
  };

  if (!properties.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <StyledAccordion expanded={expanded} square>
        <StyledSummary
          expandIcon={<ArrowForwardIosIcon />}
          onClick={() => setExpanded((prevState) => !prevState)}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {getTypeIcon()}
            <Typography variant="subtitle1">
              {type} ({properties.length})
            </Typography>
          </Box>
        </StyledSummary>
        <AccordionDetails
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {properties.map((property) => (
            <Grid key={property.id} container alignItems="center">
              <Grid item xs={4} display="flex" alignItems="center" gap={2}>
                <Typography variant="bold">{property.name}</Typography>
                {getEmployeeObject(property) && (
                  <TitleTooltip
                    title={`${getEmployeeObject(property)?.lastname}
                          ${getEmployeeObject(property)?.firstname}`}
                  >
                    <Avatar
                      sx={{ width: '3rem', height: '3rem' }}
                      src={getEmployeeObject(property)?.avatarUrl}
                    />
                  </TitleTooltip>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{property.serialNumber}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1">
                  {property.times
                    ? `${property.bookingType} / ${property.times}`
                    : property.bookingType}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1">
                  {property.bookingAllowed ? t('label.yes') : t('label.no')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1">
                  {getPropertyLocation(property.location || '')}
                </Typography>
                <EditButton
                  onClick={() => {
                    if (!property.id) {
                      return;
                    }
                    navigate(`/properties/${property.id}`);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </AccordionDetails>
      </StyledAccordion>
    </Grid>
  );
}
