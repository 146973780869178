import { z } from 'zod';
import checkNip from 'helpers/validators/checkNip/checkNip';
import COUNTRY_PL_VALUE from './consts';

const schema = z
  .object({
    name: z
      .string()
      .min(1, { message: 'errors.too_short_or_too_long_name' })
      .max(255, { message: 'errors.too_short_or_too_long_name' }),
    country: z.string().min(1, { message: 'errors.field_required' }),
    currency: z.string().min(1, { message: 'errors.field_required' }),
    vatNumber: z.string(),
    addressCity: z.string(),
    addressPostCode: z.string(),
    addressStreetLine: z.string(),
    addressCountry: z.string(),
  })
  .refine(
    (data) => {
      if (data.country !== COUNTRY_PL_VALUE) {
        return true;
      }
      return !data.vatNumber.length || checkNip(data.vatNumber);
    },
    {
      message: 'errors.invalid_vat_number',
      path: ['vatNumber'],
    },
  );

export default schema;
