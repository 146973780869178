import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'label.bank',
    align: 'left',
  },
  {
    label: 'label.name',
    align: 'left',
  },
  {
    label: 'label.type',
    align: 'center',
  },
  {
    label: 'bank_accounts.bank_number_label',
    align: 'center',
  },
];

export default tableHeaders;
