import { ProjectPhaseTypes } from 'store/projectPhases/interfaces';
import { FormPayload } from './interfaces';

const defaultValues: FormPayload = {
  name: '',
  type: '' as ProjectPhaseTypes,
  amount: '0',
  dateFrom: '',
  dateToPrediction: '',
  dateTo: '',
  budget: '',
};

export default defaultValues;
