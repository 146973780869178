import { z } from 'zod';
import {
  AgreementAmountType,
  AgreementBillingType,
  AgreementType,
} from 'components/AgreementsList/AgreementsList';

const schema = z.object({
  position: z.string().min(1, { message: 'errors.field_required' }),
  amount: z.string().min(1, { message: 'errors.field_required' }),
  currency: z.string().min(1, { message: 'errors.field_required' }),
  hoursPerWeek: z
    .string()
    .min(1, { message: 'errors.field_required' })
    .refine((value) => Number(value) >= 0 && Number(value) <= 40, {
      message: 'errors.hours_per_week',
    }),
  billingType: z.nativeEnum(AgreementBillingType, {
    errorMap: () => ({ message: 'errors.field_required' }),
  }),
  type: z.nativeEnum(AgreementType, {
    errorMap: () => ({ message: 'errors.field_required' }),
  }),
  amountType: z.nativeEnum(AgreementAmountType, {
    errorMap: () => ({ message: 'errors.field_required' }),
  }),
});

export default schema;
