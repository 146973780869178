import React, { useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  useDeleteBankHolidayMutation,
  useGetBankHolidaysQuery,
} from 'store/bankHolidays/bankHolidays';
import Error from 'common/Error';
import Loader from 'common/Loader';
import AddHolidayForm from 'components/HolidayDaysFree/AddHolidayForm/AddHolidayForm';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomError } from 'store/api';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';

export interface HolidayDaysFreeProps extends StatusProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function HolidayDaysFree({
  isOpen,
  setIsOpen,
  setIsError,
  setIsSuccess,
  setErrorMessage,
}: HolidayDaysFreeProps) {
  const { data = [], isLoading, isError } = useGetBankHolidaysQuery();
  const [deleteHoliday] = useDeleteBankHolidayMutation();
  const { t } = useTranslation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [selectedDay, setSelectedDay] = useState(0);

  const handleDeleteHoliday = async (holidayId: number) => {
    try {
      await deleteHoliday(holidayId).unwrap();
      setIsSuccess(true);
      setIsConfirmationDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="tableHeader" color="text.secondary">
                  {t('bank_holidays.date')}
                </Typography>
              </TableCell>
              <TableCell colSpan={1} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((freeDay) => (
              <TableRow key={freeDay.id}>
                <TableCell>
                  <Typography variant="body2">{freeDay.date}</Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => {
                      setSelectedDay(freeDay.id);
                      setIsConfirmationDialogOpen(true);
                    }}
                  >
                    <DeleteIcon color="error" fontSize="large" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddHolidayForm isOpen={isOpen} setIsOpen={setIsOpen} />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleAccept={() => handleDeleteHoliday(selectedDay)}
      />
    </>
  );
}

export default HolidayDaysFree;
