import api from 'store/api';
import {
  ProjectMember,
  ProjectMemberPatchMutation,
  ProjectMemberPostMutation,
} from './interfaces';

export const projectMembersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addProjectMember: builder.mutation<
      ProjectMember,
      ProjectMemberPostMutation
    >({
      query: (body) => ({
        url: '/project-members',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Projects'],
    }),
    updateProjectMember: builder.mutation<
      ProjectMember,
      ProjectMemberPatchMutation
    >({
      query: ({ id, ...body }) => ({
        url: `/project-members/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Projects'],
    }),
    deleteProjectMember: builder.mutation<void, string>({
      query: (id) => ({
        url: `/project-members/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Projects'],
    }),
  }),
});

export const {
  useAddProjectMemberMutation,
  useUpdateProjectMemberMutation,
  useDeleteProjectMemberMutation,
} = projectMembersApi;
