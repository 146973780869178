import React from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import 'react-calendar/dist/Calendar.css';
import StyledCalendar from 'components/EditHolidayForm/DateRange/styled/Calendar.styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetPropertyBookingsQuery } from 'store/propertyBooking/propertyBooking';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import calculateTimeBetweenDates from 'helpers/calculateTimeBetweenDates';
import { BookingType } from 'components/Properties/enums';
import { LoadingButton } from '@mui/lab';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import { PropertyBooking } from 'store/propertyBooking/interfaces';
import { CalendarTileProperties } from 'react-calendar';
import useBookPropertiesContext from 'components/BookProperties/context/useBookPropertiesContext';
import getCalendarType from 'helpers/getCalendarType';
import useOrganizationStartWeekDay from 'hooks/useOrganizationStartWeekDay';
import schema from './DaysForm.schema';
import {
  DaysFormProps,
  FormPayload,
  HandleOnDateChangeProps,
} from './interfaces';

const validate = zodResolver(schema);

export default function DaysForm({
  property,
  handleOnSubmit,
  isSubmitting,
}: DaysFormProps) {
  const isPermanentBooking = property.bookingType === BookingType.permanently;
  const isPropertyManager = useCheckRoles(roles.properties);
  const startWeekDay = useOrganizationStartWeekDay();
  const { selectedDate } = useBookPropertiesContext();

  const { data: propertyBookings = [] as PropertyBooking[] } =
    useGetPropertyBookingsQuery({});

  const propertyBookingsForProperty = propertyBookings.filter(
    (booking) =>
      booking.property['@id'] === property['@id'] && !booking.cancelledAt,
  );

  const { t } = useTranslation();
  const { handleSubmit, watch, control, setValue } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: {
      dateFrom: selectedDate ?? undefined,
      dateTo: undefined,
      duration: undefined,
    },
  });
  const { dateFrom, dateTo } = watch();

  const disableSubmission = () =>
    !isPermanentBooking ? !dateFrom || !dateTo : !dateFrom;

  const calendarTypeName = getCalendarType(startWeekDay);

  const checkIsDateInBookedRange = (date: Date) =>
    propertyBookingsForProperty?.some((booking) => {
      const bookingStartDate = new Date(booking.startDate);
      if (isPermanentBooking) {
        return date >= bookingStartDate;
      }

      const bookingLastDay = new Date(booking.dateTo as string);
      bookingLastDay.setSeconds(bookingLastDay.getSeconds() - 1);

      return date >= bookingStartDate && date <= bookingLastDay;
    });

  const handleOnChangeDateFrom = ({
    newDate,
    onChange,
    onBlur,
  }: HandleOnDateChangeProps) => {
    onChange(newDate);
    onBlur();
    if (dateTo && newDate > dateTo) {
      setValue('dateTo', undefined);
      setValue('duration', null);
      return;
    }

    if (!dateTo) {
      setValue('duration', null);
      return;
    }

    const durationInSeconds = calculateTimeBetweenDates(
      dateFormat(newDate, 'yyyy-mm-dd'),
      dateFormat(dateTo, 'yyyy-mm-dd'),
    );

    setValue('duration', durationInSeconds);
  };

  const handleOnChangeDateTo = ({
    newDate,
    onChange,
    onBlur,
  }: HandleOnDateChangeProps) => {
    onChange(newDate);
    onBlur();

    const durationInSeconds = calculateTimeBetweenDates(
      dateFormat(dateFrom, 'yyyy-mm-dd'),
      dateFormat(newDate, 'yyyy-mm-dd'),
    );

    setValue('duration', durationInSeconds);
  };

  const setIsTileDisabled = ({ date }: CalendarTileProperties) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date < today) {
      return true;
    }
    const isBooked = checkIsDateInBookedRange(date);

    return !!isBooked;
  };

  const setTileClassName = ({ date }: CalendarTileProperties) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date < today) {
      return 'react-calendar__tile--booked';
    }
    const isBooked = checkIsDateInBookedRange(date);

    if (isBooked) {
      return 'react-calendar__tile--booked';
    }

    return '';
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap={3}
        p={3}
      >
        <Grid container columnSpacing={2} justifyContent="center">
          <Grid item xs={6}>
            <Controller
              name="dateFrom"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <StyledCalendar
                  value={value}
                  calendarType={calendarTypeName}
                  onChange={(newDate: Date) =>
                    handleOnChangeDateFrom({
                      newDate,
                      onChange,
                      onBlur,
                    })
                  }
                  {...(!isPropertyManager && {
                    tileDisabled: setIsTileDisabled,
                  })}
                  {...(isPropertyManager && {
                    tileClassName: setTileClassName,
                  })}
                />
              )}
            />
          </Grid>
          {!isPermanentBooking && (
            <Grid item xs={6}>
              <Controller
                name="dateTo"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <StyledCalendar
                    value={value}
                    onChange={(newDate: Date) =>
                      handleOnChangeDateTo({
                        newDate,
                        onChange,
                        onBlur,
                      })
                    }
                    minDate={dateFrom}
                    calendarType={calendarTypeName}
                    {...(!isPropertyManager && {
                      tileDisabled: setIsTileDisabled,
                    })}
                    {...(isPropertyManager && {
                      tileClassName: setTileClassName,
                    })}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <LoadingButton
          disabled={disableSubmission()}
          endIcon={<AddCircleOutlineIcon />}
          loading={isSubmitting}
          type="submit"
          variant="contained"
        >
          {t('button.save')}
        </LoadingButton>
      </Box>
    </form>
  );
}
