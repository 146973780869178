import { BookingType } from 'components/Properties/enums';
import dateFormat from 'dateformat';

const getBookingDateFromFormat = (bookingType: string, bookingDate: string) => {
  if (bookingType === BookingType.minutes) {
    return dateFormat(bookingDate, 'yyyy-mm-dd HH:MM');
  }

  return dateFormat(bookingDate, 'yyyy-mm-dd');
};

export default getBookingDateFromFormat;
