import { matchPath } from 'react-router';
import { generatePath, useLocation } from 'react-router-dom';
import { routes } from '../config/routing/routing';

export default class RouterHelper {
  static findRouteDefinition = (routeName) =>
    routes.reduce((route, definition) => {
      if (route) {
        return route;
      }
      return definition.name === routeName ? definition : route;
    }, null);

  static generate = (routeName, params) => {
    const route = this.findRouteDefinition(routeName);

    if (!route) {
      console.error(`Unrecognized route ${routeName} called in RouteGenerator`);
    }

    return generatePath(route.path, params);
  };

  static isOneOf = (routeNames) => {
    const location = useLocation();
    const currentRoute = routes.find((route) =>
      matchPath(route.path, location.pathname)
    );

    if (!currentRoute) {
      return false;
    }

    return routeNames.findIndex((name) => name === currentRoute.name) !== -1;
  };
}
