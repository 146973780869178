import React from 'react';
import { Typography, Stack, Divider } from '@mui/material';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import { useTranslation } from 'react-i18next';
import { differenceInDays } from 'date-fns';
import useResourceRequestContext from 'pages/HumanResourceResource/context/useResourceRequestContext';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import resourceRequestsBillingOptions from 'helpers/resourceRequestBillingOptions';
import CalendarIcon from 'assets/icons/CalendarIcon';
import PinMapIcon from 'assets/icons/PinMapIcon';

export default function Summary() {
  const { resourceRequest, resourceRequestCandidates } =
    useResourceRequestContext();
  const formatAmount = useCurrencyFormat();
  const { t } = useTranslation();

  const billingType = resourceRequestsBillingOptions.find(
    (option) => option.value === resourceRequest.billingType,
  );

  return (
    <StyledContentBox>
      <Typography p={3} variant="h6">
        {t('human_resources.summary')}
      </Typography>
      <Stack pb={3} px={3} direction="row" justifyContent="space-between" spacing={3}>
        <StyledContentBox py={1.5} px={2.5} width="100%">
          <Stack direction="row" spacing={1} alignItems="center">
            <CalendarIcon sx={{ color: 'primary.main', fontSize: '2.4rem' }} />
            <Stack>
              <Typography variant="body2">
                {resourceRequestCandidates.length}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {t('human_resources.overview_applicants')}
              </Typography>
            </Stack>
          </Stack>
        </StyledContentBox>
        <StyledContentBox py={1.5} px={2.5} width="100%">
          <Stack direction="row" spacing={1} alignItems="center">
            <CalendarIcon sx={{ color: 'primary.main', fontSize: '2.4rem' }} />
            <Stack>
              <Typography variant="body2">
                {differenceInDays(new Date(), resourceRequest.createdAt)}{' '}
                {t('holidays.days')}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {t('human_resources.active_since')}
              </Typography>
            </Stack>
          </Stack>
        </StyledContentBox>
        <StyledContentBox py={1.5} px={2.5} width="100%">
          <Stack direction="row" spacing={1} alignItems="center">
            <PinMapIcon sx={{ color: 'primary.main', fontSize: '2.4rem' }} />
            <Stack>
              <Typography variant="body2">
                {resourceRequest.location || t('label.not_available')}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {t('label.location').toLowerCase()}
              </Typography>
            </Stack>
          </Stack>
        </StyledContentBox>
      </Stack>
      {resourceRequest.description && (
        <Typography px={3} pb={3} color="text.secondary">
          {resourceRequest.description}
        </Typography>
      )}
      <Divider />
      <Stack p={3} direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" fontWeight={700}>
          {t('human_resources.salary_range')}
        </Typography>
        <Stack direction="row" spacing={0.25}>
          {!!resourceRequest.salaryRangeMin && (
            <Typography fontWeight={700}>
              {formatAmount(
                resourceRequest.salaryRangeMin / BACKEND_AMOUNT_MODIFIER,
              )}
            </Typography>
          )}
          {!!resourceRequest.salaryRangeMax && (
            <>
              <Typography color="text.secondary">-</Typography>
              <Typography fontWeight={700}>
                {formatAmount(
                  resourceRequest.salaryRangeMax / BACKEND_AMOUNT_MODIFIER,
                )}
              </Typography>
            </>
          )}
          {billingType && (
            <Typography color="text.secondary">
              / {t(billingType.label)}
            </Typography>
          )}
        </Stack>
      </Stack>
    </StyledContentBox>
  );
}
