import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useGetPropertyBookingsQuery } from 'store/propertyBooking/propertyBooking';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import { EmployeePreview } from 'store/employee/interfaces';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useCurrentDateEvery from 'hooks/useCurrentDateEvery';
import replaceApiIri from 'helpers/replaceApiIri';
import headers from './headers';
import Booking from './Booking';

interface EmployeeBookingsProps {
  employeeIri: string;
  setEmployeeIri: React.Dispatch<React.SetStateAction<string>>;
  employees: EmployeePreview[];
}

const minuteInMilliseconds = 60 * 1000;

export default function EmployeeBookings({
  employeeIri,
  setEmployeeIri,
  employees,
}: EmployeeBookingsProps) {
  const { t } = useTranslation();
  const currentDateEveryMinute = useCurrentDateEvery(minuteInMilliseconds);
  const isPropertiesManager = useCheckRoles(roles.properties);
  const organizationConfig = useSelector(
    (state: RootState) => state.organization.organization.config,
  );
  const isCancelReasonRequired =
    organizationConfig?.['property-booking-require-cancel-reason'];

  const startOfCurrentDay = startOfDay(new Date());

  const {
    data: employeeBookings = [],
    isFetching: employeeBookingsFetching,
    isError: employeeBookingsError,
  } = useGetPropertyBookingsQuery({
    employee: replaceApiIri(employeeIri, 'employees'),
    'dateTo[after]': startOfCurrentDay.toISOString(),
  });

  const activeEmployeeBookings = employeeBookings.filter(
    (booking) => !booking.cancelledAt,
  );

  return (
    <>
      {isPropertiesManager ? (
        <FormControl sx={{ width: { xs: '100%', md: '30%' } }}>
          <InputLabel id="employee-select">
            {t('inputs.select_employee')}
          </InputLabel>
          <Select
            name="employeeSelect"
            labelId="employee-select"
            label={t('inputs.select_employee')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '40rem',
                },
              },
            }}
            onChange={(event) => setEmployeeIri(event.target.value)}
            value={employeeIri}
          >
            {employees.map((employeeItem) => (
              <MenuItem key={employeeItem['@id']} value={employeeItem['@id']}>
                {`${employeeItem.lastname} ${employeeItem.firstname}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography variant="subtitle2">{t('booking.my_bookings')}</Typography>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBodyContent
            isError={employeeBookingsError}
            isFetching={employeeBookingsFetching}
            tableHeaders={headers}
            skeletonRowsLength={5}
          >
            {activeEmployeeBookings.map((booking) => (
              <Booking
                key={booking.id}
                booking={booking}
                dateToCompareIsBookingPast={currentDateEveryMinute}
                isCancelReasonRequired={isCancelReasonRequired}
              />
            ))}
          </TableBodyContent>
        </Table>
      </TableContainer>
    </>
  );
}
