import React from 'react';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { Box, DialogContent, TextField } from '@mui/material';
import { useValidate } from 'hooks/useValidate';
import { FormTypes } from 'enums';
import { LoadingButton } from '@mui/lab';

export interface BenefitFormProps {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formType: FormTypes;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}

interface Errors {
  name: boolean;
}

export default function BenefitForm({
  name,
  setName,
  setIsOpen,
  formType,
  handleSubmit,
  isLoading,
}: BenefitFormProps) {
  const { t } = useTranslation();
  const { errors, validate } = useValidate<Errors>();

  const disableSubmission = () =>
    !name || Object.values(errors).some((error) => error);

  return (
    <>
      <DialogHeader
        setIsOpen={setIsOpen}
        title={
          formType === FormTypes.add
            ? t('benefits.add_new')
            : t('benefits.edit')
        }
      />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={3}
            pt={2}
          >
            <TextField
              error={errors.name}
              fullWidth
              helperText={errors.name && t('errors.too_short_name')}
              label={t('label.name')}
              name="benefitName"
              onChange={(event) => {
                setName(event.target.value);
                validate('name', event.target.value.length >= 3);
              }}
              required
              value={name}
            />
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
