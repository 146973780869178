import React from 'react';
import { endOfDay, isAfter } from 'date-fns';
import { Stack, Avatar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import useBookingSchedulerContext from 'components/BookProperties/BookingForm/context/useBookingScheduler.context';
import {
  createBookingId,
  oneMinuteInMs,
} from 'components/BookProperties/BookingForm/consts';
import roles from 'config/roles/roles';
import useCheckRoles from 'hooks/useCheckRoles';
import useCurrentDateEvery from 'hooks/useCurrentDateEvery';
import checkIsSomeBookingInDateRangeDraftAppointment from 'components/BookProperties/BookingForm/checkIsSomeBookingInDateRangeDraftBooking';
import SaveBookingButton from '../SaveBookingButton/SaveBookingButton';
import { DraftAppointmentProps } from './interfaces';

export default function DraftAppointment({
  data,
  handleAddPropertyBooking,
  employeeData,
  isMultipleDaysWidthSize,
  dateFrom,
  dateTo,
  bookingDaysCount,
}: DraftAppointmentProps) {
  const { t } = useTranslation();
  const { removeBookingFromState, bookingsInState } =
    useBookingSchedulerContext();

  const currentDateEveryMinute = useCurrentDateEvery(oneMinuteInMs);

  const isPropertyManager = useCheckRoles(roles.properties);

  const isSomeBookingInDateRangeDraftAppointment =
    checkIsSomeBookingInDateRangeDraftAppointment({
      bookingsInState: bookingsInState.filter(
        (booking) => booking.id !== createBookingId,
      ),
      draftBookingStartDate: data.startDate as Date,
      draftBookingEndDate: data.endDate as Date,
    });

  const isEndDayOfStartDateAfterCurrentTime = isAfter(
    endOfDay(data.startDate),
    currentDateEveryMinute,
  );

  const isPossibleToAdd =
    isPropertyManager ||
    (isEndDayOfStartDateAfterCurrentTime &&
      !isSomeBookingInDateRangeDraftAppointment);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <SaveBookingButton
          isDraftBookingPossibleToAdd={isPossibleToAdd}
          handleAddPropertyBooking={handleAddPropertyBooking}
        />

        <Stack direction="row" alignItems="center" spacing={0.25}>
          <Typography variant="body2" color="main.white">
            {`${dateFrom} - ${dateTo}`}
          </Typography>
          {isMultipleDaysWidthSize && (
            <Typography variant="body2" color="main.white">
              {t('booking.booking_days_scheduler', { count: bookingDaysCount })}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar
          sx={{
            width: '2.4rem',
            height: '2.4rem',
            display: !isMultipleDaysWidthSize ? 'none' : 'block',
          }}
          src={employeeData?.avatarUrl || undefined}
        />
        <CloseIcon
          sx={{
            color: 'main.white',
            cursor: 'pointer',
          }}
          onClick={() => removeBookingFromState(data.id as string)}
        />
      </Stack>
    </>
  );
}
