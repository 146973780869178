import React, { useMemo, useState, useCallback } from 'react';
import { endOfMonth, startOfMonth } from 'date-fns';
import { SelectChangeEvent } from '@mui/material';
import { BudgetPeriod } from 'store/budget/interfaces';
import { BudgetPeriodContext, PeriodState } from './BudgetPeriod.context';

interface BudgetPeriodProviderProps {
  children: React.ReactNode;
}

const emptyState: PeriodState = {
  period: '',
  dateFrom: null,
  dateTo: null,
};

export default function BudgetPeriodProvider({
  children,
}: BudgetPeriodProviderProps) {
  const [periodState, setPeriodState] = useState<PeriodState>(emptyState);

  const handleOnSelectChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setPeriodState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    },
    [],
  );

  const handleOnDatePickerChange = useCallback(
    (date: Date | null, key: Omit<keyof PeriodState, 'period'>) => {
      setPeriodState((prevState) => ({
        ...prevState,
        [key as string]: date,
      }));
    },
    [],
  );

  const handleResetPeriodState = useCallback(() => {
    setPeriodState(emptyState);
  }, []);

  const modifyDateToByMonths = useCallback(
    (monthCount: number) => {
      if (!periodState.dateTo || !periodState.dateFrom) return null;

      let dateTo = new Date(periodState.dateTo);
      const dateToYear = dateTo.getFullYear();
      const dateToMonth = dateTo.getMonth();

      if (dateToMonth + monthCount > 11) {
        dateTo = endOfMonth(
          new Date(dateToYear + 1, dateToMonth + monthCount - 12),
        );
      } else if (dateToMonth + monthCount < 0) {
        dateTo = endOfMonth(
          new Date(dateToYear - 1, dateToMonth + monthCount + 12),
        );
      } else {
        dateTo = endOfMonth(new Date(dateToYear, dateToMonth + monthCount));
      }

      if (dateTo < periodState.dateFrom)
        return setPeriodState((prevState) => ({
          ...prevState,
          dateTo: endOfMonth(periodState.dateFrom as Date),
        }));

      return setPeriodState((prevState) => ({
        ...prevState,
        dateTo,
      }));
    },
    [periodState.dateFrom, periodState.dateTo],
  );

  const modifyDateFromByMonths = useCallback(
    (monthCount: number) => {
      if (!periodState.dateTo || !periodState.dateFrom) return null;

      let dateFrom = new Date(periodState.dateFrom);
      const dateFromYear = dateFrom.getFullYear();
      const dateFromMonth = dateFrom.getMonth();

      if (dateFromMonth + monthCount > 11) {
        dateFrom = startOfMonth(
          new Date(dateFromYear + 1, dateFromMonth + monthCount - 12),
        );
      } else if (dateFromMonth + monthCount < 0) {
        dateFrom = startOfMonth(
          new Date(dateFromYear - 1, dateFromMonth + monthCount + 12),
        );
      } else {
        dateFrom = startOfMonth(
          new Date(dateFromYear, dateFromMonth + monthCount),
        );
      }

      if (dateFrom > periodState.dateTo)
        return setPeriodState((prevState) => ({
          ...prevState,
          dateFrom: startOfMonth(periodState.dateTo as Date),
        }));

      return setPeriodState((prevState) => ({
        ...prevState,
        dateFrom,
      }));
    },
    [periodState.dateFrom, periodState.dateTo],
  );

  const createMonthPeriodState = useCallback(
    (date: Date) =>
      setPeriodState({
        period: BudgetPeriod.month,
        dateFrom: startOfMonth(new Date(date.getFullYear(), date.getMonth())),
        dateTo: endOfMonth(date),
      }),
    [],
  );

  const createQuarterPeriodState = useCallback((date: Date) => {
    const quarter = Math.floor(date.getMonth() / 3);

    setPeriodState({
      period: BudgetPeriod.quarter,
      dateFrom: startOfMonth(new Date(date.getFullYear(), quarter * 3)),
      dateTo: endOfMonth(new Date(date.getFullYear(), quarter * 3 + 2)),
    });
  }, []);

  const createYearPeriodState = useCallback(
    (date: Date) =>
      setPeriodState({
        period: BudgetPeriod.year,
        dateFrom: startOfMonth(new Date(date.getFullYear(), 0)),
        dateTo: endOfMonth(new Date(date.getFullYear(), 11)),
      }),
    [],
  );

  const handleChangePeriod = useCallback((period: BudgetPeriod) => {
    setPeriodState((prevState) => ({
      ...prevState,
      period,
    }));
  }, []);

  const isEmptyState =
    !periodState.period && !periodState.dateFrom && !periodState.dateTo;

  const contextValue = useMemo(
    () => ({
      periodState,
      handleOnSelectChange,
      handleOnDatePickerChange,
      handleResetPeriodState,
      modifyDateToByMonths,
      modifyDateFromByMonths,
      createMonthPeriodState,
      createQuarterPeriodState,
      createYearPeriodState,
      handleChangePeriod,
      isEmptyState,
    }),
    [
      periodState,
      handleOnSelectChange,
      handleOnDatePickerChange,
      handleResetPeriodState,
      modifyDateToByMonths,
      modifyDateFromByMonths,
      createMonthPeriodState,
      createQuarterPeriodState,
      createYearPeriodState,
      handleChangePeriod,
      isEmptyState,
    ],
  );

  return (
    <BudgetPeriodContext.Provider value={contextValue}>
      {children}
    </BudgetPeriodContext.Provider>
  );
}
