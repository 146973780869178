import { HolidayTypes } from 'enums';
import { z } from 'zod';

const typeFormSchema = z.object({
  name: z.string().min(1, { message: 'errors.field_required' }),
  status: z.string(),
  id: z
    .nativeEnum(HolidayTypes, {
      errorMap: () => ({ message: 'errors.field_required' }),
    })
    .or(z.literal('')),
});

export default typeFormSchema;
