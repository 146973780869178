import React, { useEffect, useState } from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import { useValidate } from 'hooks/useValidate';
import { Errors } from 'components/AddEmployeeForm/AddEmployeeForm';
import { Employee } from 'store/employee/interfaces';
import { useUpdateEmployeeMutation } from 'store/employee/employees';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { CustomError } from 'store/api';

export interface PersonalInfoProps extends StatusProps {
  employeeFetched: boolean;
  employee: Employee;
}

function PersonalInfo({
  employeeFetched,
  employee,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: PersonalInfoProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [changed, setChanged] = useState(false);
  const { errors, validate } = useValidate<Errors>();
  const [updateEmployee, { isLoading }] = useUpdateEmployeeMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!employeeFetched) {
      return;
    }
    setFirstName(employee.firstname);
    setLastName(employee.lastname);
    setBirthday(
      employee.birthday ? dateFormat(employee.birthday, 'yyyy-mm-dd') : '',
    );
  }, [employeeFetched, employee]);

  const disableSubmission = () => Object.values(errors).some((error) => error);

  const handleEditEmployee = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateEmployee({
        id: employee.id,
        firstname: firstName,
        lastname: lastName,
        birthday: birthday || null,
      }).unwrap();
      setIsSuccess(true);
      setChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };
  return (
    <Box p={4} bgcolor="background.list" borderRadius="1.2rem">
      <Typography variant="h5" mb={4}>
        {t('employees.personal_info')}
      </Typography>
      <form onSubmit={handleEditEmployee}>
        <FormControl fullWidth>
          <Box display="flex" flexDirection="column" rowGap={3}>
            <TextField
              required
              error={errors.firstname}
              helperText={errors.firstname && t('errors.too_short_name')}
              name="firstname"
              variant="outlined"
              label={t('label.firstname')}
              id="name"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
                setChanged(true);
              }}
              onBlur={(event) => {
                validate('firstname', event.target.value.length >= 2);
              }}
            />
            <TextField
              required
              error={errors.lastname}
              helperText={errors.lastname && t('errors.too_short_name')}
              name="lastname"
              variant="outlined"
              label={t('label.lastname')}
              id="surname"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
                setChanged(true);
              }}
              onBlur={(event) => {
                validate('lastname', event.target.value.length >= 2);
              }}
            />
            <TextField
              name="birthday"
              variant="outlined"
              label={t('label.birthday')}
              id="birthday"
              InputLabelProps={{
                shrink: true,
              }}
              value={birthday}
              onChange={(event) => {
                setBirthday(event.target.value);
                setChanged(true);
              }}
              type="date"
            />
          </Box>
        </FormControl>
        {changed && (
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<CheckIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save_changes')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
}

export default PersonalInfo;
