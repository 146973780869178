import React from 'react';
import { Grid } from '@mui/material';
import { WidgetCaption, WidgetContainer, WidgetTitle } from 'common/Widget';
import { useTranslation } from 'react-i18next';
import Employee from 'components/AbsentEmployeesList/Employee/Employee';
import AgreementBadge from 'components/EmployeeList/AgreementBadge/AgreementBadge';
import { useNavigate } from 'react-router-dom';
import { useGetActionNeededAgreementsQuery } from 'store/agreements/agreements';
import replaceApiIri from 'helpers/replaceApiIri';

function ActionNeededAgreements() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: agreements = [],
    isLoading: agreementsLoading,
    isError: agreementsError,
    isSuccess: agreementsSuccess,
  } = useGetActionNeededAgreementsQuery();

  return (
    <WidgetContainer
      title={<WidgetTitle>{t('dashboard.agreements')}</WidgetTitle>}
      isError={agreementsError}
      isLoading={agreementsLoading}
    >
      {agreementsSuccess &&
        agreements.map((agreement) => (
          <Grid
            container
            key={agreement.id}
            alignItems="center"
            justifyContent="space-between"
            sx={{ cursor: 'pointer' }}
            pb={1}
            onClick={() =>
              navigate(
                `/employees/${replaceApiIri(agreement.employee, 'employees')}`,
                {
                  state: { tabValue: '4' },
                },
              )
            }
          >
            <Grid item xs={8}>
              <Employee employeeIri={agreement.employee} />
            </Grid>
            <Grid item xs={1}>
              <WidgetCaption>{agreement.variant}</WidgetCaption>
            </Grid>
            <Grid item display="flex" justifyContent="flex-end" xs={3}>
              <AgreementBadge
                agreement={agreement}
                employeeId={+replaceApiIri(agreement.employee, 'employees')}
              />
            </Grid>
          </Grid>
        ))}
    </WidgetContainer>
  );
}

export default ActionNeededAgreements;
