import React, { useMemo, useState } from 'react';
import { BudgetPeriodSelectorContext } from './BudgetPeriodSelector.context';
import getYearGroups from '../getYearGroups';

interface BudgetPeriodSelectorProviderProps {
  children: React.ReactNode;
}

export default function BudgetPeriodSelectorProvider({
  children,
}: BudgetPeriodSelectorProviderProps) {
  const [isCreatingPeriod, setIsCreatingPeriod] = useState(false);

  const displayedYear = new Date().getFullYear();
  const yearGroups = getYearGroups(displayedYear);

  const contextValue = useMemo(
    () => ({
      displayedYear,
      yearGroups,
      isCreatingPeriod,
      setIsCreatingPeriod,
    }),
    [
      displayedYear,
      yearGroups,
      setIsCreatingPeriod,
      isCreatingPeriod,
    ],
  );

  return (
    <BudgetPeriodSelectorContext.Provider value={contextValue}>
      {children}
    </BudgetPeriodSelectorContext.Provider>
  );
}
