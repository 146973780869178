import React from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteButton from 'common/DeleteButton/DeleteButton';
import TextButton from 'common/TextButton/TextButton';

export interface CcItem {
  email: string;
  name: string;
}

interface CcDataProps {
  ccData: CcItem[];
  setCcData: React.Dispatch<React.SetStateAction<CcItem[]>>;
}

function CcData({ ccData, setCcData }: CcDataProps) {
  const { t } = useTranslation();

  const handleAddContact = () => {
    const contactData = [...ccData];
    contactData.push({
      name: '',
      email: '',
    });
    setCcData(contactData);
  };

  const handleDeleteContact = (data: CcItem) => {
    const contactData = [...ccData];
    const deletedIndex = contactData.indexOf(data);
    setCcData([
      ...contactData.slice(0, deletedIndex),
      ...contactData.slice(deletedIndex + 1),
    ]);
  };

  const handleEdit = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: CcItem,
  ) => {
    if (!ccData.length) {
      return;
    }
    const editRowIndex = ccData.indexOf(field);
    const updatedRow = { ...ccData[editRowIndex] } as CcItem;
    const keyOfRow = event.target.name as keyof CcItem;
    updatedRow[keyOfRow] = event.target.value;
    setCcData([
      ...ccData.slice(0, editRowIndex),
      updatedRow,
      ...ccData.slice(editRowIndex + 1),
    ]);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" fontWeight={700}>
          {t('invoices.cc_settings')}
        </Typography>
        <TextButton
          onClick={handleAddContact}
          label="invoice.add_cc"
          iconPosition="start"
        />
      </Stack>
      {ccData.map((item) => (
        <Box key={ccData.indexOf(item)} display="flex" gap={3}>
          <TextField
            fullWidth
            label={t('invoices.recipient_email')}
            name="email"
            onChange={(event) => handleEdit(event, item)}
            value={item.email}
          />
          <TextField
            fullWidth
            label={t('invoices.recipient_name')}
            name="name"
            onChange={(event) => handleEdit(event, item)}
            value={item.name}
          />
          <DeleteButton
            onClick={() => handleDeleteContact(item)}
            color="icon.dark"
          />
        </Box>
      ))}
    </>
  );
}

export default CcData;
