import React from 'react';
import { StatusProps } from 'hoc/Status/Status';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { Box, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttachedEmployeesSelect from 'components/EditProjectForm/AttachedEmployeesSelect/AttachedEmployeesSelect';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LoadingButton } from '@mui/lab';
import { useAddFeedbackRequestMutation } from 'store/feedbacks/feedbacks';
import { CustomError } from 'store/api';
import { LoggedEmployee } from 'store/employee/interfaces';
import createApiIri from 'helpers/createApiIri';

export interface RequestFeedbackFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeeFor?: number;
}

export default function RequestFeedbackForm({
  setIsOpen,
  setIsSuccess,
  setErrorMessage,
  setIsError,
  employeeFor,
}: RequestFeedbackFormProps) {
  const { t } = useTranslation();
  const { data: currentUser = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery(undefined, { skip: !!employeeFor });

  const selectedEmployeeId = employeeFor || currentUser.id;
  const {
    selected: selectedEmployees,
    isSelected: isEmployeeSelected,
    handleChange,
  } = useMultipleCheckboxSelect<string>([]);
  const { data: employees = [] } = useGetEmployeesPreview({
    isActive: true,
  });
  const [addRequest, { isLoading }] = useAddFeedbackRequestMutation();
  const displayedEmployees = employees.filter(
    (employee) => employee.id !== selectedEmployeeId,
  );

  const handleAddRequest = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addRequest({
        employeeFor: createApiIri('employees', `${selectedEmployeeId}`),
        requestedEmployees: selectedEmployees,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('feedbacks.request')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleAddRequest}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={3}
            pt={3}
          >
            <AttachedEmployeesSelect
              employees={displayedEmployees}
              isEmployeeSelected={isEmployeeSelected}
              selectedEmployees={selectedEmployees}
              onCheckboxChange={handleChange}
            />
            <LoadingButton
              disabled={!selectedEmployees.length}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {t('button.save')}
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
