import { useContext } from 'react';
import {
  BudgetPeriodSelectorContext,
  BudgetPeriodSelectorContextProps,
} from './BudgetPeriodSelector.context';

export default function useBudgetPeriodSelectorContext(): BudgetPeriodSelectorContextProps {
  const context = useContext(BudgetPeriodSelectorContext);

  if (!context) {
    throw new Error(
      'useBudgetPeriodSelectorContext must be used within a BudgetPeriodSelectorProvider',
    );
  }

  return context;
}
