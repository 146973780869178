import api from 'store/api';
import { ApiResponse } from 'store/interfaces';

export interface Position {
  id: number;
  name: string;
}

export interface PositionsResponseDto extends ApiResponse {
  'hydra:member': Position[];
}

export const positionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPositions: builder.query<Position[], void>({
      query: () => '/positions',
      providesTags: ['Positions'],
      transformResponse: (response: PositionsResponseDto) =>
        response['hydra:member'].sort((position, comparedPosition) =>
          position.name.localeCompare(comparedPosition.name),
        ),
    }),
    addPosition: builder.mutation({
      query: (body) => ({
        url: '/positions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Positions'],
    }),
    updatePosition: builder.mutation<
      Position,
      Partial<Position> & Pick<Position, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/positions/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Positions'],
    }),
  }),
});

export const {
  useGetPositionsQuery,
  useAddPositionMutation,
  useUpdatePositionMutation,
} = positionsApi;
