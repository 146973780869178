import React, { useState } from 'react';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { isBefore } from 'date-fns';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import { PropertyBooking } from 'store/propertyBooking/interfaces';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmationTextArea from 'common/ConfirmationTextArea/ConfirmationTextArea';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { useCancelPropertyBookingMutation } from 'store/propertyBooking/propertyBooking';
import { CustomError } from 'store/api';
import { propertyTypes } from 'components/Properties/PropertiesAccordion/propertyTypes';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { BookingType } from 'components/Properties/enums';
import endOfPrevDay from 'helpers/endOfPrevDay';
import getBookingDateFromFormat from './getBookingDateFromFormat';
import getBookingDateToFormat from './getBookingDateToFormat';

export interface BookingProps extends StatusProps {
  booking: PropertyBooking;
  dateToCompareIsBookingPast: Date;
  isCancelReasonRequired: boolean;
}

export default function Booking({
  booking,
  dateToCompareIsBookingPast,
  isCancelReasonRequired,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: BookingProps) {
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [cancelBooking] = useCancelPropertyBookingMutation();

  const handleCancelBooking = async (cancelReason?: string) => {
    const payload = isCancelReasonRequired
      ? { id: booking.id, cancelReason }
      : { id: booking.id };

    try {
      await cancelBooking(payload).unwrap();
      setIsSuccess(true);
      setIsConfirmModalOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const getDayTo = () => {
    if (booking.dateTo) {
      const dayInSeconds = 86400;
      if (booking.duration % dayInSeconds === 0) {
        return endOfPrevDay(booking.dateTo);
      }
      return booking.dateTo;
    }
    return '';
  };

  const getTypeIcon = (propertyType: string) => {
    const propertyData = propertyTypes.find(
      (property) => property.type === propertyType,
    );

    if (!propertyData) {
      return <QuestionMarkIcon />;
    }

    return propertyData.icon;
  };

  const shouldDisplayCancelButton =
    booking.property.bookingType === BookingType.permanently ||
    (booking.dateTo &&
      !isBefore(new Date(booking.dateTo), dateToCompareIsBookingPast));

  return (
    <>
      <TableRow>
        <TableCell>
          <Box display="flex" alignItems="center" gap={2}>
            {getTypeIcon(booking.property.type)}
            <Typography variant="body2">{booking.property.name}</Typography>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {getBookingDateFromFormat(
              booking.property.bookingType,
              booking.startDate,
            )}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {booking.dateTo &&
          booking.property.bookingType !== BookingType.permanently ? (
            <Typography variant="body2">
              {getBookingDateToFormat(booking.property.bookingType, getDayTo())}
            </Typography>
          ) : (
            <AllInclusiveIcon fontSize="large" />
          )}
        </TableCell>
        <TableCell align="right">
          {shouldDisplayCancelButton ? (
            <TitleTooltip title={t('label.cancel')}>
              <IconButton onClick={() => setIsConfirmModalOpen(true)}>
                <CancelIcon color="error" fontSize="large" />
              </IconButton>
            </TitleTooltip>
          ) : (
            <Box width="4rem" height="4rem" />
          )}
        </TableCell>
      </TableRow>
      {isCancelReasonRequired ? (
        <ConfirmationTextArea
          handleAccept={handleCancelBooking}
          isOpen={isConfirmModalOpen}
          setIsOpen={setIsConfirmModalOpen}
          lengthValidation={{ min: 3, max: 255 }}
        />
      ) : (
        <ConfirmationDialog
          handleAccept={() => handleCancelBooking()}
          isOpen={isConfirmModalOpen}
          setIsOpen={setIsConfirmModalOpen}
        />
      )}
    </>
  );
}
