import { createContext } from 'react';
import { Budget, BudgetConfig } from 'store/budget/interfaces';

export interface FrontendBudgetConfig extends BudgetConfig {
  placeholderBaseAmount: number;
  placeholderLinearGrowth: number;
  placeholderExponentialGrowth: number;
}

export interface OnUpdateBudgetConfigProps {
  period: string;
  field: string;
  value: string;
}

export interface BudgetPlannerContextProps {
  budget: Budget;
  onUpdateBudgetConfig: (props: OnUpdateBudgetConfigProps) => void;
  budgetConfigData: FrontendBudgetConfig[];
  handleResetBudgetConfig: () => void;
}

export const BudgetPlannerContext = createContext<
  BudgetPlannerContextProps | undefined
>(undefined);
