import React from 'react';
import { DialogContent, FormControl, Stack } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { useUpdateProjectMutation } from 'store/projects/projects';
import {
  useAddBudgetProjectsMutation,
  useGetBudgetProjectsQuery,
  useRemoveBudgetProjectsMutation,
} from 'store/budget/budget';
import useCheckRoles from 'hooks/useCheckRoles';
import useCheckBudgetIsEnabled from 'hooks/useCheckBudgetIsEnabled';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import roles from 'config/roles/roles';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import CancelButton from 'common/CancelButton/CancelButton';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import { useAppDispatch } from 'store/hooks';
import { setSuccessStatus, setErrorCatch } from 'store/status/actions';
import { OverviewProps, FormPayload } from './interfaces';
import schema from './OverviewForm.schema';
import getSelectOptions from './getSelectOptions';
import useEditProjectFormContext from '../context/useEditProjectFormContext';

const validate = zodResolver(schema);

export default function OverviewForm({
  setIsOpen,
  projectPreviews,
  clients,
  budgetPreviews,
}: OverviewProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { project } = useEditProjectFormContext();

  const {
    data: budgetProjectsData = {
      'hydra:member': [],
    },
    isLoading: budgetProjectsDataLoading,
    isError: budgetProjectsDataError,
  } = useGetBudgetProjectsQuery();

  const assignedBudgetProject = budgetProjectsData['hydra:member'].find(
    (budgetProject) => budgetProject.project['@id'] === project['@id'],
  );
  const assignedBudget = assignedBudgetProject?.budget;

  const initValues = {
    name: project.name,
    type: project.type,
    status: project.status,
    client: project.client ? project.client : '',
    budget: assignedBudget,
    parentProject: project.parent ? project.parent['@id'] : '',
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initValues,
  });

  const isAllowedToSetClient = useCheckRoles([
    ...roles.clients,
    ...roles.debtCollection,
  ]);

  const { name, type, status } = watch();

  const [addBudgetProjects, { isLoading: isAddBudgetProjectLoading }] =
    useAddBudgetProjectsMutation();
  const [removeBudgetProjects, { isLoading: isRemoveBudgetProjectLoading }] =
    useRemoveBudgetProjectsMutation();
  const [updateProject, { isLoading: isUpdateProjectLoading }] =
    useUpdateProjectMutation();

  const isSomeValueChanged = Object.keys(dirtyFields).length > 0;

  const handleUpdateProject = async (data: FormPayload) => {
    if (!isSomeValueChanged) {
      setIsOpen(false);
      return;
    }

    const { client, budget, parentProject, ...restPayload } = data;
    try {
      const isProjectChanged = (
        Object.keys(dirtyFields) as (keyof typeof dirtyFields)[]
      ).some((fieldKey) => fieldKey !== 'budget' && !!dirtyFields[fieldKey]);

      if (isProjectChanged) {
        await updateProject({
          id: project.id,
          ...restPayload,
          client: client || null,
          parent: parentProject || null,
        }).unwrap();
      }

      const isBudgetChanged = !!dirtyFields.budget;
      if (isBudgetChanged) {
        if (budget) {
          const addedBudgetProject = {
            budget,
            project: project['@id'],
          };
          await addBudgetProjects(addedBudgetProject).unwrap();
        } else if (assignedBudget) {
          await removeBudgetProjects({
            id: assignedBudgetProject?.id,
          }).unwrap();
        }
      }

      dispatch(setSuccessStatus(true));
      setIsOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };
  const isBudgetEnabled = useCheckBudgetIsEnabled();

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) || !name || !type || !status;

  const {
    projectsFilteredByStatus,
    projectTypesOptions,
    projectStatusesOptions,
    projectClientsOptions,
    projectBudgetOptions,
  } = getSelectOptions({
    clients,
    projectPreviews,
    project,
    budgetPreviews,
  });

  return (
    <ContentSuspense
      isError={budgetProjectsDataError}
      isLoading={budgetProjectsDataLoading}
    >
      <DialogHeader setIsOpen={setIsOpen} title={t('Edit project')} />
      <DialogContent>
        <form onSubmit={handleSubmit(handleUpdateProject)}>
          <FormControl fullWidth sx={{ rowGap: 3, mt: 2 }}>
            <ControlTextField
              control={control}
              label="label.name"
              errors={errors}
              name="name"
            />
            <ControlSelect
              control={control}
              label="projects.parent_project"
              name="parentProject"
              errors={errors}
              selectOptions={projectsFilteredByStatus}
              optional
            />
            <ControlSelect
              control={control}
              label="label.type"
              name="type"
              errors={errors}
              selectOptions={projectTypesOptions}
            />
            <ControlSelect
              control={control}
              label="label.status"
              name="status"
              errors={errors}
              selectOptions={projectStatusesOptions}
            />
            {isAllowedToSetClient && (
              <ControlSelect
                control={control}
                label="projects.client"
                name="client"
                errors={errors}
                selectOptions={projectClientsOptions}
                optional
              />
            )}
            {budgetPreviews && isBudgetEnabled && (
              <ControlSelect
                control={control}
                label="projects.budget_label"
                name="budget"
                errors={errors}
                selectOptions={projectBudgetOptions}
                optional
              />
            )}
          </FormControl>
          <Stack direction="row" justifyContent="flex-end" mt={3} spacing={2}>
            <CancelButton onClick={() => setIsOpen(false)} />
            <SubmitButton
              disabled={disableSubmission()}
              iconType="save"
              label="button.save_changes"
              isLoading={
                isUpdateProjectLoading ||
                isAddBudgetProjectLoading ||
                isRemoveBudgetProjectLoading
              }
            />
          </Stack>
        </form>
      </DialogContent>
    </ContentSuspense>
  );
}
