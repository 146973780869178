import React from 'react';
import { Typography, Stack, Avatar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { EmployeePreview } from 'store/employee/interfaces';

interface AppointmentContentLongProps {
  employeeData: EmployeePreview;
  dateFrom: string;
  dateTo: string;
  isBookingRemovable: boolean;
  isInDraftMode: boolean;
  isDraftBookingPossibleToAdd: boolean;
  handleAddPropertyBooking: () => void;
  handleCancelPropertyBooking: () => void;
}

export default function AppointmentContentLong({
  dateFrom,
  dateTo,
  employeeData,
  isBookingRemovable,
  isInDraftMode,
  isDraftBookingPossibleToAdd,
  handleCancelPropertyBooking,
  handleAddPropertyBooking,
}: AppointmentContentLongProps) {
  return (
    <Stack px={1.5} pt={0.5} height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Avatar
          sx={{
            width: '2.4rem',
            height: '2.4rem',
          }}
          src={employeeData?.avatarUrl || ''}
        />
        {isBookingRemovable && (
          <IconButton
            onClick={handleCancelPropertyBooking}
            sx={{ width: '2rem', height: '2rem' }}
          >
            <CloseIcon sx={{ color: 'main.white' }} />
          </IconButton>
        )}
      </Stack>
      <Stack mt={0.25} direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="caption" color="main.white">
            {dateFrom}
          </Typography>
          <Typography variant="caption" color="main.white">
            {dateTo}
          </Typography>
        </Stack>
        {isInDraftMode && (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton
              onClick={handleAddPropertyBooking}
              disabled={!isDraftBookingPossibleToAdd}
            >
              <SaveIcon
                sx={{
                  color: isDraftBookingPossibleToAdd
                    ? 'main.white'
                    : 'status.notActive.main',
                  fontSize: '2rem',
                }}
              />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
