import { yearBlockHeight } from './consts';

export default function getYearGroups(year: number) {
  const yearGroups = [
    {
      year: year - 1,
      top: -yearBlockHeight,
    },
    {
      year,
      top: 0,
    },
    {
      year: year + 1,
      top: yearBlockHeight,
    },
  ];
  return yearGroups;
}
