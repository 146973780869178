import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'employees.header_name',
    align: 'left',
  }
]

export default tableHeaders;
