import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import CalendarDayTile from 'components/CalendarDayTile/CalendarDayTile';
import { WorkingHours } from 'store/workingHours/workingHours';
import { Holiday } from 'store/holidays/interfaces';
import createCalendar from 'helpers/createCalendar';
import dateFormat from 'dateformat';
import useOrganizationStartWeekDay from 'hooks/useOrganizationStartWeekDay';

export interface CalendarProps {
  year: number;
  month: number;
  workingHours: WorkingHours[];
  holidays: string[];
  employeeHolidays: Holiday[];
  displayedEmployee: string;
  currentUserIri: string;
  remoteHolidays: Holiday[];
}
function Calendar({
  year,
  month,
  workingHours,
  holidays,
  employeeHolidays,
  displayedEmployee,
  currentUserIri,
  remoteHolidays,
}: CalendarProps) {
  const startWeekDay = useOrganizationStartWeekDay();

  const displayedDays = useMemo(
    () => createCalendar(year, month, startWeekDay),
    [year, month, startWeekDay],
  );
  return (
    <Grid container columns={14}>
      {displayedDays.map((day) => (
        <CalendarDayTile
          key={dateFormat(day, 'yyyy-mm-dd')}
          day={day}
          displayedMonth={month}
          workingHours={workingHours}
          holidays={holidays}
          employeeHolidays={employeeHolidays}
          displayedEmployee={displayedEmployee}
          currentUserIri={currentUserIri}
          remoteHolidays={remoteHolidays}
        />
      ))}
    </Grid>
  );
}

export default Calendar;
