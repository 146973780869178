import api from 'store/api';
import { setErrorCatch } from 'store/status/actions';
import replaceApiIri from 'helpers/replaceApiIri';
import {
  Holiday,
  HolidayRequest,
  HolidayRequestsFilters,
  HolidayRequestsResponseDto,
  HolidaysFilters,
  HolidaysResponseDto,
  HolidayRequestPostMutation,
  HolidayPatchMutationRequest,
  HolidayPostMutationRequest,
} from './interfaces';
import shouldModifyHolidayCache from './shouldModifyHolidayCache';

export const holidaysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHolidayRequests: builder.query<
      HolidayRequest[],
      Partial<HolidayRequestsFilters>
    >({
      query: (params) => ({
        url: '/holiday-requests',
        params,
      }),
      providesTags: ['HolidayRequests'],
      transformResponse: (response: HolidayRequestsResponseDto) =>
        response['hydra:member'].sort((request, comparedRequest) =>
          request.requestedAt.localeCompare(comparedRequest.requestedAt),
        ),
    }),
    getHolidays: builder.query<HolidaysResponseDto, Partial<HolidaysFilters>>({
      query: (filters) => ({
        url: '/holidays',
        params: filters,
      }),
      providesTags: ['Holidays'],
    }),
    getHoliday: builder.query<Holiday, number>({
      query: (id) => `/holidays/${id}`,
      providesTags: ['Holidays'],
    }),
    acceptHolidayRequest: builder.mutation({
      query: (id: number) => ({
        url: `/holiday-requests/${id}/approve`,
        method: 'POST',
      }),
    }),
    rejectHolidayRequest: builder.mutation({
      query: (id: number) => ({
        url: `/holiday-requests/${id}/reject`,
        method: 'POST',
      }),
    }),
    deleteHoliday: builder.mutation({
      query: (id: number) => ({
        url: `/holidays/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const holidaysCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Holidays' },
          ]);

          holidaysCache
            .filter(({ endpointName }) => endpointName === 'getHolidays')
            .forEach(({ originalArgs }) => {
              dispatch(
                holidaysApi.util.updateQueryData(
                  'getHolidays',
                  originalArgs,
                  (draft) => ({
                    ...draft,
                    'hydra:member': draft?.['hydra:member'].filter(
                      (item) => item.id !== id,
                    ),
                  }),
                ),
              );
            });
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
    updateHoliday: builder.mutation<Holiday, HolidayPatchMutationRequest>({
      query: ({ id, ...body }) => ({
        url: `/holidays/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Holidays'],
    }),
    addHolidayRequest: builder.mutation<
      HolidayRequest,
      HolidayRequestPostMutation
    >({
      query: (body) => ({
        url: '/holiday-requests',
        method: 'POST',
        body,
      }),
    }),
    deleteHolidayRequest: builder.mutation<void, number>({
      query: (id) => ({
        url: `/holiday-requests/${id}`,
        method: 'DELETE',
      }),
    }),
    addHoliday: builder.mutation<Holiday, HolidayPostMutationRequest>({
      query: (body) => ({
        url: '/holidays',
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: createdHoliday } = await queryFulfilled;

          const holidaysCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Holidays' },
          ]);

          holidaysCache
            .filter(({ endpointName }) => endpointName === 'getHolidays')
            .forEach(({ originalArgs }) => {
              const shouldAddToCache = shouldModifyHolidayCache(
                originalArgs,
                createdHoliday,
              );

              if (!shouldAddToCache) {
                return;
              }

              dispatch(
                holidaysApi.util.updateQueryData(
                  'getHolidays',
                  originalArgs,
                  (draft) => {
                    draft?.['hydra:member'].push(createdHoliday);
                  },
                ),
              );
            });
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
    getActiveHolidays: builder.query<Holiday[], Partial<HolidaysFilters>>({
      query: (filters) => ({
        url: '/holidays/active',
        headers: { 'content-type': 'application/merge-patch+json' },
        params: filters,
      }),
      providesTags: ['Holidays'],
      transformResponse: (response: HolidaysResponseDto) =>
        response['hydra:member'],
    }),
    addRemoteHoliday: builder.mutation({
      query: (body) => ({
        url: '/holidays',
        method: 'POST',
        body,
      }),
      async onQueryStarted(
        { employee: employeeIri },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: createdHoliday } = await queryFulfilled;
          dispatch(
            holidaysApi.util.updateQueryData(
              'getHolidays',
              { employee: replaceApiIri(employeeIri, 'employees') },
              (draft) => {
                draft?.['hydra:member'].push(createdHoliday);
              },
            ),
          );
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
    deleteRemoteHoliday: builder.mutation({
      query: ({ id }) => ({
        url: `/holidays/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { employee: employeeIri, id },
        { dispatch, queryFulfilled },
      ) {
        try {
          await queryFulfilled;
          dispatch(
            holidaysApi.util.updateQueryData(
              'getHolidays',
              { employee: replaceApiIri(employeeIri, 'employees') },
              (draft) => ({
                ...draft,
                'hydra:member': draft?.['hydra:member'].filter(
                  (holiday) => holiday.id !== id,
                ),
              }),
            ),
          );
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
  }),
});

export const {
  useGetHolidayRequestsQuery,
  useGetHolidaysQuery,
  useAcceptHolidayRequestMutation,
  useRejectHolidayRequestMutation,
  useDeleteHolidayMutation,
  useGetHolidayQuery,
  useUpdateHolidayMutation,
  useAddHolidayRequestMutation,
  useDeleteHolidayRequestMutation,
  useAddHolidayMutation,
  useGetActiveHolidaysQuery,
  useAddRemoteHolidayMutation,
  useDeleteRemoteHolidayMutation,
} = holidaysApi;
