export enum Currencies {
  pln = 'pln',
}

export enum HolidayTypes {
  vacations = 'vacations',
  disease = 'disease',
  pickUpDay = 'pick-up-day',
  marriage = 'marriage',
  army = 'army',
  notPaid = 'not-paid',
  remote = 'remote',
  onDemand = 'on-demand',
  familyMatters = 'family-matters',
  coaching = 'coaching',
  clientOffice = 'client-office',
}

export enum VatRates {
  '23%' = 23,
  '8%' = 8,
  '5%' = 5,
  '3%' = 3,
  np = 'np.',
  zw = 'zw.',
}

export enum ProjectStatuses {
  active = 'active',
  finished = 'finished',
}

export enum FormTypes {
  add = 'add',
  edit = 'edit',
}

export enum FeedbackRates {
  negative = 'negative',
  positive = 'positive',
  neutral = 'neutral',
  excellent = 'excellent',
}

export enum FeedbackVisibility {
  private = 'private',
  visible = 'visible',
}

export enum MeetingStatuses {
  requested = 'requested',
  planned = 'planned',
  finished = 'finished',
  canceled = 'canceled',
}

export enum AgreementVariants {
  uop = 'uop',
  b2b = 'b2b',
  uod = 'uod',
  uz = 'uz',
}

export enum ProjectTypes {
  fixedPrice = 'fixed-price',
  timeMaterial = 'time-and-material',
  nonBillable = 'non-billable',
  internal = 'internal',
}

export enum MuiStatusColors {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum ConditionProperties {
  projects = 'projects',
  holidays = 'holidays',
  taxes = 'taxes',
  requiredCosts = 'requiredCosts',
  necessaryCosts = 'necessaryCosts',
  slightCosts = 'slightCosts',
  internalProjects = 'internalProjects',
  nonBillableProjects = 'nonBillableProjects',
  missingWorkingHours = 'missingWorkingHours',
}

export enum CalendarType {
  us = 0,
  arabic = 6,
  iso = 1,
}

export enum ServerEventType {
  holidayRequest = 'HolidayRequest',
  holiday = 'Holiday',
}

export enum HolidayRequestStatus {
  accepted = 'accepted',
  rejected = 'rejected',
  canceled = 'canceled',
  created = 'created',
}

export enum BudgetType {
  employee = 'employee',
  global = 'global',
}
