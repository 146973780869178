import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdatePropertyMutation } from 'store/properties/properties';
import { CustomError } from 'store/api';
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from 'store/storage/storage';
import ImageFile from 'common/ImageFile/ImageFile';
import AddImageFile from 'common/AddImageFile/AddImageFile';
import { PropertyFileUploadProps } from './interfaces'

export default function PropertyFileUpload({
  setIsSuccess,
  setIsError,
  setErrorMessage,
  property,
}: PropertyFileUploadProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const propertyFile = property?.file;
  const { t } = useTranslation();

  const [updateProperty] = useUpdatePropertyMutation();
  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const handleDeletePropertyFile = async () => {
    if (!propertyFile) {
      return;
    }
    try {
      await deleteFile(propertyFile.id).unwrap();
      const payload = {
        id: property.id,
        file: null,
      };

      await updateProperty(payload).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleUploadPropertyFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    const file = event.target.files[0];

    try {
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append('relationName', 'property');
      fileData.append('relationId', property.id.toString());

      const uploadedFile = await uploadFile(fileData).unwrap();

      const payload = {
        id: property.id,
        file: uploadedFile,
      };

      await updateProperty(payload).unwrap();
      setIsSuccess(true);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (propertyFile) {
    return (
      <ImageFile
        file={propertyFile}
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        handleDeleteFile={handleDeletePropertyFile}
      />
    );
  }

  return (
    <AddImageFile
      handleUploadFile={handleUploadPropertyFile}
      uploadLabel={t('property.upload_property_photo')}
    />
  );
}
