import React, { useState } from 'react';
import {
  useGetHolidayDaysLimitsQuery,
  useAddHolidayDaysLimitMutation,
} from 'store/holidayDaysLimits/holidayDaysLimits';
import {
  Dialog,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import { useTranslation } from 'react-i18next';
import { Employee } from 'store/employee/interfaces';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import { FormTypes } from 'enums';
import AnnualRow from './AnnualRow/AnnualRow';
import AnnualLeaveForm from './AnnualLeaveForm/AnnualLeaveForm';
import { FormPayload } from './AnnualLeaveForm/interfaces';
import tableHeaders from './tableHeaders';

export interface AnnualLeaveProps {
  employee: Employee;
}

function AnnualLeave({ employee }: AnnualLeaveProps) {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const {
    data: holidayDayLimits = [],
    isLoading,
    isError,
  } = useGetHolidayDaysLimitsQuery({ employee: employee.id });
  const { t } = useTranslation();

  const [addHolidayLimit, { isLoading: addHolidayLimitLoading }] =
    useAddHolidayDaysLimitMutation();

  const handleAddAnnualLeave = async (data: FormPayload) => {
    const { days, holidayType, ...restPayload } = data;
    try {
      await addHolidayLimit({
        employee: employee['@id'],
        days: +data.days,
        ...(holidayType && { holidayType }),
        ...restPayload,
      }).unwrap();
      dispatch(setSuccessStatus(true));
      setIsAddDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={tableHeaders.length} />
            </TableRow>
          </TableHead>
          <TableBodyContent
            isError={isError}
            isFetching={isLoading}
            tableHeaders={tableHeaders}
          >
            <RowAddButton
              colSpan={5}
              onClick={() => setIsAddDialogOpen(!isAddDialogOpen)}
            >
              <Typography variant="body2" color="secondary">
                {t('employees.edit_annual_leave_add')}
              </Typography>
            </RowAddButton>
            {holidayDayLimits.map((holidayDayLimit) => (
              <AnnualRow
                key={holidayDayLimit.id}
                holidayDayLimit={holidayDayLimit}
              />
            ))}
          </TableBodyContent>
        </Table>
      </TableContainer>
      <Dialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(!isAddDialogOpen)}
        fullWidth
      >
        <AnnualLeaveForm
          isOpen={isAddDialogOpen}
          setIsOpen={setIsAddDialogOpen}
          onSubmit={handleAddAnnualLeave}
          isSubmitting={addHolidayLimitLoading}
          type={FormTypes.add}
        />
      </Dialog>
    </>
  );
}

export default AnnualLeave;
