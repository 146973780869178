import React, { useState } from 'react';
import {
  Dialog,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import headers from 'components/EditEmployeeForm/Documents/headers';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import { useTranslation } from 'react-i18next';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import { useGetEmployeeDocumentsQuery } from 'store/employee/employees';
import { skipToken } from '@reduxjs/toolkit/query';
import AddDocumentForm from 'components/AddDocumentForm/AddDocumentForm';
import Document from 'components/EditEmployeeForm/Documents/Document';

interface DocumentsProps {
  employeeId: number;
}

function Documents({ employeeId }: DocumentsProps) {
  const { t } = useTranslation();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const {
    data: documents = [],
    isLoading,
    isError,
  } = useGetEmployeeDocumentsQuery(employeeId ?? skipToken);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBodyContent
            isFetching={isLoading}
            isError={isError}
            tableHeaders={headers}
          >
            <RowAddButton
              colSpan={headers.length + 1}
              onClick={() => setIsAddFormOpen(true)}
            >
              <Typography variant="body2">
                {t('employees.documents_add_new')}
              </Typography>
            </RowAddButton>
            {documents.map((document) => (
              <Document
                key={document.id}
                document={document}
                file={document.storage}
              />
            ))}
          </TableBodyContent>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        open={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
      >
        <AddDocumentForm setIsOpen={setIsAddFormOpen} />
      </Dialog>
    </>
  );
}

export default Documents;
