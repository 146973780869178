import { InvoiceRow } from 'store/incomes/interfaces';

export default function checkIncomeRows(incomeRows: InvoiceRow[]) {
  return incomeRows.some(
    (row) =>
      !row.productName ||
      !row.netPrice ||
      !row.vatRate ||
      !row.unit ||
      !row.quantity,
  );
}
