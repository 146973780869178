import { TableHeadersProps } from 'interfaces';

interface HeadersProps extends TableHeadersProps {
  width: string;
}

const tableHeaders: HeadersProps[] = [
  { label: 'label.type', align: 'left', width: '50%' },
  { label: 'label.from', align: 'left', width: '12.5%' },
  { label: 'label.to', align: 'left', width: '12.5%' },
  { label: 'label.duration', align: 'center', width: '12.5%' },
  { label: 'label.status', align: 'center', width: '12.5%' },
];

export default tableHeaders;
