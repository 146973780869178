import React from 'react';
import { Box, FormControl } from '@mui/material';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import IntegerMaskInput from 'common/MaskInputs/IntegerMaskInput';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { taxAmounts } from 'components/EditCostForm/OverviewForm/constants';
import useCheckBudgetIsEnabled from 'hooks/useCheckBudgetIsEnabled';

interface DetailsProps<T extends FieldValues> {
  errors: FieldErrors;
  control: Control<T, any, T>;
  costEmployeeOptions: Array<{ value: string; label: string }>;
  costGroupsOptions: Array<{ value: string; label: string }>;
  costBudgetOptions: Array<{ value: string; label: string }>;
}

export default function Details<T extends FieldValues>({
  errors,
  control,
  costEmployeeOptions,
  costGroupsOptions,
  costBudgetOptions,
}: DetailsProps<T>) {
  const isBudgetEnabled = useCheckBudgetIsEnabled();

  return (
    <Box display="flex" gap={3}>
      <FormControl fullWidth>
        <ControlSelect
          control={control as Control<FieldValues, any, FieldValues>}
          label="costs.owner_employee_label"
          name="ownerEmployee"
          errors={errors}
          selectOptions={costEmployeeOptions}
        />
      </FormControl>
      <FormControl fullWidth required>
        <ControlSelect
          control={control as Control<FieldValues, any, FieldValues>}
          label="costs.cost_group_label"
          name="costGroup"
          errors={errors}
          selectOptions={costGroupsOptions}
          required
        />
      </FormControl>
      <FormControl fullWidth>
        <ControlSelect
          control={control as Control<FieldValues, any, FieldValues>}
          label="costs.tax_amount_label"
          name="taxAmount"
          errors={errors}
          selectOptions={taxAmounts}
        />
      </FormControl>
      <FormControl fullWidth>
        <ControlTextField
          errors={errors}
          control={control as Control<FieldValues, any, FieldValues>}
          name="paymentDeadline"
          type="number"
          label="costs.payment_deadline_label"
          InputProps={{
            inputComponent: IntegerMaskInput as any,
          }}
        />
      </FormControl>
      {isBudgetEnabled && (
        <FormControl fullWidth>
          <ControlSelect
            control={control as Control<FieldValues, any, FieldValues>}
            label="projects.budget_label"
            name="budget"
            errors={errors}
            selectOptions={costBudgetOptions}
            optional
          />
        </FormControl>
      )}
    </Box>
  );
}
