import React, { useState } from 'react';
import { Button, Dialog, Grid, Stack, Tab } from '@mui/material';
import { TabContext } from '@mui/lab';
import { useNavigate } from 'react-router';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import EmployeeList from 'components/EmployeeList';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddEmployeeForm from 'components/AddEmployeeForm';
import useCheckRoles from 'hooks/useCheckRoles';
import useCheckFeature from 'hooks/useCheckFeature';
import features from 'config/features/features';
import roles from 'config/roles/roles';

export enum EmployeesTabValue {
  active = '1',
  inactive = '0',
}

export default function Employees() {
  const [tabValue, setTabValue] = useState(EmployeesTabValue.active);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const isEmployeesManager = useCheckRoles(roles.employees);
  const isAgreementsFeature = useCheckFeature(features.agreements);
  const isOrganizationUsersManager = useCheckRoles(roles.organizationUsers);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: EmployeesTabValue,
  ) => setTabValue(newValue);

  return (
    <Grid item xs={12} md={10} bgcolor="background.list">
      <NavBarContainer>
        <Stack alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <NavBarTitle>{t('employees.list_header')}</NavBarTitle>
            {isOrganizationUsersManager && (
              <Button
                variant="contained"
                onClick={() => navigate('/organization/users')}
              >
                {t('label.manage_users')}
              </Button>
            )}
          </Stack>
        </Stack>
        {isEmployeesManager && (
          <Button
            variant="contained"
            onClick={() => setIsOpen(!isOpen)}
            endIcon={<AddCircleOutlineIcon />}
          >
            {t('button.create')}
          </Button>
        )}
      </NavBarContainer>
      <TabContext value={tabValue}>
        {isAgreementsFeature ? (
          <>
            <StyledTabList
              onChange={handleTabChange}
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab label={t('label.active')} value={EmployeesTabValue.active} />
              <Tab
                label={t('label.inactive')}
                value={EmployeesTabValue.inactive}
              />
            </StyledTabList>

            <EmployeeList />
          </>
        ) : (
          <EmployeeList />
        )}
      </TabContext>
      <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} fullWidth>
        <AddEmployeeForm isOpen={isOpen} setIsOpen={setIsOpen} />
      </Dialog>
    </Grid>
  );
}
