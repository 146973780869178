import { TableHeadersProps } from 'interfaces';

interface RowTableHeadersProps extends TableHeadersProps {
  required: boolean;
  width: string;
}

const tableHeaders: RowTableHeadersProps[] = [
  {
    label: 'invoices.product_name',
    align: 'center',
    required: true,
    width: '20%'
  },
  {
    label: 'invoices.project',
    align: 'center',
    required: false,
    width: '10%'
  },
  {
    label: 'GTU',
    align: 'center',
    required: false,
    width: '7.5%'
  },
  {
    label: 'invoices.quantity',
    align: 'center',
    required: true,
    width: '7.5%'
  },
  {
    label: 'invoices.units',
    align: 'center',
    required: true,
    width: '5%'
  },
  {
    label: 'invoices.net_price',
    align: 'center',
    required: true,
    width: '10%'
  },
  {
    label: 'invoices.net_value',
    align: 'center',
    required: false,
    width: '10%'
  },
  {
    label: 'VAT',
    align: 'center',
    required: true,
    width: '7.5%'
  },
  {
    label: 'invoices.total_vat',
    align: 'center',
    required: false,
    width: '10%'
  },
  {
    label: 'invoices.gross_value',
    align: 'center',
    required: false,
    width: '10%'
  },
];

export default tableHeaders;
