import { Typography, TypographyProps, styled } from '@mui/material';

const StyledValueTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    wordBreak: 'break-all',
    maxWidth: '17rem',
  }),
);

export default StyledValueTypography;
