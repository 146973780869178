import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import replaceApiIri from 'helpers/replaceApiIri';

const useCurrencyFormat = () => {
  const organizationCurrencyIri = useSelector(
    (state: RootState) => state.organization.organization.defaultCurrency,
  );
  const { i18n } = useTranslation();

  const formatAmount = (
    value: number,
    currency?: string,
    minimumFractionDigits = 2,
  ) => {
    const isCurrencyInIriFormat =
      currency && currency.includes('/api/currencies/');

    const formattedOrganizationCurrency = replaceApiIri(
      organizationCurrencyIri || '',
      'currencies',
    );
    const formattedCurrency = isCurrencyInIriFormat
      ? replaceApiIri(currency, 'currencies')
      : currency;

    if (formattedCurrency) {
      return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: formattedCurrency,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits,
      }).format(value);
    }

    if (!value) {
      return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: formattedOrganizationCurrency || '',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits,
      }).format(0);
    }

    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: formattedOrganizationCurrency || '',
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits,
    }).format(value);
  };

  return formatAmount;
};

export default useCurrencyFormat;
