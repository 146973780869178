import { useContext } from 'react';
import {
  ResourceRequestContext,
  ResourceRequestContextProps,
} from './ResourceRequest.context';

function useResourceRequestContext(): ResourceRequestContextProps {
  const context = useContext(ResourceRequestContext);

  if (!context) {
    throw new Error(
      'useResourceRequestContext must be used within a ResourceRequestProvider',
    );
  }

  return context;
}

export default useResourceRequestContext;
