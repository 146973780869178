import React from 'react';
import { Grid, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import BookIcon from '@mui/icons-material/Book';
import { WidgetCaption, WidgetContainer, WidgetTitle } from 'common/Widget';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import dateFormat from 'dateformat';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import { useGetPropertyBookingsQuery } from 'store/propertyBooking/propertyBooking';
import { LoggedEmployee } from 'store/employee/interfaces';
import getBookingDateFromFormat from 'components/EmployeeBookings/Booking/getBookingDateFromFormat';
import getBookingDateToFormat from 'components/EmployeeBookings/Booking/getBookingDateToFormat';
import createApiIri from 'helpers/createApiIri';

export default function MyPropertyBookingWidget() {
  const { t } = useTranslation();
  const currentDate = new Date();
  const {
    data: currentUser = {} as LoggedEmployee,
    isError: currentUserError,
    isLoading: currentUserLoading,
  } = useGetLoggedEmployeeQuery();
  const navigate = useNavigate();

  const {
    data: propertyBookings = [],
    isError: propertyBookingsError,
    isLoading: propertyBookingLoading,
  } = useGetPropertyBookingsQuery({
    'startDate[strictly_after]': dateFormat(currentDate, 'yyyy-mm-dd'),
  });

  const currentUserPropertyBookings = propertyBookings.filter(
    (propertyBooking) =>
      propertyBooking.employee ===
      createApiIri('employees', String(currentUser.id)),
  );

  return (
    <WidgetContainer
      title={
        <Stack direction="row" justifyContent="space-between">
          <WidgetTitle>{t('menu.property_booking')}</WidgetTitle>
          <IconButton onClick={() => navigate('/property-booking')}>
            <BookIcon sx={{ color: 'icon.main', fontSize: '1.6rem' }} />
          </IconButton>
        </Stack>
      }
      isError={currentUserError || propertyBookingsError}
      isLoading={currentUserLoading || propertyBookingLoading}
    >
      {currentUserPropertyBookings.map((propertyBooking) => (
        <Grid
          container
          key={propertyBooking.id}
          alignItems="center"
          justifyContent="space-between"
          pb={1}
        >
          <Grid item xs={4}>
            <WidgetCaption>{propertyBooking.property.name}</WidgetCaption>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            <WidgetCaption>
              {getBookingDateFromFormat(
                propertyBooking.property.bookingType,
                propertyBooking.startDate,
              )}
            </WidgetCaption>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            {propertyBooking.dateTo ? (
              <WidgetCaption>
                {getBookingDateToFormat(
                  propertyBooking.property.bookingType,
                  propertyBooking.dateTo,
                )}
              </WidgetCaption>
            ) : (
              <AllInclusiveIcon />
            )}
          </Grid>
        </Grid>
      ))}
    </WidgetContainer>
  );
}
