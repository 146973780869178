/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { select, put, takeEvery } from 'redux-saga/effects';
import { defaultHeaders } from '../../api';
import { getApiHost } from './selectors';
import { authorize, createAccount, setAuthorization } from './actions';

// function * handleFetchProjects() {
//   const state = yield select();
//   const apiHost = getApiHost(state);
//
//   const head = { ...yield defaultHeaders()};
//
//   let projects = yield fetch(`${apiHost}/api/projects`, {
//       headers: head,
//       method: 'GET',
//     })
//       .then(response => response.json())
//   ;
//
//   yield put(setProjects(projects));
// }

// function * handleCreateProject(action) {
//   const state = yield select();
//   const apiHost = getApiHost(state);
//
//   const head = { ...yield defaultHeaders() };
//
//   let project = yield fetch(`${apiHost}/api/projects`, {
//       headers: head,
//       method: 'POST',
//       body: JSON.stringify(action.payload.project),
//     })
//     .then((response) => {
//       if (!response.ok) {
//         throw response;
//       }
//       return response.json();
//     })
//   ;
//
//   if (typeof action.payload.successCallback !== 'undefined') {
//     action.payload.successCallback();
//   }
//
//   yield put(addProject(project));
// }

function* handleAuthorize(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const head = { ...(yield defaultHeaders()) };

  const { response, error } = yield fetch(`https://${apiHost}/api/login`, {
    headers: head,
    method: 'POST',
    body: JSON.stringify(action.payload),
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
  if (response && response.status === 200) {
    const json = yield response.json();
    localStorage.setItem('token', json.token);
    yield put(setAuthorization(json.token));
  } else if (response) {
    switch (response.status) {
      case 401:
        console.log(401);
        break;
    }
  } else {
    console.log('error');
  }
}

function* handleCreateAccount(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const head = { ...(yield defaultHeaders()) };

  // eslint-disable-next-line no-unused-vars
  const { response, error } = yield fetch(`https://${apiHost}/api/users`, {
    headers: head,
    method: 'POST',
    body: JSON.stringify(action.payload),
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
  if (response && response.status === 200) {
    const json = yield response.json();
    yield put(setAuthorization(json.token));
  } else if (response) {
    switch (response.status) {
      case 401:
        console.log(401);
        break;
    }
  } else {
    console.log('error');
  }
}

export default [
  takeEvery(String(authorize), handleAuthorize),
  takeEvery(String(createAccount), handleCreateAccount),
  // takeEvery(String(createProject), handleCreateProject),
];
