import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'organization_users.header_user',
    align: 'left',
  },
  {
    label: 'agreements.header_employee',
    align: 'center',
  },
];

export default tableHeaders;
