import React, { useContext } from 'react';
import { useAddPropertyBookingMutation } from 'store/propertyBooking/propertyBooking';
import formatDateToUtcFormat from 'helpers/formatDateToUtcFormat';
import { CustomError } from 'store/api';
import { BookingType } from 'components/Properties/enums';
import { EmployeeContext } from 'pages/PropertyBooking/PropertyBooking';
import { BookingFormProps } from './interfaces';
import DialogFormWrapper from './DialogFormWrapper/DialogFormWrapper';
import { FormPayload as DaysFormPayload } from './DaysForm/interfaces';
import DaysForm from './DaysForm/DaysForm';
import MinutesBookingScheduler from './MinutesBookingScheduler/MinutesBookingScheduler';
import DaysBookingScheduler from './DaysBookingScheduler/DaysBookingScheduler';
import BookingSchedulerProvider from './context/BookingSchedulerProvider';

export default function BookingForm({
  setIsOpen,
  property,
  propertyBookings,
  setIsSuccess,
  setIsErrorCatch,
}: BookingFormProps) {
  const [addBooking, { isLoading: addBookingLoading }] =
    useAddPropertyBookingMutation();
  const selectedEmployee = useContext(EmployeeContext);

  const bookingsForProperty = propertyBookings.filter(
    ({ property: { '@id': propertyIri }, cancelledAt }) =>
      propertyIri === property['@id'] && !cancelledAt,
  );

  const handleOnSubmit = async (payload: DaysFormPayload) => {
    try {
      await addBooking({
        employee: selectedEmployee,
        property: property['@id'],
        startDate: formatDateToUtcFormat(payload.dateFrom),
        duration: payload.duration,
      }).unwrap();

      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  switch (property.bookingType) {
    case BookingType.permanently:
      return (
        <DialogFormWrapper property={property}>
          <DaysForm
            handleOnSubmit={handleOnSubmit}
            isSubmitting={addBookingLoading}
            property={property}
          />
        </DialogFormWrapper>
      );
    case BookingType.days:
      return (
        <DialogFormWrapper property={property}>
          <BookingSchedulerProvider
            bookingType={BookingType.days}
            bookingsForProperty={bookingsForProperty}
            property={property}
          >
            <DaysBookingScheduler setIsOpen={setIsOpen} />
          </BookingSchedulerProvider>
        </DialogFormWrapper>
      );
    default:
      return (
        <DialogFormWrapper property={property}>
          <BookingSchedulerProvider
            bookingType={BookingType.minutes}
            bookingsForProperty={bookingsForProperty}
            property={property}
          >
            <MinutesBookingScheduler setIsOpen={setIsOpen} />
          </BookingSchedulerProvider>
        </DialogFormWrapper>
      );
  }
}
