import replaceApiIri from 'helpers/replaceApiIri';
import { CandidateNotesFilters, CandidateNote } from './interfaces';

function candidateMatches(
  candidate: string[] | string | undefined,
  createdNote: CandidateNote,
) {
  if (candidate && Array.isArray(candidate)) {
    return candidate.includes(
      replaceApiIri(createdNote.candidate, 'candidates'),
    );
  }

  if (candidate && !Array.isArray(candidate)) {
    return candidate === replaceApiIri(createdNote.candidate, 'candidates');
  }

  return true;
}

function employeeMatches(
  employee: string[] | string | undefined,
  createdNote: CandidateNote,
) {
  if (employee && Array.isArray(employee)) {
    return employee.includes(replaceApiIri(createdNote.employee, 'employees'));
  }

  if (employee && !Array.isArray(employee)) {
    return employee === replaceApiIri(createdNote.employee, 'employees');
  }

  return true;
}

function shouldModifyCandidateNoteCache(
  originalArgs: Partial<CandidateNotesFilters>,
  createdNote: CandidateNote,
) {
  const isCandidateMatches = candidateMatches(
    originalArgs.candidate,
    createdNote,
  );
  const isEmployeeMatches = employeeMatches(originalArgs.employee, createdNote);

  return isCandidateMatches && isEmployeeMatches;
}

export default shouldModifyCandidateNoteCache;
