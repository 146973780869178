import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'hooks/useValidate';
import checkMinMaxCharactersLength from 'helpers/validators/checkMinMaxCharactersLength/checkMinMaxCharactersLength';

interface ConfirmationTextAreaProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAccept: (description: string) => Promise<void> | React.MouseEventHandler<HTMLButtonElement>;
  lengthValidation: {
    min: number;
    max: number
  }
}

interface Errors {
  description: boolean;
}

function ConfirmationTextArea({
  isOpen,
  setIsOpen,
  handleAccept,
  lengthValidation: { min, max }
}: ConfirmationTextAreaProps) {
  const { t } = useTranslation();
  const { errors, validate } = useValidate<Errors>();
  const [description, setDescription] = useState('');

  const disableSubmission = () => !description ||
  Object.values(errors).some((error) => error);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogContent>
        <Typography variant="h5">{t('label.confirm_delete')}</Typography>
        <TextField
          sx={{
            marginTop: '1rem'
          }}
          fullWidth
          rows={7}
          multiline
          placeholder={t('feedbacks.give_reason')}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            validate('description', checkMinMaxCharactersLength(min, max, description));
          }}
          error={errors.description}
          helperText={errors.description && t ('feedbacks.length_allowed_from_to', { min, max })}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
          mt={2}
        >
          <Button
            onClick={() => handleAccept(description)}
            variant="contained"
            disabled={disableSubmission()}
          >
            {t('label.yes')}
          </Button>
          <Button
            color="error"
            onClick={() => setIsOpen(false)}
            variant="contained"
          >
            {t('label.cancel')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationTextArea;
