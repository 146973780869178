import formatDateToUtcFormat from './formatDateToUtcFormat';

const endOfPrevDay = (date: string | Date) => {
  const data = new Date(date);
  data.setHours(0, 0, 0, 0);
  const timeStamp = data.setMilliseconds(data.getMilliseconds() - 1);
  return formatDateToUtcFormat(timeStamp);
};

export default endOfPrevDay;
