import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { BudgetsTabValue } from 'pages/Budget/interfaces';
import BudgetPeriodActionButtons from 'components/BudgetPeriodActionButtons/BudgetPeriodActionButtons';
import BudgetPeriodForm from 'components/BudgetPeriodForm/BudgetPeriodForm';
import BudgetPeriodSelector from 'components/BudgetPeriodSelector/BudgetPeriodSelector';
import { yearBlockHeight } from 'components/BudgetPeriodSelector/consts';
import BudgetPeriodProvider from './context/BudgetPeriodProvider';

export default function BudgetPeriod() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  return (
    <Grid item xs={12} bgcolor="background.main" minHeight="100vh">
      <BudgetPeriodProvider>
        <NavBarContainer>
          <Stack direction="row" alignItems="center">
            <NavigationBackButton
              to={`/budget/${id}`}
              options={{
                state: {
                  tabValue: BudgetsTabValue.list,
                },
              }}
            />
            <Stack>
              <NavBarTitle>{t('budget.add_budget')}</NavBarTitle>
            </Stack>
          </Stack>
          <BudgetPeriodActionButtons />
        </NavBarContainer>
        <Stack minHeight={yearBlockHeight * 2} width="100%" direction="row">
          <BudgetPeriodForm />
          <BudgetPeriodSelector />
        </Stack>
      </BudgetPeriodProvider>
    </Grid>
  );
}
