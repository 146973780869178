import api, { CustomError } from 'store/api';

import {
  CompanyStartDateResponse,
  ConfigResponse,
  PropertyIds,
  InvoiceSendCcsResponse,
} from './interfaces';
import updateOrganizationsCache from './updateOrganizationsCache';
import { setErrorMessage, setErrorStatus } from '../status/actions';

export const configApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGoogleCalendarStatus: builder.query<ConfigResponse<boolean>, void>({
      query: () => '/configs/google-calendar-enabled',
      providesTags: ['GoogleCalendarStatus'],
    }),
    updateGoogleCalendarStatus: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/google-calendar-enabled',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['GoogleCalendarStatus'],
    }),
    getGoogleCalendarId: builder.query<ConfigResponse<string>, void>({
      query: () => '/configs/google-calendar-client-holidays',
      providesTags: ['GoogleCalendarId'],
    }),
    updateGoogleCalendarId: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/google-calendar-client-holidays',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['GoogleCalendarId'],
    }),
    getApprovalsCount: builder.query<ConfigResponse<string>, void>({
      query: () => '/configs/company-holidays-request-approvals-count',
      providesTags: ['ApprovalsCount'],
    }),
    updateApprovalsCount: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/company-holidays-request-approvals-count',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['ApprovalsCount'],
    }),
    getHolidaysDaysLimit: builder.query<ConfigResponse<string>, void>({
      query: () => 'configs/company-holidays-days-limit',
      providesTags: ['HolidaysDaysLimit'],
    }),
    updateHolidaysDaysLimit: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: 'configs/company-holidays-days-limit',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['HolidaysDaysLimit'],
    }),
    getConditionSectionsOrder: builder.query<ConfigResponse<string[]>, void>({
      query: () => '/configs/condition-sections-order',
      providesTags: ['ConditionOrder'],
    }),
    getTaxesCosts: builder.query<PropertyIds, void>({
      query: () => '/configs/condition-taxes-costs',
    }),
    getRequiredCosts: builder.query<PropertyIds, void>({
      query: () => '/configs/condition-required-costs',
    }),
    getNecessaryCosts: builder.query<PropertyIds, void>({
      query: () => '/configs/condition-necessary-costs',
    }),
    getSlightCosts: builder.query<PropertyIds, void>({
      query: () => '/configs/condition-slight-costs',
    }),
    getCompanyWorkingDays: builder.query<ConfigResponse<number[]>, void>({
      query: () => '/configs/company-working-days',

      providesTags: ['CompanyWorkingDays'],
    }),
    updateCompanyWorkingDays: builder.mutation<
      ConfigResponse<number[]>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/company-working-days',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedCompanyWorkingDays } = await queryFulfilled;

          updateOrganizationsCache({
            changedConfig: {
              'company-working-days': updatedCompanyWorkingDays.value,
            },
          });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    getOrganizationWeekStartDay: builder.query<ConfigResponse<number>, void>({
      query: () => '/configs/organization-week-start-day',
      providesTags: ['OrganizationWeekStartDay'],
    }),
    updateOrganizationWeekStartDay: builder.mutation<
      ConfigResponse<number>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/organization-week-start-day',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedOrganizationWeekStartDay } =
            await queryFulfilled;

          updateOrganizationsCache({
            changedConfig: {
              'organization-week-start-day':
                updatedOrganizationWeekStartDay.value,
            },
          });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    getCompanyDefaultCurrency: builder.query<ConfigResponse<string>, void>({
      query: () => '/configs/company-default-currency',
      providesTags: ['CompanyDefaultCurrency'],
    }),
    updateCompanyDefaultCurrency: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/company-default-currency',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['CompanyDefaultCurrency'],
    }),
    getCompanyStartDate: builder.query<CompanyStartDateResponse, void>({
      query: () => '/configs/company-start-date',

      providesTags: ['CompanyStartDate'],
    }),
    updateCompanyStartDate: builder.mutation<
      CompanyStartDateResponse,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/company-start-date',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['CompanyStartDate'],
    }),
    getInvoicePrefix: builder.query<ConfigResponse<string>, void>({
      query: () => '/configs/invoice-prefix',
      providesTags: ['InvoicePrefix'],
    }),
    updateInvoicePrefix: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/invoice-prefix',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['InvoicePrefix'],
    }),
    getCompanyTaxIdNumber: builder.query<ConfigResponse<string>, void>({
      query: () => '/configs/company-tax-identification-number',

      providesTags: ['CompanyTaxIdNumber'],
    }),
    updateCompanyTaxIdNumber: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/company-tax-identification-number',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['CompanyTaxIdNumber'],
    }),
    getOrganizationBillingAddress: builder.query<ConfigResponse<string>, void>({
      query: () => '/configs/organization-billing-address',

      providesTags: ['OrganizationBillingAddress'],
    }),
    updateOrganizationBillingAddress: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/organization-billing-address',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['OrganizationBillingAddress'],
    }),
    getOrganizationBillingPhoneNumber: builder.query<
      ConfigResponse<string>,
      void
    >({
      query: () => '/configs/organization-billing-phone-number',
      providesTags: ['OrganizationBillingPhoneNumber'],
    }),
    updateOrganizationBillingPhoneNumber: builder.mutation<
      ConfigResponse<string>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/organization-billing-phone-number',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['OrganizationBillingPhoneNumber'],
    }),
    getInvoiceSendCcs: builder.query<InvoiceSendCcsResponse, void>({
      query: () => '/configs/invoice-send-ccs',

      providesTags: ['InvoiceSendCcs'],
    }),
    updateInvoiceSendCcs: builder.mutation<
      InvoiceSendCcsResponse,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/invoice-send-ccs',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['InvoiceSendCcs', 'InvoicesSend'],
    }),
    getPropertyBookingDaysLimit: builder.query<ConfigResponse<number>, void>({
      query: () => '/configs/property-booking-days-limit',
      providesTags: ['PropertyBookingDaysLimit'],
    }),
    updatePropertyBookingDaysLimit: builder.mutation<
      ConfigResponse<number>,
      Partial<ConfigResponse<string>>
    >({
      query: (body) => ({
        url: '/configs/property-booking-days-limit',
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPropertyBookingDaysLimit } =
            await queryFulfilled;

          dispatch(
            configApi.util.updateQueryData(
              'getPropertyBookingDaysLimit',
              undefined,
              () => updatedPropertyBookingDaysLimit,
            ),
          );
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
  }),
});

export const {
  useGetGoogleCalendarStatusQuery,
  useUpdateGoogleCalendarStatusMutation,
  useGetGoogleCalendarIdQuery,
  useUpdateGoogleCalendarIdMutation,
  useGetApprovalsCountQuery,
  useUpdateApprovalsCountMutation,
  useGetHolidaysDaysLimitQuery,
  useUpdateHolidaysDaysLimitMutation,
  useGetConditionSectionsOrderQuery,
  useGetTaxesCostsQuery,
  useGetRequiredCostsQuery,
  useGetNecessaryCostsQuery,
  useGetSlightCostsQuery,
  useGetCompanyWorkingDaysQuery,
  useUpdateCompanyWorkingDaysMutation,
  useGetOrganizationWeekStartDayQuery,
  useUpdateOrganizationWeekStartDayMutation,
  useGetCompanyDefaultCurrencyQuery,
  useUpdateCompanyDefaultCurrencyMutation,
  useGetCompanyStartDateQuery,
  useUpdateCompanyStartDateMutation,
  useGetInvoicePrefixQuery,
  useUpdateInvoicePrefixMutation,
  useGetCompanyTaxIdNumberQuery,
  useUpdateCompanyTaxIdNumberMutation,
  useGetOrganizationBillingAddressQuery,
  useUpdateOrganizationBillingAddressMutation,
  useGetOrganizationBillingPhoneNumberQuery,
  useUpdateOrganizationBillingPhoneNumberMutation,
  useGetInvoiceSendCcsQuery,
  useUpdateInvoiceSendCcsMutation,
  useGetPropertyBookingDaysLimitQuery,
  useUpdatePropertyBookingDaysLimitMutation,
} = configApi;
