import React, { useState } from 'react';
import {
  Box,
  Dialog,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  DocumentType,
  useGetDocumentTypesQuery,
} from 'store/documentTypes/documentTypes';
import { StorageResponse } from 'store/storage/storage';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import DownloadIcon from '@mui/icons-material/Download';
import EditButton from 'common/EditButton/EditButton';
import EditDocumentForm from 'components/EditDocumentForm';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useDeleteDocumentMutation,
  DocumentData,
} from 'store/documents/documents';
import { StatusProps } from 'hoc/Status/Status';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { CustomError } from 'store/api';

export interface DocumentProps extends StatusProps {
  document: DocumentData;
  file: StorageResponse;
}

const emptyDocType = {} as DocumentType;

function Document({
  document,
  file,
  setIsError,
  setIsSuccess,
  setErrorMessage,
}: DocumentProps) {
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(0);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const { docType } = useGetDocumentTypesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      docType:
        data?.find((item) => item['@id'] === document.documentType) ?? emptyDocType,
    }),
  });
  const [deleteDocument] = useDeleteDocumentMutation();

  const handleDocumentDelete = async (id: number) => {
    try {
      await deleteDocument(id).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{docType.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{document.notes}</Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton
            key={file.id}
            onClick={() => downloadFileWithAuth(file.url, file.id)}
          >
            <DownloadIcon fontSize="large" />
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <EditButton
              onClick={() => {
                setSelectedDocument(document.id);
                setIsEditFormOpen(true);
              }}
            />
            <IconButton
              onClick={() => {
                setSelectedDocument(document.id);
                setIsConfirmationDialogOpen(true);
              }}
            >
              <DeleteIcon color="error" fontSize="large" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <Dialog
        fullWidth
        open={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
      >
        <EditDocumentForm
          documentId={selectedDocument}
          setIsOpen={setIsEditFormOpen}
        />
      </Dialog>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleAccept={() => handleDocumentDelete(selectedDocument)}
      />
    </>
  );
}

export default Document;
