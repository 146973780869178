import { MuiStatusColors } from 'enums';
import { EmployeeValues, RangeConditions } from 'store/condition/interfaces';

export const calculatePropertySum = (
  data: RangeConditions,
  month: string,
  property: string,
  projectId: number,
) => {
  let sum = 0;

  if (!data[month] || !data[month][projectId]) {
    return sum;
  }

  Object.keys(data[month][projectId]).forEach((employee) => {
    sum += data[month][projectId][+employee][property as keyof EmployeeValues];
  });

  return sum;
};

export const calculateMonthlyBalance = (costs: number, incomes: number) =>
  incomes - costs;

export const getChipColor = (month: string, checkedValue: number) => {
  if (checkedValue === 0) {
    return MuiStatusColors.warning;
  }
  if (checkedValue < 0) {
    return MuiStatusColors.error;
  }

  return MuiStatusColors.success;
};

export const getMonthlyProjectsBalance = (
  month: string,
  data: RangeConditions,
  projects: string[],
) => {
  const convertedProjects = projects.map((project) => +project);
  let sum = 0;
  convertedProjects.forEach((project) => {
    const incomesSum = calculatePropertySum(data, month, 'income', project);
    const costsSum = calculatePropertySum(data, month, 'cost', project);
    const balance = incomesSum - costsSum;
    sum += balance;
  });

  return sum;
};
