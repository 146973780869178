import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import headers from 'components/HolidaysList/headers';
import { useTranslation } from 'react-i18next';
import Result from 'components/HolidaysList/Result/Result';
import { Holiday } from 'store/holidays/interfaces';
import Filters from 'components/HolidaysList/Filters/Filters';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import { HolidayType } from 'store/holidayTypes/interfaces';

export interface HolidaysListProps {
  holidays: Holiday[];
  holidayTypes: HolidayType[];
  isFetching: boolean;
  isError: boolean;
}

function HolidaysList({
  holidays,
  isError,
  isFetching,
  holidayTypes,
}: HolidaysListProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="subtitle2" pt={5}>
        {t('holidays.holidays_list')}
      </Typography>
      <Filters holidayTypes={holidayTypes} />
      <TableContainer sx={{ mt: 3 }}>
        <Table data-testid="holidays-table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              {!!holidays.length && <TableCell colSpan={headers.length} />}
            </TableRow>
          </TableHead>
          <TableBodyContent
            isError={isError}
            isFetching={isFetching}
            tableHeaders={headers}
            skeletonRowsLength={5}
          >
            {holidays.map((holiday) => (
              <Result key={holiday.id} holiday={holiday} />
            ))}
          </TableBodyContent>
        </Table>
      </TableContainer>
    </>
  );
}

export default HolidaysList;
