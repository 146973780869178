import React, { useState } from 'react';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useValidate } from 'hooks/useValidate';
import { StatusProps } from 'hoc/Status/Status';
import {
  useAddCurrencyMutation,
  useGetCurrenciesQuery,
} from 'store/currencies/currencies';
import currenciesData from 'components/CurrenciesList/currenciesData';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface AddCurrencyFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Errors {
  currency: boolean;
}

function AddCurrencyForm({
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddCurrencyFormProps) {
  const [currency, setCurrency] = useState('');
  const { t } = useTranslation();
  const { errors, validate } = useValidate<Errors>();
  const [addCurrency, { isLoading }] = useAddCurrencyMutation();
  const { data: currencies = [] } = useGetCurrenciesQuery();

  const disableSubmission = () =>
    Object.values(errors).some((error) => error) || !currency;

  const handleAddCurrency = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addCurrency(JSON.parse(currency)).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  const filteredOptions = currenciesData.filter(
    (currencyData) =>
      !currencies.some(
        (singleCurrency) => singleCurrency.id === currencyData.id,
      ),
  );

  return (
    <>
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">{t('currencies.add_new')}</Typography>
        <IconButton onClick={() => setIsOpen(false)}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddCurrency}>
          <FormControl
            required
            fullWidth
            error={errors.currency}
            sx={{ mt: 4 }}
          >
            <InputLabel id="currency-select">
              {t('currencies.select')}
            </InputLabel>
            <Select
              id="currency-select"
              value={currency}
              label={t('currencies.select')}
              onChange={(event) => {
                setCurrency(event.target.value);
                validate('currency', event.target.value !== '');
              }}
            >
              {filteredOptions.length ? (
                filteredOptions.map((currencyData) => (
                  <MenuItem
                    key={currencyData.id}
                    value={JSON.stringify(currencyData)}
                  >
                    {t(currencyData.name)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>{t('currencies.no_available')}</em>
                </MenuItem>
              )}
            </Select>
            {errors.currency && (
              <FormHelperText>{t('errors.field_required')}</FormHelperText>
            )}
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default AddCurrencyForm;
