import { SelectChangeEvent } from '@mui/material';
import { SetURLSearchParams } from 'react-router-dom';

interface UsePagination {
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  defaultItemsPerPage?: number;
}

export default function usePagination({
  searchParams,
  setSearchParams,
  defaultItemsPerPage = 10,
}: UsePagination) {
  const defaultPage = 1;
  const queryPage = searchParams.get('page');
  const queryItemsPerPage = searchParams.get('itemsPerPage');

  const page = queryPage ? +queryPage : defaultPage;
  const itemsPerPage = queryItemsPerPage
    ? +queryItemsPerPage
    : defaultItemsPerPage;

  const handleOnPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams.toString());
      if (value > defaultPage) {
        newSearchParams.set('page', String(value));
        return newSearchParams;
      }

      newSearchParams.delete('page');
      return newSearchParams;
    });

    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const clearPage = () => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams.toString());
      newSearchParams.delete('page');
      return newSearchParams;
    });
  };

  const handleOnItemsPerPageChange = (event: SelectChangeEvent<unknown>) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams.toString());
      newSearchParams.set('itemsPerPage', event.target.value as string);
      return newSearchParams;
    });

    window.scroll({ top: 0, behavior: 'smooth' });
  };

  return {
    page,
    clearPage,
    itemsPerPage,
    handleOnPageChange,
    handleOnItemsPerPageChange,
  };
}
