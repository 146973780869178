import dateFormat from 'dateformat';

const getAmountMonthsBack = (startDate: Date, monthsAmount: number) => {
  const year = startDate.getFullYear();
  const month = startDate.getMonth();
  const periods: string[] = [];

  for (let i = monthsAmount; i >= 0; i -= 1) {
    periods.push(dateFormat(new Date(year, month - i), 'yyyy-mm'));
  }

  return periods;
};

export default getAmountMonthsBack;
