const features = {
  agreements: 'agreements',
  banks: 'banks',
  benefits: 'benefits',
  bookings: 'bookings',
  budget: 'budget',
  clients: 'clients',
  costs: 'costs',
  countries: 'countries',
  currencies: 'currencies',
  employees: 'employees',
  feedback: 'feedback',
  holidays: 'holidays',
  incomes: 'incomes',
  projects: 'projects',
  responsibilities: 'responsibilities',
  sales: 'sales',
  skills: 'skills',
  transactions: 'transactions',
  hr: 'hr',
  // There aren't feature like `properties` use `bookings` instead
};

export default features;
