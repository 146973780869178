import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  Box,
  IconButton,
} from '@mui/material';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { useLocation, Location } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import AddFeedbackForm from 'components/AddFeedbackForm';
import { useGetFeedbackRequestsQuery } from 'store/feedbacks/feedbacks';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import ScheduleMeetingForm from 'components/ScheduleMeetingForm';
import replaceApiIri from 'helpers/replaceApiIri';
import { useGetMeetingsQuery } from 'store/meetings/meetings';
import { MeetingStatuses } from 'enums';
import { LoggedEmployee, EmployeePreview } from 'store/employee/interfaces';
import { FeedbackRequestData } from 'store/feedbacks/interfaces';
import AddNoteButton from './AddNoteButton/AddNoteButton';
import EmployeeMeetings from './EmployeeMeetings/EmployeeMeetings';

interface FeedbackLocation extends Omit<Location, 'state'> {
  state: {
    employeeIri?: string;
  };
}

export default function FeedbacksList() {
  const location: FeedbackLocation = useLocation();
  const { t } = useTranslation();

  const { data: currentUser = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery();
  const {
    data: employees = [],
    isLoading: employeesFetching,
    isError: employeesFetchError,
  } = useGetEmployeesPreview({ isActive: true });

  const [isAddFormOpen, setIsAddFormOpen] = useState(
    !!location.state?.employeeIri,
  );
  const [selectedEmployeeIri, setSelectedEmployeeIri] = useState(
    location.state?.employeeIri ?? '',
  );

  const {
    data: feedbackRequestsData = {} as FeedbackRequestData,
    isLoading: requestsFetching,
    isError: requestsFetchError,
  } = useGetFeedbackRequestsQuery({});

  const feedbackRequests = feedbackRequestsData['hydra:member'] || [];

  const displayedEmployees = employees.filter(
    (employee) => employee.id !== currentUser.id,
  );
  const employeeRequests = feedbackRequests.filter(
    (item) =>
      replaceApiIri(item.requestedEmployee, 'employees') ===
      String(currentUser.id),
  );
  const isMeetingManager = useCheckRoles(roles.meetingManager);
  const { isLoading: meetingsFetching, isError: meetingsFetchError } =
    useGetMeetingsQuery(
      { status: [MeetingStatuses.requested, MeetingStatuses.planned] },
      { skip: !isMeetingManager },
    );
  const [employeeMeeting, setEmployeeMeeting] = useState<EmployeePreview>(
    {} as EmployeePreview,
  );
  const [isMeetingFormOpen, setIsMeetingFormOpen] = useState(false);

  const checkIfUserIsRequested = (employeeId: string) => {
    if (!feedbackRequests.length) {
      return false;
    }

    const refactoredRequests = employeeRequests.map((item) =>
      replaceApiIri(item.employeeFor, 'employees'),
    );

    return refactoredRequests.includes(employeeId);
  };

  const employeesFeedback = displayedEmployees
    .map((employee) => ({
      ...employee,
      currentUserIsRequested: checkIfUserIsRequested(String(employee.id)),
    }))
    .sort(
      (employee, comparedEmployee) =>
        +comparedEmployee.currentUserIsRequested -
        +employee.currentUserIsRequested,
    );

  if (employeesFetching || requestsFetching || meetingsFetching) {
    return <Loader />;
  }

  if (employeesFetchError || requestsFetchError || meetingsFetchError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer sx={{ px: 3, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="tableHeader" color="text.secondary">
                  {t('label.name')}
                </Typography>
              </TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </TableHead>
          <TableBody>
            {employeesFeedback.map((employee) => (
              <TableRow key={employee.id}>
                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2">
                      {`${employee.lastname} ${employee.firstname}`}
                    </Typography>
                    {isMeetingManager && (
                      <IconButton
                        onClick={() => {
                          setEmployeeMeeting(employee);
                          setIsMeetingFormOpen(true);
                        }}
                      >
                        <VideoCallIcon fontSize="large" />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <EmployeeMeetings employeeIri={employee['@id']} />
                </TableCell>
                <TableCell align="right">
                  <AddNoteButton
                    employeeIri={employee['@id']}
                    isUserRequested={employee.currentUserIsRequested}
                    setIsAddFormOpen={setIsAddFormOpen}
                    setSelectedEmployee={setSelectedEmployeeIri}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        onClose={() => setIsAddFormOpen(false)}
        open={isAddFormOpen}
      >
        <AddFeedbackForm
          selectedEmployee={selectedEmployeeIri}
          setIsOpen={setIsAddFormOpen}
        />
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => setIsMeetingFormOpen(false)}
        open={isMeetingFormOpen}
      >
        <ScheduleMeetingForm
          selectedEmployee={employeeMeeting}
          setIsOpen={setIsMeetingFormOpen}
        />
      </Dialog>
    </>
  );
}
