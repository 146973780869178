import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
} from '@mui/material';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import CandidateCell from './CandidateCell/CandidateCell';
import tableHeaders from './tableHeaders';
import { HrCandidatesTableProps } from './interfaces';

export default function HrCandidatesTable({
  candidatesData,
  candidatesError,
  candidatesFetching,
}: HrCandidatesTableProps) {
  const { t } = useTranslation();

  const {
    selected: selectedCandidateIds,
    handleChangeByValue: handleCheckboxClick,
    toggleSelectAll: handleHeaderCheckboxClick,
    isSelected: isCheckboxSelected,
  } = useMultipleCheckboxSelect<string>([]);

  const candidates = candidatesData?.['hydra:member'] ?? [];

  return (
    <TableContainer
      sx={{
        pt: 3,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                onChange={() =>
                  handleHeaderCheckboxClick(
                    candidates.map((candidate) => candidate.id),
                  )
                }
                checked={
                  !candidatesFetching &&
                  candidates.length === selectedCandidateIds.length
                }
              />
            </TableCell>
            {tableHeaders.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={1} />
          </TableRow>
        </TableHead>
        <TableBodyContent
          isError={candidatesError}
          isFetching={candidatesFetching}
          tableHeaders={tableHeaders}
        >
          {candidates.map((candidate) => (
            <CandidateCell
              key={candidate.id}
              candidate={candidate}
              handleCheckboxClick={handleCheckboxClick}
              isCheckboxSelected={isCheckboxSelected}
            />
          ))}
        </TableBodyContent>
      </Table>
    </TableContainer>
  );
}
