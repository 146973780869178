import { HolidayTypes } from 'enums';
import { FormPayload } from './interfaces';

const defaultValues: FormPayload = {
  type: '' as HolidayTypes,
  description: '',
  dateFrom: new Date(),
  dateTo: new Date(),
  hours: ''
};

export default defaultValues;
