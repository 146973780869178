import React from 'react';
import { useGetCostsChartsQuery } from 'store/costGroups/costGroups';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import Loader from 'common/Loader';
import Error from 'common/Error';
import CostsChart from 'components/CostsChart/CostsChart';
import { useTranslation } from 'react-i18next';

function CostsCharts() {
  const { t } = useTranslation();
  const { isLoading, isError, isSuccess } = useGetCostsChartsQuery();

  return (
    <Grid item xs={10} bgcolor="main.white">
      <NavBarContainer>
        <NavBarTitle>{t('charts.header')}</NavBarTitle>
      </NavBarContainer>
      {isLoading && <Loader />}
      {isError && <Error />}
      {isSuccess && <CostsChart />}
    </Grid>
  );
}

export default CostsCharts;
