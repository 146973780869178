import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import useBudgetPeriodContext from 'pages/BudgetPeriod/context/useBudgetPeriodContext';
import { isAfter, isBefore, startOfYear } from 'date-fns';
import { BudgetPeriod } from 'store/budget/interfaces';
import {
  yearBlockHeight,
  yearBlockWidth,
  yearBlockWrapperWidth,
} from '../consts';
import useBudgetPeriodSelectorContext from '../context/useBudgetPeriodSelectorContext';

const monthsThatCoversYearText = [6, 7];

export default function YearGroups() {
  const { yearGroups } = useBudgetPeriodSelectorContext();
  const { periodState, createYearPeriodState, isEmptyState } =
    useBudgetPeriodContext();
  const { isCreatingPeriod, setIsCreatingPeriod } =
    useBudgetPeriodSelectorContext();

  const isYearTextCovered = (year: number) => {
    if (
      !periodState?.dateTo ||
      !periodState?.dateFrom ||
      periodState?.period !== BudgetPeriod.year
    )
      return false;

    const monthFrom = monthsThatCoversYearText[0];
    const monthFromDate = new Date(year, monthFrom - 1);

    const monthTo = monthsThatCoversYearText[1];
    const monthToDate = new Date(year, monthTo - 1);

    return (
      !isAfter(new Date(periodState.dateFrom), monthToDate) &&
      !isBefore(new Date(periodState.dateTo), monthFromDate)
    );
  };

  const onYearOnMouseDown = (year: number) => {
    const yearBlockDate = new Date(year, 0);
    if (!isEmptyState || isBefore(yearBlockDate, startOfYear(new Date())))
      return false;

    createYearPeriodState(yearBlockDate);
    return setIsCreatingPeriod(true);
  };

  return (
    <Box
      position="relative"
      width={yearBlockWrapperWidth}
      bgcolor="background.other"
    >
      {yearGroups.map((yearItem) => (
        <Stack
          key={yearItem.year}
          height={yearBlockHeight}
          width={yearBlockWidth}
          alignItems="center"
          justifyContent="center"
          data-year={yearItem.year}
          onMouseDown={() => onYearOnMouseDown(yearItem.year)}
          sx={{
            borderLeft: '0.1rem solid',
            borderTop: '0.1rem solid',
            borderColor: 'border.divider',
            position: 'absolute',
            left: 0,
            top: yearItem.top,
            cursor: isEmptyState ? 'pointer' : 'default',
            '&:hover': {
              backgroundColor:
                !isCreatingPeriod && isEmptyState
                  ? 'primary.light'
                  : 'transparent',
            },
          }}
        >
          <Typography
            variant="h5"
            color={
              isYearTextCovered(yearItem.year) ? 'common.white' : 'text.primary'
            }
            sx={{
              transform: 'rotate(90deg)',
              userSelect: 'none',
              pointerEvents: 'none',
            }}
            fontWeight={500}
            zIndex={3}
          >
            {yearItem.year}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}
