import React, { useMemo } from 'react';
import { Box, CardMedia, Grid, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import { WorkingHours } from 'store/workingHours/workingHours';
import fullDateFormat from 'helpers/fullDateFormat';
import freeDayBg from 'assets/img/Frame 45.png';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { useTranslation } from 'react-i18next';
import { useGetBankHolidaysQuery } from 'store/bankHolidays/bankHolidays';
import { Holiday } from 'store/holidays/interfaces';

interface DayTileProps {
  day: Date;
  dateRange: [Date, Date];
  workingHours: WorkingHours[];
  employeeHolidays: Holiday[];
}

function DayTile({
  day,
  dateRange,
  workingHours,
  employeeHolidays,
}: DayTileProps) {
  const { t } = useTranslation();
  const { data: holidays = [] } = useGetBankHolidaysQuery();
  const tileDate = fullDateFormat(day);
  const selectedMonth = day.getMonth();
  const selectedDay = day.getDay();
  const selectedYear = day.getFullYear();
  const isSaturday = selectedDay === 6;
  const isSunday = selectedDay === 0;
  const dayHours = workingHours.filter(
    (hours) => fullDateFormat(hours.date) === tileDate,
  );
  const hoursSum = dayHours.reduce((acc, { hours }) => acc + hours, 0);
  const formattedHolidays = useMemo(
    () =>
      holidays.map((holiday) =>
        holiday.date.replace('*', String(selectedYear)),
      ),
    [holidays, selectedYear],
  );
  const isHoliday = formattedHolidays.includes(tileDate);

  const utcFormattedDay = new Date(
    Date.UTC(day.getFullYear(), day.getMonth(), day.getDate()),
  );

  const isEmployeeHoliday = employeeHolidays.some(
    (holiday) =>
      utcFormattedDay >= new Date(holiday.dateFrom) &&
      utcFormattedDay <= new Date(holiday.dateTo),
  );
  const isFreeDay = isSaturday || isSunday || isHoliday || isEmployeeHoliday;

  if (
    selectedMonth < dateRange[0].getMonth() ||
    selectedMonth > dateRange[1].getMonth()
  ) {
    return (
      <Grid item xs={2} border="0.1rem solid" borderColor="border.main" p={1}>
        ---
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={2}
      border="0.1rem solid"
      borderColor="border.main"
      p={1}
      position="relative"
    >
      {isFreeDay && (
        <TitleTooltip
          placement="right-end"
          title={t('hours_view.employee_not_available')}
        >
          <Box height="100%" left={0} position="absolute" top={0} width="100%">
            <CardMedia
              component="img"
              src={freeDayBg}
              alt="frame"
              sx={{ height: '100%' }}
            />
          </Box>
        </TitleTooltip>
      )}
      <Box display="flex" flexDirection="column" gap={1} zIndex={2}>
        <Typography variant="body1">{dateFormat(day, 'dd')}</Typography>
        {dayHours.length > 0 && (
          <TitleTooltip
            placement="right"
            titleComponent={
              <Box display="flex" flexDirection="column" gap={1}>
                {dayHours.map((hours) => (
                  <Box key={hours.id} display="flex" gap={2}>
                    <Typography variant="bold">{hours.hours}h</Typography>
                    <Typography variant="body1">{hours.description}</Typography>
                  </Box>
                ))}
              </Box>
            }
          >
            <Typography variant="bold">{hoursSum}h</Typography>
          </TitleTooltip>
        )}
      </Box>
    </Grid>
  );
}

export default DayTile;
