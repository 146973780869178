import React, { useState } from 'react';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import SearchIcon from '@mui/icons-material/Search';
import { useLazyGetPipeDriveLeadsQuery } from 'store/pipeDriveLead/pipeDriveLead';
import { CustomError } from 'store/api';
import LeadsData from 'components/PipeDrive/LeadsData/LeadsData';

function PipeDrive() {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [
    search,
    { data: leadsInfo = [], isError, isLoading, isSuccess, error },
  ] = useLazyGetPipeDriveLeadsQuery();

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>Pipedrive</NavBarTitle>
      </NavBarContainer>
      <Box px={1.5} py={3}>
        <Box
          display="flex"
          alignItems="center"
          borderRadius={2}
          gap={2}
          bgcolor="main.white"
          width="100%"
          p={2}
        >
          <TextField
            fullWidth
            label={t('label.company')}
            name="company"
            onChange={(event) => setQuery(event.target.value)}
            value={query}
          />
          <Button
            disabled={!query}
            endIcon={<SearchIcon />}
            onClick={() => search({ company: query })}
            variant="contained"
          >
            {t('button.search')}
          </Button>
        </Box>
      </Box>
      <Backdrop
        open={isLoading}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
      {isError && (
        <Box px={1.5}>
          <Alert variant="filled" severity="error">
            {(error as CustomError).data.detail}
          </Alert>
        </Box>
      )}
      {isSuccess && <LeadsData leadsInfo={leadsInfo} />}
    </Grid>
  );
}

export default PipeDrive;
