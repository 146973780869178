import { TableHeadersProps } from 'interfaces';
import roles from 'config/roles/roles';

interface Headers extends TableHeadersProps {
  displayRoles: string[];
}

const tableHeaders: Headers[] = [
  {
    label: 'label.name',
    align: 'left',
    displayRoles: [...roles.clients, ...roles.debtCollection],
  },
  {
    label: 'label.status',
    align: 'left',
    displayRoles: [...roles.clients, ...roles.debtCollection],
  },
  {
    label: 'clients.country',
    align: 'left',
    displayRoles: [...roles.clients, ...roles.debtCollection],
  },
  {
    label: 'clients.default_cost',
    align: 'left',
    displayRoles: [...roles.clients, ...roles.debtCollection],
  },
];

export default tableHeaders;
