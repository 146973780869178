import { useRef, useState, useEffect } from 'react';

export default function useElementWidth() {
  const elementRef = useRef<HTMLDivElement>(null);
  const isWidthSet = useRef(false);
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current && !isWidthSet.current) {
      setElementWidth(elementRef.current.clientWidth);
      isWidthSet.current = true;
    }
    const handleResize = () => {
      if (elementRef.current) {
        setElementWidth(elementRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef]);

  return { elementRef, elementWidth };
}
