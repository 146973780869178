import React, { ChangeEvent, useCallback } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { EmployeePreview } from 'store/employee/interfaces';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import { ProjectStatuses } from 'enums';
import { useTranslation } from 'react-i18next';

interface DataSelectProps {
  employees: EmployeePreview[];
  selectedEmployees: string[];
  isEmployeeSelected: (value: string) => boolean;
  onCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedProjects: string[];
  isProjectSelected: (value: string) => boolean;
  onProjectsChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
function DataSelect({
  employees,
  selectedEmployees,
  isEmployeeSelected,
  onCheckboxChange,
  selectedProjects,
  isProjectSelected,
  onProjectsChange,
}: DataSelectProps) {
  const { t } = useTranslation();
  const { data: projects = [] } = useGetProjectsPreviewQuery({
    status: ProjectStatuses.active,
  });
  const getSelectedEmployees = useCallback(
    (selectedEmployeesIri: string[]) =>
      employees
        .filter((employee) =>
          selectedEmployeesIri.includes(employee['@id']),
        )
        .map((employee) => `${employee.lastname} ${employee.firstname}`)
        .join(', '),
    [employees],
  );
  const getSelectedProjects = useCallback(
    (selectedProjectsIds: string[]) =>
      projects
        .filter((project) => selectedProjectsIds.includes(project['@id']))
        .map((project) => project.name)
        .join(', '),
    [projects],
  );

  return (
    <Box display="flex" gap={1}>
      <FormControl>
        <Select
          displayEmpty
          id="employee-select"
          multiple
          value={selectedEmployees}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{t('inputs.choose_employee')}</em>;
            }
            return getSelectedEmployees(selected);
          }}
        >
          {employees.map((employee) => (
            <MenuItem key={employee.id} value={employee['@id']}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEmployeeSelected(employee['@id'])}
                    onChange={onCheckboxChange}
                    value={employee['@id']}
                  />
                }
                label={`${employee.lastname} ${employee.firstname}`}
                sx={{ width: '100%' }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <Select
          displayEmpty
          id="projects-select"
          multiple
          value={selectedProjects}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{t('inputs.project')}</em>;
            }
            return getSelectedProjects(selected);
          }}
        >
          {projects.map((project) => (
            <MenuItem key={project.id} value={project['@id']}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isProjectSelected(project['@id'])}
                    onChange={onProjectsChange}
                    value={project['@id']}
                  />
                }
                label={project.name}
                sx={{ width: '100%' }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default DataSelect;
