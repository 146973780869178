import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogContent, FormControl, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { useTranslation } from 'react-i18next';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import PhoneMaskInput from 'common/MaskInputs/PhoneMaskInput';
import ControlAutocomplete from 'common/ControlAutocomplete/ControlAutocomplete';
import candidateFormSourceOptions from 'helpers/candidateFormSourceOptions';
import CandidateFormResources from './CandidateFormResources';
import { FormType, CandidateFormProps, FormPayload } from './interfaces';
import defaultValues from './defaultValue';
import schema from './CandidateForm.schema';

const validate = zodResolver(schema);

export default function CandidateForm({
  setIsOpen,
  type,
  onSubmit,
  isSubmitting,
  initFormValues = defaultValues,
  selectedResources,
  setSelectedResources
}: CandidateFormProps) {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initFormValues,
  });
  const { t } = useTranslation();
  const { firstname, lastname, email } = watch();

  const title =
    type === FormType.add
      ? t('human_resources.add_candidate')
      : t('human_resources.edit_candidate');

  const handleOnSubmit = handleSubmit(onSubmit);

  const disableSubmission = () =>
    !!Object.keys(errors).length || !firstname || !lastname || !email;

  return (
    <>
      <DialogHeader title={title} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleOnSubmit} noValidate>
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 4 }}
          >
            <ControlTextField
              control={control}
              errors={errors}
              label="label.firstname"
              name="firstname"
              required
            />
            <ControlTextField
              control={control}
              errors={errors}
              label="label.lastname"
              name="lastname"
              required
            />
            <ControlTextField
              control={control}
              errors={errors}
              label="label.email"
              name="email"
              required
            />
            <ControlTextField
              control={control}
              errors={errors}
              name="phoneNumber"
              label="label.phoneNumber"
              InputProps={{
                inputComponent: PhoneMaskInput as any,
              }}
            />
            <ControlAutocomplete
              control={control}
              errors={errors}
              label="label.source"
              name="source"
              options={candidateFormSourceOptions}
            />
          </FormControl>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container pt={2}>
              <CandidateFormResources
                selectedResources={selectedResources}
                setSelectedResources={setSelectedResources}
              />
            </Grid>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddIcon />}
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
