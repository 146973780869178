import React from 'react';
import { AttachFile } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import openFileWithAuth from 'helpers/openFileWithAuth';
import { TransactionAttachment } from 'store/transactions/transactions';

interface FileTooltipProps {
  attachment: TransactionAttachment;
}

export default function FileTooltip({ attachment }: FileTooltipProps) {
  return (
    <TitleTooltip title={attachment.name}>
      <IconButton onClick={() => openFileWithAuth(attachment.file!.url)}>
        <AttachFile fontSize="large" />
      </IconButton>
    </TitleTooltip>
  );
}
