import React from 'react';
import { Stack } from '@mui/material';
import ModulesFeatures from './ModulesFeatures/ModulesFeatures';
import ModulesSummary from './ModulesSummary/ModulesSummary';

export default function ModulesConfigurator() {
  return (
    <Stack direction="row" width="100%" spacing={2}>
      <ModulesFeatures />
      <ModulesSummary />
    </Stack>
  );
}
