import { useGetCountriesQuery } from 'store/countries/countries';

export default function useGetCountriesOptions() {
  const { data: countries, isLoading, isError } = useGetCountriesQuery();

  const countryIdOptions =
    countries
      ?.map((item) => ({
        value: item['@id'],
        label: item.id.toUpperCase(),
      }))
      ?.sort((currentCountry, nextCountry) =>
        currentCountry.label.localeCompare(nextCountry.label),
      ) ?? [];

  const countryNameOptions =
    countries
      ?.map((item) => ({
        value: item['@id'],
        label: item.name,
      }))
      ?.sort((currentCountry, nextCountry) =>
        currentCountry.label.localeCompare(nextCountry.label),
      ) ?? [];

  return { countryIdOptions, countryNameOptions, isLoading, isError };
}
