import React from 'react';
import { createSvgIcon } from '@mui/material';

const RatingStarIcon = createSvgIcon(
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_726_1259)">
      <path
        d="M6.01037 0.750041C5.57652 0.746609 5.13219 0.956209 4.87367 1.38172L3.78198 3.17866C3.73872 3.24986 3.66723 3.30202 3.58273 3.32141L1.52014 3.79461C0.557681 4.01542 0.168179 5.17971 0.824031 5.92812L2.21192 7.51189C2.26723 7.57502 2.29363 7.65667 2.28649 7.73817L2.10343 9.82756C2.0167 10.8176 3.0205 11.5258 3.92566 11.146L5.87512 10.3279C5.91563 10.3109 5.95894 10.3025 6.00238 10.3028C6.04407 10.3032 6.08555 10.3115 6.12443 10.3279L8.07389 11.146C8.97905 11.5258 9.98285 10.8176 9.89612 9.82756L9.71306 7.73817C9.70592 7.65667 9.73232 7.57502 9.78763 7.51189L11.1755 5.92812C11.8314 5.17971 11.4419 4.01542 10.4794 3.79461L8.41682 3.32141C8.33232 3.30202 8.26083 3.24986 8.21757 3.17866L7.12588 1.38173C6.87165 0.963268 6.43743 0.75342 6.01037 0.750041Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'RatingStarIcon',
);

export default RatingStarIcon;
