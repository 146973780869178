import React, { useState } from 'react';
import {
  Chip,
  Dialog,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import EditButton from 'common/EditButton/EditButton';
import TextButton from 'common/TextButton/TextButton';
import { Bank } from 'store/bank/bank';
import { BankAccount } from 'store/accounts/accounts';
import { ChevronRight } from '@mui/icons-material';
import EditAccountForm from 'components/EditAccountForm';
import AddAccountForm from 'components/AddAccountForm';
import EditBankForm from 'components/EditBankForm';
import BankAccountRow from './BankAccountRow/BankAccountRow';

interface BankRowProps {
  bank: Bank;
  bankAccounts: BankAccount[];
}

export default function BankRow({ bank, bankAccounts }: BankRowProps) {
  const [showBankAccounts, setShowBankAccounts] = useState(false);
  const [chosenAccountId, setChosenAccountId] = useState('');

  const [isAddAccountOpen, setIsAddAccountOpen] = useState(false);
  const [isEditAccountOpen, setIsEditAccountOpen] = useState(false);
  const [isEditBankOpen, setIsEditBankOpen] = useState(false);

  const handleOpenEditAccount = (accountId: string) => {
    setChosenAccountId(accountId);
    setIsEditAccountOpen(true);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                onClick={() => setShowBankAccounts((prevState) => !prevState)}
                disabled={!bankAccounts.length}
              >
                <ChevronRight
                  sx={{
                    fontSize: '2rem',
                    transform: showBankAccounts
                      ? 'rotate(90deg)'
                      : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                  }}
                />
              </IconButton>
              <Typography>{bank.name}</Typography>
            </Stack>
            <Chip
              size="small"
              variant="outlined"
              label={
                <Typography variant="chipLabel">
                  {bankAccounts.length}
                </Typography>
              }
            />
          </Stack>
        </TableCell>
        <TableCell>
          <Typography>{bank.swift}</Typography>
        </TableCell>
        <TableCell colSpan={2} />
        <TableCell align="right">
          <Stack
            justifyContent="flex-end"
            spacing={1}
            direction="row"
            alignItems="center"
            flexWrap="wrap"
          >
            <TextButton
              onClick={() => setIsAddAccountOpen(true)}
              label="banks.add_account"
            />
            <EditButton v2 onClick={() => setIsEditBankOpen(true)} />
          </Stack>
        </TableCell>
      </TableRow>
      {bankAccounts.map((account) => (
        <BankAccountRow
          key={account.id}
          bankAccount={account}
          isVisible={showBankAccounts}
          handleOpenEditAccount={handleOpenEditAccount}
        />
      ))}
      <Dialog
        open={isEditBankOpen}
        onClose={() => setIsEditBankOpen(!isEditBankOpen)}
        fullWidth
      >
        <EditBankForm bank={bank} setIsEditOpen={setIsEditBankOpen} />
      </Dialog>
      <Dialog
        open={isAddAccountOpen}
        onClose={() => setIsAddAccountOpen(!isAddAccountOpen)}
        fullWidth
      >
        <AddAccountForm bank={bank} setIsAddAccountOpen={setIsAddAccountOpen} />
      </Dialog>
      <Dialog
        open={isEditAccountOpen}
        onClose={() => setIsEditAccountOpen(!isEditAccountOpen)}
        fullWidth
      >
        <EditAccountForm
          accountId={chosenAccountId}
          setIsEditOpen={setIsEditAccountOpen}
        />
      </Dialog>
    </>
  );
}
