import React, { useState } from 'react';
import { Box, Grid, Tab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import clientStatuses from 'components/ClientsList/clientsSelectData';
import Details from 'components/EditClientForm/Details';
import Overview from 'components/EditClientForm/Overview';
import { Client, useGetClientQuery } from 'store/clients/clients';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import { useGetCountriesQuery } from 'store/countries/countries';
import { useGetLanguagesQuery } from 'store/languages/languages';
import { useGetCostsQuery } from 'store/costs/costs';
import getTranslationKey from 'helpers/getTranslationKey';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { LocationProps } from 'interfaces';
import { useGetDocumentTypesQuery } from 'store/documentTypes/documentTypes';
import Documents from 'components/EditClientForm/Documents/Documents';
import Address from 'components/EditClientForm/Address';
import Payments from 'components/EditClientForm/Payments/Payments';
import getBgColorForTab from 'helpers/getBgColorForTab';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import Contacts from './Contacts';

enum TabValue {
  overview = '1',
  documents = '2',
  address = '3',
  payments = '4',
  contacts = '5',
}

export default function EditClientForm() {
  const { id } = useParams<{ id: any }>();
  const { state } = useLocation() as LocationProps;

  const {
    data: client = {} as Client,
    isError: clientFetchError,
    isLoading: clientFetching,
    isSuccess: clientFetchSuccess,
  } = useGetClientQuery(id);
  const {
    data: currencies = [],
    isError: currenciesFetchError,
    isLoading: currenciesFetching,
    isSuccess: currenciesFetchSuccess,
  } = useGetCurrenciesQuery();
  const {
    data: countries = [],
    isError: countriesFetchError,
    isLoading: countriesFetching,
    isSuccess: countriesFetchSuccess,
  } = useGetCountriesQuery();
  const {
    data: languages = [],
    isError: languagesFetchError,
    isLoading: languagesFetching,
    isSuccess: languagesFetchSuccess,
  } = useGetLanguagesQuery();
  const {
    data: costs = [],
    isError: costsFetchError,
    isLoading: costsFetching,
    isSuccess: costsFetchSuccess,
  } = useGetCostsQuery({ properties: ['id', 'name'] });
  const {
    data: docTypes = [],
    isLoading: docTypesFetching,
    isError: docTypesFetchError,
    isSuccess: docTypesFetched,
  } = useGetDocumentTypesQuery(undefined, {
    skip: !useCheckRoles(roles.documentsViewer),
  });
  const clientRelatedDocTypes = docTypes.some(
    (docType) => docType.relationName === 'client',
  );

  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(
    state?.tabValue || TabValue.overview,
  );
  const listingTabs = [
    TabValue.documents,
    TabValue.payments,
    TabValue.contacts,
  ];
  const isAllowToSeePayments = useCheckRoles(roles.debtCollection);

  if (
    clientFetching ||
    currenciesFetching ||
    countriesFetching ||
    languagesFetching ||
    costsFetching ||
    docTypesFetching
  ) {
    return <Loader />;
  }

  if (
    clientFetchError ||
    currenciesFetchError ||
    countriesFetchError ||
    languagesFetchError ||
    costsFetchError ||
    docTypesFetchError
  ) {
    return <Error />;
  }

  const isDataFetchSuccess =
    clientFetchSuccess ||
    currenciesFetchSuccess ||
    countriesFetchSuccess ||
    languagesFetchSuccess ||
    costsFetchSuccess ||
    docTypesFetched;

  return (
    <Grid item xs={10} bgcolor={getBgColorForTab(tabValue, listingTabs)}>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="main.white"
        justifyContent="center"
        borderBottom="0.1rem solid"
        borderColor="secondary.light"
        columnGap={3}
        p={3}
      >
        <Typography variant="h5">{client.name}</Typography>
        <Typography variant="body1" color="text.secondary">
          {t(getTranslationKey(clientStatuses, client.status))}
        </Typography>
      </Box>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={(event, newValue: string) => setTabValue(newValue)}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('tab.overview')} value={TabValue.overview} />
          {clientRelatedDocTypes && (
            <Tab label={t('clients.documents')} value={TabValue.documents} />
          )}
          <Tab label={t('label.address')} value={TabValue.address} />
          {isAllowToSeePayments && (
            <Tab label={t('label.payments')} value={TabValue.payments} />
          )}
          <Tab label={t('label.contacts')} value={TabValue.contacts} />
        </StyledTabList>
        <TabPanel value={TabValue.overview}>
          {isDataFetchSuccess && (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Overview
                  client={client}
                  languages={languages}
                  currencies={currencies}
                  countries={countries}
                />
              </Grid>
              <Grid item xs={6}>
                <Details client={client} costs={costs} />
              </Grid>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={TabValue.documents}>
          <Documents clientId={client.id} />
        </TabPanel>
        <TabPanel value={TabValue.address}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Address />
            </Grid>
          </Grid>
        </TabPanel>
        {isAllowToSeePayments && (
          <TabPanel value={TabValue.payments}>
            <Payments />
          </TabPanel>
        )}
        <TabPanel value={TabValue.contacts}>
          <Contacts client={client} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}
