import { FormPayload } from 'components/SubscriptionClientForm/interfaces';
import { createContext } from 'react';

export interface SubscriptionContextProps {
  selectedFeatures: string[];
  userCount: number;
  clientForm: FormPayload;
  setClientForm: (clientForm: FormPayload) => void;
  setUserCount: (userCount: number) => void;
  toggleFeature: (feature: string) => void;
  handleNextStep: () => void;
}

export const SubscriptionContext = createContext<
  SubscriptionContextProps | undefined
>(undefined);
