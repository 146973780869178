const costsStatuses = [
  { value: 'active', label: 'costs.status_active' },
  { value: 'outdated', label: 'costs.status_outdated' },
];

const taxAmounts = [
  {
    label: 'zw',
    value: '-1',
  },
  {
    label: 'nd',
    value: '0',
  },
  {
    label: '23',
    value: '23',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '5',
    value: '5',
  },
];

export { taxAmounts, costsStatuses };
