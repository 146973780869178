import { FormPayload } from './interfaces';

const defaultValues: FormPayload = {
  name: '',
  country: '',
  currency: '',
  vatNumber: '',
  addressCity: '',
  addressPostCode: '',
  addressStreetLine: '',
  addressCountry: '',
};

export default defaultValues;
