import { createContext } from 'react';
import { Meeting } from 'store/meetings/interfaces';
import { Employee } from 'store/employee/interfaces';

export interface MeetingContextProps {
  meeting: Meeting;
  meetingLoading: boolean;
  meetingError: boolean;
  employees: Employee[];
  employeesLoading: boolean;
  employeesError: boolean;
}

export const MeetingContext = createContext<MeetingContextProps | undefined>(
  undefined,
);
