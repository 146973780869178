import React from 'react';
import ReactEChart from 'echarts-for-react';
import { Box } from '@mui/material';
import { ChartIncome } from 'pages/IncomeChart/useGetChartIncome';
import { Client } from 'store/clients/clients';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import IncomeTable from './IncomeTable/IncomeTable';
import getChartData from './getChartData';

interface ChartOfIncomesProps {
  incomes: ChartIncome[];
  clients: Pick<Client, 'name' | '@id'>[];
}

function ChartOfIncomes({ incomes, clients }: ChartOfIncomesProps) {
  const formatAmount = useCurrencyFormat();
  const getClientName = (clientIri: string) => {
    const client = clients.find(
      (clientItem) => clientItem['@id'] === clientIri,
    );
    return client?.name || clientIri;
  };

  const {
    displayedPeriod,
    chartOptions,
    incomesGroupedByClient,
    getTotalNetAmountFromClientPeriod,
  } = getChartData(incomes, getClientName, formatAmount);

  return (
    <>
      <Box m={2} bgcolor="main.white" borderRadius={4} height="75vh">
        <ReactEChart
          option={chartOptions}
          opts={{ renderer: 'svg' }}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <IncomeTable
        displayedPeriod={displayedPeriod}
        incomesGroupedByClient={incomesGroupedByClient}
        getTotalNetAmountFromClientPeriod={getTotalNetAmountFromClientPeriod}
      />
    </>
  );
}

export default ChartOfIncomes;
