import { Typography, Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import Loader from 'common/Loader';
import Error from 'common/Error';

import { TagGroupsData } from 'store/tagGroups/interfaces';
import { useGetTagGroupsQuery } from 'store/tagGroups/tagGroups';
import SkillTagGroup from './SkillTagGroup';

export default function Skills() {
  const { t } = useTranslation();

  const {
    data: tagGroups = {} as TagGroupsData,
    isLoading: tagGroupsLoading,
    isError: tagGroupsError,
  } = useGetTagGroupsQuery();

  if (tagGroupsLoading) {
    return <Loader />;
  }

  if (tagGroupsError) {
    return <Error />;
  }

  return (
    <StyledContentBox mt={3}>
      <Typography p={3} variant="h6">
        {t('human_resources.skills')}
      </Typography>
      {tagGroups['hydra:member'].map((tagGroup, index) => (
        <>
          <SkillTagGroup tagGroup={tagGroup} />
          {index !== tagGroups['hydra:member'].length - 1 && <Divider />}
        </>
      ))}
    </StyledContentBox>
  );
}
