import React, { useState } from 'react';
import { Box, Button, Dialog, Grid, Tab } from '@mui/material';
import { TabContext } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import AgreementsList from 'components/AgreementsList';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddAgreementForm from 'components/AddAgreementForm';

export enum AgreementsTabValue {
  active = '1',
  inactive = '0',
}

function Agreements() {
  const [tabValue, setTabValue] = useState(AgreementsTabValue.active);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: AgreementsTabValue,
  ) => {
    setTabValue(newValue);
    setPage(1);
  };

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <Box display="flex" flexDirection="column">
          <NavBarTitle>{t('agreements.header')}</NavBarTitle>
        </Box>
        <Button
          variant="contained"
          onClick={() => setIsOpen(!isOpen)}
          endIcon={<AddCircleOutlineIcon />}
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('label.active')} value={AgreementsTabValue.active} />
          <Tab
            label={t('label.inactive')}
            value={AgreementsTabValue.inactive}
          />
        </StyledTabList>
        <AgreementsList page={page} setPage={setPage} />
      </TabContext>
      <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} fullWidth>
        <AddAgreementForm isOpen={isOpen} setIsOpen={setIsOpen} />
      </Dialog>
    </Grid>
  );
}

export default Agreements;
