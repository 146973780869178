import React from 'react';
import { Stack, Typography } from '@mui/material';
import MockTechSkillIcon from 'assets/icons/MockTechSkillIcon';
import RatingStarIcon from 'assets/icons/RatingStarIcon';
import { TagDefinition } from 'store/tagDefinitions/interfaces';
import getMatchPhrase from './getMatchPhrase';

interface AutocompleteOptionProps {
  option: TagDefinition;
  searchPhrase: string;
}

export default function AutocompleteOption({
  option,
  searchPhrase,
}: AutocompleteOptionProps) {

  const { beforeMatch, match, afterMatch, isSearchPhraseFound } =
    getMatchPhrase(option, searchPhrase);

  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" spacing={1.5} alignItems="center">
        <MockTechSkillIcon />
        {isSearchPhraseFound ? (
          <Typography variant="body2" fontWeight={500}>
            {beforeMatch}
            <Typography fontWeight={800} variant="body2" component="span">
              {match}
            </Typography>
            {afterMatch}
          </Typography>
        ) : (
          <Typography variant="body2" fontWeight={500}>
            {option.name}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={0.5} alignItems="center">
        {Array.from({ length: option.level }, (_, index) => (
          <RatingStarIcon
            key={index}
            sx={{
              color: 'rating.filled',
              fontSize: '1.2rem',
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
