import { styled, Accordion, AccordionProps } from '@mui/material';

export const StyledBudgetGroupAccordion = styled(Accordion)<AccordionProps>(
  ({ theme }) => ({
    border: 0,
    borderRadius: '1.2rem',
    boxShadow: 'none',
    background: `${theme.palette.background.accordion}`,
    marginTop: '1.6rem',
    padding: '1.6rem',
    '&.Mui-expanded.Mui-expanded:first-of-type': {
      marginTop: '1.6rem',
    },
  }),
);

export default StyledBudgetGroupAccordion;
