import React, { useState } from 'react';
import { Box, DialogContent, Slider, Typography } from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import AttachedEmployeesSelect from 'components/EditProjectForm/AttachedEmployeesSelect/AttachedEmployeesSelect';
import { StatusProps } from 'hoc/Status/Status';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import { useTranslation } from 'react-i18next';
import { EmployeePreview } from 'store/employee/interfaces';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LoadingButton } from '@mui/lab';
import { useAddMeetingMutation } from 'store/meetings/meetings';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'store/api';
import { useAddFeedbackRequestMutation } from 'store/feedbacks/feedbacks';
import dateFormat from 'dateformat';

export interface ScheduleMeetingFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEmployee: EmployeePreview;
}

const numberOfMonthsBehind = 1;
const numberOfMonthsAhead = 3;
const differenceBetweenCurrentAndLastMonth = 11;

export default function ScheduleMeetingForm({
  setIsOpen,
  selectedEmployee,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: ScheduleMeetingFormProps) {
  const { t } = useTranslation();
  const { data: employees = [] } = useGetEmployeesPreview({
    isActive: true,
  });
  const {
    selected: selectedEmployees,
    isSelected: isEmployeeSelected,
    handleChange,
  } = useMultipleCheckboxSelect<string>([]);
  const [addMeeting, { isLoading: addingMeeting }] = useAddMeetingMutation();
  const [addRequest, { isLoading: requestingFeedback }] =
    useAddFeedbackRequestMutation();
  const navigate = useNavigate();
  const currentDate = new Date();
  const sliderDate = new Date();
  const sliderMarks: { label: string; value: number }[] = [];

  for (
    let month = numberOfMonthsAhead;
    month >= -differenceBetweenCurrentAndLastMonth;
    month--
  ) {
    sliderMarks.push({
      label: dateFormat(
        sliderDate.setMonth(currentDate.getMonth() + month),
        'mmm',
      ),
      value: month,
    });
  }

  const [dateRange, setDateRange] = useState([
    sliderMarks[sliderMarks.length - numberOfMonthsBehind - 1].value,
    sliderMarks[numberOfMonthsAhead].value,
  ]);

  const handleAddMeeting = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const dateFrom = new Date();
    const dateTo = new Date();
    dateFrom.setMonth(currentDate.getMonth() + dateRange[0]);
    dateTo.setMonth(currentDate.getMonth() + dateRange[1]);
    const firstSelectedDateFromMonthDate = new Date(
      dateFrom.getFullYear(),
      dateFrom.getMonth(),
      1,
    );
    const lastSelectedDateToMonthDate = new Date(
      dateTo.getFullYear(),
      dateTo.getMonth() + 1,
      0,
    );
    try {
      await addRequest({
        employeeFor: selectedEmployee['@id'],
        requestedEmployees: selectedEmployees,
      }).unwrap();
      const addedMeeting = await addMeeting({
        employee: selectedEmployee['@id'],
        dateFrom: dateFormat(firstSelectedDateFromMonthDate, 'yyyy-mm-dd'),
        dateTo: dateFormat(lastSelectedDateToMonthDate, 'yyyy-mm-dd'),
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
      navigate(`/meeting/${addedMeeting.id}`);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        title={t('feedbacks.schedule_meeting', {
          employee: `${selectedEmployee.lastname} ${selectedEmployee.firstname}`,
        })}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        <form onSubmit={handleAddMeeting}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={3}
            mt={3}
          >
            <Box display="flex" flexDirection="column" gap={2} width="100%">
              <Typography variant="bold">
                {t('feedbacks.request_from')}
              </Typography>
              <AttachedEmployeesSelect
                employees={employees}
                isEmployeeSelected={isEmployeeSelected}
                selectedEmployees={selectedEmployees}
                onCheckboxChange={handleChange}
              />
            </Box>
            <Slider
              min={sliderMarks[sliderMarks.length - 1].value}
              marks={sliderMarks}
              max={sliderMarks[0].value}
              onChange={(event, newValue: number | number[]) =>
                setDateRange(newValue as number[])
              }
              step={1}
              value={dateRange}
            />
            <LoadingButton
              disabled={!selectedEmployees.length}
              endIcon={<AddCircleOutlineIcon />}
              loading={addingMeeting || requestingFeedback}
              type="submit"
              variant="contained"
            >
              {t('button.save')}
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
