import { ChangeSet } from '@devexpress/dx-react-scheduler';
import { createContext } from 'react';
import { Property } from 'store/properties/interfaces';

export interface BookingData {
  startDate: string | Date;
  endDate: string | Date;
  title: string;
  id: string;
  employee: string;
}

export interface BookingSchedulerContextProps {
  removeBookingFromState: (id: string) => void;
  addBookingToState: (booking: BookingData) => void;
  property: Property;
  bookingsInState: BookingData[];
  onCommitChanges: (payload: ChangeSet) => void;
  updateDateEndOfDraftBooking: (newEndDate: Date | string) => void;
  isDraftAppointmentCreating: boolean;
  setIsDraftAppointmentCreating: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BookingSchedulerContext = createContext<
  BookingSchedulerContextProps | undefined
>(undefined);
