import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useGetGoogleCalendarStatusQuery,
  useUpdateGoogleCalendarStatusMutation,
} from 'store/config/config';
import { StatusProps } from 'hoc/Status/Status';
import { ConfigResponse } from 'store/config/interfaces';

export interface ActivationProps extends StatusProps {}
function Activation({ setIsSuccess, setIsError }: ActivationProps) {
  const [enabled, setEnabled] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const { t } = useTranslation();
  const { data = {} as ConfigResponse<boolean>, isSuccess: statusFetched } =
    useGetGoogleCalendarStatusQuery();
  const [
    updateStatus,
    { isSuccess: statusUpdated, isError: statusUpdateError },
  ] = useUpdateGoogleCalendarStatusMutation();

  useEffect(() => {
    if (!statusFetched) {
      return;
    }
    setEnabled(data.value);
  }, [statusFetched, data]);

  useEffect(() => {
    if (statusUpdated) {
      setIsSuccess(statusUpdated);
    }
    setIsError(statusUpdateError);
  }, [statusUpdated, statusUpdateError, setIsError, setIsSuccess]);

  useEffect(() => {
    if (!formChanged) {
      return;
    }
    updateStatus({
      value: enabled ? '1' : '0',
    });
  }, [formChanged, enabled, updateStatus]);

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
        p={2}
      >
        <FormControlLabel
          control={
            <Switch
              checked={enabled}
              onChange={() => {
                setEnabled((prevState) => !prevState);
                setFormChanged(true);
              }}
            />
          }
          label={t('google_calendar.enable_label')}
          labelPlacement="start"
          sx={{ justifyContent: 'space-between', width: '100%', ml: 0 }}
        />
      </Box>
    </Grid>
  );
}

export default Activation;
