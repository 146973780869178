import React, { useState } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useUploadFileMutation } from 'store/storage/storage';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface AddFileDialogProps extends StatusProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddFileDialog({
  isOpen,
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddFileDialogProps) {
  const { id } = useParams<{ id: any }>();
  const [uploadedFile, setUploadedFile] = useState<File | null>();
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const { t } = useTranslation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    setUploadedFile(event.target.files[0]);
  };

  const handleUploadFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!uploadedFile) {
      return;
    }
    const fileData = new FormData();
    fileData.append('file', uploadedFile, uploadedFile.name);
    fileData.append('relationName', 'agreements');
    fileData.append('relationId', id);
    try {
      await uploadFile(fileData).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">{t('agreement.add_new_file')}</Typography>
          <IconButton
            sx={{ bgcolor: 'secondary.light' }}
            onClick={() => {
              setIsOpen(!isOpen);
              setUploadedFile(null);
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleUploadFile}>
          <InputLabel id="file-upload">{t('inputs.attached_file')}</InputLabel>
          <TextField
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            fullWidth
          />
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <LoadingButton
              disabled={!uploadedFile}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.add_file')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddFileDialog;
