import { ChipOwnProps } from '@mui/material';
import theme from 'config/material-ui/theme';

const styles = {
  unit: {
    backgroundColor: theme.palette.secondary.selected,
    borderColor: theme.palette.border.secondaryOutlined,
    color: theme.palette.secondary.main,
  },
  linear: {
    backgroundColor: theme.palette.info.selected,
    borderColor: theme.palette.border.infoOutlined,
    color: theme.palette.info.main,
  },
  exponential: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.border.primaryOutlined,
    color: theme.palette.primary.main,
  },
};

export type BadgeType = keyof typeof styles;

export default function getBadgeStyles(
  type: BadgeType,
): ChipOwnProps['sx'] {
  return styles[type];
}
