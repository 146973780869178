import React from 'react';
import { Box, Typography, Divider, Stack, Chip } from '@mui/material';
import dateFormat from 'dateformat';
import { useGetFeedbacksQuery } from 'store/feedbacks/feedbacks';
import replaceApiIri from 'helpers/replaceApiIri';
import { useTranslation } from 'react-i18next';
import rates from 'components/AddFeedbackForm/rates';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { FeedbackData } from 'store/feedbacks/interfaces';
import {
  WidgetContainer,
  WidgetTitle,
  WidgetCaption,
  WidgetCaptionContainer,
} from 'common/Widget';
import useMeetingContext from 'pages/Meeting/context/useMeetingContext';
import { FeedbackVisibility } from 'enums';
import feedbacksTypes from 'components/EmployeeFeedbacksList/feedbackTypes';

export default function Feedbacks() {
  const { t } = useTranslation();
  const {
    meeting,
    meetingLoading,
    meetingError,
    employees,
    employeesError,
    employeesLoading,
  } = useMeetingContext();
  const {
    data: feedbackData = {} as FeedbackData,
    isLoading: feedbacksLoading,
    isError: feedbacksError,
  } = useGetFeedbacksQuery(
    {
      employee: replaceApiIri(meeting.employee ?? '', 'employees'),
    },
    {
      skip: !meeting.employee,
    },
  );

  const meetingEmployeeFeedbacks = feedbackData?.['hydra:member'] ?? [];

  const getFeedbackLabel = (visibility: string) => {
    const feedbackType = feedbacksTypes.find(
      (type) => type.value === visibility,
    );

    if (!feedbackType) {
      return '';
    }

    return t(feedbackType.label);
  };

  const tenLatestEmployeeFeedbacks =
    [...meetingEmployeeFeedbacks]
      ?.sort(
        (feedback, comparedFeedback) =>
          new Date(comparedFeedback.id).getTime() -
          new Date(feedback.id).getTime(),
      )
      .slice(0, 10) ?? [];

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find(
      (employeeData) =>
        employeeData.id === +replaceApiIri(employeeId, 'employees'),
    );

    if (!employee) {
      return '';
    }

    return `${employee.lastname} ${employee.firstname}`;
  };

  const getRateIcon = (rate: string) => {
    const employeeRate = Object.values(rates).find(
      (item) => item.value === rate,
    );

    if (!employeeRate) {
      return null;
    }

    return (
      <TitleTooltip title={t(employeeRate.label)}>
        {employeeRate.icon}
      </TitleTooltip>
    );
  };

  return (
    <WidgetContainer
      isLoading={feedbacksLoading || meetingLoading || employeesLoading}
      isError={feedbacksError || meetingError || employeesError}
      title={<WidgetTitle size="lg">{t('menu.feedback')}</WidgetTitle>}
    >
      {tenLatestEmployeeFeedbacks.map((feedback, index) => (
        <div key={feedback.id}>
          <Box display="flex" flexDirection="column">
            <WidgetCaptionContainer>
              <Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <WidgetCaption size="lg">
                    {getEmployeeName(String(feedback.authorEmployee))}
                  </WidgetCaption>
                  <Chip
                    color={
                      feedback.visibility === FeedbackVisibility.visible
                        ? 'primary'
                        : 'secondary'
                    }
                    label={
                      <Typography variant="body2">
                        {getFeedbackLabel(feedback.visibility)}
                      </Typography>
                    }
                  />
                </Stack>
                <WidgetCaption secondary size="sm">
                  {dateFormat(feedback.createdAt, 'dd.mm.yyyy')}
                </WidgetCaption>
              </Stack>
              {getRateIcon(feedback.rate)}
            </WidgetCaptionContainer>
            <WidgetCaption size="lg">{feedback.description}</WidgetCaption>
          </Box>
          {index < tenLatestEmployeeFeedbacks.length - 1 && (
            <Divider sx={{ my: 2 }} />
          )}
        </div>
      ))}
    </WidgetContainer>
  );
}
