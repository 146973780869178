import React from 'react';
import { Grid } from '@mui/material';
import Error from 'common/Error';
import Loader from 'common/Loader';
import {
  AgreementsData,
  useGetFilteredAgreementsQuery,
} from 'store/agreements/agreements';
import dateFormat from 'dateformat';
import { Holiday } from 'store/holidays/interfaces';
import { useGetEmployeeBenefitsQuery } from 'store/employeeBenefit/employeeBenefit';
import { useGetBenefitsQuery } from 'store/benefits/benefits';
import { EmployeeBenefit } from 'store/employeeBenefit/interfaces';
import { AgreementVariants } from 'enums';
import {
  WorkingHoursStats,
  useGetWorkingHoursStatsQuery,
} from 'store/workingHours/workingHours';
import { Employee } from 'store/employee/interfaces';
import Holidays from './Holidays/Holidays';
import Agreements from './Agreements/Agreements';
import Benefits from './Benefits/Benefits';
import WorkingHours from './WorkingHours/WorkingHours';

interface DetailsProps {
  employee: EmployeeWithBenefits;
  holidays: Holiday[];
}

export interface EmployeeWithBenefits extends Employee {
  benefits: number;
}

const emptyBenefits: EmployeeBenefit[] = [];

export default function Details({ employee, holidays }: DetailsProps) {
  const currentDate = new Date();
  const {
    data: agreements = {} as AgreementsData,
    isLoading: agreementsFetching,
    isError: agreementsFetchError,
  } = useGetFilteredAgreementsQuery({
    employee: employee.id.toString(),
  });
  const { employeeBenefits } = useGetEmployeeBenefitsQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        employeeBenefits:
          data?.filter((item) => item.employee === employee['@id']) ??
          emptyBenefits,
      }),
    },
  );
  const { isLoading: benefitsFetching, isError: benefitsFetchError } =
    useGetBenefitsQuery();
  const {
    data: workingHours = {} as WorkingHoursStats,
    isLoading: workingHoursFetching,
    isError: workingHoursFetchError,
  } = useGetWorkingHoursStatsQuery({
    id: +employee.id,
    date: dateFormat(currentDate, 'yyyy-mm'),
  });

  if (agreementsFetching || benefitsFetching || workingHoursFetching) {
    return <Loader />;
  }

  if (agreementsFetchError || benefitsFetchError || workingHoursFetchError) {
    return <Error />;
  }

  const isUzType =
    agreements['hydra:member'][0].variant === AgreementVariants.uz;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
        <Holidays holidays={holidays} />
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
        <Agreements agreementsData={agreements['hydra:member']} />
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
        <Benefits employeeBenefits={employeeBenefits} />
      </Grid>
      {isUzType && (
        <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
          <WorkingHours hoursStats={workingHours} />
        </Grid>
      )}
    </Grid>
  );
}
