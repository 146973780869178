import api from 'store/api';

export interface Country {
  ['@id']: string;
  id: string;
  name: string;
  isoCode: string;
  clients: string[];
}

export interface CountriesResponseDto {
  'hydra:member': Country[];
}

export const countriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<Country[], void>({
      query: () => '/countries',
      providesTags: ['Countries'],
      transformResponse: (response: CountriesResponseDto) =>
        response['hydra:member'],
    }),
    getCountry: builder.query<Country, string>({
      query: (id) => `/countries/${id}`,
      providesTags: ['Countries'],
    }),
    addCountry: builder.mutation({
      query: (body) => ({
        url: '/countries',
        method: 'POST',
        headers: { 'content-type': 'application/ld+json' },
        body,
      }),
      invalidatesTags: ['Countries'],
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useGetCountryQuery,
  useAddCountryMutation,
} = countriesApi;
