import React from 'react';
import { Avatar, Badge, Typography } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';

interface EmployeeBadgeProps {
  employeeName: string;
  percentage: number;
  avatarUrl: string | undefined;
}

export default function EmployeeBadge({
  employeeName,
  percentage,
  avatarUrl,
}: EmployeeBadgeProps) {
  return (
    <TitleTooltip title={employeeName}>
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<Typography variant="body2">{percentage}</Typography>}
        color="primary"
        overlap="circular"
      >
        <Avatar src={avatarUrl} />
      </Badge>
    </TitleTooltip>
  );
}
