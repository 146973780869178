import React from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Project } from 'store/projects/interfaces';
import { projectTypes } from 'components/ProjectsList/projectsSelectData';
import ClientName from 'components/ProjectsList/ClientName/ClientName';
import NavigateButton from 'common/NavigateButton/NavigateButton';
import getTranslationKey from 'helpers/getTranslationKey';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import FileClockIcon from 'assets/icons/FileClockIcon';
import useProjectMemberEmployees from 'hooks/useProjectMemberEmployee/useProjectMemberEmployees';
import EmployeesAvatars from '../EmployeesAvatars/EmployeesAvatars';

interface ResultProps {
  project: Project;
  isHoursViewer: boolean;
  isAllowedToSeeClient: boolean;
  getProjectName: (project: Project) => string;
}

export default function Result({
  project,
  isHoursViewer,
  isAllowedToSeeClient,
  getProjectName,
}: ResultProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectMemberEmployees, isProjectMemberEmployee } =
    useProjectMemberEmployees(project);

  const filteredEmployeesOfProject = projectMemberEmployees
    .filter(isProjectMemberEmployee)
    .map((projectMember) => projectMember.employee);

  return (
    <TableRow key={project.id}>
      <TableCell>
        <Stack>
          <Typography variant="body2" fontWeight={700}>
            {getProjectName(project)}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {t(getTranslationKey(projectTypes, project.flattenType))}
          </Typography>
        </Stack>
      </TableCell>
      {isAllowedToSeeClient && (
        <TableCell>
          {project.client && <ClientName clientIri={project.client} />}
        </TableCell>
      )}
      <TableCell>
        {!!filteredEmployeesOfProject && (
          <Stack direction="row" spacing={0.5}>
            <EmployeesAvatars
              employees={filteredEmployeesOfProject}
              key={project['@id']}
            />
          </Stack>
        )}
      </TableCell>
      <TableCell align="right">
        {isHoursViewer && (
          <TitleTooltip title={t('projects.hours_button')}>
            <IconButton
              onClick={() =>
                navigate('/hours-view', {
                  state: {
                    projects: [String(project.id)],
                  },
                })
              }
            >
              <FileClockIcon sx={{ fontSize: '2rem', color: 'icon.main' }} />
            </IconButton>
          </TitleTooltip>
        )}
        <NavigateButton
          to={`/projects/${project.id}`}
          tooltipLabel="projects.go_to_project"
        />
      </TableCell>
    </TableRow>
  );
}
