import React, { useState } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUploadFileMutation } from 'store/storage/storage';
import {
  Transaction,
  useAddTransactionAttachmentMutation,
  useUpdateTransactionAttachmentMutation,
} from 'store/transactions/transactions';
import { BankAccount, useGetAccountsQuery } from 'store/accounts/accounts';
import { CustomError } from 'store/api';

import StyledInput from './input.styled';

export interface UploadAttachmentProps extends StatusProps {
  transaction: Transaction;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const emptyAccounts= {} as BankAccount;

function UploadAttachment({
  transaction,
  setIsSuccess,
  setErrorMessage,
  setIsError,
  setIsOpen,
}: UploadAttachmentProps) {
  const { t } = useTranslation();
  const [receivedAt, setReceivedAt] = useState('');
  const [uploadedAttachment, setUploadedAttachment] = useState<File | null>();
  const [uploadFile] = useUploadFileMutation();
  const [addTransactionAttachment] = useAddTransactionAttachmentMutation();
  const [updateTransactionAttachment] =
    useUpdateTransactionAttachmentMutation();
  const { account } = useGetAccountsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      account:
        data?.find((item) => item['@id'] === transaction.bankAccount) ??
        emptyAccounts,
    }),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    setUploadedAttachment(event.target.files[0]);
  };

  const handleUploadAttachment = async () => {
    if (!uploadedAttachment) {
      return;
    }
    try {
      const createdAttachment = await addTransactionAttachment({
        cost: transaction.cost['@id'],
        currency: account.currency,
        name: 'attachment',
        receivedAt,
        transaction: transaction['@id'],
      }).unwrap();
      const fileData = new FormData();
      fileData.append('file', uploadedAttachment, uploadedAttachment.name);
      fileData.append('relationName', 'transaction-attachments');
      fileData.append('relationId', String(createdAttachment.id));
      const fileAttachment = await uploadFile(fileData).unwrap();
      await updateTransactionAttachment({
        id: createdAttachment.id,
        file: fileAttachment,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
      <Typography variant="h5">
        {t('transactions.upload_attachment')}
      </Typography>
      <Box display="flex" alignItems="flex-end" gap={2}>
        <Box>
          <InputLabel id="attachment" required>
            {t('inputs.attachment')}
          </InputLabel>
          <StyledInput
            accept=".pdf,.png,.jpg,.jpeg,.docx"
            id="attachment"
            name="attachmentUpload"
            type="file"
            onChange={handleFileChange}
          />
        </Box>
        <Box>
          <InputLabel id="received-at" required>
            {t('inputs.received_at')}
          </InputLabel>
          <TextField
            id="received-at"
            fullWidth
            name="receivedAt"
            type="date"
            onChange={(event) => setReceivedAt(event.target.value)}
            value={receivedAt}
          />
        </Box>
        <Button
          disabled={!receivedAt || !uploadedAttachment}
          onClick={handleUploadAttachment}
          variant="contained"
        >
          {t('button.upload')}
        </Button>
      </Box>
    </Grid>
  );
}

export default UploadAttachment;
