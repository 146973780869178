import { TextField, TextFieldProps, styled } from '@mui/material';

const StyledTextFieldVatNumber = styled(TextField)<TextFieldProps>(() => ({
  flexBasis: '80%',
  '& .MuiInputBase-root': {
    borderRadius: '0 1.2rem 1.2rem 0',
  },
}));

export default StyledTextFieldVatNumber;
