import { HolidaysCondition } from 'store/condition/interfaces';

const calculateCostsSum = (month: string, data: HolidaysCondition) => {
  let sum = 0;

  if (!data[month]) {
    return sum;
  }

  Object.keys(data[month]).forEach((employee) => {
    sum += data[month][employee].cost;
  });

  return sum;
};

export default calculateCostsSum;
