import React, { useMemo } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useParams } from 'react-router-dom';
import { Meeting as MeetingProps } from 'store/meetings/interfaces';
import { useGetMeetingQuery } from 'store/meetings/meetings';
import MeetingWidgets from 'components/MeetingWidgets/MeetingWidgets';
import { useTranslation } from 'react-i18next';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import useGetEmployees from 'hooks/useGetEmployees';
import { MeetingContext } from './context/Meeting.context';

export default function Meeting() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    data: meeting = {} as MeetingProps,
    isLoading: meetingLoading,
    isError: meetingError,
  } = useGetMeetingQuery(id as string, {
    skip: !id,
  });
  const {
    data: employees = [],
    isLoading: employeesLoading,
    isError: employeesError,
  } = useGetEmployees({ isActive: true });

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find((item) => item['@id'] === employeeId);

    if (!employee) {
      return '';
    }

    return `${employee.lastname} ${employee.firstname}`;
  };

  const contextValue = useMemo(
    () => ({
      meeting,
      meetingLoading,
      meetingError,
      employees,
      employeesLoading,
      employeesError,
    }),
    [
      meeting,
      meetingLoading,
      meetingError,
      employees,
      employeesLoading,
      employeesError,
    ],
  );

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <ContentSuspense
          isLoading={meetingLoading || employeesLoading}
          isError={meetingError || employeesError}
          loader={<Skeleton variant="text" width={200} />}
        >
          <NavBarTitle>
            {t('feedbacks.meeting_header', {
              employee: getEmployeeName(meeting.employee),
            })}
          </NavBarTitle>
        </ContentSuspense>
      </NavBarContainer>
      <MeetingContext.Provider value={contextValue}>
        <MeetingWidgets />
      </MeetingContext.Provider>
    </Grid>
  );
}
