import { Close } from '@mui/icons-material';
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import DownloadIcon from 'assets/icons/DownloadIcon';
import downloadZipFiles from 'helpers/downloadZipFiles';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'store/incomes/interfaces';

interface SelectedDataProps {
  headersLength: number;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  invoices: Invoice[];
}

interface SelectedAmounts {
  amount: number;
  amountNet: number;
}

export default function SelectedData({
  headersLength,
  selected,
  setSelected,
  invoices,
}: SelectedDataProps) {
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();

  const sumSelectedInvoiceAmounts = () =>
    invoices.reduce(
      (acc: SelectedAmounts, invoice) => {
        if (selected.includes(String(invoice.id))) {
          acc.amount += invoice.targetAmount;
          acc.amountNet += invoice.amountNet * invoice.conversionRate;
        }

        return acc;
      },
      { amount: 0, amountNet: 0 },
    );

  const selectedAmounts = sumSelectedInvoiceAmounts();

  return (
    <TableRow>
      <TableCell
        colSpan={headersLength / 2}
        sx={{ bgcolor: 'background.list' }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => setSelected([])}>
            <Close fontSize="large" />
          </IconButton>
          <Typography variant="body1">
            {t('invoices.selected', { count: selected.length })}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell
        align="right"
        colSpan={headersLength / 2 - 1}
        sx={{ bgcolor: 'background.list' }}
      >
        <Stack>
          <Typography fontWeight={700} variant="body1">
            {formatAmount(selectedAmounts.amountNet)}
            <Typography component="span" variant="body1">
              &nbsp; netto
            </Typography>
          </Typography>
          <Typography color="text.secondary" fontWeight={700} variant="body2">
            {formatAmount(selectedAmounts.amount)}
            <Typography component="span" variant="body2">
              &nbsp; brutto
            </Typography>
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="right" sx={{ bgcolor: 'background.list' }}>
        <IconButton onClick={() => downloadZipFiles(selected, 'invoices')}>
          <DownloadIcon color="primary" fontSize="large" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
