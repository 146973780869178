import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
  Tab,
  Dialog,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { StatusProps } from 'hoc/Status/Status';
import { useParams } from 'react-router';
import {
  useGetBudgetQuery,
  useUpdateBudgetMutation,
} from 'store/budget/budget';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import { NavBarContainer, NavBarSubtitle, NavBarTitle } from 'common/NavBar';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import BudgetAvatarStack from 'components/BudgetAvatarsStack/BudgetAvatarStack';
import BudgetForm from 'components/BudgetForm';
import BudgetEmployeesList from 'components/BudgetEmployees/BudgetEmployeesList';
import AddBudgetEmployees from 'components/BudgetEmployees/AddBudgetEmployees';
import BudgetCostsList from 'components/BudgetCostsList/BudgetCostsList';
import BudgetSummary from 'components/BudgetSummary/BudgetSummary';
import EmptyBudgetSummary from 'components/EmptyBudgetSummary/EmptyBudgetSummary';
import { FormPayload } from 'components/BudgetForm/interfaces';
import { BudgetType, FormTypes } from 'enums';
import { BudgetsTabValue } from 'pages/Budget/interfaces';
import { CustomError } from 'store/api';
import { Budget } from 'store/budget/interfaces';
import { FormBudgetValues } from './interfaces';

export interface BudgetPreviewProps extends StatusProps {}
export enum BudgetPreviewTabValue {
  summary = '0',
  participants = '1',
  costs = '2',
}
export default function BudgetPreview({
  setIsSuccess,
  setIsErrorCatch,
}: BudgetPreviewProps) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [tabValue, setTabValue] = useState(BudgetPreviewTabValue.summary);
  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: BudgetPreviewTabValue,
  ) => setTabValue(newValue);

  const [isOpen, setIsOpen] = useState(false);

  const {
    data: budget = {} as Budget,
    isLoading: budgetLoading,
    isError: budgetError,
  } = useGetBudgetQuery(id as string);

  const {
    data: employees = [],
    isLoading: employeeLoading,
    isError: employeeError,
  } = useGetEmployeesPreview({
    isActive: true,
  });

  const [updateBudget, { isLoading: updateBudgetLoading }] =
    useUpdateBudgetMutation();

  const initFormBudgetValues = {
    name: budget.name || '',
    budgetGroup: budget.budgetGroup || '',
    type: budget.type || '',
  } as FormBudgetValues;

  const budgetEmployees = employees
    .map((employee) => {
      const foundBudgetEmployee = budget?.budgetEmployees?.find(
        (budgetEmployee) => budgetEmployee.employee === employee['@id'],
      );
      const employeeBudgetId = foundBudgetEmployee
        ? foundBudgetEmployee.id
        : '';

      return {
        ...employee,
        employeeBudgetId,
      };
    })
    .filter((employee) =>
      budget?.budgetEmployees?.some(
        (budgetEmployee) => budgetEmployee.employee === employee['@id'],
      ),
    );

  const handleUpdateBudget = async (formPayload: FormPayload) => {
    try {
      const { dateFrom, dateTo, amount, budgetGroup, ...restOfFormPayload } =
        formPayload;
      const payload = {
        budgetGroup: budgetGroup || null,
        ...restOfFormPayload,
      };
      if (budget) {
        await updateBudget({ id: budget?.id, ...payload }).unwrap();
      }
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  const showBudgetSummary = !!budget?.dateFrom && !!budget?.dateTo;

  return (
    <ContentSuspense isLoading={budgetLoading} isError={budgetError}>
      <Grid item xs={12} md={10} bgcolor="background.other">
        <NavBarContainer>
          <Stack direction="row" alignItems="center">
            <NavigationBackButton
              to="/budget"
              options={{
                state: {
                  tabValue: BudgetsTabValue.list,
                },
              }}
            />
            <Stack>
              <NavBarTitle>
                {budget.name}
                <Chip
                  sx={{ marginLeft: '1rem' }}
                  variant="outlined"
                  label={
                    <Typography variant="chipLabel">
                      {t(`budget.budget_type_${budget?.type}`)}
                    </Typography>
                  }
                />
              </NavBarTitle>
              <NavBarSubtitle>
                {t(`budget.budget_period_${budget?.period}`)}
              </NavBarSubtitle>
            </Stack>
          </Stack>
          <Stack direction="row">
            {budget.type !== BudgetType.global &&
              budget.budgetEmployees?.length > 0 &&
              employees.length && (
                <BudgetAvatarStack employees={budgetEmployees} />
              )}
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsOpen(!isOpen)}
            >
              {t('budget.edit_budget')}
            </Button>
          </Stack>
        </NavBarContainer>
        <TabContext value={tabValue}>
          <StyledTabList
            onChange={handleTabChange}
            TabIndicatorProps={{ children: <span /> }}
          >
            <Tab
              label={t('budget.budget_summary')}
              value={BudgetPreviewTabValue.summary}
            />
            {budget.type === BudgetType.employee && (
              <Tab
                label={t('budget.budget_participants')}
                value={BudgetPreviewTabValue.participants}
              />
            )}
            <Tab
              label={t('budget.budget_costs')}
              value={BudgetPreviewTabValue.costs}
            />
          </StyledTabList>
          <TabPanel value={BudgetPreviewTabValue.summary}>
            {showBudgetSummary ? (
              <BudgetSummary
                budget={budget}
                employees={employees}
                isLoading={budgetLoading || employeeLoading}
                isFetchError={budgetError || employeeError}
              />
            ) : (
              <EmptyBudgetSummary />
            )}
          </TabPanel>
          <TabPanel value={BudgetPreviewTabValue.participants}>
            <Grid container spacing={2}>
              <>
                <Grid item xs={12} md={6}>
                  <AddBudgetEmployees employees={employees} budget={budget} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BudgetEmployeesList
                    participants={budgetEmployees}
                    isLoading={budgetLoading || employeeLoading}
                    isFetchError={budgetError || employeeError}
                  />
                </Grid>
              </>
            </Grid>
          </TabPanel>
          <TabPanel value={BudgetPreviewTabValue.costs}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BudgetCostsList
                  budget={budget}
                  tableTitle={t('budget.budget_costs')}
                  type="all"
                  employees={employees}
                  isLoading={budgetLoading || employeeLoading}
                  isFetchError={budgetError || employeeError}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          maxWidth="md"
          fullWidth
        >
          <BudgetForm
            setIsOpen={setIsOpen}
            formType={FormTypes.edit}
            onSubmit={handleUpdateBudget}
            isSubmitting={updateBudgetLoading}
            initFormValues={initFormBudgetValues}
          />
        </Dialog>
      </Grid>
    </ContentSuspense>
  );
}
