import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import AbsentEmployeesList from 'components/AbsentEmployeesList/AbsentEmployeesList';
import dateFormat from 'dateformat';
import ActionNeededAgreements from 'components/ActionNeededAgreements/ActionNeededAgreements';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import useCheckFeature from 'hooks/useCheckFeature';
import features from 'config/features/features';
import RequestedHolidaysWidget from 'components/RequestedHolidayWidget/RequestedHolidayWidget';
import UserWorkingHours from 'components/UserWorkingHours/UserWorkingHours';
import ResponsibilitiesWidget from 'components/ResponsibilitiesWidget/ResponsibilitiesWidget';
import MasonryColumns from 'common/MasonryColumns/MasonryColumns';
import PipedriveWidget from 'components/PipedriveWidget/PipedriveWidget';
import MyPropertyBookingWidget from 'components/MyPropertyBookingWidget/MyPropertyBookingWidget';
import AddFeedbackWidget from 'components/AddFeedbackWidget/AddFeedbackWidget';
import {
  useGetLoggedEmployeeQuery,
  useGetEmployeeFeedbackSuggestionQuery,
} from 'store/employee/employees';
import { LoggedEmployee, EmployeePreview } from 'store/employee/interfaces';

export default function Homepage() {
  const { t } = useTranslation();
  const { data: loggedEmployee = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery();
  const {
    data: suggestedEmployee = {} as EmployeePreview,
    isError: suggestedEmployeeError,
    isLoading: suggestedEmployeeLoading,
  } = useGetEmployeeFeedbackSuggestionQuery(loggedEmployee?.id, {
    skip: !loggedEmployee.id,
  });

  const currentDate = new Date();
  const isAgreementManager = useCheckRoles(roles.agreements);
  const isHolidaysVerificator = useCheckRoles(roles.holidays);
  const isSalesManager = useCheckRoles(roles.salesManager);

  const isProjectsFeature = useCheckFeature(features.projects);
  const isAgreementsFeature = useCheckFeature(features.agreements);
  const isHolidaysFeature = useCheckFeature(features.holidays);
  const isSalesFeature = useCheckFeature(features.sales);
  const isResponsibilitiesFeature = useCheckFeature(features.responsibilities);
  const isPropertyBookingFeature = useCheckFeature(features.bookings);
  const isFeedbackFeature = useCheckFeature(features.feedback);

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('dashboard.header')}</NavBarTitle>
        <NavBarTitle>{dateFormat(currentDate, 'dd mmmm yyyy')}</NavBarTitle>
      </NavBarContainer>
      <MasonryColumns columns={3}>
        {isProjectsFeature && <UserWorkingHours />}
        {isHolidaysFeature && <AbsentEmployeesList />}
        {isFeedbackFeature && !suggestedEmployeeError && (
          <AddFeedbackWidget
            suggestedEmployee={suggestedEmployee}
            suggestedEmployeeLoading={suggestedEmployeeLoading}
          />
        )}
        {isAgreementManager && isAgreementsFeature && (
          <ActionNeededAgreements />
        )}
        {isHolidaysVerificator && isHolidaysFeature && (
          <RequestedHolidaysWidget />
        )}
        {isSalesManager && isSalesFeature && <PipedriveWidget />}
        {isResponsibilitiesFeature && <ResponsibilitiesWidget />}
        {isPropertyBookingFeature && <MyPropertyBookingWidget />}
      </MasonryColumns>
    </Grid>
  );
}
