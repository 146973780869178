import * as React from 'react';
import { IMaskInput } from 'react-imask';
import { MaskInputProps } from './interfaces';

const IntegerMaskInput = React.forwardRef<HTMLInputElement, MaskInputProps>(
  (props, ref) => {
    const {
      onChange,
      onBlur,
      name,
      className,
      type,
      id,
      onAnimationEnd,
      onAnimationStart,
      autoComplete,
      autoFocus,
      defaultValue,
      disabled,
      onFocus,
      onKeyDown,
      onKeyUp,
      readonly,
      required,
      rows,
      value,
      min,
      max,
      maxLength
    } = props;
    return (
      <IMaskInput
        id={id}
        mask={Number}
        scale={0}
        radix="."
        unmask
        min={min ?? 0}
        maxLength={maxLength}
        inputRef={ref}
        onAccept={(inputValue: any) =>
          onChange({ target: { name: props.name, value: inputValue } })
        }
        onBlur={onBlur}
        value={value}
        name={name}
        className={className}
        type={type}
        aria-describedby={props['aria-describedby']}
        aria-invalid={props['aria-invalid']}
        autoFocus={autoFocus}
        disabled={disabled}
        onFocus={onFocus}
        readOnly={readonly}
        required={required}
        onAnimationStart={onAnimationStart}
        onAnimationEnd={onAnimationEnd}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        rows={rows}
        max={max}
        overwrite
      />
    );
  },
);

export default IntegerMaskInput;
