import api from 'store/api';
import { ApiResponse } from 'store/interfaces';

export interface Bank {
  ['@id']: string;
  id: string;
  name: string;
  swift: string;
}

export interface BanksResponseDto extends ApiResponse {
  'hydra:member': Bank[];
}

export const banksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBanks: builder.query<Bank[], void>({
      query: () => '/banks',
      providesTags: ['Banks'],
      transformResponse: (response: BanksResponseDto) =>
        response['hydra:member'].sort((bank, comparedBank) =>
          bank.name.localeCompare(comparedBank.name),
        ),
    }),
    getBank: builder.query<Bank, string>({
      query: (id: string) => `/banks/${id}`,
      providesTags: ['Banks'],
    }),
    addBank: builder.mutation({
      query: (body) => ({
        url: '/banks',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Banks'],
    }),
    updateBank: builder.mutation<Bank, Partial<Bank> & Pick<Bank, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `/banks/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Banks'],
    }),
  }),
});

export const {
  useGetBanksQuery,
  useAddBankMutation,
  useGetBankQuery,
  useUpdateBankMutation,
} = banksApi;
