import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import { SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface IconPopoverProps {
  Icon: OverridableComponent<SvgIconTypeMap>;
  content: string;
}

function IconPopover({ Icon, content }: IconPopoverProps) {
  const [isHovered, setIsHovered] = useState<SVGSVGElement | null>(null);

  return (
    <>
      <Icon
        aria-owns={isHovered ? 'hovered-icon' : undefined}
        aria-haspopup="true"
        fontSize="large"
        onMouseEnter={(event: {
          currentTarget: React.SetStateAction<SVGSVGElement | null>;
        }) => setIsHovered(event.currentTarget)}
        onMouseLeave={() => setIsHovered(null)}
      />
      <Popover
        id="hovered-icon"
        open={Boolean(isHovered)}
        sx={{ pointerEvents: 'none' }}
        anchorEl={isHovered}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={() => setIsHovered(null)}
        disableRestoreFocus
      >
        <Typography variant="body1" p={1}>
          {content}
        </Typography>
      </Popover>
    </>
  );
}

export default IconPopover;
