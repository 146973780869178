import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import useResourceRequestContext from 'pages/HumanResourceResource/context/useResourceRequestContext';
import { ResourceRequestCandidateState } from 'store/resourceRequestCandidates/interfaces';
import {
  useUpdateResourceRequestCandidateMutation,
  useAddResourceRequestCandidateMutation,
} from 'store/resourceRequestCandidates/resourceRequestCandidates';
import { CustomError } from 'store/api';
import ColumnHeader from './ColumnHeader/ColumnHeader';
import ResourceCandidateTile from './ResourceCandidateTile/ResourceCandidateTile';
import StyledColumnTilesWrapper from './ColumnTilesWrapper.styled';
import { ResourceCandidatesProps } from './interfaces';
import ResourceCandidateAutocomplete from './ResourceCandidateAutocomplete/ResourceCandidateAutocomplete';

export default function ResourceCandidates({
  setErrorMessage,
  setIsError,
}: ResourceCandidatesProps) {
  const { resourceRequestCandidates, resourceRequest } =
    useResourceRequestContext();
  const [isDragging, setIsDragging] = useState(false);

  const [resourceCandidateColumns, setResourceCandidateColumns] = useState({
    [ResourceRequestCandidateState.applied]: resourceRequestCandidates.filter(
      (resourceCandidate) =>
        resourceCandidate.state === ResourceRequestCandidateState.applied,
    ),
    [ResourceRequestCandidateState.pending]: resourceRequestCandidates.filter(
      (resourceCandidate) =>
        resourceCandidate.state === ResourceRequestCandidateState.pending,
    ),
    [ResourceRequestCandidateState.rejected]: resourceRequestCandidates.filter(
      (resourceCandidate) =>
        resourceCandidate.state === ResourceRequestCandidateState.rejected,
    ),
  });

  const [updateResourceRequestCandidate] =
    useUpdateResourceRequestCandidateMutation();
  const [addResourceRequestCandidate] =
    useAddResourceRequestCandidateMutation();

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      setIsDragging(false);
      return;
    }

    const clonedResourceCandidateColumns = {
      ...resourceCandidateColumns,
    };
    const prevResourceCandidateColumns = { ...resourceCandidateColumns };

    const sourceIndex = result.source.index;
    const sourceColumn = result.source
      .droppableId as ResourceRequestCandidateState;

    const destinationIndex = result.destination.index;
    const destinationColumn = result.destination
      .droppableId as ResourceRequestCandidateState;

    let draggedCandidate =
      clonedResourceCandidateColumns[sourceColumn][sourceIndex];

    if (sourceColumn === destinationColumn) {
      const updatedColumnCandidates = [
        ...clonedResourceCandidateColumns[sourceColumn],
      ];
      updatedColumnCandidates.splice(sourceIndex, 1);
      updatedColumnCandidates.splice(destinationIndex, 0, draggedCandidate);

      clonedResourceCandidateColumns[sourceColumn] = updatedColumnCandidates;
      setResourceCandidateColumns(clonedResourceCandidateColumns);
      setIsDragging(false);
      return;
    }

    draggedCandidate = {
      ...draggedCandidate,
      state: destinationColumn,
    };
    const updatedSourceColumnCandidates = [
      ...clonedResourceCandidateColumns[sourceColumn],
    ];
    const updatedDestinationCandidates = [
      ...clonedResourceCandidateColumns[destinationColumn],
    ];

    updatedSourceColumnCandidates.splice(sourceIndex, 1);
    updatedDestinationCandidates.splice(destinationIndex, 0, draggedCandidate);

    clonedResourceCandidateColumns[sourceColumn] =
      updatedSourceColumnCandidates;
    clonedResourceCandidateColumns[destinationColumn] =
      updatedDestinationCandidates;

    setResourceCandidateColumns(clonedResourceCandidateColumns);
    setIsDragging(false);

    try {
      await updateResourceRequestCandidate({
        id: draggedCandidate.id,
        state: destinationColumn,
      }).unwrap();
    } catch (error) {
      setResourceCandidateColumns(prevResourceCandidateColumns);
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleAddResourceRequestCandidate = async (candidateIri: string) => {
    try {
      const resourceRequestCandidate = await addResourceRequestCandidate({
        candidate: candidateIri,
        resourceRequest: resourceRequest['@id'],
        state: ResourceRequestCandidateState.applied,
      }).unwrap();
      setResourceCandidateColumns((prev) => ({
        ...prev,
        [ResourceRequestCandidateState.applied]: [
          resourceRequestCandidate,
          ...prev[ResourceRequestCandidateState.applied],
        ],
      }));
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <ResourceCandidateAutocomplete
        handleAddResourceRequestCandidate={handleAddResourceRequestCandidate}
      />
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={() => setIsDragging(true)}
      >
        <Grid container spacing={6} px={3} pt={3}>
          {Object.keys(resourceCandidateColumns).map((column) => (
            <Grid item xs={4} key={`column-${column}`}>
              <ColumnHeader
                count={
                  resourceCandidateColumns[
                    column as ResourceRequestCandidateState
                  ].length
                }
                column={column}
              />
              <Droppable droppableId={column} key={column}>
                {(provided) => (
                  <StyledColumnTilesWrapper
                    sx={{
                      backgroundColor: isDragging
                        ? 'background.otherBacklight'
                        : 'inherit',
                    }}
                    ref={provided.innerRef}
                    data-rbd-droppable-context-id={
                      provided.droppableProps['data-rbd-droppable-context-id']
                    }
                    data-rbd-droppable-id={
                      provided.droppableProps['data-rbd-droppable-id']
                    }
                  >
                    {resourceCandidateColumns[
                      column as ResourceRequestCandidateState
                    ].map((resourceCandidate, index) => (
                      <ResourceCandidateTile
                        resourceCandidate={resourceCandidate.candidate}
                        key={resourceCandidate.id}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </StyledColumnTilesWrapper>
                )}
              </Droppable>
            </Grid>
          ))}
        </Grid>
      </DragDropContext>
    </>
  );
}
