import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import CancelButton from 'common/CancelButton/CancelButton';
import AddButton from 'common/AddButton/AddButton';
import useBudgetPlannerContext from 'pages/BudgetPlanner/context/useBudgetPlannerContext';
import { useUpdateBudgetMutation } from 'store/budget/budget';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import { CustomError } from 'store/api';
import { BudgetPlannerActionButtonsProps } from './interfaces';

export default function BudgetPlannerActionButtons({
  setIsSuccess,
  setIsErrorCatch,
}: BudgetPlannerActionButtonsProps) {
  const { id } = useParams<{ id: string }>();
  const { handleResetBudgetConfig, budgetConfigData } =
    useBudgetPlannerContext();
  const [updateBudget] = useUpdateBudgetMutation();

  const handleOnSaveBudget = async () => {
    const changedBudgetConfigData = budgetConfigData.filter(
      (configItem) =>
        [
          configItem.baseAmount,
          configItem.linearGrowth,
          configItem.exponentialGrowth,
          configItem.unitGrowth,
        ].some((configField) => configField !== undefined),
    );

    const payload = changedBudgetConfigData.map(
      ({
        period,
        baseAmount,
        linearGrowth,
        unitGrowth,
        exponentialGrowth,
      }) => ({
        period: period.replaceAll('.', ''),
        ...(baseAmount !== undefined && {
          baseAmount: +(baseAmount * BACKEND_AMOUNT_MODIFIER).toFixed(2),
        }),
        ...(linearGrowth !== undefined && { linearGrowth }),
        ...(exponentialGrowth !== undefined && { exponentialGrowth }),
        ...(unitGrowth !== undefined && { unitGrowth }),
      }),
    );

    try {
      await updateBudget({
        id,
        config: payload,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <CancelButton onClick={handleResetBudgetConfig} />
      <AddButton
        onClick={handleOnSaveBudget}
        noIcon
        label="budget.save_budget"
      />
    </Stack>
  );
}
