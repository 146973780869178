import React, { useState } from 'react';
import { FormTypes } from 'enums';
import { BenefitPrice } from 'store/benefits/interfaces';
import fullDateFormat from 'helpers/fullDateFormat';
import { useUpdateBenefitPricingMutation } from 'store/benefits/benefits';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import BenefitPriceForm from '../BenefitPriceForm';

export interface EditBenefitPriceProps extends StatusProps {
  price: BenefitPrice;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EditBenefitPrice({
  price,
  setIsOpen,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: EditBenefitPriceProps) {
  const [benefitPrice, setBenefitPrice] = useState({
    price: String(price.price),
    taxPercent: String(price.taxPercent),
    defaultCompanyCoverageAmount: String(price.defaultCompanyCoverageAmount),
    validFrom: fullDateFormat(price.validFrom),
    validTo: price.validTo ? fullDateFormat(price.validTo) : '',
  });
  const [updatePrice] = useUpdateBenefitPricingMutation();

  const handleUpdatePrice = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updatePrice({
        id: price.id,
        price: +benefitPrice.price,
        taxPercent: +benefitPrice.taxPercent,
        defaultCompanyCoverageAmount:
          +benefitPrice.defaultCompanyCoverageAmount,
        validFrom: new Date(benefitPrice.validFrom),
        validTo: benefitPrice.validTo ? new Date(benefitPrice.validTo) : null,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <BenefitPriceForm
      benefitPrice={benefitPrice}
      formType={FormTypes.edit}
      handleChange={(key, value) =>
        setBenefitPrice({ ...benefitPrice, [key]: value })
      }
      handleSubmit={handleUpdatePrice}
      setIsOpen={setIsOpen}
    />
  );
}
