import { Box, BoxProps, styled } from '@mui/material';

const StyledDateRangePicker = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  border: `0.1rem solid ${theme.palette.border.main}`,
  overflow: 'hidden',
  padding: theme.spacing(1),
  '& .rdrDayNumber span': {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  '& .rdrDayToday .rdrDayNumber span': {
    fontWeight: 600,
  },
  '& .rdrDay.rdrDayToday .rdrDayNumber span:after': {
    background: 'none',
  },
  '& .rdrNextPrevButton': {
    background: 'none',
  },
  '& .rdrWeekDay': {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.2rem',
    fontWeight: 600,
    letterSpacing: '0.04rem',
    color: theme.palette.text.disabled,
  },
  '& .rdrDayPassive': {
    color: theme.palette.text.disabled,
  },
  '& .rdrMonthPicker select': {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.8rem',
    fontWeight: 600,
  },
  '& .rdrYearPicker select': {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.8rem',
    fontWeight: 600,
  },
}));

export default StyledDateRangePicker;
