import { t } from 'i18next';
import { ChipOwnProps } from '@mui/material';
import { HolidayRowType } from '../interfaces';

export default function getChipOptions() {
  const getChipLabel = (type: HolidayRowType) => {
    switch (type) {
      case HolidayRowType.holiday:
        return t('label.accepted');
      case HolidayRowType.holidayRequest:
        return t('label.pending');
      default:
        return '';
    }
  };

  const getChipStyles = (type: HolidayRowType): ChipOwnProps['sx'] => {
    switch (type) {
      case HolidayRowType.holiday:
        return {
          backgroundColor: 'success.main',
          color: 'main.white',
          borderColor: 'success.main',
        };
      case HolidayRowType.holidayRequest:
        return {
          backgroundColor: 'main.white',
          color: 'text.primary',
          borderColor: 'border.main',
        };
      default:
        return {};
    }
  };

  return { getChipLabel, getChipStyles };
}
