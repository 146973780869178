import React from 'react';
import DownloadIcon from 'assets/icons/DownloadIcon';
import SendIcon from 'assets/icons/SendIcon';
import { ContentCopy } from '@mui/icons-material';
import EditIcon from 'assets/icons/EditIcon';
import DeleteIcon from 'assets/icons/DeleteIcon';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import { Invoice } from 'store/incomes/interfaces';
import { NavigateFunction } from 'react-router';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const getMenuItems = (
  navigate: NavigateFunction,
  invoice: Invoice,
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => [
  {
    label: 'invoices.download_pdf',
    icon: <DownloadIcon fontSize="large" />,
    clickHandler: () =>
      downloadFileWithAuth(
        `${apiUrl}/incomes/${invoice.id}`,
        invoice.name,
        true,
      ),
  },
  {
    label: 'invoices.send_mail',
    icon: <SendIcon fontSize="large" />,
    clickHandler: () => navigate(`/invoices/${invoice.id}/email`),
  },
  {
    label: 'label.create_similar',
    icon: <ContentCopy fontSize="large" />,
    clickHandler: () =>
      navigate('/invoices/add', { state: { income: invoice } }),
  },
  {
    label: 'invoices.edit_invoice',
    icon: <EditIcon fontSize="large" />,
    clickHandler: () => navigate(`/invoices/${invoice.id}/edit`),
  },
  {
    label: 'invoices.delete_invoice',
    icon: <DeleteIcon fontSize="large" />,
    clickHandler: () => setIsDialogOpen(true),
  },
];

export default getMenuItems;
