import holidayTypes from 'components/EmployeeHolidayForm/holidayTypes';

export const getHolidayIcon = (typeId: string) => {
  const matchedType = holidayTypes.find(
    (holidayType) => holidayType.type === typeId,
  );

  if (!matchedType) {
    return '';
  }

  return matchedType.icon;
};

export const getHolidayName = (value: string) =>
  holidayTypes.map((type) => (type.type === value ? type.description : ''));
