import React from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { createSearchParams } from 'react-router-dom';
import Link from 'common/Link/Link';
import fullDateFormat from 'helpers/fullDateFormat';
import TransactionChoice from 'components/EditClientForm/Payments/TransactionChoice';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { IncomeTransaction } from 'store/incomeTransactions/interfaces';
import { Client } from 'store/clients/clients';
import { Invoice } from 'store/incomes/interfaces';
import { Transaction } from 'store/transactions/transactions';
import replaceApiIri from 'helpers/replaceApiIri';

interface ResultProps {
  income: Invoice;
  client: Client;
  incomeTransactions: IncomeTransaction[];
  transactions: Transaction[];
}

function Result({
  income,
  client,
  incomeTransactions,
  transactions,
}: ResultProps) {
  const formatAmount = useCurrencyFormat();

  const paidAmount =
    income.incomeTransactions.reduce(
      (acc, incomeTransaction: IncomeTransaction) =>
        acc + incomeTransaction.amount,
      0,
    ) / 100;

  const incomeAmount = Math.round(income.amount * 100) / 100;

  return (
    <TableRow key={income.id}>
      <TableCell>
        <Link
          to={`/invoices/${income.id}/edit`}
          sx={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Typography variant="body2">{income.name}</Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {formatAmount(income.amount, income.currency)}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body2">
            {fullDateFormat(new Date(income.saleDate))}
          </Typography>
          <Typography variant="body2">
            {fullDateFormat(new Date(income.dueDate))}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={{
          bgcolor: paidAmount >= incomeAmount ? 'success.light' : 'error.main',
        }}
      >
        {income.incomeTransactions.length > 0 &&
          income.incomeTransactions.map(({ transaction, amount }) => (
            <Box
              key={transaction.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2">
                {formatAmount(amount / 100, income.currency)}
              </Typography>
              <Link
                to={{
                  pathname: `/transactions/${replaceApiIri(
                    transaction.bankAccount,
                    'bank-accounts',
                  )}`,
                  search: createSearchParams({
                    cost: String(client.defaultCost),
                    'execDate[after]': fullDateFormat(transaction.execDate),
                    'execDate[before]': fullDateFormat(transaction.execDate),
                    'amount[gte]': String(transaction.amount),
                    'amount[lte]': String(transaction.amount),
                  }).toString(),
                }}
                sx={{ textDecoration: 'none' }}
              >
                <Typography variant="body2">#{transaction.id}</Typography>
              </Link>
            </Box>
          ))}
      </TableCell>
      <TableCell>
        <TransactionChoice
          income={income}
          incomeTransactions={incomeTransactions}
          transactions={transactions}
        />
      </TableCell>
    </TableRow>
  );
}

export default Result;
