import { useSelector } from 'react-redux';
import { RootState } from 'store';

const useCheckFeature = (feature: string) => {
  const organizationFeatures = useSelector(
    (state: RootState) => state.organization.organization.features,
  );

  return organizationFeatures.includes(feature);
};

export default useCheckFeature;
