import store from 'store';
import api from 'store/api';
import { transactionsApi } from 'store/transactions/transactions';
import { BudgetTransaction } from 'store/budget/interfaces';

export default function updateTransactionsCache(
  updatedTransaction: BudgetTransaction,
) {
  const state = store.getState();

  const transactionCache = api.util?.selectInvalidatedBy(state, [
    { type: 'Transactions' },
  ]);

  transactionCache
    .filter(({ endpointName }) => endpointName === 'getTransactions')
    .forEach(({ originalArgs }) => {
      store.dispatch(
        transactionsApi.util.updateQueryData(
          'getTransactions',
          originalArgs,
          (draft) => {
            const changedTransactionIndex = draft['hydra:member'].findIndex(
              (item) => item.id === updatedTransaction.transaction.id,
            );

            const changedTransaction = draft['hydra:member'].find(
              (item) => item.id === updatedTransaction.transaction.id,
            );

            const { transaction, ...restUpdatedTransaction } =
              updatedTransaction;

            const budgetTransaction = {
              ...restUpdatedTransaction,
              transaction: updatedTransaction.transaction['@id'],
            };

            if (changedTransactionIndex !== -1 && changedTransaction) {
              draft['hydra:member']?.splice(changedTransactionIndex, 1, {
                ...transaction,
                cost: changedTransaction?.cost,
                budgetTransactions: [budgetTransaction],
              });
            }
          },
        ),
      );
    });
}
