import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import { useTranslation } from 'react-i18next';
import ChartOfIncomes from 'components/ChartOfIncomes/ChartOfIncomes';
import { useGetClientsQuery } from 'store/clients/clients';
import useGetChartIncome from './useGetChartIncome';

function IncomeChart() {
  const { t } = useTranslation();
  const { incomes, fetched, isError } = useGetChartIncome();
  const {
    data: clients = [],
    isLoading: clientsLoading,
    isError: clientsError,
  } = useGetClientsQuery({ properties: ['name'] });

  return (
    <Grid item xs={10} bgcolor="main.white">
      <NavBarContainer>
        <NavBarTitle>{t('invoices.income_chart')}</NavBarTitle>
      </NavBarContainer>
      <ContentSuspense
        isLoading={!fetched || clientsLoading}
        isError={isError || clientsError}
      >
        <ChartOfIncomes incomes={incomes} clients={clients} />
      </ContentSuspense>
    </Grid>
  );
}

export default IncomeChart;
