import React from 'react';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import IntegerMaskInput from 'common/MaskInputs/IntegerMaskInput';
import { agreementVariant } from 'components/AddAgreementForm/selectData';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import { getHolidayIcon } from 'helpers/getHolidayData';
import useHolidayTypes from 'hooks/useHolidayTypes';
import defaultValues from './defaultValues';
import schema from './DefaultAnnualLeaveForm.schema';
import { FormPayload, DefaultAnnualLeaveFormProps } from './interfaces';

const validate = zodResolver(schema);

function DefaultAnnualLeaveForm({
  isOpen,
  setIsOpen,
  onSubmit,
  isSubmitting,
  type,
  initValues = defaultValues,
}: DefaultAnnualLeaveFormProps) {
  const { t } = useTranslation();
  const { activeHolidayTypes } = useHolidayTypes();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initValues,
  });

  const variantOptions = agreementVariant.map((variant) => ({
    value: variant,
    label: variant,
  }));

  const { variant, dateFrom, days } = watch();

  const isSubmissionDisabled =
    !variant || !dateFrom || !days || !!Object.keys(errors).length;

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            {t(`employees.edit_annual_leave_${type}`)}
          </Typography>
          <IconButton
            sx={{ bgcolor: 'secondary.light' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormControl fullWidth sx={{ rowGap: 3, mt: 4 }}>
            <ControlTextField
              control={control}
              errors={errors}
              type="number"
              name="days"
              label={t('employees.days')}
              required
              fullWidth
              InputProps={{
                inputComponent: IntegerMaskInput as any,
                inputProps: {
                  min: 0,
                  max: 366,
                },
              }}
            />
            <ControlTextField
              control={control}
              errors={errors}
              label={t('employees.date_from')}
              type="date"
              name="dateFrom"
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <ControlSelect
              control={control}
              errors={errors}
              selectOptions={variantOptions}
              name="variant"
              label={t('agreements.header_variant')}
              required
            />
            <ControlSelect
              control={control}
              errors={errors}
              renderOptions={activeHolidayTypes.map((holidayType) => (
                <MenuItem key={holidayType.id} value={holidayType['@id']}>
                  <Stack direction="row" alignItems="center">
                    {getHolidayIcon(holidayType.id)}
                    <Typography ml={1} variant="body1">
                      {holidayType.name}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
              name="holidayType"
              label={t('label.holiday_type')}
              required
            />
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <SubmitButton
              disabled={isSubmissionDisabled}
              isLoading={isSubmitting}
            />
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default DefaultAnnualLeaveForm;
