import React from 'react';
import { createSvgIcon } from '@mui/material';

const UserSearchIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.24"
      d="M13.75 6.25C13.75 8.32107 12.0711 10 10 10C7.92893 10 6.25 8.32107 6.25 6.25C6.25 4.17893 7.92893 2.5 10 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99995 3.33366C8.38912 3.33366 7.08328 4.6395 7.08328 6.25033C7.08328 7.86116 8.38912 9.16699 9.99995 9.16699C11.6108 9.16699 12.9166 7.86116 12.9166 6.25033C12.9166 4.6395 11.6108 3.33366 9.99995 3.33366ZM5.41662 6.25033C5.41662 3.71902 7.46865 1.66699 9.99995 1.66699C12.5313 1.66699 14.5833 3.71902 14.5833 6.25033C14.5833 8.78163 12.5313 10.8337 9.99995 10.8337C7.46865 10.8337 5.41662 8.78163 5.41662 6.25033ZM14.5833 13.3337C13.8929 13.3337 13.3333 13.8933 13.3333 14.5837C13.3333 15.274 13.8929 15.8337 14.5833 15.8337C14.9287 15.8337 15.24 15.6947 15.4672 15.4675C15.6943 15.2404 15.8333 14.9291 15.8333 14.5837C15.8333 13.8933 15.2736 13.3337 14.5833 13.3337ZM11.6666 14.5837C11.6666 12.9728 12.9725 11.667 14.5833 11.667C16.1941 11.667 17.5 12.9728 17.5 14.5837C17.5 15.0856 17.3727 15.5585 17.1492 15.9711L18.0892 16.9111C18.4146 17.2365 18.4146 17.7641 18.0892 18.0896C17.7638 18.415 17.2361 18.415 16.9107 18.0896L15.9707 17.1496C15.5581 17.3731 15.0852 17.5003 14.5833 17.5003C12.9725 17.5003 11.6666 16.1945 11.6666 14.5837ZM8.23682 12.5003C8.2685 12.5003 8.30065 12.5003 8.33328 12.5003H9.16662C9.62685 12.5003 9.99995 12.8734 9.99995 13.3337C9.99995 13.7939 9.62685 14.167 9.16662 14.167H8.33328C7.51892 14.167 7.23343 14.171 7.01222 14.215C6.0205 14.4123 5.24525 15.1875 5.04799 16.1793C5.00399 16.4005 4.99995 16.686 4.99995 17.5003C4.99995 17.9606 4.62685 18.3337 4.16662 18.3337C3.70638 18.3337 3.33328 17.9606 3.33328 17.5003C3.33328 17.4677 3.33327 17.4355 3.33327 17.4039C3.3331 16.7225 3.33298 16.2581 3.41334 15.8541C3.74212 14.2012 5.0342 12.9092 6.68707 12.5804C7.09108 12.5 7.55548 12.5001 8.23682 12.5003Z"
      fill="currentColor"
    />
  </svg>,
  'UserSearchIcon',
);

export default UserSearchIcon;
