import React from 'react';
import { Box, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import { FormTypes } from 'enums';
import schema from './ProjectEmployeeForm.schema';
import { ProjectEmployeeFromProps, FormPayload } from './interfaces';
import getEmployeeOptions from './getEmployeeOptions';
import defaultValues from '../defaultValues';
import projectRoleOptions from './projectRoleOptions';

const validate = zodResolver(schema);

export default function ProjectEmployeeForm({
  setIsDialogOpen,
  onSubmit,
  isSubmitting,
  selectEmployees,
  formType,
  initialValues = defaultValues,
}: ProjectEmployeeFromProps) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const { t } = useTranslation();
  const { employee, role } = watch();
  const isDisableSubmission =
    !!Object.keys(errors).length || !employee || !role;

  const employeeOptions = getEmployeeOptions(selectEmployees);

  return (
    <>
      <DialogHeader
        title={t(
          formType === FormTypes.add
            ? 'transactions.transaction_employee'
            : 'employees.edit_header',
        )}
        setIsOpen={setIsDialogOpen}
      />
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box display="flex" flexDirection="column" pt={4} gap={3}>
            <ControlSelect
              control={control}
              name="employee"
              errors={errors}
              label="agreements.header_employee"
              required
              selectOptions={employeeOptions}
              disabled={formType === FormTypes.edit}
            />
            <ControlSelect
              control={control}
              name="role"
              errors={errors}
              label="label.role"
              required
              selectOptions={projectRoleOptions}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <SubmitButton
              disabled={isDisableSubmission}
              isLoading={isSubmitting}
              iconType={formType === FormTypes.add ? 'add' : 'save'}
              label={formType === FormTypes.add ? 'transactions.transaction_employee' : 'button.save'}
            />
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
