import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  {
    align: 'left',
    label: 'label.name',
  },
  {
    align: 'center',
    label: 'inputs.amount',
  },
  {
    align: 'left',
    label: 'transactions.related_received_date',
  },
  {
    align: 'left',
    label: 'label.description',
  },
];

export default headers;
