import {
  BarSeriesOption,
  AriaComponentOption,
  TooltipComponentOption,
} from 'echarts';
import theme from './theme';

interface CustomSeries extends Omit<BarSeriesOption, 'itemStyle'> {
  itemStyle?: {
    normal: {
      barBorderRadius: number[];
    };
  };
}

export interface CustomFormatterParam {
  value: number;
  seriesData: string[];
  seriesName: string;
  dataIndex: number;
  marker: string;
  data: string | number;
  name: string;
}

interface CustomTooltip extends Omit<TooltipComponentOption, 'formatter'> {
  formatter?: (params: CustomFormatterParam[]) => string;
}

interface GetCharOptionsProps {
  xAxisData: string[];
  seriesData?: string[];
  seriesName?: string;
  xAxisLabelFormatter?: (value: string) => string;
  barWidth?: number;
  series?: CustomSeries[];
  aria?: AriaComponentOption;
  tooltip?: CustomTooltip;
}

export default function getCharOptions({
  xAxisData,
  seriesData,
  seriesName,
  barWidth = 80,
  aria,
  xAxisLabelFormatter,
  series,
  tooltip,
}: GetCharOptionsProps) {
  return {
    textStyle: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.secondary,
      ...theme.typography.body2,
    },
    tooltip: tooltip || {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      data: xAxisData,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        formatter: xAxisLabelFormatter || ((value: string) => value),
        interval: 0,
      },
    },
    yAxis: {
      type: 'value',
      splitNumber: 4,
    },
    series: series || [
      {
        name: seriesName,
        type: 'bar',
        barWidth,
        data: seriesData,
        color: theme.palette.background.blueBar,
        itemStyle: {
          normal: {
            barBorderRadius: [8, 8, 8, 8],
          },
        },
      },
    ],
    aria,
  };
}
