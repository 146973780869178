// this hook should be used to select a single employee preview
import { EmployeePreview } from 'store/employee/interfaces';
import useGetEmployeesPreview from './useGetEmployeesPreview';

interface UseGetSelectedEmployeePreviewProps {
  employeeIri: string;
  filters?: Parameters<typeof useGetEmployeesPreview>[0];
  options?: Parameters<typeof useGetEmployeesPreview>[1];
}

const emptyEmployee = {} as EmployeePreview;
export default function useGetSelectedEmployeePreview({
  employeeIri,
  filters = {},
  options,
}: UseGetSelectedEmployeePreviewProps) {
  const {
    data: employees = [],
    isLoading,
    isError,
    isSuccess,
  } = useGetEmployeesPreview(filters, options);

  return {
    selectedEmployee:
      employees?.find((employee) => employee['@id'] === employeeIri) ??
      emptyEmployee,
    isLoading,
    isError,
    isSuccess,
  };
}
