import React from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as MuiLink, LinkOwnProps } from '@mui/material';
import RouterLinkComponent from './RouterLinkComponent';

export default function Link(
  props: RouterLinkProps & Omit<LinkOwnProps, 'component'>,
) {
  return <MuiLink {...props} component={RouterLinkComponent} />;
}
