import React from 'react';
import { IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditIcon from 'assets/icons/EditIcon';
import { useNavigate } from 'react-router-dom';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { useTranslation } from 'react-i18next';

interface EditButtonProps {
  navigateTo?: string;
  onClick?: (params?: any) => any;
  v2?: boolean; // v2 is used for the new figma's design
  testId?: string;
}

function EditButton({
  navigateTo,
  onClick,
  v2 = false,
  testId = 'edit-button',
}: EditButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TitleTooltip
      title={t('label.edit')}
    >
      <IconButton
        data-testid={testId}
        sx={{
          bgcolor: v2 ? 'transparent' : 'secondary.light',
          width: '3.2rem',
          height: '3.2rem',
          borderRadius: '0.8rem',
        }}
        onClick={navigateTo ? () => navigate(navigateTo) : onClick}
      >
        {v2 ? (
          <EditIcon sx={{ color: 'primary.main', fontSize: '2rem' }} />
        ) : (
          <BorderColorIcon color="secondary" />
        )}
      </IconButton>
    </TitleTooltip>
  );
}

export default EditButton;
