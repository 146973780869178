import React from 'react';
import { Grid } from '@mui/material';
import { Controller, FieldValues, Path } from 'react-hook-form';
import 'react-calendar/dist/Calendar.css';
import StyledCalendar from 'components/EditHolidayForm/DateRange/styled/Calendar.styled';
import getCalendarType from 'helpers/getCalendarType';
import useOrganizationStartWeekDay from 'hooks/useOrganizationStartWeekDay';
import { ControlDateRangeProps } from './interfaces';

function ControlDateRange<T extends FieldValues>({
  control,
  getValues,
}: ControlDateRangeProps<T>) {
  const startWeekDay = useOrganizationStartWeekDay();
  const calendarTypeName = getCalendarType(startWeekDay);

  const nameFrom = 'dateFrom' as Path<T>;
  const nameTo = 'dateTo' as Path<T>;
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <Controller
          name={nameFrom}
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledCalendar
              value={value}
              onChange={onChange}
              calendarType={calendarTypeName}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name={nameTo}
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledCalendar
              value={value}
              onChange={onChange}
              minDate={getValues(nameFrom)}
              calendarType={calendarTypeName}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default ControlDateRange;
