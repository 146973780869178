import React from 'react';
import { useParams } from 'react-router-dom';
import {
  TransactionsData,
  useGetTransactionsQuery,
} from 'store/transactions/transactions';
import { Box } from '@mui/material';
import ReactEChart from 'echarts-for-react';
import getAmountMonthsBack from 'helpers/getAmountMonthsBack';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';

function TransactionsChart() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data: transactions = {} as TransactionsData } =
    useGetTransactionsQuery({ cost: id }, { skip: !id });
  const displayedPeriod = getAmountMonthsBack(new Date(), 24);

  const monthlyAmountValues = displayedPeriod.map((period) => {
    const monthlyRelatedTransactions = transactions['hydra:member'].filter(
      (transaction) =>
        dateFormat(transaction.connectedMonth, 'yyyy-mm') === period,
    );
    const monthlyAmount = monthlyRelatedTransactions.reduce(
      (acc, transaction) => acc + Math.abs(transaction.amount),
      0,
    );
    return +monthlyAmount.toFixed(2);
  });

  const chartOptions = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      top: '5%',
      right: '5%',
      bottom: '10%',
      left: '5%',
    },
    xAxis: {
      axisTick: {
        alignWithLabel: true,
      },
      type: 'category',
      data: displayedPeriod,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: t('inputs.amount'),
        type: 'bar',
        data: monthlyAmountValues,
      },
    ],
  };

  return (
    <Box py={2} bgcolor="main.white" borderRadius={4} mb={0.5}>
      <ReactEChart option={chartOptions} style={{ width: '100%' }} />
    </Box>
  );
}

export default TransactionsChart;
