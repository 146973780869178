import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface TransactionBudgetSelectProps {
  selectId: string;
  value: string;
  onChangeFn: (event: SelectChangeEvent) => void;
  placeholder?: string;
  selectOptions: {
    id: string | number;
    name: string;
  }[];
}

export default function TransactionBudgetSelect({
  selectId,
  value,
  onChangeFn,
  placeholder = '',
  selectOptions,
}: TransactionBudgetSelectProps) {
  const { t } = useTranslation();
  const sortedByTranslatedLabelOptions = selectOptions.sort(
    (option, comparedOption) =>
      t(option.name).localeCompare(t(comparedOption.name)),
  );

  return (
    <FormControl fullWidth>
      <Select displayEmpty id={selectId} value={value} onChange={onChangeFn}>
        {placeholder.length > 0 && (
          <MenuItem value="">
            <em>{t(placeholder)}</em>
          </MenuItem>
        )}
        {sortedByTranslatedLabelOptions.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {t(item.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
