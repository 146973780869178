import { useContext } from 'react';

import {
  BookPropertiesContext,
  BookPropertiesContextProps,
} from './BookProperties.context';

function useBookPropertiesContext(): BookPropertiesContextProps {
  const context = useContext(BookPropertiesContext);

  if (!context) {
    throw new Error(
      'useBookPropertiesContext must be used within a BookPropertiesProvider',
    );
  }

  return context;
}

export default useBookPropertiesContext;
