import React from 'react';
import { BookingType, PropertyType } from 'components/Properties/enums';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DeskIcon from '@mui/icons-material/Desk';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import MonitorIcon from '@mui/icons-material/Monitor';
import LaptopIcon from '@mui/icons-material/Laptop';
import CableIcon from '@mui/icons-material/Cable';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MouseIcon from '@mui/icons-material/Mouse';
import CellTowerIcon from '@mui/icons-material/CellTower';
import PendingIcon from '@mui/icons-material/Pending';
import DevicesIcon from '@mui/icons-material/Devices';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChairAltIcon from '@mui/icons-material/ChairAlt';
import RouterIcon from '@mui/icons-material/Router';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';

export const propertyTypes = [
  {
    type: PropertyType.room,
    description: 'property.room',
    icon: <MeetingRoomIcon key="room" />,
  },
  {
    type: PropertyType.desk,
    description: 'property.desk',
    icon: <DeskIcon key="desk" />,
  },
  {
    type: PropertyType.parking,
    description: 'property.parking',
    icon: <LocalParkingIcon key="parking" />,
  },
  {
    type: PropertyType.monitor,
    description: 'property.monitor',
    icon: <MonitorIcon key="monitor" />,
  },
  {
    type: PropertyType.notebook,
    description: 'property.notebook',
    icon: <LaptopIcon key="laptop" />,
  },
  {
    type: PropertyType.accessory,
    description: 'property.accessory',
    icon: <CableIcon key="accessory" />,
  },
  {
    type: PropertyType.headphone,
    description: 'property.headphone',
    icon: <HeadphonesIcon key="headphone" />,
  },
  {
    type: PropertyType.keyboard,
    description: 'property.keyboard',
    icon: <KeyboardIcon key="keyboard" />,
  },
  {
    type: PropertyType.mouse,
    description: 'property.mouse',
    icon: <MouseIcon key="mouse" />,
  },
  {
    type: PropertyType.network,
    description: 'property.network',
    icon: <CellTowerIcon key="network" />,
  },
  {
    type: PropertyType.other,
    description: 'property.other',
    icon: <PendingIcon key="other" />,
  },
  {
    type: PropertyType.pc,
    description: 'property.pc',
    icon: <DevicesIcon key="pc" />,
  },
  {
    type: PropertyType.phone,
    description: 'property.phone',
    icon: <PhoneAndroidIcon key="phone" />,
  },
  {
    type: PropertyType.printer,
    description: 'property.printer',
    icon: <PrintIcon key="printer" />,
  },
  {
    type: PropertyType.shredder,
    description: 'property.shredder',
    icon: <ReceiptIcon key="shredder" />,
  },
  {
    type: PropertyType.chairs,
    description: 'property.chairs',
    icon: <ChairAltIcon key="chairs" />,
  },
  {
    type: PropertyType.workstation,
    description: 'property.workstation',
    icon: <DevicesOtherIcon key="workstation" />,
  },
  {
    type: PropertyType.router,
    description: 'property.router',
    icon: <RouterIcon key="router" />,
  },
];

export const bookingTypes = [
  {
    description: 'properties.minutes',
    value: BookingType.minutes,
  },
  {
    description: 'properties.days',
    value: BookingType.days,
  },
  {
    description: 'property.permanently',
    value: BookingType.permanently,
  },
];
