import React, { useState } from 'react';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Position } from 'store/positions/positions';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import { useTranslation } from 'react-i18next';
import EditButton from 'common/EditButton/EditButton';
import EditPositionForm from 'components/PositionsList/EditPositionForm';

export interface PositionsListProps {
  positions: Position[];
}

function PositionsList({ positions }: PositionsListProps) {
  const [chosenPositionId, setChosenPositionId] = useState('');
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const headers = ['positions.header_position'];
  const { t } = useTranslation();

  return (
    <>
      <TableContainer sx={{ px: 3, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            {positions.length ? (
              positions.map((position) => (
                <TableRow key={position.id}>
                  <TableCell>
                    <Typography variant="body2">{position.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <EditButton
                      onClick={() => {
                        setChosenPositionId(position.id.toString());
                        setIsEditDialogOpen(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoDataRow headers={headers} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        fullWidth
      >
        <EditPositionForm
          id={Number(chosenPositionId)}
          setIsOpen={setIsEditDialogOpen}
        />
      </Dialog>
    </>
  );
}

export default PositionsList;
