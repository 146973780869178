import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetCostsQuery } from 'store/costs/costs';

function Filters() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});
  const [dateFrom, setDateFrom] = useState(
    searchParams.get('execDate[after]') ?? '',
  );
  const [dateTo, setDateTo] = useState(
    searchParams.get('execDate[before]') ?? '',
  );
  const [cost, setCost] = useState(searchParams.get('cost') ?? '');
  const [amountGreater, setAmountGreater] = useState(
    searchParams.get('amount[gte]') ?? '',
  );
  const [amountLess, setAmountLess] = useState(
    searchParams.get('amount[lte]') ?? '',
  );
  const { data: costs = [] } = useGetCostsQuery({ properties: ['id', 'name'] });

  const handleApplyFilters = () => {
    setSearchParams(() => ({
      ...(dateFrom && { 'execDate[after]': dateFrom }),
      ...(dateTo && { 'execDate[before]': dateTo }),
      ...(cost && { cost }),
      ...(amountLess && { 'amount[lte]': amountLess }),
      ...(amountGreater && { 'amount[gte]': amountGreater }),
    }));
  };

  const handleClearFilters = () => {
    setDateFrom('');
    setDateTo('');
    setCost('');
    setAmountGreater('');
    setAmountLess('');
    setSearchParams({});
  };

  return (
    <Grid item xs={12} display="flex" alignItems="center" gap={2} mb={1}>
      <TextField
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        label={t('label.date_from')}
        onChange={(event) => setDateFrom(event.target.value)}
        type="date"
        value={dateFrom}
      />
      <TextField
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: dateFrom,
        }}
        label={t('label.date_to')}
        onChange={(event) => setDateTo(event.target.value)}
        type="date"
        value={dateTo}
      />
      <FormControl fullWidth>
        <InputLabel id="cost-select">{t('inputs.cost')}</InputLabel>
        <Select
          id="cost-select"
          label={t('inputs.cost')}
          onChange={(event) => setCost(event.target.value)}
          value={cost}
        >
          <MenuItem value="">
            <em>{t('general.placeholder')}</em>
          </MenuItem>
          {costs.map((costData) => (
            <MenuItem key={costData.id} value={costData.id}>
              {costData.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label={t('label.amount_gte')}
        onChange={(event) => setAmountGreater(event.target.value)}
        type="number"
        value={amountGreater}
      />
      <TextField
        fullWidth
        label={t('label.amount_lte')}
        onChange={(event) => setAmountLess(event.target.value)}
        type="number"
        value={amountLess}
      />
      <Box display="flex" flexDirection="column" gap={1}>
        <Button
          color="primary"
          onClick={handleApplyFilters}
          variant="contained"
        >
          {t('button.apply')}
        </Button>
        <Button
          color="secondary"
          onClick={handleClearFilters}
          variant="contained"
        >
          {t('button.clear')}
        </Button>
      </Box>
    </Grid>
  );
}

export default Filters;
