import api from 'store/api';
import { setErrorCatch } from 'store/status/actions';
import {
  HolidayDaysLimitDefault,
  HolidayDaysLimitDefaultsFilters,
  HolidayDaysLimitDefaultsPatchMutation,
  HolidayDaysLimitDefaultsPostMutation,
  HolidayDaysLimitDefaultsResponseDto,
} from './interfaces';

export const holidayDaysLimitDefaultsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHolidayDaysLimitDefaults: builder.query<
      HolidayDaysLimitDefaultsResponseDto,
      Partial<HolidayDaysLimitDefaultsFilters>
    >({
      query: (params) => ({
        url: '/holiday-days-limit-defaults',
        params,
      }),
      providesTags: ['HolidayDaysLimitDefaults'],
    }),
    addHolidayDaysLimitDefault: builder.mutation<
      HolidayDaysLimitDefault,
      HolidayDaysLimitDefaultsPostMutation
    >({
      query: (body) => ({
        url: '/holiday-days-limit-defaults',
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: createdHolidayDaysLimitDefault } = await queryFulfilled;

          const holidayDaysLimitDefaultsCache = api.util.selectInvalidatedBy(
            getState(),
            [{ type: 'HolidayDaysLimitDefaults' }],
          );

          holidayDaysLimitDefaultsCache
            .filter(
              ({ endpointName }) =>
                endpointName === 'getHolidayDaysLimitDefaults',
            )
            .forEach(({ originalArgs }) => {
              dispatch(
                holidayDaysLimitDefaultsApi.util.updateQueryData(
                  'getHolidayDaysLimitDefaults',
                  originalArgs,
                  (draft) => {
                    draft?.['hydra:member']?.unshift(
                      createdHolidayDaysLimitDefault,
                    );
                  },
                ),
              );
            });
        } catch (error) {
          dispatch(setErrorCatch(error));
        }
      },
    }),
    updateHolidayDaysLimitDefault: builder.mutation<
      HolidayDaysLimitDefault,
      HolidayDaysLimitDefaultsPatchMutation
    >({
      query: ({ id, ...body }) => ({
        url: `/holiday-days-limit-defaults/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updateHolidayDaysLimitDefault } = await queryFulfilled;

          const holidayDaysLimitDefaultsCache = api.util.selectInvalidatedBy(
            getState(),
            [{ type: 'HolidayDaysLimitDefaults' }],
          );

          holidayDaysLimitDefaultsCache
            .filter(
              ({ endpointName }) =>
                endpointName === 'getHolidayDaysLimitDefaults',
            )
            .forEach(({ originalArgs }) => {
              dispatch(
                holidayDaysLimitDefaultsApi.util.updateQueryData(
                  'getHolidayDaysLimitDefaults',
                  originalArgs,
                  (draft) => {
                    const index = draft?.['hydra:member']?.findIndex(
                      (holidayDaysLimitDefault) =>
                        holidayDaysLimitDefault.id === id,
                    );
                    if (index !== -1) {
                      draft?.['hydra:member']?.splice(
                        index,
                        1,
                        updateHolidayDaysLimitDefault,
                      );
                    }
                  },
                ),
              );
            });
        } catch (error) {
          dispatch(setErrorCatch(error));
        }
      },
    }),
    deleteHolidayDaysLimitDefault: builder.mutation({
      query: (id) => ({
        url: `/holiday-days-limit-defaults/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;

          const holidayDaysLimitDefaultsCache = api.util.selectInvalidatedBy(
            getState(),
            [{ type: 'HolidayDaysLimitDefaults' }],
          );

          holidayDaysLimitDefaultsCache
            .filter(
              ({ endpointName }) =>
                endpointName === 'getHolidayDaysLimitDefaults',
            )
            .forEach(({ originalArgs }) => {
              dispatch(
                holidayDaysLimitDefaultsApi.util.updateQueryData(
                  'getHolidayDaysLimitDefaults',
                  originalArgs,
                  (draft) => {
                    const index = draft?.['hydra:member']?.findIndex(
                      (holidayDaysLimitDefault) =>
                        holidayDaysLimitDefault.id === id,
                    );
                    if (index !== -1) {
                      draft?.['hydra:member']?.splice(index, 1);
                    }
                  },
                ),
              );
            });
        } catch (error) {
          dispatch(setErrorCatch(error));
        }
      },
    }),
  }),
});

export const {
  useGetHolidayDaysLimitDefaultsQuery,
  useAddHolidayDaysLimitDefaultMutation,
  useUpdateHolidayDaysLimitDefaultMutation,
  useDeleteHolidayDaysLimitDefaultMutation,
} = holidayDaysLimitDefaultsApi;
