import { ApiResponse } from 'store/interfaces';
import { Tag } from 'store/tags/interfaces';

export enum ResourceRequestStatus {
  draft = 'draft',
  pending = 'pending'
}
export interface ResourceRequestFilters {
  status: ResourceRequestStatus[];
}

export enum ResourceRequestBillingType {
  per_hour = 'per-hour',
  per_day = 'per-day',
  per_week = 'per-week',
  per_month = 'per-month',
}

export interface ResourceRequest {
  '@id': string;
  id: string;
  client?: string;
  status: ResourceRequestStatus;
  location?: string;
  position: string;
  description?: string;
  start?: string;
  tags?: Tag[];
  salaryRangeMin?: number;
  salaryRangeMax?: number;
  billingType?: ResourceRequestBillingType;
  createdAt: Date;
}

export interface ResourceRequestsData extends ApiResponse {
  'hydra:member': ResourceRequest[];
}

export interface ResourceRequestMutationRequest
  extends Omit<
    ResourceRequest,
    '@id' | 'id' | 'tags' | 'createdAt' | 'status'
  > {}
