import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import dateFormat from 'dateformat';
import { ProjectTypes } from 'enums';
import {
  useGetContractorGroupsConditionQuery,
  useGetHolidaysConditionQuery,
  useGetProjectsConditionQuery,
  useGetTaxesQuery,
} from 'store/condition/condition';
import { useTranslation } from 'react-i18next';
import ProjectsCondition from 'components/ProjectsCondition/ProjectsCondition';
import {
  ContractorGroupsCondition as GroupsCondition,
  RangeConditions,
  TaxesCondition as TaxesProps,
} from 'store/condition/interfaces';
import Loader from 'common/Loader';
import Error from 'common/Error';
import HolidaysCondition from 'components/HolidaysCondition/HolidaysCondition';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import TaxesCondition from 'components/TaxesCondition/TaxesCondition';
import ContractorGroupsCondition from 'components/ContractorGroupsCondition/ContractorGroupsCondition';
import {
  useGetConditionSectionsOrderQuery,
  useGetNecessaryCostsQuery,
  useGetRequiredCostsQuery,
  useGetSlightCostsQuery,
  useGetTaxesCostsQuery,
} from 'store/config/config';
import { ConfigResponse, PropertyIds } from 'store/config/interfaces';
import NonIncomeProjectsCondition from 'components/NonIncomeProjectsCondition/NonIncomeProjectsCondition';
import ConditionSections from './enums';

export default function Condition() {
  const { t } = useTranslation();
  const currentDate = new Date();
  const currentMonth = dateFormat(currentDate, 'yyyy-mm');
  const {
    data: taxesCosts = {} as PropertyIds,
    isLoading: taxesCostsFetching,
    isError: taxesCostsFetchError,
  } = useGetTaxesCostsQuery();
  const {
    data: requiredCosts = {} as PropertyIds,
    isLoading: requiredCostsFetching,
    isError: requiredCostsFetchError,
  } = useGetRequiredCostsQuery();
  const {
    data: necessaryCosts = {} as PropertyIds,
    isLoading: necessaryCostsFetching,
    isError: necessaryCostsError,
  } = useGetNecessaryCostsQuery();
  const {
    data: slightCosts = {} as PropertyIds,
    isLoading: slightCostsFetching,
    isError: slightCostsFetchError,
  } = useGetSlightCostsQuery();
  const {
    data: sectionOrder = {} as ConfigResponse<string[]>,
    isLoading: orderFetching,
    isError: orderFetchError,
  } = useGetConditionSectionsOrderQuery();
  const projectTypes = [ProjectTypes.fixedPrice, ProjectTypes.timeMaterial];
  const {
    data: rangeConditions = {} as RangeConditions,
    isLoading: rangeConditionsFetching,
    isError: rangeConditionsFetchError,
  } = useGetProjectsConditionQuery({
    date: currentMonth,
    projectType: projectTypes,
  });
  const {
    isLoading: holidaysConditionFetching,
    isError: holidaysConditionFetchError,
  } = useGetHolidaysConditionQuery(currentMonth);
  const { isLoading: employeesFetching, isError: employeesFetchError } =
    useGetEmployeesPreview({ isActive: true });
  const {
    data: taxes = {} as TaxesProps,
    isLoading: taxesFetching,
    isError: taxesFetchError,
  } = useGetTaxesQuery(
    {
      contractor: taxesCosts.value?.contractors,
      date: currentMonth,
    },
    {
      skip: !taxesCosts?.value,
    },
  );
  const {
    data: requiredGroupsCondition = {} as GroupsCondition,
    isLoading: groupsFetching,
    isError: groupsFetchError,
  } = useGetContractorGroupsConditionQuery(
    {
      month: currentMonth,
      contractorGroup: requiredCosts.value?.contractorGroups,
    },
    {
      skip: !requiredCosts?.value,
    },
  );
  const {
    data: internalProjects = {} as RangeConditions,
    isLoading: internalProjectsFetching,
    isError: internalProjectsFetchError,
  } = useGetProjectsConditionQuery({
    date: currentMonth,
    projectType: [ProjectTypes.internal],
  });
  const {
    data: necessaryGroupsCondition = {} as GroupsCondition,
    isLoading: necessaryGroupsFetching,
    isError: necessaryGroupsFetchError,
  } = useGetContractorGroupsConditionQuery(
    {
      month: currentMonth,
      contractorGroup: necessaryCosts.value?.contractorGroups,
    },
    {
      skip: !necessaryCosts?.value,
    },
  );
  const {
    data: nonBillableProjects = {} as RangeConditions,
    isLoading: nonBillableProjectsFetching,
    isError: nonBillableProjectsFetchError,
  } = useGetProjectsConditionQuery({
    date: currentMonth,
    projectType: [ProjectTypes.nonBillable],
  });
  const {
    data: slightGroupsCondition = {} as GroupsCondition,
    isLoading: slightGroupsFetching,
    isError: slightGroupsFetchError,
  } = useGetContractorGroupsConditionQuery(
    {
      month: currentMonth,
      contractorGroup: slightCosts.value?.contractorGroups,
    },
    {
      skip: !slightCosts?.value,
    },
  );

  const isDataLoading =
    orderFetching ||
    taxesCostsFetching ||
    requiredCostsFetching ||
    necessaryCostsFetching ||
    rangeConditionsFetching ||
    holidaysConditionFetching ||
    employeesFetching ||
    taxesFetching ||
    groupsFetching ||
    internalProjectsFetching ||
    necessaryGroupsFetching ||
    nonBillableProjectsFetching ||
    slightGroupsFetching ||
    slightCostsFetching;
  const isDataFetchError =
    rangeConditionsFetchError ||
    holidaysConditionFetchError ||
    employeesFetchError ||
    taxesFetchError ||
    groupsFetchError ||
    orderFetchError ||
    taxesCostsFetchError ||
    requiredCostsFetchError ||
    internalProjectsFetchError ||
    necessaryCostsError ||
    necessaryGroupsFetchError ||
    nonBillableProjectsFetchError ||
    slightCostsFetchError ||
    slightGroupsFetchError;

  if (isDataLoading) {
    return <Loader />;
  }

  if (isDataFetchError) {
    return <Error />;
  }

  const getDisplayedComponent = (section: string) => {
    switch (section) {
      case ConditionSections.taxes:
        return (
          <TaxesCondition
            key={section}
            order={sectionOrder.value}
            section={section}
            taxes={taxes}
          />
        );
      case ConditionSections.requiredCosts:
        return (
          <ContractorGroupsCondition
            key={section}
            groupsCondition={requiredGroupsCondition}
            order={sectionOrder.value}
            section={section}
            sectionHeader="condition.required_costs"
          />
        );
      case ConditionSections.internalProjects:
        return (
          <NonIncomeProjectsCondition
            key={section}
            header="condition.internal_projects"
            order={sectionOrder.value}
            projectsData={internalProjects}
            section={section}
          />
        );
      case ConditionSections.necessaryCosts:
        return (
          <ContractorGroupsCondition
            key={section}
            groupsCondition={necessaryGroupsCondition}
            order={sectionOrder.value}
            section={section}
            sectionHeader="condition.necessary_costs"
          />
        );
      case ConditionSections.nonBillableProjects:
        return (
          <NonIncomeProjectsCondition
            key={section}
            header="condition.non_billable_projects"
            order={sectionOrder.value}
            projectsData={nonBillableProjects}
            section={section}
          />
        );
      case ConditionSections.slightCosts:
        return (
          <ContractorGroupsCondition
            key={section}
            groupsCondition={slightGroupsCondition}
            order={sectionOrder.value}
            section={section}
            sectionHeader="condition.slight_costs"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('menu.condition')}</NavBarTitle>
      </NavBarContainer>
      <ProjectsCondition rangeData={rangeConditions} />
      <HolidaysCondition />
      {sectionOrder.value.map((item) => getDisplayedComponent(item))}
    </Grid>
  );
}
