import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardMedia,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Link from 'common/Link/Link';
import { useValidate } from 'hooks/useValidate';
import { StatusProps } from 'hoc/Status/Status';
import { useResetPasswordMutation } from 'store/authorization/authorization';
import checkEmail from 'helpers/validators/checkEmail/checkEmail';
import Logo from 'assets/img/logo.svg';
import RouterHelper from 'helpers/RouterHelper';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export interface Errors {
  organization: boolean;
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  password: boolean;
}

export interface PagePasswordResetProps extends StatusProps {}

export default function PasswordReset({ setIsError }: PagePasswordResetProps) {
  const [isFormHidden, setIsFormHidden] = useState(false);
  const [email, setEmail] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();
  const [register, { isSuccess, isError }] = useResetPasswordMutation();

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) || !checkEmail(email);

  useEffect(() => {
    setIsError(isError);
    if (!isSuccess) {
      return;
    }
    setIsFormHidden(true);
  }, [setIsError, isSuccess, isError]);

  const handleRegisterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    register({
      email,
    });
  };

  return (
    <Grid container height="100vh" justifyContent="center">
      <Grid item xs={12} display="flex" justifyContent="center">
        <CardMedia
          component="img"
          loading="lazy"
          src={Logo}
          alt={t('menu.logo')}
          sx={{ width: { xs: '15rem', md: '30rem' }, objectFit: 'contain' }}
        />
      </Grid>
      <Grid item xs={10} md={3}>
        {!isFormHidden && (
          <form onSubmit={handleRegisterSubmit}>
            <FormControl
              fullWidth
              sx={{ display: 'flex', flexDirection: 'column', rowGap: 2.5 }}
            >
              <TextField
                required
                id="e-mail"
                label={t('authorization.email_label')}
                autoComplete="off"
                defaultValue={email}
                error={errors.email}
                helperText={errors.email && t('errors.email_error')}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={() => validate('email', checkEmail(email))}
              />
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={disableSubmission()}
              >
                {t('button.send')}
              </Button>
              <Box display="flex" justifyContent="center">
                <Typography variant="body1">
                  {t('authorization.return_to_login')}
                  <Link
                    to={RouterHelper.generate('route_login')}
                    underline="none"
                    pl={1}
                  >
                    {t('button.sign_in')}
                  </Link>
                </Typography>
              </Box>
            </FormControl>
          </form>
        )}
        {isSuccess && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap={3}
          >
            <TaskAltIcon color="success" sx={{ fontSize: '10rem' }} />
            <Typography variant="h5" align="center">
              {t('authorization.reset_success')}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
