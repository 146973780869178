import { Grid, Stack, Typography } from '@mui/material';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import React from 'react';

interface OverviewTileProps {
  label: string;
  children: React.ReactNode;
  icon: React.ReactNode;
}

export default function OverviewTile({
  label,
  children,
  icon,
}: OverviewTileProps) {
  return (
    <Grid item xs={4}>
      <StyledContentBox p={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="body2" color="text.secondary">
              {label}
            </Typography>
            {children}
          </Stack>
          {icon}
        </Stack>
      </StyledContentBox>
    </Grid>
  );
}
