import { EmployeePreview } from 'store/employee/interfaces';

export interface BudgetTransactionCost {
  type: string;
  description: string;
  execDate: Date;
  amount: string;
  targetAmount: string;
  employee: string;
  contractor: string;
  project: string;
  id: string;
  currency: string;
}
export interface ResultProps {
  transaction: BudgetTransactionCost;
  employees: EmployeePreview[];
}

export enum CostType {
  transaction = 'transaction',
  project = 'project',
  contractor = 'contractor',
}
