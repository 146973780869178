import React from 'react';
import { Box, FormControl } from '@mui/material';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import ControlCheckbox from 'common/ControlCheckbox/ControlCheckbox';

interface StatusesProps<T extends FieldValues> {
  errors: FieldErrors;
  control: Control<T, any, T>;
  costsStatuses: Array<{ value: string; label: string }>;
}

export default function Statuses<T extends FieldValues>({
  errors,
  control,
  costsStatuses,
}: StatusesProps<T>) {
  return (
    <Box display="flex" alignItems="center" gap={3}>
      <FormControl fullWidth required>
        <ControlSelect
          control={control as Control<FieldValues, any, FieldValues>}
          label="label.status"
          name="status"
          errors={errors}
          selectOptions={costsStatuses}
          required
        />
      </FormControl>
      <FormControl fullWidth required>
        <ControlCheckbox
          errors={errors}
          control={control as Control<FieldValues, any, FieldValues>}
          name="cyclic"
          label="costs.cyclic_label"
        />
      </FormControl>
      <FormControl fullWidth>
        <ControlCheckbox
          errors={errors}
          control={control as Control<FieldValues, any, FieldValues>}
          name="fixedAmount"
          label="costs.fixed_amount_label"
        />
      </FormControl>
      <FormControl fullWidth>
        <ControlCheckbox
          errors={errors}
          control={control as Control<FieldValues, any, FieldValues>}
          name="requireAttachment"
          label="costs.require_attachment_label"
        />
      </FormControl>
      <FormControl fullWidth>
        <ControlCheckbox
          errors={errors}
          control={control as Control<FieldValues, any, FieldValues>}
          name="notIncludeInChart"
          label="costs.not_include_in_chart_label"
        />
      </FormControl>
    </Box>
  );
}
