import { createTheme } from '@mui/material';
import colors from 'config/material-ui/colors';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/800.css';

const theme = createTheme({
  palette: {
    primary: {
      light: colors.PRIMARY_LIGHT,
      main: colors.PRIMARY_MAIN,
    },
    secondary: {
      light: colors.GREY_AGATE_300,
      main: colors.GREY_AGATE_800,
      selected: colors.SECONDARY_SELECTED,
    },
    text: {
      primary: colors.TEXT_PRIMARY,
      secondary: colors.TEXT_SECONDARY,
      disabled: colors.TEXT_DISABLED,
    },
    background: {
      list: colors.WHITE,
      other: colors.GREY_50,
      accordion: colors.GREY_100,
      otherBacklight: colors.BG_OTHER_BACKLIGHT,
      otherAvatarFill: colors.PALE_BLUE,
      blueBar: colors.BLUE_500,
      orangeBarLight: colors.ORANGE_300,
      orangeBarMain: colors.ORANGE_500,
      deepPurpleBarLight: colors.DEEP_PURPLE_50,
      deepPurpleBarMain: colors.DEEP_PURPLE_100,
    },
    main: {
      white: colors.WHITE,
      black: colors.BLACK,
    },
    icon: {
      light: colors.GREY_300,
      lightDark: colors.GREY_400,
      main: colors.PRIMARY_MAIN,
      dark: colors.ACTION_ACTIVE,
    },
    border: {
      veryLight: colors.ENABLED_BORDER_VERY_LIGHT,
      light: colors.ENABLED_BORDER_LIGHT,
      main: colors.ENABLED_BORDER_MAIN,
      divider: colors.DIVIDER,
      primaryOutlined: colors.PRIMARY_OUTLINED_BORDER,
      secondaryOutlined: colors.SECONDARY_OUTLINED_BORDER,
      errorOutlined: colors.ERROR_OUTLINED_BORDER,
      warningOutlined: colors.WARNING_OUTLINED_BORDER,
      successOutlined: colors.SUCCESS_OUTLINED_BORDER,
      infoOutlined: colors.INFO_OUTLINED_BORDER,
    },
    status: {
      active: {
        light: colors.FLORIDA_KEYS_200,
        main: colors.FLORIDA_KEYS_800,
      },
      finishing: {
        light: colors.ZUCCHINI_FLOWER_300,
        main: colors.ZUCCHINI_FLOWER_1200,
      },
      missingFile: {
        light: colors.KHMER_CURRY_200,
        main: colors.KHMER_CURRY_900,
      },
      notStarted: {
        light: colors.VENETIAN_NIGHTS_200,
        main: colors.VENETIAN_NIGHTS_800,
      },
      notActive: {
        light: colors.WHITE,
        main: colors.GREY_AGATE_800,
      },
      freshlyFinished: {
        light: colors.ENABLED_BORDER_LIGHT,
        main: colors.GREY_AGATE_900,
      },
    },
    info: {
      main: colors.INFO_MAIN,
      selected: colors.INFO_SELECTED,
    },
    rating: {
      filled: colors.RATING_ACTIVE_FILL,
      empty: colors.GREY_200,
    },
    error: {
      main: colors.ERROR_MAIN,
    },
    success: {
      main: colors.SUCCESS_MAIN,
    },
    warning: {
      main: colors.WARNING_MAIN,
      light: colors.WARNING_LIGHT,
      selected: colors.WARNING_SELECTED,
    },
    other: {
      resizeIndicator: colors.RESIZE_INDICATOR,
    },
  },
  typography: {
    fontFamily: 'Plus Jakarta Sans',
    h4: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '3.4rem',
      lineHeight: '4.2rem',
      letterSpacing: '0.025rem',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '2.4rem',
      lineHeight: '3.2rem',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '2rem',
      lineHeight: '3.2rem',
      letterSpacing: '0.015rem',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.8rem',
      lineHeight: '2.7rem',
      letterSpacing: '0.015rem',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.4rem',
      lineHeight: '2.2rem',
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.4rem',
      lineHeight: '2.002rem',
      letterSpacing: '0.017rem',
    },
    bold: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.4rem',
      lineHeight: '2rem',
    },
    button: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.4rem',
      lineHeight: '2rem',
    },
    buttonSmall: {
      fontSize: '1.3rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '2.2rem',
      letterSpacing: '0.046rem',
    },
    buttonMedium: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
    },
    buttonLarge: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '1.5rem',
      lineHeight: '2.6rem',
      letterSpacing: '0.046rem',
    },
    inputValue: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      letterSpacing: '0.015rem',
    },
    chipLabel: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '1.3rem',
      letterSpacing: '0.016rem',
    },
    inputLabel: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '1.2rem',
      lineHeight: '1.2rem',
      letterSpacing: '0.015rem',
    },
    smallBody: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '1.1rem',
      lineHeight: '1.4rem',
    },
    tableHeader: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.2rem',
      lineHeight: '2.4rem',
      letterSpacing: '0.017rem',
    },
    caption: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.2rem',
      lineHeight: '1.992rem',
      letterSpacing: '0.04rem',
    },
    menuItemDefault: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '2.4rem',
      letterSpacing: '0.015rem',
    },
    inputHelper: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '1.2rem',
      lineHeight: '1.992rem',
      letterSpacing: '0.04rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      body {
        font-family: 'Plus Jakarta Sans', sans-serif;
      }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '4rem',
          borderRadius: '1.2rem',
          textTransform: 'none',
          letterSpacing: '0.04rem',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `0.1rem solid ${colors.PRIMARY_OUTLINED_BORDER}`,
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          height: '4rem',
          borderRadius: '1.2rem',
          textTransform: 'none',
          letterSpacing: '0.04rem',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `0.1rem solid ${colors.PRIMARY_OUTLINED_BORDER}`,
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '4.8rem',
          borderRadius: '1.2rem',
        },
        notchedOutline: {
          borderColor: colors.ENABLED_BORDER_MAIN,
        },
        input: {
          height: '1.44rem',
        },
        multiline: {
          height: 'initial',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          color: colors.GREY_AGATE_900,
          top: '-0.4rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          color: colors.GREY_AGATE_900,
          '&:hover': {
            backgroundColor: colors.PRIMARY_LIGHT,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          minHeight: '8rem',
          justifyContent: 'center',
          backgroundColor: colors.WHITE,
          boxShadow: 'none',
          borderBottom: `0.1rem solid ${colors.ENABLED_BORDER_LIGHT}`,
          color: 'inherit',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: 'transparent',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          background: colors.WHITE,
        },
        head: {
          borderBottom: 'none',
          background: colors.GREY_50,
          paddingX: '1.6rem',
          paddingY: '1.2rem',
          '&:first-child': {
            borderTopLeftRadius: '1.2rem',
            borderBottomLeftRadius: '1.2rem',
          },
          '&:last-child': {
            borderTopRightRadius: '1.2rem',
            borderBottomRightRadius: '1.2rem',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '1.2rem',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.AVATAR_FILL,
          color: colors.PRIMARY_MAIN,
          fontWeight: '800',
        },
        rounded: {
          borderRadius: '0.8rem',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        textColor: 'primary',
      },
      styleOverrides: {
        indicator: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          height: '0.3rem',
          '& span': {
            width: 'calc(100% - 2.4rem)',
            backgroundColor: colors.PRIMARY_MAIN,
            borderRadius: '0.4rem 0.4rem 0.05rem 0.05rem',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: '6.4rem',
          lineHeight: '6.4rem',
          fontSize: '1.5rem',
          fontStyle: 'normal',
          textTransform: 'none',
          fontWeight: '600',
          letterSpacing: '0.046rem',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '40rem',
            },
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          gap: '0.4rem',
          '.MuiStepConnector-root': {
            top: 0,
          },
          '.MuiStepConnector-root span': {
            borderColor: 'transparent',
            minHeight: 0,
          },
        },
      },
      defaultProps: {
        orientation: 'vertical',
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.MuiStepLabel-alternativeLabel': {
            flexDirection: 'row',
            gap: '1.2rem',
          },
          '&.MuiStepLabel-alternativeLabel .MuiStepLabel-label': {
            marginTop: 0,
            textAlign: 'left',
          },
          '& circle': {
            color: 'transparent',
          },
          '& text': {
            fill: colors.TEXT_SECONDARY,
          },
          '& svg': {
            fontSize: '2.8rem',
            border: `0.1rem solid ${colors.DIVIDER}`,
            borderRadius: '50%',
          },
          '& .Mui-active': {
            '& circle': {
              color: colors.PRIMARY_MAIN,
            },
            '& text': {
              fill: colors.WHITE,
            },
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: '0.6rem 1.6rem',
          borderRadius: '1.2rem',
          width: '100%',
        },
      },
      variants: [
        {
          props: { active: true },
          style: { backgroundColor: colors.PRIMARY_LIGHT },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: '4rem',
          height: '4rem',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '2.4rem',
          letterSpacing: '0.015rem',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '0.8rem',
          borderRadius: '0.4rem',
        },
        bar: {
          borderRadius: '0.4rem',
        },
      },
    },
  },
});

export default theme;
