import React, { useState } from 'react';
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Attachment from 'components/AttachmentsList/Attachment';
import { TransactionAttachment } from 'store/transactions/transactions';

export interface DailyAttachmentsProps {
  attachmentsGroupedByDay: TransactionAttachment[];
  day: string;
  headersLength: number;
}

function DailyAttachments({
  attachmentsGroupedByDay,
  day,
  headersLength,
}: DailyAttachmentsProps) {
  const [isAttachmentsVisible, setIsAttachmentsVisible] = useState(true);
  const shouldRenderAttachments =
    !!attachmentsGroupedByDay.length && isAttachmentsVisible;

  return (
    <>
      <TableRow>
        <TableCell
          colSpan={headersLength + 1}
          sx={{ padding: 'calc(8em / 3) calc(4em / 3)' }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              onClick={() => setIsAttachmentsVisible((prevState) => !prevState)}
            >
              {isAttachmentsVisible ? (
                <RemoveRedEyeOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </IconButton>
            <Typography variant="h6">{day}</Typography>
          </Stack>
        </TableCell>
      </TableRow>
      {shouldRenderAttachments &&
        attachmentsGroupedByDay.map((attachment) => (
          <Attachment key={attachment.id} attachment={attachment} />
        ))}
    </>
  );
}

export default DailyAttachments;
