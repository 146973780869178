import React from 'react';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

interface ContentSuspenseProps {
  isLoading: boolean;
  isError: boolean;
  children: React.ReactNode;
  loader?: React.ReactNode;
}

export default function ContentSuspense({
  isLoading,
  isError,
  children,
  loader = <Loader />,
}: ContentSuspenseProps) {
  if (isLoading) {
    return loader as EmotionJSX.Element;
  }

  if (isError) {
    return <Error />;
  }
  return <>{children}</>;
}
