import { Grid } from '@mui/material';
import React from 'react';
import PersonalInformationsForm from './PersonalInformationsForm';
import ResumeForm from './ResumeForm';
import CandidateNotes from './CandidateNotes';
import Skills from './Skills/Skills';
import ActiveRecruitments from './ActiveRecruitments/ActiveRecruitments';

export default function CandidateOverview() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={7.5}>
        <PersonalInformationsForm />
        <CandidateNotes />
        <Skills />
      </Grid>
      <Grid item xs={4.5}>
        <ResumeForm />
        <ActiveRecruitments />
      </Grid>
    </Grid>
  );
}
