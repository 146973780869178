import { Chip, Typography } from '@mui/material';
import React from 'react';
import { InvoiceStatus } from 'store/incomes/interfaces';

interface StatusChipProps {
  status: string;
}

export default function StatusChip({ status }: StatusChipProps) {
  const getChipColors = () => {
    switch (status) {
      case InvoiceStatus.draft:
        return {
          backgroundColor: 'background.list',
          border: 'border.main',
          labelColor: 'text.primary',
        };
      case InvoiceStatus.edited:
        return {
          backgroundColor: 'primary.main',
          border: 'border.primaryOutlined',
          labelColor: 'main.white',
        };
      case InvoiceStatus.sent:
        return {
          backgroundColor: 'background.other',
          border: 'border.divider',
          labelColor: 'text.secondary',
        };
      default:
        return {
          backgroundColor: 'background.list',
          border: 'main.white',
          labelColor: 'text.primary',
        };
    }
  };

  const chipColors = getChipColors();

  return (
    <Chip
      label={
        <Typography color={chipColors.labelColor} variant="chipLabel">
          {status}
        </Typography>
      }
      sx={{
        bgcolor: chipColors.backgroundColor,
        border: '0.1rem solid',
        borderColor: chipColors.border,
      }}
    />
  );
}
