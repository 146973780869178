import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Stack, Typography } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import useSubscriptionContext from 'pages/Subscription/context/useSubscriptionContext';
import UserCountSlider from './UserCountSlider/UserCountSlider';
import PriceSummary from './PriceSummary/PriceSummary';
import features from '../ModulesFeatures/features';

export default function ModulesSummary() {
  const { t } = useTranslation();
  const { userCount, selectedFeatures, handleNextStep } =
    useSubscriptionContext();
  const formatAmount = useCurrencyFormat();

  const pricePerUser = selectedFeatures.reduce((acc, feature) => {
    const featurePrice =
      features.find((featureItem) => featureItem.name === feature)
        ?.pricePerUser || 0;
    return acc + featurePrice;
  }, 0);

  const additionalPrice = selectedFeatures.reduce((acc, feature) => {
    const featurePrice =
      features.find((featureItem) => featureItem.name === feature)?.price || 0;
    return acc + featurePrice;
  }, 0);

  const totalPrice = pricePerUser * userCount + additionalPrice;

  return (
    <Stack p={5} spacing={5} width="25%" minWidth="41.2rem">
      <Typography variant="h5">{t('label.summary')}</Typography>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Typography>{t('label.users_count')}</Typography>
          <Typography fontWeight={700}>{userCount}</Typography>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          {t('subscription.users_count_description')}
        </Typography>
        <UserCountSlider />
      </Stack>
      <Divider sx={{ pt: 2.5 }} />
      <Stack spacing={3}>
        <PriceSummary
          amount={pricePerUser}
          label="subscription.price_per_user"
        />
        <PriceSummary amount={additionalPrice} label="label.additional_costs" />
        <Stack
          justifyContent="space-between"
          alignContent="center"
          width="100%"
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="h6" color="primary.main" fontWeight={600}>
              {t('label.total')}
            </Typography>
            <Typography variant="h6">{formatAmount(0)}</Typography>
          </Stack>
          {!!totalPrice && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant="caption" color="text.secondary">
                {t('label.monthly')}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                <del>{formatAmount(totalPrice)}</del>
              </Typography>
            </Stack>
          )}
        </Stack>
        <Typography variant="body2" color="primary.main">
          {t('subscription.free_trial')}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        fullWidth
        onClick={handleNextStep}
        disabled={!selectedFeatures.length || !userCount}
      >
        {t('label.go_further')}
      </Button>
    </Stack>
  );
}
