import { t } from 'i18next';
import holidaysSettings from 'helpers/holidaysSettings';

export default function getEmployeeHolidayTimeLeft(hours: number) {
  const isHolidaysWholeDays = hours % holidaysSettings.workingHoursInDay === 0;

  const isHolidaysOnlyHours =
    Math.abs(hours) < holidaysSettings.workingHoursInDay;

  if (isHolidaysWholeDays) {
    return t('holidays.some_days_duration', {
      days: hours / holidaysSettings.workingHoursInDay,
    });
  }

  if (isHolidaysOnlyHours) {
    return t('holidays.some_hours_duration', {
      hours,
    });
  }

  return t('holidays.duration_days_hours', {
    days: Math.floor(hours / holidaysSettings.workingHoursInDay),
    hours: hours % holidaysSettings.workingHoursInDay,
  });
}
