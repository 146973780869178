import api from 'store/api';
import { Bank } from 'store/bank/bank';
import { ApiResponse } from 'store/interfaces';
import { Transaction, TransactionsData } from 'store/transactions/transactions';

export interface BankAccount {
  ['@id']: string;
  id: string;
  name: string;
  type: string;
  number: string;
  currency: string;
  prefix: string;
  default: boolean;
  bank: Bank;
  defaultImportFormat: string;
}

export interface EditBankAccountBody extends Omit<BankAccount, 'bank'> {
  bank: string;
}

export interface BankAccountsResponseDto extends ApiResponse {
  'hydra:member': BankAccount[];
}

export const accountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query<BankAccount[], void>({
      query: () => '/bank-accounts',
      providesTags: ['Accounts'],
      transformResponse: (response: BankAccountsResponseDto) =>
        response['hydra:member'].sort((account, comparedAccount) => {
          const sortedByBankName = account.bank.name.localeCompare(
            comparedAccount.bank.name,
          );
          if (sortedByBankName !== 0) {
            return sortedByBankName;
          }
          const sortedByIsDefault =
            Number(comparedAccount.default) - Number(account.default);
          if (sortedByIsDefault !== 0) {
            return sortedByIsDefault;
          }
          return account.name.localeCompare(comparedAccount.name);
        }),
    }),
    getAccount: builder.query<BankAccount, string>({
      query: (id) => `/bank-accounts/${id}`,
      providesTags: ['Accounts'],
    }),
    addAccount: builder.mutation({
      query: (body) => ({
        url: '/bank-accounts',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Accounts'],
    }),
    updateAccount: builder.mutation<
      EditBankAccountBody,
      Partial<EditBankAccountBody> & Pick<EditBankAccountBody, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/bank-accounts/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Accounts'],
    }),
    getAccountTransactions: builder.query<Transaction[], number>({
      query: (id) => ({
        url: `/bank-accounts/${id}/transactions`,
      }),
      providesTags: ['Transactions'],
      transformResponse: (response: TransactionsData) =>
        response['hydra:member'].sort((transaction, comparedTransaction) => {
          const sortedByOrderDate =
            new Date(comparedTransaction.orderDate).getTime() -
            new Date(transaction.orderDate).getTime();
          const sortedById = comparedTransaction.id - transaction.id;
          return sortedByOrderDate !== 0 ? sortedByOrderDate : sortedById;
        }),
    }),
  }),
});

export const {
  useGetAccountsQuery,
  useAddAccountMutation,
  useGetAccountQuery,
  useUpdateAccountMutation,
  useGetAccountTransactionsQuery,
} = accountsApi;
