import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import HolidayTypesList from 'components/HolidayTypesList/HolidayTypesList';
import { AddCircleOutline } from '@mui/icons-material';
import TypeForm from 'components/HolidayTypesList/TypeForm/TypeForm';
import { HolidayTypeStatus } from 'store/holidayTypes/interfaces';
import { TypeFormPayload } from 'components/HolidayTypesList/TypeForm/interfaces';
import { usePatchHolidayTypeMutation } from 'store/holidayTypes/holidayTypes';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';
import { FormTypes } from 'enums';

export default function HolidayTypes() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [addHolidayType, { isLoading: addHolidayTypeLoading }] =
    usePatchHolidayTypeMutation();
  const dispatch = useAppDispatch();

  const handleAddHoliday = async (formPayload: TypeFormPayload) => {
    try {
      await addHolidayType(formPayload).unwrap();
      setIsOpen(false);
      dispatch(setSuccessStatus(true));
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  const initFormValues = {
    name: '',
    id: '',
    status: HolidayTypeStatus.active,
  } as TypeFormPayload;

  return (
    <Grid item xs={10} bgcolor="background.main">
      <NavBarContainer>
        <NavBarTitle>{t('settings.holiday_types')}</NavBarTitle>
        <Button
          endIcon={<AddCircleOutline />}
          onClick={() => setIsOpen(true)}
          variant="contained"
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <HolidayTypesList />
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
        <TypeForm
          initFormValues={initFormValues}
          isSubmitting={addHolidayTypeLoading}
          onSubmit={handleAddHoliday}
          setIsOpen={setIsOpen}
          formType={FormTypes.add}
        />
      </Dialog>
    </Grid>
  );
}
