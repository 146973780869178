import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BankAccount } from 'store/accounts/accounts';

interface PaymentInfoProps {
  bankAccount: BankAccount;
}

export default function PaymentInfo({ bankAccount }: PaymentInfoProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={3}>
      <Stack>
        <Typography color="text.secondary" variant="body1">
          {t('invoices.payment_by_transfer')}
        </Typography>
        <Typography fontWeight={700} variant="body1">
          {bankAccount.number}
        </Typography>
      </Stack>
      <Stack>
        <Typography
          color="text.secondary"
          variant="body2"
          textTransform="uppercase"
        >
          {t('label.bank')}:&nbsp;
          <Typography
            color="text.primary"
            component="span"
            fontWeight={700}
            variant="body2"
            textTransform="none"
          >
            {bankAccount.bank.name}
          </Typography>
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          textTransform="uppercase"
        >
          {t('banks.swift_label')}:&nbsp;
          <Typography
            color="text.primary"
            component="span"
            fontWeight={700}
            variant="body2"
            textTransform="none"
          >
            {bankAccount.bank.swift}
          </Typography>
        </Typography>
      </Stack>
    </Stack>
  );
}
