const countrySelectOptions = [
  {
    id: 'pl',
  },
  {
    id: 'gb',
  },
  {
    id: 'fr',
  },
  {
    id: 'de',
  },
  {
    id: 'jp',
  },
  {
    id: 'ae',
  },
  {
    id: 'be',
  },
  {
    id: 'dk',
  },
  {
    id: 'ee',
  },
  {
    id: 'es',
  },
  {
    id: 'nl',
  },
  {
    id: 'se',
  },
  {
    id: 'us',
  },
];

export default countrySelectOptions;
