import React from 'react';
import UnauthorizedTemplate from 'common/UnauthorizedTemplate/UnauthorizedTemplate';
import LoginForm from 'components/LoginForm';

export interface Errors {
  username: boolean;
  password: boolean;
}

export default function Authorize() {
  return (
    <UnauthorizedTemplate
      headerTextKey="label.get_started"
      headerLinkTo="/register"
      headerLinkKey="label.sign_up"
      form={<LoginForm />}
    />
  );
}
