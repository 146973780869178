import api from 'store/api';
import { Benefit, BenefitPrice, BenefitsResponseDto } from './interfaces';

export const benefitsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBenefits: builder.query<Benefit[], void>({
      query: () => '/benefits',

      providesTags: ['Benefits'],
      transformResponse: (benefits: BenefitsResponseDto) => {
        const sortedByName = benefits['hydra:member'].sort(
          (benefit, comparedBenefit) =>
            benefit.name.localeCompare(comparedBenefit.name),
        );
        const sortedByDate = sortedByName.map((benefit) => ({
          ...benefit,
          benefitPrices: benefit.benefitPrices.sort(
            (price, comparedPrice) =>
              new Date(comparedPrice.validFrom).getTime() -
              new Date(price.validFrom).getTime(),
          ),
        }));
        return sortedByDate;
      },
    }),
    addBenefit: builder.mutation({
      query: (body) => ({
        url: '/benefits',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Benefits'],
    }),
    updateBenefit: builder.mutation<
      Benefit,
      Partial<Benefit> & Pick<Benefit, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/benefits/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Benefits'],
    }),
    addBenefitPricing: builder.mutation({
      query: (body) => ({
        url: '/benefit-prices',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Benefits'],
    }),
    deleteBenefitPricing: builder.mutation({
      query: (id: number) => ({
        url: `/benefit-prices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Benefits'],
    }),
    updateBenefitPricing: builder.mutation<
      BenefitPrice,
      Partial<BenefitPrice> & Pick<BenefitPrice, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/benefit-prices/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Benefits'],
    }),
  }),
});

export const {
  useGetBenefitsQuery,
  useAddBenefitMutation,
  useUpdateBenefitMutation,
  useAddBenefitPricingMutation,
  useDeleteBenefitPricingMutation,
  useUpdateBenefitPricingMutation,
} = benefitsApi;
