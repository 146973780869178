import React, { useEffect, useState } from 'react';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import {
  Box,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'hooks/useValidate';
import CheckIcon from '@mui/icons-material/Check';
import entities from 'components/AddDocumentTypeForm/entities';
import {
  useGetDocumentTypeQuery,
  DocumentType,
  useUpdateDocumentTypeMutation,
} from 'store/documentTypes/documentTypes';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { StatusProps } from 'hoc/Status/Status';

export interface EditDocumentTypeFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  docTypeId: number;
}

interface Errors {
  documentName: boolean;
  entity: boolean;
}

function EditDocumentTypeForm({
  setIsOpen,
  docTypeId,
  setIsError,
  setIsSuccess,
  setErrorMessage,
}: EditDocumentTypeFormProps) {
  const { t } = useTranslation();
  const [documentName, setDocumentName] = useState('');
  const [entity, setEntity] = useState('');
  const [description, setDescription] = useState('');
  const { validate, errors } = useValidate<Errors>();
  const {
    data: documentType = {} as DocumentType,
    isLoading: docTypeFetching,
    isError: docTypeFetchError,
    isSuccess: docTypeFetched,
  } = useGetDocumentTypeQuery(docTypeId);
  const [
    updateDocType,
    {
      isError: updateError,
      isSuccess: updatedSuccessfully,
      error: updatingError,
    },
  ] = useUpdateDocumentTypeMutation();

  useEffect(() => {
    if (!docTypeFetched) {
      return;
    }
    setDocumentName(documentType.name);
    setEntity(documentType.relationName);
    setDescription(documentType.description ?? '');
  }, [docTypeFetched, documentType]);
  const disableSubmission = () =>
    !documentName || !entity || Object.values(errors).some((error) => error);

  const handleEditDocumentType = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateDocType({
      id: docTypeId,
      name: documentName,
      relationName: entity,
      description,
    });
  };

  useEffect(() => {
    setIsSuccess(updatedSuccessfully);
    setIsError(updateError);
    if (!updatedSuccessfully) {
      return;
    }
    setIsOpen(false);
  }, [setIsSuccess, updatedSuccessfully, setIsError, updateError, setIsOpen]);

  useEffect(() => {
    if (!updatingError) {
      return;
    }
    if (!('data' in updatingError)) {
      return;
    }
    setErrorMessage(updatingError.data['hydra:description']);
  }, [updatingError, setErrorMessage]);

  return (
    <>
      <DialogHeader title={t('documents.edit')} setIsOpen={setIsOpen} />
      <DialogContent>
        {docTypeFetchError && <Error />}
        {docTypeFetching && <Loader />}
        {docTypeFetched && (
          <form onSubmit={handleEditDocumentType}>
            <Box display="flex" flexDirection="column" gap={3} py={3}>
              <TextField
                error={errors.documentName}
                helperText={errors.documentName && t('errors.too_short_name')}
                id="document-name"
                fullWidth
                label={t('label.name')}
                name="documentName"
                onChange={(event) => setDocumentName(event.target.value)}
                onBlur={(event) =>
                  validate('documentName', event.target.value.length >= 2)
                }
                required
                value={documentName}
              />
              <FormControl disabled fullWidth required>
                <InputLabel id="entity-select">
                  {t('documents.label_entity')}
                </InputLabel>
                <Select
                  id="entity-select"
                  label={t('documents.label_entity')}
                  onChange={(event) => setEntity(event.target.value)}
                  value={entity}
                >
                  {entities.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {t(option.description)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="description"
                fullWidth
                label={t('documents.label_description')}
                name="description"
                onChange={(event) => setDescription(event.target.value)}
                value={description}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button
                disabled={disableSubmission()}
                endIcon={<CheckIcon />}
                type="submit"
                variant="contained"
              >
                {t('button.save')}
              </Button>
            </Box>
          </form>
        )}
      </DialogContent>
    </>
  );
}

export default EditDocumentTypeForm;
