import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  useGetCompanyDefaultCurrencyQuery,
  useUpdateCompanyDefaultCurrencyMutation,
} from 'store/config/config';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import { ConfigResponse } from 'store/config/interfaces';
import { CustomError } from 'store/api';
import Error from 'common/Error';
import { CompanyDefaultCurrencyProps } from './interfaces';

function CompanyDefaultCurrency({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: CompanyDefaultCurrencyProps) {
  const { t } = useTranslation();
  const {
    data: companyDefaultCurrency = {} as ConfigResponse<string>,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
  } = useGetCompanyDefaultCurrencyQuery();
  const { data: currencies = [] } = useGetCurrenciesQuery();

  const [updateCompanyDefaultCurrency, { isLoading: isMutationLoading }] =
    useUpdateCompanyDefaultCurrencyMutation();

  const [selectedDefaultCurrency, setSelectedDefaultCurrency] = useState('');
  const isDefaultCurrencyChanged =
    companyDefaultCurrency.value !== selectedDefaultCurrency;

  const handleUpdateCompanyDefaultCurrency = async () => {
    try {
      await updateCompanyDefaultCurrency({
        value: selectedDefaultCurrency,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  useEffect(() => {
    if (isQuerySuccess) {
      setSelectedDefaultCurrency(companyDefaultCurrency.value);
    }
  }, [companyDefaultCurrency, isQuerySuccess, setSelectedDefaultCurrency]);

  if (isQueryError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.company_default_currency')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="currency-label">
                {t('bank_accounts.currency_label')}
              </InputLabel>
              {!!currencies.length && (
                <Select
                  labelId="currency-label"
                  id="currency"
                  value={selectedDefaultCurrency}
                  label={t('bank_accounts.currency_label')}
                  onChange={(event) =>
                    setSelectedDefaultCurrency(event.target.value)
                  }
                >
                  {/* company default currency is in id format */}
                  {currencies.map((currency) => (
                    <MenuItem key={currency.id} value={currency.id}>
                      {currency.id}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          {isDefaultCurrencyChanged && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isMutationLoading}
                onClick={handleUpdateCompanyDefaultCurrency}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default CompanyDefaultCurrency;
