import React, { useEffect, useState } from 'react';
import {
  Button,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useActivateUserMutation } from 'store/register/register';
import Logo from 'assets/img/logo.svg';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function ActivateUser() {
  const [isActivationLoading, setIsActivationLoading] = useState(false);
  const { token } = useParams<{ token: string }>();
  const { t } = useTranslation();
  const [
    activateUser,
    { isSuccess: activeUserSuccess, isError: activateUserError },
  ] = useActivateUserMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      return;
    }
    const handleActivateUser = async () => {
      setIsActivationLoading(true);
      await activateUser({ token });
      setTimeout(() => {
        setIsActivationLoading(false);
      }, 3000);
    };

    handleActivateUser();
  }, [token, activateUser]);

  return (
    <Grid container height="100vh" justifyContent="center">
      <Grid item xs={12} display="flex" justifyContent="center">
        <CardMedia
          component="img"
          loading="lazy"
          src={Logo}
          alt={t('menu.logo')}
          sx={{ width: '30rem', objectFit: 'contain' }}
        />
      </Grid>
      <Grid
        item
        xs={10}
        md={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap={3}
      >
        {isActivationLoading && (
          <>
            <CircularProgress size="10rem" />
            <Typography variant="h5">
              {t('authorization.activation_processing')}
            </Typography>
          </>
        )}
        {activeUserSuccess && !isActivationLoading && (
          <>
            <TaskAltIcon color="success" sx={{ fontSize: '10rem' }} />
            <Typography variant="h5">
              {t('authorization.activation_success')}
            </Typography>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={() => navigate('/login')}
            >
              {t('button.go_to_login_page')}
            </Button>
          </>
        )}
        {activateUserError && !isActivationLoading && (
          <>
            <HighlightOffIcon color="error" sx={{ fontSize: '10rem' }} />
            <Typography variant="h5">
              {t('authorization.activation_failed')}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}
