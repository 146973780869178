import React, { useState } from 'react';
import {
  Typography,
  AccordionDetails,
  Box,
  Grid,
  Chip,
  Button,
} from '@mui/material';
import { Budget } from 'store/budget/interfaces';
import { StyledSummary } from 'components/ResponsibilityGroup/Accordion.styled';
import { ArrowForwardIos } from '@mui/icons-material';
import BudgetTile from 'components/BudgetList/BudgetTile/BudgetTile';
import { EmployeePreview } from 'store/employee/interfaces';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { StyledBudgetGroupAccordion } from './BudgetGroupAccordion.styled';

interface Group {
  name: string;
  id: string;
  '@id': string;
}

interface BudgetGroupAccordionProps {
  budgets: Budget[];
  employees: EmployeePreview[];
  setIsOpenBudget: (groupId: string, isOpen: boolean) => void;
  group: Group;
}

export default function BudgetGroupAccordion({
  budgets = [],
  employees,
  group,
  setIsOpenBudget,
}: BudgetGroupAccordionProps) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  return (
    <StyledBudgetGroupAccordion expanded={expanded} disableGutters square>
      <StyledSummary
        expandIcon={<ArrowForwardIos />}
        onClick={() => setExpanded((prevState) => !prevState)}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          gap={1}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h5">{group?.name}</Typography>
            <Chip
              color="default"
              size="medium"
              variant="outlined"
              label={
                <Typography variant="chipLabel">{budgets?.length}</Typography>
              }
            />
          </Box>
          <Button
            variant="contained"
            color="inherit"
            size="medium"
            endIcon={<AddCircleOutlineIcon />}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenBudget(group['@id'], true);
            }}
          >
            {t('budget.add_new_budget')}
          </Button>
        </Box>
      </StyledSummary>
      <AccordionDetails
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Grid container spacing={2}>
          {budgets &&
            budgets.map((budget) => (
              <Grid item xs={12} md={3} key={budget.id}>
                <BudgetTile budget={budget} employees={employees} />
              </Grid>
            ))}
        </Grid>
      </AccordionDetails>
    </StyledBudgetGroupAccordion>
  );
}
