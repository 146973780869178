import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Button,
  Stack,
  Dialog,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import AddProjectPhase from 'components/ProjectPhaseForm/AddProjectPhase';
import CalendarPlusIcon from 'assets/icons/CalendarPlusIcon';
import tableHeaders from './tableHeaders';
import Phase from './Phase';
import useEditProjectFormContext from '../context/useEditProjectFormContext';

export default function ProjectPhasesList() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { t } = useTranslation();
  const { project } = useEditProjectFormContext();
  const { projectPhases } = project;

  return (
    <>
      <Grid item xs={12}>
        <Stack alignItems="flex-end" pb={3}>
          <Button
            variant="contained"
            endIcon={<CalendarPlusIcon />}
            onClick={() => setIsAddModalOpen(true)}
          >
            <Typography variant="buttonMedium">
              {t('projects.add_project_phase')}
            </Typography>
          </Button>
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell
                    key={header.label}
                    align={header.align}
                    width={header.width}
                  >
                    <Typography variant="tableHeader" color="text.secondary">
                      {t(header.label)}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell colSpan={tableHeaders.length} />
              </TableRow>
            </TableHead>
            <TableBodyContent
              isError={false}
              isFetching={false}
              tableHeaders={tableHeaders}
            >
              {projectPhases.map((projectPhase) => (
                <Phase key={projectPhase.id} projectPhase={projectPhase} />
              ))}
            </TableBodyContent>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog
        fullWidth
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      >
        <AddProjectPhase
          setIsOpen={setIsAddModalOpen}
          projectIri={project['@id']}
        />
      </Dialog>
    </>
  );
}
