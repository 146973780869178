import { Holiday, HolidayRequest } from 'store/holidays/interfaces';

export interface EmployeeHolidaysListProps {
  holidays: Holiday[];
  holidayRequests: HolidayRequest[];
  dataFetchError: boolean;
  dataFetching: boolean;
}

export enum HolidayRowType {
  holiday = 'holiday',
  holidayRequest = 'holidayRequest',
}
