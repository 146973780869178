import { BudgetDetailsType } from 'components/BudgetDetails/interfaces';

export default function getBudgetDetailsTypography(type: 'tile'| 'preview') {
  switch (type) {
    case BudgetDetailsType.tile:
      return {
        circleSize: 62,
        costsVariant: 'h5',
        budgetVariant: 'body1'
      }
    case BudgetDetailsType.preview:
      return {
        circleSize: 110,
        costsVariant: 'h4',
        budgetVariant: 'h6'
      }
    default:
      return {
        circleSize: 62,
        costsVariant: 'h5',
        budgetVariant: 'body1'
      }
  }
}
