import { RangeConditions } from 'store/condition/interfaces';

export const calculateCostsSum = (
  data: RangeConditions,
  month: string,
  projectId: number,
) => {
  let sum = 0;

  if (!data[month] || !data[month][projectId]) {
    return sum;
  }

  Object.keys(data[month][projectId]).forEach((employee) => {
    sum += data[month][projectId][+employee].cost;
  });

  return sum;
};

export const getMonthlyCostsBalance = (
  month: string,
  data: RangeConditions,
  projects: string[],
) => {
  const convertedProjects = projects.map((project) => +project);
  let sum = 0;
  convertedProjects.forEach((project) => {
    sum += calculateCostsSum(data, month, project);
  });

  return sum;
};
