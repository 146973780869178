import { Invoice } from 'store/incomes/interfaces';
import dateFormat from 'dateformat';
import { MonthlySum } from './MonthlyAmounts/MonthlyAmounts';

const getMonthlyAmountSum = (invoices: Invoice[]) =>
  invoices.reduce((acc: MonthlySum[], income) => {
    const month = dateFormat(new Date(income.saleDate), 'yyyy-mm');
    const monthTotalIndex = acc.findIndex((item) => item.month === month);

    let rowsNetSum = 0;

    if (income.amountNet !== 0) {
      rowsNetSum = +income.amountNet;
    } else {
      // backward compatibility
      income.incomeRows.forEach((row) => {
        rowsNetSum += +row.netPrice * +row.quantity;
      });
    }

    rowsNetSum *= income.conversionRate;

    if (monthTotalIndex === -1) {
      acc.push({
        month,
        total: income.targetAmount,
        netValue: rowsNetSum,
        vat: income.targetAmount - rowsNetSum,
      });
    } else {
      acc[monthTotalIndex].total += income.targetAmount;
      acc[monthTotalIndex].netValue += rowsNetSum;
      acc[monthTotalIndex].vat += income.targetAmount - rowsNetSum;
    }
    return acc;
  }, []);

export default getMonthlyAmountSum;
