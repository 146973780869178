import React, { useState } from 'react';
import {
  useEditHolidayDaysLimitMutation,
  useDeleteHolidayDaysLimitMutation,
} from 'store/holidayDaysLimits/holidayDaysLimits';
import { Dialog, Stack, TableCell, TableRow, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import EditButton from 'common/EditButton/EditButton';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';
import { FormTypes } from 'enums';
import { HolidayType } from 'store/holidayTypes/interfaces';
import DeleteButton from 'common/DeleteButton/DeleteButton';
import { useGetHolidayTypesQuery } from 'store/holidayTypes/holidayTypes';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { HolidayDaysLimits } from 'store/holidayDaysLimits/interfaces';
import AnnualLeaveForm from '../AnnualLeaveForm/AnnualLeaveForm';
import { FormPayload } from '../AnnualLeaveForm/interfaces';

export interface AnnualRowProps {
  holidayDayLimit: HolidayDaysLimits;
}

function AnnualRow({ holidayDayLimit }: AnnualRowProps) {
  const dispatch = useAppDispatch();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {
    data: holidayTypes = {
      'hydra:member': [] as HolidayType[],
    },
  } = useGetHolidayTypesQuery();

  const holidayTypeName = holidayTypes['hydra:member'].find(
    (holidayType) => holidayType['@id'] === holidayDayLimit.holidayType,
  )?.name;

  const [editHolidayLimit, { isLoading: editHolidayLimitLoading }] =
    useEditHolidayDaysLimitMutation();
  const [deleteHolidayType] = useDeleteHolidayDaysLimitMutation();

  const handleEditAnnualLeave = async (data: FormPayload) => {
    const { days, ...restPayload } = data;
    try {
      await editHolidayLimit({
        id: holidayDayLimit.id,
        days: +days,
        ...restPayload,
      }).unwrap();
      dispatch(setSuccessStatus(true));
      setIsEditDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  const handleOnDeleteAnnualLeave = async () => {
    try {
      await deleteHolidayType(holidayDayLimit.id).unwrap();
      dispatch(setSuccessStatus(true));
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <>
      <TableRow key={holidayDayLimit.id}>
        <TableCell>
          <Typography variant="body2">{holidayDayLimit.days}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {dateFormat(holidayDayLimit.dateFrom, 'yyyy-mm-dd')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{holidayDayLimit.variant}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{holidayTypeName}</Typography>
        </TableCell>
        <TableCell align="right">
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <EditButton
              v2
              onClick={() => {
                setIsEditDialogOpen(true);
              }}
            />
            <DeleteButton onClick={() => setIsDeleteDialogOpen(true)} />
          </Stack>
        </TableCell>
      </TableRow>
      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(!isEditDialogOpen)}
        fullWidth
      >
        <AnnualLeaveForm
          isOpen={isEditDialogOpen}
          setIsOpen={setIsEditDialogOpen}
          onSubmit={handleEditAnnualLeave}
          isSubmitting={editHolidayLimitLoading}
          type={FormTypes.edit}
          initValues={{
            days: holidayDayLimit.days.toString(),
            dateFrom: dateFormat(holidayDayLimit.dateFrom, 'yyyy-mm-dd'),
            variant: holidayDayLimit.variant,
            holidayType: holidayDayLimit.holidayType,
          }}
        />
      </Dialog>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        handleAccept={handleOnDeleteAnnualLeave}
      />
    </>
  );
}

export default AnnualRow;
