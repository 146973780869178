import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProgressChart from 'common/ProgressChart/ProgressChart';
import getBudgetDetailsTypography from 'helpers/getBudgetDetailsTypography';
import { Variant } from '@mui/material/styles/createTypography';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import { BudgetDetailsProps, BudgetDetailsType } from './interfaces';

export default function BudgetDetails({ budget, type }: BudgetDetailsProps) {
  const { t } = useTranslation();

  const budgetUsed =
    budget.budgetTransactions.reduce((acc, budgetTransaction) => {
      const { transaction } = budgetTransaction;
      const { cost, targetAmount } = transaction;

      const transactionAmount = budgetTransaction.targetAmount
        ? budgetTransaction.targetAmount / BACKEND_AMOUNT_MODIFIER
        : targetAmount;

      if (cost && cost.taxAmount) {
        // Calculate the tax amount based on the gross amount and tax rate
        const tax = transactionAmount * (cost.taxAmount / 100);
        return acc + (transactionAmount - tax);
      }

      return acc + transactionAmount;
    }, 0) * -1;

  const formatAmount = useCurrencyFormat();
  const budgetPercentage =
    (budgetUsed / (+budget.amount / BACKEND_AMOUNT_MODIFIER)) * 100;
  const typography = getBudgetDetailsTypography(type);

  return (
    <Grid container spacing={2} mt={3}>
      <Grid item xs={12} md={6}>
        {type === BudgetDetailsType.preview && (
          <Typography variant="body1" color="text.secondary">
            {t('budget.budget_actual_consumption')}
          </Typography>
        )}
        <Typography variant={`${typography.costsVariant as Variant}`}>
          {formatAmount(budgetUsed)}
        </Typography>
        <Typography
          variant={`${typography.budgetVariant as Variant}`}
          color="text.secondary"
        >
          {t('budget.from', {
            amount: formatAmount(+budget.amount / BACKEND_AMOUNT_MODIFIER),
          })}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ProgressChart
          percentageValue={budgetPercentage}
          reversed
          circleSize={typography.circleSize}
        />
      </Grid>
    </Grid>
  );
}
