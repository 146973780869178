import { styled, Button, ButtonProps } from '@mui/material';

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  border: '0.1rem solid',
  borderColor: theme.palette.border.main,
  borderRadius: '0.4rem',
  color: theme.palette.secondary.main,
  width: '100%',
  height: '3rem',

  '&:hover': {
    backgroundColor: theme.palette.border.main,
  },
}));

export default StyledButton;
