/* eslint-disable react/require-default-props */
import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface BadgeProps {
  navigateTo: string;
  children: React.ReactNode;
  bgcolor: string;
  iconColor: string;
  border?: string;
  borderColor?: string;
  tab: string;
}

function Badge({
  navigateTo,
  children,
  bgcolor,
  iconColor,
  border,
  borderColor,
  tab,
}: BadgeProps) {
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="center">
      <Box
        width="3.6rem"
        height="3.6rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="0.8rem"
        bgcolor={bgcolor}
        border={border}
        borderColor={borderColor}
        sx={{
          cursor: 'pointer',
          '& svg': {
            color: iconColor,
          },
        }}
        onClick={() => navigate(navigateTo, { state: { tabValue: tab } })}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Badge;
