import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  useGetApprovalsCountQuery,
  useUpdateApprovalsCountMutation,
} from 'store/config/config';
import { ConfigResponse } from 'store/config/interfaces';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { useValidate } from 'hooks/useValidate';
import Error from 'common/Error';
import { LoadingButton } from '@mui/lab';

export interface HolidaysApprovalsCountProps extends StatusProps {}

interface Errors {
  count: boolean;
}

function HolidaysApprovalsCount({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: HolidaysApprovalsCountProps) {
  const { t } = useTranslation();
  const { data: approvals = {} as ConfigResponse<string>, isError } =
    useGetApprovalsCountQuery();
  const [approvalCount, setApprovalCount] = useState('');
  const [approvalChanged, setApprovalChanged] = useState(false);
  const [updateApprovalCount, { isLoading }] =
    useUpdateApprovalsCountMutation();
  const { errors, validate } = useValidate<Errors>();

  useEffect(() => {
    setApprovalCount(approvals.value ?? '');
  }, [approvals]);

  const handleCountChange = () => {
    if (+approvalCount < 0) {
      setApprovalCount('0');
    } else if (+approvalCount > 5) {
      setApprovalCount('5');
    }
  };

  const handleCountUpdate = async () => {
    try {
      await updateApprovalCount({
        value: approvalCount,
      }).unwrap();
      setIsSuccess(true);
      setApprovalChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (isError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('settings.holidays_approval_count')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              error={errors.count}
              fullWidth
              helperText={
                errors.count &&
                t('errors.wrong_numerical_values', {
                  min: 0,
                  max: 5,
                })
              }
              id="approval-count"
              label={t('settings.holidays_approval_count')}
              type="number"
              inputProps={{
                min: 0,
                max: 5,
              }}
              onBlur={(event) => {
                handleCountChange();
                validate(
                  'count',
                  +event.target.value >= 0 && +event.target.value <= 5,
                );
              }}
              onChange={(event) => {
                setApprovalCount(event.target.value);
                setApprovalChanged(true);
              }}
              value={approvalCount}
            />
          </Grid>
          {approvalChanged && (
            <Grid item xs={1}>
              <LoadingButton
                disabled={!approvalCount}
                endIcon={<CheckIcon />}
                loading={isLoading}
                onClick={handleCountUpdate}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default HolidaysApprovalsCount;
