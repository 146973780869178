import React, { useState } from 'react';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { StyledAccordion } from 'components/ResponsibilityGroup/Accordion.styled';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { RangeConditions } from 'store/condition/interfaces';
import { calculateCostsSum } from '../utils';
import EmployeeBalances from './EmployeeBalances/EmployeeBalances';

interface ProjectDataProps {
  projectId: number;
  dateRange: string[];
  projectsCondition: RangeConditions;
}

export default function ProjectData({
  projectId,
  dateRange,
  projectsCondition,
}: ProjectDataProps) {
  const { data: projects = [] } = useGetProjectsPreviewQuery({});
  const [expanded, setExpanded] = useState(false);
  const formatAmount = useCurrencyFormat();
  const projectEmployees = [
    ...new Set(
      Object.values(projectsCondition).flatMap((condition) =>
        Object.keys(condition[projectId] || {}),
      ),
    ),
  ];

  const getProjectName = () => {
    const matchedProject = projects.find((project) => project.id === projectId);

    if (!matchedProject) {
      return '';
    }

    return matchedProject.name;
  };

  return (
    <Grid item xs={13}>
      <StyledAccordion disableGutters expanded={expanded} square>
        <AccordionSummary
          onClick={() => setExpanded((prevState) => !prevState)}
          sx={{ px: 0, m: 0 }}
        >
          <Grid container columns={13} alignItems="center">
            <Grid item xs={1}>
              <Typography variant="body2" pl={1}>
                {getProjectName()}
              </Typography>
            </Grid>
            {dateRange.map((month) => (
              <React.Fragment key={month}>
                <Grid item xs={1.8}>
                  <Typography variant="smallBody">
                    {formatAmount(
                      calculateCostsSum(projectsCondition, month, projectId),
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={1.2}>
                  <Typography variant="smallBody">
                    {formatAmount(
                      -calculateCostsSum(projectsCondition, month, projectId),
                    )}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            px: 0,
            py: 1,
            bgcolor: 'secondary.light',
          }}
        >
          {projectEmployees.map((employee) => (
            <EmployeeBalances
              key={employee}
              dateRange={dateRange}
              employeeId={+employee}
              projectsCondition={projectsCondition}
              projectId={projectId}
            />
          ))}
        </AccordionDetails>
      </StyledAccordion>
    </Grid>
  );
}
