import { FormPayload } from './interfaces';

const defaultValues: FormPayload = {
  type: '',
  description: '',
  dateFrom: new Date(),
  dateTo: new Date(),
  hours: '',
  employee: '',
};

export default defaultValues;
