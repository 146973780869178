import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import {
  TextField,
  Autocomplete,
  Stack,
  IconButton,
  BoxProps,
} from '@mui/material';
import FilterIcon from 'assets/icons/FilterIcon';
import { Budget } from 'store/budget/interfaces';
import CandidateAutocompletePopper from 'components/CandidateAutocompletePopper/CandidateAutocompletePopper';
import StyledOptionWrapper from './OptionWrapper.styled';
import AutocompleteOption from './AutocompleteOption/AutocompleteOption';

interface BudgetAutocompleteProps {
  setSearchPhrase: (phrase: string) => void;
  budgetData: Budget[] | undefined;
  searchPhrase: string;
}

export default function BudgetAutocomplete({
  setSearchPhrase,
  budgetData,
  searchPhrase,
}: BudgetAutocompleteProps) {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setSearchPhrase(event.target.value);
  const handleSearchPhrase = debounce(handleChangeInput, 250);

  const { t } = useTranslation();

  const autocompleteOptions =
    budgetData && searchPhrase.length > 1
      ? budgetData.filter(budget =>
        budget.name.toLowerCase().includes(searchPhrase.toLowerCase()))
      : [];

  const handleUpdateSearchPhraseQuery = () => {
    setSearchPhrase(searchPhrase);
  };

  const handleOnClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setSearchPhrase('');
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Autocomplete
        id="hr-candidates-autocomplete"
        freeSolo
        options={autocompleteOptions}
        filterOptions={(options) => options}
        onChange={(_, newValue) => {
          if (typeof newValue === 'string') {
            return;
          }
          navigate(`/budget/${newValue.id}`);
        }}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : `${option.name}`
        }
        disableClearable
        sx={{
          width: '36rem',
        }}
        PopperComponent={CandidateAutocompletePopper}
        renderOption={(props, budgetOption) => (
          <StyledOptionWrapper
            component="li"
            {...(props as BoxProps)}
            key={budgetOption.id}
          >
            <AutocompleteOption budgetOption={budgetOption} />
          </StyledOptionWrapper>
        )}
        renderInput={(params) => (
          <Stack direction="row" alignItems="center">
            <TextField
              {...params}
              inputRef={inputRef}
              label={t('label.search')}
              inputProps={{
                ...params.inputProps,
                value: inputRef?.current?.value ?? '',
              }}
              placeholder={t('budget.budget_name')}
              onChange={handleSearchPhrase}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleUpdateSearchPhraseQuery();
                }
              }}
            />
            {inputRef?.current?.value && (
              <IconButton
                onClick={handleOnClear}
                sx={{
                  marginLeft: '-4rem',
                  width: '3rem',
                  height: '3rem',
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        )}
      />
      <IconButton
        sx={{
          width: '4rem',
          height: '4rem',
        }}
        onClick={handleUpdateSearchPhraseQuery}
      >
        <FilterIcon
          sx={{
            fontSize: '2rem',
            color: 'icon.dark',
          }}
        />
      </IconButton>
    </Stack>
  );
}
