import React, { useEffect } from 'react';
import { getChipColor } from 'components/ProjectsCondition/utils';
import { Chip, Grid, Typography } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { useDispatch } from 'react-redux';
import useConditionBalance from 'hooks/useConditionBalance';
import setBalance from 'store/condition/actions';
import { getPropertyBalance } from './utils';

interface ConditionBalanceProps {
  section: string;
  month: string;
  order: string[];
  sectionBalance: number;
}

export default function ConditionBalance({
  section,
  month,
  order,
  sectionBalance,
}: ConditionBalanceProps) {
  const formatAmount = useCurrencyFormat();
  const dispatch = useDispatch();
  const { balances, startBalance } = useConditionBalance(month);
  const propertyIndex = order.indexOf(section);

  const calculatePreviousSectionSum = () =>
    order
      .slice(0, propertyIndex)
      .reduce((acc, item) => acc + getPropertyBalance(balances, item), 0);

  const getOverallBalance = () => {
    if (propertyIndex === 0) {
      return startBalance - sectionBalance;
    }
    return startBalance - calculatePreviousSectionSum() - sectionBalance;
  };

  useEffect(() => {
    dispatch(setBalance({ collection: section, month, value: sectionBalance }));
  }, [dispatch, month, section, sectionBalance]);

  return (
    <>
      <Grid item xs={1.8} />
      <Grid item xs={1.2}>
        <Chip
          color={getChipColor(month, getOverallBalance())}
          label={
            <Typography variant="chipLabel">
              {formatAmount(getOverallBalance())}
            </Typography>
          }
        />
      </Grid>
    </>
  );
}
