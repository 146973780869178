import React, { useState } from 'react';
import {
  Avatar,
  IconButton,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useDeleteOrganizationUserMutation } from 'store/OrganizationUsers/organizationUsers';
import { useAddUserForEmployeeMutation } from 'store/employee/employees';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { CustomError } from 'store/api';
import DeleteButton from 'common/DeleteButton/DeleteButton';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import ContextMenuButton from 'common/ContextMenuButton/ContextMenuButton';
import { OrganizationUserRowProps } from './interfaces';

export default function OrganizationUserRow({
  organizationUser,
  setIsSuccess,
  setIsErrorCatch,
}: OrganizationUserRowProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [contextAnchorEl, setContextAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: employees = [] } = useGetEmployeesPreview();
  const [deleteOrganizationUser] = useDeleteOrganizationUserMutation();

  const organizationUserEmployee = employees.find(
    (employee) => employee.id === organizationUser.user.employeeId,
  );

  const [addUser] = useAddUserForEmployeeMutation();

  const sentInvitationToOrganization = async () => {
    try {
      await addUser({
        id: organizationUser.user.employeeId,
        email: organizationUser.user.email,
        firstname: organizationUser.user.firstname,
        lastname: organizationUser.user.lastname,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  const handleDeleteOrganizationUser = async (id: string) => {
    try {
      await deleteOrganizationUser(id).unwrap();
      setIsSuccess(true);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  return (
    <>
      <TableRow key={organizationUser.id}>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar src={organizationUser.user?.avatarUrl} />
            <Stack spacing={0.5}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2">
                  {`${organizationUser.user.lastname} ${organizationUser.user.firstname}`}
                </Typography>
              </Stack>
              <Typography variant="caption" color="text.secondary">
                {organizationUser.user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" justifyContent="center">
            {organizationUserEmployee && (
              <TitleTooltip
                title={`${organizationUserEmployee.lastname} ${organizationUserEmployee.firstname}`}
              >
                <IconButton
                  onClick={() =>
                    navigate(`/employees/${organizationUser.user.employeeId}`)
                  }
                >
                  <AccountCircleOutlinedIcon
                    sx={{ fontSize: '2rem', color: 'icon.dark' }}
                  />
                </IconButton>
              </TitleTooltip>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <ContextMenuButton
              anchorEl={contextAnchorEl}
              setAnchorEl={setContextAnchorEl}
              onClick={(event) => setContextAnchorEl(event.currentTarget)}
              items={
                <MenuItem
                  onClick={() => {
                    sentInvitationToOrganization();
                    setContextAnchorEl(null);
                  }}
                >
                  <Typography variant="body2">
                    {t('organization_users.send_invitation_again')}
                  </Typography>
                </MenuItem>
              }
            />
            <DeleteButton onClick={() => setIsDeleteDialogOpen(true)} />
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        confirmationText={t('organization_users.delete_user', {
          user: `${organizationUser.user.firstname} ${organizationUser.user.lastname}`,
        })}
        handleAccept={async () => {
          await handleDeleteOrganizationUser(organizationUser.id);
        }}
      />
    </>
  );
}
