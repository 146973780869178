import React from 'react';
import { Grid } from '@mui/material';
import { useGetResourceRequestsQuery } from 'store/resourceRequests/resourceRequests';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { ResourceRequestsData, ResourceRequestStatus } from 'store/resourceRequests/interfaces';
import ResourceRequestTile from './ResourceRequestTile/ResourceRequestTile';

export default function HrResourcesList() {
  const {
    data: resourceRequestsData = {} as ResourceRequestsData,
    isLoading: resourcesRequestsLoading,
    isError: resourcesRequestsError,
  } = useGetResourceRequestsQuery({ status: [ResourceRequestStatus.draft, ResourceRequestStatus.pending] });

  if (resourcesRequestsLoading) {
    return <Loader />;
  }

  if (resourcesRequestsError) {
    return <Error />;
  }

  const { 'hydra:member': resourceRequests } = resourceRequestsData;

  return (
    <Grid container spacing={3}>
      {resourceRequests.map((resource) => (
        <ResourceRequestTile key={resource.id} resourceRequest={resource} />
      ))}
    </Grid>
  );
}
