import api from 'store/api';
import { DocumentData, DocumentsResponseDto } from 'store/documents/documents';
import {
  EmployeeProjectRate,
  EmployeeProjectRateResponseDto,
} from 'store/employeeProjectRate/employeeProjectRate';
import {
  EmployeeFilters,
  EmployeePreview,
  EmployeePreviewResponseDto,
  EmployeesResponseDto,
  LoggedEmployee,
  Employee,
} from './interfaces';

export const employeesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query<Employee[], Partial<EmployeeFilters>>({
      query: (filters) => ({
        url: '/employees',
        headers: { accept: 'application/ld+json' },
        params: filters,
      }),
      providesTags: ['Employees'],
      transformResponse: (response: EmployeesResponseDto) =>
        response['hydra:member'].sort((employee, comparedEmployee) => {
          const sortedByLastName = employee.lastname.localeCompare(
            comparedEmployee.lastname,
          );
          const sortedByFirstName = employee.firstname.localeCompare(
            comparedEmployee.firstname,
          );
          return sortedByLastName !== 0 ? sortedByLastName : sortedByFirstName;
        }),
    }),
    getEmployee: builder.query<Employee, number | string>({
      query: (id) => ({
        url: `/employees/${id}`,
        headers: { accept: 'application/ld+json' },
      }),
      providesTags: ['Employees'],
    }),
    addEmployee: builder.mutation({
      query: (body) => ({
        url: '/employees',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Employees'],
    }),
    updateEmployee: builder.mutation<
      Employee,
      Partial<Employee> & Pick<Employee, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/employees/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Employees'],
    }),
    addUserForEmployee: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/employees/${id}/user`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Employees'],
    }),
    getEmployeeDocuments: builder.query<DocumentData[], number>({
      query: (id) => `/employees/${id}/documents`,
      providesTags: ['Documents'],
      transformResponse: (response: DocumentsResponseDto) =>
        response['hydra:member'],
    }),
    getEmployeeProjectRates: builder.query<EmployeeProjectRate[], number>({
      query: (id) => `/employees/${id}/project-rates`,
      providesTags: ['EmployeeProjectRates'],
      transformResponse: (response: EmployeeProjectRateResponseDto) =>
        response['hydra:member'].sort((rate, comparedRate) => {
          const sortedByProjectName = rate.project.name.localeCompare(
            comparedRate.project.name,
          );
          const sortedByDateFrom =
            new Date(rate.dateFrom).getTime() -
            new Date(comparedRate.dateFrom).getTime();
          return sortedByProjectName !== 0
            ? sortedByProjectName
            : sortedByDateFrom;
        }),
    }),
    getEmployeesPreview: builder.query<
      EmployeePreview[],
      Partial<EmployeeFilters>
    >({
      query: (filters) => ({
        url: '/employees/preview',
        params: filters,
      }),
      providesTags: ['Employees'],
      transformResponse: (response: EmployeePreviewResponseDto) =>
        response['hydra:member'],
    }),
    getLoggedEmployee: builder.query<LoggedEmployee, void>({
      query: () => '/employees/me',
      providesTags: [{ type: 'Employees', id: 'ME' }],
    }),
    getEmployeeFeedbackSuggestion: builder.query<Employee, number>({
      query: (id) => `/employees/${id}/feedback-suggestion`,
      providesTags: ['Employees'],
    }),
  }),
});

export const {
  useGetEmployeesQuery, // use hook useGetEmployees instead
  useAddEmployeeMutation,
  useGetEmployeeQuery,
  useUpdateEmployeeMutation,
  useAddUserForEmployeeMutation,
  useGetEmployeeDocumentsQuery,
  useGetEmployeeProjectRatesQuery,
  useGetEmployeesPreviewQuery, // use hook useGetEmployeesPreview or useGetSelectedEmployeePreview instead
  useGetLoggedEmployeeQuery,
  useGetEmployeeFeedbackSuggestionQuery,
} = employeesApi;
