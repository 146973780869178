import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { HolidayRequestStatus } from 'enums';
import useNotification from 'hooks/useNotifications';
import { holidaysApi } from 'store/holidays/holidays';
import { employeesApi } from 'store/employee/employees';
import { HolidayRequest } from 'store/holidays/interfaces';
import { useAppDispatch } from 'store/hooks';
import Logo from 'assets/img/logo.svg';
import { useTranslation } from 'react-i18next';

const useHolidayRequestsEvent = () => {
  const dispatch = useAppDispatch();
  const notifyUser = useNotification();
  const { t } = useTranslation();

  const handleRequestsUpdate = (
    data: HolidayRequest,
    requests: MaybeDrafted<HolidayRequest[]>,
  ) => {
    const changedRequestIndex = requests.findIndex(
      (request) => request.id === data.id,
    );

    if (changedRequestIndex === -1) {
      return [...requests, data];
    }

    if (
      data.status === HolidayRequestStatus.accepted ||
      data.status === HolidayRequestStatus.rejected ||
      data.status === HolidayRequestStatus.canceled
    ) {
      return requests.filter((request) => request.id !== data.id);
    }

    const updatedRequests = [...requests];
    updatedRequests.splice(changedRequestIndex, 1, data);

    return updatedRequests;
  };

  const handleHolidayRequestsServerEvents = (
    data: HolidayRequest,
    loggedEmployeeId: number,
  ) => {
    dispatch(
      holidaysApi.util.updateQueryData('getHolidayRequests', {}, (requests) =>
        handleRequestsUpdate(data, requests),
      ),
    );

    dispatch(
      holidaysApi.util.updateQueryData(
        'getHolidayRequests',
        { employee: [data.employee.id] },
        (requests) => handleRequestsUpdate(data, requests),
      ),
    );

    if (
      data.status === HolidayRequestStatus.created &&
      data.employee.id !== loggedEmployeeId
    ) {
      notifyUser(t('holidays.new_request'), {
        icon: Logo,
      });
    }

    if (
      data.status === HolidayRequestStatus.accepted &&
      data.employee.id === loggedEmployeeId
    ) {
      notifyUser(t('holidays.request_accepted'), {
        icon: Logo,
      });
      dispatch(
        employeesApi.util.invalidateTags([{ type: 'Employees', id: 'ME' }]),
      );
    }

    if (
      data.status === HolidayRequestStatus.rejected &&
      data.employee.id === loggedEmployeeId
    ) {
      notifyUser(t('holidays.request_rejected'), {
        icon: Logo,
      });
    }
  };

  return handleHolidayRequestsServerEvents;
};

export default useHolidayRequestsEvent;
