import React, { useEffect, useState } from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import validMailSigns from 'helpers/ValidationRegex';
import { useValidate } from 'hooks/useValidate';
import { Errors } from 'components/AddEmployeeForm/AddEmployeeForm';
import { useUpdateEmployeeMutation } from 'store/employee/employees';
import { Employee } from 'store/employee/interfaces';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface ContactDataProps extends StatusProps {
  employeeFetched: boolean;
  employee: Employee;
}

function ContactData({
  employeeFetched,
  employee,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: ContactDataProps) {
  const [companyEmail, setCompanyEmail] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [changed, setChanged] = useState(false);
  const [updateEmployee, { isLoading }] = useUpdateEmployeeMutation();
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!employeeFetched) {
      return;
    }
    setCompanyEmail(employee.companyEmail);
    setContactEmail(employee.contactEmail);
    setContactPhone(employee.contactPhone);
  }, [employeeFetched, employee]);

  const disableSubmission = () => Object.values(errors).some((error) => error);

  const handleEditEmployee = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateEmployee({
        id: employee.id,
        companyEmail,
        contactPhone,
        contactEmail,
      }).unwrap();
      setIsSuccess(true);
      setChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box p={4} bgcolor="background.list" borderRadius="1.2rem">
      <Typography variant="h5" mb={4}>
        {t('employee.contact_details')}
      </Typography>
      <form onSubmit={handleEditEmployee}>
        <FormControl fullWidth>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              required
              error={errors.companyEmail}
              helperText={errors.companyEmail && t('errors.wrong_mail')}
              name="companyEmail"
              variant="outlined"
              label={t('inputs.company_email')}
              id="company-email"
              value={companyEmail}
              onChange={(event) => {
                setCompanyEmail(event.target.value);
                setChanged(true);
              }}
              onBlur={(event) => {
                validate(
                  'companyEmail',
                  Boolean(event.target.value.match(validMailSigns)),
                );
              }}
            />
            <TextField
              error={errors.contactEmail}
              helperText={errors.contactEmail && t('errors.wrong_mail')}
              name="contactEmail"
              variant="outlined"
              label={t('inputs.contact_email')}
              id="contact-email"
              value={contactEmail}
              onChange={(event) => {
                setContactEmail(event.target.value);
                setChanged(true);
              }}
              onBlur={(event) => {
                validate(
                  'contactEmail',
                  Boolean(event.target.value.match(validMailSigns)) ||
                    !event.target.value,
                );
              }}
            />
            <TextField
              type="tel"
              name="contactPhone"
              variant="outlined"
              label={t('inputs.contact_phone')}
              id="contact-phone"
              value={contactPhone}
              onChange={(event) => {
                setContactPhone(event.target.value);
                setChanged(true);
              }}
            />
          </Box>
        </FormControl>
        {changed && (
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<CheckIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save_changes')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
}

export default ContactData;
