import { BookingData } from './context/BookingScheduler.context';

interface IsDraftStartDateInBookingDateRangeProps {
  startDate: Date;
  bookingStartDate: Date;
  bookingEndDate: Date;
}

interface IsDraftEndDateInBookingDateRangeProps {
  endDate: Date;
  bookingStartDate: Date;
  bookingEndDate: Date;
}

interface IsDraftDateInBookingInDateRangeProps {
  startDate: Date;
  endDate: Date;
  bookingStartDate: Date;
  bookingEndDate: Date;
}

interface CheckIsSomeBookingInDateRangeDraftAppointmentProps {
  bookingsInState: BookingData[];
  draftBookingStartDate: Date;
  draftBookingEndDate: Date;
}

const isDraftStartDateInBookingDateRange = ({
  startDate,
  bookingStartDate,
  bookingEndDate,
}: IsDraftStartDateInBookingDateRangeProps) =>
  startDate >= bookingStartDate && startDate < bookingEndDate;

const isDraftEndDateInBookingDateRange = ({
  endDate,
  bookingStartDate,
  bookingEndDate,
}: IsDraftEndDateInBookingDateRangeProps) =>
  endDate > bookingStartDate && endDate <= bookingEndDate;

const isDraftDateHasSameDateRangeAsBooking = ({
  startDate,
  endDate,
  bookingStartDate,
  bookingEndDate,
}: IsDraftDateInBookingInDateRangeProps) =>
  startDate <= bookingStartDate && endDate >= bookingEndDate;

const checkIsSomeBookingInDateRangeDraftAppointment = ({
  bookingsInState,
  draftBookingStartDate,
  draftBookingEndDate,
}: CheckIsSomeBookingInDateRangeDraftAppointmentProps) =>
  bookingsInState.some(
    (booking) =>
      isDraftDateHasSameDateRangeAsBooking({
        startDate: draftBookingStartDate,
        endDate: draftBookingEndDate,
        bookingStartDate: booking.startDate as Date,
        bookingEndDate: booking.endDate as Date,
      }) ||
      isDraftStartDateInBookingDateRange({
        startDate: draftBookingStartDate,
        bookingStartDate: booking.startDate as Date,
        bookingEndDate: booking.endDate as Date,
      }) ||
      isDraftEndDateInBookingDateRange({
        endDate: draftBookingEndDate,
        bookingStartDate: booking.startDate as Date,
        bookingEndDate: booking.endDate as Date,
      }),
  );

export default checkIsSomeBookingInDateRangeDraftAppointment;
