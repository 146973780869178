import { useContext } from 'react';

import { CandidateContext, CandidateContextProps } from './Candidate.context';

function useCandidateContext(): CandidateContextProps {
  const context = useContext(CandidateContext);

  if (!context) {
    throw new Error(
      'useCandidateContext must be used within a CandidateProvider',
    );
  }

  return context;
}

export default useCandidateContext;
