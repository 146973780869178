import React, { useState } from 'react';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Location } from 'store/locations/locations';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import { useTranslation } from 'react-i18next';
import EditButton from 'common/EditButton/EditButton';
import EditLocation from './EditLocation';

export interface LocationsListProps {
  locations: Location[];
}

function LocationsList({ locations }: LocationsListProps) {
  const [chosenLocationIri, setChosenLocationIri] = useState('');
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const headers = ['locations.header_location'];
  const { t } = useTranslation();

  const selectedLocation = locations.find(
    (location) => location['@id'] === chosenLocationIri,
  ) as Location;

  return (
    <>
      <TableContainer sx={{ px: 3, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.length ? (
              locations.map((location) => (
                <TableRow key={location.id}>
                  <TableCell>
                    <Typography variant="body2">{location.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <EditButton
                      onClick={() => {
                        setChosenLocationIri(location['@id']);
                        setIsEditDialogOpen(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoDataRow headers={headers} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        fullWidth
      >
        <EditLocation
          location={selectedLocation}
          setIsOpen={setIsEditDialogOpen}
        />
      </Dialog>
    </>
  );
}

export default LocationsList;
