import { Budget } from 'store/budget/interfaces';
import { EmployeePreview } from 'store/employee/interfaces';

export interface BudgetCostsListProps {
  budget: Budget;
  tableTitle: string;
  isLoading: boolean;
  isFetchError: boolean;
  type: string;
  employees: EmployeePreview[];
}

export enum CostsType {
  all = 'all',
  latest = 'latest',
}
