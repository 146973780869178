const getShorterDescription = (
  description: string,
  maxCharactersAmount: number,
) => {
  let charactersAmount = 0;
  const slicedDescription = description
    .split(' ')
    .map((word) => {
      charactersAmount += word.length;
      if (charactersAmount > maxCharactersAmount) {
        return null;
      }
      return word;
    })
    .join(' ');
  return slicedDescription;
};

export default getShorterDescription;
