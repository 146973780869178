import React from 'react';
import { Box, FormControl, MenuItem, Select, TextField } from '@mui/material';
import {
  amountFrequency,
  amountSort,
} from 'components/AddAgreementForm/selectData';
import StyledAmountInput from 'components/EditAgreementForm/GeneralForm/AmountInputs/AmountInput.styled';
import { WorkDetailsProps } from 'components/AddAgreementForm/interfaces';
import { useTranslation } from 'react-i18next';

function WorkDetails({
  errors,
  hoursPerWeek,
  onHoursPerWeekChange,
  amount,
  onAmountChange,
  currency,
  onCurrencyChange,
  currencies,
  perFrequency,
  onPerFrequencyChange,
  amountType,
  onAmountTypeChange,
  validate,
}: WorkDetailsProps) {
  const { t } = useTranslation();

  return (
    <>
      <FormControl required fullWidth>
        <TextField
          required
          error={errors.hoursPerWeek}
          helperText={errors.hoursPerWeek && t('errors.hours_per_week')}
          id="hours"
          label={t('inputs.hours_per_week')}
          name="hours"
          value={hoursPerWeek}
          onChange={onHoursPerWeekChange}
          onBlur={(event) =>
            validate(
              'hoursPerWeek',
              Number(event.target.value) >= 0 &&
                Number(event.target.value) <= 40 &&
                event.target.value !== '',
            )
          }
          inputProps={{
            min: 0,
            max: 40,
          }}
          fullWidth
        />
      </FormControl>
      <Box display="flex">
        <StyledAmountInput
          required
          type="number"
          error={errors.amount}
          helperText={errors.amount && t('errors.field_required')}
          id="amount"
          label={t('inputs.amount')}
          name="amount"
          value={amount}
          onChange={onAmountChange}
          onBlur={(event) =>
            validate(
              'amount',
              event.target.value !== '' && Number(event.target.value) >= 0,
            )
          }
          inputProps={{
            min: 0,
            step: 0.01,
          }}
        />
        <FormControl sx={{ flexBasis: '15%' }}>
          <Select
            id="currency-select"
            value={currency}
            onChange={onCurrencyChange}
            sx={{ borderRadius: 0 }}
          >
            {currencies.map((currencyData) => (
              <MenuItem key={currencyData.id} value={currencyData['@id']}>
                {currencyData.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ flexBasis: '25%' }}>
          <Select
            id="frequency-select"
            value={perFrequency}
            onChange={onPerFrequencyChange}
            sx={{ borderRadius: 0 }}
          >
            {amountFrequency.map((frequency) => (
              <MenuItem key={frequency.label} value={frequency.value}>
                {frequency.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ flexBasis: '20%' }}>
          <Select
            id="amount-sort-select"
            value={amountType}
            onChange={onAmountTypeChange}
            sx={{
              borderRadius: 0,
              borderTopRightRadius: '1.2rem',
              borderBottomRightRadius: '1.2rem',
            }}
          >
            {amountSort.map((sort) => (
              <MenuItem key={sort} value={sort}>
                {sort}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}

export default WorkDetails;
