import { DeepPartial, FieldErrors, FieldValues } from 'react-hook-form';

const getControlErrors = <T extends FieldValues>(
  name: string,
  errors: FieldErrors<T>
): DeepPartial<T> | undefined => {
  const registerNameParts = name.split('.');
  let registerNameErrors: DeepPartial<T> | undefined = errors as DeepPartial<T>;

  registerNameParts.forEach((part: string) => {
    if (registerNameErrors) {
      registerNameErrors = registerNameErrors[part] as DeepPartial<T>;
    }
  });

  return registerNameErrors;
};

export default getControlErrors;
