import { useContext } from 'react';

import { MeetingContext, MeetingContextProps } from './Meeting.context';

function useMeetingContext(): MeetingContextProps {
  const context = useContext(MeetingContext);

  if (!context) {
    throw new Error(
      'useMeetingContext must be used within a MeetingProvider',
    );
  }

  return context;
}

export default useMeetingContext;
