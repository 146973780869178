import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RangeConditions } from 'store/condition/interfaces';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import Loader from 'common/Loader';
import Error from 'common/Error';
import getMonthsRange from 'helpers/getMonthsRange';
import ConditionBalance from 'common/ConditionBalance/ConditionBalance';
import ProjectData from './ProjectData/ProjectData';
import { getMonthlyCostsBalance } from './utils';

interface NonIncomeProjectsConditionProps {
  header: string;
  projectsData: RangeConditions;
  order: string[];
  section: string;
}

export default function NonIncomeProjectsCondition({
  header,
  projectsData,
  order,
  section,
}: NonIncomeProjectsConditionProps) {
  const { t } = useTranslation();
  const { isLoading: projectsFetching, isError: projectsFetchError } =
    useGetProjectsPreviewQuery({});
  const projectsIds = Object.values(projectsData).flatMap((item) =>
    Object.keys(item),
  );
  const uniqueProjects = [...new Set(projectsIds)];
  const currentDate = new Date();
  const dateRange = getMonthsRange(currentDate, 2, 1);

  if (projectsFetching) {
    return <Loader />;
  }

  if (projectsFetchError) {
    return <Error />;
  }

  return (
    <Grid container columns={13} p={2} rowSpacing={2}>
      <Grid item xs={13}>
        <Typography textTransform="uppercase" variant="body2">
          {t(header)}
        </Typography>
      </Grid>
      {uniqueProjects.map((project) => (
        <ProjectData
          key={project}
          dateRange={dateRange}
          projectsCondition={projectsData}
          projectId={+project}
        />
      ))}
      <Grid container columns={13} pt={2}>
        <Grid item xs={1} />
        {dateRange.map((month) => (
          <ConditionBalance
            key={month}
            month={month}
            order={order}
            section={section}
            sectionBalance={getMonthlyCostsBalance(
              month,
              projectsData,
              uniqueProjects,
            )}
          />
        ))}
      </Grid>
    </Grid>
  );
}
