import Component, { LayoutProps } from 'components/Layout/Layout';
import { connect } from 'react-redux';
import { withStatus } from 'hoc/Status/Status';
import { RootState } from 'store';
import { getIsAuthorized } from 'store/authorization/selectors';

const Layout = withStatus<LayoutProps>(Component);

const mapStateToProps = (state: RootState) => ({
  isAuthorized: getIsAuthorized(state),
});

export default connect(mapStateToProps)(Layout);
