import api from 'store/api';
import { ApiResponse } from 'store/interfaces';
import { StorageResponse } from 'store/storage/storage';

export interface Location {
  ['@id']: string;
  id: number;
  name: string;
  officeOpenHour?: number;
  officeCloseHour?: number;
  file: null | StorageResponse;
}

export interface LocationPostMutationRequest
  extends Omit<
    Location,
    'officeOpenHour' | 'officeCloseHour' | '@id' | 'id' | 'file'
  > {
  officeOpenHour: number | null;
  officeCloseHour: number | null;
}

export interface LocationPatchMutationRequest
  extends Partial<LocationPostMutationRequest> {
  id: number;
}

export interface LocationsResponseDto extends ApiResponse {
  'hydra:member': Location[];
}

export const locationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query<Location[], void>({
      query: () => '/locations',
      providesTags: ['Locations'],
      transformResponse: (response: LocationsResponseDto) =>
        response['hydra:member'].sort((location, comparedLocation) =>
          location.name.localeCompare(comparedLocation.name),
        ),
    }),
    addLocation: builder.mutation<Location, LocationPostMutationRequest>({
      query: (body) => ({
        url: '/locations',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Locations'],
    }),
    updateLocation: builder.mutation<Location, LocationPatchMutationRequest>({
      query: ({ id, ...body }) => ({
        url: `/locations/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Locations'],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useAddLocationMutation,
  useUpdateLocationMutation,
} = locationsApi;
