// this hook should be used to fetch employees
import {
  useGetEmployeesQuery,
} from 'store/employee/employees';
import { Employee } from 'store/employee/interfaces';
import { useSelector } from 'react-redux';
import features from 'config/features/features';
import checkFeature from 'helpers/checkFeature';
import { RootState } from 'store';

export default function useGetEmployees(
  filters: Parameters<typeof useGetEmployeesQuery>[0] = {},
  options?: Parameters<typeof useGetEmployeesQuery>[1],
) {
  const organizationFeatures = useSelector(
    (state: RootState) => state.organization.organization.features,
  );
  const isEnabled = checkFeature(features.agreements, organizationFeatures);

  const shouldIsActiveFilterBeSkipped =
    !isEnabled && typeof filters === 'object';

  const filtersToUse = shouldIsActiveFilterBeSkipped
    ? { ...filters, isActive: undefined }
    : filters;
  const { data, isError, isFetching, isLoading, isSuccess } =
    useGetEmployeesQuery(filtersToUse, options);

  return {
    data: data as Employee[] | undefined,
    isError,
    isFetching,
    isLoading,
    isSuccess,
  };
}
