import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Box,
  Typography,
  TextField
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  useGetInvoiceSendCcsQuery,
  useUpdateInvoiceSendCcsMutation,
} from 'store/config/config';
import { InvoiceSendCcsResponse } from 'store/config/interfaces';
import Error from 'common/Error';
import { CustomError } from 'store/api';
import { InvoiceSendCcsNameProps } from './interfaces';

function InvoiceSendCcsName({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: InvoiceSendCcsNameProps) {
  const { t } = useTranslation();
  const {
    data: invoiceSendCcs = {} as InvoiceSendCcsResponse,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
  } = useGetInvoiceSendCcsQuery();

  const [updateInvoiceSendCcs, { isLoading: isMutationLoading }] = useUpdateInvoiceSendCcsMutation();

  const [selectedInvoiceSendCcsName, setSelectedInvoiceSendCcsName] = useState('');
  const isInvoiceSendCcsNameChanged = selectedInvoiceSendCcsName !== invoiceSendCcs?.value?.name;

  const handleUpdateInvoiceSendCcsName = async () => {
    const updatedInvoiceSendCcs = {
        name: selectedInvoiceSendCcsName,
        email: invoiceSendCcs?.value?.email,
    };

    try {
      await updateInvoiceSendCcs({
        value: JSON.stringify(updatedInvoiceSendCcs),
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }};

  useEffect(() => {
    if (isQuerySuccess && invoiceSendCcs?.value?.name) {
      setSelectedInvoiceSendCcsName(invoiceSendCcs.value.name);
    }
  }, [isQuerySuccess, invoiceSendCcs]);

  if (isQueryError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.invoice_send_ccs_name')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('label.ccs_name')}
              id="invoice-send-ccs-name"
              name="invoiceSendCcsName"
              onChange={(event) => setSelectedInvoiceSendCcsName(event.target.value)}
              value={selectedInvoiceSendCcsName}
              fullWidth
            />
          </Grid>
          {isInvoiceSendCcsNameChanged && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isMutationLoading}
                onClick={handleUpdateInvoiceSendCcsName}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

export default InvoiceSendCcsName;
