import React from 'react';
import { Transaction } from 'store/transactions/transactions';
import { Grid, Typography } from '@mui/material';
import TransactionItem from 'components/TransactionsList/TransactionItem';
import { useTranslation } from 'react-i18next';
import Filters from 'components/TransactionsList/Filters/Filters';

export interface TransactionListProps {
  transactions: Transaction[];
  isAllTransactionsList: boolean;
  padding: number;
}

function TransactionsList({
  transactions,
  isAllTransactionsList,
  padding,
}: TransactionListProps) {
  const { t } = useTranslation();
  const checkIfTransactionsMissing = (
    transactionsData: Transaction[],
    transaction: Transaction,
  ) => {
    const itemIndex = transactionsData.indexOf(transaction);
    if (itemIndex === 0) {
      return false;
    }
    const previousItemBalance = transactionsData[itemIndex - 1].endingBalance;
    const previousItemAmount = transactionsData[itemIndex - 1].amount;
    const currentItemBalance = transactionsData[itemIndex].endingBalance;

    return (
      +(previousItemBalance - previousItemAmount).toFixed(2) !==
      currentItemBalance
    );
  };

  return (
    <Grid container p={padding} gap={0.5}>
      {isAllTransactionsList && <Filters />}
      {transactions.length ? (
        transactions.map((transaction) => (
          <TransactionItem
            key={transaction.id}
            isAllTransactionsList={isAllTransactionsList}
            isMissingTransaction={checkIfTransactionsMissing(
              transactions,
              transaction,
            )}
            transaction={transaction}
          />
        ))
      ) : (
        <Grid
          item
          display="flex"
          justifyContent="center"
          bgcolor="main.white"
          border="0.1rem solid"
          borderColor="border.light"
          borderRadius="1.6rem"
          p={2}
          width="100%"
        >
          <Typography variant="bold">{t('label.no_data')}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default TransactionsList;
