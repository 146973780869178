import api, { CustomError } from 'store/api';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';
import {
  CandidateNote,
  CandidateNotesData,
  CandidateNotePatchMutationRequest,
  CandidateNotePostMutationRequest,
  CandidateNotesFilters,
} from './interfaces';
import shouldModifyCandidateNoteCache from './shouldModifyCandidateNoteCache';

export const candidateNotesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCandidateNotes: builder.query<
      CandidateNotesData,
      Partial<CandidateNotesFilters>
    >({
      query: (filters) => ({ url: '/candidate-notes', params: filters }),
      providesTags: ['CandidateNotes'],
      transformResponse: (response: CandidateNotesData) => ({
        ...response,
        'hydra:member': response['hydra:member'].sort(
          (note, comparedNote) =>
            new Date(comparedNote.createdAt).getTime() -
            new Date(note.createdAt).getTime(),
        ),
      }),
    }),
    addCandidateNote: builder.mutation<
      CandidateNote,
      CandidateNotePostMutationRequest
    >({
      query: (payload) => ({
        url: '/candidate-notes',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: createdNote } = await queryFulfilled;

          const candidateNotesCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'CandidateNotes' },
          ]);
          candidateNotesCache
            .filter(({ endpointName }) => endpointName === 'getCandidateNotes')
            .forEach(({ originalArgs }) => {
              const shouldModifyCache = shouldModifyCandidateNoteCache(
                originalArgs,
                createdNote,
              );

              if (!shouldModifyCache) {
                return;
              }

              dispatch(
                candidateNotesApi.util.updateQueryData(
                  'getCandidateNotes',
                  originalArgs,
                  (draft) => {
                    draft?.['hydra:member']?.unshift(createdNote);
                  },
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    updateCandidateNote: builder.mutation<
      CandidateNote,
      CandidateNotePatchMutationRequest
    >({
      query: ({ id, ...body }) => ({
        url: `/candidate-notes/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedNote } = await queryFulfilled;

          const candidateNotesCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'CandidateNotes' },
          ]);
          candidateNotesCache
            .filter(({ endpointName }) => endpointName === 'getCandidateNotes')
            .forEach(({ originalArgs }) => {
              const shouldModifyCache = shouldModifyCandidateNoteCache(
                originalArgs,
                updatedNote,
              );

              if (!shouldModifyCache) {
                return;
              }

              dispatch(
                candidateNotesApi.util.updateQueryData(
                  'getCandidateNotes',
                  originalArgs,
                  (draft) => {
                    const noteIndex = draft?.['hydra:member']?.findIndex(
                      (note) => note.id === id,
                    );

                    if (noteIndex !== -1) {
                      draft['hydra:member'].splice(noteIndex, 1, updatedNote);
                    }
                  },
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    deleteCandidateNote: builder.mutation({
      query: (id: string) => ({
        url: `/candidate-notes/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const candidateNotesCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'CandidateNotes' },
          ]);

          candidateNotesCache
            .filter(({ endpointName }) => endpointName === 'getCandidateNotes')
            .forEach(({ originalArgs }) => {
              dispatch(
                candidateNotesApi.util.updateQueryData(
                  'getCandidateNotes',
                  originalArgs,
                  (draft) => ({
                    ...draft,
                    'hydra:member': draft['hydra:member'].filter(
                      (note) => note.id !== id,
                    ),
                  }),
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
  }),
});

export const {
  useGetCandidateNotesQuery,
  useAddCandidateNoteMutation,
  useUpdateCandidateNoteMutation,
  useDeleteCandidateNoteMutation,
} = candidateNotesApi;
