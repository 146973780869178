import api from 'store/api';

export interface AuthResponse {
  token: string;
}

export interface ActivationBody {
  token: string;
  id: string;
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation<AuthResponse, any>({
      query: (body) => ({
        url: '/users/register',
        method: 'POST',
        body,
      }),
    }),

    activateUser: builder.mutation<
      ActivationBody,
      Partial<ActivationBody> & Pick<ActivationBody, 'token'>
    >({
      query: ({ token }) => ({
        url: `/users/activation/${token}`,
        headers: { 'content-type': 'application/ld+json' },
        method: 'POST',
        body: token,
      }),
    }),
  }),
});

export const { useRegisterUserMutation, useActivateUserMutation } = authApi;
