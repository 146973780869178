import React, { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import dateFormat from 'dateformat';
import {
  useGetEmployeeQuery,
  useGetLoggedEmployeeQuery,
} from 'store/employee/employees';
import EditButton from 'common/EditButton/EditButton';
import DeleteButton from 'common/DeleteButton/DeleteButton';
import Loader from 'common/Loader';
import Error from 'common/Error';
import replaceApiIri from 'helpers/replaceApiIri';
import { Employee } from 'store/employee/interfaces';
import {
  CandidateNote,
  CandidateNoteType,
} from 'store/candidateNotes/interfaces';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';

interface NoteHeaderProps {
  candidateNote: CandidateNote;
  handleOnEdit: () => void;
  handleOnDelete: () => void;
}

export default function NoteHeader({
  candidateNote,
  handleOnEdit,
  handleOnDelete,
}: NoteHeaderProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const openDeleteModal = () => setIsDeleteDialogOpen(true);

  const {
    data: noteEmployee = {} as Employee,
    isLoading: noteEmployeeLoading,
    isError: noteEmployeeError,
  } = useGetEmployeeQuery(replaceApiIri(candidateNote.employee, 'employees'));

  const {
    data: currentEmployee = {} as Employee,
    isLoading: currentEmployeeLoading,
    isError: currentEmployeeError,
  } = useGetLoggedEmployeeQuery();

  if (noteEmployeeLoading || currentEmployeeLoading) {
    return <Loader />;
  }

  if (noteEmployeeError || currentEmployeeError) {
    return <Error />;
  }

  const isPossibleToModifyNote =
    currentEmployee.id === noteEmployee.id &&
    candidateNote.type !== CandidateNoteType.system;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography variant="body1">
            {noteEmployee.firstname} {noteEmployee.lastname}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {dateFormat(candidateNote.updatedAt, 'dd.mm.yyyy HH:MM')}
          </Typography>
        </Stack>
        {isPossibleToModifyNote && (
          <Stack direction="row" alignItems="center">
            <EditButton onClick={handleOnEdit} />
            <DeleteButton onClick={openDeleteModal} />
          </Stack>
        )}
      </Stack>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        handleAccept={handleOnDelete}
      />
    </>
  );
}
