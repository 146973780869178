import { Stack, StackProps, styled } from '@mui/material';

const StyledImageWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '32rem',
  position: 'relative',
  borderRadius: '0.4rem',
  overflow: 'hidden',
  border: `0.1rem dashed ${theme.palette.border.veryLight}`,
}));

export default StyledImageWrapper;
