import { Menu, MenuProps, styled } from '@mui/material';

const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '26rem',
    maxWidth: '100%',
    borderRadius: theme.spacing(1.5),
    transform: 'translateY(-5rem) !important',
  },
  '& .MuiList-root.MuiList-padding.MuiMenu-list': {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
}));

export default StyledMenu;
