import React from 'react';
import { IconButton, TypographyProps } from '@mui/material';
import BinIcon from 'assets/icons/BinIcon';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { useTranslation } from 'react-i18next';

interface DeleteButtonProps {
  onClick: () => void;
  color?: TypographyProps['color'];
}

function DeleteButton({ onClick, color = 'icon.main' }: DeleteButtonProps) {
  const { t } = useTranslation();

  return (
    <TitleTooltip
      title={t('label.delete')}
    >
      <IconButton onClick={onClick}>
        <BinIcon sx={{ color, fontSize: '2rem' }} />
      </IconButton>
    </TitleTooltip>
  );
}

export default DeleteButton;
