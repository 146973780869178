export const agreementType = [
  'agreement',
  'annex',
  'termination',
  'list-of-intent',
];

export const agreementVariant = ['uop', 'b2b', 'uz', 'uod'];

export const amountFrequency = [
  { label: 'Per month', value: 'per-month' },
  { label: 'Per week', value: 'per-week' },
  { label: 'Per day', value: 'per-day' },
  { label: 'Per hour', value: 'per-hour' },
];

export const amountSort = ['brutto', 'netto'];
