import React, { useState } from 'react';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { useTranslation } from 'react-i18next';
import {
  Box,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  EmployeeProjectRate,
  useUpdateProjectRateMutation,
} from 'store/employeeProjectRate/employeeProjectRate';
import { useValidate } from 'hooks/useValidate';
import CheckIcon from '@mui/icons-material/Check';
import dateFormat from 'dateformat';
import { StatusProps } from 'hoc/Status/Status';
import { Project } from 'store/projects/interfaces';
import { useGetClientsQuery } from 'store/clients/clients';
import getProjectWithClientCurrency from 'helpers/getProjectWithClientCurrency';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';
import useCheckFeature from 'hooks/useCheckFeature';
import features from 'config/features/features';

export interface EditProjectRateFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectRate: EmployeeProjectRate;
  project: Project;
}

interface Errors {
  rate: boolean;
  dateFrom: boolean;
}

function EditProjectRateForm({
  setIsOpen,
  projectRate,
  setIsError,
  setIsSuccess,
  setErrorMessage,
  project,
}: EditProjectRateFormProps) {
  const { t } = useTranslation();
  const [rate, setRate] = useState(String(projectRate.rate) ?? '');
  const [defaultPosition, setDefaultPosition] = useState(
    projectRate.position ?? '',
  );
  const [dateFrom, setDateFrom] = useState(
    dateFormat(projectRate.dateFrom, 'yyyy-mm-dd') ?? '',
  );
  const [dateTo, setDateTo] = useState(
    projectRate.dateTo ? dateFormat(projectRate.dateTo, 'yyyy-mm-dd') : '',
  );
  const [updateProjectRate, { isLoading }] = useUpdateProjectRateMutation();
  const { validate, errors } = useValidate<Errors>();
  const { data: clients = [] } = useGetClientsQuery({
    properties: ['currency', 'name'],
  });
  const isInvoiceFeatureActive = useCheckFeature(features.incomes);

  const disableSubmission = () => !rate || !dateFrom;

  const handleUpdateProjectRate = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateProjectRate({
        id: projectRate.id,
        rate: +rate,
        dateFrom: new Date(dateFrom),
        dateTo: new Date(dateTo) || null,
        position: defaultPosition || null,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        title={t('employee_project_rates.edit')}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        <form onSubmit={handleUpdateProjectRate}>
          <Box display="flex" flexDirection="column" gap={3} pt={3}>
            <FormControl disabled fullWidth required>
              <InputLabel id="project-select">
                {t('employee_project_rates.project')}
              </InputLabel>
              <Select
                id="project-select"
                label={t('employee_project_rates.project')}
                name="projectSelect"
                defaultValue={project['@id']}
              >
                <MenuItem key={project.id} value={project['@id']}>
                  {getProjectWithClientCurrency(project, clients)}
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={errors.rate}
              fullWidth
              helperText={errors.rate && t('errors.field_required')}
              id="rate"
              label={t('employee_project_rates.rate')}
              name="rate"
              onBlur={(event) =>
                validate('rate', event.target.value.length > 0)
              }
              onChange={(event) => setRate(event.target.value)}
              required
              type="number"
              value={rate}
            />
            {isInvoiceFeatureActive && (
              <TextField
                fullWidth
                id="defaultPosition"
                label={t('label.default_position')}
                name="defaultPosition"
                onChange={(event) => setDefaultPosition(event.target.value)}
                value={defaultPosition}
              />
            )}
            <Box display="flex" gap={3}>
              <TextField
                error={errors.dateFrom}
                fullWidth
                helperText={errors.dateFrom && t('errors.field_required')}
                id="date-from"
                InputLabelProps={{
                  shrink: true,
                }}
                label={t('label.date_from')}
                name="dateFrom"
                onBlur={(event) =>
                  validate('dateFrom', event.target.value.length > 0)
                }
                onChange={(event) => setDateFrom(event.target.value)}
                required
                type="date"
                value={dateFrom}
              />
              <TextField
                fullWidth
                id="date-to"
                inputProps={{
                  min: dateFrom,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label={t('label.date_to')}
                name="dateTo"
                onChange={(event) => setDateTo(event.target.value)}
                type="date"
                value={dateTo}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                disabled={disableSubmission()}
                endIcon={<CheckIcon />}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default EditProjectRateForm;
