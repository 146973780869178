import React, { useEffect } from 'react';
import { Grid, Chip, Typography } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { useDispatch } from 'react-redux';
import setBalance from 'store/condition/actions';
import { getChipColor } from '../utils';

interface BalanceProps {
  month: string;
  monthlyBalance: number;
}

export default function Balance({ month, monthlyBalance }: BalanceProps) {
  const formatAmount = useCurrencyFormat();
  const chipColor = getChipColor(month, monthlyBalance);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBalance({ collection: 'projects', month, value: monthlyBalance }),
    );
  }, [dispatch, month, monthlyBalance]);

  return (
    <>
      <Grid item xs={1.8} />
      <Grid item xs={1.2}>
        <Chip
          color={chipColor}
          label={
            <Typography variant="chipLabel">
              {formatAmount(monthlyBalance)}
            </Typography>
          }
        />
      </Grid>
    </>
  );
}
