import api from 'store/api';
import { Tag, TagMutationRequest } from './interfaces';

export const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addTag: builder.mutation<Tag, TagMutationRequest>({
      query: (tag) => ({
        url: '/tags',
        method: 'POST',
        body: tag,
      }),
      invalidatesTags: ['ResourceRequests', 'Candidates'],
    }),
    deleteTag: builder.mutation<void, string>({
      query: (id) => ({
        url: `/tags/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ResourceRequests', 'Candidates'],
    }),
  }),
});

export const { useAddTagMutation, useDeleteTagMutation } = tagsApi;
