import { enUS, enGB, ar } from 'date-fns/locale';
import { CalendarType } from 'enums';

const getCalendarTypeLocale = (startDay: number) => {
  switch (startDay) {
    case CalendarType.us:
      return enUS
    case CalendarType.iso:
      return enGB;
    case CalendarType.arabic:
      return ar;
    default:
      return enGB;
  }
}

export default getCalendarTypeLocale;
