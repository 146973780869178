import { createAction } from '@reduxjs/toolkit';
import {
  StoreInitActionPayload, UserAccount,
  UserAuthorization,
} from './interfaces';

export const storeInit = createAction<StoreInitActionPayload>('STORE_INIT');
export const authorize = createAction<UserAuthorization>('AUTHORIZE');
export const setAuthorization = createAction<string|undefined>('SET_AUTHORIZATION');
export const createAccount = createAction<UserAccount>('CREATE_ACCOUNT');
