import React from 'react';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { NavigateOptions, To, useNavigate } from 'react-router';

interface NavigationBackButtonProps {
  to: To
  options?: NavigateOptions
}

export default function NavigationBackButton({
  to,
  options,
}: NavigationBackButtonProps) {
  const navigate = useNavigate();

  return (
    <IconButton
      sx={{
        width: '3.6rem',
        height: '3.6rem',
      }}
      onClick={() => navigate(to, options)}
    >
      <ChevronLeftIcon sx={{ fontSize: '2rem', color: 'icon.dark' }} />
    </IconButton>
  );
}
