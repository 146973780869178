import React, { useState } from 'react';
import { Box, Divider, Grid, Tab, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import configurationTilesData from 'pages/Settings/configurationTilesData';
import ConfigurationTile from 'common/ConfigurationTile/ConfigurationTile';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

function Settings() {
  const [tabValue, setTabValue] = useState('1');
  const { t } = useTranslation();
  const organizationName = useSelector(
    (state: RootState) => state.organization.organization.name,
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Grid item xs={10} bgcolor="background.other">
      <Box display="flex" flexDirection="column" bgcolor="main.white" p={3}>
        <Typography variant="body1" color="secondary">
          {t('settings.workspace')}
        </Typography>
        <Typography variant="h5">{organizationName}</Typography>
      </Box>
      <Divider />
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('settings.configuration')} value="1" />
        </StyledTabList>
        <TabPanel value="1">
          <Grid container alignItems="center" spacing={3}>
            {configurationTilesData.map((tile) => (
              <ConfigurationTile
                key={tile.id}
                header={tile.header}
                description={tile.description}
                navigateTo={tile.navigateTo}
                role={tile.displayRoles}
              />
            ))}
          </Grid>
        </TabPanel>
      </TabContext>
    </Grid>
  );
}

export default Settings;
