import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeeBenefitsQuery } from 'store/employeeBenefit/employeeBenefit';
import Loader from 'common/Loader';
import Error from 'common/Error';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import { useTranslation } from 'react-i18next';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import { useGetBenefitsQuery } from 'store/benefits/benefits';
import headers from './headers';
import AddBenefit from './AddBenefit';
import Benefit from './Benefit';

export default function Benefits() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const {
    data: employeeBenefits = [],
    isLoading: employeeBenefitsFetching,
    isError: employeeBenefitsFetchError,
  } = useGetEmployeeBenefitsQuery({
    ...(id && {
      employee: [id],
    }),
  });
  const { isLoading: benefitsFetching, isError: benefitsFetchError } =
    useGetBenefitsQuery();

  if (employeeBenefitsFetching || benefitsFetching) {
    return <Loader />;
  }

  if (employeeBenefitsFetchError || benefitsFetchError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            <RowAddButton
              colSpan={headers.length + 1}
              onClick={() => setIsAddDialogOpen(true)}
            >
              <Typography variant="body2">{t('benefits.add_new')}</Typography>
            </RowAddButton>
            {employeeBenefits.length > 0 ? (
              employeeBenefits.map((benefit) => (
                <Benefit key={benefit.id} result={benefit} />
              ))
            ) : (
              <NoDataRow headers={headers} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
      >
        <AddBenefit setIsOpen={setIsAddDialogOpen} />
      </Dialog>
    </>
  );
}
