
export function getApiHost({ app }) {
  return app.config.apiHost;
}

export function getApiToken({ app }) {
  return app.config.apiToken;
}

export function isAuthorized({ app }) {
  if (!app.config.apiToken) {
    return false;
  }

  return typeof app.config.apiToken === 'string';
}
