import dateFormat from 'dateformat';

const getMonthsRange = (
  currentDate: Date,
  monthsBefore: number,
  monthsAhead: number,
) => {
  const startDate = new Date(currentDate);
  startDate.setMonth(startDate.getMonth() - monthsBefore);
  const endDate = new Date(currentDate);
  endDate.setMonth(endDate.getMonth() + monthsAhead);
  const period: string[] = [];

  const currentIterationDate = new Date(startDate);

  while (currentIterationDate <= endDate) {
    period.push(dateFormat(currentIterationDate, 'yyyy-mm'));
    currentIterationDate.setMonth(currentIterationDate.getMonth() + 1);
  }

  return period;
};

export default getMonthsRange;
