import React, { useState } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import {
  DocumentData,
  useDeleteDocumentMutation,
} from 'store/documents/documents';
import {
  Box,
  Dialog,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import DownloadIcon from '@mui/icons-material/Download';
import EditButton from 'common/EditButton/EditButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DocumentType,
  useGetDocumentTypesQuery,
} from 'store/documentTypes/documentTypes';
import EditDocumentForm from 'components/EditClientForm/Documents/EditDocumentForm';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import openFileWithAuth from 'helpers/openFileWithAuth';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { CustomError } from 'store/api';

export interface DocumentProps extends StatusProps {
  document: DocumentData;
}

const emptyDocType = {} as DocumentType;

function Document({
  document,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: DocumentProps) {
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const { docType } = useGetDocumentTypesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      docType:
        data?.find((item) => item['@id'] === document.documentType) ??
        emptyDocType,
    }),
  });
  const [deleteDocument] = useDeleteDocumentMutation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [selectedDocument, setSelectedDocument] = useState(0);

  const handleDocumentDelete = async (id: number) => {
    try {
      await deleteDocument(id).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <TableRow key={document.id}>
        <TableCell>
          <Typography variant="body2">{docType.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{document.storage.id}</Typography>
        </TableCell>
        <TableCell align="center">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <IconButton
              onClick={() =>
                downloadFileWithAuth(document.storage.url, document.storage.id)
              }
            >
              <DownloadIcon fontSize="large" />
            </IconButton>
            <IconButton onClick={() => openFileWithAuth(document.storage.url)}>
              <RemoveRedEyeOutlinedIcon fontSize="large" />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="right">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <EditButton onClick={() => setIsEditFormOpen(true)} />
            <IconButton
              onClick={() => {
                setSelectedDocument(document.id);
                setIsConfirmationDialogOpen(true);
              }}
            >
              <DeleteIcon color="error" fontSize="large" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <Dialog
        fullWidth
        onClose={() => setIsEditFormOpen(false)}
        open={isEditFormOpen}
      >
        <EditDocumentForm setIsOpen={setIsEditFormOpen} document={document} />
      </Dialog>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleAccept={() => handleDocumentDelete(selectedDocument)}
      />
    </>
  );
}

export default Document;
