import store from 'store';

const openFileWithAuth = (fileLink: string, fileFromStorage = true) => {
  const state = store.getState();
  const { organization } = state.organization;
  const token = localStorage?.token as string;

  const headers = new Headers();
  headers.append('authorization', `Bearer ${token}`);

  if (!fileFromStorage) {
    headers.append('accept', 'application/pdf');
  }

  if (organization.instance) {
    headers.append('instance', organization.instance);
  }

  fetch(fileLink, { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      const fileURL = URL.createObjectURL(blobby);
      const pdfWindow = window.open();
      pdfWindow!.location.href = fileURL;
    });
};

export default openFileWithAuth;
