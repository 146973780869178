import React, { useState } from 'react';
import { useGetDocumentTypesQuery } from 'store/documentTypes/documentTypes';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { useTranslation } from 'react-i18next';
import {
  Box,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useUploadFileMutation } from 'store/storage/storage';
import { useParams } from 'react-router-dom';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { StatusProps } from 'hoc/Status/Status';
import { useAddDocumentMutation } from 'store/documents/documents';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface AddDocumentFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddDocumentForm({
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddDocumentFormProps) {
  const { id } = useParams<{ id: any }>();
  const { t } = useTranslation();
  const [docType, setDocType] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>();
  const [description, setDescription] = useState('');
  const {
    data: types = [],
    isLoading: docTypesFetching,
    isError: docTypesFetchError,
    isSuccess: docTypesFetched,
  } = useGetDocumentTypesQuery();
  const contractorRelatedTypes = types.filter(
    (type) => type.relationName === 'contractor',
  );
  const [uploadFile] = useUploadFileMutation();
  const [addDocument, { isLoading }] = useAddDocumentMutation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    setUploadedFile(event.target.files[0]);
  };

  const handleAddDocument = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!uploadedFile) {
      return;
    }
    const fileData = new FormData();
    fileData.append('file', uploadedFile, uploadedFile.name);
    fileData.append('relationName', 'contractors');
    fileData.append('relationId', id);
    try {
      const response = await uploadFile(fileData).unwrap();
      await addDocument({
        documentType: docType,
        relationId: +id,
        storage: response.url,
        notes: description,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        title={t('employees.documents_add_new')}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        {docTypesFetchError && <Error />}
        {docTypesFetching && <Loader />}
        {docTypesFetched && (
          <form onSubmit={handleAddDocument}>
            <Box display="flex" flexDirection="column" gap={3} py={3}>
              <FormControl fullWidth required>
                <InputLabel id="type-select">
                  {t('label.document_type')}
                </InputLabel>
                <Select
                  id="type-select"
                  label={t('label.document_type')}
                  name="typeSelect"
                  value={docType}
                  onChange={(event) => setDocType(event.target.value)}
                >
                  {contractorRelatedTypes.map((contractorRelatedType) => (
                    <MenuItem
                      key={contractorRelatedType.id}
                      value={contractorRelatedType['@id']}
                    >
                      {contractorRelatedType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box>
                <InputLabel id="file-upload">
                  {t('inputs.attached_file')}
                </InputLabel>
                <TextField
                  fullWidth
                  id="file-upload"
                  inputProps={{
                    accept:
                      '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.odt,.ott,.odm,image/*',
                  }}
                  name="fileUpload"
                  onChange={handleFileChange}
                  required
                  type="file"
                />
              </Box>
              <TextField
                fullWidth
                id="description"
                label={t('label.description')}
                name="description"
                onChange={(event) => setDescription(event.target.value)}
                value={description}
              />
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  disabled={!docType || !uploadedFile}
                  endIcon={<AddCircleOutlineIcon />}
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  <span>{t('employees.documents_add')}</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </>
  );
}

export default AddDocumentForm;
