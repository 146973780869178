import api from 'store/api';
import { TagGroupsData } from './interfaces';

export const tagGroupsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTagGroups: builder.query<TagGroupsData, void>({
      query: () => '/tag-groups',
      providesTags: ['TagGroups'],
    }),
  }),
});

export const { useGetTagGroupsQuery } = tagGroupsApi;
