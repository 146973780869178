import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import {
  WorkingHours,
  WorkingHoursStats,
} from 'store/workingHours/workingHours';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmployeeOfProjectMemberEmployee } from 'hooks/useProjectMemberEmployee/interfaces';

interface EmployeeDetailsProps {
  employee: EmployeeOfProjectMemberEmployee;
  workingHours: WorkingHours[];
  stats: WorkingHoursStats;
  ratesExist: boolean;
}

function EmployeeDetails({
  stats,
  employee,
  workingHours,
  ratesExist,
}: EmployeeDetailsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hoursSum = () => {
    const employeeHours = workingHours.filter(
      (hours) => hours.employee === employee['@id'],
    );

    return employeeHours.reduce((acc, { hours }) => acc + hours, 0);
  };

  return (
    <Grid
      item
      xs={6}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={1}
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" gap={1}>
          <Typography variant="h5">
            {employee.firstname} {employee.lastname}
          </Typography>
          {!ratesExist && (
            <Chip
              color="error"
              label={
                <Typography variant="body2">
                  {t('label.missing_project_rate')}
                </Typography>
              }
              onClick={() =>
                navigate(`/employees/${employee.id}`, {
                  state: { tabValue: '6' },
                })
              }
            />
          )}
        </Box>
        <Typography
          fontWeight={500}
          variant="h5"
          color={stats.monthHoursToBeFilled <= 0 ? 'success.main' : 'error.main'}
        >
          {t('hours_view.total', {
            hours: hoursSum(),
          })}
          h
        </Typography>
        <Typography fontWeight={500} variant="h5">
          {t('hours_view.holiday_hours', {
            hours: stats.daysOffHourly,
          })}
          h
        </Typography>
        <Typography fontWeight={500} variant="h5">
          {t('hours_view.possible', {
            hours: stats.monthPossibleHours,
          })}
          h
        </Typography>
        <Typography fontWeight={500} variant="h5">
          {t('hours_view.to_be_filled', {
            hours: stats.monthHoursToBeFilled,
          })}
          h
        </Typography>
      </Box>
    </Grid>
  );
}

export default EmployeeDetails;
