import React from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

interface ContextMenuButtonProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  items: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function ContextMenuButton({
  anchorEl,
  setAnchorEl,
  items,
  onClick,
}: ContextMenuButtonProps) {
  return (
    <>
      <IconButton
        id="menu-button"
        aria-controls={anchorEl ? 'context-menu' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup="true"
        onClick={onClick}
        sx={{
          bgcolor: 'secondary.light',
          width: '3.2rem',
          height: '3.2rem',
          borderRadius: '0.8rem',
        }}
      >
        <MoreHorizIcon fontSize="large" />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        id="context-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
      >
        {items}
      </Menu>
    </>
  );
}

export default ContextMenuButton;
