import { MenuItem, MenuItemProps, styled } from '@mui/material';

const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '&:hover .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '&[aria-selected="true"] .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

export default StyledMenuItem;
