import React from 'react';
import {
  AgreementsData,
  useGetFilteredAgreementsQuery,
} from 'store/agreements/agreements';
import { TabContextProps, useTabContext } from '@mui/lab';
import {
  Pagination,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import tableHeaders from 'components/AgreementsList/tableHeaders';
import { StorageResponse } from 'store/storage/storage';
import Result from 'components/AgreementsList/Agreement/Result';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getPagesCount from 'helpers/getPagesCount';
import { AgreementsTabValue } from 'pages/Agreements/Agreements';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import AgreementStatuses from './Agreement/Status/enums';
import Filters from './Filters/Filters';


export enum AgreementType {
  AGREEMENT = 'agreement',
  ANNEX = 'annex',
  TERMINATION = 'termination',
  LIST_OF_INTENT = 'list-of-intent',
}

export enum AgreementBillingType {
  PER_HOUR = 'per-hour',
  PER_DAY = 'per-day',
  PER_WEEK = 'per-week',
  PER_MONTH = 'per-month',
}

export enum AgreementAmountType {
  BRUTTO = 'brutto',
  NETTO = 'netto',
}

export interface Agreement {
  id: number;
  employee: string;
  position: string;
  type: AgreementType;
  variant: string;
  dateFrom: string;
  dateTo: string | null;
  amount: number;
  amountType: AgreementAmountType;
  billingType: AgreementBillingType;
  hoursPerWeek: number;
  cost: string;
  files: StorageResponse[];
  active: boolean;
  status: AgreementStatuses;
  currency: string;
}

interface AgreementsListProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

function AgreementsList({ page, setPage }: AgreementsListProps) {
  const { value: tabValue } = useTabContext() as TabContextProps;
  const [searchParams] = useSearchParams({});

  const {
    data: agreementsData = {} as AgreementsData,
    isFetching: agreementsFetching,
    isError: agreementsError,
  } = useGetFilteredAgreementsQuery({
    page,
    isActive: tabValue === AgreementsTabValue.active,
    ...(searchParams.get('type[]') && {
      type: searchParams.getAll('type[]'),
    }),
    ...(searchParams.get('variant[]') && {
      variant: searchParams.getAll('variant[]'),
    }),
  });
  const { t } = useTranslation();

  return (
    <>
      <Filters />
      <TableContainer sx={{ px: 3, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={tableHeaders.length} />
            </TableRow>
          </TableHead>
          <TableBodyContent
            isError={agreementsError}
            isFetching={agreementsFetching}
            tableHeaders={tableHeaders}
          >
            {agreementsData?.['hydra:member']?.map((agreement) => (
              <Result key={agreement.id} agreement={agreement} />
            ))}
          </TableBodyContent>
        </Table>
      </TableContainer>
      {agreementsData?.['hydra:view']?.['hydra:last'] && (
        <Pagination
          color="primary"
          count={getPagesCount(agreementsData['hydra:view']['hydra:last'])}
          page={page}
          onChange={(event, value: number) => {
            setPage(value);
            window.scroll({ top: 0, behavior: 'smooth' });
          }}
          sx={{ display: 'flex', justifyContent: 'center', py: 2 }}
        />
      )}
    </>
  );
}

export default AgreementsList;
