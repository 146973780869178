import { useSelector } from 'react-redux';
import { useGetBudgetsQuery } from 'store/budget/budget';
import { getOrganization } from 'store/organizations/selectors';
import { Budget } from 'store/budget/interfaces';
import useCheckBudgetIsEnabled from './useCheckBudgetIsEnabled';

export interface BudgetPreview
  extends Pick<Budget, '@id' | 'name' | 'type' | 'amount'> {}

interface BudgetPreviewResponse {
  budgetPreviews: BudgetPreview[];
  isError: boolean;
  isLoading: boolean;
}

export default function useBudgetPreview(): BudgetPreviewResponse {
  const isBudgetEnabled = useCheckBudgetIsEnabled();
  const selectedOrganizationId = localStorage.getItem('selectedOrganizationId');

  const organization = useSelector(getOrganization);

  const {
    data: budgetsData = {
      'hydra:member': [],
    },
    isError,
    isLoading,
  } = useGetBudgetsQuery(
    {
      properties: ['name', 'type', 'amount'],
    },
    {
      skip:
        !isBudgetEnabled || !selectedOrganizationId || !organization.instance,
    },
  );

  return {
    budgetPreviews: budgetsData['hydra:member'],
    isError,
    isLoading,
  };
}
