import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';

export interface AddNoteButtonProps {
  isUserRequested: boolean;
  setIsAddFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedEmployee: React.Dispatch<React.SetStateAction<string>>;
  employeeIri: string;
}

export default function AddNoteButton({
  isUserRequested,
  setSelectedEmployee,
  setIsAddFormOpen,
  employeeIri,
}: AddNoteButtonProps) {
  const { t } = useTranslation();

  if (!isUserRequested) {
    return (
      <Button
        endIcon={<AddCircleOutlineIcon />}
        onClick={() => {
          setSelectedEmployee(employeeIri);
          setIsAddFormOpen(true);
        }}
        variant="outlined"
      >
        {t('label.add_note')}
      </Button>
    );
  }

  return (
    <TitleTooltip title={t('feedbacks.user_requested')}>
      <Button
        endIcon={<AddCircleOutlineIcon />}
        onClick={() => {
          setSelectedEmployee(employeeIri);
          setIsAddFormOpen(true);
        }}
        variant="contained"
      >
        {t('label.add_note')}
      </Button>
    </TitleTooltip>
  );
}
