import React, { useState } from 'react';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { useTranslation } from 'react-i18next';
import { StatusProps } from 'hoc/Status/Status';
import {
  Box,
  DialogContent,
  FormGroup,
  IconContainerProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LoadingButton } from '@mui/lab';
import { useAddFeedbackMutation } from 'store/feedbacks/feedbacks';
import { CustomError } from 'store/api';
import { FeedbackVisibility } from 'enums';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import { LoggedEmployee } from 'store/employee/interfaces';
import createApiIri from 'helpers/createApiIri';
import rates from './rates';
import StyledSwitch from './Switch.styled';
import StyledRating from './Rating.styled';

export interface AddFeedbackFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEmployee: string;
}

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <span {...other}>{rates[value]?.icon}</span>
  );
}

export default function AddFeedbackForm({
  setIsOpen,
  setIsSuccess,
  setErrorMessage,
  setIsError,
  selectedEmployee,
}: AddFeedbackFormProps) {
  const { t } = useTranslation();
  const { data: currentUser = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery();
  const [addFeedback, { isLoading }] = useAddFeedbackMutation();
  const [description, setDescription] = useState('');
  const [rate, setRate] = useState<number | null>(2);
  const [visibility, setVisibility] = useState(true);

  const handleAddFeedback = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addFeedback({
        employee: selectedEmployee,
        authorEmployee: createApiIri('employees', `${currentUser.id}`),
        description,
        visibility: visibility
          ? FeedbackVisibility.visible
          : FeedbackVisibility.private,
        rate: rate && rates[rate].value,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('feedbacks.add')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleAddFeedback}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={3}
          >
            <StyledRating
              getLabelText={(value: number) => rates[value]?.label}
              highlightSelectedOnly
              IconContainerComponent={IconContainer}
              name="rateSelect"
              onChange={(event, newValue) => {
                setRate(newValue);
              }}
              value={rate}
              max={4}
            />
            <TextField
              fullWidth
              label={t('label.description')}
              name="description"
              minRows={5}
              multiline
              onChange={(event) => setDescription(event.target.value)}
              required
              value={description}
            />
            <FormGroup>
              <Stack
                component="label"
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="body1" sx={{ cursor: 'pointer' }}>
                  {t('feedbacks.private')}
                </Typography>
                <StyledSwitch
                  checked={visibility}
                  onChange={() => setVisibility((prevState) => !prevState)}
                />
                <Typography variant="body1" sx={{ cursor: 'pointer' }}>
                  {t('feedbacks.visible')}
                </Typography>
              </Stack>
            </FormGroup>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <LoadingButton
                disabled={!rate || !description}
                endIcon={<AddCircleOutlineIcon />}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
