import React, { useState } from 'react';
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useAddEmployeeMutation } from 'store/employee/employees';
import { useNavigate } from 'react-router-dom';
import { useValidate } from 'hooks/useValidate';
import { useTranslation } from 'react-i18next';
import validMailSigns from 'helpers/ValidationRegex';
import { Close, Add } from '@mui/icons-material';
import { CustomError } from 'store/api';
import { StatusProps } from 'hoc/Status/Status';
import { LoadingButton } from '@mui/lab';

export interface Errors {
  firstname: boolean;
  lastname: boolean;
  companyEmail: boolean;
  currentHolidayDaysLimit: boolean;
  contactEmail: boolean;
  contactPhone: boolean;
}

export interface AddEmployeeFormProps extends StatusProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddEmployeeForm({
  setIsSuccess,
  setIsError,
  isOpen,
  setIsOpen,
  setErrorMessage,
}: AddEmployeeFormProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const [addEmployee, { isLoading }] = useAddEmployeeMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const disableSubmission = () =>
    Object.values(errors).some((error) => error) ||
    !firstName ||
    !lastName ||
    !companyEmail;

  const handleAddUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const addedEmployee = await addEmployee({
        firstname: firstName,
        lastname: lastName,
        companyEmail,
        contactEmail,
        contactPhone,
      }).unwrap();
      setIsSuccess(true);
      navigate(`/employees/${addedEmployee.id}`);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box p={4}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Typography variant="h5">{t('employees.add_header')}</Typography>
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            width: '4rem',
            height: '4rem',
            bgcolor: 'secondary.light',
            borderRadius: '50%',
          }}
        >
          <Close fontSize="large" />
        </IconButton>
      </Box>
      <form onSubmit={handleAddUser}>
        <FormControl fullWidth>
          <TextField
            required
            fullWidth
            error={errors.firstname}
            helperText={errors.firstname && t('errors.too_short_name')}
            name="firstname"
            variant="outlined"
            label={t('label.firstname')}
            id="name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            onBlur={(event) =>
              validate('firstname', event.target.value.length >= 2)
            }
            sx={{ marginBottom: '2.4rem' }}
          />
          <TextField
            required
            fullWidth
            error={errors.lastname}
            helperText={errors.lastname && t('errors.too_short_name')}
            name="lastname"
            variant="outlined"
            label={t('label.lastname')}
            id="surname"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            onBlur={(event) =>
              validate('lastname', event.target.value.length >= 2)
            }
            sx={{ marginBottom: '2.4rem' }}
          />
          <TextField
            required
            fullWidth
            error={errors.companyEmail}
            helperText={errors.companyEmail && t('errors.wrong_mail')}
            name="companyEmail"
            variant="outlined"
            label={t('inputs.company_email')}
            id="company-email"
            value={companyEmail}
            onChange={(event) => setCompanyEmail(event.target.value)}
            onBlur={(event) =>
              validate(
                'companyEmail',
                Boolean(event.target.value.match(validMailSigns)),
              )
            }
            sx={{ marginBottom: '2.4rem' }}
          />
          <TextField
            fullWidth
            error={errors.contactEmail}
            helperText={errors.contactEmail && t('errors.wrong_mail')}
            name="contactEmail"
            variant="outlined"
            label={t('inputs.contact_email')}
            id="contact-email"
            value={contactEmail}
            onChange={(event) => setContactEmail(event.target.value)}
            onBlur={(event) =>
              validate(
                'contactEmail',
                Boolean(event.target.value.match(validMailSigns)) ||
                  !event.target.value,
              )
            }
            sx={{ marginBottom: '2.4rem' }}
          />
          <TextField
            fullWidth
            type="tel"
            name="contactPhone"
            variant="outlined"
            label={t('inputs.contact_phone')}
            id="contact-phone"
            value={contactPhone}
            onChange={(event) => setContactPhone(event.target.value)}
            sx={{ marginBottom: '2.4rem' }}
          />
        </FormControl>
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            disabled={disableSubmission()}
            endIcon={<Add />}
            loading={isLoading}
            type="submit"
            variant="contained"
          >
            <span>{t('button.save')}</span>
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}

export default AddEmployeeForm;
