import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGetHolidayTypesQuery } from 'store/holidayTypes/holidayTypes';
import { HolidayTypes } from 'store/holidayTypes/interfaces';
import { useTranslation } from 'react-i18next';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import tableHeaders from './tableHeaders';
import HolidayType from './HolidayType/HolidayType';

export default function HolidayTypesList() {
  const {
    data: holidayTypes = {} as HolidayTypes,
    isLoading: holidayTypesFetching,
    isError: holidayTypesFetchError,
  } = useGetHolidayTypesQuery();
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBodyContent
          isError={holidayTypesFetchError}
          isFetching={holidayTypesFetching}
          tableHeaders={tableHeaders}
        >
          {holidayTypes['hydra:member']?.map((holidayType) => (
            <HolidayType key={holidayType.id} holidayType={holidayType} />
          ))}
        </TableBodyContent>
      </Table>
    </TableContainer>
  );
}
