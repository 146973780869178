import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, Typography } from '@mui/material';
import { Transaction } from 'store/transactions/transactions';
import AttachmentsDialog from 'components/TransactionsList/Attachment/AttachmentsDialog';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export interface AttachmentProps {
  transaction: Transaction;
}

function Attachment({ transaction }: AttachmentProps) {
  const [attachmentDialogVisible, setAttachmentDialogVisible] = useState(false);
  const { t } = useTranslation();
  const attachmentsCount = transaction.transactionAttachments.length;

  if (!transaction.cost) {
    return <Typography variant="body1">{t('general.placeholder')}</Typography>;
  }

  if (!transaction.cost.requireAttachment) {
    return (
      <Typography variant="body1">
        {t('transactions.attachment_not_required')}
      </Typography>
    );
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
        onClick={() => setAttachmentDialogVisible(true)}
        sx={{ cursor: 'pointer' }}
      >
        <Typography
          color={attachmentsCount ? 'success.main' : 'error'}
          variant="bold"
        >
          {t('transactions.attachments_required', {
            count: attachmentsCount,
          })}
        </Typography>
        <ArrowDownwardIcon color={attachmentsCount ? 'success' : 'error'} />
      </Box>
      <Dialog
        maxWidth="lg"
        fullWidth
        onClose={() => setAttachmentDialogVisible(false)}
        open={attachmentDialogVisible}
      >
        <AttachmentsDialog
          setIsOpen={setAttachmentDialogVisible}
          transaction={transaction}
        />
      </Dialog>
    </>
  );
}

export default Attachment;
