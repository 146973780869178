import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Budget } from 'store/budget/interfaces';
import { EmployeePreview } from 'store/employee/interfaces';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import BudgetCostsList from 'components/BudgetCostsList/BudgetCostsList';
import ActionOutlinedButton from 'common/ActionOutlinedButton/ActionOutlinedButton';
import BudgetSummaryChart from './BudgetSummaryChart/BudgetSummaryChart';

export interface BudgetSummaryProps {
  budget: Budget;
  employees: EmployeePreview[];
  isLoading: boolean;
  isFetchError: boolean;
}
export default function BudgetSummary({
  budget,
  employees,
  isLoading,
  isFetchError,
}: BudgetSummaryProps) {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <StyledContentBox>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h6">
                  {t('budget.budget_summary')}
                </Typography>
                <ActionOutlinedButton
                  label="budget.budget_planner"
                  onClick={() => navigate(`/budget/${id}/planner`)}
                />
              </Stack>
              <BudgetSummaryChart budget={budget} />
            </Grid>
          </Grid>
        </Box>
      </StyledContentBox>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BudgetCostsList
              budget={budget}
              tableTitle={t('budget.budget_latest_costs')}
              type="latest"
              employees={employees}
              isLoading={isLoading}
              isFetchError={isFetchError}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
