import React, { useState } from 'react';
import { Box, DialogContent, TextField } from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LoadingButton } from '@mui/lab';
import { useAddResponsibilityMutation } from 'store/responsibilityGroups/responsibilityGroups';
import { ResponsibilityGroup } from 'store/responsibilityGroups/interfaces';
import { CustomError } from 'store/api';
import { StatusProps } from 'hoc/Status/Status';
import { useValidate } from 'hooks/useValidate';

export interface AddResponsibilityProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  group: ResponsibilityGroup;
}

interface Errors {
  name: boolean;
  description: boolean;
}

export default function AddResponsibility({
  setIsOpen,
  setIsSuccess,
  setErrorMessage,
  setIsError,
  group,
}: AddResponsibilityProps) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [addResponsibility, { isLoading }] = useAddResponsibilityMutation();
  const { errors, validate } = useValidate<Errors>();

  const disableSubmission = () =>
    !name || !description || Object.values(errors).some((error) => error);

  const handleAddResponsibility = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await addResponsibility({
        name,
        description,
        responsibilityGroup: group['@id'],
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        setIsOpen={setIsOpen}
        title={t('responsibilities.add_new')}
      />
      <DialogContent>
        <form onSubmit={handleAddResponsibility}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={3}
            mt={3}
          >
            <TextField
              error={errors.name}
              fullWidth
              helperText={errors.name && t('errors.too_short_name')}
              label={t('label.name')}
              name="name"
              onBlur={(event) =>
                validate('name', event.target.value.length >= 2)
              }
              onChange={(event) => setName(event.target.value)}
              required
              value={name}
            />
            <TextField
              error={errors.description}
              fullWidth
              helperText={
                errors.description && t('errors.too_short_description')
              }
              label={t('label.description')}
              minRows={3}
              multiline
              name="description"
              onBlur={(event) =>
                validate('description', event.target.value.length >= 2)
              }
              onChange={(event) => setDescription(event.target.value)}
              required
              value={description}
            />
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {t('button.save')}
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
