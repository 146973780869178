import { styled, Box, BoxProps } from '@mui/material';

export const Container = styled(Box)<BoxProps>({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-end',
  gap: '0.4rem',
  padding: '0 0.8rem',
  zIndex: 1,
});

export const Item = styled(Box)<BoxProps>(({ theme }) => ({
  border: '0.1rem solid',
  borderColor: theme.palette.border.main,
  borderRadius: '0.4rem',
  padding: '0.65rem',
}));
