import React from 'react';
import ReactEChart from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { Budget } from 'store/budget/interfaces';
import getCharOptions from 'config/material-ui/getChartOptions';
import useFormatBudgetChartPeriod from 'hooks/useFormatBudgetChartPeriod';
import theme from 'config/material-ui/theme';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import createBudgetPeriodStats from './createBudgetPeriodStats';
import getChartData from './getChartData';

interface BudgetSummaryChartProps {
  budget: Budget;
}

export default function BudgetSummaryChart({
  budget,
}: BudgetSummaryChartProps) {
  const { t } = useTranslation();
  const formatPeriod = useFormatBudgetChartPeriod();
  const formatAmount = useCurrencyFormat();

  const budgetPeriodStats = createBudgetPeriodStats(budget);
  const { xAxisData, seriesData, maxSeriesTotalStackValue } =
    getChartData(budgetPeriodStats);
  const topIndentionValue = maxSeriesTotalStackValue / 40;

  const chartOptions = getCharOptions({
    xAxisData,
    series: [
      {
        data: seriesData.map(({ usedAmount }) =>
          usedAmount !== 0 ? (usedAmount - topIndentionValue).toFixed(2) : '-',
        ),
        type: 'bar',
        stack: 'amount',
        name: t('budget.used_amount'),
        barWidth: 60,
        color: theme.palette.background.blueBar,
        itemStyle: {
          normal: {
            barBorderRadius: [8, 8, 8, 8],
          },
        },
      },
      {
        name: 'topIndention',
        stack: 'amount',
        type: 'bar',
        color: theme.palette.common.white,
        barWidth: 60,
        data: seriesData.map(({ usedAmount }) =>
          usedAmount !== 0 ? topIndentionValue.toFixed(0) : '-',
        ),
        tooltip: { show: false },
      },
      {
        data: seriesData.map((data) => data.restAmount.toFixed(2)),
        type: 'bar',
        stack: 'amount',
        name: t('budget.rest_amount'),
        barWidth: 60,
        color: theme.palette.background.deepPurpleBarMain,
        itemStyle: {
          normal: {
            barBorderRadius: [8, 8, 0, 0],
          },
        },
      },
      {
        data: seriesData.map((data) =>
          data.amountOverLimit ? data.amountOverLimit.toFixed(2) : '-',
        ),
        type: 'bar',
        stack: 'amount',
        name: t('budget.amount_over_limit'),
        barWidth: 60,
        color: theme.palette.background.orangeBarMain,
        label: {
          position: 'top',
          align: 'center',
          verticalAlign: 'middle',
          distance: 25,
          color: theme.palette.warning.main,
          formatter: (params) => {
            if (params.value === '-' || typeof params.value !== 'string') {
              return '';
            }
            return `+ ${formatAmount(+params.value)}`;
          },
          backgroundColor: theme.palette.warning.selected,
          padding: [5, 8],
          fontSize: 13,
          borderRadius: 20,
          borderWidth: 1,
          borderColor: theme.palette.border.warningOutlined,
          show: true,
        },
        itemStyle: {
          normal: {
            barBorderRadius: [8, 8, 0, 0],
          },
        },
      },
    ],
    aria: {
      enabled: true,
      decal: {
        show: true,
        decals: [
          {
            symbol: 'none',
          },
          {
            symbol: 'none',
          },
          {
            color: theme.palette.background.deepPurpleBarLight,
            dashArrayX: [1, 0],
            dashArrayY: [8, 7],
            rotation: -Math.PI / 4,
          },
          {
            color: theme.palette.background.orangeBarLight,
            dashArrayX: [1, 0],
            dashArrayY: [8, 7],
            rotation: -Math.PI / 4,
          },
        ],
      },
    },
    xAxisLabelFormatter: formatPeriod,
    tooltip: {
      trigger: 'axis',
      formatter(params) {
        let tooltipText = '';
        params.forEach((param) => {
          if (
            param.seriesName === t('budget.used_amount') &&
            param.data !== '-'
          ) {
            const originalUsedAmount = seriesData[param.dataIndex].usedAmount;
            tooltipText += `${param.marker} ${
              param.seriesName
            }: ${originalUsedAmount.toFixed(2)}<br/>`;
          } else if (param.data !== '-') {
            tooltipText += `${param.marker} ${param.seriesName}: ${param.data}<br/>`;
          }
        });
        return tooltipText;
      },
    },
  });

  return (
    <Stack>
      <Box height="33rem">
        <ReactEChart
          option={chartOptions}
          opts={{ renderer: 'svg' }}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Stack>
  );
}
