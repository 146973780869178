import { EmployeePreview } from 'store/employee/interfaces';
import { CostGroupProps } from 'store/costGroups/costGroups';
import { BudgetPreview } from 'hooks/useBudgetPreview';

interface GetSelectOptionsProps {
  budgetPreviews: BudgetPreview[];
  employees: EmployeePreview[];
  costGroups: CostGroupProps[];
}
export default function getSelectOptions({
  budgetPreviews,
  employees,
  costGroups,
}: GetSelectOptionsProps) {
  const costEmployeeOptions = employees.map((employee) => ({
    value: employee['@id'],
    label: `${employee.firstname} ${employee.lastname}`,
  }));
  const costBudgetOptions = budgetPreviews.map((budgetData) => ({
    value: budgetData['@id'],
    label: budgetData.name,
  }));
  const costGroupsOptions = costGroups.map((cost) => ({
    value: cost['@id'],
    label: cost.name,
  }));

  return {
    costBudgetOptions,
    costEmployeeOptions,
    costGroupsOptions,
  };
}
