const currenciesData = [
  {
    id: 'pln',
    name: 'currencies.pln',
  },
  {
    id: 'eur',
    name: 'currencies.eur',
  },
  {
    id: 'usd',
    name: 'currencies.usd',
  },
];

export default currenciesData;
