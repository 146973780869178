import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  CostGroupProps,
  useGetCostGroupChartQuery,
  useGetCostGroupQuery,
} from 'store/costGroups/costGroups';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import Loader from 'common/Loader';
import Error from 'common/Error';
import GroupChart from 'components/CostGroupChart/GroupChart/GroupChart';
import { skipToken } from '@reduxjs/toolkit/query';

function CostGroupChart() {
  const { id } = useParams<{ id: string }>();
  const { isLoading, isError } = useGetCostGroupChartQuery(id ?? skipToken);
  const {
    data: costGroup = {} as CostGroupProps,
    isLoading: groupFetching,
    isError: groupFetchError,
  } = useGetCostGroupQuery(id ? +id : skipToken);

  if (isLoading || groupFetching) {
    return <Loader />;
  }

  if (isError || groupFetchError) {
    return <Error />;
  }

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{costGroup.name}</NavBarTitle>
      </NavBarContainer>
      <GroupChart />
    </Grid>
  );
}

export default CostGroupChart;
