import React, { useEffect, useState } from 'react';
import {
  Box,
  CardMedia,
  Dialog,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import dateFormat from 'dateformat';
import { WorkingHours } from 'store/workingHours/workingHours';
import AddWorkingHoursForm from 'components/AddWorkingHoursForm';
import { Holiday } from 'store/holidays/interfaces';
import { useTranslation } from 'react-i18next';
import freeDayBg from 'assets/img/Frame 45.png';
import StyledButton from 'components/CalendarDayTile/Button.styled';
import LoggedHours from 'components/CalendarDayTile/LoggedHours/LoggedHours';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import EditWorkingHoursForm from 'components/EditWorkingHoursForm';
import { HolidayTypes } from 'enums';
import { getHolidayIcon } from 'helpers/getHolidayData';
import RemoteCheck from './RemoteCheck/RemoteCheck';

interface CalendarDayTileProps {
  day: Date;
  workingHours: WorkingHours[];
  holidays: string[];
  employeeHolidays: Holiday[];
  displayedMonth: number;
  displayedEmployee: string;
  currentUserIri: string;
  remoteHolidays: Holiday[];
}

function CalendarDayTile({
  day,
  workingHours,
  holidays,
  employeeHolidays,
  displayedMonth,
  displayedEmployee,
  currentUserIri,
  remoteHolidays,
}: CalendarDayTileProps) {
  const { t } = useTranslation();
  const [dayHours, setDayHours] = useState<WorkingHours[]>([]);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [entryId, setEntryId] = useState(0);
  const tileDate = dateFormat(day, 'yyyy-mm-dd');
  const isManager = useCheckRoles(roles.workingHoursManager);
  const utcFormattedDay = new Date(
    Date.UTC(day.getFullYear(), day.getMonth(), day.getDate()),
  );

  const remoteHoliday = remoteHolidays.find(
    (holiday) =>
      utcFormattedDay >= new Date(holiday.dateFrom) &&
      utcFormattedDay <= new Date(holiday.dateTo),
  );

  useEffect(() => {
    setDayHours(
      workingHours.filter(
        (hours) => dateFormat(hours.date, 'yyyy-mm-dd') === tileDate,
      ),
    );
  }, [tileDate, workingHours]);

  const hoursSumInDay = dayHours.reduce(
    (startValue, { hours }) => startValue + hours,
    0,
  );

  const weekdayIndex = day.getDay();
  const isSaturday = weekdayIndex === 6;
  const isSunday = weekdayIndex === 0;
  const isHoliday = holidays.includes(dateFormat(day, 'yyyy-mm-dd'));
  const filteredHolidays = employeeHolidays.filter(
    (employeeHoliday) =>
      utcFormattedDay >= new Date(employeeHoliday.dateFrom) &&
      utcFormattedDay <= new Date(employeeHoliday.dateTo) &&
      employeeHoliday.type !== HolidayTypes.remote,
  );
  const isToday = dateFormat(new Date(), 'yyyy-mm-dd') === tileDate;
  const freeDay =
    isSaturday || isSunday || isHoliday || filteredHolidays.length > 0;

  if (day.getMonth() !== displayedMonth) {
    return <Grid item xs={2} border="0.1rem solid" borderColor="border.main" />;
  }

  return (
    <>
      <Grid
        display="flex"
        flexDirection="column"
        item
        xs={2}
        border={isToday ? '0.2rem solid' : '0.1rem solid'}
        borderColor={isToday ? 'primary.main' : 'border.main'}
        position="relative"
        minHeight="14rem"
      >
        {freeDay && (
          <Box position="absolute" width="100%" height="100%" top={0} left={0}>
            <CardMedia
              component="img"
              src={freeDayBg}
              alt="frame"
              sx={{ height: '100%' }}
            />
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          height="100%"
          alignItems="baseline"
          zIndex={1}
          p={2}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5" color={isToday ? 'primary' : 'secondary'}>
              {dateFormat(new Date(day), 'dd')}
            </Typography>
            {!freeDay && displayedEmployee === currentUserIri && (
              <RemoteCheck
                currentUserIri={currentUserIri}
                tileDate={tileDate}
                remoteHoliday={remoteHoliday}
              />
            )}
            {filteredHolidays.length > 0 && (
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: 'text.primary',
                      bgcolor: 'main.white',
                      boxShadow: 5,
                    },
                  },
                }}
                title={
                  <Typography variant="body1">
                    {filteredHolidays[0].type}
                  </Typography>
                }
              >
                <Box zIndex={1}>{getHolidayIcon(filteredHolidays[0].type)}</Box>
              </Tooltip>
            )}
          </Stack>
          <Typography variant="subtitle1" color="secondary">
            {hoursSumInDay}h
          </Typography>
        </Box>
        <LoggedHours
          dayHours={dayHours}
          weekdayIndex={weekdayIndex}
          date={day}
          setIsEditFormOpen={setIsEditFormOpen}
          setEntryId={setEntryId}
          userCanEdit={
            (isManager || currentUserIri === displayedEmployee) &&
            displayedEmployee !== ''
          }
        />
        <Box width="100%" pt={0.5} pb={1} px={1}>
          {currentUserIri === displayedEmployee && (
            <StyledButton
              disabled={!displayedEmployee}
              onClick={() => setIsAddFormOpen(true)}
            >
              {t('project_tracking.new_entry')}
            </StyledButton>
          )}
        </Box>
      </Grid>
      <Dialog
        open={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        fullWidth
      >
        <AddWorkingHoursForm
          employeeIri={displayedEmployee}
          date={tileDate}
          setIsOpen={setIsAddFormOpen}
        />
      </Dialog>
      <Dialog
        open={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
        fullWidth
      >
        <EditWorkingHoursForm
          employeeIri={displayedEmployee}
          date={tileDate}
          setIsOpen={setIsEditFormOpen}
          entryId={entryId}
        />
      </Dialog>
    </>
  );
}

export default CalendarDayTile;
