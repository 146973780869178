import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import headers from 'components/PaymentDueList/headers';
import { Cost } from 'store/costs/costs';
import PeriodPayment from 'components/PaymentDueList/PeriodPayment/PeriodPayment';
import getAmountMonthsBack from 'helpers/getAmountMonthsBack';

export interface PaymentDueListProps {
  contractors: Cost[];
}

function PaymentDueList({ contractors }: PaymentDueListProps) {
  const { t } = useTranslation();
  const periods = getAmountMonthsBack(new Date(), 6);
  const navbarHeight = '8rem';

  return (
    <TableContainer
      sx={{ px: 3, maxHeight: `calc(100vh - ${navbarHeight})`, mt: 3 }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                sx={{
                  backgroundColor: 'background.other',
                }}
                key={header.label}
                align={header.align}
              >
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            {periods.map((period) => (
              <TableCell
                sx={{
                  backgroundColor: 'background.other',
                }}
                key={period}
                align="center"
              >
                <Typography variant="tableHeader" color="text.secondary">
                  {t(period)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {contractors.map((contractor) => (
            <TableRow key={contractor.id}>
              <TableCell>
                <Typography variant="body2">{contractor.name}</Typography>
              </TableCell>
              {periods.map((period) => (
                <TableCell key={period} align="center">
                  <PeriodPayment period={period} contractor={contractor.name} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PaymentDueList;
