import React, { useState } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  TransactionAttachment,
  useUpdateTransactionAttachmentMutation,
} from 'store/transactions/transactions';
import { useGetCostsQuery } from 'store/costs/costs';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';
import replaceApiIri from 'helpers/replaceApiIri';

export interface AwaitingAttachmentsProps extends StatusProps {
  transactionIri: string;
  attachments: TransactionAttachment[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AwaitingAttachments({
  transactionIri,
  attachments,
  setIsSuccess,
  setIsError,
  setErrorMessage,
  setIsOpen,
}: AwaitingAttachmentsProps) {
  const { t, i18n } = useTranslation();
  const [awaitingAttachment, setAwaitingAttachment] = useState('');
  const { data: costs = [] } = useGetCostsQuery({ properties: ['id', 'name'] });
  const [updateAttachment, { isLoading }] =
    useUpdateTransactionAttachmentMutation();

  const getItemLabel = (attachment: TransactionAttachment) => {
    const transactionCost = costs.filter(
      (cost) => cost['@id'] === attachment.cost,
    );

    return `${transactionCost[0].name} (${
      attachment.amount / 100
    } ${replaceApiIri(attachment.currency, 'currencies')})`;
  };

  const sortedAttachmentsByLabel = attachments
    .map((attachment) => ({
      ...attachment,
      label: getItemLabel(attachment),
    }))
    .sort((labeledAttachment, nextLabeledAttachment) =>
      labeledAttachment.label.localeCompare(
        nextLabeledAttachment.label,
        i18n.language || 'en',
        { sensitivity: 'base' },
      ),
    );

  const handleAttach = async () => {
    try {
      await updateAttachment({
        id: +awaitingAttachment,
        transaction: transactionIri,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
      <Typography variant="h5">
        {t('transactions.awaiting_attachments')}
      </Typography>
      <Box display="flex" alignItems="center" gap={3}>
        <FormControl fullWidth>
          <Select
            displayEmpty
            id="awaiting-attachments-select"
            value={awaitingAttachment}
            onChange={(event) => setAwaitingAttachment(event.target.value)}
          >
            <MenuItem value="" disabled>
              <em>{t('transactions.choose_attachment')}</em>
            </MenuItem>
            {sortedAttachmentsByLabel.map((labeledAttachment) => (
              <MenuItem key={labeledAttachment.id} value={labeledAttachment.id}>
                {getItemLabel(labeledAttachment)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LoadingButton
          loading={isLoading}
          onClick={handleAttach}
          variant="contained"
          disabled={!awaitingAttachment}
          data-testid="awaiting-attachments-attach-button"
        >
          <span>{t('button.attach')}</span>
        </LoadingButton>
      </Box>
    </Grid>
  );
}

export default AwaitingAttachments;
