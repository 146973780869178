import { useContext } from 'react';

import {
  BookingSchedulerContext,
  BookingSchedulerContextProps,
} from './BookingScheduler.context';

function useBookingSchedulerContext(): BookingSchedulerContextProps {
  const context = useContext(BookingSchedulerContext);

  if (!context) {
    throw new Error(
      'useBookingSchedulerContext must be used within a BookingSchedulerProvider',
    );
  }

  return context;
}

export default useBookingSchedulerContext;
