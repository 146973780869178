const contactTypes = [
  {
    label: 'label.contact',
    value: 'contact',
  },
  {
    label: 'label.billing',
    value: 'billing',
  },
  {
    label: 'label.debt_collection',
    value: 'debt-collection',
  },
];

export default contactTypes;
