import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LoadingButton } from '@mui/lab';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import { useAddPersonMutation } from 'store/responsibilityGroups/responsibilityGroups';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { Responsibility } from 'store/responsibilityGroups/interfaces';

export interface PeopleProps extends StatusProps {
  responsibility: Responsibility;
}

export default function People({
  setIsSuccess,
  setErrorMessage,
  setIsError,
  responsibility,
}: PeopleProps) {
  const { t } = useTranslation();
  const { data: employees = [] } = useGetEmployeesPreview();
  const [selectedEmployeeIri, setSelectedEmployeeIri] = useState('');
  const [percentage, setPercentage] = useState('');
  const [addPerson, { isLoading }] = useAddPersonMutation();

  const percentageSum = responsibility.responsibilityEmployees.reduce(
    (acc, employee) => acc + employee.percentage,
    0,
  );

  useEffect(() => {
    if (responsibility.responsibilityEmployees.length === 0) {
      setPercentage('100');
    } else {
      setPercentage(String(100 - percentageSum));
    }
  }, [responsibility, percentageSum]);

  const handleAddPerson = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addPerson({
        employee: selectedEmployeeIri,
        percentage: +percentage,
        responsibility: responsibility['@id'],
      }).unwrap();
      setIsSuccess(true);
      setSelectedEmployeeIri('');
      setPercentage('');
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <form onSubmit={handleAddPerson}>
      <Box display="flex" alignItems="center" gap={1} mt={2}>
        <FormControl required sx={{ width: '70%' }}>
          <InputLabel id="person-select">{t('label.person')}</InputLabel>
          <Select
            labelId="person-select"
            label={t('person')}
            name="personSelect"
            onChange={(event) => setSelectedEmployeeIri(event.target.value)}
            value={selectedEmployeeIri}
          >
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee['@id']}>
                {employee.lastname} {employee.firstname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          inputProps={{
            min: 0,
            max: 100,
          }}
          label={t('label.percent')}
          name="percent"
          onChange={(event) => setPercentage(event.target.value)}
          required
          sx={{ width: '30%' }}
          type="number"
          value={percentage}
        />
        <LoadingButton
          disabled={!selectedEmployeeIri || !percentage}
          endIcon={<AddCircleOutlineIcon />}
          loading={isLoading}
          type="submit"
          variant="contained"
        >
          {t('button.add')}
        </LoadingButton>
      </Box>
    </form>
  );
}
