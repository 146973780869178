import React from 'react';
import { Box } from '@mui/material';
import ReactEChart from 'echarts-for-react';
import getAmountMonthsBack from 'helpers/getAmountMonthsBack';
import {
  CostGroupChartData,
  useGetCostGroupChartQuery,
} from 'store/costGroups/costGroups';
import { SeriesChart } from 'components/CostsChart/interfaces';
import { useParams } from 'react-router-dom';

function GroupChart() {
  const { id } = useParams<{ id: string }>();
  const displayedPeriod = getAmountMonthsBack(new Date(), 24);
  const { data: costGroupChartData = {} as CostGroupChartData } =
    useGetCostGroupChartQuery(id ?? '', { skip: !id });
  const { costs, groupedTransactions } = costGroupChartData;
  const seriesData: SeriesChart[] = [];
  costs.forEach((cost) => {
    const values: number[] = [];
    displayedPeriod.forEach((period) => {
      if (
        !groupedTransactions[period] ||
        !groupedTransactions[period][cost.id]
      ) {
        values.push(0);
      } else {
        values.push(Math.abs(groupedTransactions[period][cost.id]));
      }
    });
    seriesData.push({
      name: cost.name,
      type: 'bar',
      stack: 'stack',
      data: values,
    });
  });
  const chartOptions = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      top: '5%',
      right: '5%',
      bottom: '5%',
      left: '5%',
    },
    xAxis: {
      axisTick: {
        alignWithLabel: true,
      },
      type: 'category',
      data: displayedPeriod,
    },
    yAxis: {
      type: 'value',
    },
    series: seriesData,
  };
  return (
    <Box borderRadius={4} m={2} bgcolor="main.white" height="75vh">
      <ReactEChart
        option={chartOptions}
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  );
}

export default GroupChart;
