import React, { useState } from 'react';
import {
  Typography,
  AccordionDetails,
  Grid,
  Dialog,
  Box,
  Avatar,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Property } from 'store/properties/interfaces';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  StyledAccordion,
  StyledSummary,
} from 'components/ResponsibilityGroup/Accordion.styled';
import { useGetLocationsQuery } from 'store/locations/locations';
import { useTranslation } from 'react-i18next';
import { propertyTypes } from 'components/Properties/PropertiesAccordion/propertyTypes';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { PropertyBooking } from 'store/propertyBooking/interfaces';
import { BookingType } from 'components/Properties/enums';
import { useGetPropertyBookingsQuery } from 'store/propertyBooking/propertyBooking';
import getPropertyBookingCurrentReservedUser from 'helpers/getPropertyBookingCurrentReservedUser';
import BookingForm from '../BookingForm';

export interface PropertyTypesAccordionProps {
  type: string;
  properties: Property[];
  activeBookingsInSelectedDateRange: PropertyBooking[];
}

export default function PropertyTypesAccordion({
  type,
  properties,
  activeBookingsInSelectedDateRange,
}: PropertyTypesAccordionProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [isAddBookingFormOpen, setIsAddBookingFormOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<Property>(
    {} as Property,
  );
  const getEmployeeObject = getPropertyBookingCurrentReservedUser(
    activeBookingsInSelectedDateRange,
  );

  const { data: locations = [] } = useGetLocationsQuery();
  const {
    data: propertyBookings = [] as PropertyBooking[],
    isLoading: propertyBookingsLoading,
    isError: propertyBookingsError,
  } = useGetPropertyBookingsQuery({});

  const isPropertiesManager = useCheckRoles(roles.properties);

  const getLocationName = (locationId: string) => {
    const location = locations.find(
      (locationData) => locationData['@id'] === locationId,
    );

    if (!location) {
      return '';
    }

    return location.name;
  };

  const getTypeIcon = () => {
    const propertyData = propertyTypes.find(
      (property) => property.type === type,
    );

    if (!propertyData) {
      return <QuestionMarkIcon />;
    }

    return propertyData.icon;
  };

  const sortedProperties = properties.sort((currentProperty, nextProperty) =>
    currentProperty.name.localeCompare(nextProperty.name),
  );

  const isBookingDisabled = (property: Property) =>
    property.bookingType === BookingType.permanently &&
    !isPropertiesManager &&
    !!getEmployeeObject(property);

  return (
    <>
      <Grid item xs={12}>
        <StyledAccordion expanded={expanded} square>
          <StyledSummary
            expandIcon={<ArrowForwardIos />}
            onClick={() => setExpanded((prevState) => !prevState)}
          >
            <Box display="flex" alignItems="center" gap={1}>
              {getTypeIcon()}
              <Typography variant="subtitle1">{type}</Typography>
            </Box>
          </StyledSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            {sortedProperties.map((property) => (
              <Grid key={property.id} container alignItems="center">
                <Grid item xs={6} display="flex" alignItems="center" gap={2}>
                  {getEmployeeObject(property) ? (
                    <TitleTooltip title={t('booking.booked')}>
                      <FiberManualRecordIcon
                        sx={{ color: 'error.main', fontSize: '2.4rem' }}
                      />
                    </TitleTooltip>
                  ) : (
                    <TitleTooltip title={t('booking.available')}>
                      <FiberManualRecordIcon
                        sx={{ color: 'success.main', fontSize: '2.4rem' }}
                      />
                    </TitleTooltip>
                  )}
                  <Typography variant="bold">{property.name}</Typography>
                  {getEmployeeObject(property) && (
                    <TitleTooltip
                      title={`${getEmployeeObject(property)?.lastname} ${
                        getEmployeeObject(property)?.firstname
                      }`}
                    >
                      <Avatar
                        sx={{ width: '3rem', height: '3rem' }}
                        src={getEmployeeObject(property)?.avatarUrl}
                      />
                    </TitleTooltip>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1">
                    {property.bookingType}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1">
                    {getLocationName(property.location || '')}
                  </Typography>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="flex-end">
                  <LoadingButton
                    loading={propertyBookingsLoading}
                    disabled={
                      isBookingDisabled(property) || propertyBookingsError
                    }
                    sx={{
                      opacity: isBookingDisabled(property) ? 0 : 1,
                    }}
                    onClick={() => {
                      setSelectedProperty(property);
                      setIsAddBookingFormOpen(true);
                    }}
                    variant="contained"
                  >
                    {t('booking.book')}
                  </LoadingButton>
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Dialog
        maxWidth="lg"
        open={isAddBookingFormOpen}
        sx={{
          '& .MuiPaper-root': {
            overflowY: 'hidden',
          },
        }}
        onClose={() => setIsAddBookingFormOpen(false)}
      >
        <BookingForm
          propertyBookings={propertyBookings}
          property={selectedProperty}
          setIsOpen={setIsAddBookingFormOpen}
        />
      </Dialog>
    </>
  );
}
