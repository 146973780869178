import React, { useState } from 'react';
import { Button, Grid, Stack, Typography, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Link from 'common/Link/Link';
import CostList from 'components/CostList/CostList';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import BarChartIcon from '@mui/icons-material/BarChart';
import { CostsTabValue } from 'components/CostList/enums';

export default function Costs() {
  const [tabValue, setTabValue] = useState(CostsTabValue.active);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isCostsAnalyzer = useCheckRoles(roles.costsAnalyser);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: CostsTabValue,
  ) => setTabValue(newValue);

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <Stack>
          <Stack direction="row" alignItems="center" spacing={3}>
            <NavBarTitle>{t('costs.header')}</NavBarTitle>
            {isCostsAnalyzer && (
              <Link to="/costs-charts" underline="none">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <BarChartIcon color="primary" />
                  <Typography variant="body1">{t('charts.header')}</Typography>
                </Stack>
              </Link>
            )}
          </Stack>
        </Stack>
        <Button
          variant="contained"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate('/contractors/add')}
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('label.active')} value={CostsTabValue.active} />
          <Tab label={t('label.inactive')} value={CostsTabValue.inactive} />
        </StyledTabList>
        <CostList />
      </TabContext>
    </Grid>
  );
}
