import pl from 'translations/pl/common.json';
import en from 'translations/en/common.json';

const translations = {
  pl: {
    translation: pl,
  },
  en: {
    translation: en,
  },
};

export default translations;
