import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import CircleIcon from '@mui/icons-material/Circle';
import { Grid, Typography, Tab, Stack, Chip, Button } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import { useGetResourceRequestQuery } from 'store/resourceRequests/resourceRequests';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import Loader from 'common/Loader';
import ErrorComponent from 'common/Error';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import { ResourceRequest } from 'store/resourceRequests/interfaces';
import replaceApiIri from 'helpers/replaceApiIri';
import { Client, useGetClientQuery } from 'store/clients/clients';
import ResourceOverview from 'components/HumanResourceResource/ResourceOverview/ResourceOverview';
import ResourceCandidates from 'components/HumanResourceResource/ResourceCandidates';
import { HumanResourcesTabValue } from 'pages/HumanResources/HumanResources';
import { useGetResourceRequestCandidatesQuery } from 'store/resourceRequestCandidates/resourceRequestCandidates';
import {
  ResourceRequestCandidate,
  ResourceRequestCandidatesData,
} from 'store/resourceRequestCandidates/interfaces';
import { ResourceRequestContext } from './context/ResourceRequest.context';

export enum HumanResourceResourceTabValue {
  candidates = '1',
  overview = '2',
}

export default function HumanResourceResource() {
  const [tabValue, setTabValue] = useState(
    HumanResourceResourceTabValue.candidates,
  );

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const {
    data: resourceRequest = {} as ResourceRequest,
    isLoading: resourceRequestLoading,
    isError: resourceRequestError,
    isSuccess: resourceRequestSuccess,
  } = useGetResourceRequestQuery(id as string);

  const {
    data: resourceRequestCandidatesData = {} as ResourceRequestCandidatesData,
    isLoading: resourceRequestCandidatesLoading,
    isError: resourceRequestCandidatesError,
  } = useGetResourceRequestCandidatesQuery({
    resourceRequest: id,
  });

  const { data: resourceClient = {} as Client } = useGetClientQuery(
    replaceApiIri(resourceRequest.client ?? '', 'clients'),
    {
      skip: !resourceRequest.client,
    },
  );

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: HumanResourceResourceTabValue,
  ) => setTabValue(newValue);

  const contextValue = useMemo(
    () => ({
      resourceRequest: resourceRequest as ResourceRequest,
      resourceRequestCandidates:
        resourceRequestCandidatesData?.['hydra:member'] ||
        ([] as ResourceRequestCandidate[]),
    }),
    [resourceRequest, resourceRequestCandidatesData],
  );

  if (resourceRequestLoading || resourceRequestCandidatesLoading) {
    return <Loader />;
  }

  if (resourceRequestError || resourceRequestCandidatesError) {
    return <ErrorComponent />;
  }

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <Stack direction="row" alignItems="center">
          <NavigationBackButton
            to="/human-resources"
            options={{
              state: {
                tabValue: HumanResourcesTabValue.resources,
              },
            }}
          />
          <Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <NavBarTitle>{resourceRequest?.position}</NavBarTitle>
              <Chip
                size="small"
                color="primary"
                label={
                  <Typography variant="chipLabel" color="main.white">
                    {resourceRequest.status}
                  </Typography>
                }
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2" color="text.secondary">
                {resourceRequest.location}
              </Typography>
              {resourceRequest.client && resourceClient.name && (
                <>
                  <CircleIcon
                    sx={{
                      fontSize: '0.5rem',
                      color: 'border.divider',
                    }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {resourceClient.name}
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(`/human-resources/resources/${resourceRequest.id}/edit`)
          }
        >
          <Typography color="text.secondary" variant="buttonMedium">
            {t('human_resources.edit_request')}
          </Typography>
        </Button>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab
            label={t('human_resources.candidates')}
            value={HumanResourceResourceTabValue.candidates}
          />
          <Tab
            label={t('tab.overview')}
            value={HumanResourceResourceTabValue.overview}
          />
        </StyledTabList>
        {resourceRequestSuccess && (
          <ResourceRequestContext.Provider value={contextValue}>
            <TabPanel
              value={HumanResourceResourceTabValue.candidates}
              sx={{
                padding: 0,
              }}
            >
              <ResourceCandidates />
            </TabPanel>
            <TabPanel value={HumanResourceResourceTabValue.overview}>
              <ResourceOverview />
            </TabPanel>
          </ResourceRequestContext.Provider>
        )}
      </TabContext>
    </Grid>
  );
}
