import { Location } from 'store/locations/locations';

export default function getDefaultValues(location: Location | undefined) {
  if (!location) {
    return {
      name: '',
      officeOpenHour: null,
      officeCloseHour: null,
    };
  }

  return {
    name: location.name,
    officeOpenHour: location.officeOpenHour
      ? new Date(0, 0, 0, location.officeOpenHour)
      : null,
    officeCloseHour: location.officeCloseHour
      ? new Date(0, 0, 0, location.officeCloseHour)
      : null,
  };
}
