import React from 'react';
import { Typography, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

interface AppointmentContentShortProps {
  dateFrom: string;
  isBookingRemovable: boolean;
  isInDraftMode: boolean;
  isDraftBookingPossibleToAdd: boolean;
  handleAddPropertyBooking: () => void;
  handleCancelPropertyBooking: () => void;
}

export default function AppointmentContentShort({
  dateFrom,
  isBookingRemovable,
  isInDraftMode,
  isDraftBookingPossibleToAdd,
  handleAddPropertyBooking,
  handleCancelPropertyBooking,
}: AppointmentContentShortProps) {
  return (
    <Stack px={1.5}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={0.25}
      >
        {isInDraftMode && (
          <IconButton
            sx={{ width: '2rem', height: '2rem' }}
            onClick={handleAddPropertyBooking}
            disabled={!isDraftBookingPossibleToAdd}
          >
            <SaveIcon
              sx={{
                color: isDraftBookingPossibleToAdd
                  ? 'main.white'
                  : 'status.notActive.main',
              }}
            />
          </IconButton>
        )}
        <Typography variant="caption" color="main.white">
          {dateFrom}
        </Typography>
        {isBookingRemovable && (
          <CloseIcon
            sx={{ color: 'main.white' }}
            onClick={handleCancelPropertyBooking}
          />
        )}
      </Stack>
    </Stack>
  );
}
