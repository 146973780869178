import React, { useEffect, useState } from 'react';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import { SetURLSearchParams } from 'react-router-dom';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useHolidayTypes from 'hooks/useHolidayTypes';

interface FiltersProps {
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
}

export default function Filters({
  searchParams,
  setSearchParams,
}: FiltersProps) {
  const { t } = useTranslation();
  const { activeHolidayTypes } = useHolidayTypes();

  const {
    selected: selectedTypes,
    isSelected: isTypeSelected,
    setSelected: setSelectedTypes,
    handleChangeWithSelectedCheckboxesCallback,
  } = useMultipleCheckboxSelect<string>([]);

  const [selectedDateFrom, setSelectedDateFrom] = useState(
    searchParams.get('dateFrom[after]') ?? '',
  );
  const [selectedDateTo, setSelectedDateTo] = useState(
    searchParams.get('dateTo[before]') ?? '',
  );

  useEffect(() => {
    if (!searchParams.get('type[]')) {
      return;
    }

    setSelectedTypes(searchParams.getAll('type[]'));
  }, [searchParams, setSelectedTypes]);

  const getSelectedTypes = (selectedTypesIds: string[]) =>
    activeHolidayTypes
      .filter(({ id }) => selectedTypesIds.includes(id))
      .map(({ name }) => name)
      .join(', ');

  return (
    <Box display="flex" gap={2} alignItems="center">
      <FormControl fullWidth>
        <InputLabel id="type-select">{t('label.type')}</InputLabel>
        <Select
          label={t('label.type')}
          labelId="type-select"
          name="typeSelect"
          multiple
          renderValue={(selected) => getSelectedTypes(selected)}
          value={selectedTypes}
        >
          {activeHolidayTypes.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTypeSelected(id)}
                    onChange={(event) =>
                      handleChangeWithSelectedCheckboxesCallback(
                        event,
                        (selectedCheckboxes) => {
                          setSearchParams((prevSearchParams) => ({
                            ...prevSearchParams,
                            'type[]': selectedCheckboxes,
                            ...(selectedDateFrom && {
                              'dateFrom[after]': selectedDateFrom,
                            }),
                            ...(selectedDateTo && {
                              'dateTo[before]': selectedDateTo,
                            }),
                          }));
                        },
                      )
                    }
                    value={id}
                  />
                }
                label={name}
                sx={{ width: '100%' }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label={t('label.date_from')}
        id="date-from"
        name="dateFrom"
        onChange={(event) => {
          const dateFrom = event.target.value;
          setSelectedDateFrom(dateFrom);
          setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            ...(selectedTypes.length && { 'type[]': selectedTypes }),
            ...(dateFrom && { 'dateFrom[after]': dateFrom }),
            ...(selectedDateTo && { 'dateTo[before]': selectedDateTo }),
          }));
        }}
        type="date"
        value={selectedDateFrom}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />
      <TextField
        label={t('label.date_to')}
        id="date-to"
        name="dateTo"
        onChange={(event) => {
          const dateTo = event.target.value;
          setSelectedDateTo(dateTo);
          setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            ...(selectedTypes.length && { 'type[]': selectedTypes }),
            ...(selectedDateFrom && { 'dateFrom[after]': selectedDateFrom }),
            ...(dateTo && { 'dateTo[before]': dateTo }),
          }));
        }}
        type="date"
        value={selectedDateTo}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: selectedDateFrom,
        }}
        fullWidth
      />
    </Box>
  );
}
