import React from 'react';
import { createSvgIcon } from '@mui/material';

const CheckCircleFilledIcon = createSvgIcon(
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.5" y="1.5" width="27" height="27" rx="13.5" fill="#613CEF" />
    <rect
      x="1.5"
      y="1.5"
      width="27"
      height="27"
      rx="13.5"
      stroke="white"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0767 12.331C19.4462 12.7416 19.4129 13.3738 19.0024 13.7433L14.7809 17.5426C14.2739 17.999 13.5041 17.999 12.997 17.5426L10.9978 15.7433C10.5873 15.3738 10.554 14.7416 10.9235 14.331C11.2929 13.9205 11.9252 13.8873 12.3357 14.2567L13.889 15.6546L17.6645 12.2567C18.075 11.8873 18.7073 11.9205 19.0767 12.331Z"
      fill="white"
    />
  </svg>,
  'CheckCircleFilledIcon',
);

export default CheckCircleFilledIcon;
