import React from 'react';
import roles from 'config/roles/roles';
import features from 'config/features/features';
import ProtectedRoute from 'common/ProtectedRoute';
import OnlyUnauthorizedRoute from 'common/OnlyUnauthorizedRoute/OnlyUnauthorizedRoute';
import Authorize from 'pages/Authorize/Authorize';
import Homepage from 'pages/Homepage/Homepage';
import Employees from 'pages/Employees/Employees';
import EditEmployeeForm from 'components/EditEmployeeForm';
import Agreements from 'pages/Agreements/Agreements';
import Projects from 'pages/Projects/Projects';
import HolidaysManagement from 'pages/HolidaysManagement';
import Clients from 'pages/Clients/Clients';
import EditAgreementForm from 'components/EditAgreementForm/EditAgreementForm';
import Banks from 'pages/Banks/Banks';
import Accounts from 'pages/Accounts/Accounts';
import Registration from 'pages/Registration/Registration';
import ActivateUser from 'pages/ActivateUser/ActivateUser';
import PasswordReset from 'pages/PasswordReset';
import ResetWithToken from 'pages/ResetWithToken';
import BankHolidays from 'pages/BankHolidays/BankHolidays';
import Invoices from 'pages/Invoices/Invoices';
import Costs from 'pages/Costs/Costs';
import AddCostForm from 'components/AddCostForm';
import EditCostForm from 'components/EditCostForm/EditCostForm';
import EmployeesHolidays from 'pages/EmployeesHolidays/EmployeesHolidays';
import Currencies from 'pages/Currencies/Currencies';
import Countries from 'pages/Countries/Countries';
import Positions from 'pages/Positions/Positions';
import EditProjectForm from 'components/EditProjectForm/EditProjectForm';
import EditClientForm from 'components/EditClientForm/EditClientForm';
import Settings from 'pages/Settings/Settings';
import EditInvoiceForm from 'components/EditInvoiceForm';
import EmployeeWorkingHours from 'pages/EmployeeWorkingHours/EmployeeWorkingHours';
import CalendarSettings from 'pages/CalendarSettings/CalendarSettings';
import DocumentTypeManagement from 'pages/DocumentTypeManagement/DocumentTypeManagement';
import ContractorGroups from 'pages/ContractorGroups/ContractorGroups';
import PaymentDue from 'pages/PaymentDue/PaymentDue';
import Transactions from 'pages/Transactions/Transactions';
import TransactionAttachments from 'pages/TransactionAttachments/TransactionAttachments';
import HolidaysSettings from 'pages/HolidaysSettings/HolidaysSettings';
import Locations from 'pages/Locations/Locations';
import Properties from 'pages/Properties';
import PipeDrive from 'components/PipeDrive/PipeDrive';
import AddInvoiceForm from 'components/AddInvoiceForm';
import HoursView from 'pages/HoursView/HoursView';
import InvoiceSending from 'components/InvoiceSending';
import CostsCharts from 'pages/CostsCharts/CostsCharts';
import CostGroupChart from 'components/CostGroupChart/CostGroupChart';
import Benefits from 'pages/Benefits/Benefits';
import BenefitPricing from 'pages/BenefitPricing/BenefitPricing';
import Responsibilities from 'pages/Responsibilities/Responsibilities';
import Feedback from 'pages/Feedback/Feedback';
import EmployeeFeedbacks from 'pages/EmployeeFeedbacks/EmployeeFeedbacks';
import PropertyBooking from 'pages/PropertyBooking/PropertyBooking';
import Meeting from 'pages/Meeting/Meeting';
import Accountancy from 'pages/Accountancy/Accountancy';
import AddProperty from 'components/Properties/AddProperty';
import EditProperty from 'components/Properties/EditProperty';
import Condition from 'pages/Condition/Condition';
import OrganizationSettings from 'pages/OrganizationSettings/OrganizationSettings';
import HumanResources from 'pages/HumanResources';
import HumanResourceCandidate from 'pages/HumanResourceCandidate/HumanResourceCandidate';
import ResourceRequestAdd from 'pages/ResourceRequestAdd/ResourceRequestAdd';
import HumanResourceResource from 'pages/HumanResourceResource/HumanResourceResource';
import MailFooter from 'pages/MailFooter/MailFooter';
import ResourceRequestEdit from 'pages/ResourceRequestEdit/ResourceRequestEdit';
import OrganizationUsers from 'pages/OrganizationUsers/OrganizationUsers';
import Budget from 'pages/Budget';
import BudgetPreview from 'pages/BudgetPreview';
import BudgetPlanner from 'pages/BudgetPlanner/BudgetPlanner';
import BudgetPeriod from 'pages/BudgetPeriod/BudgetPeriod';
import HolidayTypes from 'pages/HolidayTypes/HolidayTypes';
import InvoicePreview from 'pages/InvoicePreview/InvoicePreview';
import Subscription from 'pages/Subscription/Subscription';
import DefaultAnnualLeave from 'pages/DefaultAnnualLeave/DefaultAnnualLeave';
import IncomeChart from 'pages/IncomeChart/IncomeChart';

export const routes = [
  {
    name: 'route_homepage',
    path: '/',
    component: (
      <ProtectedRoute routeRoles={roles.user}>
        <Homepage />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_login',
    path: '/login',
    component: (
      <OnlyUnauthorizedRoute>
        <Authorize />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    name: 'route_register',
    path: '/register',
    component: (
      <OnlyUnauthorizedRoute>
        <Registration isDemo={false} />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    name: 'route_register_demo',
    path: '/register/demo',
    component: (
      <OnlyUnauthorizedRoute>
        <Registration isDemo />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    name: 'route_activation',
    path: '/activation/:token',
    component: (
      <OnlyUnauthorizedRoute>
        <ActivateUser />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    name: 'route_password_reset',
    path: '/password-reset',
    component: (
      <OnlyUnauthorizedRoute>
        <PasswordReset />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    name: 'route_password_setup',
    path: '/password-setup/:token',
    component: (
      <OnlyUnauthorizedRoute>
        <ResetWithToken />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    name: 'route_employee_list',
    path: '/employees',
    component: (
      <ProtectedRoute
        routeRoles={[...roles.employees, ...roles.employeesViewer]}
        routeFeature={features.employees}
      >
        <Employees />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_employees_holidays',
    path: '/holidays/me',
    component: (
      <ProtectedRoute routeRoles={roles.user} routeFeature={features.holidays}>
        <EmployeesHolidays />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_agreements',
    path: '/agreements',
    component: (
      <ProtectedRoute
        routeRoles={roles.agreements}
        routeFeature={features.agreements}
      >
        <Agreements />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_edit_agreements',
    path: '/agreements/:id',
    component: (
      <ProtectedRoute
        routeRoles={roles.agreements}
        routeFeature={features.agreements}
      >
        <EditAgreementForm />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_edit_employee',
    path: '/employees/:id',
    component: (
      <ProtectedRoute
        routeRoles={roles.employees}
        routeFeature={features.employees}
      >
        <EditEmployeeForm />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_projects',
    path: '/projects',
    component: (
      <ProtectedRoute
        routeRoles={roles.projects}
        routeFeature={features.projects}
      >
        <Projects />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_locations',
    path: '/locations',
    component: (
      <ProtectedRoute routeRoles={roles.locations}>
        <Locations />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_properties',
    path: '/properties',
    component: (
      <ProtectedRoute
        routeRoles={roles.properties}
        routeFeature={features.bookings}
      >
        <Properties />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_edit_project',
    path: '/projects/:id',
    component: (
      <ProtectedRoute
        routeRoles={roles.projects}
        routeFeature={features.projects}
      >
        <EditProjectForm />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_holidays_management',
    path: '/holidays-management',
    component: (
      <ProtectedRoute
        routeRoles={roles.holidays}
        routeFeature={features.holidays}
      >
        <HolidaysManagement />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_clients',
    path: '/clients',
    component: (
      <ProtectedRoute
        routeRoles={[...roles.clients, ...roles.debtCollection]}
        routeFeature={features.clients}
      >
        <Clients />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_edit_client',
    path: '/clients/:id',
    component: (
      <ProtectedRoute
        routeRoles={[...roles.clients, ...roles.debtCollection]}
        routeFeature={features.clients}
      >
        <EditClientForm />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_banks',
    path: '/banks',
    component: (
      <ProtectedRoute routeRoles={roles.banks} routeFeature={features.banks}>
        <Banks />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_bank_accounts',
    path: '/transactions',
    component: (
      <ProtectedRoute
        routeFeature={features.transactions}
        routeRoles={[
          ...roles.banksManager,
          ...roles.transactionsManager,
          ...roles.accountancy,
        ]}
      >
        <Accounts />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_bank_holidays',
    path: '/bank-holidays',
    component: (
      <ProtectedRoute
        routeFeature={features.holidays}
        routeRoles={roles.bankHolidays}
      >
        <BankHolidays />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_invoices',
    path: '/invoices',
    component: (
      <ProtectedRoute routeRoles={roles.invoices}>
        <Invoices />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_add_invoice',
    path: '/invoices/add',
    component: (
      <ProtectedRoute routeRoles={roles.invoices}>
        <AddInvoiceForm />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_edit_invoice',
    path: '/invoices/:id/edit',
    component: (
      <ProtectedRoute routeRoles={roles.invoices}>
        <EditInvoiceForm />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_contractors',
    path: '/contractors',
    component: (
      <ProtectedRoute routeRoles={roles.contractors}>
        <Costs />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_add_contractor',
    path: '/contractors/add',
    component: (
      <ProtectedRoute routeRoles={roles.contractors}>
        <AddCostForm />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_edit_contractor',
    path: '/contractors/:id',
    component: (
      <ProtectedRoute routeRoles={roles.contractors}>
        <EditCostForm />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_currencies',
    path: '/currencies',
    component: (
      <ProtectedRoute routeRoles={roles.currencies}>
        <Currencies />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_countries',
    path: '/countries',
    component: (
      <ProtectedRoute routeRoles={roles.countries}>
        <Countries />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_positions',
    path: '/positions',
    component: (
      <ProtectedRoute routeRoles={roles.positions}>
        <Positions />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_settings',
    path: '/settings',
    component: (
      <ProtectedRoute routeRoles={roles.user}>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_project_tracking',
    path: '/project-tracking',
    component: (
      <ProtectedRoute routeRoles={roles.user} routeFeature={features.projects}>
        <EmployeeWorkingHours />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_calendar_settings',
    path: '/calendar-settings',
    component: (
      <ProtectedRoute routeRoles={roles.holidaysManager}>
        <CalendarSettings />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_document_type_management',
    path: '/document-type-management',
    component: (
      <ProtectedRoute routeRoles={roles.documents}>
        <DocumentTypeManagement />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_contractor_groups_management',
    path: '/contractor-groups',
    component: (
      <ProtectedRoute routeRoles={roles.contractors}>
        <ContractorGroups />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_payment_due',
    path: '/payment-due',
    component: (
      <ProtectedRoute routeRoles={roles.paymentDue}>
        <PaymentDue />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_transactions',
    path: '/transactions/:id',
    component: (
      <ProtectedRoute
        routeFeature={features.transactions}
        routeRoles={roles.transactionsManager}
      >
        <Transactions />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_transaction_attachments',
    path: '/transaction-attachments',
    component: (
      <ProtectedRoute
        routeFeature={features.transactions}
        routeRoles={[...roles.transactionsManager, ...roles.accountancy]}
      >
        <TransactionAttachments />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_holidays_settings',
    path: '/holidays-settings',
    component: (
      <ProtectedRoute
        routeRoles={roles.holidaysManager}
        routeFeature={features.holidays}
      >
        <HolidaysSettings />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_pipedrive',
    path: '/pipedrive',
    component: (
      <ProtectedRoute routeRoles={roles.pipeDrive}>
        <PipeDrive />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_hours_view',
    path: '/hours-view',
    component: (
      <ProtectedRoute routeRoles={roles.workingHoursViewer}>
        <HoursView />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_invoices_email',
    path: '/invoices/:id/email',
    component: (
      <ProtectedRoute routeRoles={roles.invoices}>
        <InvoiceSending />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_costs_charts',
    path: '/costs-charts',
    component: (
      <ProtectedRoute
        routeFeature={features.costs}
        routeRoles={roles.costsAnalyser}
      >
        <CostsCharts />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_cost_group_chart',
    path: '/costs-charts/:id',
    component: (
      <ProtectedRoute
        routeFeature={features.costs}
        routeRoles={roles.costsAnalyser}
      >
        <CostGroupChart />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_benefits',
    path: '/benefits',
    component: (
      <ProtectedRoute
        routeFeature={features.benefits}
        routeRoles={[...roles.benefitsManager, ...roles.benefitsViewer]}
      >
        <Benefits />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_benefit_pricing',
    path: '/benefits/:id/pricing',
    component: (
      <ProtectedRoute
        routeFeature={features.benefits}
        routeRoles={[...roles.benefitsManager, ...roles.benefitsViewer]}
      >
        <BenefitPricing />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_responsibilities',
    path: '/responsibilities',
    component: (
      <ProtectedRoute
        routeFeature={features.responsibilities}
        routeRoles={roles.responsibilitiesManager}
      >
        <Responsibilities />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_feedback',
    path: '/feedback',
    component: (
      <ProtectedRoute routeRoles={roles.user} routeFeature={features.feedback}>
        <Feedback />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_my_feedbacks',
    path: '/feedback/:id',
    component: (
      <ProtectedRoute routeRoles={roles.user} routeFeature={features.feedback}>
        <EmployeeFeedbacks />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_property_booking',
    path: '/property-booking',
    component: (
      <ProtectedRoute routeFeature={features.bookings} routeRoles={roles.user}>
        <PropertyBooking />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_meeting',
    path: '/meeting/:id',
    component: (
      <ProtectedRoute
        routeFeature={features.feedback}
        routeRoles={roles.meetingManager}
      >
        <Meeting />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_accountancy',
    path: '/accountancy',
    component: (
      <ProtectedRoute routeRoles={roles.accountancy}>
        <Accountancy />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_add_property',
    path: '/properties/add',
    component: (
      <ProtectedRoute
        routeFeature={features.bookings}
        routeRoles={roles.properties}
      >
        <AddProperty />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_edit_property',
    path: '/properties/:id',
    component: (
      <ProtectedRoute
        routeFeature={features.bookings}
        routeRoles={roles.properties}
      >
        <EditProperty />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_condition',
    path: '/condition',
    component: (
      // TODO: check if role is correct
      <ProtectedRoute routeRoles={roles.user}>
        <Condition />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_organization_settings',
    path: '/organization-settings',
    component: (
      <ProtectedRoute routeRoles={roles.admin}>
        <OrganizationSettings />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_human_resources',
    path: '/human-resources',
    component: (
      <ProtectedRoute
        routeFeature={features.hr}
        routeRoles={roles.humanResources}
      >
        <HumanResources />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_human_resource_candidate',
    path: '/human-resources/candidate/:id',
    component: (
      <ProtectedRoute
        routeFeature={features.hr}
        routeRoles={roles.humanResources}
      >
        <HumanResourceCandidate />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_human_resource_resource_add',
    path: '/human-resources/resources/add',
    component: (
      <ProtectedRoute
        routeFeature={features.hr}
        routeRoles={roles.humanResources}
      >
        <ResourceRequestAdd />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_human_resource_resource',
    path: '/human-resources/resources/:id',
    component: (
      <ProtectedRoute
        routeFeature={features.hr}
        routeRoles={roles.humanResources}
      >
        <HumanResourceResource />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_human_resource_resource_edit',
    path: '/human-resources/resources/:id/edit',
    component: (
      <ProtectedRoute
        routeFeature={features.hr}
        routeRoles={roles.humanResources}
      >
        <ResourceRequestEdit />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_mail_footer',
    path: '/mail-footer',
    component: (
      <ProtectedRoute routeRoles={roles.user}>
        <MailFooter />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_organization_users',
    path: '/organization/users',
    component: (
      <ProtectedRoute routeRoles={roles.organizationUsers}>
        <OrganizationUsers />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_budget',
    path: '/budget',
    component: (
      <ProtectedRoute routeRoles={roles.budgetManager}>
        <Budget />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_preview_budget',
    path: '/budget/:id',
    component: (
      <ProtectedRoute routeRoles={roles.budgetManager}>
        <BudgetPreview />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_budget_planner',
    path: '/budget/:id/planner',
    component: (
      <ProtectedRoute routeRoles={roles.budgetManager}>
        <BudgetPlanner />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_budget_period_planner',
    path: '/budget/:id/period',
    component: (
      <ProtectedRoute routeRoles={roles.budgetManager}>
        <BudgetPeriod />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_holiday_types_settings',
    path: '/holiday-types',
    component: (
      <ProtectedRoute routeRoles={roles.holidaysManager}>
        <HolidayTypes />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_invoice_preview',
    path: '/invoices/:id/preview',
    component: (
      <ProtectedRoute routeRoles={roles.invoices}>
        <InvoicePreview />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_subscription',
    path: '/subscription',
    component: (
      // TODO: change the role/type of route when whole feature ready
      <ProtectedRoute routeRoles={roles.admin}>
        <Subscription />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_default_annual_leave',
    path: '/annual-leave',
    component: (
      <ProtectedRoute
        routeRoles={roles.holidaysManager}
        routeFeature={features.holidays}
      >
        <DefaultAnnualLeave />
      </ProtectedRoute>
    ),
  },
  {
    name: 'route_income_chart',
    path: '/income-chart',
    component: (
      <ProtectedRoute routeRoles={roles.invoices}>
        <IncomeChart />
      </ProtectedRoute>
    ),
  },
];

export default routes;
