import React from 'react';
import useSubscriptionContext from 'pages/Subscription/context/useSubscriptionContext';
import getCustomScaleSliderParams, {
  ScaleEntry,
} from 'helpers/getCustomScaleSliderParams';
import StyledSlider from './Slider.styled';

const scale: ScaleEntry[] = [
  {
    lastStep: 25,
    modifier: 1,
  },
  {
    lastStep: 50,
    modifier: 5,
  },
  {
    lastStep: 65,
    modifier: 10,
  },
];

const { customScale, reverseCustomScale, maxStepValue, minStepValue, marks } =
  getCustomScaleSliderParams(scale);

export default function UserCountSlider() {
  const { userCount, setUserCount } = useSubscriptionContext();

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== 'number') {
      return;
    }
    setUserCount(customScale(newValue));
  };

  return (
    <StyledSlider
      value={reverseCustomScale(userCount)}
      onChange={handleChange}
      min={minStepValue}
      max={maxStepValue}
      step={1}
      marks={marks}
    />
  );
}
