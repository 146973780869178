import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  {
    align: 'left',
    label: 'label.type',
  },
  {
    align: 'center',
    label: 'agreements.header_variant',
  },
  {
    align: 'center',
    label: 'agreements.header_start',
  },
  {
    align: 'center',
    label: 'agreements.header_end',
  },
  {
    align: 'center',
    label: 'agreements.header_file',
  },
];

export default headers;
