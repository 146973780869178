import React, { useState } from 'react';
import { Typography, Stack, Divider, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTranslation } from 'react-i18next';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import Loader from 'common/Loader';
import Error from 'common/Error';
import {
  useAddCandidateNoteMutation,
  useGetCandidateNotesQuery,
} from 'store/candidateNotes/candidateNotes';
import useCandidateContext from 'pages/HumanResourceCandidate/context/useCandidateContext';
import { CandidateNotesData } from 'store/candidateNotes/interfaces';
import { CustomError } from 'store/api';
import CandidateNote from './CandidateNote';
import { CandidateNotesProps } from './interfaces';
import { FormPayload } from './NoteForm/interfaces';
import NoteForm from './NoteForm/NoteForm';

export default function CandidateNotes({
  setErrorMessage,
  setIsError,
  setIsSuccess,
}: CandidateNotesProps) {
  const [isAddMode, setIsAddMode] = useState(false);
  const toggleAddMode = () => setIsAddMode((prev) => !prev);

  const { t } = useTranslation();
  const { candidate } = useCandidateContext();
  const {
    data: candidateNotesData = {} as CandidateNotesData,
    isLoading: candidatesLoading,
    isError: candidatesError,
  } = useGetCandidateNotesQuery({
    candidate: candidate.id,
  });

  const [addCandidateNote, { isLoading: candidateAddNoteLoading }] =
    useAddCandidateNoteMutation();

  const handleAddNote = async (data: FormPayload) => {
    try {
      await addCandidateNote({
        candidate: candidate['@id'],
        note: data.note,
      }).unwrap();
      setIsSuccess(true);
      setIsAddMode(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (candidatesLoading) {
    return <Loader />;
  }

  if (candidatesError) {
    return <Error />;
  }

  const { 'hydra:member': candidateNotes } = candidateNotesData;

  return (
    <StyledContentBox mt={3} py={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={3}>
        <Typography variant="h6">{t('label.notes')}</Typography>
        <IconButton onClick={toggleAddMode}>
          {isAddMode ? (
            <RemoveCircleOutlineIcon
              sx={{
                color: 'primary.main',
                fontSize: '1.8rem',
              }}
            />
          ) : (
            <AddCircleOutlineIcon
              sx={{
                color: 'primary.main',
                fontSize: '1.8rem',
              }}
            />
          )}
        </IconButton>
      </Stack>
      {isAddMode && (
        <NoteForm
          isSubmitting={candidateAddNoteLoading}
          onSubmit={handleAddNote}
        />
      )}
      <Stack spacing={3} mt={3}>
        {candidateNotes.map((candidateNote, index) => (
          <>
            <CandidateNote
              key={candidateNote.id}
              candidateNote={candidateNote}
            />
            {index < candidateNotes.length - 1 && <Divider />}
          </>
        ))}
      </Stack>
    </StyledContentBox>
  );
}
