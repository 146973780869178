import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Employee } from 'store/employee/interfaces';
import EditButton from 'common/EditButton/EditButton';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import IconPopover from 'common/IconPopover/IconPopover';
import ContextMenuButton from 'common/ContextMenuButton/ContextMenuButton';
import AddUserForm from 'components/EmployeeList/AddUserForm';
import { useTranslation } from 'react-i18next';
import AgreementBadge from 'components/EmployeeList/AgreementBadge/AgreementBadge';
import UserStatus from 'components/EmployeeList/UserStatus/UserStatus';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';

interface ResultProps {
  employee: Employee;
}

function Result({ employee }: ResultProps) {
  const [addUserFormOpen, setAddUserFormOpen] = useState(false);
  const [contextAnchorEl, setContextAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const { t } = useTranslation();
  const isEmployeesManager = useCheckRoles(roles.employees);
  const isAdmin = useCheckRoles(roles.admin);

  const days = Math.floor(employee.holidayHoursLeft / 8);
  const remainingHours = employee.holidayHoursLeft % 8;

  return (
    <>
      <TableRow key={employee.id}>
        <TableCell>
          <Box display="flex" alignItems="center" columnGap={2}>
            <Avatar src={employee.avatarUrl} />
            <Box display="flex" flexDirection="column" rowGap={0.5}>
              <Box display="flex" alignItems="center" columnGap={1}>
                <Typography variant="body2">
                  {employee.lastname} {employee.firstname}
                </Typography>
                {!employee.userId && <UserStatus />}
              </Box>
              <Typography variant="body2" color="text.secondary">
                {employee.companyEmail}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {employee.activeAgreement ? employee.activeAgreement.position : ''}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{employee.daysLeft}</Typography>
          {isAdmin && (
            <Typography variant="body2">
              {remainingHours === 0
                ? `${days} ${t('label.days')}`
                : `${days} ${t('label.days')} ${remainingHours}h`}
            </Typography>
          )}
        </TableCell>

        <TableCell align="center">
          <Typography variant="body2">
            {employee.currentHolidayDaysLimit}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {employee.activeAgreement ? employee.activeAgreement.variant : ''}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <AgreementBadge
            agreement={employee.activeAgreement}
            employeeId={employee.id}
          />
        </TableCell>
        <TableCell align="center">
          <Box display="flex" columnGap={1}>
            {employee.contactEmail && (
              <IconPopover
                Icon={ContactMailIcon}
                content={employee.contactEmail}
              />
            )}
            {employee.contactPhone && (
              <IconPopover
                Icon={LocalPhoneIcon}
                content={employee.contactPhone}
              />
            )}
          </Box>
        </TableCell>
        {isEmployeesManager && (
          <TableCell align="right">
            <Box display="flex" justifyContent="flex-end" columnGap={1}>
              {!employee.userId && (
                <ContextMenuButton
                  anchorEl={contextAnchorEl}
                  setAnchorEl={setContextAnchorEl}
                  onClick={(event) => setContextAnchorEl(event.currentTarget)}
                  items={
                    <MenuItem
                      onClick={() => {
                        setAddUserFormOpen(true);
                        setContextAnchorEl(null);
                      }}
                    >
                      <Typography variant="body2">
                        {t('employees.add_user')}
                      </Typography>
                    </MenuItem>
                  }
                />
              )}
              <EditButton navigateTo={`/employees/${employee.id}`} />
            </Box>
          </TableCell>
        )}
      </TableRow>
      <Dialog
        open={addUserFormOpen}
        onClose={() => setAddUserFormOpen(false)}
        fullWidth
      >
        <AddUserForm
          employeeId={Number(employee.id)}
          setIsOpen={setAddUserFormOpen}
        />
      </Dialog>
    </>
  );
}

export default Result;
