import React from 'react';
import { Stack, Typography } from '@mui/material';
import TagTile from 'components/TagTile/TagTile';
import TagGroupAutocomplete from 'components/TagGroupAutocomplete/TagGroupAutocomplete';
import { CustomError } from 'store/api';
import { useDeleteTagMutation, useAddTagMutation } from 'store/tags/tags';
import useCandidateContext from 'pages/HumanResourceCandidate/context/useCandidateContext';
import { SkillTagGroupProps } from './interfaces';

export default function SkillTagGroup({
  tagGroup,
  setIsError,
  setErrorMessage,
}: SkillTagGroupProps) {
  const { candidate } = useCandidateContext();
  const [deleteTag] = useDeleteTagMutation();
  const [addTag] = useAddTagMutation();

  const handleDeleteTag = async (tagId: string) => {
    try {
      await deleteTag(tagId).unwrap();
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleAddTag = async (tagDefinition: string) => {
    try {
      await addTag({
        tagDefinition,
        relationName: 'candidate',
        relationId: candidate.id,
      }).unwrap();
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const candidateTagsOfTagGroup =
    candidate?.tags?.filter(
      (tag) => tag.tagDefinition.tagGroup === tagGroup['@id'],
    ) || [];
  const usedTagDefinitionNames =
    candidateTagsOfTagGroup?.map((tag) => tag.tagDefinition.name) || [];

  return (
    <Stack spacing={6} p={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" flexBasis="40%" fontWeight={700}>
          {tagGroup.name}
        </Typography>
        <TagGroupAutocomplete
          tagGroup={tagGroup}
          usedTagDefinitionNames={usedTagDefinitionNames}
          handleOnAdd={handleAddTag}
        />
      </Stack>
      <Stack direction="row" gap={3} flexWrap="wrap">
        {candidateTagsOfTagGroup.map((tag) => (
          <TagTile
            key={tag.id}
            tag={tag}
            handleOnDelete={handleDeleteTag}
          />
        ))}
      </Stack>
    </Stack>
  );
}
