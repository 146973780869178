import React, { useState } from 'react';
import { Box, DialogContent, TextField } from '@mui/material';
import { useValidate } from 'hooks/useValidate';
import { useAddBankMutation } from 'store/bank/bank';
import { useTranslation } from 'react-i18next';
import { StatusProps } from 'hoc/Status/Status';
import checkSwift from 'helpers/validators/checkSwift';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface Errors {
  bankname: boolean;
  swiftnumber: boolean;
}

export interface AddBankFormProps extends StatusProps {
  setIsAddOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddBankForm({
  setIsSuccess,
  setIsError,
  setIsAddOpen,
  setErrorMessage,
}: AddBankFormProps) {
  const [bankName, setBankName] = useState('');
  const [swiftNumber, setSwiftNumber] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const [addBank, { isLoading }] = useAddBankMutation();
  const { t } = useTranslation();

  const disableSubmission = () =>
    Object.values(errors).some((error) => error) ||
    !bankName ||
    !checkSwift(swiftNumber);

  const handleAddBank = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addBank({
        name: bankName,
        swift: swiftNumber,
      }).unwrap();
      setIsSuccess(true);
      setIsAddOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('banks.add_new_bank')} setIsOpen={setIsAddOpen} />
      <DialogContent>
        <form onSubmit={handleAddBank}>
          <Box display="flex" flexDirection="column" gap={3} pt={3}>
            <TextField
              required
              fullWidth
              error={errors.bankname}
              helperText={errors.bankname && t('banks.too_short_name_error')}
              name="bankname"
              variant="outlined"
              label={t('banks.bank_name_label')}
              id="bankname"
              value={bankName}
              onChange={(event) => setBankName(event.target.value)}
              onBlur={(event) =>
                validate('bankname', event.target.value.length >= 2)
              }
            />
            <TextField
              required
              fullWidth
              error={errors.swiftnumber}
              helperText={errors.swiftnumber && t('banks.swift_number_error')}
              name="swiftnumber"
              variant="outlined"
              label={t('banks.swift_label')}
              id="swiftnumber"
              value={swiftNumber}
              onChange={(event) => setSwiftNumber(event.target.value)}
              onBlur={() => validate('swiftnumber', checkSwift(swiftNumber))}
            />
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                disabled={disableSubmission()}
                endIcon={<AddCircleOutlineIcon />}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
