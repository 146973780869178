import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Project } from 'store/projects/interfaces';
import tableHeaders from 'components/ProjectsList/tableHeaders';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Result from './Result/Result';

interface ProjectsListProps {
  projects: Project[];
  projectsFetchError: boolean;
  projectsFetching: boolean;
}

export default function ProjectsList({
  projects,
  projectsFetchError,
  projectsFetching,
}: ProjectsListProps) {
  const { t, i18n } = useTranslation();

  const isAllowedToSeeClient = useCheckRoles([
    ...roles.clients,
    ...roles.debtCollection,
  ]);
  const isHoursViewer = useCheckRoles(roles.workingHoursViewer);
  const organizationRoles = useSelector(
    (state: RootState) => state.organization.organization.roles,
  );
  const checkRoles = (rolesData: string[]) =>
    rolesData.some((role) => organizationRoles.includes(role));

  const getProjectName = (project: Project) => {
    if (project.parent) {
      return `[${project.parent.name}] - ${project.name}`;
    }
    return project.name;
  };

  const sortedProjectsByProjectName = [...projects].sort(
    (currentProject, nextProject) => {
      const currentProjectName = getProjectName(currentProject).replace(
        /\[|\]/g,
        '',
      );
      const nextProjectName = getProjectName(nextProject).replace(/\[|\]/g, '');

      return currentProjectName.localeCompare(
        nextProjectName,
        i18n.language || 'en',
        { sensitivity: 'base' },
      );
    },
  );

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map(
              (header) =>
                checkRoles(header.displayRole) && (
                  <TableCell
                    key={header.label}
                    align={header.align}
                    width={header.width}
                  >
                    <Typography variant="tableHeader" color="text.secondary">
                      {t(header.label)}
                    </Typography>
                  </TableCell>
                ),
            )}
            <TableCell colSpan={tableHeaders.length} />
          </TableRow>
        </TableHead>
        <TableBodyContent
          isError={projectsFetchError}
          isFetching={projectsFetching}
          tableHeaders={tableHeaders.filter((header) =>
            checkRoles(header.displayRole),
          )}
          showNoDataRow={false}
        >
          {sortedProjectsByProjectName.map((project) => (
            <Result
              key={project.id}
              project={project}
              isHoursViewer={isHoursViewer}
              isAllowedToSeeClient={isAllowedToSeeClient}
              getProjectName={getProjectName}
            />
          ))}
        </TableBodyContent>
      </Table>
    </TableContainer>
  );
}
