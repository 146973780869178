import React, { useState } from 'react';
import {
  Avatar,
  IconButton,
  Stack,
  Typography,
  MenuItem,
  ListItemIcon,
  Box,
  Divider,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CogIcon from 'assets/icons/CogIcon';
import { useNavigate } from 'react-router-dom';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import { useGetOrganizationsQuery } from 'store/organizations/organizations';
import useHandleLogout from 'hooks/useHandleLogout';
import { LoggedEmployee } from 'store/employee/interfaces';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { getOrganization } from 'store/organizations/selectors';
import StyledMenu from './Menu.styled';
import OrganizationsNestedMenu from './OrganizationsNestedMenu/OrganizationsNestedMenu';

export default function MenuSettings() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const handleLogout = useHandleLogout();
  const selectedOrganization = useSelector(
    (state: RootState) => state.organization.organization,
  );
  const isAuthorized = useSelector(
    (state: RootState) => state.authorization.isAuthorized,
  );
  const organization = useSelector(getOrganization);

  const navigate = useNavigate();
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined, {
    skip: !isAuthorized,
  });
  const { data: loggedUser = {} as LoggedEmployee } = useGetLoggedEmployeeQuery(
    undefined,
    {
      skip: !organization.instance,
    },
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSettingsClick = () => {
    handleClose();
    navigate('/settings');
  };

  const handleOnLogoutClick = () => {
    handleClose();
    handleLogout();
  };

  return (
    <Stack borderTop="0.1rem solid" borderColor="secondary.light" p={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            src={loggedUser?.avatarUrl}
            sx={{
              width: '3.2rem',
              height: '3.2rem',
            }}
          />
          <Stack>
            <Typography>{loggedUser.firstname}</Typography>
            <Typography variant="body2" color="text.secondary">
              {selectedOrganization.name}
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <IconButton onClick={handleClick}>
            <ChevronRight sx={{ fontSize: '2rem', color: 'icon.dark' }} />
          </IconButton>
          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'menu-button',
            }}
          >
            {organizations.length > 1 && (
              <OrganizationsNestedMenu
                organizations={organizations}
                anchorEl={anchorEl}
                handleClose={handleClose}
              />
            )}
            <MenuItem onClick={handleOnSettingsClick}>
              <ListItemIcon>
                <CogIcon sx={{ fontSize: '2rem', color: 'icon.dark' }} />
              </ListItemIcon>
              <ListItemText>{t('employees.tab_settings')}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleOnLogoutClick}>
              <ListItemText>{t('menu.logout')}</ListItemText>
            </MenuItem>
          </StyledMenu>
        </Box>
      </Stack>
    </Stack>
  );
}
