export interface ActionStatus {
  success: boolean;
  error: boolean;
  errorMessage: string;
}

const defaultStatus: ActionStatus = {
  success: false,
  error: false,
  errorMessage: 'general.error_alert',
};

export default defaultStatus;
