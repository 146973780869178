import {
  projectStatuses,
  projectTypes,
} from 'components/ProjectsList/projectsSelectData';
import { Client } from 'store/clients/clients';
import { Project, ProjectPreview } from 'store/projects/interfaces';
import { ProjectStatuses } from 'enums';
import { BudgetPreview } from 'hooks/useBudgetPreview';

interface GetSelectOptionsProps {
  clients: Client[];
  projectPreviews: ProjectPreview[];
  project: Project;
  budgetPreviews: BudgetPreview[];
}

export default function getSelectOptions({
  clients,
  projectPreviews,
  project,
  budgetPreviews,
}: GetSelectOptionsProps) {
  const projectsFilteredByStatus = projectPreviews
    .filter(
      (currentProject) =>
        currentProject.status === ProjectStatuses.active &&
        currentProject.id !== project.id,
    )
    .map((currentProject) => ({
      value: currentProject['@id'],
      label: currentProject.name,
    }));
  const projectTypesOptions = projectTypes.map((item) => ({
    value: item.id,
    label: item.description,
  }));
  const projectStatusesOptions = projectStatuses.map((item) => ({
    value: item.id,
    label: item.description,
  }));
  const projectClientsOptions = clients.map((clientData) => ({
    value: clientData['@id'],
    label: clientData.name,
  }));
  const projectBudgetOptions = budgetPreviews.map((budgetData) => ({
    value: budgetData['@id'],
    label: budgetData.name,
  }));

  return {
    projectsFilteredByStatus,
    projectTypesOptions,
    projectStatusesOptions,
    projectClientsOptions,
    projectBudgetOptions,
  };
}
