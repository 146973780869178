import React from 'react';
import UserIcon from 'assets/icons/UserIcon';
import UsersIcon from 'assets/icons/UsersIcon';
import { BudgetType } from 'enums';
import { BudgetPeriod } from 'store/budget/interfaces';

export const budgetTypes = [
  {
    value: BudgetType.global,
    label: 'budget.budget_type_global',
    description: 'budget.budget_type_global_description',
    formIcon: <UsersIcon fontSize="large" />,
  },
  {
    value: BudgetType.employee,
    label: 'budget.budget_type_employee',
    description: 'budget.budget_type_employee_description',
    formIcon: <UserIcon fontSize="large" />,
  },
];

export const budgetPeriod = [
  { value: BudgetPeriod.month, label: 'budget.budget_period_month' },
  { value: BudgetPeriod.quarter, label: 'budget.budget_period_quarter' },
  { value: BudgetPeriod.year, label: 'budget.budget_period_year' },
  { value: BudgetPeriod.custom, label: 'budget.budget_period_custom' },
];
