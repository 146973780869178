import React from 'react';
import { createSvgIcon } from '@mui/material';

const BinIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.43684 4.19431C5.76502 2.71749 7.07489 1.66675 8.58773 1.66675H11.4129C12.9258 1.66675 14.2356 2.71749 14.5638 4.19431C14.7225 4.90856 15.3561 5.41675 16.0877 5.41675H17.5003C17.9606 5.41675 18.3337 5.78984 18.3337 6.25008C18.3337 6.71032 17.9606 7.08341 17.5003 7.08341H16.5526L15.4925 14.2394C15.4067 14.8185 15.3355 15.2991 15.2467 15.69C15.1541 16.098 15.0302 16.4684 14.8088 16.8105C14.4628 17.3454 13.9707 17.77 13.3909 18.0339C13.02 18.2027 12.6355 18.271 12.2183 18.3028C11.8186 18.3333 11.3327 18.3333 10.7474 18.3333H9.25328C8.66791 18.3333 8.18202 18.3333 7.78237 18.3028C7.36518 18.271 6.98065 18.2027 6.60975 18.0339C6.02991 17.77 5.53784 17.3454 5.19181 16.8105C4.97046 16.4684 4.84655 16.098 4.7539 15.69C4.66515 15.2991 4.59396 14.8185 4.50818 14.2394L3.44802 7.08341H2.50033C2.04009 7.08341 1.66699 6.71032 1.66699 6.25008C1.66699 5.78984 2.04009 5.41675 2.50033 5.41675H3.91292C4.6446 5.41675 5.27811 4.90856 5.43684 4.19431ZM5.13288 7.08341L6.15224 13.9641C6.2438 14.5821 6.30621 14.9995 6.3792 15.3209C6.44988 15.6322 6.51805 15.7922 6.59119 15.9053C6.76421 16.1727 7.01024 16.385 7.30016 16.5169C7.42271 16.5727 7.59096 16.6167 7.90924 16.641C8.23793 16.6661 8.65995 16.6667 9.28472 16.6667H10.7159C11.3407 16.6667 11.7627 16.6661 12.0914 16.641C12.4097 16.6167 12.5779 16.5727 12.7005 16.5169C12.9904 16.385 13.2364 16.1727 13.4095 15.9053C13.4826 15.7922 13.5508 15.6322 13.6215 15.3209C13.6944 14.9995 13.7568 14.5821 13.8484 13.9641L14.8678 7.08341H5.13288ZM13.2622 5.41675H6.73847C6.88474 5.15198 6.99553 4.86314 7.06382 4.55586C7.22254 3.8416 7.85605 3.33341 8.58773 3.33341H11.4129C12.1446 3.33341 12.7781 3.8416 12.9368 4.55586C13.0051 4.86314 13.1159 5.15198 13.2622 5.41675Z"
      fill="currentColor"
    />
  </svg>,
  'BinIcon',
);

export default BinIcon;
