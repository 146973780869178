import React, { useEffect, useState } from 'react';
import {
  useGetPropertyQuery,
  useUpdatePropertyMutation,
} from 'store/properties/properties';
import { Property } from 'store/properties/interfaces';
import { CustomError, ResponseErrors } from 'store/api';
import { Button, Grid, Tab } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { FormTypes } from 'enums';
import PropertyForm from '../PropertyFrom/PropertyForm';
import { formatFormData } from '../utils';
import PropertyChildren from '../PropertyChildren';
import { BookingType } from '../enums';
import { EditPropertyProps } from './interfaces';

function EditProperty({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditPropertyProps) {
  const { id } = useParams<{ id: string }>();
  const {
    data: propertyData,
    isError: propertiesFetchError,
    isLoading: propertiesFetching,
    isSuccess: propertiesFetched,
  } = useGetPropertyQuery(id as string, { skip: !id });

  const navigate = useNavigate();

  const [property, setProperty] = useState<Property>({
    '@id': '',
    id: 0,
    name: '',
    type: '',
    bookingType: '' as BookingType,
    times: 1,
    location: '',
    description: '',
    serialNumber: '',
    warrantyTo: '',
    bookingAllowed: true,
    children: [],
    status: '',
    parent: null,
    file: null,
  });
  const [editError, setEditError] = useState<ResponseErrors[]>([]);
  const [tabValue, setTabValue] = useState('1');
  const [updateProperty, { isLoading: propertyUpdating }] =
    useUpdatePropertyMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!propertiesFetched) {
      return;
    }
    const data = propertyData as Property;
    setProperty({ ...data });
  }, [propertyData, propertiesFetched]);

  const handleEditProperty = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    try {
      if (!id) {
        return;
      }
      await updateProperty({
        ...formatFormData(property, FormTypes.edit),
        id: +id,
        parent: property.parent || null,
        children: property.children,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
        setEditError((error as CustomError).data.violations);
      }
      setIsError(true);
    }
  };

  if (propertiesFetching) {
    return <Loader />;
  }

  if (propertiesFetchError) {
    return <Error />;
  }

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('properties.edit')}</NavBarTitle>
        <Button
          color="secondary"
          onClick={() => navigate('/properties')}
          variant="contained"
        >
          {t('button.back_to_list')}
        </Button>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={(event, newValue) => setTabValue(newValue)}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('tab.overview')} value="1" />
          <Tab label={t('tab.children')} value="2" />
        </StyledTabList>
        <TabPanel value="1">
          <PropertyForm
            property={property}
            handleSubmit={handleEditProperty}
            handleChange={(key, value) =>
              setProperty({ ...property, [key]: value })
            }
            error={editError}
            isLoading={propertyUpdating}
            formType={FormTypes.edit}
          />
        </TabPanel>
        <TabPanel value="2">
          <PropertyChildren property={property} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}

export default EditProperty;
