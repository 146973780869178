import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import ControlAutocomplete from 'common/ControlAutocomplete/ControlAutocomplete';
import IntegerMaskInput from 'common/MaskInputs/IntegerMaskInput';
import { useTranslation } from 'react-i18next';
import { useUpdateAgreementMutation } from 'store/agreements/agreements';
import CheckIcon from '@mui/icons-material/Check';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import formatAmountPayload from 'helpers/formatAmountPayload';
import getAmountMaskFormat from 'helpers/getAmountMaskFormat';
import { GeneralProps, FormPayload } from './interfaces';
import schema from './GeneralFormSchema';
import AmountInputs from './AmountInputs/AmountInputs';
import agreementTypes from './agreementTypes';

const validate = zodResolver(schema);

function GeneralForm({
  agreement,
  currencies,
  positions,
  setIsError,
  setIsSuccess,
  setErrorMessage,
}: GeneralProps) {
  const { t } = useTranslation();
  const initFormValues = {
    position: agreement.position,
    amount: getAmountMaskFormat(agreement.amount / BACKEND_AMOUNT_MODIFIER),
    currency: agreement.currency,
    hoursPerWeek: String(agreement.hoursPerWeek),
    billingType: agreement.billingType,
    amountType: agreement.amountType,
    type: agreement.type,
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: validate,
    mode: 'onBlur',
    defaultValues: initFormValues,
  });

  const [updateAgreement, { isLoading }] = useUpdateAgreementMutation();

  const isFormValuesChanged = !!Object.keys(dirtyFields).length;
  const disableSubmission = () => Object.values(errors).some((error) => error);

  const handleUpdateAgreement = async (data: FormPayload) => {
    try {
      await updateAgreement({
        id: agreement.id,
        position: data.position,
        amount: formatAmountPayload(data.amount, BACKEND_AMOUNT_MODIFIER),
        currency: data.currency,
        hoursPerWeek: +data.hoursPerWeek,
        billingType: data.billingType,
        amountType: data.amountType,
        type: data.type,
      }).unwrap();
      reset(data);
      setIsSuccess(true);
      reset(data);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box p={4} bgcolor="background.list" borderRadius="1.2rem">
      <Typography variant="h5" mb={4}>
        {t('agreements.edit_general')}
      </Typography>
      <form onSubmit={handleSubmit(handleUpdateAgreement)} noValidate>
        <Box display="flex" flexDirection="column" rowGap={3}>
          <ControlAutocomplete
            control={control}
            errors={errors}
            label="inputs.position"
            name="position"
            options={positions.map((position) => position.name)}
            required
          />
          <ControlSelect
            control={control}
            errors={errors}
            selectOptions={agreementTypes}
            label="label.type"
            name="type"
            required
          />
          <ControlTextField
            control={control}
            errors={errors}
            label="inputs.hours_per_week"
            name="hoursPerWeek"
            InputProps={{
              inputComponent: IntegerMaskInput as any,
            }}
            required
          />
          <AmountInputs
            currencies={currencies}
            control={control}
            errors={errors}
          />
        </Box>
        {isFormValuesChanged && (
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<CheckIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save_changes')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
}

export default GeneralForm;
