import { setOrganization } from 'store/organizations/actions';
import { Config } from 'store/organizations/defaultState';
import api from 'store/api';
import { setIsAuthorized, setToken } from 'store/authorization/actions';
import { useDispatch } from 'react-redux';

export default function useHandleLogout() {
  const dispatch = useDispatch();

  const logout = () => {
    setIsAuthorized(false);
    setToken(null);
    dispatch(api.util.resetApiState());
    dispatch(
      setOrganization({
        roles: [],
        possibleRoles: [],
        instance: null,
        name: null,
        defaultCurrency: null,
        features: [],
        config: {} as Config,
        id: '',
      }),
    );
    localStorage.removeItem('selectedOrganizationId');
    localStorage.removeItem('token');
  };

  return logout;
}
