import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  {
    align: 'left',
    label: 'label.author',
  },
  {
    align: 'left',
    label: 'label.description',
  },
  {
    align: 'center',
    label: 'label.rate',
  },
];

export default headers;
