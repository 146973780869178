import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Client } from 'store/clients/clients';
import { Invoice } from 'store/incomes/interfaces';
import CompanyInfo from '../CompanyInfo/CompanyInfo';

interface ContractorsProps {
  client: Client;
  invoice: Invoice;
}

export default function Contractors({ client, invoice }: ContractorsProps) {
  const { t } = useTranslation();

  const dates = [
    {
      label: 'invoices.issue_date',
      date: invoice.issueDate,
    },
    {
      label: 'invoices.sale_date',
      date: invoice.saleDate,
    },
  ];

  return (
    <Grid container>
      {/* TODO: change to real data from organization endpoint when available */}
      <CompanyInfo
        name="PRIMOTLY Sp. z o.o."
        street="ul. Człuchowska 9/6"
        postCode="01-360"
        city="Warszawa"
        vatNumber="PL5223071494"
      />
      <CompanyInfo
        name={client.name}
        street={client.addressStreetLine}
        postCode={client.addressPostCode}
        city={client.addressCity}
        vatNumber={client.vatNumber}
        isSeller={false}
      />
      <Grid
        item
        xs={4}
        display="flex"
        flexDirection="column"
        alignSelf="flex-end"
        rowGap={1}
        mb={1.5}
      >
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Typography color="text.secondary" variant="body1">
            {t('invoices.due_date')}
          </Typography>
          <Typography color="text.primary" fontWeight={700} variant="body1">
            {format(invoice.dueDate, 'yyyy-MM-dd')}
          </Typography>
        </Stack>
        {dates.map((date) => (
          <Stack
            key={date.label}
            direction="row"
            justifyContent="space-between"
          >
            <Typography color="text.secondary" variant="body1">
              {t(date.label)}
            </Typography>
            <Typography color="text.secondary" variant="body1">
              {format(date.date, 'yyyy-MM-dd')}
            </Typography>
          </Stack>
        ))}
      </Grid>
    </Grid>
  );
}
