import { styled } from '@mui/material';

const StyledInput = styled('input')(({ theme }) => ({
  border: '0.1rem solid',
  borderRadius: '1.2rem',
  borderColor: theme.palette.border.main,
  padding: '1.25rem 1.4rem',
  '&:hover': {
    borderColor: theme.palette.border.main,
  },
}));

export default StyledInput;
