import React, { useState } from 'react';
import {
  Box,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  useDeleteContactMutation,
  useGetContactsQuery,
} from 'store/clientContacts/clientContacts';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { useTranslation } from 'react-i18next';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import EditButton from 'common/EditButton/EditButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { ClientContact } from 'store/clientContacts/interfaces';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { Client } from 'store/clients/clients';
import headers from './headers';
import AddContact from './AddContact';
import EditContact from './EditContact';

export interface ContactsProps extends StatusProps {
  client: Client;
}

export default function Contacts({
  setIsSuccess,
  setIsError,
  setErrorMessage,
  client,
}: ContactsProps) {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    data: contacts = [],
    isLoading: contactsFetching,
    isError: contactsFetchError,
  } = useGetContactsQuery({ client: id }, { skip: !id });
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [deleteContact] = useDeleteContactMutation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<ClientContact>(
    {} as ClientContact,
  );
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const handleDeleteContact = async (contactId: number) => {
    try {
      await deleteContact(contactId).unwrap();
      setIsSuccess(true);
      setIsConfirmationDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  if (contactsFetching) {
    return <Loader />;
  }

  if (contactsFetchError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            <RowAddButton
              colSpan={headers.length + 1}
              onClick={() => setIsAddDialogOpen(true)}
            >
              <Typography variant="body2">{t('clients.add_contact')}</Typography>
            </RowAddButton>
            {contacts.length > 0 ? (
              contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell>
                    <Typography variant="body2">{contact.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{contact.email}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{contact.type}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      gap={1}
                    >
                      <EditButton
                        onClick={() => {
                          setSelectedContact(contact);
                          setIsEditDialogOpen(true);
                        }}
                      />
                      <IconButton
                        onClick={() => {
                          setSelectedContact(contact);
                          setIsConfirmationDialogOpen(true);
                        }}
                      >
                        <DeleteIcon color="error" fontSize="large" />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoDataRow headers={headers} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        onClose={() => setIsAddDialogOpen(false)}
        open={isAddDialogOpen}
      >
        <AddContact setIsOpen={setIsAddDialogOpen} client={client} />
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => setIsEditDialogOpen(false)}
        open={isEditDialogOpen}
      >
        <EditContact
          contact={selectedContact}
          setIsOpen={setIsEditDialogOpen}
        />
      </Dialog>
      <ConfirmationDialog
        handleAccept={() => handleDeleteContact(selectedContact.id)}
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
      />
    </>
  );
}
