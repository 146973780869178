import React, { useEffect, useState } from 'react';
import {
  CardMedia,
  Grid,
  Box,
  Typography,
  useMediaQuery,
  Theme,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import Link from 'common/Link/Link';
import Logo from 'assets/img/logo.svg';
import { Trans, useTranslation } from 'react-i18next';
import heroImage from 'assets/img/authorize-hero.jpg';

interface UnauthorizedTemplateProps {
  headerTextKey: string;
  headerLinkKey: string;
  headerLinkTo: string;
  form: React.ReactNode;
}

export default function UnauthorizedTemplate({
  headerTextKey,
  headerLinkKey,
  headerLinkTo,
  form,
}: UnauthorizedTemplateProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [languages, setLanguages] = useState<string[]>([]);

  useEffect(() => {
    if (!i18n.options.resources) {
      return;
    }
    setLanguages(Object.keys(i18n.options.resources));
  }, [i18n]);

  const getLngLink = () => {
    if (i18n.language === 'pl') {
      return 'https://flowtly.com/pl/';
    }
    return 'https://flowtly.com/';
  };

  return (
    <Grid container justifyContent="center" height="100vh" p={2}>
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        px={3}
      >
        <Box display="flex" justifyContent="space-between">
          <CardMedia
            component="img"
            loading="lazy"
            src={Logo}
            alt={t('menu.logo')}
            sx={{ width: '9rem', objectFit: 'contain' }}
          />
          <Link color="secondary" to={getLngLink()} underline="hover">
            {t('label.about')}
          </Link>
        </Box>
        <Grid container justifyContent="center">
          <Grid item xs={10} display="flex" flexDirection="column" gap={1.5}>
            <Typography variant="h4">{t(headerTextKey)}</Typography>
            <Typography color="secondary" variant="subtitle1">
              <Trans i18nKey={headerLinkKey}>
                <Link
                  color="primary"
                  to={headerLinkTo}
                  underline="hover"
                />
              </Trans>
            </Typography>
          </Grid>
          {form}
        </Grid>
        <Button
          id="lng-button"
          aria-controls={anchorEl ? 'lng-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
          color="secondary"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{ width: 'fit-content' }}
        >
          <Typography variant="body1">{i18n.language}</Typography>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{ 'aria-labelledby': 'lng-button' }}
        >
          {languages.map((language) => (
            <MenuItem
              key={language}
              onClick={() => {
                i18n.changeLanguage(language);
                setAnchorEl(null);
              }}
            >
              {language}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      {isDesktop && (
        <Grid item md={6} display="flex" alignItems="center">
          <CardMedia
            component="img"
            loading="lazy"
            src={heroImage}
            sx={{ width: '100%', height: { xl: '95vh' }, objectFit: 'contain' }}
          />
        </Grid>
      )}
    </Grid>
  );
}
