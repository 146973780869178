import React from 'react';
import { Stack, Typography, Chip } from '@mui/material';
import { Tag } from 'store/tags/interfaces';

export interface ResourceTileTagsProps {
  tags: Tag[];
}

export default function TileTags({ tags }: ResourceTileTagsProps) {
  const maxTagsToShow = 6;
  const tagsToDisplay = tags.slice(0, maxTagsToShow);
  const hiddenTagsCount = tags.length - maxTagsToShow;
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {tagsToDisplay.map((tag) => (
        <Chip
          variant="outlined"
          size="small"
          key={tag.id}
          label={
            <Typography variant="chipLabel">
              {tag.tagDefinition.name}
            </Typography>
          }
        />
      ))}
      {hiddenTagsCount > 0 && (
        <Chip
          size="small"
          label={
            <Typography variant="chipLabel">+{hiddenTagsCount}</Typography>
          }
        />
      )}
    </Stack>
  );
}
