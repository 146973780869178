export enum ProjectPhaseTypes {
  fixedPrice = 'fixed-price',
  timeAndMaterial = 'time-and-material',
  nonBillable = 'non-billable',
  internal = 'internal',
}

export interface ProjectPhase {
  ['@id']: string;
  amount: number;
  dateFrom: string;
  dateTo?: string;
  dateToPrediction?: string;
  id: number;
  name: string;
  project: string;
  type: ProjectPhaseTypes;
}

export interface ProjectPhaseMutationUpdateRequest
  extends Omit<
    ProjectPhase,
    '@id' | 'dateTo' | 'dateFrom' | 'dateToPrediction' | 'project'
  > {
  dateFrom: Date;
  dateTo?: Date | null;
  dateToPrediction?: Date | null;
}

export interface ProjectPhaseMutationAddRequest
  extends Omit<ProjectPhaseMutationUpdateRequest, 'id'> {
  project: string;
}
