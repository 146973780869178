import React from 'react';
import { createSvgIcon } from '@mui/material';

const ArrowsSwapIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.24"
      d="M3.33325 9C3.33325 7.59987 3.33325 6.8998 3.60574 6.36502C3.84542 5.89462 4.22787 5.51217 4.69828 5.27248C5.23306 5 5.93312 5 7.33325 5H12.6666C14.0667 5 14.7668 5 15.3016 5.27248C15.772 5.51217 16.1544 5.89462 16.3941 6.36502C16.6666 6.8998 16.6666 7.59987 16.6666 9V11C16.6666 12.4001 16.6666 13.1002 16.3941 13.635C16.1544 14.1054 15.772 14.4878 15.3016 14.7275C14.7668 15 14.0667 15 12.6666 15H7.33325C5.93312 15 5.23306 15 4.69828 14.7275C4.22787 14.4878 3.84542 14.1054 3.60574 13.635C3.33325 13.1002 3.33325 12.4001 3.33325 11V9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5774 1.91083C13.9028 1.58539 14.4305 1.58539 14.7559 1.91083L17.2559 4.41083C17.5814 4.73626 17.5814 5.2639 17.2559 5.58934L14.7559 8.08934C14.4305 8.41477 13.9028 8.41477 13.5774 8.08934C13.252 7.7639 13.252 7.23626 13.5774 6.91083L14.6548 5.83341H7.33333C6.61952 5.83341 6.13427 5.83406 5.7592 5.86471C5.39385 5.89456 5.20702 5.94866 5.07668 6.01507C4.76308 6.17486 4.50811 6.42983 4.34832 6.74343C4.28192 6.87376 4.22781 7.0606 4.19796 7.42595C4.16732 7.80102 4.16667 8.28627 4.16667 9.00008V9.16675C4.16667 9.62699 3.79357 10.0001 3.33333 10.0001C2.8731 10.0001 2.5 9.62699 2.5 9.16675L2.5 8.96567C2.49999 8.29486 2.49998 7.74122 2.53683 7.29023C2.5751 6.82181 2.65724 6.39123 2.86331 5.98678C3.18289 5.35957 3.69283 4.84964 4.32003 4.53006C4.72448 4.32398 5.15506 4.24185 5.62348 4.20358C6.07448 4.16673 6.62811 4.16674 7.29892 4.16675L14.6548 4.16675L13.5774 3.08934C13.252 2.7639 13.252 2.23626 13.5774 1.91083ZM17.5 10.8334V11.0345C17.5 11.7053 17.5 12.2589 17.4632 12.7099C17.4249 13.1784 17.3428 13.6089 17.1367 14.0134C16.8171 14.6406 16.3072 15.1505 15.68 15.4701C15.2755 15.6762 14.8449 15.7583 14.3765 15.7966C13.9255 15.8334 13.3719 15.8334 12.7011 15.8334H5.34518L6.42259 16.9108C6.74803 17.2363 6.74803 17.7639 6.42259 18.0893C6.09715 18.4148 5.56952 18.4148 5.24408 18.0893L2.74408 15.5893C2.41864 15.2639 2.41864 14.7363 2.74408 14.4108L5.24408 11.9108C5.56952 11.5854 6.09715 11.5854 6.42259 11.9108C6.74803 12.2363 6.74803 12.7639 6.42259 13.0893L5.34518 14.1667H12.6667C13.3805 14.1667 13.8657 14.1661 14.2408 14.1355C14.6061 14.1056 14.793 14.0515 14.9233 13.9851C15.2369 13.8253 15.4919 13.5703 15.6517 13.2567C15.7181 13.1264 15.7722 12.9396 15.802 12.5742C15.8327 12.1991 15.8333 11.7139 15.8333 11.0001V10.8334C15.8333 10.3732 16.2064 10.0001 16.6667 10.0001C17.1269 10.0001 17.5 10.3732 17.5 10.8334Z"
      fill="currentColor"
    />
  </svg>,
  'ArrowsSwapIcon',
);

export default ArrowsSwapIcon;
