import api from 'store/api';
import {
  PipeDriveFilters,
  PipeDriveLeads,
  PipeDriveLeadsResponseDto,
  PipeDriveSummariesResponseDto,
  PipeDriveSummary,
} from './interfaces';

export const pipeDriveLeadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPipeDriveLeads: builder.query<
      PipeDriveLeads[],
      Partial<PipeDriveFilters>
    >({
      query: (filter) => ({
        url: '/pipe-drive-leads',
        params: filter,
      }),
      providesTags: ['PipeDriveLeads'],
      transformResponse: (response: PipeDriveLeadsResponseDto) =>
        response['hydra:member'],
    }),
    getPipeDriveSummary: builder.query<PipeDriveSummary[], void>({
      query: () => '/pipe-drive-leads/summary',

      transformResponse: (response: PipeDriveSummariesResponseDto) =>
        response['hydra:member'],
      providesTags: ['PipeDriveLeads'],
    }),
  }),
});

export const { useLazyGetPipeDriveLeadsQuery, useGetPipeDriveSummaryQuery } =
  pipeDriveLeadApi;
