import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import 'style/index.css';
import Layout from 'components/Layout';
import routes from 'config/routing/routing';
import useOrganization from 'hooks/useOrganization';
import Organizations from 'pages/Organizations/Organizations';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import ReactGA from 'react-ga4';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { getOrganization } from 'store/organizations/selectors';
import useServerEvents from 'hooks/useServerEvents';
import { LoggedEmployee } from 'store/employee/interfaces';
import useBudgetPreview from 'hooks/useBudgetPreview';

export default function App() {
  const {
    isOrganizationChoice,
    organizations,
    organizationsLoading,
    organizationsError,
  } = useOrganization();
  const selectedOrganizationId = localStorage.getItem('selectedOrganizationId');

  const organization = useSelector(getOrganization);
  const {
    data: loggedEmployee = {} as LoggedEmployee,
    refetch,
    isError: loggedEmployeeError,
    isLoading: loggedEmployeeLoading,
  } = useGetLoggedEmployeeQuery(undefined, {
    skip: !selectedOrganizationId || !organization.instance,
  });

  useBudgetPreview();
  useServerEvents(loggedEmployee);

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    refetch();
  }, [organization.instance, refetch]);

  useEffect(() => {
    if (Notification.permission !== 'denied') {
      Notification.requestPermission();
    }
  }, []);

  if (organizationsLoading || loggedEmployeeLoading) {
    return <Loader />;
  }

  if (organizationsError || loggedEmployeeError) {
    return <Error />;
  }

  return (
    <>
      {isOrganizationChoice ? (
        <Organizations organizations={organizations} />
      ) : (
        <Layout>
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={route.component}
              />
            ))}
            <Route>404</Route>
          </Routes>
        </Layout>
      )}
    </>
  );
}
