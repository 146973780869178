import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import replaceApiIri from 'helpers/replaceApiIri';

export default function useCurrencySymbol() {
  const { i18n } = useTranslation();
  const organizationCurrencyIri = useSelector(
    (state: RootState) => state.organization.organization.defaultCurrency,
  );
  const organizationCurrencyCode = replaceApiIri(
    organizationCurrencyIri || '',
    'currencies',
  );

  const getCurrencySymbol = (currencyCode?: string) => {
    const formatter = new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: currencyCode ?? organizationCurrencyCode,
      currencyDisplay: 'narrowSymbol',
    });

    const parts = formatter.formatToParts(0);
    const currencySymbol = parts.find(
      (part) => part?.type === 'currency',
    )?.value;

    return currencySymbol;
  };

  return getCurrencySymbol;
}
