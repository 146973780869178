import { Typography, TypographyOwnProps } from '@mui/material';
import React from 'react';

interface WidgetCaptionProps {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  secondary?: boolean;
}

const sizeMap = {
  sm: 'caption',
  md: 'bold',
  lg: 'body1',
};

export default function WidgetCaption({
  children,
  size = 'md',
  secondary = false,
}: WidgetCaptionProps) {
  const variant = sizeMap[size] as TypographyOwnProps['variant'];
  return (
    <Typography
      variant={variant}
      color={secondary ? 'text.secondary' : 'text.primary'}
    >
      {children}
    </Typography>
  );
}
