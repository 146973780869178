import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Client, useGetClientQuery } from 'store/clients/clients';
import { useTranslation } from 'react-i18next';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import headers from 'components/EditClientForm/Payments/headers';
import Balance from 'components/EditClientForm/Payments/Balance/Balance';
import {
  TransactionsData,
  useGetUnassignedTransactionsQuery,
} from 'store/transactions/transactions';
import { useGetIncomesQuery } from 'store/incomes/incomes';
import { InvoicesData } from 'store/incomes/interfaces';
import Result from './Result/Result';

function Payments() {
  const { id } = useParams<{ id: any }>();
  const { t } = useTranslation();

  const {
    data: client = {} as Client,
    isFetching: clientFetching,
    isError: clientError,
  } = useGetClientQuery(id, { skip: !id });
  const {
    data: incomes = {} as InvoicesData,
    isFetching: incomesFetching,
    isError: incomesError,
  } = useGetIncomesQuery({ client: id }, { skip: !id });
  const {
    data: transactions = {} as TransactionsData,
    isFetching: transactionsFetching,
    isError: transactionsError,
  } = useGetUnassignedTransactionsQuery(
    {
      cost: String(client.defaultCost),
    },
    {
      skip: !client.defaultCost,
    },
  );

  const incomeTransactions = incomes['hydra:member']?.flatMap(
    (income) => income.incomeTransactions,
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={headers.length} />
          </TableRow>
        </TableHead>
        <TableBodyContent
          tableHeaders={headers}
          isError={clientError || incomesError || transactionsError}
          isFetching={clientFetching || incomesFetching || transactionsFetching}
        >
          {incomes?.['hydra:member']?.map((income) => (
            <Result
              key={income.id}
              income={income}
              client={client}
              incomeTransactions={incomeTransactions}
              transactions={transactions['hydra:member']}
            />
          ))}
          {incomes?.['hydra:member']?.length > 0 && (
            <Balance
              notAssignedTransactions={transactions['hydra:member']}
              client={client}
              invoices={incomes['hydra:member']}
            />
          )}
        </TableBodyContent>
      </Table>
    </TableContainer>
  );
}

export default Payments;
