import React from 'react';
import { useGetCountriesQuery, Country } from 'store/countries/countries';
import { Typography } from '@mui/material';

export interface CountryProps {
  countryIri: string;
}

const emptyCountries = {} as Country;

export default function CountryName({ countryIri }: CountryProps) {
  const { country } = useGetCountriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      country:
        data?.find((item) => item['@id'] === countryIri) ?? emptyCountries,
    }),
  });

  return <Typography variant="body2">{country?.name}</Typography>;
}
