import React from 'react';
import { CardMedia, Stack, Typography } from '@mui/material';
import AddButton from 'common/AddButton/AddButton';
import InvoiceWithCalculatorUrl from 'assets/img/invoice-with-calculator.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function EmptyBankAccountsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack
      spacing={8}
      alignItems="center"
      bgcolor="background.other"
      minHeight="calc(100vh - 8rem)"
      pt={18}
      px={12}
      pb={12}
    >
      <CardMedia
        sx={{
          width: '25.6rem',
          height: '25.6rem',
        }}
        component="img"
        loading="lazy"
        src={InvoiceWithCalculatorUrl}
        alt="Bank"
      />
      <Stack spacing={2} alignItems="center" width="50%">
        <Typography textAlign="center" variant="h5">
          {t('invoices.no_bank_account_added')}
        </Typography>
        <Typography
          textAlign="center"
          variant="subtitle1"
          color="text.secondary"
        >
          {t('invoices.add_bank_account_description')}
        </Typography>
      </Stack>
      <AddButton
        onClick={() => navigate('/banks')}
        label="invoices.add_bank_account"
      />
    </Stack>
  );
}
