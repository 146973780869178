import { z } from 'zod';

const schema = z.object({
  position: z.string().min(1, { message: 'errors.field_required' }),
  start: z.string(),
  description: z.string(),
  client: z.string(),
  location: z.string(),
});

export default schema;
