import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetEmailDataQuery,
  useGetIncomeQuery,
  useSendEmailMutation,
} from 'store/incomes/incomes';
import { useTranslation } from 'react-i18next';
import CcData, { CcItem } from 'components/InvoiceSending/CcData/CcData';
import { useValidate } from 'hooks/useValidate';
import checkEmail from 'helpers/validators/checkEmail/checkEmail';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import UndoIcon from '@mui/icons-material/Undo';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { Invoice, EmailData } from 'store/incomes/interfaces';
import SendButton from 'common/SendButton/SendButton';

interface Errors {
  name: boolean;
  email: boolean;
}

export interface InvoiceSendingProps extends StatusProps {}

function InvoiceSending({
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: InvoiceSendingProps) {
  const { id } = useParams<{ id: any }>();
  const { data: income = {} as Invoice } = useGetIncomeQuery(id);
  const {
    data: emailData = {} as EmailData,
    isLoading,
    isError,
    isSuccess,
  } = useGetEmailDataQuery(id);
  const { t } = useTranslation();
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [ccData, setCcData] = useState<CcItem[]>([
    {
      email: '',
      name: '',
    },
  ]);
  const [message, setMessage] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const [sendEmail] = useSendEmailMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    setCustomerName(emailData.address.name);
    setCustomerEmail(emailData.address.email);
    if (emailData.ccs.length > 0) {
      const [name, email] = emailData.ccs;
      setCcData([{ name, email }]);
    }
    setMessage(emailData.mailContent);
  }, [isSuccess, emailData]);

  const disableSubmission = () =>
    !customerName ||
    !customerEmail ||
    Object.values(errors).some((error) => error);

  const handleSendEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const sendObject = {
      id,
      mailContent: message,
      address: {
        email: customerEmail,
        name: customerName,
      },
      ccs: ccData,
    };
    try {
      await sendEmail(sendObject).unwrap();
      setIsSuccess(true);
      navigate('/invoices');
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <Grid item xs={10} bgcolor="background.main">
      <NavBarContainer>
        <NavBarTitle>
          {t('invoices.send_title', {
            name: income.name,
          })}
        </NavBarTitle>
        <Button
          onClick={() => navigate(`/invoices/${id}/edit`)}
          startIcon={<UndoIcon />}
          variant="contained"
        >
          {t('invoices.edition')}
        </Button>
      </NavBarContainer>
      <form onSubmit={handleSendEmail}>
        <Stack spacing={3} p={3}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('invoices.client_data')}
          </Typography>
          <Box display="flex" gap={3}>
            <TextField
              error={errors.email}
              fullWidth
              helperText={errors.email && t('errors.wrong_mail')}
              label={t('inputs.customer_email')}
              name="customerMail"
              onBlur={({ target }) =>
                validate('email', checkEmail(target.value))
              }
              onChange={({ target }) => setCustomerEmail(target.value)}
              required
              value={customerEmail}
            />
            <TextField
              error={errors.name}
              helperText={errors.name && t('errors.too_short_name')}
              fullWidth
              label={t('inputs.customer_name')}
              name="customerName"
              onBlur={({ target }) =>
                validate('name', target.value.length >= 2)
              }
              onChange={({ target }) => setCustomerName(target.value)}
              required
              value={customerName}
            />
          </Box>
          <Divider />
          <CcData ccData={ccData} setCcData={setCcData} />
          <Divider />
          <Typography variant="subtitle1" fontWeight={700}>
            {t('inputs.message')}
          </Typography>
          <TextField
            fullWidth
            label={t('inputs.message')}
            minRows={2}
            multiline
            name="message"
            onChange={({ target }) => setMessage(target.value)}
            value={message}
          />
          <Box display="flex" justifyContent="flex-end">
            <SendButton
              disabled={disableSubmission()}
              label="invoices.send_invoice"
            />
          </Box>
        </Stack>
      </form>
    </Grid>
  );
}

export default InvoiceSending;
