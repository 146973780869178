import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import checkEmail from 'helpers/validators/checkEmail/checkEmail';
import { useValidate } from 'hooks/useValidate';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  useGetGoogleCalendarIdQuery,
  useUpdateGoogleCalendarIdMutation,
} from 'store/config/config';
import { ConfigResponse } from 'store/config/interfaces';
import { StatusProps } from 'hoc/Status/Status';
import { LoadingButton } from '@mui/lab';
import { CustomError } from 'store/api';

export interface CalendarIdProps extends StatusProps {}

interface Errors {
  id: boolean;
}
function CalendarId({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: CalendarIdProps) {
  const [calendarId, setCalendarId] = useState('');
  const [valueChanged, setValueChanged] = useState(false);
  const { validate, errors } = useValidate<Errors>();
  const { t } = useTranslation();
  const {
    data: response = {} as ConfigResponse<string>,
    isSuccess: calendarIdFetched,
  } = useGetGoogleCalendarIdQuery();
  const [integrateCalendar, { isLoading }] =
    useUpdateGoogleCalendarIdMutation();

  useEffect(() => {
    if (!calendarIdFetched) {
      return;
    }
    setCalendarId(response.value);
  }, [calendarIdFetched, response]);

  const handleIntegrateCaledar = async () => {
    try {
      await integrateCalendar({
        value: calendarId,
      }).unwrap();
      setIsSuccess(true);
      setValueChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1" sx={{ width: '75%' }}>
              {t('google_calendar.id_label')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              error={errors.id}
              helperText={errors.id && t('errors.wrong_id')}
              id="calendar-id"
              label={t('google_calendar.id')}
              onBlur={(event) => validate('id', checkEmail(event.target.value))}
              onChange={(event) => {
                setCalendarId(event.target.value);
                setValueChanged(true);
              }}
              required
              value={calendarId}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            {valueChanged && (
              <LoadingButton
                disabled={errors.id}
                endIcon={<CheckIcon />}
                loading={isLoading}
                onClick={handleIntegrateCaledar}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default CalendarId;
