import React from 'react';
import { Typography, TypographyOwnProps } from '@mui/material';
import { useGetClientsQuery } from 'store/clients/clients';

export interface ClientNameProps {
  clientIri: string;
  variant?: TypographyOwnProps['variant'];
}

export default function ClientName({
  clientIri,
  variant = 'body2',
}: ClientNameProps) {
  const { data: clients = [] } = useGetClientsQuery(
    { properties: ['id', 'name'] },
  );

  const client = clients.find((item) => item['@id'] === clientIri);

  return <Typography variant={variant}>{client?.name}</Typography>;
}
