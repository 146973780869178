import {
  projectTypes,
  projectStatuses,
} from 'components/ProjectsList/projectsSelectData';
import { Budget } from 'store/budget/interfaces';

export default function getSelectOptions(budgets: Budget[]) {
  const projectTypesOptions = projectTypes.map((item) => ({
    value: item.id,
    label: item.description,
  }));
  const projectStatusesOptions = projectStatuses.map((item) => ({
    value: item.id,
    label: item.description,
  }));
  const projectBudgetOptions = budgets.map((budgetData) => ({
    value: budgetData['@id'],
    label: budgetData.name,
  }));

  return {
    projectTypesOptions,
    projectStatusesOptions,
    projectBudgetOptions,
  };
}
