import { styled } from '@mui/material';
import { TabList, TabListProps } from '@mui/lab';

const StyledTabList = styled(TabList)<TabListProps>(({ theme }) => ({
  backgroundColor: theme.palette.main.white,
  borderBottom: `0.1rem solid ${theme.palette.divider}`,
  padding: `0 ${theme.spacing(3)}`,
}));

export default StyledTabList;
