import React from 'react';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import IntegerMaskInput from 'common/MaskInputs/IntegerMaskInput';
import ActionOutlinedButton from 'common/ActionOutlinedButton/ActionOutlinedButton';
import { useNipValidationMutation } from 'store/costs/costs';
import { setErrorCatch } from 'store/status/actions';
import { VatNumberCountryInputsProps } from './interfaces';
import StyledFormControlCountry from './FormControlCountry.styled';
import StyledTextFieldVatNumber from './TextFieldVatNumber.styled';
import COUNTRY_PL_VALUE from '../consts';

export default function VatNumberCountryInputs<T extends FieldValues>({
  control,
  errors,
  countries,
  setError,
  setValue,
  watch,
  trigger,
}: VatNumberCountryInputsProps<T>) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [nipValidation, { isLoading: nipValidationLoading }] =
    useNipValidationMutation();
  const { vatNumber, country } = watch();

  const isValidateNipDisabled =
    !vatNumber || country !== COUNTRY_PL_VALUE || !!errors.vatNumber;

  const handleValidateNip = async () => {
    try {
      const response = await nipValidation({ nip: vatNumber }).unwrap();
      if (Array.isArray(response)) {
        setError('vatNumber' as Path<T>, {
          type: 'manual',
          message: t('errors.vat_number_not_found'),
        });
        return;
      }
      const { city, street, postCode, name: clientName } = response;
      setValue('name' as Path<T>, clientName as PathValue<T, Path<T>>);
      setValue('addressCity' as Path<T>, city as PathValue<T, Path<T>>);
      setValue('addressStreetLine' as Path<T>, street as PathValue<T, Path<T>>);
      setValue('addressPostCode' as Path<T>, postCode as PathValue<T, Path<T>>);
      setValue(
        'addressCountry' as Path<T>,
        COUNTRY_PL_VALUE as PathValue<T, Path<T>>,
      );
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <Box display="flex" width="75%">
        <Controller
          name={'country' as Path<T>}
          control={control}
          render={({ field: { onBlur, value, onChange } }) => (
            <StyledFormControlCountry required error={!!errors.country}>
              <InputLabel id="country-label">{t('clients.country')}</InputLabel>
              <Select
                labelId="country-label"
                label={t('clients.country')}
                value={value}
                onChange={(event) => {
                  onChange(event);
                  trigger('vatNumber' as Path<T>);
                }}
                onBlur={onBlur}
                inputProps={{
                  name: 'country',
                  id: 'country',
                }}
              >
                {countries.map((countryItem) => (
                  <MenuItem key={countryItem.value} value={countryItem.value}>
                    {countryItem.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors?.country?.message &&
                  t(errors?.country?.message as string)}
              </FormHelperText>
            </StyledFormControlCountry>
          )}
        />
        <Controller
          name={'vatNumber' as Path<T>}
          control={control}
          render={({ field: { onBlur, value, onChange } }) => (
            <StyledTextFieldVatNumber
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              error={!!errors.vatNumber}
              helperText={
                errors?.vatNumber?.message &&
                t(errors?.vatNumber?.message as string)
              }
              id="vatNumber"
              label={t('clients.vat_number')}
              InputProps={{
                inputComponent: IntegerMaskInput as any,
                inputProps: {
                  maxLength: 10,
                },
              }}
            />
          )}
        />
      </Box>
      <ActionOutlinedButton
        sx={{
          height: '4.74rem',
          width: '25%',
        }}
        onClick={handleValidateNip}
        loading={nipValidationLoading}
        disabled={isValidateNipDisabled}
        label="clients.download_from_gus"
      />
    </Stack>
  );
}
