import api from 'store/api';
import { ApiResponse } from 'store/interfaces';

export interface Language {
  id: string;
}

export interface LanguagesResponseDto extends ApiResponse {
  'hydra:member': Language[];
}

export const languagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query<Language[], void>({
      query: () => '/languages',

      transformResponse: (response: LanguagesResponseDto) =>
        response['hydra:member'],
      providesTags: ['Languages'],
    }),
  }),
});

export const { useGetLanguagesQuery } = languagesApi;
