import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'employees.header_name',
    align: 'left',
  },
  {
    label: 'employees.header_position',
    align: 'left',
  },
  {
    label: 'employees.header_free_days_left',
    align: 'center',
  },
  {
    label: 'employees.header_holidays_per_year',
    align: 'center',
  },
  {
    label: 'employees.header_contract_type',
    align: 'center',
  },
  {
    label: 'employees.header_agreement',
    align: 'center',
  },
];

export default tableHeaders;
