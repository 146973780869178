import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from 'react-router-dom';
import {
  Client,
  useGetClientQuery,
  useUpdateClientMutation,
} from 'store/clients/clients';
import ClientFormSelect from 'components/AddClientForm/ClientFormSelect';
import { useGetCountriesQuery } from 'store/countries/countries';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface AddressProps extends StatusProps {}

function Address({ setIsSuccess, setIsError, setErrorMessage }: AddressProps) {
  const { id } = useParams<{ id: any }>();
  const { data: client = {} as Client } = useGetClientQuery(id);
  const { data: countries = [] } = useGetCountriesQuery();
  const [streetLine, setStreetLine] = useState(client.addressStreetLine);
  const [city, setCity] = useState(client.addressCity);
  const [postCode, setPostCode] = useState(client.addressPostCode);
  const [country, setCountry] = useState(client.addressCountry || '');
  const [phoneNumber, setPhoneNumber] = useState(client.addressPhoneNumber);
  const [formChanged, setFormChanged] = useState(false);
  const [updateClient, { isLoading }] = useUpdateClientMutation();
  const { t } = useTranslation();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setValue: React.Dispatch<React.SetStateAction<string | undefined>>,
  ) => {
    setValue(event.target.value);
    setFormChanged(true);
  };

  const handleClientUpdate = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateClient({
        id,
        addressCity: city || null,
        addressCountry: country || null,
        addressPhoneNumber: phoneNumber || null,
        addressPostCode: postCode || null,
        addressStreetLine: streetLine || null,
      }).unwrap();
      setIsSuccess(true);
      setFormChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box p={4} bgcolor="main.white" borderRadius="1.2rem">
      <form onSubmit={handleClientUpdate}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gap={3}
        >
          <TextField
            id="street-line"
            fullWidth
            label={t('label.street')}
            name="streetLine"
            onChange={(event) => handleInputChange(event, setStreetLine)}
            value={streetLine}
          />
          <Box display="flex" alignItems="center" gap={2} width="100%">
            <TextField
              id="city"
              fullWidth
              label={t('label.city')}
              name="city"
              onChange={(event) => handleInputChange(event, setCity)}
              value={city}
            />
            <TextField
              id="post-code"
              fullWidth
              label={t('label.post_code')}
              name="postCode"
              onChange={(event) => handleInputChange(event, setPostCode)}
              value={postCode}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2} width="100%">
            <ClientFormSelect
              isRequired={false}
              label={t('clients.country')}
              onChangeFn={(event) => {
                setCountry(event.target.value);
                setFormChanged(true);
              }}
              placeholder={t('inputs.choose_country')}
              selectId="country-select"
              selectOptions={countries.map((item) => ({
                id: item['@id'],
                name: item.name,
              }))}
              value={country}
            />
            <TextField
              id="phone-number"
              fullWidth
              label={t('label.phone_number')}
              name="phoneNumber"
              onChange={(event) => handleInputChange(event, setPhoneNumber)}
              type="tel"
              value={phoneNumber}
            />
          </Box>
          {formChanged && (
            <LoadingButton
              endIcon={<CheckIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          )}
        </Box>
      </form>
    </Box>
  );
}

export default Address;
