import React, { useState } from 'react';
import { Box, Grid, Typography, Divider, Dialog } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { Responsibility } from 'store/responsibilityGroups/interfaces';
import ReactEChart from 'echarts-for-react';
import { SeriesChart } from 'components/CostsChart/interfaces';
import chartsPallette from 'helpers/chartsPallette';
import { useTranslation } from 'react-i18next';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import EditButton from 'common/EditButton/EditButton';
import EditResponsibility from '../EditResponsibility';

interface DetailsProps {
  responsibility: Responsibility;
}

export default function Details({ responsibility }: DetailsProps) {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { t } = useTranslation();
  const seriesData: SeriesChart[] = [];
  const { data: employees = [] } = useGetEmployeesPreview();

  responsibility.responsibilityEmployees.forEach((employee) => {
    const employeeData = employees.find(
      (employeePreview) => employeePreview['@id'] === employee.employee,
    );

    seriesData.push({
      name: `${employeeData?.lastname} ${employeeData?.firstname}`,
      type: 'bar',
      stack: 'percentage',
      data: [employee.percentage],
    });
  });

  const employeePercentageSum = responsibility.responsibilityEmployees.reduce(
    (acc, { percentage }) => acc + percentage,
    0,
  );

  if (employeePercentageSum !== 100) {
    seriesData.push({
      name: t('responsibilities.unassigned'),
      type: 'bar',
      stack: 'percentage',
      data: [100 - employeePercentageSum],
      color: ['#EEEDF3'],
    });
  }

  const chartOptions = {
    color: chartsPallette,
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      type: 'value',
      show: false,
    },
    yAxis: {
      type: 'category',
      show: false,
      data: [responsibility.name],
    },
    series: seriesData,
  };

  return (
    <>
      <Box display="flex" flexDirection="column" mx={3}>
        <Grid container alignItems="center" mb={2}>
          <Grid item xs={5}>
            <TitleTooltip
              placement="bottom-start"
              title={responsibility.description}
            >
              <Typography variant="subtitle1">{responsibility.name}</Typography>
            </TitleTooltip>
          </Grid>
          <Grid item xs={6}>
            <ReactEChart
              notMerge
              option={chartOptions}
              style={{ width: '100%', height: '2rem' }}
            />
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <EditButton onClick={() => setIsEditDialogOpen(true)} />
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2 }} />
      </Box>
      <Dialog
        fullWidth
        onClose={() => setIsEditDialogOpen(false)}
        open={isEditDialogOpen}
      >
        <EditResponsibility
          responsibility={responsibility}
          setIsOpen={setIsEditDialogOpen}
        />
      </Dialog>
    </>
  );
}
