/* eslint-disable no-continue */
import { endOfMonth, startOfMonth } from 'date-fns';
import { Budget } from 'store/budget/interfaces';
import dateFormat from 'dateformat';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import calculateBaseAmountFromBudgetConfig from './calculateBaseAmountFromBudgetConfig';
import { FrontendBudgetConfig } from './BudgetPlanner.context';

const draftBaseAmount = 1000 * BACKEND_AMOUNT_MODIFIER;
const draftExponentialGrowth = 3;

export function formatPeriod(dateStr: string): string {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4);
  return `${year}.${month}`;
}

const getDraftBudgetConfig = (budget: Budget): Budget => ({
  ...budget,
  config: [
    {
      period: dateFormat(budget.dateFrom, 'yyyymm'),
      baseAmount: draftBaseAmount,
      exponentialGrowth: draftExponentialGrowth,
    },
  ],
});

export default function createBudgetConfigData(
  budget: Budget,
  useDraftDefaultConfig = false,
): FrontendBudgetConfig[] {
  const preparedBudget =
    !budget.config.length && useDraftDefaultConfig
      ? getDraftBudgetConfig(budget)
      : budget;
  const dateFrom = startOfMonth(new Date(preparedBudget.dateFrom as string));
  const dateTo = endOfMonth(new Date(preparedBudget.dateTo as string));
  const chartData: FrontendBudgetConfig[] = [];

  let placeholderBaseAmount = 0;
  let placeholderLinearGrowth = 0;
  let placeholderExponentialGrowth = 0;
  let previousBudgetConfig: FrontendBudgetConfig | null = null;

  for (
    let periodDate = dateFrom;
    periodDate < dateTo;
    periodDate.setMonth(periodDate.getMonth() + 1)
  ) {
    const period = dateFormat(periodDate, 'yyyymm');

    const budgetConfig = preparedBudget.config.find(
      (configItem) => configItem.period === period,
    );

    if (budgetConfig) {
      const baseAmountFromPrevious = previousBudgetConfig
        ? calculateBaseAmountFromBudgetConfig(previousBudgetConfig)
        : 0;

      placeholderBaseAmount =
        budgetConfig.baseAmount !== undefined
          ? budgetConfig.baseAmount / BACKEND_AMOUNT_MODIFIER
          : baseAmountFromPrevious;
      placeholderLinearGrowth =
        budgetConfig.linearGrowth !== undefined
          ? budgetConfig.linearGrowth
          : placeholderLinearGrowth;
      placeholderExponentialGrowth =
        budgetConfig.exponentialGrowth !== undefined
          ? budgetConfig.exponentialGrowth
          : placeholderExponentialGrowth;

      previousBudgetConfig = {
        ...budgetConfig,
        ...(budgetConfig.baseAmount && { baseAmount: placeholderBaseAmount }),
        period: formatPeriod(budgetConfig.period),
        placeholderBaseAmount,
        placeholderLinearGrowth,
        placeholderExponentialGrowth,
      };

      chartData.push(previousBudgetConfig);

      continue;
    }

    const baseAmountFromPrevious = previousBudgetConfig
      ? calculateBaseAmountFromBudgetConfig(previousBudgetConfig)
      : 0;
    placeholderBaseAmount = baseAmountFromPrevious;

    previousBudgetConfig = {
      period: formatPeriod(period),
      placeholderBaseAmount,
      placeholderLinearGrowth,
      placeholderExponentialGrowth,
    };
    chartData.push(previousBudgetConfig);
  }
  return chartData;
}
