import { Typography, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCandidateContext from 'pages/HumanResourceCandidate/context/useCandidateContext';
import openFileWithAuth from 'helpers/openFileWithAuth';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import DownloadIcon from 'assets/icons/DownloadIcon';
import { useUpdateCandidateMutation } from 'store/candidates/candidates';
import { CandidateRelationName } from 'store/candidates/interfaces';
import { CustomError } from 'store/api';
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from 'store/storage/storage';
import { ResumeUploadProps } from './interfaces';

export default function ResumeUpload({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: ResumeUploadProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { candidate } = useCandidateContext();
  const candidateResume = candidate?.files?.find(
    (candidateFile) =>
      candidateFile.relationName === CandidateRelationName.resume,
  );
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [updateCandidate] = useUpdateCandidateMutation();
  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const handleDeleteCandidateResume = async () => {
    if (!candidateResume) {
      return;
    }
    try {
      await deleteFile(candidateResume.id).unwrap();
      const isOtherCandidateFilesExist =
        !!candidate?.files?.length && candidate?.files?.length > 1;

      const candidateFiles = isOtherCandidateFilesExist
        ? candidate?.files?.filter(
            (candidateFile) => candidateFile.id !== candidateResume.id,
          )
        : null;

      const payload = {
        id: candidate.id,
        files: candidateFiles,
      };

      await updateCandidate(payload).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleUploadFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    const file = event.target.files[0];
    try {
      const fileData = new FormData();
      fileData.append('file', file, file.name);
      fileData.append('relationName', CandidateRelationName.resume);
      fileData.append('relationId', candidate.id);

      const uploadedFile = await uploadFile(fileData).unwrap();
      const candidateFiles = candidate?.files ? [...candidate.files] : [];

      candidateFiles.push(uploadedFile);
      const payload = {
        id: candidate.id,
        files: candidateFiles,
      };
      await updateCandidate(payload).unwrap();
      setIsSuccess(true);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleOpenFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (candidateResume) {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" color="primary.main">
              {candidate.firstname}-{candidate.lastname}-cv
            </Typography>
            <IconButton onClick={() => setIsDeleteDialogOpen(true)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <IconButton onClick={() => openFileWithAuth(candidateResume.url)}>
            <DownloadIcon
              sx={{
                color: 'icon.dark',
                fontSize: '2rem',
              }}
            />
          </IconButton>
        </Stack>
        <ConfirmationDialog
          isOpen={isDeleteDialogOpen}
          setIsOpen={setIsDeleteDialogOpen}
          handleAccept={handleDeleteCandidateResume}
        />
      </>
    );
  }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadFile}
        style={{ display: 'none' }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pl={1}
      >
        <Typography
          variant="body2"
          color="primary.main"
          flexBasis="80%"
          sx={{ cursor: 'pointer', wordBreak: 'break-all' }}
          onClick={handleOpenFileUpload}
        >
          {t('human_resources.upload_resume')}
        </Typography>
      </Stack>
    </>
  );
}
