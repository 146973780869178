/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import defaultState from 'store/organizations/defaultState';
import {
  setOrganization,
  setIsOrganizationChoice,
} from 'store/organizations/actions';

export default createReducer(defaultState, (builder) => {
  builder
    .addCase(setOrganization, (state, action) => {
      state.organization = action.payload;
    })
    .addCase(setIsOrganizationChoice, (state, action) => {
      state.isOrganizationChoice = action.payload;
    });
});
