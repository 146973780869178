import React from 'react';
import { Grid, Typography } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { RangeConditions } from 'store/condition/interfaces';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';
import createApiIri from 'helpers/createApiIri';

interface EmployeeBalancesProps {
  employeeId: number;
  dateRange: string[];
  projectsCondition: RangeConditions;
  projectId: number;
}

export default function EmployeeBalances({
  employeeId,
  dateRange,
  projectsCondition,
  projectId,
}: EmployeeBalancesProps) {
  const { selectedEmployee } = useGetSelectedEmployeePreview({
    employeeIri: createApiIri('employees', employeeId),
    filters: { isActive: true },
  });
  const formatAmount = useCurrencyFormat();

  return (
    <Grid container columns={13} alignItems="center">
      <Grid item xs={1}>
        <Typography variant="smallBody" pl={1}>
          {`${selectedEmployee?.lastname} ${selectedEmployee?.firstname}`}
        </Typography>
      </Grid>
      {dateRange.map((month) => (
        <React.Fragment key={month}>
          <Grid item xs={1.8}>
            <Typography variant="smallBody">
              {formatAmount(
                projectsCondition[month]?.[projectId]?.[employeeId]?.cost,
              )}
            </Typography>
          </Grid>
          <Grid item xs={1.2}>
            <Typography variant="smallBody">
              {formatAmount(
                projectsCondition[month]?.[projectId]?.[employeeId]?.cost,
              )}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
