import React from 'react';
import { FeedbackRates } from 'enums';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

interface RatesProps {
  [index: string]: {
    label: string;
    value: string;
    icon: React.ReactElement;
  };
}

const rates = {
  4: {
    label: 'feedbacks.excellent',
    value: FeedbackRates.excellent,
    icon: (
      <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: '3.5rem' }} />
    ),
  },
  1: {
    label: 'feedbacks.negative',
    value: FeedbackRates.negative,
    icon: (
      <SentimentVeryDissatisfiedIcon
        color="error"
        sx={{ fontSize: '3.5rem' }}
      />
    ),
  },
  2: {
    label: 'feedbacks.neutral',
    value: FeedbackRates.neutral,
    icon: (
      <SentimentSatisfiedIcon color="primary" sx={{ fontSize: '3.5rem' }} />
    ),
  },
  3: {
    label: 'feedbacks.positive',
    value: FeedbackRates.positive,
    icon: (
      <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: '3.5rem' }} />
    ),
  },
} as RatesProps;

export default rates;
