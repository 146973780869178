import React from 'react';
import { Box, DialogContent, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormPayloadAddCategory, BudgetCategoryFormProps } from './interfaces';
import schema from './BudgetCategoryForm.schema';

const validate = zodResolver(schema);

export default function BudgetCategoryForm({
  setIsOpen,
  onSubmit,
  initFormValues,
  isSubmitting,
}: BudgetCategoryFormProps) {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<FormPayloadAddCategory>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initFormValues,
  });

  const { t } = useTranslation();
  const { name } = watch();

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) || !name;

  const handleOnSubmit = handleSubmit(onSubmit);

  return (
    <>
      <DialogHeader
        title={t('budget.add_budget_category')}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        <form onSubmit={handleOnSubmit} noValidate>
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 4 }}
          >
            <ControlTextField
              control={control}
              errors={errors}
              label="label.name"
              name="name"
              required
            />
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <SubmitButton
              disabled={disableSubmission()}
              isLoading={isSubmitting}
            />
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
