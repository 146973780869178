import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Grid } from '@mui/material';
import {
  useGetCandidatesQuery,
  useGetCandidatesByPhraseQuery,
} from 'store/candidates/candidates';
import CustomPagination from 'common/CustomPagination/CustomPagination';
import usePagination from 'hooks/usePagination';
import HrCandidatesTable from './HrCandidatesTable/HrCandidatesTable';
import HrCandidateAutocomplete from './HrCandidateAutocomplete/HrCandidateAutocomplete';

export default function HumanResourcesCandidatesList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchPhraseQueryParam = searchParams.get('phrase');

  const [searchPhrase, setSearchPhrase] = useState(
    searchPhraseQueryParam ?? '',
  );
  const { page, itemsPerPage, handleOnPageChange, handleOnItemsPerPageChange } =
    usePagination({ searchParams, setSearchParams });

  const {
    data: candidatesData,
    isFetching: candidatesFetching,
    isError: candidatesError,
  } = useGetCandidatesQuery(
    {
      page,
      itemsPerPage,
    },
    {
      skip: !!searchPhraseQueryParam,
    },
  );

  const { data: candidatesDataByPhrase } = useGetCandidatesByPhraseQuery(
    searchPhrase,
    { skip: searchPhrase.length < 2 },
  );

  const candidatesDataToDisplay = searchPhraseQueryParam
    ? candidatesDataByPhrase
    : candidatesData;

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between">
        <HrCandidateAutocomplete
          candidatesDataByPhrase={candidatesDataByPhrase}
          setSearchPhrase={setSearchPhrase}
          searchPhrase={searchPhrase}
          setSearchParams={setSearchParams}
          searchPhraseQueryParam={searchPhraseQueryParam}
        />
      </Stack>
      <HrCandidatesTable
        candidatesData={candidatesDataToDisplay}
        candidatesError={candidatesError}
        candidatesFetching={candidatesFetching}
      />
      {candidatesData?.['hydra:view']?.['hydra:last'] && (
        <CustomPagination
          apiData={candidatesData}
          page={page}
          itemsPerPage={itemsPerPage}
          handleOnPageChange={handleOnPageChange}
          handleOnItemsPerPageChange={handleOnItemsPerPageChange}
        />
      )}
    </Grid>
  );
}
