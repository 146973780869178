import React, { useState } from 'react';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetCostGroupsQuery } from 'store/costGroups/costGroups';
import Loader from 'common/Loader';
import Error from 'common/Error';
import headers from 'components/ContractorGroupsList/headers';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import EditButton from 'common/EditButton/EditButton';
import EditContactorGroupForm from 'components/EditContactorGroupForm';

function ContractorGroupsList() {
  const { t } = useTranslation();
  const [chosenCostGroup, setChosenCostGroup] = useState(0);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const {
    data: contractorGroups = [],
    isLoading,
    isError,
  } = useGetCostGroupsQuery();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer sx={{ px: 3, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            {contractorGroups.length ? (
              contractorGroups.map((group) => (
                <TableRow key={group.id}>
                  <TableCell>
                    <Typography variant="body2">{group.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {group.contractorsCount}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{group.type}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <EditButton
                      onClick={() => {
                        setChosenCostGroup(group.id);
                        setIsEditFormOpen(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoDataRow headers={headers} label="label.no_data" />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        onClose={() => setIsEditFormOpen(false)}
        open={isEditFormOpen}
      >
        <EditContactorGroupForm
          costGroupId={chosenCostGroup}
          setIsOpen={setIsEditFormOpen}
        />
      </Dialog>
    </>
  );
}

export default ContractorGroupsList;
