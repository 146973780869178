import React from 'react';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import { Typography, Chip, Box, Divider } from '@mui/material';
import { Budget } from 'store/budget/interfaces';
import { EmployeePreview } from 'store/employee/interfaces';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import BudgetAvatarStack from 'components/BudgetAvatarsStack/BudgetAvatarStack';
import BudgetDetails from 'components/BudgetDetails/BudgetDetails';
import { BudgetDetailsType } from 'components/BudgetDetails/interfaces';
import { BudgetType } from 'enums';

interface BudgetTileProps {
  budget: Budget;
  employees: EmployeePreview[];
}

export default function BudgetTile({ budget, employees }: BudgetTileProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filteredEmployees = employees.filter((employee) =>
    budget.budgetEmployees.some(
      (budgetEmployee) => budgetEmployee.employee === employee['@id'],
    ),
  );
  return (
    <StyledContentBox
      mt={3}
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(`/budget/${budget.id}`)}
    >
      <Box p={3}>
        <Chip
          variant="outlined"
          label={
            <Typography variant="chipLabel">
              {t(`budget.budget_type_${budget.type}`)}
            </Typography>
          }
        />
        <Typography variant="h6" mt={2}>
          {budget.name}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t(`budget.budget_period_${budget.period}`)}
        </Typography>
        <BudgetDetails budget={budget} type={BudgetDetailsType.tile} />
      </Box>
      {budget.type !== BudgetType.global && budget.budgetEmployees.length > 0 && (
        <>
          <Divider />
          <Box
            px={3}
            pt={3}
            pb={2}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="inputLabel" color="text.secondary">
              {t('budget.participators')}
            </Typography>
            <Typography variant="inputLabel" color="text.secondary">
              {budget.budgetEmployees.length}
            </Typography>
          </Box>
          {employees.length && (
            <BudgetAvatarStack employees={filteredEmployees} />
          )}
        </>
      )}
    </StyledContentBox>
  );
}
