import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  { label: 'label.invoice_number', align: 'left' },
  { label: 'label.invoice_amount', align: 'center' },
  { label: 'label.invoice_sale_date', align: 'center' },
  { label: 'label.paid_amount', align: 'center' },
];

export default headers;
