import React, { useEffect } from 'react';
import {
  agreementType,
  agreementVariant,
} from 'components/AddAgreementForm/selectData'
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import { useSearchParams } from 'react-router-dom';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';


export default function Filters() {
  const { t } = useTranslation();

  const sortedTypes = [...agreementType].sort((currentType, nextType) => currentType.localeCompare(nextType));
  const sortedVariants = [...agreementVariant].sort((currentVariant, nextVariant) => currentVariant.localeCompare(nextVariant));

  const [searchParams, setSearchParams] = useSearchParams({});

  const {
    selected: selectedTypes,
    isSelected: isTypeSelected,
    setSelected: setSelectedTypes,
    handleChangeWithSelectedCheckboxesCallback: handleChangeType,
  } = useMultipleCheckboxSelect<string>([]);

  const {
    selected: selectedVariants,
    isSelected: isVariantSelected,
    setSelected: setSelectedVariants,
    handleChangeWithSelectedCheckboxesCallback: handleChangeVariant,
  } = useMultipleCheckboxSelect<string>([]);

  useEffect(() => {
    const typeParams = searchParams.getAll('type[]');
    const variantParams = searchParams.getAll('variant[]');

    setSelectedTypes(typeParams || []);
    setSelectedVariants(variantParams || []);
  }, [searchParams, setSelectedTypes, setSelectedVariants]);

  const getSelectedTypes = (types: string[]) =>
    sortedTypes
      .filter((type) => types.includes(type))
      .map((type) => type)
      .join(', ');

  const getSelectedVariants = (variants: string[]) =>
    sortedVariants
      .filter((variant) => variants.includes(variant))
      .map((variant) => variant)
      .join(', ');

  return (
    <Box display="flex" gap={2} alignItems="center" my={2} px={3}>
      <FormControl fullWidth>
        <InputLabel id="type-select">{t('label.type')}</InputLabel>
        <Select
          label={t('label.type')}
          labelId="type-select"
          name="typeSelect"
          multiple
          renderValue={(selected) => getSelectedTypes(selected)}
          value={selectedTypes}
        >
          {sortedTypes.map((type) => (
            <MenuItem key={type} value={type}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTypeSelected(type)}
                    onChange={(event) =>
                      handleChangeType(
                        event,
                        (selectedCheckboxes) => {
                          setSearchParams((prevSearchParams) => ({
                            ...prevSearchParams,
                            'type[]': selectedCheckboxes,
                            ...(selectedVariants.length && { 'variant[]': selectedVariants }),
                          }));
                        },
                      )
                    }
                    value={type}
                  />
                }
                label={type}
                sx={{ width: '100%' }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="variant-select">{t('agreements.variant')}</InputLabel>
        <Select
          label={t('agreements.variant')}
          labelId="variant-select"
          name="variantSelect"
          multiple
          renderValue={(selected) => getSelectedVariants(selected)}
          value={selectedVariants}
        >
          {sortedVariants.map((variant) => (
            <MenuItem key={variant} value={variant}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isVariantSelected(variant)}
                    onChange={(event) =>
                      handleChangeVariant(
                        event,
                        (selectedCheckboxes) => {
                          setSearchParams((prevSearchParams) => ({
                            ...prevSearchParams,
                            'variant[]': selectedCheckboxes,
                            ...(selectedTypes.length && { 'type[]': selectedTypes }),
                          }));
                        },
                      )
                    }
                    value={variant}
                  />
                }
                label={variant}
                sx={{ width: '100%' }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );

}
