import React, { useState } from 'react';
import {
  Box,
  Dialog,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dateFormat from 'dateformat';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditButton from 'common/EditButton/EditButton';
import {
  TransactionAttachment,
  useDeleteTransactionAttachmentMutation,
} from 'store/transactions/transactions';
import DeleteIcon from '@mui/icons-material/Delete';
import { Cost, useGetCostsQuery } from 'store/costs/costs';
import EditAttachmentForm from 'components/EditAttachmentForm';
import { StatusProps } from 'hoc/Status/Status';
import useCheckRoles from 'hooks/useCheckRoles';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { CustomError } from 'store/api';
import openFileWithAuth from 'helpers/openFileWithAuth';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import roles from 'config/roles/roles';

export interface AttachmentProps extends StatusProps {
  attachment: TransactionAttachment;
}

const emptyCosts: Cost[] = [];

function Attachment({
  attachment,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AttachmentProps) {
  const { cost } = useGetCostsQuery(
    {
      properties: ['name', 'id'],
    },
    {
      selectFromResult: ({ data }) => ({
        cost:
          data?.filter((item) => item['@id'] === attachment.cost) ?? emptyCosts,
      }),
    },
  );
  const formatAmount = useCurrencyFormat();
  const isTransactionsManager = useCheckRoles(roles.transactionsManager);

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(0);
  const [deleteAttachment] = useDeleteTransactionAttachmentMutation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const handleDeleteAttachment = async (id: number) => {
    try {
      await deleteAttachment(id).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{attachment.name}</Typography>
          <Typography color="secondary" variant="body2">
            {cost[0]?.name}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {attachment.amount &&
              formatAmount(attachment.amount / 100, attachment.currency)}
          </Typography>
        </TableCell>
        <TableCell>
          <Box display="flex" flexDirection="column">
            <Typography variant="body2">
              {dateFormat(attachment.relatedMonth, 'yyyy-mm')}
            </Typography>
            <Typography variant="body2">
              {dateFormat(attachment.receivedAt, 'yyyy-mm-dd')}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => openFileWithAuth(attachment.file!.url)}>
              <RemoveRedEyeOutlinedIcon fontSize="large" />
            </IconButton>
            <Typography variant="body2">{attachment.description}</Typography>
          </Box>
        </TableCell>
        <TableCell align="right">
          {isTransactionsManager && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <EditButton
                onClick={() => {
                  setSelectedAttachment(attachment.id);
                  setIsEditFormOpen(true);
                }}
              />
              <IconButton onClick={() => setIsConfirmationDialogOpen(true)}>
                <DeleteIcon color="error" fontSize="large" />
              </IconButton>
            </Box>
          )}
        </TableCell>
      </TableRow>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setIsEditFormOpen(false)}
        open={isEditFormOpen}
      >
        <EditAttachmentForm
          attachmentId={selectedAttachment}
          setIsOpen={setIsEditFormOpen}
        />
      </Dialog>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleAccept={() => handleDeleteAttachment(attachment.id)}
      />
    </>
  );
}

export default Attachment;
