import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dateFormat from 'dateformat';
import { TransactionAttachment } from 'store/transactions/transactions';
import headers from 'components/AttachmentsList/headers';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import DailyAttachments from 'components/AttachmentsList/DailyAttachments/DailyAttachments';

export interface AttachmentsListProps {
  attachments: TransactionAttachment[];
}

export interface TransactionAttachmentsByDay {
  day: string;
  attachments: TransactionAttachment[];
}

function AttachmentsList({ attachments }: AttachmentsListProps) {
  const { t } = useTranslation();

  const attachmentsGroupedByCreatedAtDay = attachments.reduce(
    (acc: TransactionAttachmentsByDay[], attachment) => {
      const day = dateFormat(new Date(attachment.createdAt), 'yyyy-mm-dd');
      const dayIndex = acc.findIndex((item) => item.day === day);

      if (dayIndex === -1) {
        acc.push({
          day,
          attachments: [attachment],
        });
      } else {
        acc[dayIndex].attachments.push(attachment);
      }

      return acc;
    },
    [],
  );

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={headers.length} />
          </TableRow>
        </TableHead>
        <TableBody>
          {attachmentsGroupedByCreatedAtDay.length ? (
            attachmentsGroupedByCreatedAtDay.map((groupedAttachments) => (
              <DailyAttachments
                key={groupedAttachments.day}
                attachmentsGroupedByDay={groupedAttachments.attachments}
                day={groupedAttachments.day}
                headersLength={headers.length}
              />
            ))
          ) : (
            <NoDataRow headers={headers} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AttachmentsList;
