import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import { Grid, Box, Typography, TextField } from '@mui/material';
import {
  useGetInvoicePrefixQuery,
  useUpdateInvoicePrefixMutation,
} from 'store/config/config';
import Error from 'common/Error';
import { ConfigResponse } from 'store/config/interfaces';
import { CustomError } from 'store/api';
import { InvoicePrefixProps } from './interfaces';

function InvoicePrefix({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: InvoicePrefixProps) {
  const { t } = useTranslation();

  const {
    data: invoicePrefix = {} as ConfigResponse<string>,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
  } = useGetInvoicePrefixQuery();

  const [updateInvoicePrefix, { isLoading: isMutationLoading }] =
    useUpdateInvoicePrefixMutation();

  const [selectedInvoicePrefix, setSelectedInvoicePrefix] = useState('');
  const isInvoicePrefixChanged = selectedInvoicePrefix !== invoicePrefix?.value;

  const handleUpdateInvoicePrefix = async () => {
    try {
      await updateInvoicePrefix({
        value: selectedInvoicePrefix,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  useEffect(() => {
    if (isQuerySuccess) {
      setSelectedInvoicePrefix(invoicePrefix.value);
    }
  }, [invoicePrefix, isQuerySuccess, setSelectedInvoicePrefix]);

  if (isQueryError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">{t('label.invoice_prefix')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('label.invoice_prefix')}
              id="invoice-prefix"
              name="invoicePrefix"
              onChange={(event) => setSelectedInvoicePrefix(event.target.value)}
              value={selectedInvoicePrefix}
              fullWidth
            />
          </Grid>
          {isInvoicePrefixChanged && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isMutationLoading}
                onClick={handleUpdateInvoicePrefix}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default InvoicePrefix;
