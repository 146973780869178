import api from 'store/api';
import {
  OrganizationDto,
  OrganizationsResponseDto,
  OrganizationMailFooterData,
} from './interfaces';

export const organizationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query<OrganizationDto[], void>({
      query: () => '/organizations',
      transformResponse: (response: OrganizationsResponseDto) =>
        response['hydra:member'],
      providesTags: ['Organizations'],
    }),
    getOrganizationMailFooter: builder.query<OrganizationMailFooterData, void>({
      query: () => '/organizations/mail-footer',
      providesTags: ['OrganizationMailFooter'],
    }),
  }),
});

export const { useGetOrganizationsQuery, useGetOrganizationMailFooterQuery } =
  organizationsApi;
