import React from 'react';
import { createSvgIcon } from '@mui/material';

const CheckCircleIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.24"
      d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 3.33341C6.31818 3.33341 3.33341 6.31818 3.33341 10.0001C3.33341 13.682 6.31818 16.6667 10.0001 16.6667C13.682 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31818 13.682 3.33341 10.0001 3.33341ZM1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001ZM13.0893 7.74416C13.4148 8.0696 13.4148 8.59723 13.0893 8.92267L10.0506 11.9614C9.56248 12.4495 8.77102 12.4495 8.28287 11.9614L6.91083 10.5893C6.58539 10.2639 6.58539 9.73626 6.91083 9.41083C7.23626 9.08539 7.7639 9.08539 8.08934 9.41083L9.16675 10.4882L11.9108 7.74416C12.2363 7.41872 12.7639 7.41872 13.0893 7.74416Z"
      fill="currentColor"
    />
  </svg>,
  'CheckCircleIcon',
);

export default CheckCircleIcon;
