import { HolidayTypes } from 'enums';

interface Type {
  type: HolidayTypes;
  description: string;
  icon: JSX.Element;
}

const adaptHolidayTypesToSelectOptions = (holidayTypes: Type[]) =>
  holidayTypes.map(({ type, description }) => ({
    value: type,
    label: description,
  }));

export default adaptHolidayTypesToSelectOptions;
