import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import VatNumberCountryInputs from 'components/AddClientForm/VatNumberCountryInputs/VatNumberCountryInputs';
import useGetCountriesOptions from 'hooks/useGetCountriesOptions';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import LocationInputs from 'components/AddClientForm/LocationInputs/LocationInputs';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import { updateStorageSubscription } from 'pages/Subscription/storageSubscription';
import useSubscriptionContext from 'pages/Subscription/context/useSubscriptionContext';
import schema from './SubscriptionClientForm.schema';
import { FormPayload } from './interfaces';

const validate = zodResolver(schema);

export default function SubscriptionClientForm() {
  const { handleNextStep, clientForm, setClientForm } =
    useSubscriptionContext();
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    trigger,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: clientForm,
  });

  const { name, country } = watch();

  const {
    countryIdOptions,
    countryNameOptions,
    isLoading: countriesLoading,
    isError: countriesError,
  } = useGetCountriesOptions();
  const { t } = useTranslation();

  const handleAddClient = (data: FormPayload) => {
    setClientForm(data);
    updateStorageSubscription('clientForm', data);
    handleNextStep();
  };

  const disableSubmission = () =>
    !name || !country || Object.values(errors).some((error) => error);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateStorageSubscription('clientForm', watch());
    }, 3000);

    return () => clearInterval(intervalId);
  }, [watch]);

  return (
    <Stack width="68.8rem" mx="auto" my={8} spacing={8} alignItems="flex-start">
      <Typography variant="h5">
        {t('subscription.client_form_title')}
      </Typography>
      <ContentSuspense isError={countriesError} isLoading={countriesLoading}>
        <form
          onSubmit={handleSubmit(handleAddClient)}
          noValidate
          style={{ width: '100%' }}
        >
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}
          >
            <VatNumberCountryInputs
              control={control}
              errors={errors}
              countries={countryIdOptions}
              setError={setError}
              setValue={setValue}
              watch={watch}
              trigger={trigger}
            />
            <ControlTextField
              control={control}
              errors={errors}
              label="label.name"
              name="name"
              required
            />
            <LocationInputs
              control={control}
              errors={errors}
              countryNameOptions={countryNameOptions}
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            sx={{ height: '4.74rem', px: 2.75, my: 8 }}
            disabled={disableSubmission()}
          >
            {t('label.go_further')}
          </Button>
        </form>
      </ContentSuspense>
    </Stack>
  );
}
