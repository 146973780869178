import { createContext } from 'react';
import { ResourceRequestCandidate } from 'store/resourceRequestCandidates/interfaces';

import { ResourceRequest } from 'store/resourceRequests/interfaces';

export interface ResourceRequestContextProps {
  resourceRequest: ResourceRequest;
  resourceRequestCandidates: ResourceRequestCandidate[];
}

export const ResourceRequestContext = createContext<ResourceRequestContextProps | undefined>(
  undefined,
);
