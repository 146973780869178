import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  TextField,
  Autocomplete,
  FormHelperText,
} from '@mui/material';
import getControlErrors from 'helpers/validators/getControlErrors';
import { ControlAutocompleteProps } from './interfaces';

export default function ControlAutocomplete<T extends FieldValues>({
  errors,
  control,
  options,
  name,
  label,
  required = true,
}: ControlAutocompleteProps<T>) {
  const { t } = useTranslation();
  const controlErrors = getControlErrors(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, value, onChange } }) => (
        <FormControl
          fullWidth
          required={required}
          error={Boolean(controlErrors)}
        >
          <Autocomplete
            freeSolo
            options={options}
            onBlur={onBlur}
            getOptionLabel={(option) => option}
            onChange={(_, data) => onChange(data)}
            value={value}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(label)}
                onChange={onChange}
                required={required}
              />
            )}
          />
          {controlErrors?.message && (
            <FormHelperText>{t(controlErrors.message)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
