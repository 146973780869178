import React, { useState } from 'react';
import { Box, Button, Dialog, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import FeedbacksList from 'components/FeedbacksList/FeedbacksList';
import FaceIcon from '@mui/icons-material/Face';
import { useNavigate } from 'react-router-dom';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import RequestFeedbackForm from 'components/RequestFeedbackForm';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import { LoggedEmployee } from 'store/employee/interfaces';

export default function Feedback() {
  const { t } = useTranslation();
  const { data: currentUser = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery();
  const navigate = useNavigate();
  const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);

  return (
    <>
      <Grid item xs={12} md={10} bgcolor="background.list">
        <NavBarContainer>
          <NavBarTitle>{t('menu.feedback')}</NavBarTitle>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              endIcon={<FormatQuoteIcon />}
              onClick={() => setIsRequestFormOpen(true)}
              variant="contained"
            >
              {t('feedbacks.request')}
            </Button>
            <Button
              endIcon={<FaceIcon />}
              onClick={() => navigate(`/feedback/${currentUser.id}`)}
              variant="contained"
            >
              {t('feedbacks.my_feedbacks')}
            </Button>
          </Box>
        </NavBarContainer>
        <FeedbacksList />
      </Grid>
      <Dialog
        fullWidth
        open={isRequestFormOpen}
        onClose={() => setIsRequestFormOpen(false)}
      >
        <RequestFeedbackForm setIsOpen={setIsRequestFormOpen} />
      </Dialog>
    </>
  );
}
