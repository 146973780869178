import React from 'react';
import { Grid } from '@mui/material';
import Error from 'common/Error';
import Loader from 'common/Loader';
import useGetEmployees from 'hooks/useGetEmployees';
import { useGetEmployeeBenefitsQuery } from 'store/employeeBenefit/employeeBenefit';
import { AgreementVariants } from 'enums';
import EmployeeInfo from './EmployeeInfo/EmployeeInfo';

export default function AccountancyList() {
  const {
    data: employees = [],
    isLoading: employeesFetching,
    isError: employeesFetchError,
  } = useGetEmployees({
    isActive: true,
  });
  const {
    data: employeeBenefits = [],
    isLoading: employeeBenefitsFetching,
    isError: employeeBenefitsFetchError,
  } = useGetEmployeeBenefitsQuery({});

  const getBenefitsAmount = (employeeIri: string) => {
    const employeeBenefitsData = employeeBenefits.filter(
      (benefit) => benefit.employee === employeeIri,
    );

    return employeeBenefitsData.length;
  };

  const employeeWithBenefits = employees
    .map((employee) => ({
      ...employee,
      benefits: getBenefitsAmount(employee['@id']),
    }))
    .filter((employee) => {
      if (employee.activeAgreement?.variant === AgreementVariants.b2b) {
        return employee.benefits !== 0;
      }

      return true;
    });

  const employeesIds = employeeWithBenefits.map((employee) => employee.id);

  if (employeesFetching || employeeBenefitsFetching) {
    return <Loader />;
  }

  if (employeesFetchError || employeeBenefitsFetchError) {
    return <Error />;
  }

  return (
    <Grid container sx={{ px: 3, py: 3 }} rowSpacing={3}>
      {employeeWithBenefits.map((employee) => (
        <EmployeeInfo
          key={employee.id}
          employee={employee}
          employeesIds={employeesIds}
        />
      ))}
    </Grid>
  );
}
