import React from 'react';
import dateFormat from 'dateformat';
import { StatusProps } from 'hoc/Status/Status';
import { useUpdateProjectPhaseMutation } from 'store/projectPhases/projectPhases';
import { ProjectPhase } from 'store/projectPhases/interfaces';
import { CustomError } from 'store/api';
import { FormPayload } from 'components/ProjectPhaseForm/interfaces';
import { FormTypes } from 'enums';
import { ReactUseState } from 'interfaces';
import useCheckBudgetIsEnabled from 'hooks/useCheckBudgetIsEnabled';
import {
  useAddBudgetProjectsMutation,
  useGetBudgetsQuery,
  useRemoveBudgetProjectsMutation,
} from 'store/budget/budget';
import { BudgetResponse } from 'store/budget/interfaces';
import ProjectPhaseForm from '../ProjectPhaseForm';

export interface EditProjectPhaseProps extends StatusProps {
  setIsOpen: ReactUseState<boolean>;
  projectPhase: ProjectPhase;
}

export default function EditProjectPhase({
  projectPhase,
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditProjectPhaseProps) {
  const isBudgetEnabled = useCheckBudgetIsEnabled();

  const { data: budgets = {} as BudgetResponse } = useGetBudgetsQuery(
    {},
    { skip: !isBudgetEnabled },
  );
  const [editProjectPhase, { isLoading: editProjectPhaseLoading }] =
    useUpdateProjectPhaseMutation();
  const [addBudgetProjects, { isLoading: isAddBudgetProjectLoading }] =
    useAddBudgetProjectsMutation();
  const [removeBudgetProjects, { isLoading: isRemoveBudgetProjectLoading }] =
    useRemoveBudgetProjectsMutation();

  const budgetsData = budgets?.['hydra:member'] || [];

  const assignedBudgetProjectPhase = budgetsData
    .flatMap((budget) => budget.budgetProjects)
    .find(
      (budgetProject) => budgetProject.projectPhase === projectPhase['@id'],
    );
  const assignedBudget = assignedBudgetProjectPhase?.budget || '';

  const handleUpdateProjectPhase = async (formPayload: FormPayload) => {
    try {
      const {
        dateToPrediction,
        dateFrom,
        dateTo,
        amount,
        budget,
        ...restOfPayload
      } = formPayload;

      const payload = {
        id: projectPhase.id,
        ...restOfPayload,
        amount: +amount,
        dateFrom: new Date(dateFrom),
        dateToPrediction: dateToPrediction ? new Date(dateToPrediction) : null,
        dateTo: dateTo ? new Date(dateTo) : null,
      };

      await editProjectPhase(payload).unwrap();

      const isBudgetChange =
        budget && budget !== assignedBudget && isBudgetEnabled;
      const isBudgetRemove =
        assignedBudget &&
        !budget &&
        assignedBudgetProjectPhase &&
        isBudgetEnabled;

      if (isBudgetChange) {
        const addedBudgetProject = {
          budget,
          project: projectPhase.project,
          projectPhase: projectPhase['@id'],
        };
        await addBudgetProjects(addedBudgetProject).unwrap();
      } else if (isBudgetRemove) {
        await removeBudgetProjects({
          id: assignedBudgetProjectPhase.id,
        }).unwrap();
      }

      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const initFormValues = {
    ...projectPhase,
    dateFrom: dateFormat(projectPhase.dateFrom, 'yyyy-mm-dd'),
    ...(projectPhase.dateToPrediction && {
      dateToPrediction: dateFormat(projectPhase.dateToPrediction, 'yyyy-mm-dd'),
    }),
    ...(projectPhase.dateTo && {
      dateTo: dateFormat(projectPhase.dateTo, 'yyyy-mm-dd'),
    }),
    amount: projectPhase.amount.toString(),
    budget: assignedBudget,
  };

  return (
    <ProjectPhaseForm
      formType={FormTypes.edit}
      setIsOpen={setIsOpen}
      onSubmit={handleUpdateProjectPhase}
      isSubmitting={
        editProjectPhaseLoading ||
        isAddBudgetProjectLoading ||
        isRemoveBudgetProjectLoading
      }
      initFormValues={initFormValues}
      budgets={budgetsData}
      isBudgetEnabled={isBudgetEnabled}
    />
  );
}
