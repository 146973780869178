import React from 'react';
import { Grid, Stack } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import { useParams } from 'react-router';
import { useGetIncomeQuery } from 'store/incomes/incomes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import { Invoice } from 'store/incomes/interfaces';
import Preview from 'components/InvoicePreview/Preview';
import { Client, useGetClientQuery } from 'store/clients/clients';
import replaceApiIri from 'helpers/replaceApiIri';
import { BankAccount, useGetAccountQuery } from 'store/accounts/accounts';

export default function InvoicePreview() {
  const { id } = useParams<{ id: string }>();
  const {
    data: invoice = {} as Invoice,
    isLoading: invoiceFetching,
    isError: invoiceFetchError,
  } = useGetIncomeQuery(id ? +id : skipToken);
  const {
    data: client = {} as Client,
    isLoading: clientFetching,
    isError: clientFetchError,
  } = useGetClientQuery(
    invoice.client ? replaceApiIri(invoice.client, 'clients') : skipToken,
  );
  const {
    data: bankAccount = {} as BankAccount,
    isLoading: bankAccountFetching,
    isError: bankAccountFetchError,
  } = useGetAccountQuery(
    invoice.bankAccount
      ? replaceApiIri(invoice.bankAccount, 'bank-accounts')
      : skipToken,
  );

  const dataLoading = invoiceFetching || clientFetching || bankAccountFetching;
  const dataFetchError =
    invoiceFetchError || clientFetchError || bankAccountFetchError;

  return (
    <ContentSuspense isLoading={dataLoading} isError={dataFetchError}>
      <Grid item xs={12} bgcolor="background.list">
        <NavBarContainer>
          <Stack direction="row" alignItems="center">
            <NavigationBackButton to="/invoices" />
            <NavBarTitle>{invoice?.name}</NavBarTitle>
          </Stack>
        </NavBarContainer>
      </Grid>
      {invoice && (
        <Preview bankAccount={bankAccount} client={client} invoice={invoice} />
      )}
    </ContentSuspense>
  );
}
