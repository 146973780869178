import React from 'react';
import { Box, Typography } from '@mui/material';
import { getHolidayIcon, getHolidayName } from 'helpers/getHolidayData';
import { Holiday } from 'store/holidays/interfaces';
import { useTranslation } from 'react-i18next';
import fullDateFormat from 'helpers/fullDateFormat';

interface HolidaysProps {
  holidays: Holiday[];
}

export default function Holidays({ holidays }: HolidaysProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography color="primary" textTransform="uppercase" variant="h6">
        {t('menu.holidays_management')}
      </Typography>
      {holidays.length ? (
        holidays.map((holiday) => (
          <Box
            key={holiday.id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap={2}>
              {getHolidayIcon(holiday.type)}
              <Typography variant="body1">
                {t(getHolidayName(holiday.type))}
              </Typography>
            </Box>
            <Typography variant="body1">
              {`${fullDateFormat(holiday.dateFrom)} - ${fullDateFormat(
                holiday.dateTo,
              )}`}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography variant="body1">{t('label.no_data')}</Typography>
      )}
    </>
  );
}
