import { ApiResponse } from 'store/interfaces';

export interface HolidayTypes extends ApiResponse {
  'hydra:member': HolidayType[];
}

export interface HolidayType {
  '@id': string;
  id: string;
  status: string;
  name: string;
}

export enum HolidayTypeStatus {
  active = 'active',
  inactive = 'inactive',
}
