import formatDateToUtcFormat from 'helpers/formatDateToUtcFormat';
import { useState, useEffect } from 'react';
import emptyApiResponse from 'store/emptyApiResponse';
import { useGetIncomesQuery } from 'store/incomes/incomes';
import { Invoice } from 'store/incomes/interfaces';

export interface ChartIncome
  extends Pick<
    Invoice,
    '@id' | 'name' | 'saleDate' | 'targetAmountNet' | 'client'
  > {}

export default function useGetChartIncome() {
  const [page, setPage] = useState(1);
  const [chartIncomes, setChartIncomes] = useState<ChartIncome[]>([]);
  const [allPagesFetched, setAllPagesFetched] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonthTwoYearsAgo = new Date(
    currentDate.getFullYear() - 2,
    currentDate.getMonth(),
    1,
  );

  const {
    data: incomes = { 'hydra:member': [], ...emptyApiResponse },
    isFetching,
    isError,
  } = useGetIncomesQuery({
    properties: ['name', 'targetAmountNet', 'saleDate', 'client'],
    itemsPerPage: 500,
    page,
    'saleDate[after]': formatDateToUtcFormat(firstDayOfMonthTwoYearsAgo),
  });

  useEffect(() => {
    if (!incomes['hydra:member'].length) {
      return;
    }

    setChartIncomes((prev) => [...prev, ...incomes['hydra:member']]);
    if (!incomes['hydra:view']['hydra:next']) {
      setAllPagesFetched(true);
      return;
    }

    setPage((prev) => prev + 1);
  }, [incomes]);

  return {
    incomes: chartIncomes,
    isError,
    allPagesFetched,
    fetched: !isFetching && allPagesFetched,
  };
}
