import React, { useState } from 'react';
import {
  Button,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AgreementsData,
  useGetFilteredAgreementsQuery,
} from 'store/agreements/agreements';
import { useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import DownloadIcon from '@mui/icons-material/Download';
import EditButton from 'common/EditButton/EditButton';
import headers from 'components/EditEmployeeForm/Agreements/headers';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import AddEmployeeAgreement from 'components/EditEmployeeForm/Agreements/AddEmployeeAgreement';
import Loader from 'common/Loader';
import Error from 'common/Error';

function Agreements() {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const {
    data: agreements = {} as AgreementsData,
    isLoading,
    isError,
  } = useGetFilteredAgreementsQuery({
    employee: id,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            <RowAddButton
              onClick={() => setIsAddDialogOpen(true)}
              colSpan={headers.length + 1}
            >
              <Typography variant="body2">{t('agreements.add_new')}</Typography>
            </RowAddButton>
            {agreements['hydra:member'].length > 0 &&
              agreements['hydra:member'].map((agreement) => (
                <TableRow key={agreement.id}>
                  <TableCell>
                    <Typography variant="body2">{agreement.type}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{agreement.variant}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {dateFormat(agreement.dateFrom, 'yyyy-mm-dd')}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {agreement.dateTo ? (
                      <Typography variant="body2">
                        {dateFormat(agreement.dateTo, 'yyyy-mm-dd')}
                      </Typography>
                    ) : (
                      <AllInclusiveIcon fontSize="large" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {agreement.files ? (
                      agreement.files.map((file) => (
                        <IconButton
                          key={file.id}
                          onClick={() =>
                            downloadFileWithAuth(file.url, file.id)
                          }
                        >
                          <DownloadIcon fontSize="large" />
                        </IconButton>
                      ))
                    ) : (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          navigate(`/agreements/${agreement.id}`, {
                            state: { tabValue: '2' },
                          })
                        }
                      >
                        {t('button.upload_missing_file')}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <EditButton navigateTo={`/agreements/${agreement.id}`} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
        <AddEmployeeAgreement
          setIsOpen={setIsAddDialogOpen}
          employeeId={Number(id)}
        />
      </Dialog>
    </>
  );
}

export default Agreements;
