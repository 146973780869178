import React from 'react';
import {
  Box,
  SelectChangeEvent,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InvoiceFormSelect from 'components/AddInvoiceForm/InvoiceFormSelect/InvoiceFormSelect';
import { units, vatRates } from 'components/InvoicesList/invoicesSelectData';
import { ProjectPreview } from 'store/projects/interfaces';
import { InvoiceRow } from 'store/incomes/interfaces';
import DeleteButton from 'common/DeleteButton/DeleteButton';
import TextButton from 'common/TextButton/TextButton';
import tableHeaders from './tableHeaders';
import StyledTableCell from './TableCell.styled';
import StyledValueTypography from './ValueTypography.styled';
import getTotalVat from '../helpers/getTotalVat';
import getGrossWorth from './getGrossWorth';

export interface RowsProps {
  projects: ProjectPreview[];
  incomeRows: InvoiceRow[];
  setIncomeRows: React.Dispatch<React.SetStateAction<InvoiceRow[]>>;
}

export default function Rows({
  projects,
  incomeRows,
  setIncomeRows,
}: RowsProps) {
  const { t } = useTranslation();

  const handleAddFormRow = () => {
    const rows = [...incomeRows];
    rows.push({
      id: '',
      productName: '',
      project: '',
      classificationNumber: '',
      netPrice: '',
      vatRate: '',
      unit: '',
      quantity: '',
    });
    setIncomeRows(rows);
  };

  const handleDeleteFormRow = (row: InvoiceRow) => {
    const rows = [...incomeRows];
    const deletedRowIndex = rows.indexOf(row);
    setIncomeRows([
      ...rows.slice(0, deletedRowIndex),
      ...rows.slice(deletedRowIndex + 1),
    ]);
  };

  const handleEdit = (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent,
    field: InvoiceRow,
  ) => {
    if (!incomeRows.length) {
      return;
    }
    const editAtRow = incomeRows.indexOf(field);
    const updatedRow = { ...incomeRows[editAtRow] } as InvoiceRow;
    const keyOfRow = event.target.name as keyof InvoiceRow;
    updatedRow[keyOfRow] = event.target.value;

    setIncomeRows([
      ...incomeRows.slice(0, editAtRow),
      updatedRow,
      ...incomeRows.slice(editAtRow + 1),
    ]);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.label}
                  align={header.align}
                  width={header.width}
                >
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                    {header.required ? ' *' : ''}
                  </Typography>
                </StyledTableCell>
              ))}
              <StyledTableCell colSpan={1} />
            </TableRow>
          </TableHead>
          <TableBody>
            {incomeRows.map((field, index) => (
              <TableRow key={field.id || index}>
                <StyledTableCell>
                  <TextField
                    fullWidth
                    required
                    id="product-name"
                    name="productName"
                    value={field.productName}
                    onChange={(event) => handleEdit(event, field)}
                    data-testid="invoice-form-row-product-name"
                    sx={{ maxWidth: '34rem' }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <InvoiceFormSelect
                    isRequired={false}
                    selectId="project-select"
                    name="project"
                    value={field.project ?? ''}
                    onChangeFn={(event) => handleEdit(event, field)}
                    selectOptions={projects.map((item) => ({
                      id: item['@id'],
                      name: item.name,
                    }))}
                    sx={{ maxWidth: '17rem' }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    fullWidth
                    id="classification-number"
                    name="classificationNumber"
                    value={field.classificationNumber}
                    onChange={(event) => handleEdit(event, field)}
                    data-testid="invoice-form-row-classification-number"
                    sx={{ maxWidth: '12.75rem' }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    id="quantity"
                    inputProps={{
                      step: 0.01,
                      min: 0,
                    }}
                    name="quantity"
                    value={field.quantity}
                    onChange={(event) => handleEdit(event, field)}
                    data-testid="invoice-form-row-quantity"
                    sx={{ maxWidth: '12.75rem' }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <InvoiceFormSelect
                    selectId="units-select"
                    name="unit"
                    value={field.unit}
                    onChangeFn={(event) => handleEdit(event, field)}
                    selectOptions={units.map((item) => ({
                      id: item.id,
                      name: item.description,
                    }))}
                    noLabel
                    sx={{ maxWidth: '8.5rem' }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    fullWidth
                    type="number"
                    id="net-price"
                    inputProps={{
                      min: 0,
                      step: 0.0001,
                    }}
                    name="netPrice"
                    value={field.netPrice}
                    onChange={(event) => handleEdit(event, field)}
                    data-testid="invoice-form-row-net-price"
                    sx={{ maxWidth: '17rem' }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <StyledValueTypography data-testid="invoice-row-net-value">
                    {(+field.netPrice * +field.quantity).toFixed(4)}
                  </StyledValueTypography>
                </StyledTableCell>
                <StyledTableCell>
                  <InvoiceFormSelect
                    selectId="vat-rate-select"
                    name="vatRate"
                    value={field.vatRate}
                    onChangeFn={(event) => handleEdit(event, field)}
                    selectOptions={vatRates.map((item) => ({
                      id: item.value,
                      name: item.label,
                    }))}
                    noLabel
                    sx={{ maxWidth: '12.75rem' }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <StyledValueTypography data-testid="invoice-row-vat-cost">
                    {getTotalVat({
                      netPrice: +field.netPrice,
                      quantity: +field.quantity,
                      vatRate: field.vatRate,
                    })}
                  </StyledValueTypography>
                </StyledTableCell>
                <StyledTableCell>
                  <StyledValueTypography data-testid="invoice-row-gross-value">
                    {getGrossWorth(
                      +field.netPrice,
                      +field.quantity,
                      field.vatRate,
                    )}
                  </StyledValueTypography>
                </StyledTableCell>
                <StyledTableCell>
                  <DeleteButton onClick={() => handleDeleteFormRow(field)} />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <TextButton
          onClick={handleAddFormRow}
          color="secondary.main"
          iconPosition="start"
          label="invoices.add_product"
        />
      </Box>
    </>
  );
}
