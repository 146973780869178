import React from 'react';
import { Button, Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export interface TextButtonProps {
  onClick: () => void;
  label?: string;
  color?: TypographyProps['color'];
  disabled?: boolean;
  iconPosition?: 'start' | 'end';
  icon?: React.ReactNode;
}

export default function TextButton({
  onClick,
  disabled = false,
  label = 'button.create',
  color = 'icon.main',
  iconPosition = 'end',
  icon = <AddCircleOutlineIcon sx={{ color }} />,
}: TextButtonProps) {
  const { t } = useTranslation();
  const labelText = t(label);
  return (
    <Button
      onClick={onClick}
      variant="text"
      disabled={disabled}
      {...{ [`${iconPosition}Icon`]: icon }}
    >
      <Typography color={color} variant="buttonMedium">
        {labelText}
      </Typography>
    </Button>
  );
}
