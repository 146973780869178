import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TabContextProps, useTabContext } from '@mui/lab';
import useGetEmployees from 'hooks/useGetEmployees';
import tableHeaders from 'components/EmployeeList/tableHeaders';
import Result from 'components/EmployeeList/Result/Result';
import { useTranslation } from 'react-i18next';
import { EmployeesTabValue } from 'pages/Employees/Employees';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';

function EmployeeList() {
  const { value: tabValue } = useTabContext() as TabContextProps;

  const {
    data: employees = [],
    isFetching: employeesFetching,
    isError: employeesError,
  } = useGetEmployees({
      isActive: tabValue === EmployeesTabValue.active,
    });
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={tableHeaders.length} />
          </TableRow>
        </TableHead>
        <TableBodyContent
          isError={employeesError}
          isFetching={employeesFetching}
          tableHeaders={tableHeaders}
        >
          {employees.map((employee) => (
            <Result key={employee.id} employee={employee} />
          ))}
        </TableBodyContent>
      </Table>
    </TableContainer>
  );
}

export default EmployeeList;
