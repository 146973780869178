import React from 'react';
import { CardMedia, Stack, Typography } from '@mui/material';
import AddButton from 'common/AddButton/AddButton';
import InvoiceInFolderUrl from 'assets/img/invoice-in-folder.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function EmptyInvoicesList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack spacing={5} alignItems="center" pt={24}>
      <Stack spacing={2} alignItems="center" width="50%">
        <CardMedia
          sx={{
            width: '12.8rem',
            height: '12.8rem',
          }}
          component="img"
          loading="lazy"
          src={InvoiceInFolderUrl}
          alt="Invoice"
        />
        <Typography textAlign="center" variant="h5">
          {t('invoices.no_invoice_added')}
        </Typography>
        <Typography
          textAlign="center"
          variant="subtitle1"
          color="text.secondary"
        >
          {t('invoices.add_invoice_description')}
        </Typography>
      </Stack>
      <AddButton
        onClick={() => navigate('/invoices/add')}
        label="invoices.add_invoice"
      />
    </Stack>
  );
}
