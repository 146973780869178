import React, { useState, useEffect } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useGetOrganizationWeekStartDayQuery, useUpdateOrganizationWeekStartDayMutation } from 'store/config/config';
import Error from 'common/Error';
import { RootState } from 'store';
import { CustomError } from 'store/api';
import { useSelector } from 'react-redux';
import { OrganizationCalendarTypeProps } from './interfaces';
import calendarTypes from './calendarTypes';

function OrganizationCalendarType({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: OrganizationCalendarTypeProps) {
  const { t } = useTranslation();

  const organizationConfigStartWeekDay = useSelector((state: RootState) => state.organization.organization.config['organization-week-start-day']);

  const {
    data: organizationStartWeekDay,
    isError: isQueryError,
  } = useGetOrganizationWeekStartDayQuery(undefined, { skip: !!organizationConfigStartWeekDay });

  const startWeekDay = organizationConfigStartWeekDay
    ? organizationConfigStartWeekDay.toString()
    : organizationStartWeekDay?.value?.toString() || '';

  const [selectedOrganizationCalendarType, setSelectedOrganizationCalendarType] = useState(startWeekDay);

  const [updateOrganizationWeekStartDayMutation, { isLoading: isMutationLoading }] = useUpdateOrganizationWeekStartDayMutation();

  const isDefaultCalendarChanged = startWeekDay !== selectedOrganizationCalendarType;



  useEffect(() => {
    if (startWeekDay) {

      setSelectedOrganizationCalendarType(startWeekDay)
    }
  }, [startWeekDay]);


  const handleUpdateOrganizationWeekStartDay = async () => {
    try {
      await updateOrganizationWeekStartDayMutation({
        value: selectedOrganizationCalendarType.toString(),
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (!organizationConfigStartWeekDay && isQueryError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.organization_calendar_type')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="start-week-day-label">
                {t('label.organization_calendar_type')}
              </InputLabel>
              <Select
                labelId="start-week-day-label"
                id="start-week-day"
                value={selectedOrganizationCalendarType}
                label={t('label.organization_start_week_day')}
                onChange={(event) => setSelectedOrganizationCalendarType(event.target.value)}
              >
                {calendarTypes.map((type) => (
                  <MenuItem key={type.type} value={type.value}>
                    {t(type.description)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {isDefaultCalendarChanged && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isMutationLoading}
                onClick={handleUpdateOrganizationWeekStartDay}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default OrganizationCalendarType;
