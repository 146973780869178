import store from 'store';
import api from 'store/api';
import { setOrganization } from 'store/organizations/actions';
import { Config } from 'store/organizations/defaultState';
import { organizationsApi } from 'store/organizations/organizations';

interface UpdateOrganizationsCacheProps {
  changedConfig: {
    [K in keyof Config]?: Config[K];
  }
}

export default function updateOrganizationsCache({
  changedConfig,
}: UpdateOrganizationsCacheProps) {
  const state = store.getState();
  const { organization } = state.organization;

  const updatedOrganization = {
    ...organization,
    config: {
      ...organization.config,
      ...changedConfig,
    },
  };

  store.dispatch(setOrganization(updatedOrganization));

  const organizationsCache = api.util.selectInvalidatedBy(state, [
    { type: 'Organizations' },
  ]);

  organizationsCache
    .filter(({ endpointName }) => endpointName === 'getOrganizations')
    .forEach(({ originalArgs }) => {
      store.dispatch(
        organizationsApi.util.updateQueryData(
          'getOrganizations',
          originalArgs,
          (draft) =>
            draft.map((organizationItem) => {
              if (organizationItem.id === updatedOrganization.id) {
                return {
                  ...organizationItem,
                  config: updatedOrganization.config,
                };
              }
              return organizationItem;
            }),
        ),
      );
    });
}
