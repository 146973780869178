import React, { useEffect, useState } from 'react';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { Box, DialogContent, TextField } from '@mui/material';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { StatusProps } from 'hoc/Status/Status';
import {
  DocumentData,
  useGetDocumentQuery,
  useUpdateDocumentMutation,
} from 'store/documents/documents';
import { skipToken } from '@reduxjs/toolkit/query';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface EditDocumentFormProps extends StatusProps {
  documentId: number;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditDocumentForm({
  documentId,
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditDocumentFormProps) {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const {
    data: document = {} as DocumentData,
    isLoading: documentFetching,
    isError: documentFetchError,
    isSuccess: documentFetched,
  } = useGetDocumentQuery(documentId ?? skipToken);
  const [updateDocument, { isLoading: documentUpdating }] =
    useUpdateDocumentMutation();

  useEffect(() => {
    if (!documentFetched) {
      return;
    }
    setDescription(document.notes);
  }, [documentFetched, document]);

  const handleUpdateDocument = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateDocument({
        id: documentId,
        notes: description,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        title={t('employees.document_edit')}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        {documentFetchError && <Error />}
        {documentFetching && <Loader />}
        {documentFetched && (
          <form onSubmit={handleUpdateDocument}>
            <Box display="flex" flexDirection="column" gap={3} py={3}>
              <TextField
                fullWidth
                id="description"
                label={t('label.description')}
                name="description"
                onChange={(event) => setDescription(event.target.value)}
                value={description}
              />
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  endIcon={<CheckIcon />}
                  loading={documentUpdating}
                  type="submit"
                  variant="contained"
                >
                  <span>{t('button.save')}</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </>
  );
}

export default EditDocumentForm;
