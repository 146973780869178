import api from 'store/api';
import { ApiResponse } from 'store/interfaces';
import { StorageResponse } from 'store/storage/storage';

export interface DocumentData {
  documentType: string;
  id: number;
  notes: string;
  relationId: number;
  storage: StorageResponse;
}

export interface DocumentsResponseDto extends ApiResponse {
  'hydra:member': DocumentData[];
}

export const documentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addDocument: builder.mutation({
      query: (body) => ({
        url: '/documents',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    getDocument: builder.query<DocumentData, number>({
      query: (id) => `/documents/${id}`,
      providesTags: ['Documents'],
    }),
    updateDocument: builder.mutation<
      DocumentData,
      Partial<DocumentData> & Pick<DocumentData, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/documents/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteDocument: builder.mutation({
      query: (id: number) => ({
        url: `/documents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Documents'],
    }),
  }),
});

export const {
  useAddDocumentMutation,
  useGetDocumentQuery,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
} = documentsApi;
