import React from 'react';
import { DragDropProvider } from '@devexpress/dx-react-scheduler-material-ui';
import AppointmentContentComponent from 'components/BookProperties/BookingForm/DaysBookingScheduler/Appointments/AppointmentContentComponent';
import { Box } from '@mui/material';

interface SourceAppointmentComponentProps
  extends DragDropProvider.SourceAppointmentProps {}

export default function SourceAppointmentComponent({
  data,
}: SourceAppointmentComponentProps) {
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        borderRadius: '0.8rem',
        opacity: 0.4,
        height: '100%',
      }}
    >
      <AppointmentContentComponent data={data} />
    </Box>
  );
}
