import { Slider, SliderProps, styled } from '@mui/material';

const StyledSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  height: '0.4rem',
  '& .MuiSlider-track': {
    height: '0.6rem',
  },
  '& .MuiSlider-thumb': {
    width: '2.4rem',
    height: '2.4rem',
    border: '0.3rem solid',
    borderColor: theme.palette.main.white,
    boxShadow: '0 0rem 0.4rem 0.15rem rgba(0, 0, 0, 0.2)',
    '&:after': {
      content: '""',
      width: '0.6rem',
      height: '0.6rem',
      borderRadius: '50%',
      backgroundColor: theme.palette.main.white,
    },
  },
  '& span.MuiSlider-markLabel.MuiSlider-markLabel.MuiSlider-markLabelActive': {
    color: theme.palette.text.secondary,
  },
}));

export default StyledSlider;
