import React from 'react';
import { Avatar, AvatarGroup, Box } from '@mui/material';
import { EmployeePreview } from 'store/employee/interfaces';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';

interface AvatarStackProps {
  employees: EmployeePreview[];
}

export default function BudgetAvatarStack({ employees }: AvatarStackProps) {
  return (
    <Box px={3} pb={3} display="flex">
      <AvatarGroup
        max={employees.length > 8 ? 8 : 9}
        slotProps={{
          additionalAvatar: {
            sx: {
              width: '3rem',
              height: '3rem',
              color: 'primary.main',
              backgroundColor: 'background.otherAvatarFill',
            },
          },
        }}
      >
        {employees.map((employee) => (
          <TitleTooltip
            key={employee.id}
            title={`${employee.lastname} ${employee.firstname}`}
          >
            <Avatar
              sx={{ width: '3rem', height: '3rem' }}
              src={employee.avatarUrl}
            />
          </TitleTooltip>
        ))}
      </AvatarGroup>
    </Box>
  );
}
