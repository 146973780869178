import api from 'store/api';
import { setErrorCatch } from 'store/status/actions';
import {
  Meeting,
  MeetingFilters,
  MeetingsResponseDto,
  MeetingPostMutationRequest,
  MeetingPatchMutationRequest,
} from './interfaces';

export const meetingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addMeeting: builder.mutation<Meeting, MeetingPostMutationRequest>({
      query: (body) => ({
        url: '/meetings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Meetings'],
    }),
    getMeeting: builder.query<Meeting, string>({
      query: (id) => `/meetings/${id}`,
      providesTags: ['Meetings'],
    }),
    getMeetings: builder.query<Meeting[], Partial<MeetingFilters>>({
      query: (params) => ({
        url: '/meetings',
        params,
      }),
      transformResponse: (response: MeetingsResponseDto) =>
        response['hydra:member'],
      providesTags: ['Meetings'],
    }),
    updateMeeting: builder.mutation<Meeting, MeetingPatchMutationRequest>({
      query: ({ id, ...body }) => ({
        url: `/meetings/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedMeeting } = await queryFulfilled;

          const meetingCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Meetings' },
          ]);

          meetingCache.forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getMeetings') {
              dispatch(
                meetingsApi.util.updateQueryData(
                  'getMeetings',
                  originalArgs,
                  (draft) => {
                    const meetingIndex = draft.findIndex(
                      (meeting) => meeting.id === id,
                    );
                    if (meetingIndex !== -1) {
                      draft?.splice(meetingIndex, 1, updatedMeeting);
                    }
                  },
                ),
              );
            } else if (endpointName === 'getMeeting' && originalArgs === id) {
              dispatch(
                meetingsApi.util.updateQueryData(
                  'getMeeting',
                  originalArgs,
                  () => updatedMeeting,
                ),
              );
            }
          });
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
  }),
});

export const {
  useAddMeetingMutation,
  useGetMeetingQuery,
  useGetMeetingsQuery,
  useUpdateMeetingMutation,
} = meetingsApi;
