import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ResponseErrors } from 'store/api';
import {
  useGetPropertyTypesQuery,
} from 'store/properties/properties';
import { Property } from 'store/properties/interfaces';
import { useGetLocationsQuery } from 'store/locations/locations';
import { BookingType } from 'components/Properties/enums';
import { bookingTypes } from 'components/Properties/PropertiesAccordion/propertyTypes';
import { LoadingButton } from '@mui/lab';
import Loader from 'common/Loader';
import dateFormat from 'dateformat';
import { FormTypes } from 'enums';
import { convertErrorArrayToMap } from '../utils';
import statuses from './statuses';
import PropertyFileUpload from './PropertyFileUpload';

export interface PropertyFormProps {
  property: Property;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (
    key: string,
    value: string | boolean | (number | undefined)[],
  ) => void;
  error: ResponseErrors[];
  isLoading: boolean;
  formType: FormTypes;
}

export default function PropertyForm({
  property,
  handleSubmit,
  handleChange,
  error,
  isLoading,
  formType,
}: PropertyFormProps) {
  const { t } = useTranslation();
  const [responseError, setResponseError] = useState(error);
  const responseErrorMap = convertErrorArrayToMap(responseError);
  const {
    data: locations = [],
    isError: locationsFetchError,
    isLoading: locationsFetching,
  } = useGetLocationsQuery();
  const { data: types = [] } = useGetPropertyTypesQuery();

  const handleChangeInput = ({
    target,
  }:
    | React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLFormElement
      >
    | SelectChangeEvent) => {
    setResponseError([]);
    handleChange(target.name, target.value);
  };

  useEffect(() => {
    if (error) {
      setResponseError(error);
    }
  }, [error]);

  if (locationsFetching) {
    return <Loader />;
  }

  const isEditForm = formType === FormTypes.edit;

  return (
    <Box p={3} bgcolor="main.white" borderRadius={4}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {isEditForm && (
            <Grid item xs={12} md={4}>
              <PropertyFileUpload property={property} />
            </Grid>
          )}
          <Grid item xs={12} md={isEditForm ? 8 : 12}>
            <FormControl fullWidth required>
              <TextField
                name="name"
                error={responseErrorMap.has('name')}
                required
                helperText={responseErrorMap.get('name')}
                label={t('label.name')}
                value={property.name}
                onChange={handleChangeInput}
              />
            </FormControl>
            <Stack direction="row" alignItems="center" spacing={3} mt={3}>
              <FormControl
                fullWidth
                required
                error={responseErrorMap.has('type')}
              >
                <InputLabel id="typeSelect">{t('label.type')}</InputLabel>
                <Select
                  label={t('label.type')}
                  labelId="typeSelect"
                  name="type"
                  onChange={handleChangeInput}
                  value={property.type}
                >
                  {types.map((propertyData) => (
                    <MenuItem key={propertyData} value={propertyData}>
                      {propertyData}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                required
                error={responseErrorMap.has('status')}
              >
                <InputLabel id="statusSelect">{t('label.status')}</InputLabel>
                <Select
                  label={t('label.status')}
                  labelId="statusSelect"
                  name="status"
                  onChange={handleChangeInput}
                  value={property.status}
                >
                  {statuses.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {t(label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={property.bookingAllowed}
                      onChange={() =>
                        handleChange('bookingAllowed', !property.bookingAllowed)
                      }
                    />
                  }
                  label={t('inputs.visible')}
                  labelPlacement="start"
                />
              </FormGroup>
            </Stack>
            <Stack direction="row" alignItems="flex-end" spacing={3} mt={3}>
              <Stack spacing={1} width="100%">
                <Typography variant="body1">
                  {t('inputs.booking_type')}*
                </Typography>
                <ButtonGroup fullWidth>
                  {bookingTypes.map((type) => (
                    <Button
                      key={type.value}
                      onClick={() => handleChange('bookingType', type.value)}
                      variant={
                        property.bookingType === type.value
                          ? 'contained'
                          : 'outlined'
                      }
                    >
                      {t(type.description)}
                    </Button>
                  ))}
                </ButtonGroup>
              </Stack>
              <FormControl required sx={{ width: '70%' }}>
                <TextField
                  name="times"
                  disabled={property.bookingType === BookingType.permanently}
                  error={responseErrorMap.has('times')}
                  required
                  helperText={responseErrorMap.get('times')}
                  label={t('inputs.multiplicity')}
                  value={property.times}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </Stack>
            <FormControl fullWidth required sx={{ mt: 3 }}>
              <TextField
                name="description"
                error={responseErrorMap.has('description')}
                helperText={responseErrorMap.get('description')}
                label={t('label.description')}
                minRows={2}
                multiline
                value={property.description ?? ''}
                onChange={handleChangeInput}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" spacing={2} mt={3}>
            <FormControl fullWidth>
              <InputLabel id="select-locations">
                {t('inputs.locations')}
              </InputLabel>
              <Select
                name="location"
                labelId="select-locations"
                value={property.location ?? ''}
                onChange={handleChangeInput}
                label={t('inputs.locations')}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location['@id']}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                name="serialNumber"
                error={responseErrorMap.has('serialNumber')}
                helperText={responseErrorMap.get('serialNumber')}
                label={t('label.serial_number')}
                value={property.serialNumber ?? ''}
                onChange={handleChangeInput}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                name="warrantyTo"
                error={responseErrorMap.has('warrantyTo')}
                helperText={responseErrorMap.get('warrantyTo')}
                InputLabelProps={{
                  shrink: true,
                }}
                label={t('label.warranty_to')}
                value={
                  property.warrantyTo
                    ? dateFormat(property.warrantyTo, 'yyyy-mm-dd')
                    : ''
                }
                onChange={handleChangeInput}
                type="date"
              />
            </FormControl>
          </Stack>
          {locationsFetchError && (
            <Alert severity="error">Error fetching locations</Alert>
          )}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              endIcon={<AddCircleOutlineIcon />}
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </Grid>
      </form>
    </Box>
  );
}
