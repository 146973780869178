import api from 'store/api';

export interface AuthProps {
  username: string;
  password: string;
}

export interface AuthResponse {
  token: string;
}

export interface AuthByGoogleProps {
  credential: string | undefined;
}

export interface ResetProps {
  email: string;
}

export interface ResetWithTokenProps {
  password: string;
  confirm: string;
  token: string;
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    authUser: builder.mutation<AuthResponse, AuthProps>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Organizations'],
    }),

    authUserByGoogle: builder.mutation<AuthResponse, AuthByGoogleProps>({
      query: (body) => ({
        url: '/google/login-callback',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Organizations'],
    }),

    resetPassword: builder.mutation<AuthResponse, ResetProps>({
      query: (body) => ({
        url: '/users/password-reset',
        method: 'POST',
        body,
      }),
    }),

    passwordSetupWithToken: builder.mutation<
      ResetWithTokenProps,
      Partial<ResetWithTokenProps> & Pick<ResetWithTokenProps, 'token'>
    >({
      query: ({ token, ...body }) => ({
        url: `/users/password-setup/${token}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAuthUserMutation,
  useAuthUserByGoogleMutation,
  useResetPasswordMutation,
  usePasswordSetupWithTokenMutation,
} = authApi;
