import store from 'store';
import queryString from 'query-string';

const downloadWorkingHoursReport = (
  isPdf: boolean,
  dateBefore: string,
  dateAfter: string,
  employee: string[],
  project: string[],
) => {
  const state = store.getState();
  const { organization } = state.organization;
  const token = localStorage?.token as string;

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  const headers = new Headers();
  headers.append('authorization', `Bearer ${token}`);

  if (isPdf) {
    headers.append('accept', 'application/pdf');
  } else {
    headers.append('accept', 'text/csv');
  }

  if (organization.instance) {
    headers.append('instance', organization.instance);
  }

  const queryParams = queryString.stringify(
    {
      employee,
      project,
      'date[before]': dateBefore,
      'date[after]': dateAfter,
    },
    { arrayFormat: 'bracket' },
  );

  const downloadLink = `${process.env.REACT_APP_API_BASE_URL}/employee-working-hours?${queryParams}`;

  fetch(downloadLink, { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);
      anchor.href = objectUrl;
      anchor.download = 'report';
      anchor.click();

      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(objectUrl);
    });
};

export default downloadWorkingHoursReport;
