import React from 'react';
import { Box } from '@mui/material';
import { useGetActiveHolidaysQuery } from 'store/holidays/holidays';
import dateFormat from 'dateformat';
import { getHolidayIcon } from 'helpers/getHolidayData';
import { useTranslation } from 'react-i18next';
import {
  WidgetCaption,
  WidgetCaptionContainer,
  WidgetContainer,
  WidgetTitle,
} from 'common/Widget';
import { Holiday } from 'store/holidays/interfaces';
import useHolidayTypes from 'hooks/useHolidayTypes';

function AbsentEmployeesList() {
  const { t } = useTranslation();

  const { noRemoteTypes, holidayTypesError, holidayTypesLoading } =
    useHolidayTypes();

  const {
    data: holidays = [],
    isLoading: holidaysLoading,
    isError: holidaysError,
    isSuccess,
  } = useGetActiveHolidaysQuery({ type: noRemoteTypes });
  const getDisplayedDate = (holiday: Holiday) => {
    const { dateFrom, dateTo } = holiday;

    if (dateFrom !== dateTo) {
      return `${dateFormat(dateFrom, 'dd.mm')}-${dateFormat(dateTo, 'dd.mm')}`;
    }

    if (holiday.hours && holiday.hours % 8 !== 0) {
      return `${dateFormat(dateFrom, 'dd.mm')} (${holiday.hours}h)`;
    }

    return dateFormat(dateFrom, 'dd.mm');
  };

  return (
    <WidgetContainer
      title={<WidgetTitle>{t('dashboard.absent_employees')}</WidgetTitle>}
      isError={holidaysError || holidayTypesError}
      isLoading={holidaysLoading || holidayTypesLoading}
    >
      {isSuccess &&
        holidays.map((holiday) => (
          <WidgetCaptionContainer key={holiday.id}>
            <Box display="flex" alignItems="center" gap={2}>
              {getHolidayIcon(holiday.type)}
              <WidgetCaption>{holiday.employeeReversedFullname}</WidgetCaption>
            </Box>
            <WidgetCaption>{getDisplayedDate(holiday)}</WidgetCaption>
          </WidgetCaptionContainer>
        ))}
    </WidgetContainer>
  );
}

export default AbsentEmployeesList;
