import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useGetPositionsQuery } from 'store/positions/positions';
import { useTranslation } from 'react-i18next';
import PositionsList from 'components/PositionsList/PositionsList';
import Error from 'common/Error';
import Loader from 'common/Loader';
import AddPositionForm from 'components/PositionsList/AddPositionForm';

function Positions() {
  const [isAddPositionDialogOpen, setIsAddPositionDialogOpen] = useState(false);
  const {
    data: positions = [],
    isLoading: positionsFetching,
    isError: positionsFetchError,
    isSuccess: positionsFetched,
  } = useGetPositionsQuery();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={10} bgcolor="background.list">
        <NavBarContainer>
          <NavBarTitle>{t('positions.header')}</NavBarTitle>
          <Button
            variant="contained"
            onClick={() => setIsAddPositionDialogOpen(true)}
            endIcon={<AddCircleOutlineIcon />}
          >
            {t('button.create')}
          </Button>
        </NavBarContainer>
        {positionsFetching && <Loader />}
        {positionsFetchError && <Error />}
        {positionsFetched && <PositionsList positions={positions} />}
      </Grid>
      <Dialog
        open={isAddPositionDialogOpen}
        onClose={() => setIsAddPositionDialogOpen(false)}
        fullWidth
      >
        <AddPositionForm setIsOpen={setIsAddPositionDialogOpen} />
      </Dialog>
    </>
  );
}

export default Positions;
