import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import {
  TransactionAttachment,
  useGetTransactionAttachmentQuery,
  useUpdateTransactionAttachmentMutation,
} from 'store/transactions/transactions';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { Box, DialogContent, InputLabel, TextField } from '@mui/material';
import Loader from 'common/Loader';
import Error from 'common/Error';
import BaseData from 'components/AddAttachmentForm/BaseData/BaseData';
import Financial from 'components/AddAttachmentForm/Financial/Financial';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { StatusProps } from 'hoc/Status/Status';
import { skipToken } from '@reduxjs/toolkit/query';
import dateFormat from 'dateformat';
import { CustomError } from 'store/api';
import { useValidate } from 'hooks/useValidate';
import { LoadingButton } from '@mui/lab';

export interface EditAttachmentFormProps extends StatusProps {
  attachmentId: number;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Errors {
  receivedAt: boolean;
  relatedMonth: boolean;
}

function EditAttachmentForm({
  attachmentId,
  setIsOpen,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: EditAttachmentFormProps) {
  const { t } = useTranslation();
  const {
    data: attachment = {} as TransactionAttachment,
    isLoading: attachmentFetching,
    isError: attachmentFetchError,
    isSuccess: attachmentFetched,
  } = useGetTransactionAttachmentQuery(attachmentId ?? skipToken);
  const {
    data: currencies = [],
    isLoading: currenciesFetching,
    isError: currenciesFetchError,
    isSuccess: currenciesFetched,
  } = useGetCurrenciesQuery();
  const [name, setName] = useState('');
  const [receivedAt, setReceivedAt] = useState('');
  const [amount, setAmount] = useState('');
  const [relatedMonth, setRelatedMonth] = useState('');
  const [cost, setCost] = useState('');
  const [currency, setCurrency] = useState('');
  const [disableCurrencySelect, setDisableCurrencySelect] = useState(false);
  const [updateAttachment, { isLoading }] =
    useUpdateTransactionAttachmentMutation();
  const { errors, validate } = useValidate<Errors>();

  useEffect(() => {
    if (!attachmentFetched) {
      return;
    }
    setName(attachment.name);
    setReceivedAt(dateFormat(attachment.receivedAt, 'yyyy-mm-dd'));
    setAmount(String(attachment.amount / 100));
    setRelatedMonth(dateFormat(attachment.relatedMonth, 'yyyy-mm-dd'));
    setCost(attachment.cost);
    setCurrency(attachment.currency);
  }, [attachmentFetched, attachment]);

  useEffect(() => {
    if (!currenciesFetched) {
      return;
    }
    if (currencies.length > 1 || !currencies.length) {
      return;
    }
    setCurrency(currencies[0].id);
    setDisableCurrencySelect(true);
  }, [currencies, currenciesFetched]);

  const disableSubmission = () =>
    !name || !receivedAt || !relatedMonth || !cost || !currency;

  const handleUpdateAttachment = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateAttachment({
        id: attachmentId,
        name,
        receivedAt: new Date(receivedAt),
        amount: +amount * 100,
        relatedMonth: new Date(relatedMonth),
        cost,
        currency,
      });
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        title={t('transactions.edit_attachment')}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        {(currenciesFetching || attachmentFetching) && <Loader />}
        {(currenciesFetchError || attachmentFetchError) && <Error />}
        {currenciesFetched && attachmentFetched && (
          <form onSubmit={handleUpdateAttachment}>
            <Box display="flex" flexDirection="column" gap={3} py={3}>
              <BaseData
                name={name}
                setName={setName}
                cost={cost}
                setCost={setCost}
              />
              <Financial
                disabled={disableCurrencySelect}
                amount={amount}
                setAmount={setAmount}
                currency={currency}
                setCurrency={setCurrency}
                currencies={currencies}
                isEdit
              />
              <Box display="flex" alignItems="center" gap={2}>
                <Box width="100%">
                  <InputLabel
                    error={errors.receivedAt}
                    id="received-at"
                    required
                  >
                    {t('inputs.received_at')}
                  </InputLabel>
                  <TextField
                    error={errors.receivedAt}
                    id="received-at"
                    fullWidth
                    helperText={errors.receivedAt && t('errors.field_required')}
                    name="receivedAt"
                    onBlur={(event) =>
                      validate('receivedAt', event.target.value !== '')
                    }
                    onChange={(event) => setReceivedAt(event.target.value)}
                    required
                    type="date"
                    value={receivedAt}
                  />
                </Box>
                <Box width="100%">
                  <InputLabel
                    error={errors.relatedMonth}
                    id="related-month"
                    required
                  >
                    {t('transactions.related_month')}
                  </InputLabel>
                  <TextField
                    error={errors.relatedMonth}
                    id="related-month"
                    fullWidth
                    helperText={
                      errors.relatedMonth && t('errors.field_required')
                    }
                    name="relatedMonth"
                    onBlur={(event) =>
                      validate('relatedMonth', event.target.value !== '')
                    }
                    onChange={(event) => setRelatedMonth(event.target.value)}
                    required
                    type="date"
                    value={relatedMonth}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  disabled={disableSubmission()}
                  endIcon={<AddCircleOutlineIcon />}
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  <span>{t('button.save')}</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </>
  );
}

export default EditAttachmentForm;
