import { Home, HomeOutlined } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { HolidayTypes } from 'enums';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CustomError } from 'store/api';

import {
  useAddRemoteHolidayMutation,
  useDeleteRemoteHolidayMutation,
} from 'store/holidays/holidays';
import { Holiday } from 'store/holidays/interfaces';
import {
  setErrorMessage,
  setErrorStatus,
  setSuccessStatus,
} from 'store/status/actions';

interface RemoteCheckProps {
  currentUserIri: string;
  tileDate: string;
  remoteHoliday: Holiday | undefined;
}

export default function RemoteCheck({
  currentUserIri,
  tileDate,
  remoteHoliday,
}: RemoteCheckProps) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(!!remoteHoliday);
  const [addRemote, { isLoading: addingHoliday }] =
    useAddRemoteHolidayMutation();
  const [deleteRemote, { isLoading: deletingHoliday }] =
    useDeleteRemoteHolidayMutation();
  const dispatch = useDispatch();

  const handleAddRemote = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      try {
        await addRemote({
          employee: currentUserIri,
          type: HolidayTypes.remote,
          description: 'remote',
          dateFrom: tileDate,
          dateTo: tileDate,
        }).unwrap();
        dispatch(setSuccessStatus(true));
        setChecked(true);
      } catch (error) {
        if ('data' in (error as CustomError)) {
          setErrorMessage((error as CustomError).data['hydra:description']);
        }
        dispatch(setErrorStatus(true));
      }
    } else {
      try {
        await deleteRemote({
          id: remoteHoliday?.id,
          employee: currentUserIri,
        }).unwrap();
        dispatch(setSuccessStatus(true));
        setChecked(false);
      } catch (error) {
        if ('data' in (error as CustomError)) {
          setErrorMessage((error as CustomError).data['hydra:description']);
        }
        dispatch(setErrorStatus(true));
      }
    }
  };

  return (
    <TitleTooltip title={t('holidays.remote')}>
      <Checkbox
        checked={checked}
        checkedIcon={<Home color="secondary" fontSize="large" />}
        disabled={addingHoliday || deletingHoliday}
        icon={<HomeOutlined color="disabled" fontSize="large" />}
        onChange={(event) => {
          handleAddRemote(event);
        }}
        sx={{ padding: 0 }}
      />
    </TitleTooltip>
  );
}
