/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AttachmentSelectProps {
  label: string;
  selectId: string;
  value: string;
  onChangeFn: (event: SelectChangeEvent) => void;
  selectOptions: Array<{
    id: string | number;
    description: string;
  }>;
  disabled?: boolean;
  width?: string;
}

function AttachmentSelect({
  label,
  selectId,
  value,
  onChangeFn,
  selectOptions,
  disabled = false,
  width = '100%',
}: AttachmentSelectProps) {
  const { t } = useTranslation();

  return (
    <FormControl disabled={disabled} required sx={{ width }}>
      <InputLabel id={selectId}>{t(label)}</InputLabel>
      <Select
        id={selectId}
        label={t(label)}
        value={value}
        onChange={onChangeFn}
        data-testid={selectId}
      >
        {selectOptions.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {t(item.description)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default AttachmentSelect;
