import React from 'react';
import { useParams, useNavigate } from 'react-router';
import { endOfMonth, startOfMonth } from 'date-fns';
import dateFormat from 'dateformat';
import { Stack } from '@mui/material';
import CancelButton from 'common/CancelButton/CancelButton';
import AddButton from 'common/AddButton/AddButton';
import useBudgetPeriodContext from 'pages/BudgetPeriod/context/useBudgetPeriodContext';
import { useUpdateBudgetMutation } from 'store/budget/budget';
import { PeriodState } from 'pages/BudgetPeriod/context/BudgetPeriod.context';
import { useAppDispatch } from 'store/hooks';
import {
  setSuccessStatus,
  setErrorCatch,
} from 'store/status/actions';
import { BudgetPeriod } from 'store/budget/interfaces';

interface FilledState {
  period: BudgetPeriod;
  dateFrom: Date;
  dateTo: Date;
}

export default function BudgetPeriodActionButtons() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { handleResetPeriodState, periodState } = useBudgetPeriodContext();
  const [updateBudget] = useUpdateBudgetMutation();

  const isFilledState = (state: PeriodState): state is FilledState =>
    Boolean(state.period && state.dateFrom && state.dateTo);

  const handleOnSaveBudget = async () => {
    if (!isFilledState(periodState)) {
      return;
    }

    const payload = {
      ...periodState,
      dateFrom: dateFormat(startOfMonth(periodState.dateFrom), 'yyyy-mm-dd'),
      dateTo: dateFormat(endOfMonth(periodState.dateTo), 'yyyy-mm-dd'),
    };
    try {
      await updateBudget({
        id,
        ...payload,
      }).unwrap();
      dispatch(setSuccessStatus(true));
      navigate(`/budget/${id}/planner`);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <CancelButton onClick={handleResetPeriodState} />
      <AddButton
        onClick={handleOnSaveBudget}
        disabled={!isFilledState(periodState)}
        noIcon
        label="budget.save_budget"
      />
    </Stack>
  );
}
