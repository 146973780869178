import { TextField, TextFieldProps, styled } from '@mui/material';

const StyledAmountInput = styled(TextField)<TextFieldProps>(() => ({
  flexBasis: '40%',
  '& .MuiInputBase-root': {
    borderRadius: 0,
    borderTopLeftRadius: '1.2rem',
    borderBottomLeftRadius: '1.2rem',
  },
}));

export default StyledAmountInput;
