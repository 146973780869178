/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import {
  amountFrequency,
  amountSort,
} from 'components/AddAgreementForm/selectData';
import { useValidate } from 'hooks/useValidate';
import { useAddAgreementMutation } from 'store/agreements/agreements';
import AgreementDetails from 'components/AddAgreementForm/AgreementDetails';
import DateSelect from 'components/AddAgreementForm/DateSelect';
import WorkDetails from 'components/AddAgreementForm/WorkDetails';
import { Errors } from 'components/AddAgreementForm/interfaces';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import { useGetPositionsQuery } from 'store/positions/positions';
import { CustomError } from 'store/api';
import { StatusProps } from 'hoc/Status/Status';
import { LoadingButton } from '@mui/lab';

export interface AddAgreementFormProps extends StatusProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddAgreementForm({
  setIsSuccess,
  setIsError,
  isOpen,
  setIsOpen,
  setErrorMessage,
}: AddAgreementFormProps) {
  const {
    data: employees = [],
    isError: employeeFetchError,
    isLoading: employeeLoading,
  } = useGetEmployeesPreview({ isActive: true });
  const {
    data: currencies = [],
    isError: currenciesFetchError,
    isLoading: currenciesLoading,
    isSuccess: currenciesFetched,
  } = useGetCurrenciesQuery();
  const {
    data: positions = [],
    isLoading: positionsFetching,
    isError: positionsFetchError,
  } = useGetPositionsQuery();
  const [employeeId, setEmployeeId] = useState('');
  const [selectedPosition, setSelectedPosition] = useState<string | null>(null);
  const [writtenPosition, setWrittenPosition] = useState('');
  const [type, setType] = useState('');
  const [variant, setVariant] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hoursPerWeek, setHoursPerWeek] = useState('40');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [perFrequency, setPerFrequency] = useState(amountFrequency[0].value);
  const [amountType, setAmountType] = useState(amountSort[0]);
  const { errors, validate } = useValidate<Errors>();
  const [addAgreement, { isLoading }] = useAddAgreementMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currenciesFetched) {
      return;
    }
    setCurrency(currencies[0].id);
  }, [currenciesFetched, currencies]);

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) ||
    !employeeId ||
    !writtenPosition ||
    !type ||
    !variant ||
    !startDate ||
    !hoursPerWeek ||
    !amount;

  const handleSelectChange = (
    event: SelectChangeEvent,
    setFieldValue: React.Dispatch<React.SetStateAction<string>>,
    field: any,
  ) => {
    setFieldValue(event.target.value);
    validate(field, event.target.value !== '');
  };

  const handleAddAgreement = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      const addedAgreement = await addAgreement({
        employee: employeeId,
        type,
        variant,
        amount: Number(amount) * 10000,
        currency,
        hoursPerWeek: Number(hoursPerWeek),
        amountType,
        billingType: perFrequency,
        position: writtenPosition,
        dateFrom: startDate,
        dateTo: endDate || null,
      }).unwrap();
      setIsSuccess(true);
      navigate(`/agreements/${addedAgreement.id}`, { replace: true });
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (employeeFetchError || currenciesFetchError || positionsFetchError) {
    return <Error />;
  }

  if (employeeLoading || currenciesLoading || positionsFetching) {
    return <Loader />;
  }

  return (
    <Box p={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="h5">{t('agreements.add_new')}</Typography>
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            width: '4rem',
            height: '4rem',
            bgcolor: 'secondary.light',
            borderRadius: '50%',
          }}
        >
          <Close fontSize="large" />
        </IconButton>
      </Box>
      <form onSubmit={handleAddAgreement}>
        <Box display="flex" flexDirection="column" rowGap={3}>
          <FormControl required error={errors.employee} fullWidth>
            <InputLabel id="employee-select">
              {t('inputs.select_employee')}
            </InputLabel>
            <Select
              id="employee-select"
              value={employeeId}
              label={t('inputs.select_employee')}
              onChange={(event) =>
                handleSelectChange(event, setEmployeeId, 'employee')
              }
            >
              {employees.map((employee) => (
                <MenuItem key={employee.id} value={employee['@id']}>
                  {employee.lastname} {employee.firstname}
                </MenuItem>
              ))}
            </Select>
            {errors.employee && (
              <FormHelperText>{t('errors.field_required')}</FormHelperText>
            )}
          </FormControl>
          <Autocomplete
            freeSolo
            value={selectedPosition}
            onChange={(event, newValue: string | null) => {
              setSelectedPosition(newValue);
            }}
            inputValue={writtenPosition}
            onInputChange={(event, newInputValue) => {
              setWrittenPosition(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.position}
                helperText={errors.position && t('inputs.field_required')}
                required
                label={t('inputs.position')}
                onBlur={(event) =>
                  validate('position', event.target.value.length >= 2)
                }
              />
            )}
            options={positions.map((positionData) => positionData.name)}
          />
          <Box display="flex" columnGap={3}>
            <AgreementDetails
              errors={errors}
              type={type}
              setType={setType}
              variant={variant}
              setVariant={setVariant}
              handleChange={handleSelectChange}
            />
          </Box>
          <DateSelect
            errors={errors}
            startDate={startDate}
            onStartDateChange={(event) => setStartDate(event.target.value)}
            endDate={endDate}
            onEndDateChange={(event) => setEndDate(event.target.value)}
            validate={validate}
          />
          <WorkDetails
            errors={errors}
            hoursPerWeek={hoursPerWeek}
            onHoursPerWeekChange={(event) =>
              setHoursPerWeek(event.target.value)
            }
            amount={amount}
            onAmountChange={(event) => setAmount(event.target.value)}
            currency={currency}
            onCurrencyChange={(event) => setCurrency(event.target.value)}
            currencies={currencies}
            perFrequency={perFrequency}
            onPerFrequencyChange={(event) =>
              setPerFrequency(event.target.value)
            }
            amountType={amountType}
            onAmountTypeChange={(event) => setAmountType(event.target.value)}
            validate={validate}
          />
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default AddAgreementForm;
