export interface AuthorizationStateProps {
  isAuthorized: boolean;
  token: string | null;
}

const defaultStatus: AuthorizationStateProps = {
  isAuthorized: false,
  token: null,
};

export default defaultStatus;
