import { Property } from 'store/properties/interfaces';
import { PropertyBooking } from 'store/propertyBooking/interfaces';

export default function getPropertyBookingCurrentReservedUser(
  propertyBookings: PropertyBooking[],
) {

  function getEmployeeObject(property: Property) {
    return propertyBookings.find(
      (booking) => booking.property['@id'] === property['@id'],
    )?.employeeObject;
  }

  return getEmployeeObject;
}
