import React from 'react';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import EditButton from 'common/EditButton/EditButton';
import { BankAccount } from 'store/accounts/accounts';
import replaceApiIri from 'helpers/replaceApiIri';

interface BankAccountRowProps {
  bankAccount: BankAccount;
  isVisible: boolean;
  handleOpenEditAccount: (accountId: string) => void;
}

export default function BankAccountRow({
  bankAccount,
  isVisible,
  handleOpenEditAccount,
}: BankAccountRowProps) {
  return (
    <TableRow
      sx={{
        visibility: isVisible ? 'visible' : 'collapse',
      }}
    >
      <TableCell>
        <Typography pl={6}>{bankAccount.name}</Typography>
      </TableCell>
      <TableCell />
      <TableCell>
        <Typography>{bankAccount.type}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {replaceApiIri(bankAccount.currency, 'currencies').toUpperCase()}{' '}
          {'**** '.repeat(3)}
          {bankAccount.number.slice(-4)}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Stack
          justifyContent="flex-end"
          spacing={1}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
        >
          <EditButton
            v2
            onClick={() => handleOpenEditAccount(bankAccount.id)}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
