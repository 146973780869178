import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import ContractorGroupsList from 'components/ContractorGroupsList/ContractorGroupsList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddContractorGroupForm from 'components/AddContractorGroupForm';

function ContractorGroups() {
  const { t } = useTranslation();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  return (
    <>
      <Grid item xs={10} bgcolor="background.list">
        <NavBarContainer>
          <NavBarTitle>{t('contractor_groups.header')}</NavBarTitle>
          <Button
            endIcon={<AddCircleOutlineIcon />}
            onClick={() => setIsAddFormOpen(true)}
            variant="contained"
          >
            {t('button.create')}
          </Button>
        </NavBarContainer>
        <ContractorGroupsList />
      </Grid>
      <Dialog
        fullWidth
        onClose={() => setIsAddFormOpen(false)}
        open={isAddFormOpen}
      >
        <AddContractorGroupForm setIsOpen={setIsAddFormOpen} />
      </Dialog>
    </>
  );
}

export default ContractorGroups;
