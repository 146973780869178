import React, { useState } from 'react';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import CloseButton from 'common/CloseButton/CloseButton';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useValidate } from 'hooks/useValidate';
import {
  Position,
  useGetPositionsQuery,
  useUpdatePositionMutation,
} from 'store/positions/positions';
import { StatusProps } from 'hoc/Status/Status';
import { LoadingButton } from '@mui/lab';
import { CustomError } from 'store/api';

export interface EditPositionFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
}

interface Errors {
  position: boolean;
}

const emptyPositions: Position[] = [];

function EditPositionForm({
  setIsOpen,
  id,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditPositionFormProps) {
  const { positionData } = useGetPositionsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      positionData: data?.filter((item) => item.id === id) ?? emptyPositions,
    }),
  });
  const [position, setPosition] = useState(positionData[0].name);
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();
  const [updatePosition, { isLoading }] = useUpdatePositionMutation();

  const handleEditPosition = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updatePosition({
        id,
        name: position,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">{t('positions.edit_position')}</Typography>
        <CloseButton setIsOpen={setIsOpen} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleEditPosition}>
          <FormControl required fullWidth sx={{ pt: 4 }}>
            <TextField
              error={errors.position}
              helperText={errors.position && t('errors.field_required')}
              id="position"
              label={t('inputs.position')}
              fullWidth
              required
              value={position}
              onChange={(event) => setPosition(event.target.value)}
              onBlur={(event) =>
                validate('position', event.target.value.length >= 2)
              }
            />
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default EditPositionForm;
