import api, { CustomError } from 'store/api';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';
import {
  ResourceRequestCandidatesData,
  ResourceRequestCandidate,
  ResourceRequestCandidatePostMutationRequest,
  ResourceRequestCandidatePatchMutationRequest,
  ResourceRequestCandidateFilters,
} from './interfaces';
import shouldModifyResourceRequestCandidatesCache from './shouldModifyResourceRequestCandidatesCache';

export const resourceRequestCandidatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getResourceRequestCandidates: builder.query<
      ResourceRequestCandidatesData,
      Partial<ResourceRequestCandidateFilters>
    >({
      query: (filters) => ({
        url: '/resource-request-candidates',
        params: filters,
      }),
      providesTags: ['ResourceRequestCandidates'],
      transformResponse: (response: ResourceRequestCandidatesData) => ({
        ...response,
        'hydra:member': response['hydra:member'].sort(
          (resourceRequestCandidate, comparedResourceRequestCandidate) =>
            new Date(comparedResourceRequestCandidate.createdAt).getTime() -
            new Date(resourceRequestCandidate.createdAt).getTime(),
        ),
      }),
    }),
    getResourceRequestCandidate: builder.query<
      ResourceRequestCandidate,
      string
    >({
      query: (id) => ({ url: `/resource-request-candidates/${id}` }),
      providesTags: ['ResourceRequestCandidates'],
    }),
    addResourceRequestCandidate: builder.mutation<
      ResourceRequestCandidate,
      ResourceRequestCandidatePostMutationRequest
    >({
      query: (payload) => ({
        url: '/resource-request-candidates',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: createdResourceRequestCandidate } = await queryFulfilled;

          const resourceRequestCandidatesCache = api.util.selectInvalidatedBy(
            getState(),
            [{ type: 'ResourceRequestCandidates' }],
          );
          resourceRequestCandidatesCache
            .filter(
              ({ endpointName }) =>
                endpointName === 'getResourceRequestCandidates',
            )
            .forEach(({ originalArgs }) => {
              const shouldModifyCache = shouldModifyResourceRequestCandidatesCache(
                originalArgs,
                createdResourceRequestCandidate,
              );

              if (!shouldModifyCache) {
                return;
              }

              dispatch(
                resourceRequestCandidatesApi.util.updateQueryData(
                  'getResourceRequestCandidates',
                  originalArgs,
                  (draft) => {
                    draft?.['hydra:member']?.unshift(createdResourceRequestCandidate);
                  },
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      }
    }),
    updateResourceRequestCandidate: builder.mutation<
      ResourceRequestCandidate,
      Partial<ResourceRequestCandidatePatchMutationRequest>
    >({
      query: ({ id, ...body }) => ({
        url: `/resource-request-candidates/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedResourceRequestCandidate } =
            await queryFulfilled;
          const resourceRequestCandidatesCache = api.util.selectInvalidatedBy(
            getState(),
            [{ type: 'ResourceRequestCandidates' }],
          );
          resourceRequestCandidatesCache
            .filter(
              ({ endpointName }) =>
                endpointName === 'getResourceRequestCandidates',
            )
            .forEach(({ originalArgs }) => {
              const shouldModifyCache =
                shouldModifyResourceRequestCandidatesCache(
                  originalArgs,
                  updatedResourceRequestCandidate,
                );

              if (!shouldModifyCache) {
                return;
              }

              dispatch(
                resourceRequestCandidatesApi.util.updateQueryData(
                  'getResourceRequestCandidates',
                  originalArgs,
                  (draft) => {
                    const resourceRequestCandidateIndex = draft?.[
                      'hydra:member'
                    ]?.findIndex((candidate) => candidate.id === id);

                    if (resourceRequestCandidateIndex !== -1) {
                      draft['hydra:member'].splice(
                        resourceRequestCandidateIndex,
                        1,
                        updatedResourceRequestCandidate,
                      );
                    }
                  },
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    deleteResourceRequestCandidate: builder.mutation<void, string>({
      query: (id) => ({
        url: `/resource-request-candidates/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const resourceRequestCandidatesCache = api.util.selectInvalidatedBy(
            getState(),
            [{ type: 'ResourceRequestCandidates' }],
          );
          resourceRequestCandidatesCache
            .filter(
              ({ endpointName }) =>
                endpointName === 'getResourceRequestCandidates',
            )
            .forEach(({ originalArgs }) => {
              dispatch(
                resourceRequestCandidatesApi.util.updateQueryData(
                  'getResourceRequestCandidates',
                  originalArgs,
                  (draft) => ({
                    ...draft,
                    'hydra:member': draft['hydra:member'].filter(
                      (resourceRequestCandidate: ResourceRequestCandidate) =>
                        resourceRequestCandidate.id !== id,
                    ),
                  }),
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
  }),
});

export const {
  useGetResourceRequestCandidatesQuery,
  useGetResourceRequestCandidateQuery,
  useAddResourceRequestCandidateMutation,
  useUpdateResourceRequestCandidateMutation,
  useDeleteResourceRequestCandidateMutation,
} = resourceRequestCandidatesApi;
