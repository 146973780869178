import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { useGetWorkingHoursQuery } from 'store/workingHours/workingHours';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { useGetBankHolidaysQuery } from 'store/bankHolidays/bankHolidays';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import Calendar from 'components/Calendar/Calendar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import downloadWorkingHoursReport from 'helpers/downloadWorkingHoursReport';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import { useGetProjectsQuery } from 'store/projects/projects';
import { HolidayTypes, ProjectStatuses } from 'enums';
import { LoggedEmployee } from 'store/employee/interfaces';
import { useGetHolidaysQuery } from 'store/holidays/holidays';
import createApiIri from 'helpers/createApiIri';
import replaceApiIri from 'helpers/replaceApiIri';
import DataSelect from './DataSelect/DataSelect';

function EmployeeWorkingHours() {
  const { t } = useTranslation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const [displayedMonth, setDisplayedMonth] = useState(currentMonth);
  const [displayedYear, setDisplayedYear] = useState(currentYear);
  const [dateBefore, setDateBefore] = useState(
    dateFormat(new Date(displayedYear, displayedMonth + 1, 0), 'yyyy-mm-dd'),
  );
  const [dateAfter, setDateAfter] = useState(
    dateFormat(new Date(displayedYear, displayedMonth, 1), 'yyyy-mm-dd'),
  );
  const {
    data: currentEmployee = {} as LoggedEmployee,
    isLoading: userFetching,
    isError: userFetchError,
    isSuccess: userFetched,
  } = useGetLoggedEmployeeQuery();
  const isViewer = useCheckRoles([
    ...roles.workingHoursViewer,
    ...roles.workingHoursManager,
  ]);
  const {
    selected: displayedEmployeeIris,
    setSelected: setDisplayedEmployee,
    isSelected: isEmployeeSelected,
    handleChange,
  } = useMultipleCheckboxSelect<string>([]);
  const {
    selected: selectedProjectIris,
    isSelected: isProjectSelected,
    handleChange: onProjectsChange,
  } = useMultipleCheckboxSelect<string>([]);
  const displayedEmployeeIds = displayedEmployeeIris.map((employee) =>
    replaceApiIri(employee, 'employees'),
  );
  const selectedProjectIds = selectedProjectIris.map((project) =>
    replaceApiIri(project, 'projects'),
  );

  const {
    data: employees = [],
    isLoading: employeesFetching,
    isError: employeesFetchError,
  } = useGetEmployeesPreview(
    { isActive: true },
    {
      skip: !isViewer,
    },
  );
  const {
    data: workingHours = [],
    isLoading: workingHoursFetching,
    isError: workingHoursFetchError,
  } = useGetWorkingHoursQuery(
    {
      employee: displayedEmployeeIds,
      'date[after]': dateAfter,
      'date[before]': dateBefore,
      project: selectedProjectIds,
    },
    {
      skip: !displayedEmployeeIds.length && !selectedProjectIds.length,
    },
  );
  const {
    isLoading: allProjectsPreviewFetching,
    isError: allProjectsPreviewFetchError,
  } = useGetProjectsQuery(
    { status: ProjectStatuses.active, properties: ['id', 'name'] },
    { skip: !isViewer },
  );
  const {
    data: employeeHolidays = {
      'hydra:member': [],
    },
    isLoading: employeeHolidaysFetching,
    isError: employeeHolidaysFetchError,
  } = useGetHolidaysQuery(
    { employee: displayedEmployeeIds[0] },
    {
      skip: !displayedEmployeeIds.length || displayedEmployeeIds.length > 1,
    },
  );

  const {
    data: holidays = [],
    isLoading: holidaysFetching,
    isError: holidaysFetchError,
  } = useGetBankHolidaysQuery();
  const formattedHolidays = useMemo(
    () =>
      holidays.map(({ date }) => {
        const dateWithYear = date.replace('*', String(displayedYear));
        return dateFormat(dateWithYear, 'yyyy-mm-dd');
      }),
    [holidays, displayedYear],
  );
  const remoteHolidays = useMemo(
    () =>
      employeeHolidays['hydra:member'].filter(
        (holiday) => holiday.type === HolidayTypes.remote,
      ),
    [employeeHolidays],
  );
  useEffect(() => {
    if (!userFetched) {
      return;
    }
    setDisplayedEmployee([createApiIri('employees', `${currentEmployee.id}`)]);
  }, [currentEmployee, userFetched, setDisplayedEmployee]);

  const handleDisplayPreviousMonth = () => {
    if (displayedMonth === 0) {
      setDisplayedMonth(11);
      setDisplayedYear(displayedYear - 1);
      setDateBefore(dateFormat(new Date(displayedYear, 0, 0), 'yyyy-mm-dd'));
      setDateAfter(
        dateFormat(new Date(displayedYear - 1, 11, 1), 'yyyy-mm-dd'),
      );
    } else {
      setDisplayedMonth(displayedMonth - 1);
      setDateBefore(
        dateFormat(new Date(displayedYear, displayedMonth, 0), 'yyyy-mm-dd'),
      );
      setDateAfter(
        dateFormat(
          new Date(displayedYear, displayedMonth - 1, 1),
          'yyyy-mm-dd',
        ),
      );
    }
  };

  const handleDisplayNextMonth = () => {
    if (displayedMonth === 11) {
      setDisplayedMonth(0);
      setDisplayedYear(displayedYear + 1);
      setDateBefore(
        dateFormat(new Date(displayedYear + 1, 1, 0), 'yyyy-mm-dd'),
      );
      setDateAfter(dateFormat(new Date(displayedYear + 1, 0, 1), 'yyyy-mm-dd'));
    } else {
      setDisplayedMonth(displayedMonth + 1);
      setDateBefore(
        dateFormat(
          new Date(displayedYear, displayedMonth + 2, 0),
          'yyyy-mm-dd',
        ),
      );
      setDateAfter(
        dateFormat(
          new Date(displayedYear, displayedMonth + 1, 1),
          'yyyy-mm-dd',
        ),
      );
    }
  };

  if (
    userFetchError ||
    workingHoursFetchError ||
    holidaysFetchError ||
    employeeHolidaysFetchError ||
    employeesFetchError ||
    allProjectsPreviewFetchError
  ) {
    return <Error />;
  }

  if (
    userFetching ||
    workingHoursFetching ||
    holidaysFetching ||
    employeeHolidaysFetching ||
    employeesFetching ||
    allProjectsPreviewFetching
  ) {
    return <Loader />;
  }

  const monthlyHoursSum = workingHours.reduce(
    (acc, { hours }) => acc + hours,
    0,
  );

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <Box display="flex" alignItems="center" gap={1}>
          <NavBarTitle>{t('project_tracking.header')}</NavBarTitle>
          {isViewer ? (
            <DataSelect
              employees={employees}
              selectedEmployees={displayedEmployeeIris}
              isEmployeeSelected={isEmployeeSelected}
              onCheckboxChange={handleChange}
              selectedProjects={selectedProjectIris}
              isProjectSelected={isProjectSelected}
              onProjectsChange={onProjectsChange}
            />
          ) : (
            <Typography variant="h6">
              {currentEmployee.firstname} {currentEmployee.lastname}
            </Typography>
          )}
        </Box>
        {isViewer && (
          <ButtonGroup>
            <Button
              onClick={() =>
                downloadWorkingHoursReport(
                  true,
                  dateBefore,
                  dateAfter,
                  displayedEmployeeIds,
                  selectedProjectIds,
                )
              }
            >
              PDF
            </Button>
            <Button
              onClick={() =>
                downloadWorkingHoursReport(
                  false,
                  dateBefore,
                  dateAfter,
                  displayedEmployeeIds,
                  selectedProjectIds,
                )
              }
            >
              CSV
            </Button>
          </ButtonGroup>
        )}
        <Typography variant="h6">
          {t('project_tracking.logged_hours')}: {monthlyHoursSum}h
        </Typography>
        <Box display="flex" gap={2} alignItems="center">
          <IconButton onClick={handleDisplayPreviousMonth}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {dateFormat(new Date(displayedYear, displayedMonth), 'mmmm yyyy')}
          </Typography>
          <IconButton onClick={handleDisplayNextMonth}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </NavBarContainer>
      <Calendar
        year={displayedYear}
        month={displayedMonth}
        workingHours={workingHours}
        holidays={formattedHolidays}
        employeeHolidays={
          displayedEmployeeIris.length === 1
            ? employeeHolidays['hydra:member']
            : []
        }
        displayedEmployee={
          displayedEmployeeIris.length === 1 ? displayedEmployeeIris[0] : ''
        }
        currentUserIri={createApiIri('employees', `${currentEmployee.id}`)}
        remoteHolidays={remoteHolidays}
      />
    </Grid>
  );
}

export default EmployeeWorkingHours;
