import { ChipOwnProps } from '@mui/material';

export default function getChipOptions() {
  const getChipLabel = (status: string) => {
    switch (status) {
      case 'active':
        return 'label.active';
      default:
        return 'label.inactive';
    }
  };

  const getChipColor = (status: string): ChipOwnProps['color'] => {
    switch (status) {
      case 'active':
        return 'success';
      default:
        return 'error';
    }
  };

  return { getChipLabel, getChipColor };
}
