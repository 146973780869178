import React from 'react';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import EmployeeName from 'components/AgreementsList/EmployeeName';
import { Agreement } from 'components/AgreementsList/AgreementsList';
import { useTranslation } from 'react-i18next';
import EditButton from 'common/EditButton/EditButton';
import dateFormat from 'dateformat';
import DownloadIcon from '@mui/icons-material/Download';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import Badge from 'common/Badge/Badge';
import IconPopover from 'common/IconPopover/IconPopover';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Status from 'components/AgreementsList/Agreement/Status/Status';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import openFileWithAuth from 'helpers/openFileWithAuth';

interface ResultProps {
  agreement: Agreement;
}

function Result({ agreement }: ResultProps) {
  const { t } = useTranslation();
  const dateFrom = dateFormat(agreement.dateFrom, 'yyyy-mm-dd');
  const dateTo = agreement.dateTo && dateFormat(agreement.dateTo, 'yyyy-mm-dd');

  return (
    <TableRow key={agreement.id}>
      <EmployeeName employeeIri={agreement.employee} />
      <TableCell>
        <Typography variant="body2">{agreement.position}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">{agreement.variant}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">{agreement.type}</Typography>
      </TableCell>
      <TableCell align="center">
        {agreement.dateTo ? (
          <Typography variant="body2">
            {dateFrom}/{dateTo}
          </Typography>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2">{dateFrom}/</Typography>
            <AllInclusiveIcon />
          </Box>
        )}
      </TableCell>
      <TableCell align="center">
        <Status agreement={agreement} />
      </TableCell>
      <TableCell align="center">
        {agreement.files ? (
          agreement.files.map((file) => (
            <Box
              key={file.id}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <IconButton
                onClick={() => downloadFileWithAuth(file.url, file.id)}
              >
                <DownloadIcon fontSize="large" />
              </IconButton>
              <IconButton onClick={() => openFileWithAuth(file.url)}>
                <VisibilityOutlinedIcon fontSize="large" />
              </IconButton>
            </Box>
          ))
        ) : (
          <Badge
            bgcolor="status.missingFile.light"
            iconColor="status.missingFile.main"
            navigateTo={`/agreements/${agreement.id}`}
            tab="2"
          >
            <IconPopover
              Icon={UploadFileOutlinedIcon}
              content={t('agreements.upload_missing_file')}
            />
          </Badge>
        )}
      </TableCell>
      <TableCell align="right">
        <EditButton navigateTo={`/agreements/${agreement.id}`} />
      </TableCell>
    </TableRow>
  );
}

export default Result;
