import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, FormControl, Box, TextField } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import Link from 'common/Link/Link';
import { useAuthUserMutation, useAuthUserByGoogleMutation } from 'store/authorization/authorization';
import { useValidate } from 'hooks/useValidate';
import { AuthProps } from 'hoc/Auth/Auth';

interface Errors {
  username: boolean;
  password: boolean;
}

export interface LoginFormProps extends AuthProps {
  setIsError: (status: boolean) => boolean;
}

export default function LoginForm({
  setIsError,
  setIsAuthorized,
  setToken,
}: LoginFormProps) {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const [authUser, { isLoading }] = useAuthUserMutation();
  const [authUserByGoogle] = useAuthUserByGoogleMutation();
  const navigate = useNavigate();

  const authorizeAndRedirect = (token: string) => {
    localStorage.setItem('token', token);
    setIsAuthorized(true);
    setToken(token);
    navigate('/');
  }

  const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const { token } = await authUser({
        username,
        password,
      }).unwrap();
      authorizeAndRedirect(token);
    } catch (error) {
      setIsError(true);
    }
  };

  const handleLoginByGoogle = async ({ credential }: CredentialResponse) => {
    try {
      const { token } = await authUserByGoogle({
        credential
      }).unwrap();
      authorizeAndRedirect(token);
    } catch (error) {
      setIsError(true);
    }
  }

  const disableSubmission = () =>
    !username || !password || Object.values(errors).some((error) => error);

  return (
    <Grid item xs={10} mt={8}>
      <form onSubmit={handleLoginSubmit}>
        <FormControl fullWidth>
          <Box display="flex" flexDirection="column" rowGap={2.5}>
            <TextField
              required
              fullWidth
              id="login"
              label={t('label.login')}
              defaultValue={username}
              error={errors.username}
              helperText={errors.username && t('authorization.username_error')}
              onChange={(event) => setUsername(event.target.value)}
              onBlur={(event) =>
                validate('username', event.target.value.length > 0)
              }
            />
            <TextField
              required
              fullWidth
              id="password"
              label={t('register.password_label')}
              type="password"
              defaultValue={password}
              error={errors.password}
              helperText={errors.password && t('authorization.password_error')}
              onChange={(event) => setPassword(event.target.value)}
              onBlur={(event) =>
                validate('password', event.target.value.length > 0)
              }
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={7}
            >
              <LoadingButton
                disabled={disableSubmission()}
                fullWidth
                loading={isLoading}
                type="submit"
                variant="contained"
                sx={{ width: 'fit-content' }}
                data-testid="authorize-login-button"
              >
                {t('button.sign_in')}
              </LoadingButton>
              <Trans i18nKey="label.forgot_password">
                <Link
                  color="secondary"
                  to="/password-reset"
                  underline="hover"
                >
                  Forgot
                </Link>
              </Trans>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <GoogleLogin
                onSuccess={handleLoginByGoogle}
                onError={() => setIsError(true)}
              />
            </Box>
          </Box>
        </FormControl>
      </form>
    </Grid>
  );
}
