import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import headers from 'components/RequestedHolidays/headers';
import { HolidayRequest } from 'store/holidays/interfaces';
import Request from 'components/RequestedHolidays/Request';
import { useTranslation } from 'react-i18next';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';

export interface RequestedHolidaysProps {
  requests: HolidayRequest[];
  isError: boolean;
  isFetching: boolean;
}

function RequestedHolidays({
  requests,
  isError,
  isFetching,
}: RequestedHolidaysProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="subtitle2">
        {t('holidays.requested_holidays')}
      </Typography>
      <TableContainer sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={1} />
            </TableRow>
          </TableHead>
          <TableBodyContent
            isError={isError}
            isFetching={isFetching}
            tableHeaders={headers}
            skeletonRowsLength={5}
          >
            {requests.map((request) => (
              <Request key={request.id} request={request} />
            ))}
          </TableBodyContent>
        </Table>
      </TableContainer>
    </>
  );
}

export default RequestedHolidays;
