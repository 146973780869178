import React from 'react';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import { FeedbackVisibility } from 'enums';

const feedbacksTypes = [
  {
    label: 'feedbacks.visible',
    icon: <PublicIcon />,
    value: FeedbackVisibility.visible,
  },
  {
    label: 'feedbacks.private',
    icon: <PublicOffIcon />,
    value: FeedbackVisibility.private,
  },
];

export default feedbacksTypes;
