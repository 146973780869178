/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface InvoiceFormSelectProps {
  selectId: string;
  label?: string;
  isRequired?: boolean;
  value: string;
  name?: string;
  onChangeFn: (event: SelectChangeEvent) => void;
  selectOptions: Array<{
    id: string | number;
    name: string;
  }>;
  disabled?: boolean;
  noLabel?: boolean;
  sx?: SelectProps['sx'];
}

function InvoiceFormSelect({
  selectId,
  label,
  isRequired = true,
  value,
  name,
  onChangeFn,
  selectOptions,
  disabled = false,
  noLabel = false,
  sx
}: InvoiceFormSelectProps) {
  const { t } = useTranslation();
  return (
    <FormControl required={!noLabel && isRequired} fullWidth>
      {!noLabel && <InputLabel id={selectId}>{label}</InputLabel>}
      <Select
        id={selectId}
        label={label}
        value={value}
        name={name || ''}
        onChange={onChangeFn}
        disabled={disabled}
        data-testid={`invoice-form-${selectId}`}
        fullWidth
        sx={sx}
      >
        {!isRequired && (
          <MenuItem value="">
            <em>{t('general.placeholder')}</em>
          </MenuItem>
        )}
        {selectOptions.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {t(item.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default InvoiceFormSelect;
