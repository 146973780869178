import React, { useState } from 'react';
import dateFormat from 'dateformat';
import { useGetHolidaysConditionQuery } from 'store/condition/condition';
import { HolidaysCondition as ConditionProps } from 'store/condition/interfaces';
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { StyledAccordion } from 'components/ResponsibilityGroup/Accordion.styled';
import { useTranslation } from 'react-i18next';
import getMonthsRange from 'helpers/getMonthsRange';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import HolidaysEmployeeData from './HolidaysEmployeeData/HolidaysEmployeeData';
import HolidaysBalance from './HolidaysBalance/HolidaysBalance';
import calculateCostsSum from './utils';

export default function HolidaysCondition() {
  const currentDate = new Date();
  const currentMonth = dateFormat(currentDate, 'yyyy-mm');
  const amountMonthsBefore = 2;
  const amountMonthsAfter = 1;
  const dateRange = getMonthsRange(
    currentDate,
    amountMonthsBefore,
    amountMonthsAfter,
  );
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const { data: holidaysCondition = {} as ConditionProps } =
    useGetHolidaysConditionQuery(currentMonth);
  const formatAmount = useCurrencyFormat();
  const employeesWithHolidays = [
    ...new Set(
      Object.values(holidaysCondition).flatMap((employee) =>
        Object.keys(employee),
      ),
    ),
  ].map((employee) => +employee);

  return (
    <Grid container p={2}>
      <Grid item xs={12}>
        <StyledAccordion disableGutters expanded={expanded} square>
          <AccordionSummary
            onClick={() => setExpanded((prevState) => !prevState)}
            sx={{ px: 1, py: 0 }}
          >
            <Grid container columns={13} alignItems="center">
              <Grid item xs={1}>
                <Typography textTransform="uppercase" variant="body2">
                  {t('menu.holidays_management')}
                </Typography>
              </Grid>
              {dateRange.map((month) => (
                <React.Fragment key={month}>
                  <Grid item xs={1.8} />
                  <Grid item xs={1.2}>
                    <Typography variant="smallBody">
                      {formatAmount(
                        calculateCostsSum(month, holidaysCondition),
                      )}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              px: 1,
              py: 1,
              bgcolor: 'secondary.light',
            }}
          >
            {employeesWithHolidays.map((employee) => (
              <HolidaysEmployeeData
                key={employee}
                dateRange={dateRange}
                employeeId={employee}
                holidaysCondition={holidaysCondition}
              />
            ))}
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid container columns={13} pt={2}>
        <Grid item xs={1} />
        {dateRange.map((month) => (
          <HolidaysBalance key={month} month={month} data={holidaysCondition} />
        ))}
      </Grid>
    </Grid>
  );
}
