import React, { useMemo, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import getOneMonthAgoDate from 'helpers/getOneMonthAgoDate/getOneMonthAgoDate';
import { HolidaysContext } from './HolidaysContext';

export interface HolidaysProviderProps {
  children: React.ReactNode;
}

export function HolidaysProvider({ children }: HolidaysProviderProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSettingSearchParams, setIsSettingSearchParams] = useState(true);

  const contextValue = useMemo(
    () => ({
      searchParams,
      setSearchParams,
      isSettingSearchParams,
    }),
    [searchParams, setSearchParams, isSettingSearchParams],
  );

  useEffect(() => {
    if (searchParams.toString() !== '') {
      setIsSettingSearchParams(false);
      return;
    }

    const setDefaultFilters = () => {
      setSearchParams((prevSearchParams) => {
        ['vacations', 'on-demand'].forEach((type) => {
          prevSearchParams.append('type[]', type);
        });
        prevSearchParams.set('dateTo[after]', getOneMonthAgoDate());

        return prevSearchParams;
      });
    };

    setDefaultFilters();
    setIsSettingSearchParams(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HolidaysContext.Provider value={contextValue}>
      {children}
    </HolidaysContext.Provider>
  );
}
