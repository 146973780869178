import api from 'store/api';
import { ClientContact, ContactFilters, ClientContactsResponseDto } from './interfaces';

export const clientContactsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<ClientContact[], Partial<ContactFilters>>({
      query: (filters) => ({
        url: '/client-contacts',
        params: filters,
      }),
      transformResponse: (response: ClientContactsResponseDto) => response['hydra:member'],
      providesTags: ['ClientContacts'],
    }),
    addContact: builder.mutation({
      query: (body) => ({
        url: '/client-contacts',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ClientContacts'],
    }),
    deleteContact: builder.mutation({
      query: (id: number) => ({
        url: `/client-contacts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ClientContacts'],
    }),
    updateContact: builder.mutation<
      ClientContact,
      Partial<ClientContact> & Pick<ClientContact, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/client-contacts/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['ClientContacts'],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useAddContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation,
} = clientContactsApi;
