import { BudgetPeriodStats } from './createBudgetPeriodStats';

export default function getChartData(budgetPeriodStats: BudgetPeriodStats[]) {
  const xAxisData = budgetPeriodStats.map((periodStat) => periodStat.period);
  const seriesData = budgetPeriodStats.map((periodStat) => ({
    usedAmount: periodStat.usedAmount,
    restAmount: Math.max(periodStat.availableAmount - periodStat.usedAmount, 0),
    amountOverLimit: Math.max(periodStat.usedAmount - periodStat.availableAmount, 0),
  }));

  let maxSeriesTotalStackValue = 0;

  seriesData.forEach((data) => {
    if (
      data.usedAmount + data.restAmount + data.amountOverLimit >
      maxSeriesTotalStackValue
    ) {
      maxSeriesTotalStackValue =
        data.usedAmount + data.restAmount + data.amountOverLimit;
    }
  });

  return {
    xAxisData,
    seriesData,
    maxSeriesTotalStackValue,
  };
}
