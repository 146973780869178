import React from 'react';
import { Stack, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BinIcon from 'assets/icons/BinIcon';
import ImageWithAuth from 'common/ImageWithAuth/ImageWithAuth';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { StorageResponse } from 'store/storage/storage';
import StyledImageWrapper from './ImageWrapper';

interface ImageFileProps {
  file: StorageResponse;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteFile: React.MouseEventHandler<HTMLButtonElement>;
}

export default function ImageFile({
  file,
  isOpen,
  setIsOpen,
  handleDeleteFile,
}: ImageFileProps) {
  const { t } = useTranslation();

  return (
    <>
      <StyledImageWrapper>
        <ImageWithAuth src={file.url} alt={t('label.file_photo')} />
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => setIsOpen(true)}
            size="small"
          >
            <BinIcon />
          </IconButton>
        </Stack>
      </StyledImageWrapper>
      <ConfirmationDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleAccept={handleDeleteFile}
      />
    </>
  );
}
