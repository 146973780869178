import React from 'react';
import { CardMedia, Stack, Typography } from '@mui/material';
import AddButton from 'common/AddButton/AddButton';
import InvoiceInFolderUrl from 'assets/img/invoice-in-folder.svg';
import { useTranslation } from 'react-i18next';

interface EmptyProjectsListProps {
  onClick: () => void;
}

export default function EmptyProjectsList({ onClick }: EmptyProjectsListProps) {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
      bgcolor="background.list"
      minHeight="calc(100vh - 22.5rem)"
      px={12}
    >
      <Stack spacing={2} alignItems="center" width="50%">
        <CardMedia
          sx={{
            width: '12.8rem',
            height: '12.8rem',
          }}
          component="img"
          loading="lazy"
          src={InvoiceInFolderUrl}
          alt="Bank"
        />
        <Typography textAlign="center" variant="h5">
          {t('invoices.no_projects_title')}
        </Typography>
        <Typography
          textAlign="center"
          variant="subtitle1"
          color="text.secondary"
        >
          {t('projects.no_projects_description')}
        </Typography>
      </Stack>
      <AddButton onClick={onClick} label="projects.add_project" />
    </Stack>
  );
}
