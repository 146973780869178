import React from 'react';
import { Grid, Typography } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { HolidaysCondition } from 'store/condition/interfaces';
import createApiIri from 'helpers/createApiIri';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';

interface HolidaysEmployeeDataProps {
  employeeId: number;
  holidaysCondition: HolidaysCondition;
  dateRange: string[];
}

export default function HolidaysEmployeeData({
  employeeId,
  holidaysCondition,
  dateRange,
}: HolidaysEmployeeDataProps) {
  const { selectedEmployee } = useGetSelectedEmployeePreview({
    employeeIri: createApiIri('employees', employeeId),
    filters: { isActive: true },
  });
  const formatAmount = useCurrencyFormat();
  const checkIfIsLastElement = (month: string) =>
    dateRange.indexOf(month) === dateRange.length - 1;

  const getEmployeeData = (month: string) => {
    const employeeHours = holidaysCondition[month]?.[employeeId]?.hours ?? 0;
    const employeeCosts = holidaysCondition[month]?.[employeeId]?.cost ?? 0;

    return `${formatAmount(employeeCosts)} (${employeeHours}h)`;
  };

  return (
    <Grid container columns={13}>
      <Grid item xs={2.8}>
        <Typography variant="h6">
          {selectedEmployee?.lastname} {selectedEmployee?.firstname}
        </Typography>
      </Grid>
      {dateRange.map((month) => (
        <React.Fragment key={month}>
          <Grid item xs={1.2}>
            <Typography variant="smallBody">
              {getEmployeeData(month)}
            </Typography>
          </Grid>
          {!checkIfIsLastElement(month) && <Grid item xs={1.8} />}
        </React.Fragment>
      ))}
    </Grid>
  );
}
