import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import headers from 'components/CountriesList/headers';
import { useTranslation } from 'react-i18next';
import { useGetCountriesQuery } from 'store/countries/countries';
import Error from 'common/Error';
import Loader from 'common/Loader';

function CountriesList() {
  const { data: countries = [], isError, isLoading } = useGetCountriesQuery();
  const { t } = useTranslation();

  const sortedCountries = [...countries].sort((country, comparedCountry) =>
    t(`countries.country_${country.id}`).localeCompare(
      t(`countries.country_${comparedCountry.id}`),
    ),
  );

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedCountries.map((country) => (
            <TableRow key={country.id}>
              <TableCell>
                <Typography variant="body2">
                  {t(`countries.country_${country.id}`)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{country.id}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CountriesList;
