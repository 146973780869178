import React from 'react';
import { useTranslation } from 'react-i18next';
import { isAfter, isBefore, startOfYear } from 'date-fns';
import { Stack, Typography } from '@mui/material';
import useBudgetPeriodContext from 'pages/BudgetPeriod/context/useBudgetPeriodContext';
import { yearBlockHeight } from 'components/BudgetPeriodSelector/consts';
import useBudgetPeriodSelectorContext from 'components/BudgetPeriodSelector/context/useBudgetPeriodSelectorContext';
import monthLabels from 'helpers/monthLabels';

interface MonthBlockProps {
  month: number;
  year: number;
}

export default function MonthBlock({ month, year }: MonthBlockProps) {
  const { t } = useTranslation();
  const { periodState, createMonthPeriodState, isEmptyState } =
    useBudgetPeriodContext();
  const { setIsCreatingPeriod, isCreatingPeriod } =
    useBudgetPeriodSelectorContext();

  const isMonthTextCovered = () => {
    if (!periodState?.dateTo || !periodState?.dateFrom) return false;
    const monthDate = new Date(year, month - 1);

    return (
      !isAfter(new Date(periodState.dateFrom), monthDate) &&
      !isBefore(new Date(periodState.dateTo), monthDate)
    );
  };

  const handleOnMouseDown = () => {
    const monthBlockDate = new Date(year, month - 1);
    if (!isEmptyState || isBefore(monthBlockDate, startOfYear(new Date())))
      return false;

    createMonthPeriodState(monthBlockDate);
    return setIsCreatingPeriod(true);
  };

  const monthLabel = monthLabels[parseInt(month.toString(), 10) - 1];

  return (
    <Stack
      px={6}
      key={month}
      height={yearBlockHeight / 12}
      alignItems="flex-start"
      justifyContent="center"
      data-year={year}
      data-month={month}
      onMouseDown={handleOnMouseDown}
      sx={{
        borderLeft: '0.1rem solid',
        borderTop: '0.1rem solid',
        borderColor: 'border.divider',
        cursor: isEmptyState ? 'pointer' : 'default',
        '&:hover': {
          backgroundColor:
            !isCreatingPeriod && isEmptyState ? 'primary.light' : 'transparent',
        },
      }}
    >
      <Typography
        variant="subtitle1"
        color={isMonthTextCovered() ? 'common.white' : 'text.primary'}
        fontWeight={500}
        zIndex={3}
        sx={{ userSelect: 'none', pointerEvents: 'none' }}
      >
        {t(monthLabel)}
      </Typography>
    </Stack>
  );
}
