import { useGetResourceRequestsQuery } from 'store/resourceRequests/resourceRequests';
import { useGetResourceRequestCandidatesQuery } from 'store/resourceRequestCandidates/resourceRequestCandidates';
import { ResourceRequest } from 'store/resourceRequests/interfaces';
import { ResourceRequestCandidate } from 'store/resourceRequestCandidates/interfaces';

export interface ResourceRequestForCandidate
  extends Omit<ResourceRequestCandidate, 'resourceRequest'> {
  resourceRequest: ResourceRequest;
}

export default function useGetResourceRequestsForCandidate(
  candidateId: string,
) {
  const {
    data: resourceRequests,
    isLoading: resourceRequestsLoading,
    isError: resourceRequestsError,
  } = useGetResourceRequestsQuery({});

  const {
    data: resourceRequestCandidates,
    isLoading: resourceRequestCandidatesLoading,
    isError: resourceRequestCandidatesError,
  } = useGetResourceRequestCandidatesQuery({
    candidate: candidateId,
  });

  const resourceRequestsForCandidate: ResourceRequestForCandidate[] =
    resourceRequestCandidates?.['hydra:member']?.map(
      (candidateResourceRequest) => ({
        ...candidateResourceRequest,
        resourceRequest: resourceRequests?.['hydra:member']?.find(
          (resourceRequest: ResourceRequest) =>
            resourceRequest['@id'] === candidateResourceRequest.resourceRequest,
        ) as ResourceRequest,
      }),
    ) || [];

  return {
    data: resourceRequestsForCandidate,
    isLoading: resourceRequestsLoading || resourceRequestCandidatesLoading,
    isError: resourceRequestsError || resourceRequestCandidatesError,
  };
}
