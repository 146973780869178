import { TagDefinition } from 'store/tagDefinitions/interfaces';

export default function getMatchPhrase(
  option: TagDefinition,
  searchPhrase: string,
) {
  const searchPhraseIndex = option.name
    .toLowerCase()
    .indexOf(searchPhrase.toLowerCase());
  const isSearchPhraseFound = searchPhraseIndex !== -1;

  if (!isSearchPhraseFound) {
    return {
      beforeMatch: '',
      match: '',
      afterMatch: '',
      isSearchPhraseFound: false,
    };
  }

  const beforeMatch = option.name.slice(0, searchPhraseIndex);
  const match = option.name.slice(
    searchPhraseIndex,
    searchPhraseIndex + searchPhrase.length,
  );
  const afterMatch = option.name.slice(searchPhraseIndex + searchPhrase.length);

  return {
    beforeMatch,
    match,
    afterMatch,
    isSearchPhraseFound,
  };
}
