/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { Box, Select, FormControl, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AmountMaskInput from 'common/MaskInputs/AmountMaskInput';
import { AmountInputsProps } from './interfaces';
import StyledAmountInput from './AmountInput.styled';
import agreementBillingTypes from '../agreementBillingTypes';
import agreementAmountTypes from '../agreementAmountTypes';

function AmountInputs<T extends FieldValues>({
  currencies,
  control,
  errors,
}: AmountInputsProps<T>) {
  const { t } = useTranslation();

  const sortedCurrencies = [...currencies].sort((currency, nextCurrency) =>
    currency.id.localeCompare(nextCurrency.id),
  );

  return (
    <Box display="flex">
      <Controller
        name={'amount' as Path<T>}
        control={control}
        render={({ field: { onBlur, value, onChange } }) => (
          <StyledAmountInput
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            required
            error={!!errors.amount}
            helperText={errors.amount && t('errors.field_required')}
            id="amount"
            label={t('inputs.amount')}
            InputProps={{
              inputComponent: AmountMaskInput as any,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              min: 0,
              step: 0.01,
            }}
          />
        )}
      />
      <Controller
        name={'currency' as Path<T>}
        control={control}
        render={({ field: { onBlur, value, onChange } }) => (
          <FormControl sx={{ flexBasis: '15%' }}>
            <Select
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              required
              sx={{ borderRadius: 0 }}
            >
              <MenuItem value="">
                <em>{t('general.placeholder')}</em>
              </MenuItem>
              {sortedCurrencies.map((currencyData) => (
                <MenuItem key={currencyData.id} value={currencyData['@id']}>
                  {currencyData.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name={'billingType' as Path<T>}
        control={control}
        render={({ field: { onBlur, value, onChange } }) => (
          <FormControl sx={{ flexBasis: '25%' }}>
            <Select
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              required
              sx={{ borderRadius: 0 }}
            >
              {agreementBillingTypes.map((billingType) => (
                <MenuItem key={billingType.value} value={billingType.value}>
                  {t(billingType.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name={'amountType' as Path<T>}
        control={control}
        render={({ field: { onBlur, value, onChange } }) => (
          <FormControl sx={{ flexBasis: '20%' }}>
            <Select
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              required
              sx={{
                borderRadius: 0,
                borderTopRightRadius: '1.2rem',
                borderBottomRightRadius: '1.2rem',
              }}
            >
              {agreementAmountTypes.map((amountType) => (
                <MenuItem key={amountType.value} value={amountType.value}>
                  {t(amountType.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
}

export default AmountInputs;
