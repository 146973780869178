import React from 'react';
import { Stack, Typography } from '@mui/material';
import { isAfter, isBefore, startOfYear } from 'date-fns';
import useBudgetPeriodContext from 'pages/BudgetPeriod/context/useBudgetPeriodContext';
import { BudgetPeriod } from 'store/budget/interfaces';
import {
  quarterMonthStart,
  quarters,
  yearBlockHeight,
  yearBlockWidth,
} from 'components/BudgetPeriodSelector/consts';
import useBudgetPeriodSelectorContext from 'components/BudgetPeriodSelector/context/useBudgetPeriodSelectorContext';

interface QuarterBlockProps {
  year: number;
  quarter: number;
}

export default function QuarterBlock({ year, quarter }: QuarterBlockProps) {
  const { periodState, createQuarterPeriodState, isEmptyState } =
    useBudgetPeriodContext();
  const { setIsCreatingPeriod, isCreatingPeriod } =
    useBudgetPeriodSelectorContext();

  const isQuarterTextCovered = () => {
    if (
      !periodState?.dateTo ||
      !periodState?.dateFrom ||
      !periodState?.period ||
      periodState?.period === BudgetPeriod.month
    )
      return false;

    const quarterIndex = quarter - 1;
    const month = quarterMonthStart[quarterIndex] + 1;
    const monthDate = new Date(year, month - 1);

    return (
      !isAfter(new Date(periodState.dateFrom), monthDate) &&
      !isBefore(new Date(periodState.dateTo), monthDate)
    );
  };

  const handleOnMouseDown = () => {
    const quarterIndex = quarter - 1;
    const month = quarterMonthStart[quarterIndex] + 1;
    const monthDate = new Date(year, month - 1);

    if (
      !isEmptyState ||
      isBefore(monthDate, startOfYear(new Date()))
    )
      return false;

    createQuarterPeriodState(monthDate);
    return setIsCreatingPeriod(true);
  };

  return (
    <Stack
      height={yearBlockHeight / quarters.length}
      alignItems="center"
      justifyContent="center"
      data-year={year}
      data-quarter={quarter}
      width={yearBlockWidth}
      onMouseDown={handleOnMouseDown}
      sx={{
        borderLeft: '0.1rem solid',
        borderTop: '0.1rem solid',
        borderColor: 'border.divider',
        cursor: isEmptyState ? 'pointer' : 'default',
        '&:hover': {
          backgroundColor:
            !isCreatingPeriod && isEmptyState ? 'primary.light' : 'transparent',
        },
      }}
    >
      <Typography
        variant="h6"
        color={isQuarterTextCovered() ? 'common.white' : 'text.primary'}
        fontWeight={500}
        zIndex={3}
        sx={{ userSelect: 'none', pointerEvents: 'none' }}
      >
        Q{quarter}
      </Typography>
    </Stack>
  );
}
