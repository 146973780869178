import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import { Employee } from 'store/employee/interfaces';
import { StatusProps } from 'hoc/Status/Status';
import { useUpdateEmployeeMutation } from 'store/employee/employees';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface SettingsProps extends StatusProps {
  employee: Employee;
}

function Permissions({
  employee,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: SettingsProps) {
  const [permissionsChanged, setPermissionsChanged] = useState(false);
  const { isSelected, handleChange, setSelected, selected } =
    useMultipleCheckboxSelect<string>([]);
  const [updateEmployee, { isLoading }] = useUpdateEmployeeMutation();
  const possibleRoles = useSelector(
    (state: RootState) => state.organization.organization.possibleRoles,
  );
  const { t } = useTranslation();

  useEffect(() => {
    setSelected(employee.roles);
  }, [employee, setSelected]);

  const handleEditEmployee = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateEmployee({
        id: employee.id,
        roles: selected,
      }).unwrap();
      setIsSuccess(true);
      setPermissionsChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box p={4} bgcolor="background.list" borderRadius="1.2rem">
      <form onSubmit={handleEditEmployee}>
        <FormGroup>
          {possibleRoles.map((role) => (
            <FormControlLabel
              key={role}
              control={
                <Checkbox
                  checked={isSelected(role)}
                  onChange={(event) => {
                    handleChange(event);
                    setPermissionsChanged(true);
                  }}
                  name={role}
                />
              }
              label={role}
              name={role}
              value={role}
            />
          ))}
        </FormGroup>
        {permissionsChanged && (
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              endIcon={<CheckIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save_changes')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
}

export default Permissions;
