import React, { useState } from 'react';
import { ContentCopy, MoreVert, VisibilityOutlined } from '@mui/icons-material';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import EditIcon from 'assets/icons/EditIcon';
import SendIcon from 'assets/icons/SendIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Invoice } from 'store/incomes/interfaces';

interface InvoiceMenuProps {
  income: Invoice;
}

export default function InvoiceMenu({ income }: InvoiceMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={anchorEl ? 'invoice-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        id="invoice-menu-button"
        onClick={handleOpenMenu}
      >
        <MoreVert color="primary" fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'invoice-menu-button',
          sx: { borderRadius: 4 },
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => navigate(`/invoices/${income.id}/email`)}
            sx={{ paddingY: 1 }}
          >
            <ListItemIcon>
              <SendIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText>{t('invoices.send_mail')}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => navigate('add', { state: { income } })}
            sx={{ paddingY: 1 }}
          >
            <ListItemIcon>
              <ContentCopy fontSize="large" />
            </ListItemIcon>
            <ListItemText>{t('label.create_similar')}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => navigate(`/invoices/${income.id}/edit`)}
            sx={{ paddingY: 1 }}
          >
            <ListItemIcon>
              <EditIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText>{t('label.edit')}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => navigate(`${income.id}/preview`)}
            sx={{ paddingY: 1 }}
          >
            <ListItemIcon>
              <VisibilityOutlined fontSize="large" />
            </ListItemIcon>
            <ListItemText>{t('label.open_preview')}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
