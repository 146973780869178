import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useGetBenefitsQuery } from 'store/benefits/benefits';
import { useParams } from 'react-router-dom';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { Benefit } from 'store/benefits/interfaces';
import BenefitPricingsList from 'components/BenefitPricingsList/BenefitPricingsList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddBenefitPrice from 'components/BenefitPriceForm/AddBenefitPrice';

const emptyBenefits: Benefit[] = [];

export default function BenefitPricing() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const { benefit, benefitFetchError, benefitFetching } = useGetBenefitsQuery(
    undefined,
    {
      selectFromResult: ({ data, isError, isLoading }) => ({
        benefit:
          data?.filter((item) => item.id === (id ? +id : 0)) ?? emptyBenefits,
        benefitFetchError: isError,
        benefitFetching: isLoading,
      }),
    },
  );

  if (benefitFetching) {
    return <Loader />;
  }

  if (benefitFetchError) {
    return <Error />;
  }

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <NavBarTitle>
          {t('benefits.pricing_header', { benefit: benefit[0]?.name })}
        </NavBarTitle>
        <Button
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => setIsAddDialogOpen(true)}
          variant="contained"
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <BenefitPricingsList pricings={benefit[0]?.benefitPrices ?? []} />
      <Dialog
        fullWidth
        onClose={() => setIsAddDialogOpen(false)}
        open={isAddDialogOpen}
        maxWidth="md"
      >
        <AddBenefitPrice setIsOpen={setIsAddDialogOpen} benefit={benefit[0]} />
      </Dialog>
    </Grid>
  );
}
