import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import checkDate from 'helpers/validators/checkDate';
import CustomLocalizationProvider from 'common/CustomLocalizationProvider/CustomLocalizationProvider';
import useBookPropertiesContext from '../context/useBookPropertiesContext';

export default function BookPropertiesDatePicker() {
  const { t } = useTranslation();
  const { selectedDate, setSelectedDate } = useBookPropertiesContext();

  return (
    <CustomLocalizationProvider>
      <DateTimePicker
        label={t('label.date_from')}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        ampm={false}
        sx={{ width: { xs: '100%', md: '30%' } }}
        value={selectedDate}
        onChange={(newDate) => {
          if (!!newDate && checkDate(newDate)) {
            setSelectedDate(newDate);
          }
        }}
      />
    </CustomLocalizationProvider>
  );
}
