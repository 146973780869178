import React from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { BankAccount, useGetAccountsQuery } from 'store/accounts/accounts';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TransactionsList from 'components/TransactionsList/TransactionsList';
import { useGetCostsQuery } from 'store/costs/costs';
import { useTranslation } from 'react-i18next';
import {
  TransactionsData,
  useGetTransactionsQuery,
} from 'store/transactions/transactions';
import getPagesCount from 'helpers/getPagesCount';

function Transactions() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageQuery = searchParams.get('page');
  const defaultPage = 1;

  const {
    data: accounts = [],
    isLoading: accountsFetching,
    isError: accountsFetchError,
  } = useGetAccountsQuery();
  const { isLoading: costsFetching, isError: costsFetchError } =
    useGetCostsQuery({ properties: ['id', 'name'] });
  const {
    data: transactions = {} as TransactionsData,
    isLoading: transactionsFetching,
    isError: transactionsFetchError,
    isSuccess: transactionsFetched,
  } = useGetTransactionsQuery(
    {
      bankAccount: id ? +id : undefined,
      page: pageQuery ? +pageQuery : defaultPage,
      ...(searchParams.get('execDate[before]') && {
        'execDate[before]': searchParams.get('execDate[before]') ?? '',
      }),
      ...(searchParams.get('execDate[after]') && {
        'execDate[after]': searchParams.get('execDate[after]') ?? '',
      }),
      ...(searchParams.get('amount[gte]') && {
        'amount[gte]': searchParams.get('amount[gte]') ?? '',
      }),
      ...(searchParams.get('amount[lte]') && {
        'amount[lte]': searchParams.get('amount[lte]') ?? '',
      }),
      ...(searchParams.get('cost') && {
        cost: searchParams.get('cost') ?? '',
      }),
    },
    {
      skip: !id,
    },
  );

  const getItemLabel = (account: BankAccount) =>
    `${account.bank.name} ${account.name} ${'**** '.repeat(
      3,
    )} ${account.number.slice(-4)}`;

  const handleOnPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...(value > defaultPage && { page: String(value) }),
    }));

    window.scroll({ top: 0, behavior: 'smooth' });
  };

  if (accountsFetching || costsFetching || transactionsFetching) {
    return <Loader />;
  }

  if (accountsFetchError || costsFetchError || transactionsFetchError) {
    return <Error />;
  }

  return (
    <Grid item xs={10} bgcolor="main.white">
      <NavBarContainer>
        <Box display="flex" alignItems="center" gap={2}>
          <NavBarTitle>{t('transactions.header')}</NavBarTitle>
          <FormControl>
            <InputLabel id="bank-select">
              {t('label.bank_transactions')}
            </InputLabel>
            <Select
              id="bank-select"
              label={t('label.bank_transactions')}
              name="bankSelect"
              value={id}
              onChange={(event) =>
                navigate(`/transactions/${event.target.value}`)
              }
            >
              {accounts.map((account) => (
                <MenuItem key={account.id} value={+account.id}>
                  {getItemLabel(account)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </NavBarContainer>
      {transactionsFetched && (
        <TransactionsList
          isAllTransactionsList
          padding={2}
          transactions={transactions['hydra:member']}
        />
      )}
      {transactions['hydra:view']['hydra:last'] && (
        <Pagination
          color="primary"
          count={getPagesCount(transactions['hydra:view']['hydra:last'])}
          page={pageQuery ? +pageQuery : defaultPage}
          onChange={handleOnPageChange}
          sx={{ display: 'flex', justifyContent: 'center', py: 2 }}
        />
      )}
    </Grid>
  );
}

export default Transactions;
