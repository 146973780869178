import api from 'store/api';
import {
  FeedbackData,
  FeedbackRequestData,
  FeedbackFilter,
  FeedbackRequestFilter
} from './interfaces';

export const feedbacksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbacks: builder.query<FeedbackData, Partial<FeedbackFilter>>({
      query: (filter) => ({
        url: '/feedback',
        params: filter
      }),
      providesTags: ['Feedbacks'],
    }),
    addFeedback: builder.mutation({
      query: (body) => ({
        url: '/feedback',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Feedbacks'],
    }),
    addFeedbackRequest: builder.mutation({
      query: (body) => ({
        url: '/feedback-requests',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Feedbacks'],
    }),
    getFeedbackRequests: builder.query<FeedbackRequestData, Partial<FeedbackRequestFilter>>({
      query: (filter) => ({
        url: '/feedback-requests',
        params: filter
      }),
      providesTags: ['Feedbacks'],
    }),
  }),
});

export const {
  useGetFeedbacksQuery,
  useAddFeedbackMutation,
  useAddFeedbackRequestMutation,
  useGetFeedbackRequestsQuery,
} = feedbacksApi;
