import { z } from 'zod';
import schema from './CandidateForm.schema';

export interface FormPayload extends z.infer<typeof schema> {}

export enum FormType {
  add = 'add',
  edit = 'edit',
}

export enum CandidateFormSourceType {
  just_join_it = 'JustJoinIt',
  rocket_jobs = 'RocketJobs',
  linked_in = 'LinkedIn',
  email_direct = 'EmailDirect'
}

export interface CandidateFormProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: FormType;
  onSubmit: (payload: FormPayload) => void;
  isSubmitting: boolean;
  initFormValues?: FormPayload;
  selectedResources?: Set<string>
  setSelectedResources?: React.Dispatch<React.SetStateAction<Set<string>>>;
}
export interface CandidateFormResourcesProps {
  selectedResources?: Set<string>
  setSelectedResources?: React.Dispatch<React.SetStateAction<Set<string>>>;
}

