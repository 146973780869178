import React, { useState } from 'react';
import {
  Box,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { useGetBenefitsQuery } from 'store/benefits/benefits';
import { useTranslation } from 'react-i18next';
import { Benefit } from 'store/benefits/interfaces';
import EditButton from 'common/EditButton/EditButton';
import EditBenefit from 'components/BenefitForm/EditBenefit';
import WarningIcon from '@mui/icons-material/Warning';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import TooltipIconButton from 'common/TooltipIconButton/TooltipIconButton';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useNavigate } from 'react-router-dom';
import useCurrencyFormat from 'hooks/useCurrencyFormat';

export default function BenefitsList() {
  const { t } = useTranslation();
  const {
    data: benefits = [],
    isLoading: benefitsFetching,
    isError: benefitsFetchError,
  } = useGetBenefitsQuery();
  const headers = ['label.name', 'benefits.price_coverage'];
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(0);
  const navigate = useNavigate();
  const formatAmount = useCurrencyFormat();

  const getPrices = (benefit: Benefit) => {
    if (!benefit.currentBenefitPrice) {
      return (
        <TitleTooltip title={t('benefits.missing_pricing')}>
          <WarningIcon color="error" fontSize="large" />
        </TitleTooltip>
      );
    }
    return (
      <Typography variant="body2">
        {`${formatAmount(benefit.currentBenefitPrice.price)} / ${formatAmount(
          benefit.currentBenefitPrice.defaultCompanyCoverageAmount,
        )}`}
      </Typography>
    );
  };

  if (benefitsFetching) {
    return <Loader />;
  }

  if (benefitsFetchError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer sx={{ px: 3, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            {benefits.map((benefit) => (
              <TableRow key={benefit.id}>
                <TableCell>
                  <Typography variant="body2">{benefit.name}</Typography>
                </TableCell>
                <TableCell>{getPrices(benefit)}</TableCell>
                <TableCell align="right">
                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <TooltipIconButton
                      onClick={() => navigate(`${benefit.id}/pricing`)}
                      translationKey="benefits.prices"
                      icon={<AttachMoneyIcon color="secondary" />}
                    />
                    <EditButton
                      onClick={() => {
                        setSelectedBenefit(benefit.id);
                        setIsEditFormOpen(true);
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        onClose={() => setIsEditFormOpen(false)}
        open={isEditFormOpen}
      >
        <EditBenefit
          benefitId={selectedBenefit}
          setIsOpen={setIsEditFormOpen}
        />
      </Dialog>
    </>
  );
}
