import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'label.name',
    align: 'left',
  },
  {
    label: 'label.id',
    align: 'left',
  },
  {
    label: 'label.status',
    align: 'left',
  },
];

export default tableHeaders;
