import { HolidayTypes } from 'enums';
import { useGetHolidayTypesQuery } from 'store/holidayTypes/holidayTypes';
import { HolidayTypeStatus } from 'store/holidayTypes/interfaces';

export default function useHolidayTypes() {
  const {
    data: holidayTypesData = {
      'hydra:member': [],
    },
    isLoading: holidayTypesLoading,
    isError: holidayTypesError,
    isSuccess: holidayTypesSuccess,
  } = useGetHolidayTypesQuery();

  const activeHolidayTypes = holidayTypesData['hydra:member']?.filter(
    (holidayType) => holidayType.status === HolidayTypeStatus.active,
  );
  const noRemoteTypes = holidayTypesData['hydra:member']
    ?.filter((holidayType) => holidayType.id !== HolidayTypes.remote)
    .map((holidayType) => holidayType.id);

  return {
    holidayTypes: holidayTypesData['hydra:member'],
    activeHolidayTypes,
    noRemoteTypes,
    holidayTypesLoading,
    holidayTypesError,
    holidayTypesSuccess,
  };
}
