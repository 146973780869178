import React from 'react';
import { Box } from '@mui/material';

function Error() {
  return(
    <Box display="flex" justifyContent="center">
      Error during fetching data...
    </Box>
  );
}

export default Error;
