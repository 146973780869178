import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import HolidaysApprovalsCount from 'components/HolidaysApprovalsCount';
import HolidaysDaysLimit from 'components/HolidaysDaysLimit';
import {
  useGetApprovalsCountQuery,
  useGetHolidaysDaysLimitQuery,
} from 'store/config/config';
import Loader from 'common/Loader';

function HolidaysSettings() {
  const { t } = useTranslation();
  const {
    isLoading: approvalsCountFetching,
    isSuccess: approvalsCountFetched,
  } = useGetApprovalsCountQuery();
  const {
    isLoading: holidaysDaysLimitFetching,
    isSuccess: holidaysDaysLimitFetched,
  } = useGetHolidaysDaysLimitQuery();

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('settings.holidays')}</NavBarTitle>
      </NavBarContainer>
      <Grid container justifyContent="center" alignItems="center" gap={3} p={2}>
        {(approvalsCountFetching || holidaysDaysLimitFetching) && <Loader />}
        {approvalsCountFetched && <HolidaysApprovalsCount />}
        {holidaysDaysLimitFetched && <HolidaysDaysLimit />}
      </Grid>
    </Grid>
  );
}

export default HolidaysSettings;
