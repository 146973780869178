import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import EmployeeFeedbacksList from 'components/EmployeeFeedbacksList/EmployeeFeedbacksList';

export default function EmployeeFeedbacks() {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={10} bgcolor="background.list">
      <NavBarContainer>
        <NavBarTitle>{t('feedbacks.my_feedbacks')}</NavBarTitle>
      </NavBarContainer>
      <EmployeeFeedbacksList />
    </Grid>
  );
}
