import React from 'react';
import { Box } from '@mui/material';

interface WidgetCaptionWrapperProps {
  children: React.ReactNode;
}

export default function WidgetCaptionContainer({
  children,
}: WidgetCaptionWrapperProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      pb={1}
    >
      {children}
    </Box>
  );
}
