import React from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  useGetEmployeeResponsibilitiesQuery,
  useGetGroupsQuery,
  useGetResponsibilitiesQuery,
} from 'store/responsibilityGroups/responsibilityGroups';
import { WidgetContainer, WidgetTitle } from 'common/Widget';
import { Responsibility } from 'store/responsibilityGroups/interfaces';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import { LoggedEmployee } from 'store/employee/interfaces';
import Result from './Result/Result';

export default function ResponsibilitiesWidget() {
  const { t } = useTranslation();
  const { data: currentUser = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery();
  const {
    data: employeeResponsibilities = [],
    isLoading: employeeResponsibilitiesFetching,
    isError: employeeResponsibilitiesFetchError,
  } = useGetEmployeeResponsibilitiesQuery(
    { employee: [String(currentUser.id)] },
    { skip: !currentUser.id },
  );
  const {
    data: responsibilities = [],
    isLoading: responsibilitiesLoading,
    isError: responsibilitiesError,
  } = useGetResponsibilitiesQuery({});
  const {
    data: groups = [],
    isLoading: groupsLoading,
    isError: groupsError,
  } = useGetGroupsQuery();

  const getGroupName = (groupIri: string) => {
    const displayedGroup = groups.find((group) => group['@id'] === groupIri);

    if (!displayedGroup) {
      return '';
    }

    return displayedGroup.name;
  };

  const userResponsibilities = employeeResponsibilities.map((item) => ({
    ...item,
    responsibility:
      responsibilities.find((data) => data['@id'] === item.responsibility) ??
      ({} as Responsibility),
  }));

  const employeesGroups = [
    ...new Set(
      userResponsibilities.map(
        (responsibility) => responsibility.responsibility?.responsibilityGroup,
      ),
    ),
  ];

  return (
    <WidgetContainer
      title={<WidgetTitle>{t('menu.responsibilites')}</WidgetTitle>}
      isError={
        employeeResponsibilitiesFetchError ||
        groupsError ||
        responsibilitiesError
      }
      isLoading={
        employeeResponsibilitiesFetching ||
        groupsLoading ||
        responsibilitiesLoading
      }
    >
      {employeesGroups.map((group) => (
        <List key={uuid()}>
          <ListItem disableGutters>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="bold">{getGroupName(group)}</Typography>
              }
            />
          </ListItem>
          <Collapse in>
            <List component="div">
              {userResponsibilities.map(({ responsibility }) => (
                <Result
                  key={uuid()}
                  displayedResponsibility={responsibility}
                  groupIri={group}
                />
              ))}
            </List>
          </Collapse>
        </List>
      ))}
    </WidgetContainer>
  );
}
