import React, { useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { StyledAccordion } from 'components/ResponsibilityGroup/Accordion.styled';
import { useTranslation } from 'react-i18next';
import { useGetHolidaysQuery } from 'store/holidays/holidays';
import Loader from 'common/Loader';
import Error from 'common/Error';
import dateFormat from 'dateformat';
import useHolidayTypes from 'hooks/useHolidayTypes';
import Details, { EmployeeWithBenefits } from './Details/Details';

interface EmployeeInfoProps {
  employee: EmployeeWithBenefits;
  employeesIds: number[];
}

export default function EmployeeInfo({
  employee,
  employeesIds,
}: EmployeeInfoProps) {
  const currentDate = new Date();
  const fiveDaysBefore = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  const fiveDaysAfter = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );

  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    holidayTypes,
    noRemoteTypes,
    holidayTypesError,
    holidayTypesLoading,
  } = useHolidayTypes();

  const {
    data: holidays = {
      'hydra:member': [],
    },
    isLoading: holidaysFetching,
    isError: holidaysFetchError,
  } = useGetHolidaysQuery(
    {
      type: noRemoteTypes,
      employee: employeesIds.map((id) => id.toString()),
      'dateFrom[after]': dateFormat(fiveDaysBefore.setDate(-5), 'yyyy-mm-dd'),
      'dateTo[before]': dateFormat(
        fiveDaysAfter.setDate(fiveDaysAfter.getDate() + 5),
        'yyyy-mm-dd',
      ),
    },
    {
      skip: !holidayTypes.length,
    },
  );

  const employeeHolidays = holidays['hydra:member'].filter(
    (holiday) => holiday.employee === employee['@id'],
  );

  if (holidaysFetching || holidayTypesLoading) {
    return <Loader />;
  }

  if (holidaysFetchError || holidayTypesError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <StyledAccordion
        expanded={isExpanded}
        onChange={() => setIsExpanded((prevState) => !prevState)}
        square
        TransitionProps={{
          unmountOnExit: true,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Typography variant="bold">
                {employee.lastname} {employee.firstname}
              </Typography>
            </Grid>
            <Grid item xs={8} display="flex" alignItems="center" gap={2}>
              <Chip
                color="primary"
                label={
                  <Typography variant="body2">
                    {employee.activeAgreement
                      ? employee.activeAgreement.variant
                      : t('employees.header_agreement')}
                  </Typography>
                }
              />
              <Chip
                color="error"
                disabled={!employeeHolidays.length}
                label={
                  <Typography variant="body2">
                    {t('menu.holidays_management')}
                  </Typography>
                }
              />
              <Chip
                color="success"
                disabled={!employee.benefits}
                label={
                  <Typography variant="body2">{t('menu.benefits')}</Typography>
                }
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Details employee={employee} holidays={employeeHolidays} />
        </AccordionDetails>
      </StyledAccordion>
    </Grid>
  );
}
