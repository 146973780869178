import React, { useState } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'hooks/useValidate';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import {
  Box,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import groupTypes from 'components/AddContractorGroupForm/groupTypes';
import {
  CostGroupProps,
  useGetCostGroupsQuery,
  useUpdateCostGroupMutation,
} from 'store/costGroups/costGroups';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { CustomError } from 'store/api';

export interface EditContactorGroupFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  costGroupId: number;
}

interface Errors {
  name: boolean;
}

const emptyCostGroups: CostGroupProps[] = [];

function EditContactorGroupForm({
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
  costGroupId,
}: EditContactorGroupFormProps) {
  const { t } = useTranslation();
  const { validate, errors } = useValidate<Errors>();
  const { costGroup } = useGetCostGroupsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      costGroup:
        data?.filter((item) => item.id === costGroupId) ?? emptyCostGroups,
    }),
  });
  const [name, setName] = useState(costGroup[0]?.name ?? '');
  const [type, setType] = useState(costGroup[0]?.type ?? '');
  const [updateCostGroup, { isLoading }] = useUpdateCostGroupMutation();

  const disableSubmission = () =>
    !name || !type || Object.values(errors).some((error) => error);

  const handleUpdateCostGroup = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateCostGroup({
      id: costGroupId,
      name,
      type,
    })
      .unwrap()
      .then(() => {
        setIsSuccess(true);
        setIsOpen(false);
      })
      .catch((error) => {
        if ('data' in error) {
          setErrorMessage((error as CustomError).data['hydra:description']);
        } else {
          setErrorMessage(error.error);
        }
        setIsError(true);
      });
  };

  return (
    <>
      <DialogHeader title={t('contractor_groups.edit')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleUpdateCostGroup}>
          <Box display="flex" flexDirection="column" gap={3} pt={3}>
            <TextField
              error={errors.name}
              fullWidth
              helperText={errors.name && t('errors.field_required')}
              label={t('label.name')}
              name="groupName"
              onBlur={(event) =>
                validate('name', event.target.value.length >= 2)
              }
              onChange={(event) => setName(event.target.value)}
              required
              value={name}
            />
            <FormControl fullWidth required>
              <InputLabel id="type-select">{t('label.type')}</InputLabel>
              <Select
                id="type-select"
                label={t('label.type')}
                onChange={(event) => setType(event.target.value)}
                value={type}
              >
                {groupTypes.map((groupType) => (
                  <MenuItem key={groupType.value} value={groupType.value}>
                    {t(groupType.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                disabled={disableSubmission()}
                endIcon={<CheckIcon />}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default EditContactorGroupForm;
