import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAddLocationMutation } from 'store/locations/locations';
import LocationForm from 'components/LocationForm/LocationForm';
import { FormPayload } from 'components/LocationForm/interfaces';
import { DialogContent } from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { CustomError } from 'store/api';
import { AddLocationProps } from './interfaces';

function AddLocation({
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddLocationProps) {
  const [addLocation, { isLoading }] = useAddLocationMutation();
  const { t } = useTranslation();

  const handleOnSubmit = async (payload: FormPayload) => {
    try {
      const { officeCloseHour, officeOpenHour, ...restPayload } = payload;
      await addLocation({
        ...restPayload,
        officeOpenHour: officeOpenHour ? officeOpenHour.getHours() : null,
        officeCloseHour: officeCloseHour ? officeCloseHour.getHours() : null,
      }).unwrap();

      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('locations.add_new')} setIsOpen={setIsOpen} />
      <DialogContent>
        <LocationForm onSubmit={handleOnSubmit} isSubmitting={isLoading} />
      </DialogContent>
    </>
  );
}

export default AddLocation;
