import React from 'react';
import { CardMedia, Stack, Typography } from '@mui/material';
import AddButton from 'common/AddButton/AddButton';
import InvoiceWithCalculator from 'assets/img/invoice-with-calculator.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function EmptyBudgetSummary() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return (
    <Stack spacing={5} alignItems="center" pt={20}>
      <Stack spacing={2} alignItems="center" width="50%">
        <CardMedia
          sx={{
            width: '25.6rem',
            height: '25.6rem',
          }}
          component="img"
          loading="lazy"
          src={InvoiceWithCalculator}
          alt="Budget"
        />
        <Typography textAlign="center" variant="h5">
          {t('budget.no_configured_budget')}
        </Typography>
        <Typography
          textAlign="center"
          variant="subtitle1"
          color="text.secondary"
        >
          {t('budget.no_configured_budget_description')}
        </Typography>
      </Stack>
      <AddButton
        onClick={() => navigate(`/budget/${id}/period`)}
        label="budget.plan_budget"
      />
    </Stack>
  );
}
