import React, { useMemo, useState } from 'react';
import { Grid, Tab } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ProjectPhasesList from 'components/EditProjectForm/ProjectPhasesList/ProjectPhasesList';
import { useGetProjectQuery } from 'store/projects/projects';
import getBgColorForTab from 'helpers/getBgColorForTab';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import { Project } from 'store/projects/interfaces';
import ProjectHeader from './ProjectHeader/ProjectHeader';
import ProjectEmployees from './ProjectEmployees/ProjectEmployees';
import Overview from './Overview/Overview';
import { EditProjectFormContext } from './context/EditProjectForm.context';
import TabValue from './interfaces';

const listingTabs = [TabValue.phases, TabValue.employees];

export default function EditProjectForm() {
  const { id } = useParams<{ id: string }>();

  const {
    data: project,
    isError: projectError,
    isLoading: projectLoading,
  } = useGetProjectQuery(id ?? '', { skip: !id });

  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(TabValue.overview);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  const contextValue = useMemo(() => ({ project }), [project]);

  return (
    <Grid item xs={10} bgcolor={getBgColorForTab(tabValue, listingTabs)}>
      <EditProjectFormContext.Provider
        value={contextValue as { project: Project }}
      >
        <ContentSuspense isError={projectError} isLoading={projectLoading}>
          <ProjectHeader />
          <TabContext value={tabValue}>
            <StyledTabList
              onChange={handleTabChange}
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab label={t('tab.overview')} value={TabValue.overview} />
              <Tab label={t('menu.employees')} value={TabValue.employees} />
              <Tab label={t('projects.phases')} value={TabValue.phases} />
            </StyledTabList>
            <TabPanel value={TabValue.overview}>
              <Overview />
            </TabPanel>
            <TabPanel value={TabValue.employees}>
              <ProjectEmployees />
            </TabPanel>
            <TabPanel value={TabValue.phases}>
              <ProjectPhasesList />
            </TabPanel>
          </TabContext>
        </ContentSuspense>
      </EditProjectFormContext.Provider>
    </Grid>
  );
}
