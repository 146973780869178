import React from 'react';
import { createSvgIcon } from '@mui/material';

const ArrowRightCircle = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM11.3955 7.94967C11.7915 7.56469 12.4246 7.5736 12.8096 7.96959L16.0503 11.3029C16.4277 11.691 16.4277 12.309 16.0503 12.6971L12.8096 16.0304C12.4246 16.4264 11.7915 16.4353 11.3955 16.0503C10.9995 15.6653 10.9906 15.0322 11.3756 14.6363L12.9664 13H8.66667C8.11438 13 7.66667 12.5523 7.66667 12C7.66667 11.4477 8.11438 11 8.66667 11H12.9664L11.3756 9.36374C10.9906 8.96776 10.9995 8.33466 11.3955 7.94967Z"
      fill="currentColor"
    />
  </svg>,

  'ArrowRightCircle',
);

export default ArrowRightCircle;
