import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Tab, Grid, Dialog } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import BudgetList from 'components/BudgetList/BudgetList';
import BudgetForm from 'components/BudgetForm';
import BudgetCategoryForm from 'components/BudgetCategoryForm';
import { FormPayload } from 'components/BudgetForm/interfaces';
import { FormPayloadAddCategory } from 'components/BudgetCategoryForm/interfaces';
import { BudgetType, FormTypes } from 'enums';
import { useAddBudgetMutation } from 'store/budget/budget';
import { useAddBudgetGroupMutation } from 'store/budgetGroups/budgetGroups';
import { CustomError } from 'store/api';
import { useNavigate } from 'react-router-dom';
import { BudgetPostMutationRequest } from 'store/budget/interfaces';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { FormBudgetValues } from 'pages/BudgetPreview/interfaces';
import {
  BudgetsTabValue,
  BudgetProps,
  BudgetMenuOptions,
  BudgetMenu,
  BudgetMenuAction,
} from './interfaces';

export default function Budget({ setIsSuccess, setIsErrorCatch }: BudgetProps) {
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(BudgetsTabValue.list);
  const [isOpenAddBudget, setIsOpenAddBudget] = useState(false);
  const [isOpenAddBudgetCategory, setIsOpenAddBudgetCategory] = useState(false);
  const [budgetGroupIri, setBudgetGroupIri] = useState('');
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const [addBudget, { isLoading: isAddBudgetLoading }] = useAddBudgetMutation();
  const [addBudgetGroups, { isLoading: isAddBudgetGroupsLoading }] =
    useAddBudgetGroupMutation();

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: BudgetsTabValue,
  ) => setTabValue(newValue);

  const handleMenuItemClick = (action: string) => {
    switch (action) {
      case BudgetMenuAction.addBudget:
        setIsOpenAddBudget(true);
        setOpenMenu(false);
        return;
      case BudgetMenuAction.addBudgetCategory:
        setIsOpenAddBudgetCategory(true);
        setOpenMenu(false);
        return;
      default:
        setOpenMenu(false);
    }
  };

  const handleOpenBudget = (budgetId: string, isOpen: boolean) => {
    setBudgetGroupIri(budgetId);
    setIsOpenAddBudget(isOpen);
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenMenu(false);
  };

  const handleAddBudgetCategory = async (
    formPayload: FormPayloadAddCategory,
  ) => {
    try {
      const { name } = formPayload;
      await addBudgetGroups({ name }).unwrap();
      setIsSuccess(true);
      setIsOpenAddBudgetCategory(false);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  const handleAddBudget = async (formPayload: FormPayload) => {
    try {
      const { budgetGroup, ...restOfPayload } = formPayload;
      const payload = {
        budgetGroup: budgetGroup || null,
        ...restOfPayload,
      };
      const addedBudget = await addBudget(
        payload as BudgetPostMutationRequest,
      ).unwrap();
      setIsSuccess(true);
      setIsOpenAddBudget(false);
      navigate(`/budget/${addedBudget.id}`);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  const initFormBudgetValues = {
    name: '',
    type: BudgetType.global,
    budgetGroup: budgetGroupIri || '',
  } as FormBudgetValues;

  return (
    <Grid item xs={12} md={10} bgcolor="background.list">
      <NavBarContainer>
        <Stack>
          <NavBarTitle>{t('budget.header')}</NavBarTitle>
        </Stack>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="Button group with a nested menu"
        >
          <Button onClick={handleToggle}>{t('budget.add')}</Button>
          <Button
            size="small"
            aria-controls={openMenu ? 'split-button-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={openMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {BudgetMenuOptions.map((option: BudgetMenu) => (
                      <MenuItem
                        key={option.name}
                        onClick={() => handleMenuItemClick(option.action)}
                      >
                        {t(option.name)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('budget.budget_list')} value={BudgetsTabValue.list} />
        </StyledTabList>
        <TabPanel value={BudgetsTabValue.list}>
          <BudgetList
            setIsOpen={setIsOpenAddBudgetCategory}
            setIsOpenBudget={handleOpenBudget}
          />
        </TabPanel>
      </TabContext>
      <Dialog
        open={isOpenAddBudget}
        onClose={() => setIsOpenAddBudget(!isOpenAddBudget)}
        fullWidth
        maxWidth="md"
      >
        <BudgetForm
          setIsOpen={setIsOpenAddBudget}
          formType={FormTypes.add}
          onSubmit={handleAddBudget}
          isSubmitting={isAddBudgetLoading}
          budgetGroupIri={budgetGroupIri}
          initFormValues={initFormBudgetValues}
        />
      </Dialog>
      <Dialog
        open={isOpenAddBudgetCategory}
        onClose={() => setIsOpenAddBudgetCategory(!isOpenAddBudget)}
        fullWidth
      >
        <BudgetCategoryForm
          setIsOpen={setIsOpenAddBudgetCategory}
          onSubmit={handleAddBudgetCategory}
          isSubmitting={isAddBudgetGroupsLoading}
        />
      </Dialog>
    </Grid>
  );
}
