import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAddTransactionsMutation } from 'store/transactions/transactions';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { useTranslation } from 'react-i18next';
import StyledInput from 'components/UploadTransactions/input.styled';
import { LoadingButton } from '@mui/lab';

export interface UploadTransactionsProps extends StatusProps {}

function UploadTransactions({
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: UploadTransactionsProps) {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileList | null>(null);
  const [addFiles, { isLoading }] = useAddTransactionsMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    setFiles(event.target.files);
  };

  const handleAddTransactions = async () => {
    if (!files) {
      return;
    }
    const filesData = new FormData();
    [...files].forEach((file) => filesData.append('file[]', file, file.name));
    try {
      await addFiles(filesData).unwrap();
      setIsSuccess(true);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <StyledInput
        accept=".sta,.txt"
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        type="file"
      />
      <LoadingButton
        disabled={!files}
        endIcon={<AddCircleOutlineIcon />}
        loading={isLoading}
        onClick={handleAddTransactions}
        variant="contained"
      >
        <span>{t('button.upload')}</span>
      </LoadingButton>
    </Box>
  );
}

export default UploadTransactions;
