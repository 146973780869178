import React, { useEffect, useState } from 'react';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { StatusProps } from 'hoc/Status/Status';
import { Box, DialogContent, InputLabel, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import Loader from 'common/Loader';
import Error from 'common/Error';
import BaseData from 'components/AddAttachmentForm/BaseData/BaseData';
import Financial from 'components/AddAttachmentForm/Financial/Financial';
import {
  useAddTransactionAttachmentMutation,
  useUpdateTransactionAttachmentMutation,
} from 'store/transactions/transactions';
import { useUploadFileMutation } from 'store/storage/storage';
import { CustomError } from 'store/api';
import { useValidate } from 'hooks/useValidate';
import { LoadingButton } from '@mui/lab';

export interface AddAttachmentFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Errors {
  receivedAt: boolean;
  relatedMonth: boolean;
}

function AddAttachmentForm({
  setIsOpen,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: AddAttachmentFormProps) {
  const { t } = useTranslation();
  const {
    data: currencies = [],
    isLoading: currenciesFetching,
    isError: currenciesFetchError,
    isSuccess: currenciesFetched,
  } = useGetCurrenciesQuery();
  const [name, setName] = useState('');
  const [receivedAt, setReceivedAt] = useState('');
  const [amount, setAmount] = useState('');
  const [relatedMonth, setRelatedMonth] = useState('');
  const [cost, setCost] = useState('');
  const [currency, setCurrency] = useState('');
  const [disableCurrencySelect, setDisableCurrencySelect] = useState(false);
  const [file, setFile] = useState<File | null>();
  const [addAttachment, { isLoading: attachmentAdding }] =
    useAddTransactionAttachmentMutation();
  const [uploadFile, { isLoading: fileUploading }] = useUploadFileMutation();
  const [updateAttachment, { isLoading: attachmentUpdating }] =
    useUpdateTransactionAttachmentMutation();
  const { errors, validate } = useValidate<Errors>();

  useEffect(() => {
    if (!currenciesFetched) {
      return;
    }
    if (currencies.length > 1 || !currencies.length) {
      return;
    }
    setCurrency(currencies[0].id);
    setDisableCurrencySelect(true);
  }, [currencies, currenciesFetched]);

  const disableSubmission = () =>
    !name || !receivedAt || !relatedMonth || !cost || !currency || !file;

  const handleAddAttachment = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (!file) {
      return;
    }
    try {
      const addedAttachment = await addAttachment({
        cost,
        currency,
        description: file.name,
        name,
        receivedAt,
        relatedMonth,
        amount: Math.round(+amount * 100),
      }).unwrap();
      const fileData = new FormData();
      fileData.append('file', file, file.name);
      fileData.append('relationName', 'transaction-attachments');
      fileData.append('relationId', addedAttachment.id);
      const uploadedFile = await uploadFile(fileData).unwrap();
      await updateAttachment({
        id: addedAttachment.id,
        file: uploadedFile,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        title={t('transactions.add_attachment')}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        {currenciesFetching && <Loader />}
        {currenciesFetchError && <Error />}
        {currenciesFetched && (
          <form onSubmit={handleAddAttachment}>
            <Box display="flex" flexDirection="column" gap={3} py={3}>
              <BaseData
                name={name}
                setName={setName}
                cost={cost}
                setCost={setCost}
              />
              <Financial
                disabled={disableCurrencySelect}
                amount={amount}
                setAmount={setAmount}
                currency={currency}
                setCurrency={setCurrency}
                currencies={currencies}
                setFile={setFile}
                isEdit={false}
              />
              <Box display="flex" alignItems="center" gap={2}>
                <Box width="100%">
                  <InputLabel
                    error={errors.receivedAt}
                    id="received-at"
                    required
                  >
                    {t('inputs.received_at')}
                  </InputLabel>
                  <TextField
                    error={errors.receivedAt}
                    id="received-at"
                    fullWidth
                    helperText={errors.receivedAt && t('errors.field_required')}
                    name="receivedAt"
                    onBlur={(event) =>
                      validate('receivedAt', event.target.value !== '')
                    }
                    onChange={(event) => setReceivedAt(event.target.value)}
                    required
                    type="date"
                    value={receivedAt}
                    data-testid="attachment-form-received-at"
                  />
                </Box>
                <Box width="100%">
                  <InputLabel
                    error={errors.relatedMonth}
                    id="related-month"
                    required
                  >
                    {t('transactions.related_month')}
                  </InputLabel>
                  <TextField
                    error={errors.relatedMonth}
                    id="related-month"
                    fullWidth
                    helperText={
                      errors.relatedMonth && t('errors.field_required')
                    }
                    name="relatedMonth"
                    onBlur={(event) =>
                      validate('relatedMonth', event.target.value !== '')
                    }
                    onChange={(event) => setRelatedMonth(event.target.value)}
                    required
                    type="date"
                    value={relatedMonth}
                    data-testid="attachment-form-related-month"
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  disabled={disableSubmission()}
                  endIcon={<AddCircleOutlineIcon />}
                  loading={
                    attachmentAdding || attachmentUpdating || fileUploading
                  }
                  type="submit"
                  variant="contained"
                >
                  <span>{t('button.save')}</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </>
  );
}

export default AddAttachmentForm;
