/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, InputLabel, TextField } from '@mui/material';
import AttachmentSelect from 'components/AddAttachmentForm/AttachmentSelect/AttachmentSelect';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'hooks/useValidate';
import { Currency } from 'store/currencies/currencies';

export interface FinancialProps {
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  currency: string;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  currencies: Currency[];
  setFile?: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  isEdit: boolean;
  disabled?: boolean;
}

interface Errors {
  amount: boolean;
}

function Financial({
  amount,
  setAmount,
  currency,
  setCurrency,
  currencies,
  setFile,
  isEdit,
  disabled,
}: FinancialProps) {
  const { t } = useTranslation();
  const { errors, validate } = useValidate<Errors>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!setFile) {
      return;
    }
    if (!event.target.files) {
      return;
    }
    setFile(event.target.files[0]);
  };

  return (
    <Box display="flex" alignItems="flex-end" gap={2}>
      <TextField
        data-testid="amount"
        error={errors.amount}
        helperText={errors.amount && t('errors.field_required')}
        label={t('inputs.amount')}
        inputProps={{
          min: 0,
          step: 0.01,
        }}
        name="amount"
        onBlur={(event) =>
          validate(
            'amount',
            event.target.value !== '' && +event.target.value >= 0,
          )
        }
        onChange={(event) => setAmount(event.target.value)}
        required
        type="number"
        sx={{ width: '70%' }}
        value={amount}
      />
      <AttachmentSelect
        disabled={disabled}
        label="clients.currency"
        selectId="currency"
        value={currency}
        onChangeFn={(event) => setCurrency(event.target.value)}
        selectOptions={currencies.map((currencyData) => ({
          id: currencyData['@id'],
          description: currencyData.id,
        }))}
        width="30%"
      />
      {!isEdit && (
        <Box width="100%">
          <InputLabel id="attachment" required>
            {t('inputs.attachment')}
          </InputLabel>
          <TextField
            fullWidth
            name="attachment"
            inputProps={{
              accept:
                '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.odt,.ott,.odm,image/*',
            }}
            onChange={handleFileChange}
            required
            type="file"
          />
        </Box>
      )}
    </Box>
  );
}

export default Financial;
