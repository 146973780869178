import { z } from 'zod';
import { ProjectPhaseTypes } from 'store/projectPhases/interfaces';

const schema = z.object({
  name: z.string().min(1, { message: 'errors.field_required' }),
  type: z.nativeEnum(ProjectPhaseTypes, {
    errorMap: () => ({ message: 'errors.field_required' })
  }),
  amount: z.string().min(1, { message: 'errors.field_required' }),
  dateFrom: z.string().min(1, { message: 'errors.field_required' }),
  dateToPrediction: z.string().optional(),
  dateTo: z.string().optional(),
  budget: z.string().optional(),
});

export default schema;
