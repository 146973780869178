import React from 'react';
import { StatusProps } from 'hoc/Status/Status';
import { useAddProjectPhaseMutation } from 'store/projectPhases/projectPhases';
import { CustomError } from 'store/api';
import { FormTypes } from 'enums';
import { FormPayload } from 'components/ProjectPhaseForm/interfaces';
import { ReactUseState } from 'interfaces';
import {
  useAddBudgetProjectsMutation,
  useGetBudgetsQuery,
} from 'store/budget/budget';
import useCheckBudgetIsEnabled from 'hooks/useCheckBudgetIsEnabled';
import { BudgetResponse } from 'store/budget/interfaces';
import ProjectPhaseForm from '../ProjectPhaseForm';

export interface AddProjectPhaseProps extends StatusProps {
  setIsOpen: ReactUseState<boolean>;
  projectIri: string;
}

export default function AddProjectPhase({
  projectIri,
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddProjectPhaseProps) {
  const isBudgetEnabled = useCheckBudgetIsEnabled();

  const { data: budgets = {} as BudgetResponse } = useGetBudgetsQuery(
    {},
    { skip: !isBudgetEnabled },
  );

  const [addProjectPhase, { isLoading: addProjectPhaseLoading }] =
    useAddProjectPhaseMutation();
  const [addBudgetProjects, { isLoading: isAddBudgetProjectLoading }] =
    useAddBudgetProjectsMutation();

  const budgetsData = budgets?.['hydra:member'] || [];

  const handleAddProjectPhase = async (formPayload: FormPayload) => {
    try {
      const {
        dateToPrediction,
        dateFrom,
        dateTo,
        amount,
        budget,
        ...restOfPayload
      } = formPayload;

      const payload = {
        project: projectIri,
        ...restOfPayload,
        amount: +amount,
        dateFrom: new Date(dateFrom),
        ...(dateToPrediction && {
          dateToPrediction: new Date(dateToPrediction),
        }),
        ...(dateTo && { dateTo: new Date(dateTo) }),
      };
      const addedProject = await addProjectPhase(payload).unwrap();

      if (budget && isBudgetEnabled) {
        const addedBudgetProject = {
          budget,
          projectPhase: addedProject['@id'],
          project: projectIri,
        };
        await addBudgetProjects(addedBudgetProject).unwrap();
      }

      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <ProjectPhaseForm
      formType={FormTypes.add}
      setIsOpen={setIsOpen}
      onSubmit={handleAddProjectPhase}
      isSubmitting={addProjectPhaseLoading || isAddBudgetProjectLoading}
      budgets={budgetsData}
      isBudgetEnabled={isBudgetEnabled}
    />
  );
}
