import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { EmployeeContext } from 'pages/PropertyBooking/PropertyBooking';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import useBookingSchedulerContext from 'components/BookProperties/BookingForm/context/useBookingScheduler.context';

interface ContainerComponentPropsChildren {
  params: {
    data: AppointmentModel;
  };
}

interface AppointmentContainerComponentProps
  extends Appointments.ContainerProps {
  children: React.ReactElement<ContainerComponentPropsChildren>[];
}

export default function AppointmentContainerComponent(
  props: AppointmentContainerComponentProps,
) {
  const { children } = props;
  const bookingData = children[0]?.props?.params.data;
  const selectedEmployee = useContext(EmployeeContext);

  const isBookingForSelectedEmployee =
    bookingData?.employee === selectedEmployee;

  const { isDraftAppointmentCreating } = useBookingSchedulerContext();
  return (
    <Box
      {...props}
      sx={{
        // when creating a new appointment, disable pointer events to possible make appointment shorter
        pointerEvents: isDraftAppointmentCreating ? 'none' : 'auto',
        '&.MuiBox-root .Appointment-appointment': {
          borderRadius: '0.8rem',
          backgroundColor: isBookingForSelectedEmployee
            ? 'primary.main'
            : 'rating.empty',
          '&:hover': {
            backgroundColor: isBookingForSelectedEmployee
              ? 'primary.main'
              : 'rating.empty',
          },
        },
      }}
    />
  );
}
