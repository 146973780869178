import { z } from 'zod';
import checkPhoneNumber from 'helpers/validators/checkPhoneNumber/checkPhoneNumber';
import { emailRegexp } from 'helpers/validators/checkEmail/checkEmail';

const schema = z.object({
  email: z.string().regex(emailRegexp, { message: 'errors.wrong_email' }),
  phoneNumber: z
    .string()
    .refine((value) => {
      if (!value) {
        return true;
      }
      return checkPhoneNumber(value);
    })
    .optional()
    .or(z.literal('')),
  location: z.string(),
  birthDate: z.string(),
});

export default schema;
