import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'label.candidate',
    align: 'left',
  },
  {
    label: 'label.phone_number',
    align: 'left',
  },
  {
    label: 'label.source',
    align: 'left',
  },
  {
    label: 'label.tags',
    align: 'left',
  },
];

export default tableHeaders;
