import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import Error from 'common/Error';
import { getOrganization } from 'store/organizations/selectors';

interface ImageWithAuthProps {
  src: string;
  alt: string;
}

interface ImageWithAuthState {
  data: Blob | null;
  isLoading: boolean;
  isError: boolean;
}

export default function ImageWithAuth({ src, alt }: ImageWithAuthProps) {
  const [imageState, setImageState] = useState<ImageWithAuthState>({
    data: null,
    isLoading: false,
    isError: false,
  });

  const token = localStorage.getItem('token');
  const organization = useSelector(getOrganization);

  useEffect(() => {
    const fetchData = async () => {
      setImageState((prev) => ({ ...prev, isLoading: true }));

      if (!token || !organization.instance) {
        setImageState((prev) => ({ ...prev, isLoading: false }));
        return;
      }

      try {
        const response = await fetch(src, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            instance: organization.instance,
          },
        });

        if (response.ok) {
          const data = await response.blob();
          setImageState((prev) => ({ ...prev, data }));
          return;
        }

        setImageState((prev) => ({ ...prev, isError: true }));
      } catch (error) {
        setImageState((prev) => ({ ...prev, isError: true }));
      } finally {
        setImageState((prev) => ({ ...prev, isLoading: false }));
      }
    };

    fetchData();
  }, [src, token, organization.instance]);

  if (imageState.isLoading) {
    return <Skeleton variant="rectangular" width="100%" height="100%" />;
  }

  if (imageState.isError) {
    return <Error />;
  }

  return (
    <>
      {imageState.data && (
        <img
          width="100%"
          height="100%"
          style={{
            objectFit: 'contain',
          }}
          src={URL.createObjectURL(imageState.data)}
          alt={alt}
        />
      )}
    </>
  );
}
