import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import useResourceRequestWizardContext from 'components/ResourceRequestWizard/Context/useResourceWizardContext';
import TagTile from 'components/TagTile/TagTile';
import TagGroupAutocomplete from 'components/TagGroupAutocomplete/TagGroupAutocomplete';
import { TagGroupSectionProps } from './interfaces';

export default function CandidateRequirementTagGroup({
  tagGroup,
}: TagGroupSectionProps) {
  const { resourceRequestTags, handleDeleteTag, handleAddTag } =
    useResourceRequestWizardContext();

  const resourceRequestTagsOfTagGroup = resourceRequestTags.filter(
    (tag) => tag.tagDefinition.tagGroup === tagGroup['@id'],
  );
  const usedTagDefinitionNames = resourceRequestTagsOfTagGroup.map(
    (tag) => tag.tagDefinition.name,
  );

  return (
    <Stack spacing={6}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" flexBasis="40%">
          {tagGroup.name}
        </Typography>
        <TagGroupAutocomplete
          tagGroup={tagGroup}
          usedTagDefinitionNames={usedTagDefinitionNames}
          handleOnAdd={handleAddTag}
        />
      </Stack>
      <Stack direction="row" gap={3} flexWrap="wrap">
        {resourceRequestTagsOfTagGroup.map((tag) => (
          <TagTile key={tag.id} tag={tag} handleOnDelete={handleDeleteTag} />
        ))}
      </Stack>
      <Divider />
    </Stack>
  );
}
