import { ApiResponse, ApiFilters } from 'store/interfaces';
import { Transaction } from 'store/transactions/transactions';
import { Cost } from 'store/costs/costs';
import { Project } from '../projects/interfaces';

export interface BudgetEmployees {
  '@id': string;
  '@type': string;
  budget: any;
  employee: string;
  id: string;
}
export interface BudgetTransaction {
  '@id': string;
  '@type': string;
  budget: any;
  targetAmount: number;
  transaction: Transaction;
  employee?: string;
  amount: number;
  cost: Cost;
}
export interface BudgetContractorOfBudget {
  '@id': string;
  '@type': string;
  budget: string;
  amount: string;
  id: string;
  contractor: Cost;
  transactions: Transaction[];
}

export interface BudgetContractor
  extends Omit<BudgetContractorOfBudget, 'contractor'> {
  contractor: string;
}

export interface BudgetContractorsResponse extends ApiResponse {
  'hydra:member': BudgetContractor[];
}

export interface BudgetConfig {
  period: string;
  baseAmount?: number;
  exponentialGrowth?: number;
  linearGrowth?: number;
  unitGrowth?: number;
}

export interface BudgetContractorPostRequest
  extends Omit<BudgetContractor, '@id' | '@type' | 'amount' | 'id'> {}

export interface BudgetProjectOfBudget
  extends Omit<BudgetContractor, 'contractor'> {
  project: Project;
  projectPhase?: string;
  data: { [key: string]: number };
}

export interface BudgetFilters extends ApiFilters<Budget> {
  page: number;
  itemsPerPage: number;
}

export enum BudgetPeriod {
  month = 'month',
  quarter = 'quarter',
  year = 'year',
  custom = 'custom',
}

export interface Budget {
  '@id': string;
  id: string;
  name: string;
  type: string;
  amount: string;
  period: string;
  dateFrom?: Date | string;
  dateTo?: Date | string;
  budgetEmployees: BudgetEmployees[];
  budgetTransactions: BudgetTransaction[];
  budgetContractors: BudgetContractorOfBudget[];
  budgetProjects: BudgetProjectOfBudget[];
  config: BudgetConfig[];
  budgetGroup: string;
}

export interface BudgetPatchMutationRequest
  extends Partial<
    Omit<
      Budget,
      | '@id'
      | 'budgetEmployees'
      | 'budgetTransactions'
      | 'budgetContractors'
      | 'budgetProjects'
      | 'budgetGroup'
    >
  > {}

export interface BudgetPostMutationRequest {
  name: string;
  type: string;
  amount: string;
  period: string;
  dateFrom?: string | undefined;
  dateTo?: string | undefined;
}

export interface BudgetTransactionPostRequest {
  budget: string;
  transaction: string;
  amount?: number;
}

export interface BudgetResponse extends ApiResponse {
  'hydra:member': Budget[];
}

export enum BudgetType {
  global = 'global',
  employee = 'employee',
}

export enum BudgetPeriodType {
  month = 'month',
  quarter = 'quarter',
  year = 'year',
  custom = 'custom',
}

export interface BudgetProject {
  '@id': string;
  id: string;
  budget: string;
  project: Project;
  projectPhase?: string;
  createdAt: string;
}

export interface BudgetProjectsResponse extends ApiResponse {
  'hydra:member': BudgetProject[];
}

export interface BudgetProjectPostRequest
  extends Pick<BudgetProject, 'budget' | 'projectPhase'> {
  project: string;
}
