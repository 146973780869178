import api from 'store/api';

export interface StorageResponse {
  id: string;
  size: number;
  mimeType: string;
  url: string;
  relationName: string;
}

export const storageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<StorageResponse, FormData>({
      query: (body) => ({
        url: '/storage/upload',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'Agreements',
        'Clients',
        'Documents',
        'Employees',
        'Transactions',
      ],
    }),
    deleteFile: builder.mutation({
      query: (id: string) => ({
        url: `/storage/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Agreements', 'Clients', 'Documents', 'Transactions'],
    }),
    getFile: builder.query<StorageResponse, string>({
      query: (id) => `/storage/${id}`,
      providesTags: [
        'Agreements',
        'Clients',
        'Documents',
        'Employees',
        'Transactions',
      ],
    }),
  }),
});

export const { useUploadFileMutation, useDeleteFileMutation, useGetFileQuery } =
  storageApi;
