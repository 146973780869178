import React from 'react';
import { Stack } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'store/incomes/interfaces';
import StyledTypography from './StyledTypography/Typography.styled';

interface SummaryProps {
  invoice: Invoice;
}

export default function Summary({ invoice }: SummaryProps) {
  const { amountNet, amount, currency } = invoice;
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();

  const paidAmount = invoice.incomeTransactions?.reduce(
    (acc, transaction) => acc + transaction.amount,
    0,
  );

  return (
    <Stack alignItems="flex-end">
      <Stack alignItems="flex-end" direction="row" spacing={10}>
        <StyledTypography color="text.secondary" variant="body1">
          {t('invoices.net_total')}
        </StyledTypography>
        <StyledTypography color="text.secondary" variant="h6">
          {formatAmount(amountNet, currency)}
        </StyledTypography>
      </Stack>
      <Stack alignItems="flex-end" direction="row" spacing={10}>
        <StyledTypography color="text.secondary" variant="body1">
          {t('invoices.vat_include')}
        </StyledTypography>
        <StyledTypography color="text.secondary" variant="h6">
          {formatAmount(amount - amountNet, currency)}
        </StyledTypography>
      </Stack>
      <Stack alignItems="flex-end" direction="row" spacing={10}>
        <StyledTypography color="text.secondary" variant="body1">
          {t('invoices.paid')}
        </StyledTypography>
        <StyledTypography color="text.secondary" variant="h6">
          {formatAmount(paidAmount, currency)}
        </StyledTypography>
      </Stack>
      <Stack alignItems="flex-end" direction="row" spacing={10} mt={3}>
        <StyledTypography color="primary" variant="subtitle1">
          {t('invoices.remaining_pay')}
        </StyledTypography>
        <StyledTypography variant="h5">
          {formatAmount(amount - paidAmount, currency)}
        </StyledTypography>
      </Stack>
    </Stack>
  );
}
