import React from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import PropertiesConfig from 'components/Properties/PropertiesConfig/PropertiesConfig';
import {
  useGetPropertiesQuery,
  useGetPropertyTypesQuery,
} from 'store/properties/properties';
import {
  useGetPropertyBookingDaysLimitQuery,
  useUpdatePropertyBookingDaysLimitMutation,
} from 'store/config/config';
import PropertyList from 'components/Properties/PropertyList';
import { useGetLocationsQuery } from 'store/locations/locations';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'store/api';
import { PropertiesProps } from './interfaces';

export default function Properties({
  setErrorMessage,
  setIsError,
  setIsSuccess,
}: PropertiesProps) {
  const { isLoading: propertiesFetching, isError: propertiesFetchError } =
    useGetPropertiesQuery({});
  const { isLoading: locationsFetching, isError: locationsFetchError } =
    useGetLocationsQuery();
  const { isLoading: typesFetching, isError: typesFetchError } =
    useGetPropertyTypesQuery();
  const {
    data: propertyBookingDaysLimit,
    isLoading: propertyBookingDaysLimitLoading,
    isError: propertyBookingDaysLimitError,
  } = useGetPropertyBookingDaysLimitQuery();

  const [
    updatePropertyBookingDaysLimit,
    { isLoading: updatePropertyBookingDaysLimitLoading },
  ] = useUpdatePropertyBookingDaysLimitMutation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleUpdatePropertyBookingDaysLimit = async (value: string) => {
    try {
      await updatePropertyBookingDaysLimit({ value }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('properties.header')}</NavBarTitle>
        <Button
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate('/properties/add')}
          variant="contained"
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <ContentSuspense
        isError={
          propertiesFetchError ||
          locationsFetchError ||
          typesFetchError ||
          propertyBookingDaysLimitError
        }
        isLoading={
          propertiesFetching ||
          locationsFetching ||
          typesFetching ||
          propertyBookingDaysLimitLoading
        }
      >
        <PropertiesConfig
          initialDaysLimit={propertyBookingDaysLimit?.value.toString() || ''}
          isSubmitting={updatePropertyBookingDaysLimitLoading}
          onSubmit={handleUpdatePropertyBookingDaysLimit}
        />
        <PropertyList />
      </ContentSuspense>
    </Grid>
  );
}
