import React from 'react';
import { useUpdateLocationMutation } from 'store/locations/locations';
import LocationForm from 'components/LocationForm/LocationForm';
import { FormPayload } from 'components/LocationForm/interfaces';
import { DialogContent } from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { t } from 'i18next';
import { CustomError } from 'store/api';
import { EditLocationProps } from './interfaces';

function EditLocation({
  location,
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditLocationProps) {
  const [updateLocation, { isLoading }] = useUpdateLocationMutation();

  const handleOnSubmit = async (payload: FormPayload) => {
    try {
      const { officeCloseHour, officeOpenHour, ...restPayload } = payload;
      await updateLocation({
        id: location.id,
        ...restPayload,
        officeOpenHour: officeOpenHour ? officeOpenHour.getHours() : null,
        officeCloseHour: officeCloseHour ? officeCloseHour.getHours() : null,
      }).unwrap();

      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('locations.edit')} setIsOpen={setIsOpen} />
      <DialogContent>
        <LocationForm
          onSubmit={handleOnSubmit}
          isSubmitting={isLoading}
          location={location}
        />
      </DialogContent>
    </>
  );
}

export default EditLocation;
