import api from 'store/api';
import { HolidayTypes } from './interfaces';

export const holidayTypesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHolidayTypes: builder.query<HolidayTypes, void>({
      query: () => '/holiday-types',
      providesTags: ['HolidayTypes'],
    }),
    patchHolidayType: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/holiday-types/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['HolidayTypes'],
    }),
  }),
});

export const { useGetHolidayTypesQuery, usePatchHolidayTypeMutation } =
  holidayTypesApi;
