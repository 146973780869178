import React from 'react';
import { createSvgIcon } from '@mui/material';

const DownloadIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99992 1.66675C10.4602 1.66675 10.8333 2.03984 10.8333 2.50008V10.8057L13.1038 8.45113C13.4232 8.11983 13.9508 8.11024 14.2821 8.4297C14.6134 8.74917 14.623 9.27672 14.3035 9.60802L10.5998 13.4489C10.4427 13.6118 10.2262 13.7038 9.99992 13.7038C9.77365 13.7038 9.55711 13.6118 9.40005 13.4489L5.69634 9.60802C5.37688 9.27672 5.38647 8.74917 5.71777 8.4297C6.04907 8.11024 6.57662 8.11983 6.89608 8.45113L9.16658 10.8057V2.50008C9.16658 2.03984 9.53968 1.66675 9.99992 1.66675ZM1.66659 11.1112C2.12682 11.1112 2.49992 11.4843 2.49992 11.9445V13.7964C2.49992 15.3816 3.78503 16.6667 5.37029 16.6667H14.6295C16.2148 16.6667 17.4999 15.3816 17.4999 13.7964V11.9445C17.4999 11.4843 17.873 11.1112 18.3333 11.1112C18.7935 11.1112 19.1666 11.4843 19.1666 11.9445V13.7964C19.1666 16.3021 17.1353 18.3334 14.6295 18.3334H5.37029C2.86455 18.3334 0.833252 16.3021 0.833252 13.7964V11.9445C0.833252 11.4843 1.20635 11.1112 1.66659 11.1112Z"
      fill="currentColor"
    />
  </svg>,

  'DownloadIcon',
);

export default DownloadIcon;
