import React from 'react';
import { useGetProjectsQuery } from 'store/projects/projects';
import { Project } from 'store/projects/interfaces';
import { Box, Typography } from '@mui/material';
import { WorkingHours } from 'store/workingHours/workingHours';
import WorkingHoursData from 'components/HoursViewProject/WorkingHoursData/WorkingHoursData';
import useProjectMemberEmployees from 'hooks/useProjectMemberEmployee/useProjectMemberEmployees';
import { useTranslation } from 'react-i18next';
import { ProjectStatuses } from 'enums';
import { useGetHolidaysQuery } from 'store/holidays/holidays';
import fullDateFormat from 'helpers/fullDateFormat';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { useGetProjectRatesQuery } from 'store/employeeProjectRate/employeeProjectRate';

interface HoursViewProjectProps {
  projectId: string;
  workingHours: WorkingHours[];
  dateRange: [Date, Date];
}

function HoursViewProject({
  projectId,
  workingHours,
  dateRange,
}: HoursViewProjectProps) {
  const { t } = useTranslation();
  const {
    data: projects = {
      'hydra:member': [] as Project[],
    },
    isFetching: projectsFetching,
    isError: projectsError
  } = useGetProjectsQuery({
    status: ProjectStatuses.active,
  });
  const project = projects['hydra:member'].find(
    (item) => item.id === +projectId,
  ) as Project;
  const {
    data: holidays = {
      'hydra:member': [],
    },
    isLoading: holidaysFetching,
    isError: holidaysFetchError,
  } = useGetHolidaysQuery({
    'dateFrom[before]': fullDateFormat(dateRange[1]),
    'dateTo[after]': fullDateFormat(dateRange[0]),
  });

  const {
    projectMemberEmployees,
    isProjectMemberEmployee,
    employeesLoading,
    employeesError,
  } = useProjectMemberEmployees(project);

  const employeesWithLoggedHours = projectMemberEmployees
    .filter(isProjectMemberEmployee)
    .filter((projectMember) =>
      workingHours
        .map((hours) => hours.employee)
        .includes(projectMember.employee['@id']),
    );

  const employeesWithLoggedHoursIds = employeesWithLoggedHours.map((employee) =>
    employee.id.toString(),
  );

  const {
    data: projectRates = [],
    isLoading: projectRatesLoading,
    isError: projectRatesFetchError,
  } = useGetProjectRatesQuery(
    {
      employee: employeesWithLoggedHoursIds,
    },
    {
      skip: !employeesWithLoggedHours.length,
    },
  );

  if (holidaysFetching || employeesLoading || projectsFetching) {
    return <Loader />;
  }

  if (holidaysFetchError || employeesError || projectsError) {
    return <Error />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="h5">{project.name}</Typography>
      {employeesWithLoggedHours.length ? (
        employeesWithLoggedHours.map((projectMemberEmployee) => (
          <WorkingHoursData
            projectRates={projectRates}
            projectRatesLoading={projectRatesLoading}
            projectRatesFetchError={projectRatesFetchError}
            key={projectMemberEmployee.id}
            employee={projectMemberEmployee.employee}
            dateRange={dateRange}
            holidays={holidays['hydra:member']}
            projectIri={project['@id']}
            workingHours={workingHours}
          />
        ))
      ) : (
        <Typography align="center" variant="body1">
          {t('hours_view.no_data')}
        </Typography>
      )}
    </Box>
  );
}

export default HoursViewProject;
