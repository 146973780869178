import api, { CustomError } from 'store/api';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';
import {
  CandidatesData,
  CandidateFilters,
  Candidate,
  CandidatePostMutationRequest,
  CandidatePatchMutationRequest,
} from './interfaces';

export const candidatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCandidates: builder.query<CandidatesData, Partial<CandidateFilters>>({
      query: (filters) => ({ url: '/candidates', params: filters }),
      providesTags: ['Candidates'],
      transformResponse: (response: CandidatesData) => ({
        ...response,
        'hydra:member': response['hydra:member'].sort(
          (candidate, comparedCandidate) =>
            new Date(comparedCandidate.createdAt).getTime() -
            new Date(candidate.createdAt).getTime(),
        ),
      }),
    }),
    getCandidate: builder.query<Candidate, string>({
      query: (id) => ({ url: `/candidates/${id}` }),
      providesTags: ['Candidates'],
    }),
    getCandidatesByPhrase: builder.query<CandidatesData, string>({
      query: (phrase) => ({ url: '/candidates/search', params: { phrase } }),
      providesTags: ['Candidates'],
    }),
    addCandidate: builder.mutation<Candidate, CandidatePostMutationRequest>({
      query: (payload) => ({
        url: '/candidates',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: createdCandidate } = await queryFulfilled;

          const candidatesCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Candidates' },
          ]);
          candidatesCache
            .filter(({ endpointName }) => endpointName === 'getCandidates')
            .forEach(({ originalArgs }) => {
              dispatch(
                candidatesApi.util.updateQueryData(
                  'getCandidates',
                  originalArgs,
                  (draft) => {
                    draft?.['hydra:member']?.unshift(createdCandidate);
                  },
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    updateCandidate: builder.mutation<Candidate, CandidatePatchMutationRequest>(
      {
        query: ({ id, ...body }) => ({
          url: `/candidates/${id}`,
          headers: { 'content-type': 'application/merge-patch+json' },
          method: 'PATCH',
          body: JSON.stringify(body),
        }),
        async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
          try {
            const { data: updatedCandidate } = await queryFulfilled;

            const candidatesCache = api.util.selectInvalidatedBy(getState(), [
              { type: 'Candidates' },
            ]);
            candidatesCache
              .filter(({ endpointName }) => endpointName === 'getCandidates')
              .forEach(({ originalArgs }) => {
                dispatch(
                  candidatesApi.util.updateQueryData(
                    'getCandidates',
                    originalArgs,
                    (draft) => {
                      const candidateIndex = draft?.['hydra:member'].findIndex(
                        (candidate) => candidate.id === id,
                      );
                      if (candidateIndex !== -1) {
                        draft?.['hydra:member']?.splice(
                          candidateIndex,
                          1,
                          updatedCandidate,
                        );
                      }
                    },
                  ),
                );
              });

            candidatesCache
              .filter(
                ({ endpointName, originalArgs }) =>
                  endpointName === 'getCandidate' && originalArgs === id,
              )
              .forEach(({ originalArgs }) => {
                dispatch(
                  candidatesApi.util.updateQueryData(
                    'getCandidate',
                    originalArgs,
                    () => updatedCandidate,
                  ),
                );
              });
          } catch (error) {
            if ('data' in (error as CustomError)) {
              dispatch(
                setErrorMessage(
                  (error as CustomError).data['hydra:description'],
                ),
              );
            }
            dispatch(setErrorStatus(true));
          }
        },
      },
    ),
  }),
});

export const {
  useGetCandidatesQuery,
  useGetCandidateQuery,
  useGetCandidatesByPhraseQuery,
  useAddCandidateMutation,
  useUpdateCandidateMutation,
} = candidatesApi;
