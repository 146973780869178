import { AgreementType } from 'components/AgreementsList/AgreementsList';

interface AgreementTypeItem {
  label: string;
  value: AgreementType;
}

const agreementTypes: AgreementTypeItem[] = [
  {
    label: 'agreements.type_agreement',
    value: AgreementType.AGREEMENT,
  },
  {
    label: 'agreements.type_annex',
    value: AgreementType.ANNEX,
  },
  {
    label: 'agreements.type_termination',
    value: AgreementType.TERMINATION,
  },
  {
    label: 'agreements.type_list_of_intent',
    value: AgreementType.LIST_OF_INTENT,
  },
];

export default agreementTypes;
