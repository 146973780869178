export type ScaleEntry = {
  lastStep: number;
  modifier: number;
};

export default function getCustomScaleSliderParams(scale: ScaleEntry[]) {
  const maxStepValue = scale[scale.length - 1].lastStep;
  const minStepValue = 0;
  const customScale = (step: number): number =>
    scale.reduce((acc, scaleEntry, index, array) => {
      const previousLastStep = index > 0 ? array[index - 1].lastStep : 0;
      if (step > scaleEntry.lastStep) {
        return (
          acc + (scaleEntry.lastStep - previousLastStep) * scaleEntry.modifier
        );
      }
      if (step > previousLastStep) {
        return acc + (step - previousLastStep) * scaleEntry.modifier;
      }

      return acc;
    }, 0);

  const reverseCustomScale = (value: number): number =>
    scale.reduce(
      (acc, scaleEntry, index, array) => {
        const previousLastStep = index > 0 ? array[index - 1].lastStep : 0;
        const previousAccumulatedValue = acc.accumulatedValue;

        const nextAccumulatedValue =
          previousAccumulatedValue +
          (scaleEntry.lastStep - previousLastStep) * scaleEntry.modifier;

        if (value > nextAccumulatedValue) {
          return {
            step: scaleEntry.lastStep,
            accumulatedValue: nextAccumulatedValue,
          };
        }
        if (value > previousAccumulatedValue) {
          return {
            step:
              previousLastStep +
              (value - previousAccumulatedValue) / scaleEntry.modifier,
            accumulatedValue: value,
          };
        }

        return acc;
      },
      { step: 0, accumulatedValue: 0 },
    ).step;

  const marks = [
    {
      value: minStepValue,
      label: minStepValue.toString(),
    },
    ...scale.map((step, index) => {
      if (index === scale.length - 1) {
        return {
          value: maxStepValue,
          label: customScale(maxStepValue).toString(),
        };
      }

      return {
        value: step.lastStep,
      };
    }),
  ];

  return {
    maxStepValue,
    minStepValue,
    customScale,
    reverseCustomScale,
    marks,
  };
}
