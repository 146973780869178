import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import getCalendarTypeLocale from 'helpers/getCalendarTypeLocale';
import useOrganizationStartWeekDay from 'hooks/useOrganizationStartWeekDay';

interface CustomLocalizationProviderProps {
  children: React.ReactNode;
}

export default function CustomLocalizationProvider({
  children,
}: CustomLocalizationProviderProps) {
  const startWeekDay = useOrganizationStartWeekDay();
  const locale = getCalendarTypeLocale(startWeekDay);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
}
