/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useGetEmployeeQuery } from 'store/employee/employees';
import {
  amountFrequency,
  amountSort,
} from 'components/AddAgreementForm/selectData';
import { useValidate } from 'hooks/useValidate';
import { useAddAgreementMutation } from 'store/agreements/agreements';
import AgreementDetails from 'components/AddAgreementForm/AgreementDetails';
import DateSelect from 'components/AddAgreementForm/DateSelect';
import WorkDetails from 'components/AddAgreementForm/WorkDetails';
import { Errors } from 'components/AddAgreementForm/interfaces';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import {
  useAddPositionMutation,
  useGetPositionsQuery,
} from 'store/positions/positions';
import { Employee } from 'store/employee/interfaces';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { CustomError } from 'store/api';
import { StatusProps } from 'hoc/Status/Status';
import { LoadingButton } from '@mui/lab';

export interface AddEmployeeAgreementProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeeId: number;
}

function AddEmployeeAgreement({
  setIsSuccess,
  setIsError,
  setIsOpen,
  setErrorMessage,
  employeeId,
}: AddEmployeeAgreementProps) {
  const {
    data: employee = {} as Employee,
    isLoading: employeeFetching,
    isError: employeeFetchError,
  } = useGetEmployeeQuery(employeeId);
  const {
    data: currencies = [],
    isError: currenciesFetchError,
    isLoading: currenciesLoading,
    isSuccess: currenciesFetched,
  } = useGetCurrenciesQuery();
  const {
    data: positions = [],
    isLoading: positionsFetching,
    isError: positionsFetchError,
    isSuccess: positionsFetched,
  } = useGetPositionsQuery();
  const [selectedPosition, setSelectedPosition] = useState<string | null>(null);
  const [writtenPosition, setWrittenPosition] = useState('');
  const [type, setType] = useState('');
  const [variant, setVariant] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hoursPerWeek, setHoursPerWeek] = useState('40');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [perFrequency, setPerFrequency] = useState(amountFrequency[0].value);
  const [amountType, setAmountType] = useState(amountSort[0]);
  const { errors, validate } = useValidate<Errors>();
  const [addAgreement, { isLoading }] = useAddAgreementMutation();
  const { t } = useTranslation();
  const [addPosition] = useAddPositionMutation();

  useEffect(() => {
    if (!currenciesFetched) {
      return;
    }
    setCurrency(currencies[0].id);
  }, [currenciesFetched, currencies, positionsFetched, positions]);

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) ||
    !writtenPosition ||
    !type ||
    !variant ||
    !startDate ||
    !hoursPerWeek ||
    !amount;

  const handleSelectChange = (
    event: SelectChangeEvent,
    setFieldValue: React.Dispatch<React.SetStateAction<string>>,
    field: any,
  ) => {
    setFieldValue(event.target.value);
    validate(field, event.target.value !== '');
  };

  const handleAddAgreement = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (!selectedPosition) {
      addPosition({ name: writtenPosition });
    }
    try {
      await addAgreement({
        employee: employee['@id'],
        type,
        variant,
        amount: Number(amount) * 10000,
        currency,
        hoursPerWeek: Number(hoursPerWeek),
        amountType,
        billingType: perFrequency,
        position: selectedPosition || writtenPosition,
        dateFrom: startDate,
        dateTo: endDate || null,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (currenciesFetchError || positionsFetchError || employeeFetchError) {
    return <Error />;
  }

  if (currenciesLoading || positionsFetching || employeeFetching) {
    return <Loader />;
  }

  return (
    <>
      <DialogHeader title={t('agreements.add_new')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleAddAgreement}>
          <Box display="flex" flexDirection="column" rowGap={3} pt={4}>
            <FormControl disabled fullWidth>
              <InputLabel id="employee-select">
                {t('inputs.select_employee')}
              </InputLabel>
              <Select
                id="employee-select"
                defaultValue={employee['@id']}
                label={t('inputs.select_employee')}
              >
                <MenuItem key={employee.id} value={employee['@id']}>
                  {employee.lastname} {employee.firstname}
                </MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              freeSolo
              value={selectedPosition}
              onChange={(event, newValue: string | null) => {
                setSelectedPosition(newValue);
              }}
              inputValue={writtenPosition}
              onInputChange={(event, newInputValue) =>
                setWrittenPosition(newInputValue)
              }
              renderInput={(params) => (
                <TextField {...params} required label={t('inputs.position')} />
              )}
              options={positions.map((positionData) => positionData.name)}
            />
            <Box display="flex" columnGap={3}>
              <AgreementDetails
                errors={errors}
                type={type}
                setType={setType}
                variant={variant}
                setVariant={setVariant}
                handleChange={handleSelectChange}
              />
            </Box>
            <DateSelect
              errors={errors}
              startDate={startDate}
              onStartDateChange={(event) => setStartDate(event.target.value)}
              endDate={endDate}
              onEndDateChange={(event) => setEndDate(event.target.value)}
              validate={validate}
            />
            <WorkDetails
              errors={errors}
              hoursPerWeek={hoursPerWeek}
              onHoursPerWeekChange={(event) =>
                setHoursPerWeek(event.target.value)
              }
              amount={amount}
              onAmountChange={(event) => setAmount(event.target.value)}
              currency={currency}
              onCurrencyChange={(event) => setCurrency(event.target.value)}
              currencies={currencies}
              perFrequency={perFrequency}
              onPerFrequencyChange={(event) =>
                setPerFrequency(event.target.value)
              }
              amountType={amountType}
              onAmountTypeChange={(event) => setAmountType(event.target.value)}
              validate={validate}
            />
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <LoadingButton
                disabled={disableSubmission()}
                endIcon={<AddCircleOutlineIcon />}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                <span>{t('button.create')}</span>
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default AddEmployeeAgreement;
