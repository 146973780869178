import React, { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AuthProps } from 'hoc/Auth/Auth';
import { useSelector } from 'react-redux';
import { getOrganization } from 'store/organizations/selectors';

export interface ProtectedRouteProps extends AuthProps {
  children: ReactNode;
  routeRoles: string[];
  routeFeature?: string;
}

export default function ProtectedRoute({
  children,
  setIsAuthorized,
  setToken,
  routeRoles,
  routeFeature,
}: ProtectedRouteProps) {
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const organizationRoles = useSelector(getOrganization).roles;
  const organizationFeatures = useSelector(getOrganization).features;

  const hasRole = routeRoles.some((role) => organizationRoles.includes(role));
  const hasFeature = routeFeature
    ? organizationFeatures.includes(routeFeature)
    : true;

  useEffect(() => {
    if (!token) {
      setIsAuthorized(false);
      setToken(null);
    } else {
      setIsAuthorized(true);
      setToken(token);
    }
  }, [setIsAuthorized, setToken, token]);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return hasRole && hasFeature ? (
    <>{children}</>
  ) : (
    <Typography p={2} variant="h5">
      {t('authorization.not_authorized')}
    </Typography>
  );
}
