import { AppStateInterface } from './interfaces';

const initialAppState: AppStateInterface = {
  config: {
    apiHost: process.env.REACT_APP_API_HOST,
    apiToken: undefined,
  },
};

export default initialAppState;
