import React, { useState } from 'react';
import {
  Box,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { useTranslation } from 'react-i18next';
import { useGetBenefitsQuery } from 'store/benefits/benefits';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAddEmployeeBenefitMutation } from 'store/employeeBenefit/employeeBenefit';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { useParams } from 'react-router-dom';
import createApiIri from 'helpers/createApiIri';

export interface AddBenefitProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddBenefit({
  setIsOpen,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: AddBenefitProps) {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data: benefits = [] } = useGetBenefitsQuery();
  const [benefitIri, setBenefitIri] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [addBenefit, { isLoading }] = useAddEmployeeBenefitMutation();

  const handleAddBenefit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addBenefit({
        benefit: benefitIri,
        dateFrom,
        dateTo: dateTo || null,
        employee: createApiIri('employees', `${id}`),
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('benefits.add_new')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleAddBenefit}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={3}
            mt={3}
          >
            <FormControl fullWidth required>
              <InputLabel id="benefit">{t('label.benefit')}</InputLabel>
              <Select
                id="benefit"
                label={t('label.benefit')}
                onChange={(event) => setBenefitIri(event.target.value)}
                value={benefitIri}
              >
                {benefits.map((benefit) => (
                  <MenuItem key={benefit.id} value={benefit['@id']}>
                    {benefit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label={t('label.date_from')}
              name="dateFrom"
              onChange={(event) => setDateFrom(event.target.value)}
              required
              type="date"
              value={dateFrom}
            />
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: dateFrom,
              }}
              label={t('label.date_to')}
              name="dateTo"
              onChange={(event) => setDateTo(event.target.value)}
              type="date"
              value={dateTo}
            />
            <LoadingButton
              disabled={!benefitIri || !dateFrom}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
