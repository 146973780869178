import React, { useState } from 'react';
import { useAddBenefitMutation } from 'store/benefits/benefits';
import { CustomError } from 'store/api';
import { StatusProps } from 'hoc/Status/Status';
import { FormTypes } from 'enums';
import BenefitForm from '../BenefitForm';

export interface AddBenefitProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddBenefit({
  setIsOpen,
  setIsError,
  setErrorMessage,
  setIsSuccess,
}: AddBenefitProps) {
  const [name, setName] = useState('');
  const [addBenefit, { isLoading }] = useAddBenefitMutation();

  const handleAddBenefit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addBenefit({
        name,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <BenefitForm
      formType={FormTypes.add}
      handleSubmit={handleAddBenefit}
      isLoading={isLoading}
      name={name}
      setIsOpen={setIsOpen}
      setName={setName}
    />
  );
}
