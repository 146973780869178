import React from 'react';
import { Box, FormHelperText, InputLabel, TextField } from '@mui/material';
import { DateSelectProps } from 'components/AddAgreementForm/interfaces';
import { useTranslation } from 'react-i18next';

function DateSelect({
  errors,
  startDate,
  endDate,
  validate,
  onStartDateChange,
  onEndDateChange,
}: DateSelectProps) {
  const { t } = useTranslation();

  return (
    <Box display="flex" columnGap={3}>
      <Box width="100%">
        <InputLabel
          color={errors.startDate ? 'error' : 'primary'}
          id="start-date-picker"
          sx={{ marginLeft: '0.5rem' }}
        >
          {t('agreements.start_date')}
        </InputLabel>
        <TextField
          required
          error={errors.startDate}
          id="start-date-picker"
          type="date"
          value={startDate}
          onChange={onStartDateChange}
          onBlur={(event) => validate('startDate', event.target.value !== '')}
          fullWidth
        />
        {errors.startDate && (
          <FormHelperText error={errors.startDate}>
            {t('errors.field_required')}
          </FormHelperText>
        )}
      </Box>
      <Box width="100%">
        <InputLabel id="end-date-picker" sx={{ marginLeft: '0.5rem' }}>
          {t('agreements.end_date')}
        </InputLabel>
        <TextField
          id="end-date-picker"
          type="date"
          value={endDate}
          onChange={onEndDateChange}
          inputProps={{
            min: startDate,
          }}
          fullWidth
        />
      </Box>
    </Box>
  );
}

export default DateSelect;
