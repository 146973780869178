import api from 'store/api';
import {
  EmployeeBenefit,
  EmployeeBenefitsFilters,
  EmployeeBenefitsResponseDto,
  EmployeeBenefitRequestDto
} from './interfaces';

export const employeeBenefitApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeBenefits: builder.query<
      EmployeeBenefit[],
      Partial<EmployeeBenefitsFilters>
    >({
      query: (filters) => ({
        url: '/employee-benefits',
        params: filters,
      }),
      providesTags: ['EmployeeBenefits'],
      transformResponse: (response: EmployeeBenefitsResponseDto) =>
        response['hydra:member'].sort(
          (benefit, comparedBenefit) =>
            new Date(comparedBenefit.dateFrom).getTime() -
            new Date(benefit.dateFrom).getTime(),
        ),
    }),
    addEmployeeBenefit: builder.mutation({
      query: (body) => ({
        url: '/employee-benefits',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EmployeeBenefits'],
    }),
    updateEmployeeBenefit: builder.mutation<
      EmployeeBenefit,
      Partial<EmployeeBenefitRequestDto> & Pick<EmployeeBenefit, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/employee-benefits/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['EmployeeBenefits'],
    }),
  }),
});

export const {
  useGetEmployeeBenefitsQuery,
  useAddEmployeeBenefitMutation,
  useUpdateEmployeeBenefitMutation,
} = employeeBenefitApi;
