import React from 'react';
import { Tooltip, Typography } from '@mui/material';

interface TitleTooltipProps {
  title?: string;
  children: React.ReactElement<any, any>;
  placement?: Placement;
  titleComponent?: React.ReactNode;
}

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

function TitleTooltip({
  title,
  children,
  placement = 'bottom',
  titleComponent = <Typography>{title}</Typography>
}: TitleTooltipProps) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'main.white',
            boxShadow: 5,
            color: 'text.primary',
          },
        },
      }}
      placement={placement}
      title={titleComponent}
    >
      {children}
    </Tooltip>
  );
}

export default TitleTooltip;
