import React from 'react';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import Result from 'components/BudgetCostsList/Result/Result';
import { BudgetCostsListProps, CostsType } from './interfaces';
import getMergedBudgetCollections from './budgetUtils';
import tableHeaders from './tableHeaders';

export default function BudgetCostsList({
  budget,
  employees,
  tableTitle,
  type,
  isLoading,
  isFetchError,
}: BudgetCostsListProps) {
  const { t } = useTranslation();

  const mergedBudgetCollections = getMergedBudgetCollections(budget);

  const budgetCollectionsToDisplay =
    type === CostsType.latest
      ? mergedBudgetCollections.slice(-5)
      : mergedBudgetCollections;

  return (
    <StyledContentBox>
      <Box p={3}>
        <Typography variant="h5">{tableTitle}</Typography>
        <TableContainer sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header.label} align={header.align}>
                    <Typography variant="tableHeader" color="text.secondary">
                      {t(header.label)}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell colSpan={tableHeaders.length} />
              </TableRow>
            </TableHead>
            <TableBodyContent
              tableHeaders={tableHeaders}
              isError={isFetchError}
              isFetching={isLoading}
            >
              {budgetCollectionsToDisplay.map((transaction) => (
                <Result
                  key={transaction.id}
                  transaction={transaction}
                  employees={employees}
                />
              ))}
            </TableBodyContent>
          </Table>
        </TableContainer>
      </Box>
    </StyledContentBox>
  );
}
