import React from 'react';
import Error from 'common/Error';
import { TableBody, TableRow, TableCell, Skeleton } from '@mui/material';
import { TableHeadersProps } from 'interfaces';
import NoDataRow from 'common/NoDataRow/NoDataRow';

interface TableBodyProps {
  isError: boolean;
  isFetching: boolean;
  children: React.ReactNode;
  tableHeaders: TableHeadersProps[];
  skeletonRowsLength?: number;
  dataTestId?: string;
  showNoDataRow?: boolean;
}

const defaultSkeletonRowsLength = 15;

export default function TableBodyContent({
  isError,
  isFetching,
  children,
  tableHeaders,
  skeletonRowsLength = defaultSkeletonRowsLength,
  dataTestId,
  showNoDataRow = true,
}: TableBodyProps) {
  const SKELETON_ROWS = Array.from(
    { length: skeletonRowsLength },
    (_, i) => i + 1,
  );

  if (isFetching) {
    return (
      <TableBody>
        {SKELETON_ROWS.map((row) => (
          <TableRow key={row}>
            {tableHeaders.map((header) => (
              <TableCell key={header.label}>
                <Skeleton />
              </TableCell>
            ))}
            <TableCell colSpan={tableHeaders.length}>
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  if (isError) {
    return (
      <TableBody>
        <Error />
      </TableBody>
    );
  }

  if (children && !React.Children.count(children) && showNoDataRow) {
    return (
      <TableBody>
        <NoDataRow headers={tableHeaders} />
      </TableBody>
    );
  }

  return <TableBody data-testid={dataTestId}>{children}</TableBody>;
}
