import React, { useEffect, useState } from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { Client, useUpdateClientMutation } from 'store/clients/clients';
import { StatusProps } from 'hoc/Status/Status';
import { useValidate } from 'hooks/useValidate';
import checkVat from 'helpers/validators/checkVat';
import { Cost } from 'store/costs/costs';
import ClientFormSelect from 'components/AddClientForm/ClientFormSelect';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface DetailsProps extends StatusProps {
  client: Client;
  costs: Cost[];
}

export interface Errors {
  vatNumber: boolean;
}

export default function Details({
  setIsError,
  setIsSuccess,
  setErrorMessage,
  client,
  costs,
}: DetailsProps) {
  const [updateClient, { isLoading }] = useUpdateClientMutation();

  const [vatNumber, setVatNumber] = useState('');
  const [dueDays, setDueDays] = useState('');
  const [costIri, setCostIri] = useState('');
  const [formDataChanged, setFormDataChanged] = useState(false);
  const isCostsManager = useCheckRoles(roles.contractors);
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();

  useEffect(() => {
    setVatNumber(client.vatNumber || '');
    setDueDays(client.dueDays || '');
    setCostIri(client.defaultCost || '');
  }, [client]);

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) ||
    (Boolean(vatNumber) && !checkVat(vatNumber));

  const handleEditClient = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateClient({
        id: client.id,
        vatNumber: vatNumber || null,
        dueDays: +dueDays,
        defaultCost: costIri || null,
      }).unwrap();
      setIsSuccess(true);
      setFormDataChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box p={4} bgcolor="background.list" borderRadius="1.2rem">
      <Typography variant="h5" mb={4}>
        {t('clients.details')}
      </Typography>
      <form onSubmit={handleEditClient}>
        <FormControl fullWidth sx={{ rowGap: 2 }}>
          <TextField
            fullWidth
            id="vat-number"
            label={t('clients.vat_number')}
            value={vatNumber}
            error={errors.vatNumber}
            helperText={errors.vatNumber && t('errors.invalid_vat_number')}
            onChange={(event) => {
              setVatNumber(event.target.value);
              setFormDataChanged(true);
            }}
            onBlur={(event) =>
              validate(
                'vatNumber',
                event.target.value.length !== 0
                  ? checkVat(event.target.value)
                  : !checkVat(event.target.value),
              )
            }
          />
          <TextField
            fullWidth
            id="due-days"
            type="number"
            label={t('clients.due_days')}
            value={dueDays}
            onChange={(event) => {
              setDueDays(event.target.value);
              setFormDataChanged(true);
            }}
          />
          {isCostsManager && (
            <ClientFormSelect
              selectId="cost-select"
              placeholder={t('clients.default_cost')}
              label={t('clients.default_cost')}
              isRequired={false}
              value={costIri}
              onChangeFn={(event) => {
                setCostIri(event.target.value);
                setFormDataChanged(true);
              }}
              selectOptions={costs.map((item) => ({
                id: item['@id'],
                name: item.name,
              }))}
            />
          )}
        </FormControl>
        {formDataChanged && (
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<Add />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
}
