/* eslint-disable no-param-reassign */
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import { startOfMonth, endOfMonth } from 'date-fns';
import dateFormat from 'dateformat';
import { Budget } from 'store/budget/interfaces';
import createBudgetConfigData, {
  formatPeriod,
} from 'pages/BudgetPlanner/context/createBudgetConfigData';
import calculateTotalAmountBudgetConfig from 'components/BudgetPlannerChart/calculateTotalAmountBudgetConfig';

export interface BudgetPeriodStats {
  period: string;
  usedAmount: number;
  availableAmount: number;
}

export default function createBudgetPeriodStats(
  budget: Budget,
): BudgetPeriodStats[] {
  const dateFrom = startOfMonth(new Date(budget.dateFrom as string));
  const dateTo = endOfMonth(new Date(budget.dateTo as string));
  const budgetPeriodStats: BudgetPeriodStats[] = [];
  const budgetConfigData = createBudgetConfigData(budget);

  const { budgetTransactions, budgetProjects } = budget;
  const budgetTransactionIris = budgetTransactions.map(
    (budgetTransaction) => budgetTransaction.transaction['@id'],
  );
  const budgetContractorTransactions = budget.budgetContractors
    .flatMap((budgetContractor) => budgetContractor.transactions)
    .filter(
      (transaction) => !budgetTransactionIris.includes(transaction?.['@id']),
    );
  const budgetProjectsPeriodData = budgetProjects.map(
    (project) => project.data,
  );

  const budgetProjectsPeriodUsedAmount = budgetProjectsPeriodData.reduce(
    (chartData, projectPeriods) => {
      Object.entries(projectPeriods).forEach(([period, amount]) => {
        if (chartData[period]) {
          chartData[period] += amount;
        } else {
          chartData[period] = amount;
        }
      });

      return chartData;
    },
    {} as Record<string, number>,
  );

  const budgetTransactionPeriodUsedAmount = budgetTransactions.reduce(
    (chartData, budgetTransaction) => {
      const { transaction } = budgetTransaction;
      const { cost, targetAmount, orderDate, connectedMonth } = transaction;
      const period = connectedMonth
        ? dateFormat(connectedMonth, 'yyyymm')
        : dateFormat(orderDate, 'yyyymm');

      let transactionAmount = budgetTransaction.targetAmount
        ? Math.abs(budgetTransaction.targetAmount / BACKEND_AMOUNT_MODIFIER)
        : Math.abs(targetAmount);

      if (cost && cost.taxAmount) {
        const tax = transactionAmount * (cost.taxAmount / 100);
        transactionAmount += tax;
      }

      if (chartData[period]) {
        const sum = +(chartData[period] + transactionAmount).toFixed(2);
        chartData[period] = sum;
      } else {
        chartData[period] = transactionAmount;
      }

      return chartData;
    },
    {} as Record<string, number>,
  );

  const budgetContractorPeriodUsedAmount = budgetContractorTransactions.reduce(
    (chartData, transaction) => {
      if (!transaction) {
        return chartData;
      }

      const { cost, targetAmount, orderDate, connectedMonth } = transaction;
      const period = connectedMonth
        ? dateFormat(connectedMonth, 'yyyymm')
        : dateFormat(orderDate, 'yyyymm');

      let transactionAmount = Math.abs(targetAmount);

      if (cost && cost.taxAmount) {
        const tax = transactionAmount * (cost.taxAmount / 100);
        transactionAmount += tax;
      }

      if (chartData[period]) {
        const sum = +(chartData[period] + transactionAmount).toFixed(2);
        chartData[period] = sum;
      } else {
        chartData[period] = transactionAmount;
      }

      return chartData;
    },
    {} as Record<string, number>,
  );

  for (
    let periodDate = dateFrom;
    periodDate < dateTo;
    periodDate.setMonth(periodDate.getMonth() + 1)
  ) {
    const period = dateFormat(periodDate, 'yyyymm');
    const budgetPeriodChartData = {
      period: formatPeriod(period),
      usedAmount: 0,
      availableAmount: 0,
    };
    const budgetConfigForPeriod = budgetConfigData.find(
      (data) => data.period === formatPeriod(period),
    );

    if (budgetProjectsPeriodUsedAmount[period]) {
      budgetPeriodChartData.usedAmount += budgetProjectsPeriodUsedAmount[period];
    }
    if (budgetTransactionPeriodUsedAmount[period]) {
      budgetPeriodChartData.usedAmount +=
        budgetTransactionPeriodUsedAmount[period];
    }
    if (budgetContractorPeriodUsedAmount[period]) {
      budgetPeriodChartData.usedAmount += budgetContractorPeriodUsedAmount[period];
    }
    if (budgetConfigForPeriod) {
      const availableAmount = calculateTotalAmountBudgetConfig(
        budgetConfigForPeriod,
      );
      budgetPeriodChartData.availableAmount = +availableAmount;
    }

    budgetPeriodStats.push(budgetPeriodChartData);
  }

  return budgetPeriodStats;
}
