import React from 'react';
import { DialogContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Property } from 'store/properties/interfaces';
import ImageWithAuth from 'common/ImageWithAuth/ImageWithAuth';

interface DialogFormWrapperProps {
  property: Property;
  children: React.ReactNode;
}

export default function DialogFormWrapper({
  property,
  children,
}: DialogFormWrapperProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row">
      <Stack p={3} width="29.6rem">
        <Typography variant="body2" color="text.secondary">
          {t('booking.book')}
        </Typography>
        <Typography variant="h6" pb={3}>
          {property.name}
        </Typography>
        <Stack
          width="24.8rem"
          maxHeight="24.8rem"
          alignItems="center"
          justifyContent="center"
          alignSelf="center"
          borderRadius="1.2rem"
          overflow="hidden"
        >
          {property.file && (
            <ImageWithAuth src={property.file.url} alt={property.name} />
          )}
        </Stack>
      </Stack>
      <Stack>
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          {children}
        </DialogContent>
      </Stack>
    </Stack>
  );
}
