import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { useTranslation } from 'react-i18next';

export interface ActionOutlinedButtonProps {
  onClick: () => void;
  label: string;
  color?: TypographyProps['color'];
  disabled?: LoadingButtonProps['disabled'];
  loading?: LoadingButtonProps['loading'];
  sx?: LoadingButtonProps['sx'];
  endIcon?: LoadingButtonProps['endIcon'];
}

export default function ActionOutlinedButton({
  onClick,
  label,
  disabled = false,
  loading = false,
  color = 'primary.main',
  sx,
  endIcon
}: ActionOutlinedButtonProps) {
  const { t } = useTranslation();
  const labelText = t(label);
  return (
    <LoadingButton
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      loading={loading}
      sx={sx}
      endIcon={endIcon}
    >
      <Typography
        color={disabled ? 'disabled' : color}
        variant="buttonMedium"
        sx={{ opacity: loading ? 0 : 1 }}
      >
        {labelText}
      </Typography>
    </LoadingButton>
  );
}
