import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Link from 'common/Link/Link';
import {
  CostCharts,
  useGetCostsChartsQuery,
} from 'store/costGroups/costGroups';
import { useTranslation } from 'react-i18next';

interface CostsTableProps {
  displayedPeriod: string[];
}

function CostsTable({ displayedPeriod }: CostsTableProps) {
  const { t } = useTranslation();
  const { data: costChart = {} as CostCharts } = useGetCostsChartsQuery();
  const { groupedTransactions, costGroups } = costChart;
  const sortedGroups = Object.keys(costGroups).sort((group, comparedGroup) =>
    costGroups[group].name.localeCompare(costGroups[comparedGroup].name),
  );

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="tableHeader" color="text.secondary">
                {t('label.contractor_group')}
              </Typography>
            </TableCell>
            {displayedPeriod.map((period) => (
              <TableCell key={period}>
                <Typography variant="tableHeader" color="text.secondary">
                  {period}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={displayedPeriod.length} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedGroups.map((group) => (
            <TableRow key={costGroups[group].name}>
              <TableCell>
                <Link
                  color="secondary.main"
                  to={`/costs-charts/${group}`}
                  underline="none"
                >
                  <Typography variant="body2">
                    {costGroups[group].name}
                  </Typography>
                </Link>
              </TableCell>
              {displayedPeriod.map((period) => (
                <TableCell key={period}>
                  <Typography variant="body2">
                    {groupedTransactions[period]
                      ? groupedTransactions[period][group] ?? 0
                      : 0}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CostsTable;
