import React from 'react';
import {
  Box,
  Button,
  DialogContent,
  Divider,
  FormControl,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTypes } from 'enums';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import { useGetBudgetGroupsQuery } from 'store/budgetGroups/budgetGroups';
import { FormPayload, BudgetFormProps } from './interfaces';
import schema from './BudgetForm.schema';
import TypeSelect from './TypeSelect/TypeSelect';
import { budgetTypes } from './budgetSelectData';

const validate = zodResolver(schema);

export default function BudgetForm({
  setIsOpen,
  onSubmit,
  formType,
  isSubmitting,
  initFormValues,
}: BudgetFormProps) {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initFormValues,
  });

  const {
    data: budgetGroups,
    isLoading: budgetGroupsLoading,
    isError: budgetGroupsError,
  } = useGetBudgetGroupsQuery({});

  const { t } = useTranslation();
  const { name, type } = watch();

  const title =
    formType === FormTypes.add
      ? t('budget.add_budget')
      : t('budget.edit_budget');

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) || !name || !type;

  const handleOnSubmit = handleSubmit(onSubmit);

  const budgetGroupsData = budgetGroups?.map((group) => ({
    value: group['@id'],
    label: group.name,
  }));

  return (
    <ContentSuspense
      isLoading={budgetGroupsLoading}
      isError={budgetGroupsError}
    >
      <DialogHeader title={title} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleOnSubmit} noValidate>
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 4 }}
          >
            <ControlTextField
              control={control}
              errors={errors}
              label="label.name"
              name="name"
              required
            />
            <ControlSelect
              control={control}
              errors={errors}
              name="budgetGroup"
              label={t('budget.budget_category_select')}
              selectOptions={budgetGroupsData || []}
              optional
            />
          </FormControl>
          <Divider sx={{ marginY: 6 }} />
          <Stack spacing={1}>
            <Typography variant="subtitle1">
              {t('budget.budget_type')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t('budget.budget_type_description')}
            </Typography>
          </Stack>
          <Stack direction="row" gap={3} mt={3}>
            {budgetTypes.map((budgetType) => (
              <TypeSelect
                key={budgetType.value}
                budgetType={budgetType}
                selectedType={type}
                setSelectedType={setValue}
              />
            ))}
          </Stack>
          <Box display="flex" justifyContent="flex-end" mt={4} gap={3}>
            <Button onClick={() => setIsOpen(false)} variant="text">
              {t('button.cancel')}
            </Button>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddIcon />}
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </ContentSuspense>
  );
}
