import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import getTotalVat from '../helpers/getTotalVat';
import { InvoiceSummaryProps } from './interfaces';

function InvoiceSummary({
  incomeRows,
  disableSubmission,
  isLoading,
  currencyIri,
}: InvoiceSummaryProps) {
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();

  const vatWorth = incomeRows.reduce((acc, { vatRate, netPrice, quantity }) => {
    const totalVat = getTotalVat({
      netPrice: +netPrice,
      quantity: +quantity,
      vatRate,
    });

    return acc + +totalVat;
  }, 0);

  const netWorth = incomeRows.reduce((acc, { netPrice, quantity }) => {
    const worth = (+netPrice * +quantity).toFixed(4);

    return acc + +worth;
  }, 0);
  const grossWorth = netWorth + vatWorth;

  return (
    <Stack alignItems="flex-end" justifyContent="center" spacing={3} px={6}>
      <Stack direction="row" spacing={3} alignItems="center">
        <Typography
          variant="subtitle1"
          width="20rem"
          textAlign="right"
          color="primary.main"
        >
          {t('invoices.total_pay')}
        </Typography>
        <Typography variant="h5" width="20rem" textAlign="right">
          {formatAmount(grossWorth, currencyIri)}
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography width="20rem" textAlign="right" color="text.secondary">
            {t('invoices.net_total')}
          </Typography>
          <Typography
            variant="h6"
            width="20rem"
            textAlign="right"
            color="text.secondary"
          >
            {formatAmount(netWorth, currencyIri)}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography width="20rem" textAlign="right" color="text.secondary">
            {t('invoices.including_vat')}
          </Typography>
          <Typography
            variant="h6"
            width="20rem"
            textAlign="right"
            color="text.secondary"
          >
            {formatAmount(vatWorth, currencyIri)}
          </Typography>
        </Stack>
      </Stack>
      <SubmitButton
        disabled={disableSubmission()}
        isLoading={isLoading}
        testId="invoice-form-submit-button"
      />
    </Stack>
  );
}

export default InvoiceSummary;
