import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import { RootState } from 'store';

export interface ResponseErrors {
  code: string;
  message: string;
  propertyPath: string;
}

export interface CustomError {
  status: number;
  data: {
    ['hydra:description']: string;
    detail: string;
    title: string;
    type: string;
    violations: ResponseErrors[];
  };
}

export const api = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers, store) => {
      const state = store.getState() as RootState;
      const { organization } = state.organization;
      const token = localStorage?.token as string;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      if (organization.instance) {
        headers.set('instance', organization.instance);
      }
      return headers;
    },
    paramsSerializer: (params) =>
      queryString.stringify(params, { arrayFormat: 'bracket' }),
  }) as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {},
    FetchBaseQueryMeta
  >,
  tagTypes: [
    'Employees',
    'Agreements',
    'Projects',
    'Holidays',
    'HolidaysTypes',
    'HolidayRequests',
    'Clients',
    'Countries',
    'Currencies',
    'Addresses',
    'Languages',
    'Banks',
    'Accounts',
    'Auth',
    'BankHolidays',
    'Incomes',
    'Organizations',
    'Costs',
    'HolidayDaysLimits',
    'Positions',
    'Locations',
    'Properties',
    'WorkingHours',
    'GoogleCalendarStatus',
    'GoogleCalendarId',
    'DocumentTypes',
    'Documents',
    'CostGroups',
    'EmployeeProjectRates',
    'PaymentDue',
    'Transactions',
    'ApprovalsCount',
    'HolidaysDaysLimit',
    'PipeDriveLeads',
    'CostsCharts',
    'Benefits',
    'EmployeeBenefits',
    'ClientContacts',
    'Responsibilities',
    'Feedbacks',
    'PropertyBookings',
    'Meetings',
    'ConditionProjects',
    'HolidaysCondition',
    'TaxesCondition',
    'ContractorGroupsCondition',
    'ConditionOrder',
    'CompanyWorkingDays',
    'CompanyDefaultCurrency',
    'CompanyStartDate',
    'InvoicePrefix',
    'CompanyTaxIdNumber',
    'OrganizationBillingAddress',
    'OrganizationBillingPhoneNumber',
    'InvoiceSendCcs',
    'Candidates',
    'TagGroups',
    'ResourceRequests',
    'Tags',
    'TagDefinitions',
    'OrganizationMailFooter',
    'CandidateNotes',
    'ResourceRequestCandidates',
    'OrganizationUsers',
    'OrganizationWeekStartDay',
    'PropertyBookingDaysLimit',
    'Budgets',
    'Budget',
    'BudgetGroups',
    'HolidayTypes',
    'HolidayDaysLimitDefaults',
    'InvoicesSend',
  ],
  endpoints: () => ({}),
});

export default api;
