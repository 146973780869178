import React from 'react';
import { Box, Grid, Typography, Stack, CardMedia } from '@mui/material';
import LogoUrl from 'assets/img/logo.svg';
import BuildingIcon from 'assets/icons/BuildingIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OrganizationDto } from 'store/organizations/interfaces';
import {
  setOrganization,
  setIsOrganizationChoice,
} from 'store/organizations/actions';
import StyledOrganizationButton from './OrganizationButton.styled';

export interface PageOrganizationsProps {
  organizations: OrganizationDto[];
}

export default function Organizations({
  organizations,
}: PageOrganizationsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onChooseOrganizationClick = (organization: OrganizationDto) => {
    localStorage.setItem('selectedOrganizationId', organization.id);
    dispatch(
      setOrganization({
        roles: organization.roles,
        possibleRoles: organization.possibleRoles,
        instance: organization.instance,
        name: organization.name,
        defaultCurrency: organization.defaultCurrency,
        features: organization.features,
        config: organization.config,
        id: organization.id,
      }),
    );
    dispatch(setIsOrganizationChoice(false));
  };

  const organizationsSortedByName = [...organizations].sort(
    (currentOrganization, nextOrganization) =>
      currentOrganization.name.localeCompare(nextOrganization.name),
  );

  return (
    <Grid
      item
      xs={12}
      p={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
    >
      <Box>
        <Box p={3}>
          <CardMedia
            component="img"
            loading="lazy"
            src={LogoUrl}
            sx={{
              width: '10.6rem',
              height: '100%',
            }}
            alt={t('menu.logo')}
          />
        </Box>
        <Stack
          py={8}
          px={{
            xs: 4,
            sm: 8,
            md: 16,
            lg: 32,
          }}
          height="56rem"
          justifyContent="center"
          gap={8}
        >
          <Typography variant="h4">
            {t('organizations.choose_organization')}
          </Typography>
          <Grid container spacing={3}>
            {organizationsSortedByName.map((organization) => (
              <Grid item xs={12} md={6} key={organization.slug}>
                <StyledOrganizationButton
                  component="button"
                  key={organization.slug}
                  onClick={() => onChooseOrganizationClick(organization)}
                  data-testid={`organization-${organization.name}`}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <BuildingIcon
                      sx={{ fontSize: '2rem', color: 'icon.light' }}
                    />
                    <Typography>{organization.name}</Typography>
                  </Stack>
                </StyledOrganizationButton>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Box>
      <Stack p={3}>
        <Typography variant="body2" color="secondary.main" alignSelf="flex-end">
          Flowtly © {new Date().getFullYear()}
        </Typography>
      </Stack>
    </Grid>
  );
}
