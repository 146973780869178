import React from 'react';
import Component from './HolidaysManagement';
import { HolidaysProvider } from './context/HolidaysProvider';

export default function HolidaysManagement() {
  return (
    <HolidaysProvider>
      <Component />
    </HolidaysProvider>
  );
}
