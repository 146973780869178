import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Tab, Stack, IconButton } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import { useParams } from 'react-router';
import { NavBarContainer, NavBarSubtitle, NavBarTitle } from 'common/NavBar';
import { useGetCandidateQuery } from 'store/candidates/candidates';
import useGetResourceRequestsForCandidate from 'hooks/useGetResourceRequestsForCandidate';
import Loader from 'common/Loader';
import ErrorComponent from 'common/Error';
import { Candidate } from 'store/candidates/interfaces';
import DoubleDotIcon from 'assets/icons/DoubleDotIcon';
import CandidateOverview from 'components/HumanResourceCandidate/CandidateOverview/CandidateOverview';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import { HumanResourcesTabValue } from 'pages/HumanResources/HumanResources';
import { CandidateContext } from './context/Candidate.context';

export default function HumanResourceCandidate() {
  const [tabValue, setTabValue] = useState('1');

  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const {
    data: candidate,
    isLoading: candidateLoading,
    isError: candidateError,
    isSuccess: candidateSuccess,
  } = useGetCandidateQuery(id as string);

  const {
    data: resourceRequestsForCandidate,
    isLoading: resourceRequestsForCandidateLoading,
    isError: resourceRequestsForCandidateError,
  } = useGetResourceRequestsForCandidate(id as string);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) =>
    setTabValue(newValue);

  const contextValue = useMemo(
    () => ({
      candidate: candidate as Candidate,
      resourceRequestsForCandidate,
    }),
    [candidate, resourceRequestsForCandidate],
  );

  if (candidateLoading || resourceRequestsForCandidateLoading) {
    return <Loader />;
  }

  if (candidateError || resourceRequestsForCandidateError) {
    return <ErrorComponent />;
  }

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <Stack direction="row" alignItems="center">
          <NavigationBackButton
            to="/human-resources"
            options={{
              state: {
                tabValue: HumanResourcesTabValue.candidates,
              },
            }}
          />
          <Stack>
            <NavBarTitle>
              {candidate?.firstname} {candidate?.lastname}
            </NavBarTitle>
            <NavBarSubtitle>{candidate?.email}</NavBarSubtitle>
          </Stack>
        </Stack>
        <IconButton
          sx={{
            width: '4rem',
            height: '4rem',
          }}
        >
          <DoubleDotIcon
            sx={{
              fontSize: '2rem',
              color: 'icon.dark',
            }}
          />
        </IconButton>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('tab.overview')} value="1" />
        </StyledTabList>
        {candidateSuccess && (
          <CandidateContext.Provider value={contextValue}>
            <TabPanel value="1">
              <CandidateOverview />
            </TabPanel>
          </CandidateContext.Provider>
        )}
      </TabContext>
    </Grid>
  );
}
