import { Popper, PopperProps } from '@mui/material';
import React from 'react';

export default function CandidateAutocompletePopper(props: PopperProps) {
  const { children, anchorEl, className, disablePortal, open, role, style } =
    props;
  return (
    <Popper
      anchorEl={anchorEl}
      className={className}
      disablePortal={disablePortal}
      open={open}
      role={role}
      style={{ ...{ style }, width: '65rem' }}
      placement="bottom-start"
    >
      {children}
    </Popper>
  );
}
