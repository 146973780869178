import React from 'react';
import { Typography, Stack, Avatar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { EmployeePreview } from 'store/employee/interfaces';

interface AppointmentContentMiddleProps {
  employeeData: EmployeePreview;
  dateFrom: string;
  dateTo: string;
  isBookingRemovable: boolean;
  isInDraftMode: boolean;
  isDraftBookingPossibleToAdd: boolean;
  handleAddPropertyBooking: () => void;
  handleCancelPropertyBooking: () => void;
}

export default function AppointmentContentMiddle({
  dateFrom,
  dateTo,
  employeeData,
  isBookingRemovable,
  isInDraftMode,
  isDraftBookingPossibleToAdd,
  handleCancelPropertyBooking,
  handleAddPropertyBooking,
}: AppointmentContentMiddleProps) {
  return (
    <Stack px={1.5}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={0.25}
      >
        {isInDraftMode ? (
          <Typography variant="caption" color="main.white">
            {dateFrom}
          </Typography>
        ) : (
          <Avatar
            sx={{
              width: '2.4rem',
              height: '2.4rem',
            }}
            src={employeeData?.avatarUrl || ''}
          />
        )}
        <Stack alignItems="center" direction="row">
          {isBookingRemovable && (
            <CloseIcon
              sx={{ color: 'main.white' }}
              onClick={handleCancelPropertyBooking}
            />
          )}
        </Stack>
      </Stack>
      {isInDraftMode ? (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <IconButton
            onClick={handleAddPropertyBooking}
            disabled={!isDraftBookingPossibleToAdd}
            sx={{
              width: '2rem',
              height: '2rem',
            }}
          >
            <SaveIcon
              sx={{
                color: isDraftBookingPossibleToAdd
                  ? 'main.white'
                  : 'status.notActive.main',
                fontSize: '2rem',
              }}
            />
          </IconButton>
        </Stack>
      ) : (
        <Typography variant="caption" color="main.white">
          {`${dateFrom} ${dateTo}`}
        </Typography>
      )}
    </Stack>
  );
}
