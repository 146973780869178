import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import { WorkingHours } from 'store/workingHours/workingHours';
import ProjectData from 'components/CalendarDayTile/ProjectData/ProjectData';
import BorderColorIcon from '@mui/icons-material/BorderColor';

export interface DetailsProps {
  date: Date;
  workingHours: WorkingHours[];
  setIsEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEntryId: React.Dispatch<React.SetStateAction<number>>;
  userCanEdit: boolean;
}
function Details({
  date,
  workingHours,
  setIsEditFormOpen,
  setEntryId,
  userCanEdit,
}: DetailsProps) {
  return (
    <Box p={2} minWidth="20rem">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" color="text.primary">
          {dateFormat(date, 'dd mmmm')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} mt={3}>
        {workingHours.map((hours) => (
          <Box display="flex" flexDirection="column" gap={2} key={hours.id}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <ProjectData
                projectIri={hours.project}
                projectPhaseIri={hours.projectPhase}
                isExtended
              />
              <Box display="flex" alignItems="center" gap={2}>
                <Typography variant="bold" color="text.primary">
                  {hours.hours}h
                </Typography>
                {userCanEdit && (
                  <IconButton
                    onClick={() => {
                      setIsEditFormOpen(true);
                      setEntryId(hours.id);
                    }}
                  >
                    <BorderColorIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
            <Typography variant="body1" color="text.secondary">
              {hours.description}
            </Typography>
            <Divider />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Details;
