import { RequestQuote } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import dateFormat from 'dateformat';
import fullDateFormat from 'helpers/fullDateFormat';
import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Transaction } from 'store/transactions/transactions';

interface TransactionTooltipProps {
  transaction: Transaction;
}

export default function TransactionTooltip({
  transaction,
}: TransactionTooltipProps) {
  const navigate = useNavigate();

  const handleRedirectToTransaction = () => {
    navigate(
      {
        pathname: `/transactions/${transaction.bankAccount}`,
        search: createSearchParams({
          cost: String(transaction.cost.id),
          'execDate[after]': fullDateFormat(transaction.execDate),
          'execDate[before]': fullDateFormat(transaction.execDate),
          'amount[gte]': String(transaction.amount),
          'amount[lte]': String(transaction.amount),
        }).toString(),
      },
      {
        replace: true,
      },
    );
  };

  const getPaymentTitle = () => {
    if (!transaction.relatedDate) {
      return `#${transaction.id} ${dateFormat(
        transaction.orderDate,
        'yyyy-mm-dd',
      )}`;
    }

    return `#${transaction.id} ${dateFormat(
      transaction.relatedDate,
      'yyyy-mm-dd',
    )}`;
  };

  return (
    <TitleTooltip title={getPaymentTitle()}>
      <IconButton onClick={handleRedirectToTransaction}>
        <RequestQuote fontSize="large" />
      </IconButton>
    </TitleTooltip>
  );
}
