export const initValues = {
  name: '',
  type: '',
  costGroup: '',
  status: '',
  tin: '',
  matchRegex: '',
  description: '',
  ownerEmployee: '',
  budget: '',
  notIncludeInChart: false,
  fixedAmount: false,
  requireAttachment: false,
  cyclic: false,
  taxAmount: '',
  paymentDeadline: '',
};
export default initValues;
