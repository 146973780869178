import React from 'react';
import { Box } from '@mui/material';
import { MonthView } from '@devexpress/dx-react-scheduler-material-ui';

export default function TimeTableLayoutComponent(
  props: MonthView.TimeTableLayoutProps,
) {
  return (
    <Box sx={{
      '& .MuiTableCell-root .Cell-text': {
        width: '100%',
        textAlign: 'left',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '2rem',
        fontWeight: 600,
        color: 'text.secondary',
        '&.Cell-otherMonth': {
          color: 'text.disabled',
        }
      },
      '& .MuiTableCell-root .Cell-today': {
        width: '100%',
        marginTop: 0,
        padding: '0.3rem 2rem 0',
        textAlign: 'left',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '2rem',
        fontWeight: 600,
        color: 'primary.main',
        background: 'transparent',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '0.6rem',
          left: '4.1rem',
          right: 0,
          bottom: 0,
          width: '0.6rem',
          height: '0.6rem',
          borderRadius: '50%',
          backgroundColor: 'primary.main',
        },
      }
    }}>
      <MonthView.TimeTableLayout {...props} />
    </Box>
  );
}
