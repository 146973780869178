import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ContractorGroupsCondition as GroupsConditionProps } from 'store/condition/interfaces';
import { useGetCostGroupsQuery } from 'store/costGroups/costGroups';
import Loader from 'common/Loader';
import Error from 'common/Error';
import getMonthsRange from 'helpers/getMonthsRange';
import { useTranslation } from 'react-i18next';
import ConditionBalance from 'common/ConditionBalance/ConditionBalance';
import GroupCondition from './GroupCondition/GroupCondition';
import { calculateMonthlyBalance } from './utils';

interface ContractorGroupsConditionProps {
  groupsCondition: GroupsConditionProps;
  order: string[];
  section: string;
  sectionHeader: string;
}

export default function ContractorGroupsCondition({
  groupsCondition,
  order,
  section,
  sectionHeader,
}: ContractorGroupsConditionProps) {
  const { t } = useTranslation();
  const currentDate = new Date();
  const dateRange = getMonthsRange(currentDate, 2, 1);
  const {
    data: contractorGroups = [],
    isLoading: groupsFetching,
    isError: groupsFetchError,
  } = useGetCostGroupsQuery();
  const groupsIds = Object.values(groupsCondition).flatMap((group) =>
    Object.keys(group),
  );
  const uniqueGroups = [...new Set(groupsIds)];

  if (groupsFetching) {
    return <Loader />;
  }

  if (groupsFetchError) {
    return <Error />;
  }

  return (
    <Grid container columns={13} p={2} rowSpacing={2}>
      <Grid item xs={13}>
        <Typography textTransform="uppercase" variant="body2">
          {t(sectionHeader)}
        </Typography>
      </Grid>
      {uniqueGroups.map((group) => (
        <GroupCondition
          key={group}
          groupId={+group}
          groups={contractorGroups}
          groupsCondition={groupsCondition}
        />
      ))}
      <Grid container columns={13} pt={2}>
        <Grid item xs={1} />
        {dateRange.map((month) => (
          <ConditionBalance
            key={month}
            month={month}
            order={order}
            section={section}
            sectionBalance={calculateMonthlyBalance(month, groupsCondition)}
          />
        ))}
      </Grid>
    </Grid>
  );
}
