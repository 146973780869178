import React from 'react';
import { createSvgIcon } from '@mui/material';

const DeleteIcon = createSvgIcon(
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.43586 3.19431C4.76404 1.71749 6.07391 0.666748 7.58675 0.666748H10.4119C11.9248 0.666748 13.2347 1.71749 13.5628 3.19431C13.7216 3.90856 14.3551 4.41675 15.0868 4.41675H16.4993C16.9596 4.41675 17.3327 4.78984 17.3327 5.25008C17.3327 5.71032 16.9596 6.08341 16.4993 6.08341H15.5517L14.4915 13.2394C14.4057 13.8185 14.3345 14.2991 14.2458 14.69C14.1531 15.098 14.0292 15.4684 13.8079 15.8105C13.4618 16.3454 12.9698 16.77 12.3899 17.0339C12.019 17.2027 11.6345 17.271 11.2173 17.3028C10.8177 17.3333 10.3318 17.3333 9.7464 17.3333H8.2523C7.66693 17.3333 7.18104 17.3333 6.7814 17.3028C6.36421 17.271 5.97968 17.2027 5.60877 17.0339C5.02893 16.77 4.53686 16.3454 4.19083 15.8105C3.96949 15.4684 3.84557 15.098 3.75292 14.69C3.66418 14.2991 3.59298 13.8185 3.5072 13.2394L2.44704 6.08341H1.49935C1.03911 6.08341 0.666016 5.71032 0.666016 5.25008C0.666016 4.78984 1.03911 4.41675 1.49935 4.41675H2.91194C3.64362 4.41675 4.27714 3.90856 4.43586 3.19431ZM4.1319 6.08341L5.15127 12.9641C5.24283 13.5821 5.30523 13.9995 5.37822 14.3209C5.4489 14.6322 5.51708 14.7922 5.59021 14.9053C5.76323 15.1727 6.00926 15.385 6.29918 15.5169C6.42174 15.5727 6.58999 15.6167 6.90826 15.641C7.23695 15.6661 7.65897 15.6667 8.28374 15.6667H9.71495C10.3397 15.6667 10.7617 15.6661 11.0904 15.641C11.4087 15.6167 11.577 15.5727 11.6995 15.5169C11.9894 15.385 12.2355 15.1727 12.4085 14.9053C12.4816 14.7922 12.5498 14.6322 12.6205 14.3209C12.6935 13.9995 12.7559 13.5821 12.8474 12.9641L13.8668 6.08341H4.1319ZM12.2612 4.41675H5.73749C5.88377 4.15198 5.99455 3.86314 6.06284 3.55586C6.22156 2.8416 6.85507 2.33341 7.58675 2.33341H10.4119C11.1436 2.33341 11.7771 2.8416 11.9359 3.55586C12.0041 3.86314 12.1149 4.15198 12.2612 4.41675Z"
      fill="currentColor"
    />
  </svg>,
  'DeleteIcon',
);

export default DeleteIcon;
