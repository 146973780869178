import React, { useState } from 'react';
import ReactEChart from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Box, Chip } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import useBudgetPlannerContext from 'pages/BudgetPlanner/context/useBudgetPlannerContext';
import getCharOptions from 'config/material-ui/getChartOptions';
import useFormatBudgetChartPeriod from 'hooks/useFormatBudgetChartPeriod';
import { EchartOnClickEvent } from './interfaces';
import BudgetConfigPeriodForm from './BudgetConfigPeriodForm/BudgetConfigPeriodForm';
import calculateTotalAmountBudgetConfig from './calculateTotalAmountBudgetConfig';
import StyledBarHeaderWrapper from './BarHeaderWrapper.styled';
import BarHeader from './BarHeader/BarHeader';
import getBadgeStyles, { BadgeType } from './getBadgeStyles';
import DialogOverlay from './DialogOverlay/DialogOverlay';
import { SelectedBar } from './DialogOverlay/interfaces';

const growthTypes: BadgeType[] = ['exponential', 'linear', 'unit'];
const firstBaseDefault = 1000;

export default function BudgetPlannerChart() {
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();
  const formatPeriod = useFormatBudgetChartPeriod();
  const { budgetConfigData, onUpdateBudgetConfig } = useBudgetPlannerContext();

  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [selectedBar, setSelectedBar] = useState<SelectedBar | null>(null);

  const selectedPeriodData = budgetConfigData.find(
    (data) => data.period === selectedPeriod,
  );
  const isSelectedFirstPeriod =
    selectedPeriodData &&
    selectedPeriodData?.period === budgetConfigData[0].period;

  const xAxisData = budgetConfigData.map((data) => data.period);
  const seriesData = budgetConfigData.map((data) =>
    calculateTotalAmountBudgetConfig(data),
  );

  const chartOptions = getCharOptions({
    xAxisData,
    seriesData,
    seriesName: t('budget.budget_amount'),
    xAxisLabelFormatter: formatPeriod,
  });
  const budgetPeriodsTotalAmount = +budgetConfigData.reduce(
    (totalAmount, budgetConfig) =>
      totalAmount + +calculateTotalAmountBudgetConfig(budgetConfig),
    0,
  );

  const onChartClick = (event: EchartOnClickEvent) => {
    setSelectedBar(event.event.target.shape);
    setSelectedPeriod(event.name);
  };

  const handleOnClose = () => {
    setSelectedPeriod(null);
    setSelectedBar(null);
    if (isSelectedFirstPeriod && !selectedPeriodData.baseAmount) {
      onUpdateBudgetConfig({
        period: selectedPeriodData.period,
        field: 'baseAmount',
        value: firstBaseDefault.toString(),
      });
    }
  };

  return (
    <Box>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack spacing={1.5}>
          <Typography variant="h5">{t('budget.set_your_budget')}</Typography>
          <Typography color="text.secondary">
            {t('budget.set_budget_description')}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={6}>
          <Stack direction="row" spacing={1} alignItems="center">
            {growthTypes.map((type) => (
              <Chip
                key={type}
                variant="outlined"
                size="small"
                sx={getBadgeStyles(type)}
                label={
                  <Typography variant="chipLabel">
                    {t(`budget.${type}_growth`)}
                  </Typography>
                }
              />
            ))}
          </Stack>
          <Stack>
            <Typography variant="body2" color="text.secondary">
              {t('budget.budget_total')}
            </Typography>
            <Typography variant="h5">
              {formatAmount(budgetPeriodsTotalAmount)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <StyledBarHeaderWrapper
        display={{
          xs: 'none',
          xl: 'flex',
        }}
      >
        {budgetConfigData.map((data) => (
          <BarHeader key={data.period} budgetConfig={data} />
        ))}
      </StyledBarHeaderWrapper>
      <Box height="50.6rem">
        <ReactEChart
          option={chartOptions}
          style={{ width: '100%', height: '100%' }}
          onEvents={{
            click: onChartClick,
          }}
        />
      </Box>
      {selectedBar && (
        <DialogOverlay
          open={!!selectedPeriod}
          onClose={handleOnClose}
          selectedBar={selectedBar}
        >
          {selectedPeriodData && (
            <BudgetConfigPeriodForm
              onClose={handleOnClose}
              selectedPeriodData={selectedPeriodData}
            />
          )}
        </DialogOverlay>
      )}
    </Box>
  );
}
