import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Responsibility } from 'store/responsibilityGroups/interfaces';
import { LoadingButton } from '@mui/lab';
import { useUpdateResponsibilityMutation } from 'store/responsibilityGroups/responsibilityGroups';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';

export interface ResponsibilityDetailsProps extends StatusProps {
  responsibility: Responsibility;
}

export default function ResponsibilityDetails({
  responsibility,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: ResponsibilityDetailsProps) {
  const { t } = useTranslation();
  const [name, setName] = useState(responsibility.name);
  const [description, setDescription] = useState(
    responsibility.description || '',
  );
  const [updateResponsibility, { isLoading }] =
    useUpdateResponsibilityMutation();

  const handleUpdateResponsibility = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateResponsibility({
        id: responsibility.id,
        name,
        description,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <form onSubmit={handleUpdateResponsibility}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap={3}
        mt={3}
      >
        <TextField
          fullWidth
          label={t('label.name')}
          name="name"
          required
          onChange={(event) => setName(event.target.value)}
          value={name}
        />
        <TextField
          fullWidth
          label={t('label.description')}
          minRows={3}
          multiline
          name="description"
          onChange={(event) => setDescription(event.target.value)}
          required
          value={description}
        />
        <LoadingButton
          disabled={!name || !description}
          endIcon={<CheckIcon />}
          loading={isLoading}
          type="submit"
          variant="contained"
        >
          {t('button.save')}
        </LoadingButton>
      </Box>
    </form>
  );
}
