import React from 'react';
import { Box } from '@mui/material';
import { DragDropProvider } from '@devexpress/dx-react-scheduler-material-ui';
import AppointmentContentComponent from 'components/BookProperties/BookingForm/MinutesBookingScheduler/Appointments/AppointmentContentComponent';

interface DraftAppointmentComponentProps
  extends DragDropProvider.DraftAppointmentProps {
  durationType: string;
}

export default function DraftAppointmentComponent({
  style,
  durationType,
  data,
}: DraftAppointmentComponentProps) {
  return (
    <Box style={style} sx={{ bgcolor: 'primary.main', borderRadius: '0.8rem' }}>
      <AppointmentContentComponent data={data} durationType={durationType} />
    </Box>
  );
}
