import { ApiResponse } from 'store/interfaces';

export enum CandidateNoteType {
  user = 'user',
  system = 'system',
}

export interface CandidateNote {
  '@id': string;
  id: string;
  employee: string;
  candidate: string;
  note: string;
  type: CandidateNoteType;
  createdAt: string;
  updatedAt: string;
}

export interface CandidateNotesData extends ApiResponse {
  'hydra:member': CandidateNote[];
}

export interface CandidateNotesFilters {
  employee: string[] | string;
  candidate: string[] | string;
}

export interface CandidateNotePostMutationRequest
  extends Omit<
    CandidateNote,
    '@id' | 'id' | 'createdAt' | 'updatedAt' | 'employee' | 'type'
  > {}

export interface CandidateNotePatchMutationRequest
  extends Partial<
    Omit<CandidateNote, '@id' | 'createdAt' | 'updatedAt' | 'employee' | 'type'>
  > {}
