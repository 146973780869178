import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useTranslation } from 'react-i18next';
import InvoicesList from 'components/InvoicesList/InvoicesList';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useNavigate } from 'react-router-dom';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import AddButton from 'common/AddButton/AddButton';
import Link from 'common/Link/Link';
import EmptyBankAccountsList from 'components/EmptyBankAccountsList/EmptyBankAccountsList';
import { useGetAccountsQuery } from 'store/accounts/accounts';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';

export default function Invoices() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selected, handleChange, isSelected, setSelected } =
    useMultipleCheckboxSelect<string>([]);
  const {
    data: bankAccounts = [],
    isLoading: bankAccountsLoading,
    isError: bankAccountsError,
  } = useGetAccountsQuery();

  const noBankAccounts = !bankAccounts.length && !bankAccountsLoading;

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <Stack direction="row" alignItems="center" spacing={3}>
          <NavBarTitle dataTestId="invoices_title">
            {t('invoices.header')}
          </NavBarTitle>
          <Link to="/income-chart" underline="none">
            <Stack direction="row" alignItems="center" spacing={1}>
              <BarChartIcon color="primary" />
              <Typography variant="body1">
                {t('invoices.income_chart')}
              </Typography>
            </Stack>
          </Link>
        </Stack>
        {!noBankAccounts && !bankAccountsLoading && (
          <AddButton
            onClick={() => navigate('/invoices/add')}
            label="invoices.add_invoice"
          />
        )}
      </NavBarContainer>
      <ContentSuspense
        isLoading={bankAccountsLoading}
        isError={bankAccountsError}
      >
        {noBankAccounts ? (
          <EmptyBankAccountsList />
        ) : (
          <InvoicesList
            handleChange={handleChange}
            isSelected={isSelected}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </ContentSuspense>
    </Grid>
  );
}
