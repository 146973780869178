import colors from 'config/material-ui/colors';

const chartsPallette = [
  colors.HALF_SMOKE_700,
  colors.KHMER_CURRY_700,
  colors.VENETIAN_NIGHTS_700,
  colors.VENETIAN_NIGHTS_1200,
];

export default chartsPallette;
