import React, { useState } from 'react';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import RequestedHolidays from 'components/RequestedHolidays';
import HolidaysList from 'components/HolidaysList';
import {
  useGetHolidaysQuery,
  useGetHolidayRequestsQuery,
  useAddHolidayMutation,
} from 'store/holidays/holidays';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import EventIcon from '@mui/icons-material/Event';
import StyledForwardLink from 'pages/HolidaysManagement/Link.styled';
import useCheckRoles from 'hooks/useCheckRoles';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import roles from 'config/roles/roles';
import useHolidaysContext from 'pages/HolidaysManagement/context/useHolidaysContext';
import HolidayForm from 'components/HolidayForm/HolidayForm';
import { FormTypes } from 'enums';
import { useAppDispatch } from 'store/hooks';
import { setSuccessStatus, setErrorCatch } from 'store/status/actions';
import { FormPayload } from 'components/HolidayForm/interfaces';
import useHolidayTypes from 'hooks/useHolidayTypes';
import holidaysSettings from 'helpers/holidaysSettings';

function HolidaysManagement() {
  const { t } = useTranslation();
  const { searchParams, isSettingSearchParams } = useHolidaysContext();
  const dispatch = useAppDispatch();

  const {
    data: holidays = {
      'hydra:member': [],
    },
    isFetching: holidaysFetching,
    isError: holidaysError,
  } = useGetHolidaysQuery(
    {
      ...(searchParams.get('employee') && {
        employee: searchParams.get('employee') ?? '',
      }),
      ...(!!searchParams.getAll('type[]').length && {
        type: searchParams.getAll('type[]'),
      }),
      ...(searchParams.get('dateTo[after]') && {
        'dateTo[after]': searchParams.get('dateTo[after]') ?? '',
      }),
    },
    {
      skip: isSettingSearchParams,
    },
  );
  const { activeHolidayTypes, holidayTypesLoading, holidayTypesError } =
    useHolidayTypes();
  const [isAddFormDialogOpen, setIsAddFormDialogOpen] = useState(false);

  const {
    data: requests = [],
    isError: requestsError,
    isLoading: requestsFetching,
  } = useGetHolidayRequestsQuery({});
  const { isLoading: employeesFetching, isError: employeesFetchError } =
    useGetEmployeesPreview({ isActive: true });
  const isManager = useCheckRoles(roles.bankHolidays);

  const [addHoliday, { isLoading: addHolidayLoading }] =
    useAddHolidayMutation();

  const handleAddHoliday = async (data: FormPayload) => {
    const { dateFrom, dateTo, hours, ...restPayload } = data;

    const shouldAddHours =
      hours &&
      +hours >= holidaysSettings.minLimitHolidayHours &&
      +hours <= holidaysSettings.maxLimitHolidayHours;

    try {
      await addHoliday({
        dateFrom: dateFormat(dateFrom, 'yyyy-mm-dd'),
        dateTo: dateFormat(dateTo, 'yyyy-mm-dd'),
        ...(shouldAddHours && { hours: +hours }),
        ...restPayload,
      }).unwrap();
      dispatch(setSuccessStatus(true));
      setIsAddFormDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <>
      <Grid item xs={10} bgcolor="background.list">
        <NavBarContainer>
          <Box display="flex" alignItems="center" columnGap={3}>
            <NavBarTitle dataTestId="holidays_title">
              {t('holidays.holidays_management')}
            </NavBarTitle>
            {isManager && (
              <StyledForwardLink to="/bank-holidays">
                <Box display="flex" columnGap={0.5} alignItems="center">
                  <Typography variant="h6">
                    {t('bank_holidays.header')}
                  </Typography>
                  <EventIcon fontSize="large" />
                </Box>
              </StyledForwardLink>
            )}
          </Box>
          {isManager && (
            <Button
              variant="contained"
              onClick={() => setIsAddFormDialogOpen(!isAddFormDialogOpen)}
              endIcon={<AddCircleOutlineIcon />}
            >
              {t('button.create')}
            </Button>
          )}
        </NavBarContainer>
        <Box py={2} px={3}>
          <RequestedHolidays
            requests={requests}
            isError={requestsError || employeesFetchError}
            isFetching={requestsFetching || employeesFetching}
          />
          <HolidaysList
            holidays={holidays['hydra:member']}
            holidayTypes={activeHolidayTypes}
            isError={holidaysError || employeesFetchError || holidayTypesError}
            isFetching={
              holidaysFetching || employeesFetching || holidayTypesLoading
            }
          />
        </Box>
      </Grid>
      <Dialog
        open={isAddFormDialogOpen}
        onClose={() => setIsAddFormDialogOpen(!isAddFormDialogOpen)}
        fullWidth
      >
        <HolidayForm
          onSubmit={handleAddHoliday}
          isSubmitting={addHolidayLoading}
          formType={FormTypes.add}
          setIsDialogOpen={setIsAddFormDialogOpen}
        />
      </Dialog>
    </>
  );
}

export default HolidaysManagement;
