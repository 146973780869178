import { zodResolver } from '@hookform/resolvers/zod';
import {
  DialogContent,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import holidayTypes from 'components/EmployeeHolidayForm/holidayTypes';
import { HolidayTypeStatus, HolidayTypes } from 'store/holidayTypes/interfaces';
import { useGetHolidayTypesQuery } from 'store/holidayTypes/holidayTypes';
import { FormTypes } from 'enums';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import typeFormSchema from './TypeForm.schema';
import { TypeFormPayload, TypeFormProps } from './interfaces';
import adaptHolidayTypesToSelectOptions from './utils';

const validate = zodResolver(typeFormSchema);

export default function TypeForm({
  setIsOpen,
  initFormValues,
  onSubmit,
  isSubmitting,
  formType,
}: TypeFormProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    setValue,
  } = useForm<TypeFormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initFormValues,
  });
  const { data: existingTypesData = {} as HolidayTypes } =
    useGetHolidayTypesQuery();
  const existingTypes = existingTypesData['hydra:member'] ?? [];
  const typeOptions = adaptHolidayTypesToSelectOptions(holidayTypes);
  const { name, id, status } = watch();

  const header =
    formType === FormTypes.add
      ? 'holiday_types.add_type'
      : 'holiday_types.edit_type';

  const notExistingTypes = typeOptions.filter(
    (type) =>
      !existingTypes.some((existingType) => existingType.id === type.value),
  );

  const possibleOptions =
    formType === FormTypes.add ? notExistingTypes : typeOptions;

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) || !name || !id || !isDirty;

  const handleSwitchChange = () => {
    if (status === HolidayTypeStatus.active) {
      setValue('status', HolidayTypeStatus.inactive, {
        shouldDirty: true,
      });
    } else {
      setValue('status', HolidayTypeStatus.active, {
        shouldDirty: true,
      });
    }
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  return (
    <>
      <DialogHeader title={t(header)} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleFormSubmit} noValidate>
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 4 }}
          >
            <ControlTextField
              control={control}
              name="name"
              label="label.name"
              errors={errors}
              required
            />
            <ControlSelect
              control={control}
              disabled={formType === FormTypes.edit}
              errors={errors}
              name="id"
              label="label.id"
              selectOptions={possibleOptions}
              required
            />
            <Controller
              control={control}
              name="status"
              render={() => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={status === HolidayTypeStatus.active}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={
                    <Typography variant="body2">{t('label.active')}</Typography>
                  }
                  labelPlacement="start"
                  sx={{
                    justifyContent: 'flex-end',
                    ml: 0,
                    width: 'fit-content',
                  }}
                />
              )}
            />
          </FormControl>
          <Stack alignItems="flex-end" mt={4}>
            <SubmitButton
              isLoading={isSubmitting}
              disabled={disableSubmission()}
            />
          </Stack>
        </form>
      </DialogContent>
    </>
  );
}
