import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

interface ListElementProps {
  header?: string;
  description?: string;
  itemPadding: number;
}

export default function ListElement({
  header,
  description,
  itemPadding,
}: ListElementProps) {
  const [expanded, setExpanded] = useState(false);
  const trimmedDescription = description?.trim();

  if (!description || !trimmedDescription?.length) {
    return (
      <ListItem sx={{ pl: itemPadding }}>
        <ListItemText primary={header} />
      </ListItem>
    );
  }

  return (
    <>
      <ListItemButton
        onClick={() => setExpanded((prevState) => !prevState)}
        sx={{ pl: itemPadding }}
      >
        <ListItemText primary={header} />
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div">
          <ListItem sx={{ pl: itemPadding }}>
            <ListItemText
              disableTypography
              primary={
                <Typography fontStyle="italic" variant="body1">
                  {description}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}
