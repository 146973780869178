import React, { useState } from 'react';
import {
  Box,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import { Responsibility } from 'store/responsibilityGroups/interfaces';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import { useDeletePersonMutation } from 'store/responsibilityGroups/responsibilityGroups';
import { CustomError } from 'store/api';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import ResponsibilityDetails from './ResponsibilityDetails';
import People from './People';

export interface EditResponsibilityProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  responsibility: Responsibility;
}

export default function EditResponsibility({
  setIsOpen,
  responsibility,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditResponsibilityProps) {
  const { t } = useTranslation();
  const { data: employees = [] } = useGetEmployeesPreview();
  const [deletePerson] = useDeletePersonMutation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [selectedPerson, setSelectedPerson] = useState(0);

  const getEmployeeName = (employeeIri: string) => {
    const employeeData = employees.find(
      (employee) => employee['@id'] === employeeIri,
    );

    return `${employeeData?.lastname} ${employeeData?.firstname}`;
  };

  const handleDeletePerson = async (personId: number) => {
    try {
      await deletePerson(personId).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('responsibilities.edit')} setIsOpen={setIsOpen} />
      <DialogContent>
        <ResponsibilityDetails responsibility={responsibility} />
        <Divider sx={{ my: 3 }} />
        <Typography variant="subtitle1">{t('label.people')}</Typography>
        <People responsibility={responsibility} />
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          {responsibility.responsibilityEmployees?.map((employee) => (
            <Box
              key={employee.id}
              display="flex"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Typography variant="subtitle1">
                  {employee.percentage}%
                </Typography>
                <Typography variant="body1">
                  {getEmployeeName(employee.employee)}
                </Typography>
              </Box>
              <IconButton
                onClick={() => {
                  setSelectedPerson(employee.id);
                  setIsConfirmationDialogOpen(true);
                }}
              >
                <DeleteOutlineIcon fontSize="large" />
              </IconButton>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleAccept={() => handleDeletePerson(selectedPerson)}
      />
    </>
  );
}
