import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { differenceInMonths, startOfYear } from 'date-fns';
import useBudgetPeriodContext from 'pages/BudgetPeriod/context/useBudgetPeriodContext';
import { BudgetPeriod } from 'store/budget/interfaces';
import TopResize from './TopResize';
import BottomResize from './BottomResize';
import RightResize from './RightResize';
import { yearBlockHeight, yearBlockWidth } from '../consts';
import useBudgetPeriodSelectorContext from '../context/useBudgetPeriodSelectorContext';
import useIndicatorResize from './useIndicatorResize';

interface PeriodRangeIndicatorProps {
  periodSelectorWidth: number;
}

export default function PeriodRangeIndicator({
  periodSelectorWidth,
}: PeriodRangeIndicatorProps) {
  const { isCreatingPeriod, setIsCreatingPeriod } =
    useBudgetPeriodSelectorContext();
  const { periodState } = useBudgetPeriodContext();

  const [top, setTop] = useState(0);
  const boxRef = useRef<HTMLDivElement>(null);

  const yearPeriodWidth = periodSelectorWidth;
  const quarterPeriodWidth = yearPeriodWidth - yearBlockWidth;
  const monthPeriodWidth = yearPeriodWidth - 2 * yearBlockWidth;

  const {
    handleResizeBottom,
    handleResizeTop,
    handleResizeRight,
    resizableHeight,
    resizableWidth,
  } = useIndicatorResize({
    boxRef,
    monthPeriodWidth,
    quarterPeriodWidth,
    yearPeriodWidth,
    top,
    setTop,
  });

  const getWidth = () => {
    if (resizableWidth !== null) {
      return resizableWidth;
    }

    switch (periodState?.period) {
      case BudgetPeriod.year:
        return yearPeriodWidth;
      case BudgetPeriod.quarter:
        return quarterPeriodWidth;
      default:
        return monthPeriodWidth;
    }
  };

  const getHeight = () => {
    if (resizableHeight !== null) return resizableHeight;
    if (!periodState.dateFrom || !periodState.dateTo) return 0;

    const dateFrom = new Date(periodState.dateFrom);
    const dateTo = new Date(periodState.dateTo.getTime() + 1);

    return (yearBlockHeight * differenceInMonths(dateTo, dateFrom)) / 12;
  };

  useEffect(() => {
    if (!periodState.dateFrom) {
      return;
    }

    const dateFrom = new Date(periodState.dateFrom);

    setTop(
      (differenceInMonths(dateFrom, startOfYear(new Date())) *
        yearBlockHeight) /
        12,
    );
  }, [periodState.dateFrom]);

  return (
    <Box
      ref={boxRef}
      sx={{
        position: 'absolute',
        zIndex: 1,
        top,
        left: 0,
        height: getHeight(),
        width: getWidth(),
        backgroundColor: 'primary.main',
        borderRadius: '0.8rem',
        overflow: 'auto',
      }}
    >
      <TopResize onMouseDown={handleResizeTop} />
      <BottomResize
        sx={{
          height: isCreatingPeriod ? '100%' : '10px',
        }}
        onMouseDown={handleResizeBottom}
        onMouseUp={() => setIsCreatingPeriod(false)}
        onMouseLeave={(event) => {
          if (!isCreatingPeriod) {
            return;
          }
          handleResizeBottom(event);
        }}
      />
      <RightResize onMouseDown={handleResizeRight} />
    </Box>
  );
}
