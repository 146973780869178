import React from 'react';
import { Grid, Typography } from '@mui/material';
import { getCostName } from 'components/ContractorGroupsCondition/utils';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { ContractorGroupsCondition } from 'store/condition/interfaces';
import { useGetCostsQuery } from 'store/costs/costs';

interface CostDataProps {
  groupsCondition: ContractorGroupsCondition;
  costId: number;
  dateRange: string[];
  groupId: number;
}

export default function CostData({
  groupsCondition,
  costId,
  dateRange,
  groupId,
}: CostDataProps) {
  const { data: costs = [] } = useGetCostsQuery({ properties: ['id', 'name'] });
  const costName = getCostName(costs, costId);
  const formatAmount = useCurrencyFormat();

  return (
    <Grid container columns={13} alignItems="center">
      <Grid item xs={2.8}>
        <Typography variant="bold">{costName}</Typography>
      </Grid>
      {dateRange.map((month) => (
        <React.Fragment key={month}>
          <Grid item xs={1.2}>
            <Typography variant="smallBody">
              {formatAmount(groupsCondition[month]?.[groupId]?.[costId]?.cost)}
            </Typography>
          </Grid>
          <Grid item xs={1.8} />
        </React.Fragment>
      ))}
    </Grid>
  );
}
