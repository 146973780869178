import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateMeetingMutation } from 'store/meetings/meetings';
import { WidgetContainer, WidgetTitle } from 'common/Widget';
import useMeetingContext from 'pages/Meeting/context/useMeetingContext';
import { CustomError } from 'store/api';
import NotesForm from 'common/NotesForm/NotesForm';
import { FormPayload } from 'common/NotesForm/interfaces';
import { NotesProps } from './interfaces';

export default function Notes({ setIsErrorCatch, setIsSuccess }: NotesProps) {
  const { t } = useTranslation();
  const { meeting, meetingError, meetingLoading } = useMeetingContext();
  const [updateMeeting, { isLoading: updateMeetingLoading }] =
    useUpdateMeetingMutation();

  const handleOnSubmit = async (data: FormPayload) => {
    try {
      await updateMeeting({
        id: meeting.id,
        notes: data.note,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  return (
    <WidgetContainer
      isError={meetingError}
      isLoading={meetingLoading}
      title={
        <WidgetTitle size="lg" noMargin>
          {t('inputs.notes')}
        </WidgetTitle>
      }
    >
      <NotesForm
        initValues={{ note: meeting?.notes || '' }}
        onSubmit={handleOnSubmit}
        isSubmitting={updateMeetingLoading}
      />
    </WidgetContainer>
  );
}
