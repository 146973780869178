import React from 'react';
import { Responsibility } from 'store/responsibilityGroups/interfaces';
import ListElement from '../ListElement/ListElement';

interface ResultProps {
  displayedResponsibility: Responsibility;
  groupIri: string;
}

export default function Result({
  displayedResponsibility,
  groupIri,
}: ResultProps) {
  if (
    !displayedResponsibility ||
    displayedResponsibility?.responsibilityGroup !== groupIri
  ) {
    return null;
  }

  if (!displayedResponsibility.parent) {
    return (
      <ListElement
        header={displayedResponsibility.name}
        description={displayedResponsibility.description}
        itemPadding={0}
      />
    );
  }

  return (
    <>
      <ListElement
        header={displayedResponsibility.parent.name}
        description={displayedResponsibility.parent.description}
        itemPadding={0}
      />
      <ListElement
        header={displayedResponsibility.name}
        description={displayedResponsibility.description}
        itemPadding={4}
      />
    </>
  );
}
