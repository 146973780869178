import { VatRates } from 'enums';

interface GetTotalVatProps {
  netPrice: number;
  quantity: number;
  vatRate: string;
}

export default function getTotalVat({
  netPrice,
  quantity,
  vatRate,
}: GetTotalVatProps) {
  if (vatRate === VatRates.zw || vatRate === VatRates.np) {
    return (0).toFixed(4);
  }
  return ((netPrice * quantity * +vatRate) / 100).toFixed(4);
}
