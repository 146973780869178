import React from 'react';
import { createSvgIcon } from '@mui/material';

const DoubleDotIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16667 5.83325C9.16667 6.29349 9.53976 6.66659 10 6.66659C10.4602 6.66659 10.8333 6.29349 10.8333 5.83325C10.8333 5.37301 10.4602 4.99992 10 4.99992C9.53976 4.99992 9.16667 5.37301 9.16667 5.83325ZM9.16667 14.1666C9.16667 14.6268 9.53976 14.9999 10 14.9999C10.4602 14.9999 10.8333 14.6268 10.8333 14.1666C10.8333 13.7063 10.4602 13.3333 10 13.3333C9.53976 13.3333 9.16667 13.7063 9.16667 14.1666ZM7.5 5.83325C7.5 7.21396 8.61929 8.33325 10 8.33325C11.3807 8.33325 12.5 7.21396 12.5 5.83325C12.5 4.45254 11.3807 3.33325 10 3.33325C8.61929 3.33325 7.5 4.45254 7.5 5.83325ZM7.5 14.1666C7.5 15.5473 8.61929 16.6666 10 16.6666C11.3807 16.6666 12.5 15.5473 12.5 14.1666C12.5 12.7859 11.3807 11.6666 10 11.6666C8.61929 11.6666 7.5 12.7859 7.5 14.1666Z"
      fill="currentColor"
      fillOpacity="0.48"
    />
  </svg>,

  'DoubleDotIcon',
);

export default DoubleDotIcon;
