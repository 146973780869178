import { styled } from '@mui/material';
import colors from 'config/material-ui/colors';

const StyledInput = styled('input')({
  border: '0.1rem solid',
  borderRadius: '1.2rem',
  borderColor: colors.GREY_AGATE_400,
  padding: '1.65rem 1.4rem',

  '&:hover': {
    borderColor: colors.GREY_AGATE_800,
  },
});

export default StyledInput;
