import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import getControlErrors from 'helpers/validators/getControlErrors';
import { ControlSelectProps } from './interfaces';

export default function ControlSelect<T extends FieldValues>({
  errors,
  control,
  name,
  labelId,
  label,
  id,
  sx,
  selectOptions = [],
  required = false,
  fullWidth = true,
  optional = false,
  onValueChange: parentOnChange,
  dataTestId,
  disabled = false,
  renderOptions,
}: ControlSelectProps<T>) {
  const { t } = useTranslation();
  const selectId = id ?? name;
  const selectLabelId = labelId ?? `${selectId}-label`;
  const controlErrors = getControlErrors(name, errors);

  const sortedByTranslatedLabelOptions = selectOptions.sort((a, b) =>
    t(a.label).localeCompare(t(b.label)),
  );

  const getOptions = () => {
    if (!renderOptions) {
      return [
        optional && (
          <MenuItem value="">
            <em>{t('general.placeholder')}</em>
          </MenuItem>
        ),
        ...sortedByTranslatedLabelOptions.map(
          ({ value: optionValue, label: optionLabel }) => (
            <MenuItem key={optionValue} value={optionValue}>
              {t(optionLabel)}
            </MenuItem>
          ),
        ),
      ];
    }

    return renderOptions;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, value, onChange } }) => (
        <FormControl
          disabled={disabled}
          fullWidth={fullWidth}
          required={required}
          error={Boolean(controlErrors)}
          sx={sx}
        >
          <InputLabel id={selectLabelId}>{t(label)}</InputLabel>
          <Select
            labelId={selectLabelId}
            label={t(label)}
            fullWidth={fullWidth}
            id={id}
            value={value}
            onChange={(event) => {
              onChange(event);
              if (parentOnChange) {
                parentOnChange(event);
              }
            }}
            data-testid={dataTestId}
            onBlur={onBlur}
            inputProps={{
              name,
              id,
            }}
          >
            {getOptions()}
          </Select>
          {controlErrors?.message && (
            <FormHelperText>{t(controlErrors.message)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
