import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableHeadersProps } from 'interfaces';

interface NoDataRowProps {
  headers: string[] | TableHeadersProps[];
  label?: string;
}

function NoDataRow({ headers, label = 'label.no_data' }: NoDataRowProps) {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell align="center" colSpan={headers.length + 1}>
        <Typography variant="bold">{t(label)}</Typography>
      </TableCell>
    </TableRow>
  );
}

export default NoDataRow;
