import React, { useState } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import {
  useGetBenefitsQuery,
  useUpdateBenefitMutation,
} from 'store/benefits/benefits';
import { Benefit } from 'store/benefits/interfaces';
import { CustomError } from 'store/api';
import { FormTypes } from 'enums';
import BenefitForm from '../BenefitForm';

export interface EditBenefitProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  benefitId: number;
}

const emptyBenefits: Benefit[] = [];

export default function EditBenefit({
  benefitId,
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditBenefitProps) {
  const { benefit } = useGetBenefitsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      benefit: data?.filter((item) => item.id === benefitId) || emptyBenefits,
    }),
  });
  const [name, setName] = useState(benefit[0]?.name ?? '');
  const [updateBenefit, { isLoading }] = useUpdateBenefitMutation();

  const handleUpdateBenefit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateBenefit({
        id: benefitId,
        name,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  return (
    <BenefitForm
      formType={FormTypes.edit}
      handleSubmit={handleUpdateBenefit}
      isLoading={isLoading}
      name={name}
      setIsOpen={setIsOpen}
      setName={setName}
    />
  );
}
