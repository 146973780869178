import { ResourceRequestBillingType } from 'store/resourceRequests/interfaces';

interface ResourceRequestBillingOption {
  value: string;
  label: string;
}

const resourceRequestsBillingOptions: ResourceRequestBillingOption[] = [
  {
    value: ResourceRequestBillingType.per_hour,
    label: 'resource_request.billing_type.per_hour',
  },
  {
    value: ResourceRequestBillingType.per_day,
    label: 'resource_request.billing_type.per_day',
  },
  {
    value: ResourceRequestBillingType.per_week,
    label: 'resource_request.billing_type.per_week',
  },
  {
    value: ResourceRequestBillingType.per_month,
    label: 'resource_request.billing_type.per_month',
  },
];

export default resourceRequestsBillingOptions;
