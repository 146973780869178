import React, { useContext } from 'react';
import { isAfter } from 'date-fns';
import { Stack, Avatar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { oneMinuteInMs } from 'components/BookProperties/BookingForm/consts';
import roles from 'config/roles/roles';
import useCheckRoles from 'hooks/useCheckRoles';
import useCurrentDateEvery from 'hooks/useCurrentDateEvery';
import { EmployeeContext } from 'pages/PropertyBooking/PropertyBooking';
import { StateAppointmentProps } from './interfaces';

export default function StateAppointment({
  data,
  handleCancelPropertyBooking,
  employeeData,
  isMultipleDaysWidthSize,
  dateFrom,
  dateTo,
  bookingDaysCount,
}: StateAppointmentProps) {
  const { t } = useTranslation();

  const currentDateEveryMinute = useCurrentDateEvery(oneMinuteInMs);
  const isBookingFinished = isAfter(
    currentDateEveryMinute,
    data.endDate as string,
  );

  const isPropertyManager = useCheckRoles(roles.properties);
  const selectedEmployee = useContext(EmployeeContext);

  const isBookingRemovable =
    isPropertyManager ||
    (!isBookingFinished && data.employee === selectedEmployee);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.25}>
          <Typography variant="body2" color="main.white">
            {`${dateFrom} - ${dateTo}`}
          </Typography>
          {isMultipleDaysWidthSize && (
            <Typography variant="body2" color="main.white">
              {t('booking.booking_days_scheduler', { count: bookingDaysCount })}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar
          sx={{
            width: '2.4rem',
            height: '2.4rem',
          }}
          src={employeeData?.avatarUrl || undefined}
        />
        {isBookingRemovable && (
          <CloseIcon
            sx={{
              color: 'main.white',
              cursor: 'pointer',
            }}
            onClick={handleCancelPropertyBooking}
          />
        )}
      </Stack>
    </>
  );
}
