import api from 'store/api';

export const incomeTransactionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addIncomeTransaction: builder.mutation({
      query: (body) => ({
        url: '/income-transactions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Incomes'],
    }),
  }),
});

export const { useAddIncomeTransactionMutation } = incomeTransactionsApi;
