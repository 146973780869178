import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Stack } from '@mui/material';
import Loader from 'common/Loader';
import Error from 'common/Error';
import InvoiceClient from 'components/InvoicesList/InvoiceClient/InvoiceClient';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import { useGetAccountsQuery } from 'store/accounts/accounts';
import {
  useGetClientsQuery,
  useUpdateClientMutation,
} from 'store/clients/clients';
import {
  useGetIncomeQuery,
  useUpdateIncomeMutation,
} from 'store/incomes/incomes';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import InvoiceForm from 'components/InvoiceForm/InvoiceForm';
import dateFormat from 'dateformat';
import { CustomError } from 'store/api';
import { StatusProps } from 'hoc/Status/Status';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { Invoice } from 'store/incomes/interfaces';
import replaceApiIri from 'helpers/replaceApiIri';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';

export default function EditInvoiceForm({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: StatusProps) {
  const { id } = useParams<{ id: any }>();
  const [updateIncome, { isLoading: incomeUpdating }] =
    useUpdateIncomeMutation();
  const [name, setName] = useState('');
  const [clientIri, setClientIri] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [saleDate, setSaleDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [currencyIri, setCurrencyIri] = useState('');
  const [conversionRate, setConversionRate] = useState('');
  const [bankAccountIri, setBankAccountIri] = useState('');
  const [incomeRows, setIncomeRows] = useState([
    {
      id: '',
      productName: '',
      project: '',
      classificationNumber: '',
      netPrice: '',
      vatRate: '',
      unit: '',
      quantity: '',
    },
  ]);
  const [notes, setNotes] = useState('');
  const [internalComments, setInternalComments] = useState({
    current: '',
    previous: '',
  });
  const {
    data: income = {} as Invoice,
    isError: incomeFetchError,
    isLoading: incomeFetching,
    isSuccess: incomeFetchSuccess,
  } = useGetIncomeQuery(id);
  const {
    data: currencies = [],
    isError: currenciesFetchError,
    isLoading: currenciesFetching,
    isSuccess: currenciesFetchSuccess,
  } = useGetCurrenciesQuery();
  const {
    data: clients = [],
    isError: clientsFetchError,
    isLoading: clientsFetching,
    isSuccess: clientsFetchSuccess,
  } = useGetClientsQuery({});
  const incomeClient = clients.find((item) => item['@id'] === clientIri);

  const {
    data: accounts = [],
    isError: accountsFetchError,
    isLoading: accountsFetching,
    isSuccess: accountsFetchSuccess,
  } = useGetAccountsQuery();
  const {
    data: projects = [],
    isError: projectsFetchError,
    isLoading: projectsFetching,
    isSuccess: projectsFetchSuccess,
  } = useGetProjectsPreviewQuery({});
  const { t } = useTranslation();
  const [updateClient, { isLoading: clientUpdating }] =
    useUpdateClientMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!incomeFetchSuccess) {
      return;
    }
    setName(income.name);
    setClientIri(String(income.client));
    setIssueDate(dateFormat(income.issueDate, 'yyyy-mm-dd'));
    setSaleDate(dateFormat(income.saleDate, 'yyyy-mm-dd'));
    setDueDate(dateFormat(income.dueDate, 'yyyy-mm-dd'));
    setCurrencyIri(income.currency);
    setConversionRate(String(income.conversionRate));
    setBankAccountIri(income.bankAccount);
    setNotes(income.notes);
    setIncomeRows(income.incomeRows);
    setInternalComments({
      current: incomeClient?.invoiceComment || '',
      previous: incomeClient?.invoiceComment || '',
    });
  }, [income, incomeFetchSuccess, incomeClient]);

  const handleEditIncome = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const rows = incomeRows.map((row) => ({
      ...row,
      id: row.id || null,
      project: row.project || null,
      netPrice: String(row.netPrice),
      quantity: +row.quantity,
    }));
    try {
      await updateIncome({
        id,
        name,
        client: clientIri,
        issueDate: dateFormat(issueDate, 'yyyy-mm-dd'),
        saleDate: dateFormat(saleDate, 'yyyy-mm-dd'),
        dueDate: dateFormat(dueDate, 'yyyy-mm-dd'),
        currency: currencyIri,
        conversionRate: +conversionRate,
        bankAccount: bankAccountIri,
        incomeRows: rows,
        notes,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleUpdateInternalComments = async () => {
    if (internalComments.current === internalComments.previous) {
      return;
    }
    try {
      await updateClient({
        id: replaceApiIri(clientIri, 'clients'),
        invoiceComment: internalComments.current,
      });
      setInternalComments({
        current: internalComments.current,
        previous: internalComments.current,
      });
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (
    incomeFetching ||
    clientsFetching ||
    accountsFetching ||
    currenciesFetching ||
    projectsFetching
  ) {
    return <Loader />;
  }

  if (
    incomeFetchError ||
    clientsFetchError ||
    currenciesFetchError ||
    accountsFetchError ||
    projectsFetchError
  ) {
    return <Error />;
  }

  const isDataFetchSuccess =
    incomeFetchSuccess ||
    currenciesFetchSuccess ||
    clientsFetchSuccess ||
    accountsFetchSuccess ||
    projectsFetchSuccess;

  return (
    <Grid item xs={12} bgcolor="background.main">
      <NavBarContainer>
        <Stack direction="row" spacing={1} alignItems="center">
          <NavigationBackButton to="/invoices" />
          <Stack justifyContent="center">
            <NavBarTitle>{income.name}</NavBarTitle>
            <InvoiceClient clientIri={income.client} />
          </Stack>
        </Stack>
        <Button
          onClick={() => navigate(`/invoices/${income.id}/email`)}
          variant="contained"
        >
          {t('invoices.send_to_client')}
        </Button>
      </NavBarContainer>
      {isDataFetchSuccess && (
        <InvoiceForm
          name={name}
          setName={setName}
          issueDate={issueDate}
          setIssueDate={setIssueDate}
          saleDate={saleDate}
          setSaleDate={setSaleDate}
          dueDate={dueDate}
          setDueDate={setDueDate}
          clientIri={clientIri}
          setClientIri={setClientIri}
          accountIri={bankAccountIri}
          setAccountIri={setBankAccountIri}
          currencyIri={currencyIri}
          setCurrencyIri={setCurrencyIri}
          conversionRate={conversionRate}
          setConversionRate={setConversionRate}
          incomeRows={incomeRows}
          setIncomeRows={setIncomeRows}
          clients={clients}
          accounts={accounts}
          currencies={currencies}
          disableSubmission={() => false}
          projects={projects}
          handleFormSubmit={handleEditIncome}
          notes={notes}
          setNotes={setNotes}
          internalComments={internalComments}
          setInternalComments={setInternalComments}
          isEditForm
          isLoading={incomeUpdating || clientUpdating}
          handleUpdateInternalComments={handleUpdateInternalComments}
        />
      )}
    </Grid>
  );
}
