import React from 'react';
import { createSvgIcon } from '@mui/material';

const FilterIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_210_1804)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28735 4.79145C0.151178 3.15333 1.27471 0.833252 3.3139 0.833252H16.6862C18.7254 0.833252 19.8489 3.15334 18.7127 4.79145L13.8649 11.781C13.7655 11.9243 13.7109 12.0975 13.7109 12.2768V15.9333C13.7109 17.0432 12.9895 18.0354 11.9184 18.3479L9.45196 19.0676C7.84528 19.5364 6.28915 18.3001 6.28915 16.6529V12.2768C6.28915 12.0975 6.23457 11.9243 6.13522 11.781L1.28735 4.79145ZM3.3139 2.49992C2.68416 2.49992 2.25033 3.25547 2.65685 3.84159L7.50472 10.8312C7.799 11.2555 7.95582 11.7606 7.95582 12.2768V16.6529C7.95582 17.2371 8.49095 17.6118 8.98511 17.4676L11.4516 16.748C11.7923 16.6485 12.0443 16.3229 12.0443 15.9333V12.2768C12.0443 11.7606 12.2011 11.2555 12.4954 10.8312L17.3432 3.84159C17.7497 3.25547 17.3159 2.49992 16.6862 2.49992H3.3139Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_210_1804">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>,

  'FilterIcon',
);

export default FilterIcon;
