import React from 'react';
import { Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LoadingButton } from '@mui/lab';
import CustomLocalizationProvider from 'common/CustomLocalizationProvider/CustomLocalizationProvider';
import LocationFileUpload from './LocationFileUpload';
import schema from './LocationForm.schema';
import { FormPayload, LocationFormProps } from './interfaces';
import getDefaultValues from './getDefaultValues';

const validate = zodResolver(schema);

export default function LocationForm({
  onSubmit,
  isSubmitting,
  location,
}: LocationFormProps) {
  const { t } = useTranslation();
  const defaultValues = getDefaultValues(location);
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues,
  });

  const { name } = watch();
  const handleOnSubmit = handleSubmit(onSubmit);

  const isFormValuesChanged = !!Object.keys(dirtyFields).length;
  const disableSubmission = () =>
    !name || Object.values(errors).some((error) => error);

  const isEditForm = !!location;

  return (
    <form onSubmit={handleOnSubmit}>
      <Box display="flex" flexDirection="column" gap={3} flexWrap="wrap" pt={3}>
        <ControlTextField
          control={control}
          errors={errors}
          label="label.name"
          name="name"
          required
        />
        <Controller
          name="officeOpenHour"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <CustomLocalizationProvider>
              <TimePicker
                label={t('locations.office_open_hour')}
                views={['hours']}
                ampm={false}
                sx={{ width: '100%' }}
                value={value}
                onChange={onChange}
                onClose={onBlur}
              />
            </CustomLocalizationProvider>
          )}
        />
        <Controller
          name="officeCloseHour"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <CustomLocalizationProvider>
              <TimePicker
                label={t('locations.office_close_hour')}
                views={['hours']}
                ampm={false}
                sx={{ width: '100%' }}
                value={value || null}
                onChange={onChange}
                onClose={onBlur}
              />
            </CustomLocalizationProvider>
          )}
        />
        {isEditForm && (
          <LocationFileUpload location={location} />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={4}>
        <LoadingButton
          disabled={disableSubmission() || !isFormValuesChanged}
          endIcon={<AddCircleOutlineIcon />}
          loading={isSubmitting}
          type="submit"
          variant="contained"
        >
          <span>{t('button.save')}</span>
        </LoadingButton>
      </Box>
    </form>
  );
}
