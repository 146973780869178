import React, { useState } from 'react';
import {
  Box,
  Dialog,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dateFormat from 'dateformat';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {
  EmployeeProjectRate,
  useDeleteProjectRateMutation,
} from 'store/employeeProjectRate/employeeProjectRate';
import EditButton from 'common/EditButton/EditButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { StatusProps } from 'hoc/Status/Status';
import EditProjectRateForm from 'components/EditEmployeeForm/ProjectRates/EditProjectRateForm';
import { CustomError } from 'store/api';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import useCheckFeature from 'hooks/useCheckFeature';
import features from 'config/features/features';

export interface RateProps extends StatusProps {
  rate: EmployeeProjectRate;
}

function Rate({ rate, setIsError, setIsSuccess, setErrorMessage }: RateProps) {
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [chosenProjectRate, setChosenProjectRate] =
    useState<EmployeeProjectRate>({} as EmployeeProjectRate);
  const [deleteProjectRate] = useDeleteProjectRateMutation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const isInvoiceFeatureActive = useCheckFeature(features.incomes);

  const handleDeleteProjectRate = async () => {
    try {
      await deleteProjectRate(rate.id).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{rate.project.name}</Typography>
        </TableCell>
        {isInvoiceFeatureActive && (
          <TableCell>
            <Typography variant="body2">{rate.position}</Typography>
          </TableCell>
        )}
        <TableCell align="center">
          <Typography variant="body2">{rate.rate}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {dateFormat(rate.dateFrom, 'dd-mm-yyyy')}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {rate.dateTo ? (
            <Typography variant="body2">
              {dateFormat(rate.dateTo, 'dd-mm-yyyy')}
            </Typography>
          ) : (
            <AllInclusiveIcon fontSize="large" />
          )}
        </TableCell>
        <TableCell align="right">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
          >
            <EditButton
              onClick={() => {
                setChosenProjectRate(rate);
                setIsEditFormOpen(true);
              }}
            />
            <IconButton onClick={() => setIsConfirmationDialogOpen(true)}>
              <DeleteIcon color="error" fontSize="large" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <Dialog
        fullWidth
        onClose={() => setIsEditFormOpen(false)}
        open={isEditFormOpen}
      >
        <EditProjectRateForm
          projectRate={chosenProjectRate}
          setIsOpen={setIsEditFormOpen}
          project={rate.project}
        />
      </Dialog>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleAccept={handleDeleteProjectRate}
      />
    </>
  );
}

export default Rate;
