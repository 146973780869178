export interface Config {
  'organization-week-start-day': number;
  'company-working-days': number[];
  'property-booking-require-cancel-reason': boolean;
}

export interface OrganizationReduxState {
  roles: string[];
  possibleRoles: string[];
  instance: string | null;
  name: string | null;
  defaultCurrency: string | null;
  features: string[];
  config: Config;
  id: string;
}

export interface OrganizationStateProps {
  organization: OrganizationReduxState;
  isOrganizationChoice: boolean;
}

const defaultState: OrganizationStateProps = {
  organization: {
    roles: [],
    possibleRoles: [],
    instance: null,
    name: null,
    defaultCurrency: null,
    features: [],
    config: {} as Config,
    id: '',
  },
  isOrganizationChoice: false,
};

export default defaultState;
