import { Typography, Stack, Divider } from '@mui/material';
import React from 'react';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import { ResourceRequestForCandidate } from 'hooks/useGetResourceRequestsForCandidate';

interface RecruitmentTileProps {
  resourceRequestForCandidate: ResourceRequestForCandidate;
  icon: React.ReactNode;
}

export default function RecruitmentTile({
  resourceRequestForCandidate,
  icon,
}: RecruitmentTileProps) {
  return (
    <StyledContentBox p={3}>
      <Stack direction="row" spacing={2} alignItems="center">
        {icon}
        <Stack>
          <Typography variant="body1" fontWeight={700}>
            {resourceRequestForCandidate.resourceRequest.position}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {resourceRequestForCandidate.resourceRequest.location}
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: 'border.veryLight', my: 3 }} />

      <Typography variant="body2" fontWeight={700}>
        {resourceRequestForCandidate.state}
      </Typography>
    </StyledContentBox>
  );
}
