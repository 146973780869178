import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CloseButtonProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function CloseButton({ setIsOpen }: CloseButtonProps) {
  return (
    <IconButton
      onClick={() => setIsOpen(false)}
      sx={{
        bgcolor: 'secondary.light',
        width: '4rem',
        height: '4rem',
        borderRadius: '50%',
      }}
    >
      <CloseIcon fontSize="large" />
    </IconButton>
  );
}

export default CloseButton;
