import React, { useState } from 'react';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import EditButton from 'common/EditButton/EditButton';
import {
  DocumentData,
  useDeleteDocumentMutation,
} from 'store/documents/documents';
import {
  DocumentType,
  useGetDocumentTypeQuery,
} from 'store/documentTypes/documentTypes';
import { skipToken } from '@reduxjs/toolkit/query';
import { StorageResponse } from 'store/storage/storage';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { StatusProps } from 'hoc/Status/Status';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import openFileWithAuth from 'helpers/openFileWithAuth';
import { CustomError } from 'store/api';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import replaceApiIri from 'helpers/replaceApiIri';

export interface CostDocumentProps extends StatusProps {
  setIsEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  document: DocumentData;
  file: StorageResponse;
  setChosenDocId: React.Dispatch<React.SetStateAction<number>>;
}

function CostDocument({
  document,
  setIsEditFormOpen,
  file,
  setIsSuccess,
  setIsError,
  setErrorMessage,
  setChosenDocId,
}: CostDocumentProps) {
  const { data: docType = {} as DocumentType } = useGetDocumentTypeQuery(
    replaceApiIri(document.documentType || '', 'document-types') || skipToken,
  );
  const [deleteDocument] = useDeleteDocumentMutation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const handleDeleteDocument = async () => {
    try {
      await deleteDocument(document.id).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{docType.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{document.notes}</Typography>
        </TableCell>
        <TableCell align="center">
          <Box display="flex" justifyContent="center" gap={1}>
            <IconButton onClick={() => downloadFileWithAuth(file.url, file.id)}>
              <DownloadIcon fontSize="large" />
            </IconButton>
            <IconButton onClick={() => openFileWithAuth(file.url)}>
              <RemoveRedEyeOutlinedIcon fontSize="large" />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="right">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
          >
            <EditButton
              onClick={() => {
                setChosenDocId(document.id);
                setIsEditFormOpen(true);
              }}
            />
            <IconButton onClick={() => setIsConfirmationDialogOpen(true)}>
              <DeleteIcon color="error" fontSize="large" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleAccept={handleDeleteDocument}
      />
    </>
  );
}

export default CostDocument;
