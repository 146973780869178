import React from 'react';
import { Box, FormControl, Grid } from '@mui/material';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import { useAddCostMutation } from 'store/costs/costs';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import { useGetCostGroupsQuery } from 'store/costGroups/costGroups';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import BaseData from 'components/EditCostForm/OverviewForm/BaseData/BaseData';
import Statuses from 'components/EditCostForm/OverviewForm/Statuses/Statuses';
import Details from 'components/EditCostForm/OverviewForm/Details/Details';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'store/api';
import { useAddBudgetContractorMutation } from 'store/budget/budget';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import { useForm } from 'react-hook-form';
import { costsStatuses } from 'components/EditCostForm/OverviewForm/constants';
import { FormPayload } from 'components/EditCostForm/OverviewForm/interfaces';
import schema from 'components/EditCostForm/OverviewForm/OverviewForm.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import getSelectOptions from 'components/EditCostForm/OverviewForm/getSelectOptions';
import useBudgetPreview from 'hooks/useBudgetPreview';
import { initValues } from './constants';

const validate = zodResolver(schema);

export interface AddCostFormProps extends StatusProps {}

export default function AddCostForm({
  setIsSuccess,
  setIsErrorCatch,
}: AddCostFormProps) {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initValues,
  });

  const [addCost, { isLoading }] = useAddCostMutation();
  const [addContractor, { isLoading: isAddContractorLoading }] =
    useAddBudgetContractorMutation();
  const { budgetPreviews } = useBudgetPreview();
  const {
    data: employees = [],
    isLoading: employeesLoading,
    isError: employeesFetchError,
    isSuccess: employeesFetched,
  } = useGetEmployeesPreview({ isActive: true });
  const {
    data: costGroups = [],
    isLoading: costGroupsLoading,
    isError: costGroupsFetchError,
    isSuccess: costGroupsFetched,
  } = useGetCostGroupsQuery();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { costBudgetOptions, costEmployeeOptions, costGroupsOptions } =
    getSelectOptions({
      budgetPreviews,
      employees,
      costGroups,
    });

  const { name, costGroup, status } = watch();

  const isFormValuesChanged = !!Object.keys(dirtyFields).length;
  const disableSubmission = () =>
    Object.values(errors).some((value) => value) ||
    !name ||
    !status ||
    !costGroup;

  const handleAddCost = async (data: FormPayload) => {
    const {
      budget,
      ownerEmployee,
      taxAmount,
      paymentDeadline,
      ...restPayload
    } = data;

    try {
      const addedContractor = await addCost({
        paymentDeadline: paymentDeadline ? +paymentDeadline : null,
        ownerEmployee: ownerEmployee || null,
        taxAmount: taxAmount && +taxAmount >= 0 ? +taxAmount : null,
        ...restPayload,
      }).unwrap();
      if (budget) {
        const addedBudgetContractor = {
          budget,
          contractor: addedContractor['@id'],
        };
        await addContractor(addedBudgetContractor).unwrap();
      }
      setIsSuccess(true);
      navigate(`/contractors/${addedContractor.id}`);
      reset(data);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('costs.add_cost')}</NavBarTitle>
      </NavBarContainer>
      {(employeesFetchError || costGroupsFetchError) && <Error />}
      {(employeesLoading || costGroupsLoading) && <Loader />}
      {employeesFetched && costGroupsFetched && (
        <Box p={2}>
          <form onSubmit={handleSubmit(handleAddCost)}>
            <FormControl fullWidth sx={{ rowGap: 2 }}>
              <BaseData
                errors={errors}
                control={control}
              />
              <ControlTextField
                errors={errors}
                control={control}
                name="matchRegex"
                label="costs.match_regex_label"
              />
              <Statuses
                errors={errors}
                control={control}
                costsStatuses={costsStatuses}
              />
              <Details
                errors={errors}
                control={control}
                costEmployeeOptions={costEmployeeOptions}
                costGroupsOptions={costGroupsOptions}
                costBudgetOptions={costBudgetOptions}
              />
              <ControlTextField
                control={control}
                label="label.description"
                errors={errors}
                name="description"
                multiline
                minRows={3}
              />
            </FormControl>

            <Box display="flex" justifyContent="flex-end" mt={2}>
              {isFormValuesChanged && (
                <SubmitButton
                  disabled={disableSubmission()}
                  isLoading={isLoading || isAddContractorLoading}
                />
              )}
            </Box>
          </form>
        </Box>
      )}
    </Grid>
  );
}
