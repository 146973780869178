import React from 'react';
import { useParams } from 'react-router-dom';
import {
  TransactionsData,
  useGetTransactionsQuery,
} from 'store/transactions/transactions';
import Loader from 'common/Loader';
import Error from 'common/Error';
import TransactionsList from 'components/TransactionsList/TransactionsList';
import TransactionsChart from 'components/EditCostForm/CostTransactions/TransactionsChart/TransactionsChart';

function CostTransactions() {
  const { id } = useParams<{ id: string }>();
  const {
    data: transactions = {} as TransactionsData,
    isLoading: transactionsFetching,
    isError: transactionsFetchError,
  } = useGetTransactionsQuery({ cost: id }, { skip: !id });

  if (transactionsFetching) {
    return <Loader />;
  }

  if (transactionsFetchError) {
    return <Error />;
  }

  return (
    <>
      <TransactionsChart />
      <TransactionsList
        isAllTransactionsList={false}
        padding={0}
        transactions={transactions['hydra:member']}
      />
    </>
  );
}

export default CostTransactions;
