import dateFormat from 'dateformat';

export default function formatDateRange({
  dateFrom,
  dateTo,
}: {
  dateFrom: Date | string;
  dateTo: Date | string | null;
}) {
  return {
    from: dateFormat(dateFrom, 'dd.mm.yyyy'),
    separator: ' - ',
    to: dateTo ? dateFormat(dateTo, 'dd.mm.yyyy') : '∞',
    text: `${dateFormat(dateFrom, 'dd.mm.yyyy')} - ${
      dateTo ? dateFormat(dateTo, 'dd.mm.yyyy') : '∞'
    }`,
  };
}
