import api from 'store/api';
import {
  DaysLimitsFilters,
  HolidayDaysLimits,
  HolidayDaysLimitsResponseDto,
  HolidayDaysLimitPatchMutationRequest,
  HolidayDaysLimitPostMutationRequest,
} from './interfaces';

export const holidayDaysLimitsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHolidayDaysLimits: builder.query<
      HolidayDaysLimits[],
      Partial<DaysLimitsFilters>
    >({
      query: (filters) => ({
        url: '/holiday-days-limits',
        params: filters,
      }),
      providesTags: ['HolidayDaysLimits'],
      transformResponse: (response: HolidayDaysLimitsResponseDto) =>
        response['hydra:member'].sort((daysLimit, comparedDaysLimit) =>
          daysLimit.dateFrom
            .toString()
            .localeCompare(comparedDaysLimit.dateFrom.toString()),
        ),
    }),
    getHolidayDaysLimit: builder.query<HolidayDaysLimits, number>({
      query: (id) => `/holiday-days-limits/${id}`,

      providesTags: ['HolidayDaysLimits'],
    }),
    addHolidayDaysLimit: builder.mutation<
      HolidayDaysLimits,
      HolidayDaysLimitPostMutationRequest
    >({
      query: (body) => ({
        url: '/holiday-days-limits',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['HolidayDaysLimits', 'Employees'],
    }),
    editHolidayDaysLimit: builder.mutation<
      HolidayDaysLimits,
      HolidayDaysLimitPatchMutationRequest
    >({
      query: ({ id, ...body }) => ({
        url: `/holiday-days-limits/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['HolidayDaysLimits', 'Employees'],
    }),
    deleteHolidayDaysLimit: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/holiday-days-limits/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['HolidayDaysLimits', 'Employees'],
    }),
  }),
});

export const {
  useGetHolidayDaysLimitsQuery,
  useGetHolidayDaysLimitQuery,
  useAddHolidayDaysLimitMutation,
  useEditHolidayDaysLimitMutation,
  useDeleteHolidayDaysLimitMutation,
} = holidayDaysLimitsApi;
