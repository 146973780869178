import React, { useState } from 'react';
import { Box, Dialog, Grid } from '@mui/material';
import AccountsList from 'components/AccountsList/AccountsList';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import EditAccountForm from 'components/EditAccountForm';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import StyledForwardLink from 'pages/HolidaysManagement/Link.styled';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import UploadTransactions from 'components/UploadTransactions';

export default function Accounts() {
  const [chosenAccount, setChosenAccount] = useState<string>('');
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { t } = useTranslation();
  const isManager = useCheckRoles(roles.banksManager);
  const isTransactionsManager = useCheckRoles(roles.transactionsManager);

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <Box display="flex" alignItems="center" columnGap={3}>
          <NavBarTitle>{t('bank_accounts.header')}</NavBarTitle>
          {isManager && (
            <StyledForwardLink to="/banks">
              <Box display="flex" columnGap={0.5} alignItems="center">
                <NavBarTitle>{t('bank_accounts.manage_banks')}</NavBarTitle>
                <PaidOutlinedIcon fontSize="large" />
              </Box>
            </StyledForwardLink>
          )}
        </Box>
        {isTransactionsManager && <UploadTransactions />}
      </NavBarContainer>
      <AccountsList
        setIsEditOpen={setIsEditOpen}
        setChosenAccount={setChosenAccount}
      />
      <Dialog
        open={isEditOpen}
        onClose={() => setIsEditOpen(!isEditOpen)}
        fullWidth
      >
        <EditAccountForm
          accountId={chosenAccount}
          setIsEditOpen={setIsEditOpen}
        />
      </Dialog>
    </Grid>
  );
}
