import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import dateFormat from 'dateformat';
import { StatusProps } from 'hoc/Status/Status';
import { useLocation, useNavigate } from 'react-router-dom';
import Error from 'common/Error';
import checkIncomeRows from 'helpers/validators/checkIncomeRows';
import {
  useGetClientsQuery,
  useUpdateClientMutation,
} from 'store/clients/clients';
import {
  useAddIncomeMutation,
  useGetDraftIncomeQuery,
} from 'store/incomes/incomes';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import { useGetAccountsQuery } from 'store/accounts/accounts';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import { CustomError } from 'store/api';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import InvoiceForm from 'components/InvoiceForm/InvoiceForm';
import { LocationProps } from 'interfaces';
import Loader from 'common/Loader';
import { DraftInvoice } from 'store/incomes/interfaces';
import replaceApiIri from 'helpers/replaceApiIri';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';

export interface Errors {
  name: boolean;
  issueDate: boolean;
  saleDate: boolean;
  dueDate: boolean;
  conversionRate: boolean;
}

export interface AddInvoiceFormProps extends StatusProps {}

export default function AddInvoiceForm({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddInvoiceFormProps) {
  const { state } = useLocation() as LocationProps;
  const currentDate = new Date();
  const formattedDate = dateFormat(currentDate, 'yyyy-mm-dd');
  const defaultDueDate = currentDate.setDate(currentDate.getDate() + 20);
  const formattedDueDate = dateFormat(defaultDueDate, 'yyyy-mm-dd');
  const [name, setName] = useState('');
  const [clientIri, setClientIri] = useState<string>(
    state?.income ? state.income.client : '',
  );
  const [issueDate, setIssueDate] = useState(formattedDate);
  const [saleDate, setSaleDate] = useState(formattedDate);
  const [dueDate, setDueDate] = useState(formattedDueDate);
  const [currencyIri, setCurrencyIri] = useState(state?.income?.currency ?? '');
  const [conversionRate, setConversionRate] = useState('');
  const [bankAccountIri, setBankAccountIri] = useState(
    state?.bankAccount ? state.bankAccount : '',
  );
  const [incomeRows, setIncomeRows] = useState(
    state?.income?.incomeRows ?? [
      {
        id: '',
        productName: '',
        project: '',
        projectId: '',
        classificationNumber: '',
        netPrice: '',
        vatRate: '',
        unit: '',
        quantity: '',
      },
    ],
  );
  const [notes, setNotes] = useState(state?.income?.notes ?? '');
  const [internalComments, setInternalComments] = useState({
    current: '',
    previous: '',
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [addIncome, { isLoading }] = useAddIncomeMutation();
  const {
    data: currencies = [],
    isError: currenciesFetchError,
    isLoading: currenciesFetching,
  } = useGetCurrenciesQuery();
  const {
    data: clients = [],
    isError: clientsFetchError,
    isLoading: clientsFetching,
  } = useGetClientsQuery({});
  const {
    data: accounts = [],
    isError: accountsFetchError,
    isLoading: accountsFetching,
  } = useGetAccountsQuery();
  const {
    data: projects = [],
    isError: projectsFetchError,
    isLoading: projectsFetching,
  } = useGetProjectsPreviewQuery({});
  const [updateClient] = useUpdateClientMutation();
  const { data: draftIncome = {} as DraftInvoice, isSuccess: draftFetched } =
    useGetDraftIncomeQuery(
      {
        client:
          (state?.clientIri && replaceApiIri(state.clientIri, 'clients')) || '',
        dateFrom: state?.dateFrom ?? '',
        dateTo: state?.dateTo ?? '',
        projects: state?.projects ?? '',
      },
      {
        skip: !state || !state?.createDraft,
      },
    );

  useEffect(() => {
    if (!draftFetched || !draftIncome?.rows?.length) {
      return;
    }
    const draftRows = draftIncome.rows.map((row) => ({
      id: '',
      productName: row.name,
      projectId: String(row.projectId),
      project: row.project,
      classificationNumber: '',
      netPrice: String(row.unitAmountNet),
      vatRate: row.tax,
      unit: `${row.unit}s`,
      quantity: String(row.quantity),
    }));
    setClientIri(state?.clientIri ?? '');
    setIncomeRows(draftRows);
    if (draftIncome.holidays && draftIncome.holidays.length) {
      const notesData = draftIncome.holidays.join(', ');
      setNotes(notesData);
    }
  }, [draftFetched, draftIncome, state]);

  useEffect(() => {
    if (!state?.clientIri) {
      return;
    }
    const clientData = clients.find((item) => item['@id'] === state.clientIri);
    if (!clientData) {
      return;
    }
    setCurrencyIri(clientData.currency);
    setClientIri(state.clientIri);

    if (clientData.invoiceComment) {
      setInternalComments({
        current: clientData.invoiceComment,
        previous: clientData.invoiceComment,
      });
    }

    if (!clientData.dueDays) {
      return;
    }
    const convertedSaleDate = new Date(saleDate);
    const calculatedDueDate = convertedSaleDate.setDate(
      convertedSaleDate.getDate() + +clientData.dueDays,
    );
    setDueDate(dateFormat(calculatedDueDate, 'yyyy-mm-dd'));
  }, [state, clients, saleDate]);

  const disableSubmission = () =>
    !name ||
    !clientIri ||
    !issueDate ||
    !saleDate ||
    !dueDate ||
    !currencyIri ||
    !conversionRate ||
    !bankAccountIri ||
    checkIncomeRows(incomeRows);

  const handleAddInvoice = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const rows = incomeRows.map((row) => ({
      ...row,
      id: null,
      project: row.project ? row.project : null,
      netPrice: String(row.netPrice),
      quantity: +row.quantity,
    }));
    try {
      const addedIncome = await addIncome({
        name,
        client: clientIri,
        issueDate: dateFormat(issueDate, 'yyyy-mm-dd'),
        saleDate: dateFormat(saleDate, 'yyyy-mm-dd'),
        dueDate: dateFormat(dueDate, 'yyyy-mm-dd'),
        currency: currencyIri,
        conversionRate: +conversionRate,
        bankAccount: bankAccountIri,
        incomeRows: rows,
        notes,
      }).unwrap();
      setIsSuccess(true);
      navigate(`/invoices/${addedIncome.id}/edit`);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleUpdateInternalComments = async () => {
    if (!clientIri) {
      return;
    }
    if (internalComments.current === internalComments.previous) {
      return;
    }
    try {
      await updateClient({
        id: replaceApiIri(clientIri, 'clients'),
        invoiceComment: internalComments.current,
      });
      setInternalComments({
        current: internalComments.current,
        previous: internalComments.current,
      });
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (
    clientsFetching ||
    currenciesFetching ||
    accountsFetching ||
    projectsFetching
  ) {
    return <Loader />;
  }

  if (
    clientsFetchError ||
    currenciesFetchError ||
    accountsFetchError ||
    projectsFetchError
  ) {
    return <Error />;
  }

  return (
    <Grid item xs={12} bgcolor="background.main">
      <NavBarContainer>
        <Stack spacing={1} direction="row" alignItems="center">
          <NavigationBackButton to="/invoices" />
          <NavBarTitle>{t('invoices.add_new_invoice')}</NavBarTitle>
        </Stack>
      </NavBarContainer>
      <InvoiceForm
        name={name}
        setName={setName}
        issueDate={issueDate}
        setIssueDate={setIssueDate}
        saleDate={saleDate}
        setSaleDate={setSaleDate}
        dueDate={dueDate}
        setDueDate={setDueDate}
        clientIri={clientIri}
        setClientIri={setClientIri}
        accountIri={bankAccountIri}
        setAccountIri={setBankAccountIri}
        currencyIri={currencyIri}
        setCurrencyIri={setCurrencyIri}
        conversionRate={conversionRate}
        setConversionRate={setConversionRate}
        incomeRows={incomeRows}
        setIncomeRows={setIncomeRows}
        clients={clients}
        currencies={currencies}
        accounts={accounts}
        disableSubmission={disableSubmission}
        projects={projects}
        handleFormSubmit={handleAddInvoice}
        notes={notes}
        setNotes={setNotes}
        internalComments={internalComments}
        setInternalComments={setInternalComments}
        isEditForm={false}
        isLoading={isLoading}
        handleUpdateInternalComments={handleUpdateInternalComments}
      />
    </Grid>
  );
}
