import api, { CustomError } from 'store/api';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';
import formatDateToUtcFormat from 'helpers/formatDateToUtcFormat';
import {
  PropertyBooking,
  PropertyBookingsResponseDto,
  PropertyBookingFilters,
  CancelPropertyBookingRequest,
} from './interfaces';

export const propertyBookingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyBookings: builder.query<
      PropertyBooking[],
      Partial<PropertyBookingFilters>
    >({
      query: (params) => ({ url: '/property-bookings', params }),
      providesTags: ['PropertyBookings'],
      transformResponse: (response: PropertyBookingsResponseDto) =>
        response['hydra:member'].sort(
          (booking, comparedBooking) =>
            new Date(comparedBooking.startDate).getTime() -
            new Date(booking.startDate).getTime(),
        ),
    }),
    addPropertyBooking: builder.mutation({
      query: (body) => ({
        url: '/property-bookings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PropertyBookings'],
    }),
    cancelPropertyBooking: builder.mutation<void, CancelPropertyBookingRequest>(
      {
        query: ({ id, ...body }) => ({
          url: `/property-bookings/${id}/cancel`,
          method: 'POST',
          body,
        }),
        async onQueryStarted(
          { id, cancelReason },
          { dispatch, queryFulfilled, getState },
        ) {
          try {
            await queryFulfilled;
            const propertyBookingsCache = api.util.selectInvalidatedBy(
              getState(),
              [{ type: 'PropertyBookings' }],
            );

            propertyBookingsCache
              .filter(
                ({ endpointName }) => endpointName === 'getPropertyBookings',
              )
              .forEach(({ originalArgs }) => {
                dispatch(
                  propertyBookingApi.util.updateQueryData(
                    'getPropertyBookings',
                    originalArgs,
                    (draft) =>
                      draft.map((booking) => {
                        if (booking.id === id) {
                          return {
                            ...booking,
                            cancelledAt: formatDateToUtcFormat(),
                            ...(cancelReason && { cancelReason }),
                          };
                        }
                        return booking;
                      }),
                  ),
                );
              });
          } catch (error) {
            if ('data' in (error as CustomError)) {
              dispatch(
                setErrorMessage(
                  (error as CustomError).data['hydra:description'],
                ),
              );
            }
            dispatch(setErrorStatus(true));
          }
        },
      },
    ),
  }),
});

export const {
  useGetPropertyBookingsQuery,
  useAddPropertyBookingMutation,
  useCancelPropertyBookingMutation,
} = propertyBookingApi;
