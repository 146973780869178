import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CustomError } from 'store/api';
import { locationsApi } from 'store/locations/locations';
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from 'store/storage/storage';
import ImageFile from 'common/ImageFile/ImageFile';
import AddImageFile from 'common/AddImageFile/AddImageFile';
import { AppDispatch } from 'store';
import { LocationFileUploadProps } from './interfaces';

export default function LocationFileUpload({
  setIsSuccess,
  setIsError,
  setErrorMessage,
  location,
}: LocationFileUploadProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const locationFile = location.file;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const handleDeleteLocationFile = async () => {
    if (!locationFile) {
      return;
    }
    try {
      await deleteFile(locationFile.id).unwrap();

      dispatch(
        locationsApi.util.updateQueryData('getLocations', undefined, (data) =>
          data.map((locationItem) => {
            if (locationItem.id === location.id) {
              return { ...locationItem, file: null };
            }
            return locationItem;
          }),
        ),
      );
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleUploadLocationFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    const file = event.target.files[0];

    try {
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append('relationName', 'location');
      fileData.append('relationId', location.id.toString());

      const uploadedFile = await uploadFile(fileData).unwrap();
      dispatch(
        locationsApi.util.updateQueryData('getLocations', undefined, (data) =>
          data.map((locationItem) => {
            if (locationItem.id === location.id) {
              return { ...locationItem, file: uploadedFile };
            }
            return locationItem;
          }),
        ),
      );

      setIsSuccess(true);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (locationFile) {
    return (
      <ImageFile
        file={locationFile}
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        handleDeleteFile={handleDeleteLocationFile}
      />
    );
  }

  return (
    <AddImageFile
      handleUploadFile={handleUploadLocationFile}
      uploadLabel={t('location.upload_location_photo')}
    />
  );
}
