import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetOrganizationsQuery } from 'store/organizations/organizations';
import { RootState } from 'store';
import {
  setIsOrganizationChoice,
  setOrganization,
} from 'store/organizations/actions';
import { getOrganization } from 'store/organizations/selectors';
import useHandleLogout from './useHandleLogout';

export default function useOrganization() {
  const dispatch = useDispatch();
  const handleLogout = useHandleLogout();
  const isAuthorized = useSelector(
    (state: RootState) => state.authorization.isAuthorized,
  );
  const isOrganizationChoice = useSelector(
    (state: RootState) => state.organization.isOrganizationChoice,
  );
  const organizationFromReduxState = useSelector(getOrganization);
  const isOrganizationStoredInRedux = !!organizationFromReduxState.id;

  const {
    data: organizations = [],
    isLoading: organizationsLoading,
    isError: organizationsError,
  } = useGetOrganizationsQuery(undefined, {
    skip: !isAuthorized,
  });

  useEffect(() => {
    if (!isAuthorized || !organizations.length || isOrganizationStoredInRedux) {
      return;
    }
    const selectedOrganizationId = localStorage.getItem(
      'selectedOrganizationId',
    );

    if (selectedOrganizationId) {
      const selectedOrganization = organizations.find(
        (organizationItem) => organizationItem.id === selectedOrganizationId,
      );

      if (!selectedOrganization) {
        handleLogout();
        return;
      }

      dispatch(
        setOrganization({
          roles: selectedOrganization.roles,
          possibleRoles: selectedOrganization.possibleRoles,
          instance: selectedOrganization.instance,
          name: selectedOrganization.name,
          defaultCurrency: selectedOrganization.defaultCurrency,
          features: selectedOrganization.features,
          config: selectedOrganization.config,
          id: selectedOrganization.id,
        }),
      );
      return;
    }

    if (organizations.length > 1) {
      dispatch(setIsOrganizationChoice(true));
      return;
    }

    if (organizations.length === 1) {
      const userOrganization = organizations[0];
      localStorage.setItem('selectedOrganizationId', userOrganization.id);
      dispatch(
        setOrganization({
          roles: userOrganization.roles,
          possibleRoles: userOrganization.possibleRoles,
          instance: userOrganization.instance,
          name: userOrganization.name,
          defaultCurrency: userOrganization.defaultCurrency,
          features: userOrganization.features,
          config: userOrganization.config,
          id: userOrganization.id,
        }),
      );
    }
  }, [
    dispatch,
    handleLogout,
    isAuthorized,
    organizations,
    isOrganizationStoredInRedux,
  ]);

  return {
    isOrganizationChoice,
    organizations,
    organizationsLoading,
    organizationsError,
  };
}
