import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  {
    align: 'left',
    label: 'benefits.price',
  },
  {
    align: 'center',
    label: 'benefits.tax',
  },
  {
    align: 'center',
    label: 'benefits.coverage',
  },
  {
    align: 'center',
    label: 'benefits.valid_from',
  },
  {
    align: 'center',
    label: 'benefits.valid_to',
  },
];

export default headers;
