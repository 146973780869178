import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationUsersQuery } from 'store/OrganizationUsers/organizationUsers';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import { OrganizationUsersResponseDto } from 'store/OrganizationUsers/interfaces';
import tableHeaders from './tableHeaders';
import OrganizationUserRow from './OrganizationUserRow';

export default function OrganizationUsersList() {
  const { t } = useTranslation();

  const {
    data: organizationUsersData = {} as OrganizationUsersResponseDto,
    isFetching: organizationUsersFetching,
    isError: organizationUsersError,
  } = useGetOrganizationUsersQuery();
  const organizationUsers = organizationUsersData?.['hydra:member'] || [];

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {header.label === 'organization_users.header_user'
                    ? t(header.label, { count: organizationUsers.length })
                    : t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={tableHeaders.length} />
          </TableRow>
        </TableHead>
        <TableBodyContent
          tableHeaders={tableHeaders}
          isError={organizationUsersError}
          isFetching={organizationUsersFetching}
        >
          {organizationUsers?.map((organizationUser) => (
            <OrganizationUserRow
              key={organizationUser.id}
              organizationUser={organizationUser}
            />
          ))}
        </TableBodyContent>
      </Table>
    </TableContainer>
  );
}
