import { AgreementBillingType } from 'components/AgreementsList/AgreementsList';

interface AgreementBillingTypeItem {
  label: string;
  value: AgreementBillingType;
}

const agreementBillingTypes: AgreementBillingTypeItem[] = [
  {
    label: 'agreements.billing_type_per_hour',
    value: AgreementBillingType.PER_HOUR,
  },
  {
    label: 'agreements.billing_type_per_day',
    value: AgreementBillingType.PER_DAY,
  },
  {
    label: 'agreements.billing_type_per_week',
    value: AgreementBillingType.PER_WEEK,
  },
  {
    label: 'agreements.billing_type_per_month',
    value: AgreementBillingType.PER_MONTH,
  },
];

export default agreementBillingTypes;
