import React, { useState } from 'react';
import { Chip, Dialog, TableCell, TableRow, Typography } from '@mui/material';
import {
  HolidayTypeStatus,
  HolidayType as TypeProps,
} from 'store/holidayTypes/interfaces';
import { useTranslation } from 'react-i18next';
import holidayTypes from 'components/EmployeeHolidayForm/holidayTypes';
import { Cancel, CheckCircleOutline, QuestionMark } from '@mui/icons-material';
import EditButton from 'common/EditButton/EditButton';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { FormTypes } from 'enums';
import { usePatchHolidayTypeMutation } from 'store/holidayTypes/holidayTypes';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';
import { TypeFormPayload } from '../TypeForm/interfaces';
import TypeForm from '../TypeForm/TypeForm';

interface HolidayTypeProps {
  holidayType: TypeProps;
}

export default function HolidayType({ holidayType }: HolidayTypeProps) {
  const { t } = useTranslation();
  const idTranslationKey = holidayType.id.replaceAll('-', '_');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editedType, setEditedType] = useState<TypeFormPayload>({
    id: '',
    name: '',
    status: '',
  });
  const [editHolidayType, { isLoading: holidayTypePatching }] =
    usePatchHolidayTypeMutation();
  const dispatch = useAppDispatch();

  const typeIcon = (() => {
    const matchedType = holidayTypes.find(
      ({ type }) => type === holidayType.id,
    );

    if (!matchedType) {
      return <QuestionMark />;
    }

    return matchedType.icon;
  })();

  const statusIcon =
    holidayType.status === HolidayTypeStatus.active ? (
      <CheckCircleOutline color="primary" fontSize="large" />
    ) : (
      <Cancel color="error" fontSize="large" />
    );

  const handleOpenEditForm = () => {
    setEditedType({
      id: holidayType.id,
      name: holidayType.name,
      status: holidayType.status,
    } as TypeFormPayload);
    setIsFormOpen(true);
  };

  const handleEditHolidayType = async (formPayload: TypeFormPayload) => {
    try {
      await editHolidayType(formPayload).unwrap();
      setIsFormOpen(false);
      dispatch(setSuccessStatus(true));
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{holidayType.name}</Typography>
        </TableCell>
        <TableCell>
          <Chip
            icon={typeIcon}
            label={
              <Typography variant="body2">
                {t(`holidays.${idTranslationKey}`)}
              </Typography>
            }
          />
        </TableCell>
        <TableCell>
          <TitleTooltip title={holidayType.status}>{statusIcon}</TitleTooltip>
        </TableCell>
        <TableCell align="right">
          <EditButton onClick={handleOpenEditForm} />
        </TableCell>
      </TableRow>
      <Dialog open={isFormOpen} fullWidth onClose={() => setIsFormOpen(false)}>
        <TypeForm
          formType={FormTypes.edit}
          initFormValues={editedType}
          isSubmitting={holidayTypePatching}
          onSubmit={handleEditHolidayType}
          setIsOpen={setIsFormOpen}
        />
      </Dialog>
    </>
  );
}
