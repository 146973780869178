import React, { useState } from 'react';

type ValidValue = string | number;

const useMultipleCheckboxSelect = <T extends ValidValue>(initialValue: T[]) => {
  const [selected, setSelected] = useState(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const index = selected.indexOf(value as T);
    if (index > -1) {
      setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
    } else {
      setSelected([...selected, value as T]);
    }
  };

  const handleChangeWithSelectedCheckboxesCallback = (
    event: React.ChangeEvent<HTMLInputElement>,
    callback: (selectedCheckboxes: T[]) => void,
  ) => {
    const { value } = event.target;
    const index = selected.indexOf(value as T);
    if (index > -1) {
      const selectedCheckboxes = [
        ...selected.slice(0, index),
        ...selected.slice(index + 1),
      ];

      setSelected(selectedCheckboxes);
      callback(selectedCheckboxes);
    } else {
      const selectedCheckboxes = [...selected, value as T];

      setSelected(selectedCheckboxes);
      callback(selectedCheckboxes);
    }
  };

  const handleChangeByValue = (value: T) => {
    const index = selected.indexOf(value);
    if (index > -1) {
      setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
    } else {
      setSelected([...selected, value as T]);
    }
  };

  const isSelected = (value: T) => selected.includes(value);

  const toggleSelectAll = (values: T[]) => {
    if (selected.length === values.length) {
      return setSelected([]);
    }

    return setSelected(values);
  };

  return {
    selected,
    setSelected,
    isSelected,
    handleChange,
    handleChangeWithSelectedCheckboxesCallback,
    handleChangeByValue,
    toggleSelectAll,
  };
};

export default useMultipleCheckboxSelect;
