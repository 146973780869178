import { useSelector } from 'react-redux';
import { RootState } from 'store';

export default function useOrganizationStartWeekDay() {
  const organizationConfig = useSelector(
    (state: RootState) => state.organization.organization.config,
  );
  const startWeekDay = organizationConfig?.['organization-week-start-day'] ?? 1;

  return +startWeekDay;
}
