import { ApiResponse } from 'store/interfaces';
import { Candidate } from 'store/candidates/interfaces';

export enum ResourceRequestCandidateState {
  applied = 'applied',
  pending = 'pending',
  rejected = 'rejected',
}

export interface ResourceRequestCandidate {
  '@id': string;
  id: string;
  resourceRequest: string;
  candidate: Candidate;
  state: ResourceRequestCandidateState;
  createdAt: string;
}

export interface ResourceRequestCandidatesData extends ApiResponse {
  'hydra:member': ResourceRequestCandidate[];
}

export interface ResourceRequestCandidateFilters {
  page: number;
  itemsPerPage: number;
  candidate: string | string[];
  resourceRequest: string | string[];
}

export interface ResourceRequestCandidatePostMutationRequest
  extends Omit<
    ResourceRequestCandidate,
    '@id' | 'id' | 'candidate' | 'createdAt'
  > {
  candidate: string;
}

export interface ResourceRequestCandidatePatchMutationRequest
  extends Omit<ResourceRequestCandidate, '@id' | 'candidate' | 'createdAt'> {
  candidate: string;
}
