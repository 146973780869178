import { createAction } from '@reduxjs/toolkit';
import { OrganizationReduxState } from 'store/organizations/defaultState';

export const setOrganization = createAction<OrganizationReduxState>(
  'organization/setOrganization',
);

export const setIsOrganizationChoice = createAction<boolean>(
  'organization/setIsOrganizationChoice',
);
