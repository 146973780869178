import React, { useState } from 'react';
import { Box, FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'store/transactions/transactions';
import fullDateFormat from 'helpers/fullDateFormat';
import { useAddIncomeTransactionMutation } from 'store/incomeTransactions/incomeTransactions';
import { CustomError } from 'store/api';
import { StatusProps } from 'hoc/Status/Status';
import { LoadingButton } from '@mui/lab';
import { IncomeTransaction } from 'store/incomeTransactions/interfaces';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { Invoice } from 'store/incomes/interfaces';

export interface TransactionChoiceProps extends StatusProps {
  income: Invoice;
  incomeTransactions: IncomeTransaction[];
  transactions: Transaction[];
}

function TransactionChoice({
  income,
  setIsError,
  setIsSuccess,
  setErrorMessage,
  transactions,
  incomeTransactions,
}: TransactionChoiceProps) {
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();
  const [chosenTransactionIri, setChosenTransactionIri] = useState('');
  const [amount, setAmount] = useState(income.amount.toFixed(2));
  const [addTransaction, { isLoading }] = useAddIncomeTransactionMutation();

  const getTransactionAmount = (transactionId: string) => {
    const selectedTransaction = transactions.find(
      (transactionData) => String(transactionData.id) === transactionId,
    );
    const pinnedTransaction = incomeTransactions.filter(
      (incomeTransaction) =>
        +incomeTransaction.transaction.id === +transactionId,
    );
    const transactionsSum = pinnedTransaction.reduce(
      (acc, { amount: incomeAmount }) => acc + incomeAmount / 100,
      0,
    );

    if (!selectedTransaction) {
      setAmount(income.amount.toFixed(2));
    } else if (selectedTransaction.amount >= income.amount) {
      setAmount(income.amount.toFixed(2));
    } else {
      setAmount(selectedTransaction.amount.toFixed(2));
    }

    if (pinnedTransaction.length && selectedTransaction) {
      const difference = selectedTransaction.amount - transactionsSum;
      if (difference >= income.amount) {
        setAmount(income.amount.toFixed(2));
      } else {
        setAmount(difference.toFixed(2));
      }
    }
  };

  const getItemLabel = (transaction: Transaction) => {
    const pinnedTransaction = incomeTransactions.filter(
      (incomeTransaction) =>
        transaction.id === incomeTransaction.transaction.id,
    );
    const transactionsSum = pinnedTransaction.reduce(
      (acc, { amount: incomeAmount }) => acc + incomeAmount / 100,
      0,
    );
    const transactionDetails = `${fullDateFormat(
      transaction.orderDate,
    )} ${formatAmount(transaction.amount)}`;

    if (!pinnedTransaction.length) {
      return transactionDetails;
    }

    const differenceAmount = formatAmount(transaction.amount - transactionsSum);

    return `${transactionDetails} (${differenceAmount})`;
  };

  const handleAddTransaction = async () => {
    try {
      await addTransaction({
        amount: +amount * 100,
        income: income['@id'],
        transaction: chosenTransactionIri,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box display="flex" alignItems="center" width="100%">
      <FormControl fullWidth>
        <Select
          displayEmpty
          id="transaction-select"
          onChange={(event) => {
            setChosenTransactionIri(event.target.value);
            getTransactionAmount(event.target.value);
          }}
          value={chosenTransactionIri}
        >
          <MenuItem value="">{t('inputs.choose_transaction')}</MenuItem>
          {transactions &&
            transactions.map((transaction) => (
              <MenuItem key={transaction.id} value={transaction['@id']}>
                {getItemLabel(transaction)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        inputProps={{
          min: 0,
          step: 0.01,
        }}
        onChange={(event) => setAmount(event.target.value)}
        type="number"
        value={amount}
      />
      <LoadingButton
        loading={isLoading}
        onClick={handleAddTransaction}
        variant="contained"
      >
        <span>{t('button.save')}</span>
      </LoadingButton>
    </Box>
  );
}

export default TransactionChoice;
