import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues } from 'react-hook-form';
import { Checkbox, Typography, Box } from '@mui/material';
import { ControlCheckboxProps } from './interfaces';

export default function ControlCheckbox<T extends FieldValues>({
  control,
  name,
  label,
  id,
  inputProps,
  sx,
  required = false,
  disabled = false,
}: ControlCheckboxProps<T>) {
  const { t } = useTranslation();
  const inputId = id ?? name;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, value, onChange } }) => (
        <Box display="flex" alignItems="center">
          <Checkbox
            id={inputId}
            value={value}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            sx={sx}
            inputProps={inputProps}
            disabled={disabled}
          />
          <Typography variant="body1">{t(label)}</Typography>
        </Box>
      )}
    />
  );
}
