import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export interface AddButtonProps {
  onClick: ButtonProps['onClick'];
  label?: string;
  noIcon?: boolean;
  disabled?: ButtonProps['disabled'];
}

export default function AddButton({
  onClick,
  label = 'button.create',
  noIcon = false,
  disabled = false,
}: AddButtonProps) {
  const { t } = useTranslation();
  const labelText = t(label);

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      {...(noIcon ? {} : { endIcon: <AddCircleOutlineIcon /> })}
    >
      {labelText}
    </Button>
  );
}
