import { ContractorGroupsCondition } from 'store/condition/interfaces';
import { CostGroupProps } from 'store/costGroups/costGroups';
import { Cost } from 'store/costs/costs';

export const getGroupName = (groups: CostGroupProps[], groupId: number) => {
  const checkedGroup = groups.find((item) => item.id === groupId);

  if (!checkedGroup) {
    return '';
  }

  return checkedGroup.name;
};

export const getCostName = (costs: Cost[], costId: number) => {
  const matchedCost = costs.find((item) => item.id === costId);

  if (!matchedCost) {
    return '';
  }

  return matchedCost.name;
};

export const calculateMonthlyBalance = (
  month: string,
  data: ContractorGroupsCondition,
) => {
  let sum = 0;

  if (!data[month]) {
    return sum;
  }

  Object.values(data[month]).flatMap((group) =>
    Object.values(group).forEach(({ cost }) => {
      sum += cost;
    }),
  );

  return sum;
};
