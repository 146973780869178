import { startOfDay } from 'date-fns';
import { HolidayRequest } from 'store/holidays/interfaces';
import fullDateFormat from './fullDateFormat';

const getHolidayRequestTimeRange = (request: HolidayRequest) => {
  const isOneDayTimeRange =
    startOfDay(request.dateFrom).getTime() ===
    startOfDay(request.dateTo).getTime();

  return isOneDayTimeRange
    ? fullDateFormat(new Date(request.dateFrom))
    : `${fullDateFormat(new Date(request.dateFrom))} - ${fullDateFormat(
        new Date(request.dateTo),
      )}`;
};

export default getHolidayRequestTimeRange;
