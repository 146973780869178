import api from 'store/api';
import { Employee } from 'store/employee/interfaces';
import { Project } from 'store/projects/interfaces';
import { ApiResponse } from 'store/interfaces';
import { ProjectRatesFilters } from './interfaces';

export interface ProjectRate {
  currency: string;
  dateFrom: Date;
  dateTo: Date | null;
  employee: Employee;
  id: number;
  project: Project;
  rate: number;
  position: string | null;
}

export interface EmployeeProjectRate extends Omit<ProjectRate, 'employee'> {
  employee: number;
}

export interface EmployeeSingleProjectRate extends Omit<ProjectRate, 'project'> {
  project: string;
}

export interface EmployeeProjectRateResponseDto extends ApiResponse {
  'hydra:member': EmployeeProjectRate[];
}

export interface ProjectRateResponseDto extends ApiResponse {
  'hydra:member': EmployeeSingleProjectRate[];
}

export const employeeProjectRate = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjectRates: builder.query<EmployeeSingleProjectRate[], Partial<ProjectRatesFilters>>({
      query: (filters) => ({
        url: '/employee-project-rates',
        params: filters
      }),
      providesTags: ['EmployeeProjectRates'],
      transformResponse: (response: ProjectRateResponseDto) =>
        response['hydra:member'],
    }),
    getProjectRate: builder.query<ProjectRate, number>({
      query: (id) => `/employee-project-rates/${id}`,
      providesTags: ['EmployeeProjectRates'],
    }),
    addProjectRate: builder.mutation({
      query: (body) => ({
        url: '/employee-project-rates',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EmployeeProjectRates'],
    }),
    deleteProjectRate: builder.mutation({
      query: (id: number) => ({
        url: `/employee-project-rates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmployeeProjectRates'],
    }),
    updateProjectRate: builder.mutation<
      ProjectRate,
      Partial<ProjectRate> & Pick<ProjectRate, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/employee-project-rates/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['EmployeeProjectRates'],
    }),
  }),
});

export const {
  useGetProjectRatesQuery,
  useGetProjectRateQuery,
  useAddProjectRateMutation,
  useDeleteProjectRateMutation,
  useUpdateProjectRateMutation,
} = employeeProjectRate;
