import React, { useState, useRef } from 'react';
import {
  TextField,
  InputAdornment,
  Autocomplete,
  IconButton,
  Box,
} from '@mui/material';
import Error from 'common/Error';
import { debounce } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from 'assets/icons/SearchIcon';
import { TagGroup } from 'store/tagGroups/interfaces';
import { useGetAutocompleteTagDefinitionsQuery } from 'store/tagDefinitions/tagDefinitions';
import AutocompleteOption from './AutocompleteOption/AutocompleteOption';
import getTagDefinitionsOptions from './getTagDefinitionsOptions';

interface TagGroupAutocompleteProps {
  tagGroup: TagGroup;
  usedTagDefinitionNames: string[];
  handleOnAdd: (tagDefinition: string) => void;
}

export default function TagGroupAutocomplete({
  tagGroup,
  usedTagDefinitionNames,
  handleOnAdd,
}: TagGroupAutocompleteProps) {
  const [searchPhrase, setSearchPhrase] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const shouldFetchTagDefinitions = searchPhrase.length >= 1;

  const {
    data: tagDefinitionsData,
    isFetching: tagDefinitionsLoading,
    isError: tagDefinitionsError,
  } = useGetAutocompleteTagDefinitionsQuery(
    {
      tagGroupCode: tagGroup.code,
      phrase: searchPhrase,
    },
    {
      skip: !shouldFetchTagDefinitions,
    },
  );

  const tagDefinitions =
    tagDefinitionsData?.['hydra:member'] && shouldFetchTagDefinitions
      ? tagDefinitionsData['hydra:member']
      : [];

  const tagDefinitionsOptions = getTagDefinitionsOptions(
    tagDefinitions,
    usedTagDefinitionNames,
  );

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setSearchPhrase(event.target.value);

  const handleSearchPhrase = debounce(handleChangeInput, 250);

  const handleClearInput = () => {
    setSearchPhrase('');
  };

  if (tagDefinitionsError) {
    return <Error />;
  }

  return (
    <Autocomplete
      id="tag-group-autocomplete"
      freeSolo
      fullWidth
      disableClearable
      filterOptions={(options) => options}
      loading={tagDefinitionsLoading}
      options={tagDefinitionsOptions}
      ref={inputRef}
      value={searchPhrase}
      onChange={async (event, tagValue) => {
        if (!tagValue || typeof tagValue === 'string') {
          return;
        }
        handleOnAdd(tagValue['@id']);
        handleClearInput();
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      renderOption={(props, option) => (
        <Box component="li" {...props} key={`${option.name} - ${option.level}`}>
          <AutocompleteOption option={option} searchPhrase={searchPhrase} />
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleSearchPhrase}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClearInput}
                  sx={{
                    width: '3rem',
                    height: '3rem',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
