import React from 'react';
import { Typography, Stack, Chip } from '@mui/material';
import { Candidate } from 'store/candidates/interfaces';

interface HrCandidateAutocompleteProps {
  candidateOption: Candidate;
}

export default function AutocompleteOption({
  candidateOption,
}: HrCandidateAutocompleteProps) {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack width="50%">
        <Typography variant="body1">
          {candidateOption.firstname} {candidateOption.lastname}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {candidateOption.email}
        </Typography>
      </Stack>
      <Stack direction="row" gap={1} width="50%">
        {candidateOption.tags?.map((tag) => (
          <Chip
            variant="outlined"
            size="small"
            label={
              <Typography variant="chipLabel">
                {tag.tagDefinition.name}
              </Typography>
            }
            key={tag.id}
          />
        ))}
      </Stack>
    </Stack>
  );
}
