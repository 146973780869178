import React, { useState } from 'react';
import { useGetClientDocumentsQuery } from 'store/clients/clients';
import { skipToken } from '@reduxjs/toolkit/query';
import Loader from 'common/Loader';
import Error from 'common/Error';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import { useTranslation } from 'react-i18next';
import headers from 'components/EditClientForm/Documents/headers';
import AddDocumentForm from 'components/EditClientForm/Documents/AddDocumentForm';
import Document from 'components/EditClientForm/Documents/Document';

interface DocumentsProps {
  clientId: number;
}

function Documents({ clientId }: DocumentsProps) {
  const { t } = useTranslation();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const {
    data: clientDocs = [],
    isLoading,
    isError,
  } = useGetClientDocumentsQuery(clientId ?? skipToken);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            <RowAddButton
              colSpan={headers.length + 1}
              onClick={() => setIsAddFormOpen(true)}
            >
              <Typography variant="body2">
                {t('clients.add_document')}
              </Typography>
            </RowAddButton>
            {clientDocs.map((doc) => (
              <Document key={doc.id} document={doc} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        onClose={() => setIsAddFormOpen(false)}
        open={isAddFormOpen}
      >
        <AddDocumentForm setIsOpen={setIsAddFormOpen} />
      </Dialog>
    </>
  );
}

export default Documents;
