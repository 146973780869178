import React, { useState } from 'react';
import { Avatar, Box, Divider, Grid, Tab, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';
import { useGetAgreementQuery } from 'store/agreements/agreements';
import { Agreement } from 'components/AgreementsList/AgreementsList';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import GeneralForm from 'components/EditAgreementForm/GeneralForm';
import Dates from 'components/EditAgreementForm/Dates';
import Files from 'components/EditAgreementForm/Files';
import { useTranslation } from 'react-i18next';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import { useGetPositionsQuery } from 'store/positions/positions';
import { LocationProps } from 'interfaces';
import getBgColorForTab from 'helpers/getBgColorForTab';

function EditAgreementForm() {
  const { id } = useParams<{ id: any }>();
  const { state } = useLocation() as LocationProps;
  const {
    data: agreement = {} as Agreement,
    isSuccess: agreementFetched,
    isLoading: agreementFetching,
    isError: agreementFetchError,
  } = useGetAgreementQuery(id);
  const { selectedEmployee } = useGetSelectedEmployeePreview({
    employeeIri: agreement.employee,
  });
  const {
    data: currencies = [],
    isLoading: currenciesLoading,
    isError: currenciesFetchError,
  } = useGetCurrenciesQuery();
  const {
    data: positions = [],
    isLoading: positionsFetching,
    isError: positionsFetchError,
  } = useGetPositionsQuery();
  const [tabValue, setTabValue] = useState(state?.tabValue || '1');
  const listingTabs = ['2'];
  const { t } = useTranslation();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  if (agreementFetchError || currenciesFetchError || positionsFetchError) {
    return <Error />;
  }

  if (agreementFetching || currenciesLoading || positionsFetching) {
    return <Loader />;
  }

  return (
    <Grid item xs={10} bgcolor={getBgColorForTab(tabValue, listingTabs)}>
      <Box
        display="flex"
        bgcolor="main.white"
        alignItems="center"
        p={3}
        columnGap={3}
      >
        <Avatar
          variant="circular"
          sx={{ width: '8rem', height: '8rem' }}
          src={selectedEmployee?.avatarUrl}
        />
        <Box>
          <Typography variant="h5">
            {selectedEmployee?.firstname} {selectedEmployee?.lastname}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {agreement.variant} | {agreement.type}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('tab.overview')} value="1" />
          <Tab label={t('agreements.edit_tab_files')} value="2" />
        </StyledTabList>
        <TabPanel value="1">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <GeneralForm
                agreement={agreement}
                agreementFetched={agreementFetched}
                currencies={currencies}
                positions={positions}
              />
            </Grid>
            <Grid item xs={6}>
              <Dates
                agreement={agreement}
                agreementFetched={agreementFetched}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Files files={agreement.files} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}

export default EditAgreementForm;
