import api from 'store/api';
import { Agreement } from 'components/AgreementsList/AgreementsList';
import { ApiResponse } from 'store/interfaces';

interface Filters {
  page: number;
  itemsPerPage: number;
  isActive: boolean;
  employee: string;
  type: string[] | string;
  variant: string[] | string;
}

export interface AgreementsData extends ApiResponse {
  'hydra:member': Agreement[];
}

export const agreementsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAgreement: builder.query<Agreement, number>({
      query: (id) => `/agreements/${id}`,
      providesTags: ['Agreements'],
    }),
    addAgreement: builder.mutation({
      query: (body) => ({
        url: '/agreements',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements', 'Employees'],
    }),
    updateAgreement: builder.mutation<
      Agreement,
      Partial<Agreement> & Pick<Agreement, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/agreements/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Agreements', 'Employees'],
    }),
    getActionNeededAgreements: builder.query<Agreement[], void>({
      query: () => '/agreements/action-needed',
      providesTags: ['Agreements'],
      transformResponse: (response: AgreementsData) => response['hydra:member'],
    }),
    getFilteredAgreements: builder.query<AgreementsData, Partial<Filters>>({
      query: (filters) => ({
        url: '/agreements',
        params: filters,
      }),
      providesTags: ['Agreements'],
      transformResponse: (response: AgreementsData) => {
        const sortedAgreements = response['hydra:member']
          .sort((agreement, comparedAgreement) =>
            agreement.dateFrom.localeCompare(comparedAgreement.dateFrom),
          )
          .reverse();
        return { ...response, 'hydra:member': sortedAgreements };
      },
    }),
  }),
});

export const {
  useAddAgreementMutation,
  useGetAgreementQuery,
  useUpdateAgreementMutation,
  useGetActionNeededAgreementsQuery,
  useGetFilteredAgreementsQuery,
} = agreementsApi;
