import { Box, styled } from '@mui/material';

const Logo = styled(Box)(({ theme }) => ({
  height: '8rem',
  borderBottom: `0.1rem solid ${theme.palette.secondary.light}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '2.4rem',
  position: 'relative',
  '& img': {
    height: '2.9rem',
    width: '10.6rem',
  },
}));

export default Logo;
