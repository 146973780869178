import { ComponentType } from 'react';
import {
  setErrorStatus,
  setSuccessStatus,
  setErrorMessage,
  setErrorCatch,
} from 'store/status/actions';
import { connect } from 'react-redux';
import { RootState } from 'store';
import {
  getErrorMessage,
  getErrorStatus,
  getSuccessStatus,
} from 'store/status/selectors';

export interface StatusProps {
  setIsSuccess: (status: boolean) => boolean;
  setIsError: (status: boolean) => boolean;
  setErrorMessage: (message: string) => string;
  setIsErrorCatch: (error: unknown) => void;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}

/**
 * @deprecated Use `useDispatchApp` instead
 */
export function withStatus<BaseProps>(BaseComponent: ComponentType<BaseProps>) {
  const mapStateToProps = (state: RootState) => ({
    isSuccess: getSuccessStatus(state),
    isError: getErrorStatus(state),
    errorMessage: getErrorMessage(state),
  });

  const mapDispatchToProps = (dispatch: any) => ({
    setIsSuccess: (status: boolean) => dispatch(setSuccessStatus(status)),
    setIsError: (status: boolean) => dispatch(setErrorStatus(status)),
    setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
    setIsErrorCatch: (error: unknown) => dispatch(setErrorCatch(error)),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
    // @ts-ignore
  )(BaseComponent);
}
