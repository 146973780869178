import { TableHeadersProps } from 'interfaces';

interface HeadersProps extends TableHeadersProps {
  width: string;
}

const tableHeaders: HeadersProps[] = [
  {
    label: 'agreements.header_employee',
    align: 'left',
    width: '33%'
  },
  {
    label: 'label.role',
    align: 'left',
    width: '30%'
  },
];

export default tableHeaders;
