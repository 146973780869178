import React from 'react';
import {
  useGetWorkingHoursStatsQuery,
  WorkingHoursStats,
} from 'store/workingHours/workingHours';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import {
  WidgetCaption,
  WidgetCaptionContainer,
  WidgetContainer,
  WidgetTitle,
} from 'common/Widget';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import { LoggedEmployee } from 'store/employee/interfaces';

function UserWorkingHours() {
  const { t } = useTranslation();
  const currentDate = new Date();
  const {
    data: currentUser = {} as LoggedEmployee,
    isLoading: currentUserLoading,
    isError: currentUserError,
  } = useGetLoggedEmployeeQuery();
  const {
    data: workingHours = {} as WorkingHoursStats,
    isLoading: workingHoursLoading,
    isError: workingHoursError,
    isSuccess: workingHoursSuccess,
  } = useGetWorkingHoursStatsQuery(
    {
      id: currentUser.id,
      date: dateFormat(currentDate, 'yyyy-mm'),
    },
    {
      skip: !currentUser.id,
    },
  );

  const monthHoursDetails = [
    {
      label: 'dashboard.days_off_hourly',
      value: workingHours.daysOffHourly,
    },
    {
      label: 'dashboard.month_hours_filled',
      value: workingHours.monthHoursFilled,
    },
    {
      label: 'dashboard.month_hours_to_filled',
      value: workingHours.monthHoursToBeFilled,
    },
  ];

  return (
    <WidgetContainer
      title={<WidgetTitle>{t('dashboard.working_hours')}</WidgetTitle>}
      isError={workingHoursError || currentUserError}
      isLoading={workingHoursLoading || currentUserLoading}
    >
      {workingHoursSuccess &&
        monthHoursDetails.map((data) => (
          <WidgetCaptionContainer key={data.label}>
            <WidgetCaption>{t(data.label)}:</WidgetCaption>
            <WidgetCaption>{data.value}h</WidgetCaption>
          </WidgetCaptionContainer>
        ))}
    </WidgetContainer>
  );
}

export default UserWorkingHours;
