import dateFormat from 'dateformat';
import getAmountMonthsBack from 'helpers/getAmountMonthsBack';
import { ChartIncome } from 'pages/IncomeChart/useGetChartIncome';
import { CustomFormatterParam } from 'config/material-ui/getChartOptions';

const twoYearsInMonths = 24;

export default function getChartData(
  incomes: ChartIncome[],
  getClientName: (clientIri: string) => string,
  formatAmount: (
    value: number,
    currency?: string,
    minimumFractionDigits?: number,
  ) => string,
) {
  const displayedPeriod = getAmountMonthsBack(new Date(), twoYearsInMonths);

  const convertedIncomes = incomes.map((income) => ({
    ...income,
    client: getClientName(income.client),
  }));

  const incomesGroupedByClient = convertedIncomes.reduce(
    (acc: Record<string, ChartIncome[]>, income: ChartIncome) => {
      if (!acc[income.client]) {
        acc[income.client] = [];
      }
      acc[income.client].push(income);
      return acc;
    },
    {},
  );

  const getTotalNetAmountFromClientPeriod = (client: string, period: string) =>
    incomesGroupedByClient[client].reduce(
      (acc, income) =>
        dateFormat(new Date(income.saleDate), 'yyyy-mm') === period
          ? acc + income.targetAmountNet
          : acc,
      0,
    );

  const incomesByMonthGroupedByClient = Object.keys(
    incomesGroupedByClient,
  ).reduce((acc: Record<string, Record<string, number>>, client) => {
    acc[client] = displayedPeriod.reduce(
      (clientAcc: Record<string, number>, period) => {
        // eslint-disable-next-line no-param-reassign
        clientAcc[period] = getTotalNetAmountFromClientPeriod(client, period);
        return clientAcc;
      },
      {},
    );
    return acc;
  }, {});

  const seriesData = Object.keys(incomesByMonthGroupedByClient).map(
    (client) => ({
      name: client,
      type: 'bar',
      stack: 'amount',
      data: displayedPeriod.map(
        (period) => incomesByMonthGroupedByClient[client][period],
      ),
    }),
  );

  const chartOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter(params: CustomFormatterParam[]) {
        let tooltipText = '';
        params.forEach((param) => {
          if (param.data !== 0) {
            const clientTotalAmount =
              incomesByMonthGroupedByClient[param.seriesName][param.name];

            tooltipText += `${param.marker} ${param.seriesName}: ${formatAmount(
              clientTotalAmount,
            )}<br/>`;
          }
        });
        return tooltipText;
      },
    },
    grid: {
      left: '1%',
      right: '1%',
      top: '5%',
      bottom: '5%',
      containLabel: true,
    },
    xAxis: {
      axisTick: {
        alignWithLabel: true,
      },
      type: 'category',
      data: displayedPeriod,
      axisLabel: {
        interval: 0,
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
      splitNumber: 4,
    },
    series: seriesData,
  };

  return {
    displayedPeriod,
    chartOptions,
    incomesGroupedByClient,
    getTotalNetAmountFromClientPeriod,
  };
}
