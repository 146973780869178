import { Box, BoxProps, styled } from '@mui/material';

const StyledBarHeaderWrapper = styled(Box)<BoxProps>(() => ({
  marginLeft: '10rem',
  marginTop: '2.4rem',
  width: 'calc(100vw - 28rem)',
  height: '16rem',
  alignItems: 'center',
  justifyContent: 'space-around',
}));

export default StyledBarHeaderWrapper;
