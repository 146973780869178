import React from 'react';
import {
  DialogContent,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import useBudgetPlannerContext from 'pages/BudgetPlanner/context/useBudgetPlannerContext';
import useCurrencySymbol from 'hooks/useCurrencySymbol';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { FrontendBudgetConfig } from 'pages/BudgetPlanner/context/BudgetPlanner.context';
import useFormatBudgetChartPeriod from 'hooks/useFormatBudgetChartPeriod';
import calculateTotalAmountBudgetConfig from '../calculateTotalAmountBudgetConfig';

interface BudgetConfigPeriodFormProps {
  onClose: () => void;
  selectedPeriodData: FrontendBudgetConfig;
}

export default function BudgetConfigPeriodForm({
  onClose,
  selectedPeriodData,
}: BudgetConfigPeriodFormProps) {
  const { t } = useTranslation();
  const getCurrencySymbol = useCurrencySymbol();
  const formatAmount = useCurrencyFormat();
  const currencySymbol = getCurrencySymbol();

  const totalAmount = calculateTotalAmountBudgetConfig(selectedPeriodData);
  const { onUpdateBudgetConfig } = useBudgetPlannerContext();
  const formatPeriod = useFormatBudgetChartPeriod();

  return (
    <>
      <DialogHeader
        title={formatPeriod(selectedPeriodData.period)}
        onClose={onClose}
      />
      <DialogContent>
        <Stack spacing={4} alignItems="center" mt={1.5}>
          <Stack width="100%">
            <TextField
              type="number"
              name="baseAmount"
              label={t('budget.base_amount')}
              placeholder={
                selectedPeriodData.placeholderBaseAmount
                  ? selectedPeriodData.placeholderBaseAmount.toFixed(2)
                  : ''
              }
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                onUpdateBudgetConfig({
                  period: selectedPeriodData.period,
                  field: event.target.name,
                  value: event.target.value,
                });
              }}
              value={selectedPeriodData.baseAmount ?? ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      mr: 1,
                      '& .MuiTypography-root': {
                        color:
                          selectedPeriodData.baseAmount !== undefined
                            ? 'text.primary'
                            : 'text.secondary',
                      },
                    }}
                    position="end"
                  >
                    {currencySymbol}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <Typography
              variant="inputHelper"
              color="text.secondary"
              px={2}
              pt={0.5}
            >
              {t('budget.base_amount_description')}
            </Typography>
          </Stack>
          <TextField
            type="number"
            name="exponentialGrowth"
            label={t('budget.exponential_growth')}
            placeholder={
              selectedPeriodData.placeholderExponentialGrowth
                ? selectedPeriodData.placeholderExponentialGrowth.toString()
                : ''
            }
            value={selectedPeriodData.exponentialGrowth ?? ''}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              onUpdateBudgetConfig({
                period: selectedPeriodData.period,
                field: event.target.name,
                value: event.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    mr: 1,
                    '& .MuiTypography-root': {
                      color:
                        selectedPeriodData.exponentialGrowth !== undefined
                          ? 'text.primary'
                          : 'text.secondary',
                    },
                  }}
                  position="end"
                >
                  %
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <TextField
            type="number"
            name="linearGrowth"
            label={t('budget.linear_growth')}
            placeholder={
              selectedPeriodData.placeholderLinearGrowth
                ? selectedPeriodData.placeholderLinearGrowth.toFixed(2)
                : ''
            }
            value={selectedPeriodData.linearGrowth ?? ''}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              onUpdateBudgetConfig({
                period: selectedPeriodData.period,
                field: event.target.name,
                value: event.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    mr: 1,
                    '& .MuiTypography-root': {
                      color:
                        selectedPeriodData.linearGrowth !== undefined
                          ? 'text.primary'
                          : 'text.secondary',
                    },
                  }}
                  position="end"
                >
                  {currencySymbol}
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <TextField
            type="number"
            name="unitGrowth"
            label={t('budget.unit_growth')}
            value={selectedPeriodData.unitGrowth ?? ''}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              onUpdateBudgetConfig({
                period: selectedPeriodData.period,
                field: event.target.name,
                value: event.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    mr: 1,
                    '& .MuiTypography-root': {
                      color:
                        selectedPeriodData.unitGrowth !== undefined
                          ? 'text.primary'
                          : 'text.secondary',
                    },
                  }}
                  position="end"
                >
                  {currencySymbol}
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <Stack alignItems="flex-end" width="100%" spacing={1}>
            <Typography variant="body2" color="text.secondary">
              {t('budget.budget_for_month')}
            </Typography>
            <Typography variant="h6">{formatAmount(+totalAmount)}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </>
  );
}
