import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TabContextProps, useTabContext } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useGetCostsQuery } from 'store/costs/costs';
import { CostStatuses, CostsTabValue } from 'components/CostList/enums';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import tableHeaders from 'components/CostList/tableHeaders';
import Result from './Result/Result';

export default function CostList() {
  const { value: tabValue } = useTabContext() as TabContextProps;
  const { t } = useTranslation();

  const {
    data: costs = [],
    isError: costsFetchError,
    isFetching: costsFetching,
  } = useGetCostsQuery({
    status:
      tabValue === CostsTabValue.active
        ? CostStatuses.active
        : CostStatuses.outdated,
    // TODO: add cyclic property when backend is ready
    properties: ['id', 'name', 'costGroup', 'description', 'status'],
  });

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={tableHeaders.length} />
          </TableRow>
        </TableHead>
        <TableBodyContent
          isError={costsFetchError}
          isFetching={costsFetching}
          tableHeaders={tableHeaders}
        >
          {costs.map((cost) => (
            <Result key={cost.id} cost={cost} />
          ))}
        </TableBodyContent>
      </Table>
    </TableContainer>
  );
}
