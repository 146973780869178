import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClientsList from 'components/ClientsList/ClientsList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddClientForm from 'components/AddClientForm';

export default function Clients() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <NavBarTitle>{t('clients.client')}</NavBarTitle>
        <Button
          variant="contained"
          onClick={() => setIsOpen(!isOpen)}
          endIcon={<AddCircleOutlineIcon />}
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <ClientsList />
      <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} fullWidth>
        <AddClientForm setIsOpen={setIsOpen} />
      </Dialog>
    </Grid>
  );
}
