import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  {
    label: 'label.type',
    align: 'left',
  },
  {
    label: 'label.name',
    align: 'left',
  },
  {
    label: 'clients.document_attached_file',
    align: 'center',
  },
];

export default headers;
