import { ApiResponse } from 'store/interfaces';
import { Tag } from 'store/tags/interfaces';
import { StorageResponse } from 'store/storage/storage';

export interface Candidate {
  '@id': string;
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber?: string;
  source?: string;
  birthDate?: Date;
  linkedinProfile?: string;
  expectations?: string;
  tags?: Tag[];
  location?: string;
  files: StorageResponse[] | null;
  createdAt: string;
}

export interface CandidatesData extends ApiResponse {
  'hydra:member': Candidate[];
}

export interface CandidateFilters {
  page: number;
  itemsPerPage: number;
}

export interface CandidatePostMutationRequest
  extends Omit<
    Candidate,
    '@id' | 'id' | 'tags' | 'createdAt' | 'birthDate'
  > {
  birthDate?: string | Date | null;
}

export interface CandidatePatchMutationRequest
  extends Partial<Omit<Candidate, '@id' | 'tags' | 'birthDate'>> {
  birthDate?: string | Date | null;
}

export enum CandidateRelationName {
  resume = 'candidate-cvs',
}
