/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';

interface BadgeProps {
  children: React.ReactNode;
  bgcolor: string;
  border?: string;
  borderColor?: string;
  iconColor: string;
  title: string;
  titleOptions?: TOptions | string,
}

function Badge({
  children,
  bgcolor,
  border,
  borderColor,
  iconColor,
  title,
  titleOptions,
}: BadgeProps) {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center">
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'main.white',
              boxShadow: 5,
              color: 'text.primary',
            },
          },
        }}
        placement="left-start"
        title={<Typography variant="body1">{t(title, titleOptions)}</Typography>}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="0.8rem"
          height="3.6rem"
          width="3.6rem"
          bgcolor={bgcolor}
          border={border}
          borderColor={borderColor}
          sx={{
            '& svg': {
              color: iconColor,
            },
          }}
        >
          {children}
        </Box>
      </Tooltip>
    </Box>
  );
}

export default Badge;
