import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import BriefCaseIcon from 'assets/icons/BriefCaseIcon';
import DesignToolsIcon from 'assets/icons/DesignToolsIcon';
import TimeMaterialsIcon from 'assets/icons/TimeMaterialsIcon';
import ClientName from 'components/ProjectsList/ClientName/ClientName';
import { projectTypes } from 'components/ProjectsList/projectsSelectData';
import OverviewTile from './OverviewTile/OverviewTile';
import OverviewNotes from './OverviewNotes/OverviewNotes';
import useEditProjectFormContext from '../context/useEditProjectFormContext';

export default function Overview() {
  const { t } = useTranslation();
  const { project } = useEditProjectFormContext();

  const projectTypeLabel = projectTypes.find(
    (type) => type.id === project.type,
  )?.description;

  return (
    <Grid container spacing={3}>
      <OverviewTile
        label={t('label.client')}
        icon={
          <BriefCaseIcon sx={{ fontSize: '2.5rem', color: 'primary.main' }} />
        }
      >
        {project.client ? (
          <ClientName variant="subtitle1" clientIri={project.client} />
        ) : (
          <Typography variant="subtitle1">
            {t('general.placeholder')}
          </Typography>
        )}
      </OverviewTile>
      <OverviewTile
        label={t('projects.parent_project')}
        icon={
          <DesignToolsIcon sx={{ fontSize: '2.5rem', color: 'primary.main' }} />
        }
      >
        {project.parent ? (
          <Typography variant="subtitle1">{project.parent.name}</Typography>
        ) : (
          <Typography variant="subtitle1">
            {t('general.placeholder')}
          </Typography>
        )}
      </OverviewTile>
      <OverviewTile
        label={t('projects.project_type')}
        icon={
          <TimeMaterialsIcon
            sx={{ fontSize: '2.5rem', color: 'primary.main' }}
          />
        }
      >
        <Typography
          variant="subtitle1"
          color={projectTypeLabel ? 'text.primary' : 'text.disabled'}
        >
          {projectTypeLabel ? t(projectTypeLabel) : t('general.placeholder')}
        </Typography>
      </OverviewTile>
      <OverviewNotes />
    </Grid>
  );
}
