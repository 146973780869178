const monthLabels = [
  'label.january',
  'label.february',
  'label.march',
  'label.april',
  'label.may',
  'label.june',
  'label.july',
  'label.august',
  'label.september',
  'label.october',
  'label.november',
  'label.december',
];

export default monthLabels;
