import api from 'store/api';
import {
  ResponsibilitiesFilters,
  Responsibility,
  ResponsibilityGroup,
  ResponsibleEmployee,
  ResponsibilityGroupsResponseDto,
  ResponsibilitiesResponseDto,
  ResponsibleEmployeesResponseDto,
} from './interfaces';

export const responsibilityGroupsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<ResponsibilityGroup[], void>({
      query: () => '/responsibility-groups',
      providesTags: ['Responsibilities'],
      transformResponse: (response: ResponsibilityGroupsResponseDto) =>
        response['hydra:member'],
    }),
    updateResponsibility: builder.mutation<
      Responsibility,
      Partial<Responsibility> & Pick<Responsibility, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/responsibilities/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Responsibilities'],
    }),
    addPerson: builder.mutation({
      query: (body) => ({
        url: '/responsibility-employees',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Responsibilities'],
    }),
    deletePerson: builder.mutation({
      query: (id: number) => ({
        url: `/responsibility-employees/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Responsibilities'],
    }),
    addResponsibility: builder.mutation({
      query: (body) => ({
        url: '/responsibilities',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Responsibilities'],
    }),
    getResponsibilities: builder.query<
      Responsibility[],
      Partial<ResponsibilitiesFilters>
    >({
      query: (filters) => ({
        url: '/responsibilities',
        params: filters,
      }),
      providesTags: ['Responsibilities'],
      transformResponse: (response: ResponsibilitiesResponseDto) =>
        response['hydra:member'],
    }),
    getEmployeeResponsibilities: builder.query<
      ResponsibleEmployee[],
      Partial<ResponsibilitiesFilters>
    >({
      query: (filters) => ({
        url: '/responsibility-employees',
        params: filters,
      }),
      transformResponse: (response: ResponsibleEmployeesResponseDto) =>
        response['hydra:member'],
      providesTags: ['Responsibilities'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useUpdateResponsibilityMutation,
  useAddPersonMutation,
  useDeletePersonMutation,
  useAddResponsibilityMutation,
  useGetResponsibilitiesQuery,
  useGetEmployeeResponsibilitiesQuery,
} = responsibilityGroupsApi;
