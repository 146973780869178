import monthLabels from 'helpers/monthLabels';
import { useTranslation } from 'react-i18next';

export default function useFormatBudgetChartPeriod() {
  const { t } = useTranslation();

  const formatPeriod = (period: string) => {
    const [year, month] = period.split('.');
    const monthLabel = monthLabels[parseInt(month, 10) - 1];

    return `${t(monthLabel)} ${year}`;
  };

  return formatPeriod;
}
