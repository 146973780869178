import React, { useState } from 'react';
import { Grid, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { StatusProps } from 'hoc/Status/Status';
import {
  Transaction,
  useUpdateTransactionMutation,
} from 'store/transactions/transactions';
import { useGetCostsQuery } from 'store/costs/costs';
import {
  useAddBudgetTransactionMutation,
  useGetBudgetsQuery,
  useUpdateBudgetTransactionMutation,
  useRemoveBudgetTransactionMutation,
} from 'store/budget/budget';
import { CustomError } from 'store/api';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import replaceApiIri from 'helpers/replaceApiIri';
import { useTranslation } from 'react-i18next';
import TransactionSelect from 'components/TransactionsList/TransactionSelect/TransactionSelect';
import TransactionBudgetSelect from 'components/TransactionsList/TransactionBudgetSelect/TransactionBudgetSelect';
import TransactionEmployeeSelect from 'components/TransactionsList/TransactionEmployeeSelect/TransactionEmployeeSelect';
import Attachment from 'components/TransactionsList/Attachment/Attachment';
import Balance from 'components/TransactionsList/Balance/Balance';
import Dates from 'components/TransactionsList/Dates/Dates';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import useCheckBudgetIsEnabled from 'hooks/useCheckBudgetIsEnabled';

export interface TransactionItemProps extends StatusProps {
  transaction: Transaction;
  isMissingTransaction: boolean;
  isAllTransactionsList: boolean;
}

function TransactionItem({
  transaction,
  isMissingTransaction,
  isAllTransactionsList,
  setIsSuccess,
  setIsErrorCatch,
}: TransactionItemProps) {
  const { t } = useTranslation();

  const isBudgetEnabled = useCheckBudgetIsEnabled();

  const { data: costs = [] } = useGetCostsQuery({ properties: ['id', 'name'] });
  const { data: budgets } = useGetBudgetsQuery({}, { skip: !isBudgetEnabled });
  const { data: employees = [] } = useGetEmployeesPreview(
    { isActive: true },
    { skip: !isBudgetEnabled },
  );

  const budgetTransaction = transaction?.budgetTransactions[0];

  const budgetData = budgets?.['hydra:member'] || [];

  const [cost, setCost] = useState(
    transaction.cost ? transaction.cost['@id'] : '',
  );

  const [budget, setBudget] = useState(budgetTransaction?.budget ?? '');

  const [employee, setEmployee] = useState(budgetTransaction?.employee ?? '');

  const budgetTransactionAmount = budgetTransaction?.amount
    ? budgetTransaction.amount / BACKEND_AMOUNT_MODIFIER
    : '';

  const [amount, setAmount] = useState(budgetTransactionAmount.toString());

  const [updateTransaction] = useUpdateTransactionMutation();

  const [addBudgetTransaction, { isSuccess }] =
    useAddBudgetTransactionMutation();
  const [removeBudgetTransaction] = useRemoveBudgetTransactionMutation();
  const [updateBudgetTransaction] = useUpdateBudgetTransactionMutation();

  const budgetsToAdd = budgetData.map((budgetItem) => ({
    id: budgetItem['@id'],
    name: budgetItem.name,
  }));

  const handleUpdateTransaction = async (event: SelectChangeEvent) => {
    setCost(event.target.value);
    try {
      await updateTransaction({
        id: transaction.id,
        cost: event.target.value || null,
      });
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  const handleRemoveBudgetTransaction = async () => {
    if (!budgetTransaction?.['@id']) {
      return;
    }
    try {
      await removeBudgetTransaction({
        id: replaceApiIri(budgetTransaction?.['@id'], 'budget-transactions'),
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  const handleAddBudgetTransaction = async (budgetIri: string) => {
    if (!budgetIri) {
      return;
    }
    try {
      await addBudgetTransaction({
        budget: budgetIri,
        transaction: transaction?.['@id'],
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  const handleUpdateTransactionBudget = async (event: SelectChangeEvent) => {
    setBudget(event.target.value);
    if (!event.target.value) {
      await handleRemoveBudgetTransaction();
      return;
    }
    await handleAddBudgetTransaction(event.target.value);
  };

  const handleUpdateTransactionEmployee = async (event: SelectChangeEvent) => {
    setEmployee(event.target.value);
    if (!budgetTransaction?.['@id']) {
      return;
    }
    try {
      await updateBudgetTransaction({
        id: replaceApiIri(budgetTransaction?.['@id'], 'budget-transactions'),
        employee: event.target.value || null,
        ...(amount && {
          amount: +amount * BACKEND_AMOUNT_MODIFIER,
        }),
      }).unwrap();
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };
  const handleUpdateTransactionAmount = async () => {
    if (amount) {
      if (
        +amount === budgetTransaction.amount / BACKEND_AMOUNT_MODIFIER ||
        !budgetTransaction['@id']
      ) {
        return;
      }
      try {
        await updateBudgetTransaction({
          id: replaceApiIri(budgetTransaction?.['@id'], 'budget-transactions'),
          amount: +amount * BACKEND_AMOUNT_MODIFIER,
        }).unwrap();
      } catch (error) {
        setIsErrorCatch(error as CustomError);
      }
    }
  };

  return (
    <>
      {isMissingTransaction && isAllTransactionsList && (
        <Grid item xs={12} p={2}>
          <Typography color="error" variant="bold">
            {t('transactions.missing')}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        bgcolor="main.white"
        border="0.1rem solid"
        borderColor="border.light"
        borderRadius="1.6rem"
        p={2}
      >
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Typography color="secondary" variant="body1">
              #{transaction.id}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Dates
            transaction={transaction}
            size={isBudgetEnabled ? 'md' : 'lg'}
          />
          <Grid item xs={!isBudgetEnabled ? 3 : 2}>
            <TransactionSelect
              fullWidth
              selectId="cost-select"
              value={cost}
              notChosen={!cost}
              onChangeFn={handleUpdateTransaction}
              placeholder="transactions.contractor_type"
              selectOptions={costs.map((costData) => ({
                id: costData['@id'],
                description: costData.name,
              }))}
            />
          </Grid>
          {isBudgetEnabled && (
            <>
              <Grid item xs={2}>
                <TransactionBudgetSelect
                  selectId="budget-select"
                  value={budget}
                  onChangeFn={handleUpdateTransactionBudget}
                  placeholder="transactions.transaction_budget"
                  selectOptions={budgetsToAdd}
                />
              </Grid>
              <Grid item xs={2}>
                <TransactionEmployeeSelect
                  selectId="employee-select"
                  disabled={!budget && !isSuccess}
                  value={employee}
                  onChangeFn={handleUpdateTransactionEmployee}
                  placeholder="transactions.transaction_employee"
                  selectOptions={employees.map((employeeItem) => ({
                    id: employeeItem['@id'],
                    name: `${employeeItem.lastname} ${employeeItem.firstname}`,
                  }))}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  value={amount?.toString()}
                  disabled={!budget && !isSuccess}
                  label={t('transactions.transaction_amount')}
                  onBlur={handleUpdateTransactionAmount}
                  onChange={(event) => setAmount(event.target.value)}
                  type="number"
                />
              </Grid>
            </>
          )}
          <Balance
            transaction={transaction}
            size={isBudgetEnabled ? 'md' : 'lg'}
          />
        </Grid>
        <Grid container mt={2} spacing={2} alignItems="center">
          <Grid item xs={9}>
            <Typography variant="body1">{transaction.description}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Attachment transaction={transaction} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default TransactionItem;
