import { Box, BoxProps, styled } from '@mui/material';
import gradients from 'config/material-ui/gradients';

const StyledOptionWrapper = styled(Box)<BoxProps>(() => ({
  overflow: 'hidden',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    right: 0,
    bottom: 0,
    width: '12rem',
    background: gradients.TRANSPARENT_TO_WHITE,
  },
}));

export default StyledOptionWrapper;
