import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SendIcon from 'assets/icons/SendIcon';

export interface SendButtonProps {
  label?: string;
  disabled?: boolean;
}

export default function SendButton({
  label = 'button.send',
  disabled = false,
}: SendButtonProps) {
  const { t } = useTranslation();
  const labelText = t(label);

  return (
    <Button
      type="submit"
      variant="contained"
      endIcon={
        <SendIcon
          sx={{
            fontSize: '2rem',
            color: disabled ? 'text.disabled' : 'main.white',
          }}
        />
      }
      disabled={disabled}
    >
      {labelText}
    </Button>
  );
}
