import { Grid } from '@mui/material';
import React from 'react';
import Summary from './Summary/Summary';
import Requirements from './Requirements/Requirements';

export default function ResourceOverview() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={7.5}>
        <Summary />
        <Requirements />
      </Grid>
    </Grid>
  );
}
