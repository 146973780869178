import { CandidateFormSourceType } from 'components/CandidateForm/interfaces';

const candidateFormSourceOptions: string[] = [
  CandidateFormSourceType.email_direct,
  CandidateFormSourceType.just_join_it,
  CandidateFormSourceType.linked_in,
  CandidateFormSourceType.rocket_jobs
]

export default candidateFormSourceOptions;
