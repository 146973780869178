import api from 'store/api';
import { CostGroupProps } from 'store/costGroups/costGroups';
import { DocumentData, DocumentsResponseDto } from 'store/documents/documents';
import { ApiFilters, ApiResponse } from 'store/interfaces';
import {
  Transaction,
  TransactionAttachment,
} from 'store/transactions/transactions';

export interface Cost {
  ['@id']: string;
  costGroup: CostGroupProps;
  cyclic: boolean;
  description: string;
  fixedAmount: boolean;
  id: number;
  matchRegex: string | null;
  name: string;
  notIncludeInChart: boolean;
  ownerEmployee: string | null;
  paymentDeadline: number | null;
  requireAttachment: boolean;
  status: string;
  taxAmount: number | null;
  tin: string | null;
  budget: string;
  createdAt: Date;
}

export interface CostsResponseDto extends ApiResponse {
  'hydra:member': Cost[];
}

export interface EditCostToSend extends Omit<Cost, 'costGroup'> {
  costGroup: string;
}

interface CostFilters extends ApiFilters<Cost> {
  status: string;
}

interface NipValidationMutationRequest {
  nip: string;
}

interface NipValidation {
  '@id': string;
  name: string;
  province: string;
  district: string;
  community: string;
  city: string;
  postCode: string;
  street: string;
}

export interface PaymentDue extends Cost {
  transactionAttachments: TransactionAttachment[];
  transactions: Transaction[];
}

export interface PaymentDueResponseDto extends ApiResponse {
  'hydra:member': PaymentDue[];
}

export const costsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCosts: builder.query<Cost[], Partial<CostFilters>>({
      query: (filter) => ({
        url: '/costs',
        params: filter,
      }),
      providesTags: ['Costs'],
      transformResponse: (response: CostsResponseDto) =>
        response['hydra:member'].sort((cost, comparedCost) => {
          let sortedByStatus = 0;

          if (cost.status) {
            sortedByStatus = cost.status.localeCompare(comparedCost.status);
          }

          const sortedByName = cost.name.localeCompare(comparedCost.name);

          return sortedByStatus !== 0 ? sortedByStatus : sortedByName;
        }),
    }),
    getCost: builder.query<Cost, string>({
      query: (id) => `/costs/${id}`,
      providesTags: ['Costs'],
    }),
    addCost: builder.mutation({
      query: (body) => ({
        url: '/costs',
        method: 'POST',
        headers: { 'content-type': 'application/ld+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Costs'],
    }),
    updateCost: builder.mutation<
      EditCostToSend,
      Partial<EditCostToSend> & Pick<EditCostToSend, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/costs/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Costs'],
    }),
    getCostsDocuments: builder.query<DocumentData[], number>({
      query: (id) => `/costs/${id}/documents`,
      providesTags: ['Documents'],
      transformResponse: (response: DocumentsResponseDto) =>
        response['hydra:member'],
    }),
    getCostsPaymentDue: builder.query<PaymentDue[], string>({
      query: (date) => `/costs/payment-due/${date}`,
      providesTags: ['PaymentDue'],
      transformResponse: (response: PaymentDueResponseDto) =>
        response['hydra:member'],
    }),
    nipValidation: builder.mutation<
      NipValidation | [],
      NipValidationMutationRequest
    >({
      query: (body) => ({
        url: '/contractors/nip-validation',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetCostsQuery,
  useGetCostQuery,
  useUpdateCostMutation,
  useAddCostMutation,
  useGetCostsDocumentsQuery,
  useGetCostsPaymentDueQuery,
  useNipValidationMutation,
} = costsApi;
