import api from 'store/api';
import {
  Invoice,
  InvoiceUpdate,
  DraftInvoice,
  EmailData,
  DraftIncomeFilters,
  IncomesFilters,
  InvoicesData,
} from './interfaces';

export const incomesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIncomes: builder.query<InvoicesData, Partial<IncomesFilters>>({
      query: (filters) => ({
        url: '/incomes',
        params: filters,
      }),
      providesTags: ['Incomes'],
    }),
    getIncome: builder.query<Invoice, number>({
      query: (id) => `/incomes/${id}`,

      providesTags: ['Incomes'],
    }),
    addIncome: builder.mutation({
      query: (body) => ({
        url: '/incomes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Incomes'],
    }),
    updateIncome: builder.mutation<
      Invoice,
      Partial<InvoiceUpdate> & Pick<InvoiceUpdate, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/incomes/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Incomes'],
    }),
    getIncomeName: builder.query<Pick<Invoice, 'name'>, string>({
      query: (date) => `/incomes/name/${date}`,

      providesTags: ['Incomes'],
    }),
    getDraftIncome: builder.query<DraftInvoice, Partial<DraftIncomeFilters>>({
      query: (filters) => ({
        url: '/invoices/draft',
        params: filters,
      }),
      providesTags: ['Incomes'],
    }),
    sendEmail: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/invoices/${id}/send`,
        method: 'POST',
        body,
      }),
    }),
    getEmailData: builder.query<EmailData, number>({
      query: (id) => `/invoices/${id}/send`,
      providesTags: ['InvoicesSend']
    }),
    deleteInvoice: builder.mutation({
      query: (id) => ({
        url: `/invoices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Incomes'],
    }),
  }),
});

export const {
  useGetIncomesQuery,
  useGetIncomeQuery,
  useAddIncomeMutation,
  useUpdateIncomeMutation,
  useGetIncomeNameQuery,
  useGetDraftIncomeQuery,
  useSendEmailMutation,
  useGetEmailDataQuery,
  useDeleteInvoiceMutation,
} = incomesApi;
