import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface TransactionEmployeeSelectProps {
  selectId: string;
  value: string;
  onChangeFn: (event: SelectChangeEvent) => void;
  placeholder?: string;
  selectOptions: {
    id: string | number;
    name: string;
  }[];
  disabled: boolean;
}

export default function TransactionEmployeeSelect({
  selectId,
  value,
  onChangeFn,
  placeholder = '',
  selectOptions,
  disabled,
}: TransactionEmployeeSelectProps) {
  const { t } = useTranslation();

  const sortedSelectOptionsByName = selectOptions.sort(
    (option, comparedOption) => option.name.localeCompare(comparedOption.name),
  );

  return (
    <FormControl fullWidth>
      <Select
        disabled={disabled}
        displayEmpty
        id={selectId}
        value={value}
        onChange={onChangeFn}
      >
        {placeholder.length > 0 && (
          <MenuItem value="">
            <em>{t(placeholder)}</em>
          </MenuItem>
        )}
        {sortedSelectOptionsByName.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {t(item.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
