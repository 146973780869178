import React, { useState, useEffect } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import {
  CardMedia,
  Stack,
  LinearProgress,
  Typography,
} from '@mui/material';
import LogoUrl from 'assets/img/logo.svg';
import Link from 'common/Link/Link';
import ModulesConfigurator from 'components/ModulesConfigurator/ModulesConfigurator';
import externalLinks from 'helpers/externalLinks';
import SubscriptionClientForm from 'components/SubscriptionClientForm/SubscriptionClientForm';
import SubscriptionPaymentMethod from 'components/SubscriptionPaymentMethod/SubscriptionPaymentMethod';
import SubscriptionProvider from './context/SubscriptionProvider';
import { SubscriptionStep } from './interfaces';
import getStorageSubscription, {
  updateStorageSubscription,
} from './storageSubscription';

const stepsCount = Object.keys(SubscriptionStep).length / 2;

// TODO: replace with your stripe public key when backend is ready
const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

// TODO: change options to real data when backend is ready
const options: StripeElementsOptions = {
  mode: 'subscription',
  amount: 0,
  currency: 'usd',
};

export default function Subscription() {
  const storageSubscription = getStorageSubscription();

  const [step, setSep] = useState(
    storageSubscription?.step || SubscriptionStep.modules,
  );

  const { t } = useTranslation();
  const handleNextStep = () => {
    if (step === SubscriptionStep.payment) {
      return;
    }

    setSep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    updateStorageSubscription('step', step);
  }, [step]);

  return (
    <Stack
      width="100%"
      bgcolor="background.list"
      p={2}
      minHeight="100vh"
      justifyContent="space-between"
    >
      <Stack p={3} spacing={3}>
        <CardMedia
          component="img"
          loading="lazy"
          src={LogoUrl}
          alt={t('menu.logo')}
          sx={{
            width: '10.5rem',
          }}
        />
        <LinearProgress
          variant="determinate"
          value={Math.round(step + 1) * (100 / (stepsCount + 1))}
        />
      </Stack>
      <SubscriptionProvider handleNextStep={handleNextStep}>
        {step === SubscriptionStep.modules && <ModulesConfigurator />}
        {step === SubscriptionStep.client && <SubscriptionClientForm />}
        {step === SubscriptionStep.payment && (
          <Elements stripe={stripePromise} options={options}>
            <SubscriptionPaymentMethod />
          </Elements>
        )}
      </SubscriptionProvider>
      <Stack
        pt={4}
        pb={3}
        px={3}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Link
          to={externalLinks.knowledgeBase}
          target="_blank"
          sx={{ textDecoration: 'none' }}
        >
          <Typography variant="buttonMedium" color="secondary.main">
            {t('label.knowledge_base')}
          </Typography>
        </Link>
        <Typography variant="buttonMedium" color="secondary.main">
          Flowtly © {new Date().getFullYear()}
        </Typography>
      </Stack>
    </Stack>
  );
}
