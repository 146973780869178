import { TableHeadersProps } from 'interfaces';

interface RatesHeaders extends TableHeadersProps {
  visible: boolean;
}

const getRatesHeaders = (isPositionVisible: boolean): RatesHeaders[] => [
  {
    align: 'left',
    label: 'employee_project_rates.project',
    visible: true,
  },
  {
    align: 'left',
    label: 'inputs.position',
    visible: isPositionVisible,
  },
  {
    align: 'center',
    label: 'employee_project_rates.rate',
    visible: true,
  },
  {
    align: 'center',
    label: 'employee_project_rates.date_from',
    visible: true,
  },
  {
    align: 'center',
    label: 'employee_project_rates.date_to',
    visible: true,
  },
];

export default getRatesHeaders;
