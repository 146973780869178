import useGetEmployees from 'hooks/useGetEmployees';
import { Project } from 'store/projects/interfaces';
import {
  ProjectMemberEmployee,
  EmployeeOfProjectMemberEmployee,
} from './interfaces';

export default function useProjectMemberEmployees(project: Project) {
  const {
    data: employees = [],
    isLoading: employeesLoading,
    isError: employeesError,
  } = useGetEmployees({
    properties: [
      'lastname',
      'firstname',
      'avatarUrl',
      'position',
      'id',
      'isActive',
    ],
  });

  const isProjectMemberEmployee = (projectMemberEmployee: {
    employee: EmployeeOfProjectMemberEmployee | undefined;
    '@id': string;
    id: string;
    position: string;
    project: string;
  }): projectMemberEmployee is ProjectMemberEmployee =>
    projectMemberEmployee.employee !== undefined;

  const getFullEmployeeObject = (
    employeeIri: string,
  ): EmployeeOfProjectMemberEmployee | undefined =>
    employees.find((employee) => employee['@id'] === employeeIri);

  const projectMemberEmployees = project.projectMembers.map(
    (projectMember) => ({
      ...projectMember,
      employee: getFullEmployeeObject(projectMember.employee),
    }),
  );

  return {
    employees,
    employeesLoading,
    employeesError,
    isProjectMemberEmployee,
    projectMemberEmployees,
  };
}
