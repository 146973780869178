import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddLocation from 'components/LocationsList/AddLocation';
import LocationsList from 'components/LocationsList/LocationsList';
import { useGetLocationsQuery } from 'store/locations/locations';
import Loader from 'common/Loader';
import Error from 'common/Error';

export default function Locations() {
  const {
    data: locations = [],
    isLoading: locationsFetching,
    isError: locationsFetchError,
    isSuccess: locationsFetched,
  } = useGetLocationsQuery();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Grid item xs={10} bgcolor="background.list">
        <NavBarContainer>
          <NavBarTitle>{t('locations.header')}</NavBarTitle>
          <Button
            variant="contained"
            onClick={() => setIsOpen(!isOpen)}
            endIcon={<AddCircleOutlineIcon />}
          >
            {t('button.create')}
          </Button>
        </NavBarContainer>
        {locationsFetching && <Loader />}
        {locationsFetchError && <Error />}
        {locationsFetched && <LocationsList locations={locations} />}
      </Grid>
      <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} fullWidth>
        <AddLocation setIsOpen={setIsOpen} />
      </Dialog>
    </>
  );
}
