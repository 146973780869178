import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import { useGetProjectsQuery } from 'store/projects/projects';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { useLazyGetWorkingHoursQuery } from 'store/workingHours/workingHours';
import fullDateFormat from 'helpers/fullDateFormat';
import HoursViewFilters from 'components/HoursViewFilters/HoursViewFilters';
import HoursViewProject from 'components/HoursViewProject/HoursViewProject';
import ClientSelect from 'components/HoursViewFilters/ClientSelect/ClientSelect';
import { ProjectStatuses } from 'enums';
import { useLocation } from 'react-router-dom';
import { LocationProps } from 'interfaces';
import replaceApiIri from 'helpers/replaceApiIri';

function HoursView() {
  const { state } = useLocation() as LocationProps;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const firstDayInMonth = new Date(currentYear, currentMonth, 1);
  const lastDayInMonth = new Date(currentYear, currentMonth + 1, 0);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    firstDayInMonth,
    lastDayInMonth,
  ]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [displayedProjects, setDisplayedProjects] = useState<string[]>([]);
  const [clientSelectVisible, setClientSelectVisible] = useState(false);
  const {
    isLoading: projectsFetching,
    isError: projectsFetchError,
    isSuccess: projectsFetched,
  } = useGetProjectsQuery({ status: ProjectStatuses.active });

  const [
    showProjects,
    {
      data: hours = [],
      isLoading: workingHoursFetching,
      isError: workingHoursFetchError,
      isSuccess: workingHoursFetched,
    },
  ] = useLazyGetWorkingHoursQuery();

  const handleProjectsSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedProjects(value);
  };

  useEffect(() => {
    if (!state?.projects) {
      return;
    }
    setSelectedProjects(state.projects);
  }, [state]);

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('hours_view.header')}</NavBarTitle>
      </NavBarContainer>
      <Box display="flex" flexDirection="column" p={2} gap={2}>
        {projectsFetching && <Loader />}
        {projectsFetchError && <Error />}
        {projectsFetched && (
          <Box borderRadius={2} p={3} bgcolor="main.white">
            <Grid container borderRadius={2} spacing={4}>
              <HoursViewFilters
                dateRange={dateRange}
                setDateRange={setDateRange}
                selectedProjects={selectedProjects}
                handleProjectsSelect={handleProjectsSelect}
              />
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <Button
                  data-testid="hours-view-working-hours-button"
                  onClick={() => {
                    setDisplayedProjects(selectedProjects);
                    showProjects({
                      'date[after]': fullDateFormat(dateRange[0]),
                      'date[before]': fullDateFormat(dateRange[1]),
                      project: selectedProjects,
                    });
                    setClientSelectVisible(true);
                  }}
                  variant="contained"
                >
                  {t('hours_view.button')}
                </Button>
              </Grid>
              {clientSelectVisible && (
                <ClientSelect
                  dateRange={dateRange}
                  selectedProjects={displayedProjects}
                />
              )}
            </Grid>
          </Box>
        )}
        {workingHoursFetching && <Loader />}
        {workingHoursFetchError && <Error />}
        {workingHoursFetched && (
          <Box borderRadius={2} p={2} bgcolor="main.white">
            {displayedProjects.map((project) => (
              <HoursViewProject
                dateRange={dateRange}
                key={project}
                projectId={project}
                workingHours={hours.filter(
                  (hour) => replaceApiIri(hour.project, 'projects') === project,
                )}
              />
            ))}
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export default HoursView;
