import { Stack, StackProps, styled } from '@mui/material';

const StyledColumnTilesWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  overflowY: 'auto',
  height: 'calc(100vh - 32.6rem)',
  gap: theme.spacing(1),
  borderRadius: '1.6rem',
}));

export default StyledColumnTilesWrapper;
