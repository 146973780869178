import React from 'react';
import MasonryElements from 'common/MasonryElements/MasonryElements';
import Information from './Information/Information';
import Holidays from './Holidays/Holidays';
import Feedbacks from './Feedbacks/Feedbacks';
import Agreements from './Agreements/Agreements';
import PendingRequests from './PendingRequests/PendingRequests';
import Notes from './Notes';

export default function MeetingWidgets() {
  return (
    <MasonryElements
      columnsElements={[
        [<Information />, <Notes />, <Holidays />, <Agreements />],
        [<Feedbacks />, <PendingRequests />],
      ]}
    />
  );
}
