import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useValidate } from 'hooks/useValidate';
import { useAddAccountMutation } from 'store/accounts/accounts';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import { Bank } from 'store/bank/bank';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { CustomError } from 'store/api';
import ImportFormats from 'components/AddAccountForm/enums';
import { LoadingButton } from '@mui/lab';

export interface Errors {
  accountname: boolean;
  prefix: boolean;
  type: boolean;
  isDefault: boolean;
  accountnumber: boolean;
  currency: boolean;
}

export interface AddAccountFormProps extends StatusProps {
  bank: Bank;
  setIsAddAccountOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddAccountForm({
  bank,
  setIsSuccess,
  setIsError,
  setErrorMessage,
  setIsAddAccountOpen,
}: AddAccountFormProps) {
  const [accountName, setAccountName] = useState('');
  const [type, setType] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [prefix, setPrefix] = useState('');
  const [currencyIri, setCurrencyIri] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const [addAccount, { isLoading }] = useAddAccountMutation();
  const { data: currencies = [] } = useGetCurrenciesQuery();
  const { t } = useTranslation();

  const disableButton = () =>
    Object.values(errors).some((error) => error) ||
    !accountName ||
    !type ||
    !currencyIri;

  const handleAddBank = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addAccount({
        bank: bank['@id'],
        type,
        default: isDefault,
        name: accountName,
        number: accountNumber,
        prefix,
        swift: bank.swift,
        currency: currencyIri,
        defaultImportFormat: ImportFormats.mt940,
      }).unwrap();
      setIsSuccess(true);
      setIsAddAccountOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleSetCurrency = () => {
    if (!prefix) {
      return;
    }
    const prefixPattern = new RegExp(prefix, 'i');
    setCurrencyIri(
      currencies
        .map((currencyData) =>
          prefixPattern.test(currencyData.id) ? currencyData.id : null,
        )
        .filter((item) => item)[0] || '',
    );
  };

  return (
    <>
      <DialogHeader
        title={`${t('bank_accounts.accounts_for')} ${bank.name}`}
        setIsOpen={setIsAddAccountOpen}
      />
      <DialogContent>
        <form onSubmit={handleAddBank}>
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            flexWrap="wrap"
            pt={3}
          >
            <TextField
              required
              fullWidth
              error={errors.accountname}
              helperText={
                errors.accountname && t('errors.too_short_account_name')
              }
              name="accountname"
              variant="outlined"
              label={t('bank_accounts.account_name_label')}
              id="accountname"
              value={accountName}
              onChange={(event) => setAccountName(event.target.value)}
              onBlur={(event) =>
                validate('accountname', event.target.value.length >= 2)
              }
            />
            <Box display="flex" columnGap={3} width="100%">
              <TextField
                required
                error={errors.prefix}
                helperText={errors.prefix && t('errors.too_short_prefix')}
                name="prefix"
                variant="outlined"
                label={t('bank_accounts.prefix_label')}
                id="prefix"
                value={prefix}
                onChange={(event) => setPrefix(event.target.value)}
                onBlur={(event) => {
                  validate('prefix', event.target.value.length >= 2);
                  handleSetCurrency();
                }}
                sx={{ width: '30%' }}
              />
              <TextField
                required
                fullWidth
                error={errors.accountnumber}
                helperText={
                  errors.accountnumber && t('errors.too_short_account_number')
                }
                name="accountnumber"
                variant="outlined"
                label={t('bank_accounts.account_number_label')}
                id="accountnumber"
                value={accountNumber}
                onChange={(event) => setAccountNumber(event.target.value)}
                onBlur={(event) =>
                  validate('accountnumber', event.target.value.length >= 2)
                }
              />
            </Box>
            <Box display="flex" columnGap={3}>
              <FormControl sx={{ width: '30%' }} required>
                <InputLabel id="currency-label">
                  {t('bank_accounts.currency_label')}
                </InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  labelId="currency-label"
                  id="currency"
                  value={currencyIri}
                  label={t('bank_accounts.currency_label')}
                  onChange={(event) => setCurrencyIri(event.target.value)}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency.id} value={currency['@id']}>
                      {currency.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth required>
                <InputLabel id="account-type-label">
                  {t('label.type')}
                </InputLabel>
                <Select
                  labelId="account-type-label"
                  id="account-type"
                  value={type}
                  label={t('label.type')}
                  onChange={(event) => setType(event.target.value)}
                >
                  <MenuItem value="regular">
                    {t('bank_accounts.account_type_option_1')}
                  </MenuItem>
                  <MenuItem value="vat">
                    {t('bank_accounts.account_type_option_2')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDefault}
                    onChange={() => setIsDefault((prevState) => !prevState)}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '2.5rem',
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    {t('bank_accounts.is_default_label')}
                  </Typography>
                }
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              disabled={disableButton()}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
