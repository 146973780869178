import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleFilledIcon from 'assets/icons/CheckCircleFilledIcon';
import { UseFormSetValue } from 'react-hook-form';
import { BudgetType } from 'enums';
import { FormPayload } from '../interfaces';

interface TypeSelectProps {
  budgetType: {
    value: BudgetType;
    label: string;
    description: string;
    formIcon: JSX.Element;
  };
  selectedType: string;
  setSelectedType: UseFormSetValue<FormPayload>;
}

export default function TypeSelect({
  budgetType,
  selectedType,
  setSelectedType,
}: TypeSelectProps) {
  const { t } = useTranslation();
  const isTypeSelected = selectedType === budgetType.value;

  return (
    <Box
      border={1}
      borderRadius={3}
      borderColor={isTypeSelected ? 'primary.main' : 'border.main'}
      display="flex"
      flexDirection="column"
      onClick={() => setSelectedType('type', budgetType.value)}
      padding={3}
      position="relative"
      rowGap={3}
      sx={{ cursor: 'pointer' }}
      width="50%"
    >
      <Stack direction="row" alignItems="center" spacing={1.5}>
        {budgetType.formIcon}
        <Typography variant="subtitle1">{t(budgetType.label)}</Typography>
      </Stack>
      <Typography variant="body1" color="text.secondary">
        {t(budgetType.description)}
      </Typography>
      {isTypeSelected && (
        <CheckCircleFilledIcon
          color="primary"
          sx={{ fontSize: 32, position: 'absolute', top: -12, right: -12 }}
        />
      )}
    </Box>
  );
}
