import React, { useState } from 'react';
import { Chip, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getHolidayIcon, getHolidayName } from 'helpers/getHolidayData';
import { Holiday, HolidayRequest } from 'store/holidays/interfaces';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';
import formatDateRange from 'helpers/formatDateRange';
import getHolidayDuration from 'helpers/getHolidayDuration';
import DeleteButton from 'common/DeleteButton/DeleteButton';
import { useDeleteHolidayRequestMutation } from 'store/holidays/holidays';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import getChipOptions from './getChipOptions';
import { HolidayRowType } from '../interfaces';

interface HolidayWithType extends Holiday {
  rowType: HolidayRowType.holiday;
}

interface HolidayRequestWithType extends HolidayRequest {
  rowType: HolidayRowType.holidayRequest;
}

export interface HolidayRowProps {
  holidayRow: HolidayWithType | HolidayRequestWithType;
}

export default function HolidayRow({ holidayRow }: HolidayRowProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dateRange = formatDateRange({
    dateFrom: holidayRow.dateFrom,
    dateTo: holidayRow.dateTo,
  });
  const { getChipLabel, getChipStyles } = getChipOptions();

  const [deleteRequest] = useDeleteHolidayRequestMutation();

  const handleDeleteRequest = async () => {
    try {
      await deleteRequest(holidayRow.id).unwrap();
      dispatch(setSuccessStatus(true));
      setIsDeleteDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <>
      <TableRow key={holidayRow.id} data-testid="my-days-off-table-row">
        <TableCell>
          <Stack spacing={0.5}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                '& svg': {
                  color: 'primary.main',
                  fontSize: '2rem',
                },
              }}
            >
              {getHolidayIcon(holidayRow.type)}
              <Typography variant="body1">
                {t(getHolidayName(holidayRow.type))}
              </Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary">
              {holidayRow.description}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{dateRange.from}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{dateRange.to}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {getHolidayDuration({ holidayItem: holidayRow })}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Chip
            variant="outlined"
            size="small"
            sx={getChipStyles(holidayRow.rowType)}
            label={
              <Typography variant="chipLabel">
                {getChipLabel(holidayRow.rowType)}
              </Typography>
            }
          />
        </TableCell>
        {holidayRow.rowType === HolidayRowType.holidayRequest ? (
          <TableCell>
            <DeleteButton onClick={() => setIsDeleteDialogOpen(true)} />
          </TableCell>
        ) : (
          <TableCell colSpan={1} />
        )}
      </TableRow>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        handleAccept={handleDeleteRequest}
      />
    </>
  );
}
