import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { useAddWorkingHoursMutation } from 'store/workingHours/workingHours';
import { useValidate } from 'hooks/useValidate';
import { useTranslation } from 'react-i18next';
import checkTaskPattern from 'helpers/validators/checkTaskPattern';
import dateFormat from 'dateformat';
import CloseButton from 'common/CloseButton/CloseButton';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import ProjectStatus from 'components/AddWorkingHoursForm/enums';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';
import replaceApiIri from 'helpers/replaceApiIri';
import {
  AddWorkingHoursFormProps,
  EmployeeProjectPhase,
  Errors,
} from './interfaces';

function AddWorkingHoursForm({
  setIsOpen,
  setIsError,
  setIsSuccess,
  setErrorMessage,
  date,
  employeeIri,
}: AddWorkingHoursFormProps) {
  const { t } = useTranslation();
  const [hours, setHours] = useState('');
  const [description, setDescription] = useState('');
  const [projectData, setProjectData] = useState('');
  const {
    data: employeeProjects = [],
    isLoading,
    isError,
    isSuccess,
  } = useGetProjectsPreviewQuery({
    status: ProjectStatus.active,
    employee: replaceApiIri(employeeIri, 'employees'),
  });

  const employeeProjectsPhases = employeeProjects.reduce(
    (acc: EmployeeProjectPhase[], employeeProject) => {
      if (employeeProject.projectPhases.length) {
        acc.push({
          projectName: employeeProject.name,
          projectIri: employeeProject['@id'],
        });

        const sortedAlphabeticallyPhases = structuredClone(
          employeeProject.projectPhases,
        ).sort((a, b) => a.name.localeCompare(b.name));

        sortedAlphabeticallyPhases.forEach((employeeProjectPhase) => {
          acc.push({
            projectName: employeeProject.name,
            phaseName: employeeProjectPhase.name,
            projectIri: employeeProject['@id'],
            phaseIri: employeeProjectPhase['@id'],
          });
        });

        return acc;
      }

      acc.push({
        projectName: employeeProject.name,
        projectIri: employeeProject['@id'],
      });

      return acc;
    },
    [],
  );

  const getProjectPhaseText = (projectPhaseData: EmployeeProjectPhase) => {
    if (projectPhaseData.phaseName) {
      return `${projectPhaseData.projectName} - ${projectPhaseData.phaseName}`;
    }

    return projectPhaseData.projectName;
  };

  const getProjectPhaseKey = (projectPhaseData: EmployeeProjectPhase) => {
    if (projectPhaseData.phaseIri) {
      return `${projectPhaseData.projectIri} ${projectPhaseData.phaseIri}`;
    }

    return `${projectPhaseData.projectIri}`;
  };

  const getProjectPhaseValue = (projectPhaseData: EmployeeProjectPhase) => {
    const value = {
      projectIri: projectPhaseData.projectIri,
      ...(projectPhaseData.phaseIri && {
        phaseIri: projectPhaseData.phaseIri,
      }),
    };

    return JSON.stringify(value);
  };

  const [addWorkingHours, { isLoading: addingEntry }] =
    useAddWorkingHoursMutation();
  const { errors, validate } = useValidate<Errors>();
  const minDescriptionLength = 32;

  const disableSubmission = () =>
    !projectData ||
    !hours ||
    !description ||
    Object.values(errors).some((error) => error);

  const handleAddHours = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { projectIri, phaseIri } = JSON.parse(projectData);
    try {
      await addWorkingHours({
        employee: employeeIri,
        project: projectIri,
        projectPhase: phaseIri || null,
        date,
        hours: +hours,
        description,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h5">
            {t('project_tracking.add_hours')}
          </Typography>
          <Typography variant="bold" color="secondary">
            {dateFormat(date, 'dd mmmm')}
          </Typography>
        </Box>
        <CloseButton setIsOpen={setIsOpen} />
      </DialogTitle>
      <DialogContent>
        <Divider />
        {isLoading && <Loader />}
        {isError && <Error />}
        {isSuccess && (
          <form onSubmit={handleAddHours}>
            <Box display="flex" flexDirection="column" rowGap={3} pt={4}>
              <Box display="flex" columnGap={2} width="100%">
                <FormControl
                  sx={{ width: '80%' }}
                  required
                  error={errors.projectData}
                >
                  <InputLabel id="project">{t('inputs.project')}</InputLabel>
                  <Select
                    id="project"
                    label={t('inputs.project')}
                    value={projectData}
                    onChange={(event) => {
                      setProjectData(event.target.value);
                      validate('projectData', event.target.value !== '');
                    }}
                  >
                    {employeeProjectsPhases.map(
                      (projectPhaseData: EmployeeProjectPhase) => (
                        <MenuItem
                          key={getProjectPhaseKey(projectPhaseData)}
                          value={getProjectPhaseValue(projectPhaseData)}
                        >
                          {getProjectPhaseText(projectPhaseData)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
                <TextField
                  error={errors.hours}
                  helperText={errors.hours && t('errors.field_required')}
                  required
                  type="number"
                  name="loggedHours"
                  label={t('inputs.hours')}
                  value={hours}
                  onChange={(event) => setHours(event.target.value)}
                  onBlur={(event) =>
                    validate(
                      'hours',
                      event.target.value !== '' &&
                        +event.target.value > 0 &&
                        +event.target.value <= 24,
                    )
                  }
                  inputProps={{
                    step: 0.25,
                    min: 0,
                    max: 24,
                  }}
                  sx={{ width: '20%' }}
                />
              </Box>
              <Box>
                <TextField
                  error={errors.description}
                  required
                  name="description"
                  placeholder={t('project_tracking.working_hours_description')}
                  fullWidth
                  label={t('label.description')}
                  multiline
                  minRows={5}
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                    validate(
                      'description',
                      checkTaskPattern(event.target.value),
                    );
                  }}
                />
                {errors.description && (
                  <FormHelperText
                    variant="outlined"
                    sx={{
                      color: errors.description
                        ? 'error.main'
                        : 'secondary.main',
                    }}
                  >
                    {t('project_tracking.characters_count', {
                      count: description.length,
                    })}
                    /{minDescriptionLength}
                  </FormHelperText>
                )}
              </Box>
              <Divider />
            </Box>
            <Box display="flex" columnGap={2} justifyContent="flex-end" mt={4}>
              <Button
                color="secondary"
                onClick={() => setIsOpen(false)}
                sx={{
                  bgcolor: 'secondary.light',
                }}
              >
                {t('button.cancel')}
              </Button>
              <LoadingButton
                disabled={disableSubmission()}
                endIcon={<AddCircleOutlineIcon />}
                loading={addingEntry}
                type="submit"
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Box>
          </form>
        )}
      </DialogContent>
    </>
  );
}

export default AddWorkingHoursForm;
