import { TaxesCondition } from 'store/condition/interfaces';

const calculateTaxesSum = (month: string, taxes: TaxesCondition) => {
  let sum = 0;

  if (!taxes[month]) {
    return sum;
  }

  Object.keys(taxes[month]).forEach((contractor) => {
    sum += taxes[month][contractor].cost;
  });

  return sum;
};

export default calculateTaxesSum;
