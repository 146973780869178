import React from 'react';
import { Agreement } from 'components/AgreementsList/AgreementsList';
import { Box, IconButton, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import { AllInclusive, Download } from '@mui/icons-material';
import openFileWithAuth from 'helpers/openFileWithAuth';
import { useTranslation } from 'react-i18next';

interface AgreementsProps {
  agreementsData: Agreement[];
}

export default function Agreements({ agreementsData }: AgreementsProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography color="primary" textTransform="uppercase" variant="h6">
        {t('menu.agreements')}
      </Typography>
      {agreementsData.map((agreement) => (
        <Box
          key={agreement.id}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="bold">
            {agreement.variant} / {agreement.type}
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body1">
              {dateFormat(agreement.dateFrom, 'yyyy-mm-dd')}
            </Typography>
            {agreement.dateTo ? (
              <Typography variant="body1">
                {dateFormat(agreement.dateTo, 'yyyy-mm-dd')}
              </Typography>
            ) : (
              <AllInclusive />
            )}
            <Typography variant="body1">{agreement.hoursPerWeek}h</Typography>
            {agreement.files &&
              agreement.files.map((file) => (
                <IconButton
                  key={file.id}
                  onClick={() => openFileWithAuth(file.url)}
                >
                  <Download />
                </IconButton>
              ))}
          </Box>
        </Box>
      ))}
    </>
  );
}
