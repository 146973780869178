import React from 'react';
import { Typography, Stack } from '@mui/material';
import { Budget } from 'store/budget/interfaces';

interface BudgetAutocompleteProps {
  budgetOption: Budget;
}

export default function AutocompleteOption({
  budgetOption,
}: BudgetAutocompleteProps) {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack width="50%">
        <Typography variant="body1">
          {budgetOption.name}
        </Typography>
      </Stack>
    </Stack>
  );
}
