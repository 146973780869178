export default function getReversedProgressChartTheme(percentageValue: number) {
  switch (true) {
    case (percentageValue >= 0 && percentageValue <= 50):
      return {
        color: 'success.main',
        borderColor: 'border.successOutlined',
      };
    case (percentageValue > 50 && percentageValue <= 75):
      return {
        color: 'warning.light',
        borderColor: 'border.warningOutlined',
      };
    case (percentageValue > 75 && percentageValue <= 100):
      return {
        color: 'error.main',
        borderColor: 'border.errorOutlined',
      };
    default:
      return {
        color: 'error.main',
        borderColor: 'border.errorOutlined',
      };
  }
}
