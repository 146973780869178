import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  DialogContent,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import checkEmail from 'helpers/validators/checkEmail/checkEmail';
import { useValidate } from 'hooks/useValidate';
import { useTranslation } from 'react-i18next';
import { ClientContact } from 'store/clientContacts/interfaces';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useUpdateContactMutation } from 'store/clientContacts/clientContacts';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import contactTypes from '../AddContact/contactTypes';

export interface EditContactProps extends StatusProps {
  contact: ClientContact;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Errors {
  name: boolean;
  email: boolean;
}

export default function EditContact({
  contact,
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditContactProps) {
  const { t } = useTranslation();
  const { errors, validate } = useValidate<Errors>();
  const [name, setName] = useState(contact.name);
  const [email, setEmail] = useState(contact.email);
  const [type, setType] = useState(contact.type);
  const [title, setTitle] = useState(contact.title || '');
  const [phone, setPhone] = useState(contact.phone || '');
  const [updateContact, { isLoading }] = useUpdateContactMutation();

  const disableSubmission = () =>
    !name || !email || !type || Object.values(errors).some((error) => error);

  const handleUpdateContact = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateContact({
        id: contact.id,
        name,
        email,
        type,
        title,
        phone,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader title={t('clients.edit_contact')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleUpdateContact}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={3}
            mt={3}
          >
            <Box display="flex" gap={3} width="100%">
              <TextField
                error={errors.name}
                fullWidth
                helperText={errors.name && t('errors.too_short_name')}
                label={t('label.name')}
                name="contactName"
                onBlur={(event) =>
                  validate('name', event.target.value.length >= 2)
                }
                onChange={(event) => setName(event.target.value)}
                required
                value={name}
              />
              <TextField
                error={errors.email}
                fullWidth
                helperText={errors.email && t('errors.wrong_email')}
                label={t('label.email')}
                name="email"
                onBlur={(event) =>
                  validate('email', checkEmail(event.target.value))
                }
                onChange={(event) => setEmail(event.target.value)}
                required
                value={email}
              />
            </Box>
            <Box display="flex" gap={3} width="100%">
              <FormControl fullWidth required>
                <InputLabel id="type-select">{t('label.type')}</InputLabel>
                <Select
                  labelId="type-select"
                  label={t('label.type')}
                  onChange={(event) => setType(event.target.value)}
                  value={type}
                >
                  {contactTypes.map((contactType) => (
                    <MenuItem key={contactType.label} value={contactType.value}>
                      {t(contactType.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label={t('label.title')}
                name="title"
                onChange={(event) => setTitle(event.target.value)}
                value={title}
              />
            </Box>
            <TextField
              fullWidth
              label={t('inputs.contact_phone')}
              name="phone"
              onChange={(event) => setPhone(event.target.value)}
              type="tel"
              value={phone}
            />
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {t('button.save')}
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
