import React, { ChangeEvent, useCallback } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmployeePreview } from 'store/employee/interfaces';

interface AttachedEmployeesListProps {
  employees: EmployeePreview[];
  selectedEmployees: string[];
  isEmployeeSelected: (value: string) => boolean;
  onCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function AttachedEmployeesSelect({
  employees,
  selectedEmployees,
  isEmployeeSelected,
  onCheckboxChange,
}: AttachedEmployeesListProps) {
  const { t } = useTranslation();

  const getSelectedEmployeeNames = useCallback(
    (selectedEmployeeIds: string[]) =>
      employees
        .filter((employee) => selectedEmployeeIds.includes(employee['@id']))
        .map((employee) => `${employee.lastname} ${employee.firstname}`)
        .join(', '),
    [employees],
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="attached-employees">
        {t('projects.attach_employees')}
      </InputLabel>
      <Select
        id="attached-employees"
        label={t('projects.attach_employees')}
        multiple
        value={selectedEmployees}
        renderValue={(selected) => getSelectedEmployeeNames(selected)}
      >
        {employees.map((employee) => (
          <MenuItem key={employee.id} value={employee['@id']} sx={{ paddingY: 0 }}>
            <FormControlLabel
              sx={{ width: '100%' }}
              control={
                <Checkbox
                  value={employee['@id']}
                  onChange={onCheckboxChange}
                  checked={isEmployeeSelected(employee['@id'])}
                />
              }
              label={`${employee.lastname} ${employee.firstname}`}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
