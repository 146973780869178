/* eslint-disable react/require-default-props */
import React from 'react';
import StyledCell from 'common/RowAddButton/TableCell.styled';
import { TableRow } from '@mui/material';

interface RowAddButtonProps {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLTableRowElement>;
  colSpan?: number;
}

function RowAddButton({ children, onClick, colSpan }: RowAddButtonProps) {
  return (
    <TableRow onClick={onClick} sx={{ cursor: 'pointer' }}>
      <StyledCell colSpan={colSpan} align="center">
        {children}
      </StyledCell>
    </TableRow>
  );
}

export default RowAddButton;
