import React from 'react';
import { Box } from '@mui/material';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

interface BaseDataProps<T extends FieldValues> {
  errors: FieldErrors;
  control: Control<T, any, T>;
}

function BaseData<T extends FieldValues>({
  errors,
  control,
}: BaseDataProps<T>) {
  return (
    <Box display="flex" gap={3}>
      <ControlTextField
        errors={errors}
        control={control as Control<FieldValues, any, FieldValues>}
        name="name"
        label="label.name"
        required
      />
      <ControlTextField
        errors={errors}
        control={control as Control<FieldValues, any, FieldValues>}
        name="tin"
        label="costs.tin_label"
      />
    </Box>
  );
}

export default BaseData;
