import {
  addDays,
  differenceInBusinessDays,
  isAfter,
  isBefore,
  isWeekend,
  startOfDay,
} from 'date-fns';
import { useGetBankHolidaysQuery } from 'store/bankHolidays/bankHolidays';

export default function useWorkingDaysBetweenRange() {
  const { data: bankHolidays = [] } = useGetBankHolidaysQuery();

  const getWorkingDaysCount = (dateFrom: Date, dateTo: Date) => {
    const convertedDateFrom = startOfDay(dateFrom);
    const convertedDateTo = addDays(startOfDay(dateTo), 1);
    const businessDays = differenceInBusinessDays(
      convertedDateTo,
      convertedDateFrom,
    );

    const dateFromYear = convertedDateFrom.getFullYear();
    const dateToYear = convertedDateTo.getFullYear();

    const recurringHolidayDates: string[] = [];
    for (let year = dateFromYear; year <= dateToYear; year++) {
      bankHolidays.forEach((holiday) => {
        if (holiday.date.startsWith('*-')) {
          const convertedDate = holiday.date.replace('*', year.toString());
          recurringHolidayDates.push(convertedDate);
        }
      });
    }

    const holidays = [
      ...bankHolidays.map((holiday) => holiday.date),
      ...recurringHolidayDates,
    ];

    const holidaysInRange = holidays.filter(
      (holiday) =>
        !isBefore(convertedDateTo, holiday) &&
        !isAfter(convertedDateFrom, holiday),
    );

    const holidaysInRangeCount = holidaysInRange.filter((holiday) => {
      const holidayDate = new Date(holiday);
      return !isWeekend(holidayDate);
    }).length;

    return businessDays - holidaysInRangeCount;
  };

  return { getWorkingDaysCount };
}
