import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HolidayDaysFree from 'components/HolidayDaysFree';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function BankHolidays() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('bank_holidays.header')}</NavBarTitle>
        <Button
          variant="contained"
          onClick={() => setIsOpen(true)}
          endIcon={<AddCircleOutlineIcon />}
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <Box p={2}>
        <HolidayDaysFree isOpen={isOpen} setIsOpen={setIsOpen} />
      </Box>
    </Grid>
  );
}

export default BankHolidays;
