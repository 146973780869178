import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
  Stack,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import tableHeaders from 'components/InvoicesList/tableHeaders';
import { useGetIncomesQuery } from 'store/incomes/incomes';
import { InvoicesData } from 'store/incomes/interfaces';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import EmptyInvoicesList from 'components/EmptyInvoicesList/EmptyInvoicesList';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CustomPagination from 'common/CustomPagination/CustomPagination';
import { useSearchParams } from 'react-router-dom';
import usePagination from 'hooks/usePagination';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import SelectedData from './SelectedData/SelectedData';
import MonthlyAmounts from './MonthlyAmounts/MonthlyAmounts';
import getMonthlyAmountSum from './utils';

interface InvoicesListProps {
  isSelected: (value: string) => boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function InvoicesList({
  isSelected,
  handleChange,
  setSelected,
  selected,
}: InvoicesListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { page, handleOnPageChange, handleOnItemsPerPageChange } =
    usePagination({ searchParams, setSearchParams });
  const {
    data: invoices = {} as InvoicesData,
    isError: incomesFetchError,
    isFetching: incomesFetching,
  } = useGetIncomesQuery({
    page,
  });
  const { t } = useTranslation();
  const {
    handleChange: handleChangeMonths,
    isSelected: isMonthSelected,
    setSelected: setSelectedMonths,
  } = useMultipleCheckboxSelect<string>([]);

  const monthlyAmountSums = getMonthlyAmountSum(invoices['hydra:member'] || []);

  const handleCheckWholePage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected((prevState) => {
        if (invoices['hydra:member']) {
          return [
            ...new Set(
              invoices['hydra:member']
                .map((income) => String(income.id))
                .concat(prevState),
            ),
          ];
        }
        return prevState;
      });
      setSelectedMonths(monthlyAmountSums.map(({ month }) => month));
    } else {
      setSelected([]);
      setSelectedMonths([]);
    }
  };

  const noInvoices = !incomesFetching && !invoices?.['hydra:member']?.length;

  return (
    <>
      <TableContainer sx={{ px: 3, mt: 3 }}>
        <Table>
          <TableHead>
            {!!selected.length && (
              <SelectedData
                invoices={invoices['hydra:member'] || []}
                headersLength={tableHeaders.length + 1}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            <TableRow>
              <TableCell>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checkedIcon={<IndeterminateCheckBoxIcon />}
                    onChange={handleCheckWholePage}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: '2.4rem' } }}
                  />
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(tableHeaders[0].label)}
                  </Typography>
                </Stack>
              </TableCell>
              {tableHeaders.slice(1).map((header) => (
                <TableCell
                  key={header.label}
                  align={header.align}
                  width={header.width}
                >
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          {!noInvoices && (
            <TableBodyContent
              dataTestId="invoice-list-container"
              tableHeaders={tableHeaders}
              isFetching={incomesFetching}
              isError={incomesFetchError}
            >
              {monthlyAmountSums?.map((monthlySum) => (
                <React.Fragment key={monthlySum.month}>
                  <MonthlyAmounts
                    monthlySum={monthlySum}
                    headersLength={tableHeaders.length}
                    isSelected={isSelected}
                    handleChange={handleChange}
                    setSelected={setSelected}
                    incomes={invoices['hydra:member']}
                    isMonthSelected={isMonthSelected}
                    setSelectedMonths={setSelectedMonths}
                    handleMonthChange={handleChangeMonths}
                  />
                  {monthlyAmountSums.length > 1 && (
                    <TableRow>
                      <TableCell colSpan={tableHeaders.length + 1} />
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBodyContent>
          )}
        </Table>
      </TableContainer>
      {noInvoices && <EmptyInvoicesList />}
      {invoices['hydra:view']?.['hydra:last'] && (
        <Box px={4} pb={4}>
          <CustomPagination
            apiData={invoices}
            page={page}
            itemsPerPage={100}
            handleOnPageChange={handleOnPageChange}
            handleOnItemsPerPageChange={handleOnItemsPerPageChange}
            showRowsSelect={false}
          />
        </Box>
      )}
    </>
  );
}
