const workingDays = [
  {
    value: 1,
    description: 'day.monday',
    abbreviation: 'day.monday_abbreviation',
  },
  {
    value: 2,
    description: 'day.tuesday',
    abbreviation: 'day.tuesday_abbreviation',
  },
  {
    value: 3,
    description: 'day.wednesday',
    abbreviation: 'day.wednesday_abbreviation',
  },
  {
    value: 4,
    description: 'day.thursday',
    abbreviation: 'day.thursday_abbreviation',
  },
  {
    value: 5,
    description: 'day.friday',
    abbreviation: 'day.friday_abbreviation',
  },
  {
    value: 6,
    description: 'day.saturday',
    abbreviation: 'day.saturday_abbreviation',
  },
  {
    value: 7,
    description: 'day.sunday',
    abbreviation: 'day.sunday_abbreviation',
  }
];

export default workingDays;
