import React, { useRef, useImperativeHandle, Ref } from 'react';
import { InputBaseComponentProps } from '@mui/material'

const isInputElement = (element: unknown): element is HTMLInputElement =>
  element instanceof HTMLInputElement;

function StripeInput(props: InputBaseComponentProps, ref: Ref<unknown | undefined>) {
  const { component: Component, ...other } = props;

  const elementRef = useRef<HTMLInputElement | null>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (isInputElement(elementRef.current)) {
        return elementRef.current.focus();
      }

      return undefined;
    },
  }));

  const handleReady = (element: HTMLInputElement) => {
    elementRef.current = element;
  };

  return <Component onReady={handleReady} {...other} />;
}

export default React.forwardRef(StripeInput);
