import React from 'react';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';

export default function TimeScaleTickCellComponent(
  props: WeekView.TimeScaleTickCellProps,
) {
  return (
    <WeekView.TimeScaleTickCell
      {...props}
      style={{ borderBottom: 'none' }}
    />
  );
}
