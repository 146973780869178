import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import DocumentTypesList from 'components/DocumentTypesList/DocumentTypesList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddDocumentTypeForm from 'components/AddDocumentTypeForm';
import EditDocumentTypeForm from 'components/EditDocumentTypeForm';

function DocumentTypeManagement() {
  const { t } = useTranslation();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [chosenDocType, setChosenDocType] = useState(0);

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <NavBarTitle>{t('documents.header')}</NavBarTitle>
        <Button
          variant="contained"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => setIsAddFormOpen(true)}
        >
          {t('button.create')}
        </Button>
      </NavBarContainer>
      <DocumentTypesList
        setChosenDocType={setChosenDocType}
        setIsEditFormOpen={setIsEditFormOpen}
      />
      <Dialog
        fullWidth
        open={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
      >
        <AddDocumentTypeForm setIsOpen={setIsAddFormOpen} />
      </Dialog>
      <Dialog
        fullWidth
        open={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
      >
        <EditDocumentTypeForm
          docTypeId={chosenDocType}
          setIsOpen={setIsEditFormOpen}
        />
      </Dialog>
    </Grid>
  );
}

export default DocumentTypeManagement;
