import React, { useState } from 'react';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import CloseButton from 'common/CloseButton/CloseButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'hooks/useValidate';
import { StatusProps } from 'hoc/Status/Status';
import { useAddPositionMutation } from 'store/positions/positions';
import { LoadingButton } from '@mui/lab';
import { CustomError } from 'store/api';

export interface AddPositionFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Errors {
  position: boolean;
}

function AddPositionForm({
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddPositionFormProps) {
  const [position, setPosition] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();
  const [addPosition, { isLoading }] = useAddPositionMutation();
  const disableSubmission = () =>
    Object.values(errors).some((error) => error) || !position;

  const handleAddPosition = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addPosition({
        name: position,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">{t('positions.add_new')}</Typography>
        <CloseButton setIsOpen={setIsOpen} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddPosition}>
          <FormControl fullWidth required sx={{ pt: 4 }}>
            <TextField
              error={errors.position}
              required
              helperText={errors.position && t('errors.too_short_name')}
              id="position-name"
              label={t('inputs.position')}
              value={position}
              onChange={(event) => setPosition(event.target.value)}
              onBlur={(event) =>
                validate('position', event.target.value.length >= 2)
              }
            />
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default AddPositionForm;
