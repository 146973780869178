import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

const MenuLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  minHeight: '4.8rem',
  borderRadius: '0.8rem',
  padding: '0 2.1rem',
  columnGap: '1.6rem',
  margin: '0 0.8rem',
  '& svg': {
    color: theme.palette.icon.light,
    fontSize: '2.4rem',
  },
  '&.active': {
    backgroundColor: theme.palette.primary.light,
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      color: theme.palette.main.black,
    },
  },
}));

export default MenuLink;
