import { styled, Switch, SwitchProps } from '@mui/material';

const StyledSwitch = styled(Switch)<SwitchProps>(({ theme }) => ({
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.dark,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
  },
}));

export default StyledSwitch;
