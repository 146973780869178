import { createContext} from 'react';
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';

export interface HolidaysContextProps {
  searchParams: URLSearchParams;
  setSearchParams: (nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit), navigateOpts?: NavigateOptions) => void;
  isSettingSearchParams: boolean;
}

export const HolidaysContext = createContext<HolidaysContextProps | null>(null);
