import api from 'store/api';
import { Cost } from 'store/costs/costs';
import { StorageResponse } from 'store/storage/storage';
import { ApiResponse } from 'store/interfaces';
import { BudgetTransaction } from '../budget/interfaces';

export interface TransactionsData extends ApiResponse {
  'hydra:member': Transaction[];
}

export interface TransactionAttachmentsData extends ApiResponse {
  'hydra:member': TransactionAttachment[];
}
interface TransactionBudgetTransaction
  extends Omit<BudgetTransaction, 'transaction'> {
  transaction: string;
}

export interface Transaction {
  ['@id']: string;
  amount: number;
  bankAccount: string;
  targetAmount: number;
  connectedMonth: Date;
  cost: Cost;
  description: string;
  currency: string;
  employeeOwner: string | null;
  endingBalance: number;
  execDate: Date;
  id: number;
  ignored: number;
  note: string;
  orderDate: Date;
  relatedDate: Date | null;
  transactionAttachments: TransactionAttachment[];
  budgetTransactions: TransactionBudgetTransaction[];
}

export interface TransactionUpdate extends Omit<Transaction, 'cost'> {
  cost: string | null;
}

export interface TransactionAttachment extends Omit<Transaction, 'cost'> {
  cost: string;
  currency: string;
  createdAt: Date;
  employee: number;
  externalId: string;
  file: StorageResponse | null;
  name: string;
  paidAt: Date | null;
  receivedAt: Date;
  relatedMonth: Date;
  transaction: number | null;
}

export interface TransactionAttachmentMutationUpdate
  extends Omit<TransactionAttachment, 'cost' | 'transaction'> {
  transaction: string;
  cost: string;
}

interface Filters {
  bankAccount: number;
  orderDate: string;
  cost: string;
  page: number;
  itemsPerPage: number;
  'execDate[before]': string;
  'execDate[after]': string;
  'amount[gte]': string;
  'amount[lte]': string;
}

interface AttachmentFilters {
  page: number;
  'exists[transaction]': boolean;
}

export const transactionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateTransaction: builder.mutation<
      Transaction,
      Partial<TransactionUpdate> & Pick<TransactionUpdate, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/transactions/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Transactions'],
    }),
    getTransactionAttachments: builder.query<
      TransactionAttachmentsData,
      Partial<AttachmentFilters>
    >({
      query: (filters) => ({
        url: '/transaction-attachments',
        params: filters,
      }),
      providesTags: ['Transactions'],
    }),
    getTransactionAttachment: builder.query<TransactionAttachment, number>({
      query: (id) => `/transaction-attachments/${id}`,
      providesTags: ['Transactions'],
    }),
    updateTransactionAttachment: builder.mutation<
      TransactionAttachment,
      Partial<TransactionAttachmentMutationUpdate> &
        Pick<TransactionAttachment, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/transaction-attachments/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Transactions'],
    }),
    addTransactionAttachment: builder.mutation({
      query: (body) => ({
        url: '/transaction-attachments',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transactions'],
    }),
    addTransactions: builder.mutation({
      query: (body) => ({
        url: '/transactions/import',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transactions'],
    }),
    deleteTransactionAttachment: builder.mutation({
      query: (id: number) => ({
        url: `/transaction-attachments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Transactions'],
    }),
    getTransactions: builder.query<TransactionsData, Partial<Filters>>({
      query: (filters) => ({
        url: '/transactions',
        params: filters,
      }),
      providesTags: ['Transactions'],
    }),
    getUnassignedTransactions: builder.query<
      TransactionsData,
      Partial<Filters>
    >({
      query: (filters) => ({
        url: '/transactions/unassigned',
        params: filters,
      }),
      providesTags: ['Transactions'],
    }),
  }),
});

export const {
  useUpdateTransactionMutation,
  useGetTransactionAttachmentsQuery,
  useGetTransactionAttachmentQuery,
  useUpdateTransactionAttachmentMutation,
  useAddTransactionAttachmentMutation,
  useAddTransactionsMutation,
  useDeleteTransactionAttachmentMutation,
  useGetTransactionsQuery,
  useGetUnassignedTransactionsQuery,
} = transactionsApi;
