import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CardMedia, Grid, Typography } from '@mui/material';
import MenuLink from 'components/Menu/styled/MenuLink.styled';
import Logo from 'components/Menu/styled/Logo.styled';
import LogoUrl from 'assets/img/logo.svg';
import menuLinks, { Link } from 'components/Menu/menuLinks';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import checkFeature from 'helpers/checkFeature';
import checkRoles from 'helpers/checkRoles';
import MenuSettings from './MenuSettings/MenuSettings';

export default function Menu() {
  const { t } = useTranslation();
  const organizationRoles = useSelector(
    (state: RootState) => state.organization.organization.roles,
  );

  const organizationFeatures = useSelector(
    (state: RootState) => state.organization.organization.features,
  );

  const shouldDisplayLink = (link: Link) => {
    if (link.displayFeature) {
      return (
        checkFeature(link.displayFeature, organizationFeatures) &&
        checkRoles(link.displayRoles, organizationRoles)
      );
    }
    return checkRoles(link.displayRoles, organizationRoles);
  };

  return (
    <Grid
      item
      xs={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
      position="sticky"
      top="0"
      borderRight="0.1rem solid"
      borderColor="secondary.light"
      overflow="auto"
    >
      <Grid container>
        <Logo>
          <CardMedia
            component="img"
            loading="lazy"
            src={LogoUrl}
            alt={t('menu.logo')}
          />
        </Logo>
        <Box pt={3} width="100%">
          {menuLinks.map((link) =>
            shouldDisplayLink(link) ? (
              <MenuLink key={link.to} to={link.to}>
                {link.icon}
                <Typography color="text.primary">{t(link.name)}</Typography>
              </MenuLink>
            ) : null,
          )}
        </Box>
      </Grid>
      <MenuSettings />
    </Grid>
  );
}
