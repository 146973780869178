import React from 'react';
import { Stack, Typography, IconButton } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Candidate } from 'store/candidates/interfaces';
import TileTags from 'components/TileTags/TileTags';
import PinMapIcon from 'assets/icons/PinMapIcon';

interface ResourceCandidateTileProps {
  resourceCandidate: Candidate;
  index: number;
}

export default function ResourceCandidateTile({
  resourceCandidate,
  index,
}: ResourceCandidateTileProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Draggable
      key={resourceCandidate.id}
      draggableId={resourceCandidate.id}
      index={index}
    >
      {(tileProvider, snapshot) => (
        <StyledContentBox
          sx={{
            opacity: snapshot.isDragging ? 0.8 : 1,
          }}
          p={3}
          ref={tileProvider.innerRef}
          style={tileProvider.draggableProps.style}
          data-rbd-draggable-context-id={
            tileProvider.draggableProps['data-rbd-draggable-context-id']
          }
          data-rbd-draggable-id={
            tileProvider.draggableProps['data-rbd-draggable-id']
          }
          onTransitionEnd={tileProvider.draggableProps.onTransitionEnd}
          data-rbd-drag-handle-draggable-id={
            tileProvider?.dragHandleProps?.['data-rbd-drag-handle-draggable-id']
          }
          data-rbd-drag-handle-context-id={
            tileProvider?.dragHandleProps?.['data-rbd-drag-handle-context-id']
          }
          aria-describedby={tileProvider?.dragHandleProps?.['aria-describedby']}
          role={tileProvider?.dragHandleProps?.role}
          tabIndex={tileProvider?.dragHandleProps?.tabIndex}
          draggable={tileProvider?.dragHandleProps?.draggable}
          onDragStart={tileProvider?.dragHandleProps?.onDragStart}
        >
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Typography fontWeight={700} sx={{ wordBreak: 'break-all' }}>
                  {resourceCandidate.firstname} {resourceCandidate.lastname}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {resourceCandidate.email}
                </Typography>
              </Stack>
              <IconButton
                sx={{
                  width: '3.6rem',
                  height: '3.6rem',
                }}
                onClick={() =>
                  navigate(`/human-resources/candidate/${resourceCandidate.id}`)
                }
              >
                <MoreVertIcon sx={{ fontSize: '2rem', color: 'icon.dark' }} />
              </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <PinMapIcon
                sx={{
                  color: 'primary.main',
                  fontSize: '2rem',
                }}
              />
              <Typography variant="body2">
                {resourceCandidate?.location || t('label.not_available')}
              </Typography>
            </Stack>
            {!!resourceCandidate.tags?.length && (
              <TileTags tags={resourceCandidate.tags} />
            )}
          </Stack>
        </StyledContentBox>
      )}
    </Draggable>
  );
}
