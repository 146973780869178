import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loader from 'common/Loader';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import { useGetFeedbacksQuery } from 'store/feedbacks/feedbacks';
import Error from 'common/Error';
import rates from 'components/AddFeedbackForm/rates';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import { QuestionMark } from '@mui/icons-material';
import { LoggedEmployee } from 'store/employee/interfaces';
import { FeedbackData } from 'store/feedbacks/interfaces';
import createApiIri from 'helpers/createApiIri';
import headers from './headers';
import feedbacksTypes from './feedbackTypes';

export default function EmployeeFeedbacksList() {
  const { t } = useTranslation();
  const { data: currentUser = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery();
  const {
    data: feedbackData = {} as FeedbackData,
    isLoading: feedbacksFetching,
    isError: feedbacksFetchError,
  } = useGetFeedbacksQuery({
    employee: createApiIri('employees', `${currentUser.id}`),
  });
  const {
    data: employees = [],
    isLoading: employeesFetching,
    isError: employeesFetchError,
  } = useGetEmployeesPreview({ isActive: true });

  const employeeFeedbacks = feedbackData['hydra:member'] || [];

  const isManager = useCheckRoles(roles.feedbackManager);

  const getAuthorName = (authorIri: string) => {
    const author = employees.find((employee) => employee['@id'] === authorIri);
    if (!author) {
      return '';
    }

    return `${author.lastname} ${author.firstname}`;
  };

  const getRateIcon = (rateValue: string) => {
    const rate = Object.values(rates).find((item) => item.value === rateValue);
    if (!rate) {
      return null;
    }

    return <TitleTooltip title={t(rate.label)}>{rate.icon}</TitleTooltip>;
  };

  const getFeedbackVisibility = (visibility: string) => {
    const visibilityData = feedbacksTypes.find(
      (type) => type.value === visibility,
    );

    if (!visibilityData) {
      return {
        label: '',
        icon: <QuestionMark />,
        value: '',
      };
    }

    return visibilityData;
  };

  if (feedbacksFetching || employeesFetching) {
    return <Loader />;
  }

  if (feedbacksFetchError || employeesFetchError) {
    return <Error />;
  }

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {isManager && (
              <TableCell>
                <Typography variant="tableHeader" color="text.secondary">
                  {t('label.type')}
                </Typography>
              </TableCell>
            )}
            {headers.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography color="text.secondary" variant="tableHeader">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeFeedbacks.length ? (
            employeeFeedbacks.map((feedback) => (
              <TableRow key={feedback.id}>
                {isManager && (
                  <TableCell align="center">
                    <TitleTooltip
                      title={t(
                        getFeedbackVisibility(feedback.visibility).label,
                      )}
                    >
                      {getFeedbackVisibility(feedback.visibility).icon}
                    </TitleTooltip>
                  </TableCell>
                )}
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Typography variant="body2">
                    {getAuthorName(feedback.authorEmployee)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {feedback.description}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2">
                    {getRateIcon(feedback.rate)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoDataRow headers={headers} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
