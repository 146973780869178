import { TableHeadersProps } from 'interfaces';
import roles from 'config/roles/roles';

interface HeadersProps extends TableHeadersProps {
  displayRole: string[];
  width: string;
}

const tableHeaders: HeadersProps[] = [
  {
    label: 'label.name',
    align: 'left',
    displayRole: roles.projects,
    width: '30%',
  },
  {
    label: 'projects.client',
    align: 'left',
    displayRole: [...roles.clients, ...roles.debtCollection],
    width: '30%',
  },
  {
    label: 'menu.employees',
    align: 'left',
    displayRole: roles.projects,
    width: '30%',
  },
];

export default tableHeaders;
