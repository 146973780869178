import React, { useState } from 'react';
import { TableRow, TableCell, Typography, Dialog, Stack } from '@mui/material';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import EditButton from 'common/EditButton/EditButton';
import { useDeleteProjectPhaseMutation } from 'store/projectPhases/projectPhases';
import { CustomError } from 'store/api';
import { StatusProps } from 'hoc/Status/Status';
import { ProjectPhase } from 'store/projectPhases/interfaces';
import EditProjectPhase from 'components/ProjectPhaseForm/EditProjectPhase';
import DeleteButton from 'common/DeleteButton/DeleteButton';
import formatDateRange from 'helpers/formatDateRange';

export interface PhaseProps extends StatusProps {
  projectPhase: ProjectPhase;
}

export default function Phase({
  projectPhase,
  setErrorMessage,
  setIsError,
  setIsSuccess,
}: PhaseProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [deleteProjectPhase] = useDeleteProjectPhaseMutation();

  const handleDelete = async (id: number) => {
    try {
      await deleteProjectPhase(id).unwrap();
      setIsSuccess(true);
      setIsConfirmationDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  const getPhaseDateTo = ({ dateTo, dateToPrediction }: ProjectPhase) => {
    if (dateTo) {
      return new Date(dateTo);
    }
    if (dateToPrediction) {
      return new Date(dateToPrediction);
    }
    return null;
  };

  const dateRange = formatDateRange({
    dateFrom: new Date(projectPhase.dateFrom),
    dateTo: getPhaseDateTo(projectPhase),
  });

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{projectPhase.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{projectPhase.type}</Typography>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" alignItems="center">
            <Typography variant="body2">{dateRange.text}</Typography>
          </Stack>
        </TableCell>
        <TableCell align="right">
          <EditButton
            v2
            onClick={() => {
              setIsDialogOpen(true);
            }}
          />
          <DeleteButton onClick={() => setIsConfirmationDialogOpen(true)} />
        </TableCell>
      </TableRow>
      <Dialog
        fullWidth
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
      >
        <EditProjectPhase
          setIsOpen={setIsDialogOpen}
          projectPhase={projectPhase}
        />
      </Dialog>
      <ConfirmationDialog
        handleAccept={() => handleDelete(projectPhase.id)}
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
      />
    </>
  );
}
