import api, { CustomError } from 'store/api';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';
import { OrganizationUsersResponseDto } from './interfaces';

export const organizationUsersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationUsers: builder.query<OrganizationUsersResponseDto, void>({
      query: () => '/organization-users',
      providesTags: ['OrganizationUsers'],
      transformResponse: (response: OrganizationUsersResponseDto) => ({
        ...response,
        'hydra:member': response['hydra:member'].sort(
          (organizationUser, comparedOrganizationUser) => {
            const { firstname: firstNameUser, lastname: lastNameUser } =
              organizationUser.user;
            const {
              firstname: firstNameComparedUser,
              lastname: lastNameComparedUser,
            } = comparedOrganizationUser.user;

            const sortedByLastName =
              lastNameUser.localeCompare(lastNameComparedUser);
            if (sortedByLastName !== 0) {
              return sortedByLastName;
            }

            return firstNameUser.localeCompare(firstNameComparedUser);
          },
        ),
      }),
    }),
    deleteOrganizationUser: builder.mutation({
      query: (id: string) => ({
        url: `/organization-users/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const organizationUsersCache = api.util.selectInvalidatedBy(
            getState(),
            [{ type: 'OrganizationUsers' }],
          );

          organizationUsersCache
            .filter(
              ({ endpointName }) => endpointName === 'getOrganizationUsers',
            )
            .forEach(({ originalArgs }) => {
              dispatch(
                organizationUsersApi.util.updateQueryData(
                  'getOrganizationUsers',
                  originalArgs,
                  (draft) => ({
                    ...draft,
                    'hydra:member': draft['hydra:member'].filter(
                      (user) => user.id !== id,
                    ),
                  }),
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
  }),
});

export const {
  useGetOrganizationUsersQuery,
  useDeleteOrganizationUserMutation,
} = organizationUsersApi;
