import api from 'store/api';
import {
  ProjectPhase,
  ProjectPhaseMutationUpdateRequest,
  ProjectPhaseMutationAddRequest,
} from './interfaces';

export const projectPhasesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addProjectPhase: builder.mutation<
      ProjectPhase,
      ProjectPhaseMutationAddRequest
    >({
      query: (body) => ({
        url: '/project-phases',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Projects'],
    }),
    updateProjectPhase: builder.mutation<
      ProjectPhase,
      ProjectPhaseMutationUpdateRequest
    >({
      query: ({ id, ...body }) => ({
        url: `/project-phases/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Projects'],
    }),
    deleteProjectPhase: builder.mutation<void, number>({
      query: (id) => ({
        url: `/project-phases/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Projects'],
    }),
  }),
});

export const {
  useAddProjectPhaseMutation,
  useUpdateProjectPhaseMutation,
  useDeleteProjectPhaseMutation,
} = projectPhasesApi;
