import { Select, SelectProps, styled } from '@mui/material';

const StyledSelect = styled(Select)<SelectProps>(() => ({
  '& > div.MuiSelect-select.MuiSelect-outlined': {
    paddingRight: '2.6rem',
  },
  '& fieldset': {
    display: 'none',
  },
}));

export default StyledSelect;
