import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import CheckSmallIcon from 'assets/icons/CheckSmallIcon';

const iconMap = {
  save: <CheckSmallIcon />,
  addInCircle: <AddCircleOutlineIcon />,
  add: <AddIcon />,
};

export interface AddButtonProps {
  label?: string;
  isLoading?: boolean;
  disabled?: boolean;
  testId?: string;
  iconType?: keyof typeof iconMap;
  noIcon?: boolean;
}

export default function SubmitButton({
  label = 'button.save',
  isLoading = false,
  disabled = false,
  testId = 'submit-button',
  iconType = 'add',
  noIcon = false,
}: AddButtonProps) {
  const { t } = useTranslation();
  const labelText = t(label);

  return (
    <LoadingButton
      endIcon={noIcon ? undefined : iconMap[iconType]}
      loading={isLoading}
      disabled={disabled}
      type="submit"
      variant="contained"
      data-testid={testId}
    >
      {labelText}
    </LoadingButton>
  );
}
