import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Error from 'common/Error';
import Loader from 'common/Loader';
import StepperStepButtons from 'common/StepperStepButtons/StepperStepButtons';
import { TagGroupsData } from 'store/tagGroups/interfaces';
import { useGetTagGroupsQuery } from 'store/tagGroups/tagGroups';
import CandidateRequirementTagGroup from './CandidateRequirementTagGroup/CandidateRequirementTagGroup';

interface ResourceCandidateRequirementProps {
  activeStep: number;
  handleBackStep: () => void;
  handleNextStep: () => void;
}

export default function ResourceCandidateRequirement({
  activeStep,
  handleBackStep,
  handleNextStep,
}: ResourceCandidateRequirementProps) {
  const { t } = useTranslation();
  const {
    data: tagGroups = {} as TagGroupsData,
    isLoading: tagGroupsLoading,
    isError: tagGroupsError,
  } = useGetTagGroupsQuery();

  if (tagGroupsLoading) {
    return <Loader />;
  }

  if (tagGroupsError) {
    return <Error />;
  }

  return (
    <Stack justifyContent="space-between" height="100%" py={6} px={4}>
      <Typography variant="h5">
        {t('human_resources.candidate_requirements')}
      </Typography>
      <Divider sx={{ my: 6 }} />
      <Stack
        px={2}
        spacing={6}
        mb={6}
        sx={{
          overflowY: 'auto',
        }}
      >
        {tagGroups['hydra:member'].map((tagGroup) => (
          <CandidateRequirementTagGroup key={tagGroup.id} tagGroup={tagGroup} />
        ))}
      </Stack>
      <StepperStepButtons
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        activeStep={activeStep}
      />
    </Stack>
  );
}
