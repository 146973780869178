import React, { useRef } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import StyledUploadFileWrapper from './UploadFileWrapper.styled';

interface AddImageFileProps {
  handleUploadFile: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  uploadLabel: string;
}
export default function AddImageFile({
  handleUploadFile,
  uploadLabel,
}: AddImageFileProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleOpenFileUpload = () => fileInputRef.current?.click();
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        '& .MuiStack-root': {
          marginLeft: 0,
        },
      }}
    >
      <input
        aria-label={t('label.file_input')}
        hidden
        ref={fileInputRef}
        type="file"
        onChange={handleUploadFile}
      />
      <StyledUploadFileWrapper onClick={handleOpenFileUpload}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <UploadFileIcon
            sx={{
              color: 'icon.main',
              fontSize: '2rem',
            }}
          />
          <Typography
            variant="subtitle1"
            color="primary.main"
            sx={{ wordBreak: 'break-all' }}
          >
            {uploadLabel}
          </Typography>
          <Typography
            color="text.secondary"
            fontWeight={400}
            variant="subtitle1"
          >
            {t('property.upload_property_photo_description')}
          </Typography>
        </Stack>
      </StyledUploadFileWrapper>
    </Stack>
  );
}
