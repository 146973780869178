import React, { useEffect } from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { HolidaysCondition } from 'store/condition/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getChipColor } from 'components/ProjectsCondition/utils';
import setBalance from 'store/condition/actions';
import { ConditionProperties } from 'enums';
import calculateCostsSum from '../utils';

interface HolidaysBalanceProps {
  month: string;
  data: HolidaysCondition;
}

export default function HolidaysBalance({ month, data }: HolidaysBalanceProps) {
  const formatAmount = useCurrencyFormat();
  const currentBalance = useSelector((state: RootState) => state.balance);
  const monthlyBalance =
    currentBalance.find(
      (item) =>
        item.collection === ConditionProperties.projects &&
        item.month === month,
    )?.value ?? 0;
  const monthlyHolidaysCost = calculateCostsSum(month, data);
  const balance = monthlyBalance - monthlyHolidaysCost;
  const chipColor = getChipColor(month, balance);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBalance({ collection: 'holidays', month, value: monthlyHolidaysCost }),
    );
  }, [monthlyHolidaysCost, dispatch, month]);

  return (
    <>
      <Grid item xs={1.8} />
      <Grid item xs={1.2}>
        <Chip
          color={chipColor}
          label={
            <Typography variant="chipLabel">{formatAmount(balance)}</Typography>
          }
        />
      </Grid>
    </>
  );
}
