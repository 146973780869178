import React, { useState, useEffect } from 'react';
import { Dialog, useMediaQuery } from '@mui/material';
import theme from 'config/material-ui/theme';
import { DialogOverlayProps } from './interfaces';
import { glowWidthOffset, glowXOffset, windowWidth } from './consts';

export default function DialogOverlay({
  selectedBar,
  children,
  onClose,
  open,
}: DialogOverlayProps) {
  const [windowDimensions, setWindowDimensions] = useState({
    scrollY: window.scrollY,
    innerWidth: window.innerWidth,
  });

  const isBreakpointXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));
  const glowToWindowDistance = isBreakpointMd ? 15 : 0;
  const glowHeight = isBreakpointXl ? 706 : 520;
  const positionY = 200 - windowDimensions.scrollY;

  const windowRightSide =
    selectedBar.x +
    selectedBar.width +
    glowWidthOffset +
    glowXOffset +
    glowToWindowDistance;
  const windowLeftSide =
    selectedBar.x - windowWidth + glowXOffset - glowToWindowDistance;

  const shouldDisplayOnTheRight =
    windowRightSide + windowWidth + glowWidthOffset <
    windowDimensions.innerWidth;

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        scrollY: window.scrollY,
        innerWidth: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          display: 'block',
        },
        '& .MuiDialog-paper': {
          left: shouldDisplayOnTheRight ? windowRightSide : windowLeftSide,
          margin: 0,
          top: Math.max(positionY, 0),
          width: windowWidth,
        },
        '&::after': {
          content: '""',
          display: isBreakpointMd ? 'block' : 'none',
          position: 'absolute',
          top: positionY,
          left: selectedBar.x + glowXOffset,
          boxShadow: '0 0 999rem 999rem rgba(0, 0, 0, 0.3)',
          borderRadius: '1.2rem',
          zIndex: -1,
          width: selectedBar.width + glowWidthOffset,
          height: glowHeight,
          pointerEvents: 'none',
          border: '0.1rem solid',
          borderColor: 'rating.empty',
        },
      }}
      slotProps={{
        backdrop: { sx: { background: 'transparent' } },
      }}
    >
      {children}
    </Dialog>
  );
}
