import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Error from 'common/Error/Error';
import Loader from 'common/Loader';
import EditButton from 'common/EditButton/EditButton';
import { useGetClientsQuery } from 'store/clients/clients';
import clientStatuses from 'components/ClientsList/clientsSelectData';
import CountryName from 'components/ClientsList/CountryName/CountryName';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import DefaultCostValue from 'components/ClientsList/DefaultCostValue/DefaultCostValue';
import getTranslationKey from 'helpers/getTranslationKey';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import roles from 'config/roles/roles';
import tableHeaders from './tableHeaders';

export default function ClientsList() {
  const {
    data: clients = [],
    isError: clientsFetchError,
    isLoading: clientsLoading,
  } = useGetClientsQuery({});
  const organizationRoles = useSelector(
    (state: RootState) => state.organization.organization.roles,
  );
  const checkRoles = (rolesData: string[]) =>
    rolesData.some((role) => organizationRoles.includes(role));

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (clientsFetchError) {
    return <Error />;
  }

  if (clientsLoading) {
    return <Loader />;
  }

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map(
              (header) =>
                checkRoles(header.displayRoles) && (
                  <TableCell key={header.label} align={header.align}>
                    <Typography variant="tableHeader" color="text.secondary">
                      {t(header.label)}
                    </Typography>
                  </TableCell>
                ),
            )}
            <TableCell colSpan={tableHeaders.length} />
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.length ? (
            clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell>
                  <Typography variant="body2">{client.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {t(getTranslationKey(clientStatuses, client.status))}
                  </Typography>
                </TableCell>
                <TableCell>
                  {client.country && (
                    <CountryName countryIri={client.country} />
                  )}
                </TableCell>
                {checkRoles(roles.contractors) && (
                  <TableCell>
                    {client.defaultCost ? (
                      <DefaultCostValue costIri={client.defaultCost} />
                    ) : (
                      '---'
                    )}
                  </TableCell>
                )}
                <TableCell align="right">
                  <EditButton
                    onClick={() => navigate(`/clients/${client.id}`)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoDataRow headers={tableHeaders} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
