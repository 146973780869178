import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import CompanyWorkingDays from 'components/CompanyWorkingDays';
import CompanyDefaultCurrency from 'components/CompanyDefaultCurrency';
import OrganizationCalendarType from 'components/OrganizationCalendarType';
import CompanyStartDate from 'components/CompanyStartDate';
import CompanyTaxIdNumber from 'components/CompanyTaxIdNumber';
import OrganizationBillingAddress from 'components/OrganizationBillingAddress';
import OrganizationBillingPhoneNumber from 'components/OrganizationBillingPhoneNumber';
import InvoicePrefix from 'components/InvoicePrefix';
import InvoiceSendCcsName from 'components/InvoiceSendCcsName';
import InvoiceSendCcsEmail from 'components/InvoiceSendCcsEmail';

function OrganizationSettings() {
  const { t } = useTranslation();
  const organizationName = useSelector(
    (state: RootState) => state.organization.organization.name,
  );

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>
          {t('settings.organization_settings')}: {organizationName}
        </NavBarTitle>
      </NavBarContainer>
      <Grid container justifyContent="center" alignItems="center" gap={3} p={2}>
        <CompanyWorkingDays />
        <OrganizationCalendarType />
        <CompanyDefaultCurrency />
        <CompanyStartDate />
        <CompanyTaxIdNumber />
        <OrganizationBillingAddress />
        <OrganizationBillingPhoneNumber />
        <InvoicePrefix />
        <InvoiceSendCcsName />
        <InvoiceSendCcsEmail />
      </Grid>
    </Grid>
  );
}

export default OrganizationSettings;
