import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import store from 'store';
import App from 'components/App';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import translations from 'translations';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'config/material-ui/theme';
import LanguageDetector from 'i18next-browser-languagedetector';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import ReactGA from 'react-ga4';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: translations,
  });

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <ScrollToTop>
            <CssBaseline />
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_API_CLIENT_ID}
            >
              <App />
            </GoogleOAuthProvider>
          </ScrollToTop>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.unregister();
