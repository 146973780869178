import { TableHeadersProps } from 'interfaces';

interface InvoiceTableHeadersProps extends TableHeadersProps {
  width: string;
}

const tableHeaders: InvoiceTableHeadersProps[] = [
  {
    label: 'invoices.invoice',
    align: 'left',
    width: '25%'
  },
  {
    label: '',
    align: 'left',
    width: '10%'
  },
  {
    label: 'invoices.sale_date',
    align: 'center',
    width: '18%'
  },
  {
    label: 'invoices.payment_status',
    align: 'center',
    width: '18%'
  },
  {
    label: 'invoices.details',
    align: 'right',
    width: '18%'
  },
];

export default tableHeaders;
