import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkingHoursStats } from 'store/workingHours/workingHours';

interface WorkingHoursProps {
  hoursStats: WorkingHoursStats;
}

export default function WorkingHours({ hoursStats }: WorkingHoursProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography color="primary" textTransform="uppercase" variant="h6">
        {t('label.working_hours')}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="bold">
          {t('dashboard.month_hours_filled')}
        </Typography>
        <Typography variant="body1">{hoursStats.monthHoursFilled}h</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="bold">
          {t('dashboard.month_hours_to_filled')}
        </Typography>
        <Typography variant="body1">
          {hoursStats.monthHoursToBeFilled}h
        </Typography>
      </Box>
    </>
  );
}
