import React, { useState } from 'react';
import { Dialog, TableCell, TableRow, Typography } from '@mui/material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import fullDateFormat from 'helpers/fullDateFormat';
import { EmployeeBenefit } from 'store/employeeBenefit/interfaces';
import { useGetBenefitsQuery } from 'store/benefits/benefits';
import { Benefit as BenefitData } from 'store/benefits/interfaces';
import { StatusProps } from 'hoc/Status/Status';
import TooltipIconButton from 'common/TooltipIconButton/TooltipIconButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditBenefit from '../EditBenefit';

export interface BenefitProps extends StatusProps {
  result: EmployeeBenefit;
}

const emptyBenefit: BenefitData[] = [];

export default function Benefit({ result }: BenefitProps) {
  const { benefitData } = useGetBenefitsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      benefitData:
        data?.filter((item) => item['@id'] === result.benefit) ?? emptyBenefit,
    }),
  });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="bold">{benefitData[0]?.name}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1">
            {fullDateFormat(result.dateFrom)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {result.dateTo ? (
            <Typography variant="body1">
              {fullDateFormat(result.dateTo)}
            </Typography>
          ) : (
            <AllInclusiveIcon fontSize="large" />
          )}
        </TableCell>
        <TableCell align="right">
          <TooltipIconButton
            icon={<BorderColorIcon />}
            onClick={() => setIsEditDialogOpen(true)}
            translationKey="label.edit"
          />
        </TableCell>
      </TableRow>
      <Dialog
        fullWidth
        onClose={() => setIsEditDialogOpen(false)}
        open={isEditDialogOpen}
      >
        <EditBenefit benefit={result} setIsOpen={setIsEditDialogOpen} />
      </Dialog>
    </>
  );
}
