import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'budget.budget_cost',
    align: 'left',
  },
  {
    label: 'budget.budget_date_add',
    align: 'left',
  },
  {
    label: 'budget.budget_transaction_amount',
    align: 'left',
  },
  {
    label: 'budget.budget_reporting_person',
    align: 'left',
  },
];

export default tableHeaders;
