import roles from 'config/roles/roles';

const configurationTilesData = [
  {
    id: 0,
    header: 'settings.holidays',
    description: 'settings.holidays_description',
    navigateTo: '/holidays-settings',
    displayRoles: roles.bankHolidays,
  },
  {
    id: 1,
    header: 'settings.currencies',
    description: 'settings.currencies_description',
    navigateTo: '/currencies',
    displayRoles: roles.currencies,
  },
  {
    id: 2,
    header: 'settings.countries',
    description: 'settings.countries_description',
    navigateTo: '/countries',
    displayRoles: roles.countries,
  },
  {
    id: 3,
    header: 'settings.bank_holidays',
    description: 'settings.bank_holidays_description',
    navigateTo: '/bank-holidays',
    displayRoles: roles.bankHolidays,
  },
  {
    id: 4,
    header: 'settings.positions',
    description: 'settings.positions_description',
    navigateTo: '/positions',
    displayRoles: roles.positions,
  },
  {
    id: 5,
    header: 'settings.banks',
    description: 'settings.banks_description',
    navigateTo: '/banks',
    displayRoles: roles.banks,
  },
  {
    id: 6,
    header: 'settings.calendar',
    description: 'settings.calendar_description',
    navigateTo: '/calendar-settings',
    displayRoles: roles.holidaysManager,
  },
  {
    id: 7,
    header: 'settings.documents',
    description: 'settings.documents_description',
    navigateTo: '/document-type-management',
    displayRoles: roles.documents,
  },
  {
    id: 8,
    header: 'settings.contractor_groups',
    description: 'settings.contractor_groups_description',
    navigateTo: '/contractor-groups',
    displayRoles: roles.contractors,
  },
  {
    id: 9,
    header: 'settings.locations',
    description: 'settings.locations_description',
    navigateTo: '/locations',
    displayRoles: roles.locations,
  },
  {
    id: 10,
    header: 'settings.properties',
    description: 'settings.properties_description',
    navigateTo: '/properties',
    displayRoles: roles.properties,
  },
  {
    id: 11,
    header: 'settings.organization_settings',
    description: 'settings.organization_settings_description',
    navigateTo: '/organization-settings',
    displayRoles: roles.admin,
  },
  {
    id: 12,
    header: 'settings.mail_footer',
    description: 'settings.mail_footer_description',
    navigateTo: '/mail-footer',
    displayRoles: roles.user,
  },
  {
    id: 13,
    header: 'settings.holiday_types',
    description: 'settings.holiday_types_description',
    navigateTo: '/holiday-types',
    displayRoles: roles.holidaysManager,
  },
  {
    id: 14,
    header: 'settings.default_annual_leave',
    description: 'settings.default_annual_leave_description',
    navigateTo: '/annual-leave',
    displayRoles: roles.holidaysManager,
  },
];

export default configurationTilesData;
