import React, { useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import BenefitsList from 'components/BenefitsList/BenefitsList';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddBenefit from 'components/BenefitForm/AddBenefit';

export default function Benefits() {
  const { t } = useTranslation();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={10} bgcolor="background.list">
        <NavBarContainer>
          <NavBarTitle>{t('menu.benefits')}</NavBarTitle>
          <Button
            endIcon={<AddCircleOutlineIcon />}
            onClick={() => setIsAddDialogOpen(true)}
            variant="contained"
          >
            {t('button.create')}
          </Button>
        </NavBarContainer>
        <BenefitsList />
      </Grid>
      <Dialog
        fullWidth
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
      >
        <AddBenefit setIsOpen={setIsAddDialogOpen} />
      </Dialog>
    </>
  );
}
