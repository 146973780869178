import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { Grid, Dialog, Button, Tab } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import HumanResourcesCandidatesList from 'components/HumanResourcesCandidatesList/HumanResourcesCandidatesList';
import HrResourcesList from 'components/HrResourcesList/HrResourcesList';
import CandidateForm from 'components/CandidateForm/CandidateForm';
import { FormPayload, FormType } from 'components/CandidateForm/interfaces';
import { useAddCandidateMutation } from 'store/candidates/candidates';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { CandidatePostMutationRequest } from 'store/candidates/interfaces';
import getBgColorForTab from 'helpers/getBgColorForTab';
import { ResourceRequestCandidateState } from 'store/resourceRequestCandidates/interfaces';
import {
  useAddResourceRequestCandidateMutation
} from 'store/resourceRequestCandidates/resourceRequestCandidates';

export interface HumanResourcesProps extends StatusProps {}
export enum HumanResourcesTabValue {
  candidates = '1',
  resources = '2',
}

export default function HumanResources({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: HumanResourcesProps) {
  const { state } = useLocation();
  const initTabValue =
    (state?.tabValue as HumanResourcesTabValue) ||
    HumanResourcesTabValue.candidates;

  const [tabValue, setTabValue] = useState(initTabValue);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedResources, setSelectedResources] = useState<Set<string>>(new Set<string>());

  const [addCandidate, { isLoading: addCandidateLoading }] =
    useAddCandidateMutation();
  const [addResourceRequestCandidate] =
    useAddResourceRequestCandidateMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const listingTabs = [HumanResourcesTabValue.candidates];

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: HumanResourcesTabValue,
  ) => setTabValue(newValue);

  const handleResourceToCandidate = async (candidateIri: string, resourceIri: string) => {
    try {
      await addResourceRequestCandidate({
        candidate: candidateIri,
        resourceRequest: resourceIri,
        state: ResourceRequestCandidateState.applied,
      }).unwrap();
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  }

  const handleAddCandidate = async (formPayload: FormPayload) => {
    try {
      const { phoneNumber, ...restOfFormPayload } = formPayload;
      const filledPayload = Object.fromEntries(
        Object.entries(restOfFormPayload).filter(([, value]) => value),
      );

      const payload = {
        ...filledPayload,
        ...(phoneNumber && { phoneNumber: phoneNumber.replace(/\s/g, '') }),
      } as CandidatePostMutationRequest;

      const candidate = await addCandidate(payload).unwrap()
      if (selectedResources.size > 0) {
        selectedResources?.forEach(resource => {
          handleResourceToCandidate(candidate['@id'], resource as string)
        })
      }
      setIsSuccess(true);
      setIsDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const getAddButtonLabel = (currentTabValue: HumanResourcesTabValue) =>
    currentTabValue === HumanResourcesTabValue.resources
      ? t('human_resources.new_request')
      : t('human_resources.add_candidate');

  const handleOnButtonClick = (currentTabValue: HumanResourcesTabValue) =>
    currentTabValue === HumanResourcesTabValue.resources
      ? navigate('/human-resources/resources/add')
      : setIsDialogOpen(true);

  return (
    <Grid
      item
      xs={12}
      md={10}
      bgcolor={getBgColorForTab(tabValue, listingTabs)}
    >
      <NavBarContainer>
        <NavBarTitle>{t('human_resources.header')}</NavBarTitle>
        <Button
          onClick={() => handleOnButtonClick(tabValue)}
          variant="contained"
          endIcon={<AddCircleOutlineIcon />}
        >
          {getAddButtonLabel(tabValue)}
        </Button>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab
            label={t('human_resources.candidates')}
            value={HumanResourcesTabValue.candidates}
          />
          <Tab
            label={t('human_resources.resources')}
            value={HumanResourcesTabValue.resources}
          />
        </StyledTabList>
        <TabPanel value={HumanResourcesTabValue.candidates}>
          <HumanResourcesCandidatesList />
        </TabPanel>
        <TabPanel value={HumanResourcesTabValue.resources}>
          <HrResourcesList />
        </TabPanel>
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(!isDialogOpen)}
          fullWidth
        >
          {tabValue === HumanResourcesTabValue.candidates && (
            <CandidateForm
              setIsOpen={setIsDialogOpen}
              type={FormType.add}
              onSubmit={handleAddCandidate}
              isSubmitting={addCandidateLoading}
              selectedResources={selectedResources}
              setSelectedResources={setSelectedResources}
            />
          )}
        </Dialog>
      </TabContext>
    </Grid>
  );
}
