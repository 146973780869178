import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BenefitPrice } from 'store/benefits/interfaces';
import { useTranslation } from 'react-i18next';
import NoDataRow from 'common/NoDataRow/NoDataRow';
import headers from './headers';
import Price from './Price';

export interface BenefitPricingsListProps {
  pricings: BenefitPrice[];
}

export default function BenefitPricingsList({
  pricings,
}: BenefitPricingsListProps) {
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={headers.length} />
          </TableRow>
        </TableHead>
        <TableBody>
          {pricings.length ? (
            pricings.map((pricing) => (
              <Price key={pricing.id} pricing={pricing} />
            ))
          ) : (
            <NoDataRow headers={headers} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
