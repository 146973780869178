import api from 'store/api';
import {
  TagDefinition,
  TagDefinitionMutationRequest,
  TagDefinitionsData,
  TagDefinitionAutocompleteFilters,
} from './interfaces';

export const tagDefinitionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTagDefinitions: builder.query<TagDefinitionsData, void>({
      query: () => '/tag-definitions',
      providesTags: ['TagDefinitions'],
    }),
    getAutocompleteTagDefinitions: builder.query<TagDefinitionsData, TagDefinitionAutocompleteFilters>({
      query: (filters) => ({
        url: '/tag-definitions/autocomplete',
        params: filters,
      }),
      providesTags: ['TagDefinitions'],
    }),
    addTagDefinition: builder.mutation<
      TagDefinition,
      TagDefinitionMutationRequest
    >({
      query: (tagDefinition) => ({
        url: '/tag-definitions',
        method: 'POST',
        body: tagDefinition,
      }),
    }),
    deleteTagDefinition: builder.mutation<void, string>({
      query: (id) => ({
        url: `/tag-definitions/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddTagDefinitionMutation,
  useGetAutocompleteTagDefinitionsQuery,
  useDeleteTagDefinitionMutation,
  useGetTagDefinitionsQuery,
} = tagDefinitionsApi;
