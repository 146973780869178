import { z } from 'zod';

const schema = z.object({
  name: z
    .string()
    .min(1, { message: 'errors.field_required' })
    .max(255, { message: 'errors.too_long_name' }),
  costGroup: z.string().min(1, { message: 'errors.field_required' }),
  status: z.string().min(1, { message: 'errors.field_required' }),
  tin: z.string().min(2, { message: 'errors.too_short_tin' }).or(z.literal('')),
  matchRegex: z.string(),
  description: z.string(),
  ownerEmployee: z.string(),
  budget: z.string().optional(),
  notIncludeInChart: z.boolean(),
  fixedAmount: z.boolean(),
  requireAttachment: z.boolean(),
  cyclic: z.boolean(),
  taxAmount: z.union([z.number(), z.string()]),
  paymentDeadline: z
    .string()
    .max(2, { message: 'errors.too_long_payment_deadline' }),
});

export default schema;
