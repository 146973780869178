import React, { useState } from 'react';
import {
  useDeleteCandidateNoteMutation,
  useUpdateCandidateNoteMutation,
} from 'store/candidateNotes/candidateNotes';
import { Typography, Stack } from '@mui/material';
import { CustomError } from 'store/api';
import { CandidateNoteProps } from './interfaces';
import NoteHeader from './NoteHeader/NoteHeader';
import NoteForm from '../NoteForm/NoteForm';
import { FormPayload } from '../NoteForm/interfaces';

export default function CandidateNote({
  candidateNote,
  setErrorMessage,
  setIsError,
  setIsSuccess,
}: CandidateNoteProps) {
  const [isEditMode, setIsEditMode] = useState(false);

  const [updateCandidateNote, { isLoading: candidateUpdateNoteLoading }] =
    useUpdateCandidateNoteMutation();
  const [deleteNote] = useDeleteCandidateNoteMutation();

  const handleOnSubmit = async (data: FormPayload) => {
    try {
      await updateCandidateNote({
        id: candidateNote.id,
        note: data.note,
      }).unwrap();
      setIsSuccess(true);
      setIsEditMode(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleDeleteNote = async () => {
    try {
      await deleteNote(candidateNote.id).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleEditNote = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <Stack spacing={2} px={3}>
      <NoteHeader
        handleOnDelete={handleDeleteNote}
        candidateNote={candidateNote}
        handleOnEdit={handleEditNote}
      />
      {isEditMode ? (
        <NoteForm
          onSubmit={handleOnSubmit}
          initValues={{ note: candidateNote.note }}
          isSubmitting={candidateUpdateNoteLoading}
        />
      ) : (
        <Typography>{candidateNote.note}</Typography>
      )}
    </Stack>
  );
}
