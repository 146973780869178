import { createContext } from 'react';

interface YearGroup {
  year: number;
  top: number;
}

export interface BudgetPeriodSelectorContextProps {
  displayedYear: number;
  yearGroups: YearGroup[];
  isCreatingPeriod: boolean;
  setIsCreatingPeriod: (isCreatingPeriod: boolean) => void;
}

export const BudgetPeriodSelectorContext = createContext<
  BudgetPeriodSelectorContextProps | undefined
>(undefined);
