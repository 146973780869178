import EventSourcePolyfill from 'eventsource';
import { MercureToken } from 'interfaces';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { LoggedEmployee } from 'store/employee/interfaces';
import { useAppDispatch } from 'store/hooks';
import { ServerEventType } from 'enums';
import useHolidayRequestsEvent from './serverEvents/useHolidayRequestsEvent';
import useHolidayEvent from './serverEvents/useHolidaysEvent';

const useServerEvents = (employee: LoggedEmployee) => {
  const dispatch = useAppDispatch();
  const handleHolidayRequestsServerEvents = useHolidayRequestsEvent();
  const handleHolidaysServerEvents = useHolidayEvent();

  const createServerUrl = (token: string) => {
    const decodedToken = jwtDecode<MercureToken>(token);

    const updateUrl = new URL(process.env.REACT_APP_MERCURE_URL ?? '');

    decodedToken.mercure.subscribe.forEach((topic) => {
      updateUrl.searchParams.append('topic', topic);
    });

    return updateUrl.toString();
  };

  useEffect(() => {
    if (!employee || !employee.mercureSubscriberToken) {
      return undefined;
    }

    const serverUrl = createServerUrl(employee.mercureSubscriberToken);

    const eventSource = new EventSourcePolyfill(serverUrl, {
      headers: {
        Authorization: `Bearer ${employee.mercureSubscriberToken}`,
      },
    });

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data['@type'] === ServerEventType.holidayRequest) {
        handleHolidayRequestsServerEvents(data, employee.id);
      }

      if (data['@type'] === ServerEventType.holiday) {
        handleHolidaysServerEvents(data);
      }
    };

    return () => eventSource.close();
  }, [
    dispatch,
    employee,
    handleHolidayRequestsServerEvents,
    handleHolidaysServerEvents,
  ]);
};

export default useServerEvents;
