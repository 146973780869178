import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import getShorterDescription from 'helpers/getShorterDescription';

interface DescriptionProps {
  details: string;
}

function Description({ details }: DescriptionProps) {
  const maxDescriptionVisibleCharacters = 48;

  if (details.length < maxDescriptionVisibleCharacters) {
    return <Typography variant="body1">{details}</Typography>;
  }

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            color: 'text.primary',
            bgcolor: 'main.white',
            boxShadow: 5,
          },
        },
      }}
      placement="top-start"
      title={<Typography variant="body1">{details}</Typography>}
    >
      <Typography variant="body1">
        {getShorterDescription(details, maxDescriptionVisibleCharacters)}...
      </Typography>
    </Tooltip>
  );
}

export default Description;
