/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues } from 'react-hook-form';
import { TextField } from '@mui/material';
import getControlErrors from 'helpers/validators/getControlErrors';
import { ControlTextFieldProps } from './interfaces';

export default function ControlTextField<T extends FieldValues>({
  errors,
  control,
  name,
  label,
  id,
  inputProps,
  InputProps,
  InputLabelProps,
  sx,
  minRows,
  required = false,
  type = 'text',
  fullWidth = true,
  multiline = false,
  disabled = false,
}: ControlTextFieldProps<T>) {
  const { t } = useTranslation();
  const inputId = id ?? name;
  const controlErrors = getControlErrors(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, value, onChange } }) => (
        <TextField
          id={inputId}
          label={t(label)}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={Boolean(controlErrors)}
          helperText={controlErrors?.message && t(controlErrors.message)}
          fullWidth={fullWidth}
          required={required}
          type={type}
          sx={sx}
          multiline={multiline}
          inputProps={inputProps}
          InputProps={InputProps}
          minRows={minRows}
          InputLabelProps={InputLabelProps}
          disabled={disabled}
        />
      )}
    />
  );
}
