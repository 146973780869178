import React from 'react';
import { Stack } from '@mui/material';
import useElementWidth from 'hooks/useElementWidth';
import PeriodRangeIndicator from './PeriodRangeIndicator/PeriodRangeIndicator';
import YearGroups from './YearsGroup/YearsGroup';
import QuartersGroup from './QuartersGroup/QuartersGroup';
import MonthsGroup from './MonthsGroup/MonthsGroup';
import BudgetPeriodSelectorProvider from './context/BudgetPeriodSelectorProvider';

export default function BudgetPeriodSelector() {
  const { elementRef, elementWidth } = useElementWidth();

  return (
    <Stack
      ref={elementRef}
      width="75%"
      maxWidth="calc(100% - 36rem)"
      direction="row"
      sx={{
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      <BudgetPeriodSelectorProvider>
        <PeriodRangeIndicator periodSelectorWidth={elementWidth} />
        <MonthsGroup />
        <QuartersGroup />
        <YearGroups />
      </BudgetPeriodSelectorProvider>
    </Stack>
  );
}
