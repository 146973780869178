import React from 'react';
import { Stack, Typography, Chip } from '@mui/material';
import getAmountFormat from 'helpers/getAmountFormat';
import { FrontendBudgetConfig } from 'pages/BudgetPlanner/context/BudgetPlanner.context';
import calculateTotalAmountBudgetConfig from '../calculateTotalAmountBudgetConfig';
import getBadgeStyles from '../getBadgeStyles';

interface BarHeaderProps {
  budgetConfig: FrontendBudgetConfig;
}

export default function BarHeader({ budgetConfig }: BarHeaderProps) {
  const totalAmount = calculateTotalAmountBudgetConfig(budgetConfig);

  const getModifierSymbol = (value: string | number) => {
    const convertedValue =
      typeof value === 'string' ? +value.replaceAll(',', '') : value;

    return convertedValue > 0 ? `+${value}` : value;
  };

  const exponentialGrowth = `${getModifierSymbol(
    budgetConfig?.exponentialGrowth ||
      budgetConfig.placeholderExponentialGrowth,
  )} %`;
  const linearGrowth = getModifierSymbol(
    getAmountFormat(
      budgetConfig?.linearGrowth || budgetConfig.placeholderLinearGrowth,
    ),
  );
  const totalAmountWithoutUnitGrowth = getAmountFormat(
    +totalAmount - (budgetConfig?.unitGrowth || 0),
  );
  const unitGrowth = getModifierSymbol(
    getAmountFormat(budgetConfig?.unitGrowth || 0),
  );
  const totalAmountText = getAmountFormat(+totalAmount);

  return (
    <Stack
      key={budgetConfig.period}
      width="calc(100% / 12)"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Stack spacing={0.5} height="5.2rem" justifyContent="flex-start">
        {!!budgetConfig.placeholderExponentialGrowth && (
          <Chip
            variant="outlined"
            size="small"
            sx={getBadgeStyles('exponential')}
            label={
              <Typography variant="chipLabel">{exponentialGrowth}</Typography>
            }
          />
        )}
        {!!budgetConfig.placeholderLinearGrowth && (
          <Chip
            variant="outlined"
            size="small"
            sx={getBadgeStyles('linear')}
            label={<Typography variant="chipLabel">{linearGrowth}</Typography>}
          />
        )}
      </Stack>
      <Typography height="2.5rem" my={1}>
        {totalAmountWithoutUnitGrowth}
      </Typography>
      <Stack
        height="5.5rem"
        justifyContent="flex-start"
        spacing={0.5}
        alignItems="center"
      >
        {!!budgetConfig.unitGrowth && (
          <>
            <Chip
              variant="outlined"
              size="small"
              sx={getBadgeStyles('unit')}
              label={<Typography variant="chipLabel">{unitGrowth}</Typography>}
            />
            <Typography variant="body2" color="text.secondary">
              {totalAmountText}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
}
