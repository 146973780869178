import { useContext } from 'react';
import { ResourceRequestWizardContext } from './ResourceRequestWizardContext';

export default function useResourceRequestWizardContext() {
  const context = useContext(ResourceRequestWizardContext);
  if (!context) {
    throw new Error(
      'useResourceRequestWizardContext must be used within a ResourceRequestWizardProviderContext',
    );
  }

  return context;
}
