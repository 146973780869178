import { AgreementAmountType } from 'components/AgreementsList/AgreementsList';

interface AgreementAmountTypeItem {
  label: string;
  value: AgreementAmountType;
}

const agreementAmountTypes: AgreementAmountTypeItem[] = [
  {
    label: 'agreements.amount_type_brutto',
    value: AgreementAmountType.BRUTTO,
  },
  {
    label: 'agreements.amount_type_netto',
    value: AgreementAmountType.NETTO,
  },
];

export default agreementAmountTypes;
