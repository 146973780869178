import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack, Button } from '@mui/material';
import ArrowRightCircle from 'assets/icons/ArrowRightCircle';
import StyledButtonBack from './ButtonBack.styled';

interface StepperStepButtonsProps {
  activeStep: number;
  handleBackStep?: () => void;
  handleNextStep: () => void;
  nextStepDisabled?: boolean;
}

export default function StepperStepButtons({
  activeStep,
  handleBackStep,
  handleNextStep,
  nextStepDisabled = false,
}: StepperStepButtonsProps) {
  const { t } = useTranslation();

  const isFirstStep = activeStep === 0;
  const getNextStepButtonColor = () => nextStepDisabled ? 'border.secondaryOutlined' : 'primary.main';

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={isFirstStep ? 'flex-end' : 'space-between'}
    >
      {!isFirstStep && (
        <StyledButtonBack onClick={handleBackStep} variant="outlined">
          <Typography color="secondary.main" variant="buttonLarge">
            {t('label.back')}
          </Typography>
        </StyledButtonBack>
      )}
      <Button
        onClick={handleNextStep}
        disabled={nextStepDisabled}
        variant="outlined"
        sx={{
          padding: '0.8rem 2.2rem',
        }}
        endIcon={
          <ArrowRightCircle
            sx={{
              fontSize: '2rem',
              transition: 'color 0.3s',
              color: getNextStepButtonColor(),
            }}
          />
        }
      >
        <Typography variant="buttonLarge">{t('label.next_step')}</Typography>
      </Button>
    </Stack>
  );
}
