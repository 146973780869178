import { Box, BoxProps, styled } from '@mui/material';

const RightResize = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  width: '10px',
  height: '100%',
  cursor: 'col-resize',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '0.4rem',
    height: '4rem',
    top: '50%',
    right: '0.4rem',
    transform: 'translateY(-50%)',
    borderRadius: '0.4rem',
    backgroundColor: theme.palette.other.resizeIndicator,
  },
}));

export default RightResize;
