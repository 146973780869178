import { createContext } from 'react';

export interface BookPropertiesContextProps {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
}

export const BookPropertiesContext = createContext<
  BookPropertiesContextProps | undefined
>(undefined);
