import ProjectMemberPosition from '../interfaces';

const projectRoleOptions = [
  {
    value: ProjectMemberPosition.techLead,
    label: `projects.role_${ProjectMemberPosition.techLead}`,
  },
  {
    value: ProjectMemberPosition.accountManager,
    label: `projects.role_${ProjectMemberPosition.accountManager}`,
  },
  {
    value: ProjectMemberPosition.employee,
    label: `projects.role_${ProjectMemberPosition.employee}`,
  },
];

export default projectRoleOptions;
