import React, { useState } from 'react';
import { Property } from 'store/properties/interfaces';
import { useAddPropertyMutation } from 'store/properties/properties';
import { CustomError, ResponseErrors } from 'store/api';
import { Button, Grid, Tab } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import { useNavigate } from 'react-router-dom';
import { FormTypes } from 'enums';
import PropertyForm from '../PropertyFrom/PropertyForm';
import { formatFormData } from '../utils';
import PropertyChildren from '../PropertyChildren';
import { BookingType } from '../enums';
import { AddPropertyProps } from './interfaces';

function AddProperty({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddPropertyProps) {
  const [property, setProperty] = useState<Property>({
    '@id': '',
    id: 0,
    bookingType: '' as BookingType,
    location: null,
    name: '',
    times: 1,
    type: '',
    description: '',
    serialNumber: '',
    warrantyTo: '',
    bookingAllowed: true,
    children: [],
    status: '',
    parent: null,
    file: null,
  });
  const [addError, setAddError] = useState<ResponseErrors[]>([]);
  const [addProperty, { isLoading }] = useAddPropertyMutation();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('1');
  const navigate = useNavigate();

  const handleAddProperty = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    try {
      const addedProperty = await addProperty(
        formatFormData(property, FormTypes.add),
      ).unwrap();
      setIsSuccess(true);
      navigate(`/properties/${addedProperty.id}`);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
        setAddError((error as CustomError).data.violations);
      }
      setIsError(true);
    }
  };

  return (
    <Grid item xs={12} md={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('properties.add_new')}</NavBarTitle>
        <Button
          color="secondary"
          onClick={() => navigate('/properties')}
          variant="contained"
        >
          {t('button.back_to_list')}
        </Button>
      </NavBarContainer>
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={(event, newValue) => setTabValue(newValue)}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={t('tab.overview')} value="1" />
          <Tab disabled label={t('tab.children')} value="2" />
        </StyledTabList>
        <TabPanel value="1">
          <PropertyForm
            property={property}
            handleSubmit={handleAddProperty}
            handleChange={(key, value) =>
              setProperty({ ...property, [key]: value })
            }
            error={addError}
            isLoading={isLoading}
            formType={FormTypes.add}
          />
        </TabPanel>
        <TabPanel value="2">
          <PropertyChildren property={property} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}

export default AddProperty;
