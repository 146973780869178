import React from 'react';
import { Stack } from '@mui/material';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import { SelectOption } from 'common/ControlSelect/interfaces';
import { FieldErrors, Control, FieldValues, Path } from 'react-hook-form';

interface LocationInputsProps<T extends FieldValues> {
  errors: FieldErrors<T>;
  control: Control<T, any, T>;
  countryNameOptions: SelectOption[];
}

export default function LocationInputs<T extends FieldValues>({
  control,
  errors,
  countryNameOptions,
}: LocationInputsProps<T>) {
  return (
    <>
      <Stack spacing={2} direction="row">
        <ControlTextField
          control={control}
          errors={errors}
          label="label.city"
          name={'addressCity' as Path<T>}
        />
        <ControlTextField
          control={control}
          errors={errors}
          label="label.post_code"
          name={'addressPostCode' as Path<T>}
        />
      </Stack>
      <Stack spacing={2} direction="row">
        <ControlTextField
          control={control}
          errors={errors}
          label="label.street"
          name={'addressStreetLine' as Path<T>}
        />
        <ControlSelect
          control={control}
          errors={errors}
          label="clients.address_country"
          name={'addressCountry' as Path<T>}
          selectOptions={countryNameOptions}
        />
      </Stack>
    </>
  );
}
