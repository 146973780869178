import { connect } from 'react-redux';
import { setErrorStatus } from 'store/status/actions';
import { withAuth } from 'hoc/Auth/Auth';
import Component, { LoginFormProps } from './LoginForm';

const LoginForm = withAuth<LoginFormProps>(Component);

const mapDispatchToProps = (dispatch: any) => ({
  setIsError: (status: boolean) => dispatch(setErrorStatus(status)),
});

export default connect(null, mapDispatchToProps)(LoginForm);
