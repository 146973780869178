import { styled, TableCell, TableCellProps } from '@mui/material';

const StyledCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `0.2rem dashed ${theme.palette.divider}`,

  '&:first-of-type': {
    borderLeft: '0.2rem dashed',
    borderTopLeft: '0.2rem dashed',
    borderBottomLeft: '0.2rem dashed',
    borderColor: theme.palette.divider,
  },
  '&:last-of-type': {
    borderRight: '0.2rem dashed',
    borderTopRight: '0.2rem dashed',
    borderBottomRight: '0.2rem dashed',
    borderColor: theme.palette.divider,
  },
}));

export default StyledCell;
