import replaceApiIri from 'helpers/replaceApiIri';
import {
  ResourceRequestCandidateFilters,
  ResourceRequestCandidate,
} from './interfaces';

function candidateMatches(
  candidate: string[] | string | undefined,
  createdResourceRequestCandidate: ResourceRequestCandidate,
) {
  if (candidate && Array.isArray(candidate)) {
    return candidate.includes(createdResourceRequestCandidate.candidate.id);
  }

  if (candidate && !Array.isArray(candidate)) {
    return candidate === createdResourceRequestCandidate.candidate.id;
  }

  return true;
}

function resourceRequestMatches(
  resourceRequest: string[] | string | undefined,
  createdResourceRequestCandidate: ResourceRequestCandidate,
) {
  if (resourceRequest && Array.isArray(resourceRequest)) {
    return resourceRequest.includes(
      replaceApiIri(createdResourceRequestCandidate.resourceRequest, 'resource-requests'),
    );
  }

  if (resourceRequest && !Array.isArray(resourceRequest)) {
    return resourceRequest === replaceApiIri(createdResourceRequestCandidate.resourceRequest, 'resource-requests');
  }

  return true;
}

function shouldModifyResourceRequestCandidatesCache(
  originalArgs: Partial<ResourceRequestCandidateFilters>,
  createdResourceRequestCandidate: ResourceRequestCandidate,
) {
  const isCandidateMatches = candidateMatches(
    originalArgs.candidate,
    createdResourceRequestCandidate,
  );
  const isResourceRequestMatches = resourceRequestMatches(originalArgs.resourceRequest, createdResourceRequestCandidate);

  return isCandidateMatches && isResourceRequestMatches;
}

export default shouldModifyResourceRequestCandidatesCache;
