import React from 'react';
import UnauthorizedTemplate from 'common/UnauthorizedTemplate/UnauthorizedTemplate';
import RegistrationForm from 'components/RegistrationForm';

export interface Errors {
  organization: boolean;
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  password: boolean;
}

export interface PageRegisterProps {
  isDemo: boolean;
}

export default function Registration({ isDemo }: PageRegisterProps) {
  return (
    <UnauthorizedTemplate
      headerTextKey="button.sign_up"
      headerLinkKey="label.back_to_login"
      headerLinkTo="/login"
      form={<RegistrationForm isDemo={isDemo} />}
    />
  );
}
