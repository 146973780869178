import { HolidaysFilters, Holiday } from './interfaces';

function employeeMatches(
  employee: string[] | string | undefined,
  createdHoliday: Holiday,
) {
  if (employee && Array.isArray(employee)) {
    return employee.includes(createdHoliday.employee);
  }

  if (employee && !Array.isArray(employee)) {
    return employee === createdHoliday.employee;
  }

  return true;
}

function typeMatches(type: string[] | undefined, createdHoliday: Holiday) {
  return !type || type.includes(createdHoliday.type);
}

function shouldModifyHolidayCache(
  originalArgs: Partial<HolidaysFilters>,
  createdHoliday: Holiday,
) {
  const isEmployeeMatches = employeeMatches(
    originalArgs.employee,
    createdHoliday,
  );
  const isTypeMatches = typeMatches(originalArgs.type, createdHoliday);

  return isEmployeeMatches && isTypeMatches;
}

export default shouldModifyHolidayCache;
