import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  { label: 'holidays.employee', align: 'left' },
  { label: 'holidays.type_reason', align: 'left' },
  { label: 'holidays.requested_period', align: 'center' },
  { label: 'holidays.period', align: 'left' },
  { label: 'holidays.requested_at', align: 'left' },
];

export default headers;
