import { t } from 'i18next';
import holidaysSettings from 'helpers/holidaysSettings';

export default function getEmployeeHolidayTimeLeft(holidayHours: number) {
  const isHolidaysWholeDays =
    holidayHours % holidaysSettings.workingHoursInDay === 0;

  const isHolidaysOnlyHours = Math.abs(holidayHours) < holidaysSettings.workingHoursInDay;

  if (isHolidaysWholeDays) {
    return t('holidays.me_title_days', {
      days: holidayHours / holidaysSettings.workingHoursInDay,
    });
  }

  if (isHolidaysOnlyHours) {
    return t('holidays.me_title_hours', {
      hours: holidayHours,
    });
  }

  return t('holidays.me_title_days_hours', {
    days: Math.floor(holidayHours / holidaysSettings.workingHoursInDay),
    hours: holidayHours % holidaysSettings.workingHoursInDay,
  });
}
