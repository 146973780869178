import React, { useState } from 'react';
import {
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Invoice } from 'store/incomes/interfaces';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { useDeleteInvoiceMutation } from 'store/incomes/incomes';
import { useAppDispatch } from 'store/hooks';
import { setErrorCatch, setSuccessStatus } from 'store/status/actions';
import getMenuItems from './menuItems';

interface MenuProps {
  invoice: Invoice;
}

export default function Menu({ invoice }: MenuProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const menuItems = getMenuItems(navigate, invoice, setIsDialogOpen);
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const dispatch = useAppDispatch();

  const handleDeleteInvoice = async () => {
    try {
      await deleteInvoice(invoice.id);
      navigate('/invoices');
      dispatch(setSuccessStatus(true));
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <>
      <Grid
        item
        xs={2}
        display="flex"
        flexDirection="column"
        borderRight="0.1rem solid"
        borderColor="secondary.light"
        height="calc(100vh - 8rem)"
      >
        <MenuList>
          {menuItems.map((item) => (
            <MenuItem
              key={item.label}
              sx={{ py: 2, pl: 5 }}
              onClick={item.clickHandler}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText disableTypography>
                <Typography color="text.primary" variant="menuItemDefault">
                  {t(item.label)}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Grid>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        handleAccept={handleDeleteInvoice}
      />
    </>
  );
}
