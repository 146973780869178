enum ConditionSections {
  taxes = 'taxes',
  requiredCosts = 'required-costs',
  internalProjects = 'internal-projects',
  necessaryCosts = 'necessary-costs',
  nonBillableProjects = 'non-billable-projects',
  slightCosts = 'slight-costs',
  missingWorkingHours = 'missing-working-hours',
}

export default ConditionSections;
