const entities = [
  {
    id: 0,
    value: 'employee',
    description: 'documents.entity_employees',
  },
  {
    id: 1,
    value: 'contractor',
    description: 'documents.entity_contractors',
  },
  {
    id: 2,
    value: 'client',
    description: 'documents.entity_clients',
  },
];

export default entities;
