import React, { useState } from 'react';
import {
  Button,
  Dialog,
  Grid,
  FormControlLabel,
  Checkbox,
  Pagination,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import {
  TransactionAttachmentsData,
  useGetTransactionAttachmentsQuery,
} from 'store/transactions/transactions';
import Error from 'common/Error';
import Loader from 'common/Loader';
import AttachmentsList from 'components/AttachmentsList/AttachmentsList';
import AddAttachmentForm from 'components/AddAttachmentForm';
import { useGetCostsQuery } from 'store/costs/costs';
import getPagesCount from 'helpers/getPagesCount';

function TransactionAttachments() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  const existsTransactionQuery = searchParams.get('exists[transaction]');
  const pageQuery = searchParams.get('page');
  const defaultPage = 1;

  const {
    data: attachments = {} as TransactionAttachmentsData,
    isLoading,
    isError,
    isSuccess,
  } = useGetTransactionAttachmentsQuery({
    page: pageQuery ? +pageQuery : defaultPage,
    ...(existsTransactionQuery && {
      'exists[transaction]': JSON.parse(existsTransactionQuery),
    }),
  });
  const {
    isLoading: costsFetching,
    isError: costsFetchError,
    isSuccess: costsFetched,
  } = useGetCostsQuery({
    properties: ['id', 'name'],
  });

  const toggleSearchAwaitedAttachments = () => {
    if (existsTransactionQuery) {
      return setSearchParams(
        (prevSearchParams) => {
          prevSearchParams.delete('exists[transaction]');
          prevSearchParams.delete('page');

          return prevSearchParams;
        },
        { replace: true },
      );
    }

    return setSearchParams(
      (prevSearchParams) => {
        prevSearchParams.set('exists[transaction]', String(false));
        prevSearchParams.delete('page');

        return prevSearchParams;
      },
      { replace: true },
    );
  };

  const handleOnPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...(value > defaultPage && { page: String(value) }),
    }));

    window.scroll({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Grid item xs={10} bgcolor="background.list">
        <NavBarContainer>
          <NavBarTitle dataTestId="transaction-attachments-title">
            {t('transactions.attachments')}
          </NavBarTitle>
          <Button
            endIcon={<AddCircleOutlineIcon />}
            onClick={() => setIsAddFormOpen(true)}
            variant="contained"
          >
            {t('button.create')}
          </Button>
        </NavBarContainer>
        <FormControlLabel
          sx={{ ml: 2 }}
          value={!!existsTransactionQuery}
          checked={!!existsTransactionQuery}
          control={<Checkbox onChange={toggleSearchAwaitedAttachments} />}
          label={t('transactions.awaiting_attachments')}
        />
        {(isLoading || costsFetching) && <Loader />}
        {(isError || costsFetchError) && <Error />}
        {isSuccess && costsFetched && (
          <AttachmentsList attachments={attachments['hydra:member']} />
        )}
        {attachments?.['hydra:view']?.['hydra:last'] && (
          <Pagination
            color="primary"
            count={getPagesCount(attachments['hydra:view']['hydra:last'])}
            page={pageQuery ? +pageQuery : defaultPage}
            onChange={handleOnPageChange}
            sx={{ display: 'flex', justifyContent: 'center', py: 2 }}
          />
        )}
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setIsAddFormOpen(false)}
        open={isAddFormOpen}
      >
        <AddAttachmentForm setIsOpen={setIsAddFormOpen} />
      </Dialog>
    </>
  );
}

export default TransactionAttachments;
