import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import IntegerMaskInput from 'common/MaskInputs/IntegerMaskInput';

interface PropertiesConfigProps {
  onSubmit: (value: string) => Promise<void>;
  isSubmitting: boolean;
  initialDaysLimit: string;
}

export default function PropertiesConfig({
  onSubmit,
  isSubmitting,
  initialDaysLimit,
}: PropertiesConfigProps) {
  const [daysLimit, setDaysLimit] = useState({
    current: initialDaysLimit,
    previous: initialDaysLimit,
  });

  const isChanged = daysLimit.current !== daysLimit.previous;
  const { t } = useTranslation();

  const handleOnSubmit = async () => {
    await onSubmit(daysLimit.current);
    setDaysLimit((prev) => ({ ...prev, previous: daysLimit.current }));
  };

  return (
    <Stack spacing={2} p={3} direction="row" alignItems="center">
      <TextField
        label={t('properties.booking_days_limit')}
        sx={{ width: '32rem' }}
        value={daysLimit.current}
        onChange={(e) =>
          setDaysLimit((prev) => ({ ...prev, current: e.target.value }))
        }
        InputProps={{
          inputComponent: IntegerMaskInput as any,
        }}
      />
      {isChanged && (
        <LoadingButton
          loading={isSubmitting}
          endIcon={<CheckIcon />}
          onClick={handleOnSubmit}
          variant="contained"
        >
          {t('button.save')}
        </LoadingButton>
      )}
    </Stack>
  );
}
