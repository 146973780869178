import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stepper, Step, StepLabel } from '@mui/material';
import StyledStepperWrapper from './StepperWrapper.styled';

const steps = [
  'human_resources.position_information',
  'human_resources.candidate_requirements',
  'human_resources.budget_and_allocation',
];

interface ResourceRequestWizardStepperProps {
  activeStep: number;
  setActiveStep: (value: number) => void;
  isResourceRequestCreated: boolean;
}

export default function ResourceRequestWizardStepper({
  activeStep,
  setActiveStep,
  isResourceRequestCreated,
}: ResourceRequestWizardStepperProps) {
  const { t } = useTranslation();

  const handleOnClick = (index: number) => {
    if (!isResourceRequestCreated) {
      return;
    }
    setActiveStep(index);
  };

  return (
    <Grid item xs={3}>
      <StyledStepperWrapper>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, stepIndex) => (
            <Step key={step}>
              <StepLabel onClick={() => handleOnClick(stepIndex)}>
                {t(step)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </StyledStepperWrapper>
    </Grid>
  );
}
