import React, { useEffect } from 'react';
import _ from 'lodash';
import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import {
  useGetCompanyWorkingDaysQuery,
  useUpdateCompanyWorkingDaysMutation,
} from 'store/config/config';
import { ConfigResponse } from 'store/config/interfaces';
import { CustomError } from 'store/api';
import Error from 'common/Error';
import { CompanyWorkingDaysProps } from './interfaces';
import workingDays from './workingDays';

function CompanyWorkingDays({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: CompanyWorkingDaysProps) {
  const { t } = useTranslation();

  const {
    data: companyWorkingDays = {} as ConfigResponse<number[]>,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
  } = useGetCompanyWorkingDaysQuery();
  const [updateCompanyWorkingDays, { isLoading: isMutationLoading }] = useUpdateCompanyWorkingDaysMutation();

  const {
    selected: selectedWorkingDays,
    setSelected: setSelectedWorkingDays,
    isSelected: isWorkingDaySelected,
    handleChangeByValue: handleChangeWorkingDaysByValue,
  } = useMultipleCheckboxSelect<number>([] as number[]);
  const isWorkingDaysChanged = !_.isEqual(companyWorkingDays.value, _.sortBy(selectedWorkingDays));

  const handleUpdateCompanyWorkingDays = async () => {
    try {
      const sortedSelectedWorkingDays = _.sortBy(selectedWorkingDays);
      await updateCompanyWorkingDays({
        value: JSON.stringify(sortedSelectedWorkingDays)
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const getWorkingDays = (selectedTypesIds: number[]) =>
    workingDays
      .filter(({ value }) => selectedTypesIds.includes(value))
      .map(({ abbreviation }) => t(abbreviation))
      .join(', ');

  useEffect(() => {
    if (isQuerySuccess) {
      setSelectedWorkingDays(companyWorkingDays.value);
    }
  }, [companyWorkingDays, isQuerySuccess, setSelectedWorkingDays]);


  if (isQueryError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.company_working_days')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="working-days-select">{t('label.working_days')}</InputLabel>
              <Select
                label={t('label.working_days')}
                labelId="working-days-select"
                name="workingDaysSelect"
                multiple
                renderValue={(selected) => getWorkingDays(selected)}
                value={selectedWorkingDays}
              >
                {workingDays.map(({ value, description }) => (
                  <MenuItem key={value} value={value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={value}
                          onChange={(event) => handleChangeWorkingDaysByValue(+event.target.value)}
                          checked={isWorkingDaySelected(value)}
                        />
                      }
                      label={t(description)}
                      sx={{ width: '100%' }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {isWorkingDaysChanged && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isMutationLoading}
                onClick={handleUpdateCompanyWorkingDays}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default CompanyWorkingDays;
