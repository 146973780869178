import React, { useState } from 'react';
import { Box, Dialog, Grid, Tab, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import EmployeeHolidaysList from 'components/EmployeeHolidaysList/EmployeeHolidaysList';
import EmployeeHolidayForm from 'components/EmployeeHolidayForm/EmployeeHolidayForm';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import {
  useGetHolidayRequestsQuery,
  useGetHolidaysQuery,
} from 'store/holidays/holidays';
import { LoggedEmployee } from 'store/employee/interfaces';
import { useSearchParams } from 'react-router-dom';
import { HolidayTypes } from 'enums';
import AddButton from 'common/AddButton/AddButton';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import usePagination from 'hooks/usePagination';
import CustomPagination from 'common/CustomPagination/CustomPagination';
import emptyApiResponse from 'store/emptyApiResponse';
import Filters from 'components/EmployeeHolidaysList/Filters/Filters';
import getEmployeeHolidayTimeLeft from './getEmployeeHolidayTimeLeft';
import TabValue from './interfaces';

const itemsPerPage = 25;

export default function EmployeesHolidays() {
  const [tabValue, setTabValue] = useState(TabValue.leaveRequests);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});
  const { page, handleOnPageChange, handleOnItemsPerPageChange } =
    usePagination({
      searchParams,
      setSearchParams,
      defaultItemsPerPage: itemsPerPage,
    });
  const { data: currentUser = {} as LoggedEmployee, isLoading: userFetching } =
    useGetLoggedEmployeeQuery();
  const {
    data: holidayRequests = [],
    isFetching: holidayRequestsFetching,
    isError: holidayRequestsFetchError,
  } = useGetHolidayRequestsQuery(
    {
      employee: [currentUser.id],
    },
    {
      skip: !currentUser.id,
    },
  );
  const {
    data: holidays = {
      'hydra:member': [],
      ...emptyApiResponse,
    },
    isFetching: holidaysFetching,
    isError: holidaysFetchError,
  } = useGetHolidaysQuery(
    {
      employee: String(currentUser.id),
      ...(searchParams.get('type[]') && {
        type: searchParams.getAll('type[]'),
      }),
      ...(searchParams.get('dateFrom[after]') && {
        'dateFrom[after]': searchParams.get('dateFrom[after]') ?? '',
      }),
      ...(searchParams.get('dateTo[before]') && {
        'dateTo[before]': searchParams.get('dateTo[before]') ?? '',
      }),
      page,
      itemsPerPage,
    },
    { skip: !currentUser.id },
  );

  return (
    <>
      <Grid item xs={10} bgcolor="background.list">
        <NavBarContainer>
          <Box display="flex" alignItems="center" gap={2}>
            <NavBarTitle dataTestId="my-days-off_title">
              {t('holidays.my_days_off')}
            </NavBarTitle>
            <Typography
              color="primary"
              onClick={() =>
                setSearchParams({
                  'type[]': [HolidayTypes.onDemand, HolidayTypes.vacations],
                })
              }
              sx={{ cursor: 'pointer' }}
              variant="body1"
            >
              {getEmployeeHolidayTimeLeft(currentUser.holidayHoursLeft)}
            </Typography>
          </Box>
          <AddButton
            onClick={() => setIsDialogOpen(!isDialogOpen)}
            label="holidays.new_leave_request"
          />
        </NavBarContainer>
        <TabContext value={tabValue}>
          <StyledTabList
            onChange={(event: React.SyntheticEvent, newValue: TabValue) => {
              setTabValue(newValue);
            }}
            TabIndicatorProps={{ children: <span /> }}
          >
            <Tab
              label={t('holidays_leave_requests')}
              value={TabValue.leaveRequests}
            />
          </StyledTabList>
          <TabPanel value={TabValue.leaveRequests}>
            <Stack
              justifyContent="space-between"
              minHeight="calc(100vh - 19.3rem)"
            >
              <Box>
                <Filters
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
                <EmployeeHolidaysList
                  holidays={holidays['hydra:member']}
                  holidayRequests={holidayRequests}
                  dataFetchError={
                    holidaysFetchError ||
                    holidayRequestsFetchError ||
                    userFetching
                  }
                  dataFetching={holidaysFetching || holidayRequestsFetching}
                />
              </Box>
              {holidays['hydra:view']?.['hydra:last'] && (
                <CustomPagination
                  apiData={holidays}
                  itemsPerPage={itemsPerPage}
                  page={page}
                  handleOnPageChange={handleOnPageChange}
                  handleOnItemsPerPageChange={handleOnItemsPerPageChange}
                  showRowsSelect={false}
                />
              )}
            </Stack>
          </TabPanel>
        </TabContext>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
      >
        <EmployeeHolidayForm setIsDialogOpen={setIsDialogOpen} />
      </Dialog>
    </>
  );
}
