import React from 'react';
import { Stack, Typography } from '@mui/material';
import useResourceRequestContext from 'pages/HumanResourceResource/context/useResourceRequestContext';
import TagTile from 'components/TagTile/TagTile';
import { TagGroup } from 'store/tagGroups/interfaces';

interface RequirementTagGroupProps {
  tagGroup: TagGroup;
}

export default function RequirementTagGroup({
  tagGroup,
}: RequirementTagGroupProps) {
  const { resourceRequest } = useResourceRequestContext();

  const resourceRequestTagsOfTagGroup =
    resourceRequest?.tags?.filter(
      (tag) => tag.tagDefinition.tagGroup === tagGroup['@id'],
    ) || [];

  return (
    <Stack spacing={6} p={3}>
      <Typography variant="subtitle1" fontWeight={700}>
        {tagGroup.name}
      </Typography>
      <Stack direction="row" gap={3} flexWrap="wrap">
        {resourceRequestTagsOfTagGroup.map((tag) => (
          <TagTile key={tag.id} tag={tag} />
        ))}
      </Stack>
    </Stack>
  );
}
