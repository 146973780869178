import { ResponseErrors } from 'store/api';
import { Property } from 'store/properties/interfaces';
import { FormTypes } from 'enums';
import { BookingType } from './enums';

export function convertErrorArrayToMap(array: ResponseErrors[]) {
  return array.reduce((map, { propertyPath, message }) => {
    map.set(propertyPath, message);
    return map;
  }, new Map());
}

export function formatFormData(data: Property, formType: FormTypes) {
  const { id, ...dataWithoutId } = data;
  const isAddForm = formType === FormTypes.add;
  const payload = isAddForm ? dataWithoutId : data;

  return {
    ...payload,
    times:
      data.bookingType !== BookingType.permanently ? Number(data.times) : null,
    warrantyTo: data.warrantyTo || null,
  };
}
