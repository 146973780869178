const hiddenMenuPages = [
  '/human-resources/resources/add',
  '/human-resources/resources/*/edit',
  '/budget/*/planner',
  '/budget/*/period',
  'invoices/*/edit',
  'invoices/add',
  'invoices/*/preview',
  '/subscription',
];

export default function checkIsHiddenMenuPage(pathname: string) {
  return hiddenMenuPages.some((page) => {
    const pageRegex = new RegExp(page.replace(/\*/g, '.*'));

    return pageRegex.test(pathname);
  });
}
