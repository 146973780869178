import React from 'react';
import { Box, FormControl } from '@mui/material';
import { useUpdateCostMutation } from 'store/costs/costs';
import { CustomError } from 'store/api';
import {
  useAddBudgetContractorMutation,
  useRemoveBudgetContractorsMutation,
} from 'store/budget/budget';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import { costsStatuses } from 'components/EditCostForm/OverviewForm/constants';
import BaseData from './BaseData/BaseData';
import Statuses from './Statuses/Statuses';
import Details from './Details/Details';

import schema from './OverviewForm.schema';
import { FormPayload, OverviewProps } from './interfaces';
import getSelectOptions from './getSelectOptions';

const validate = zodResolver(schema);

function OverviewForm({
  setIsSuccess,
  setIsErrorCatch,
  budgetPreviews,
  budgetContractors,
  employees,
  cost,
  costGroups,
}: OverviewProps) {
  const assignedBudgetContractor = budgetContractors.find(
    (budgetContractor) => budgetContractor.contractor === cost['@id'],
  );

  const initValues = {
    name: cost.name,
    costGroup: cost.costGroup['@id'],
    status: cost.status,
    tin: cost?.tin ?? '',
    matchRegex: cost.matchRegex ?? '',
    description: cost.description ?? '',
    ownerEmployee: cost.ownerEmployee ?? '',
    budget: assignedBudgetContractor?.budget ?? '',
    notIncludeInChart: cost.notIncludeInChart ?? false,
    fixedAmount: cost.fixedAmount ?? false,
    requireAttachment: cost.requireAttachment ?? false,
    cyclic: cost.cyclic ?? false,
    taxAmount: cost.taxAmount ?? '',
    paymentDeadline: cost.paymentDeadline
      ? cost.paymentDeadline.toString()
      : '',
  };

  const { costBudgetOptions, costEmployeeOptions, costGroupsOptions } =
    getSelectOptions({
      budgetPreviews,
      employees,
      costGroups,
    });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initValues,
  });

  const { name, costGroup, status } = watch();

  const [editCost, { isLoading: isUpdateCostLoading }] =
    useUpdateCostMutation();
  const [addContractor, { isLoading: isContractorLoading }] =
    useAddBudgetContractorMutation();
  const [removeContractors, { isLoading: isRemoveBudgetContractorsLoading }] =
    useRemoveBudgetContractorsMutation();

  const isFormValuesChanged = !!Object.keys(dirtyFields).length;
  const disableSubmission = () =>
    Object.values(errors).some((value) => value) ||
    !name ||
    !status ||
    !costGroup;

  const handleUpdateCost = async (data: FormPayload) => {
    const {
      budget,
      taxAmount,
      paymentDeadline,
      ownerEmployee,
      ...restPayload
    } = data;
    try {
      const isCostChanged = (
        Object.keys(dirtyFields) as (keyof typeof dirtyFields)[]
      ).some((fieldKey) => fieldKey !== 'budget' && !!dirtyFields[fieldKey]);

      if (isCostChanged) {
        await editCost({
          id: cost.id,
          taxAmount: taxAmount ? +taxAmount : null,
          paymentDeadline: paymentDeadline ? +paymentDeadline : null,
          ownerEmployee: ownerEmployee || null,
          ...restPayload,
        }).unwrap();
      }

      const isBudgetChanged = !!dirtyFields.budget;

      if (!isBudgetChanged) {
        setIsSuccess(true);
        reset(data);
        return;
      }

      if (budget) {
        const addedBudgetContractor = {
          budget,
          contractor: cost['@id'],
        };
        await addContractor(addedBudgetContractor).unwrap();
      } else if (assignedBudgetContractor) {
        await removeContractors({
          id: assignedBudgetContractor.id,
        });
      }
      setIsSuccess(true);
      reset(data);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  return (
    <Box
      bgcolor="main.white"
      borderRadius="1.6rem"
      display="flex"
      flexDirection="column"
      gap={3}
      flexWrap="wrap"
      p={3}
    >
      <form onSubmit={handleSubmit(handleUpdateCost)}>
        <FormControl fullWidth sx={{ rowGap: 2 }}>
          <BaseData errors={errors} control={control}/>
          <ControlTextField
            errors={errors}
            control={control}
            name="matchRegex"
            label="costs.match_regex_label"
          />
          <Statuses
            errors={errors}
            control={control}
            costsStatuses={costsStatuses}
          />
          <Details
            errors={errors}
            control={control}
            costEmployeeOptions={costEmployeeOptions}
            costGroupsOptions={costGroupsOptions}
            costBudgetOptions={costBudgetOptions}
          />
          <ControlTextField
            control={control}
            label="label.description"
            errors={errors}
            name="description"
            multiline
            minRows={3}
          />
        </FormControl>

        <Box display="flex" justifyContent="flex-end" mt={2}>
          {isFormValuesChanged && (
            <SubmitButton
              disabled={disableSubmission()}
              isLoading={
                isUpdateCostLoading ||
                isContractorLoading ||
                isRemoveBudgetContractorsLoading
              }
            />
          )}
        </Box>
      </form>
    </Box>
  );
}

export default OverviewForm;
