import React, { useEffect } from 'react';
import { Box, DialogContent, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import hoursOptions from 'components/EmployeeHolidayForm/hoursOptions';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import ControlDateRange from 'common/ControlDateRange/ControlDateRange';
import useWorkingDaysBetweenRange from 'hooks/useWorkingDaysBetweenRange';
import { getHolidayIcon } from 'helpers/getHolidayData';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import { FormTypes } from 'enums';
import useHolidayTypes from 'hooks/useHolidayTypes';
import holidaysSettings from 'helpers/holidaysSettings';
import schema from './HolidayForm.schema';
import { FormPayload } from './interfaces';
import defaultValues from './defaultValues';

const validate = zodResolver(schema);

export interface HolidayFormProps {
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (data: FormPayload) => void;
  isSubmitting: boolean;
  formType: FormTypes;
  initialValues?: FormPayload;
}

export default function HolidayForm({
  setIsDialogOpen,
  onSubmit,
  isSubmitting,
  formType,
  initialValues = defaultValues,
}: HolidayFormProps) {
  const {
    activeHolidayTypes,
    holidayTypesLoading,
    holidayTypesError,
    holidayTypesSuccess,
  } = useHolidayTypes();

  const {
    data: employees = [],
    isError: employeesError,
    isLoading: employeesLoading,
  } = useGetEmployeesPreview({ isActive: true });

  const employeesOptions = employees.map((employee) => ({
    label: `${employee.firstname} ${employee.lastname}`,
    value: employee['@id'],
  }));

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const { getWorkingDaysCount } = useWorkingDaysBetweenRange();
  const { t } = useTranslation();
  const { type, description, dateFrom, dateTo, employee } = watch();

  const workingDaysDifferenceInDates = getWorkingDaysCount(dateFrom, dateTo);
  const workingHoursDifferenceInDates =
    workingDaysDifferenceInDates * holidaysSettings.workingHoursInDay;

  const isDisableSubmission =
    !!Object.keys(errors).length ||
    !type ||
    !description ||
    !dateFrom ||
    !dateTo ||
    !employee ||
    workingDaysDifferenceInDates < 1;

  const hoursOptionsToDisplay =
    workingHoursDifferenceInDates === holidaysSettings.workingHoursInDay
      ? hoursOptions
      : [
          {
            label: workingHoursDifferenceInDates.toString(),
            value: workingHoursDifferenceInDates.toString(),
          },
        ];

  useEffect(() => {
    if (workingHoursDifferenceInDates > holidaysSettings.workingHoursInDay) {
      setValue('hours', workingHoursDifferenceInDates.toString());
    } else if (
      workingHoursDifferenceInDates !== holidaysSettings.workingHoursInDay
    ) {
      setValue('hours', '');
    }
  }, [setValue, workingHoursDifferenceInDates]);

  return (
    <ContentSuspense
      isError={holidayTypesError || employeesError}
      isLoading={holidayTypesLoading || employeesLoading}
    >
      <DialogHeader
        title={t(`holidays.${formType}_holidays`)}
        setIsOpen={setIsDialogOpen}
      />
      {holidayTypesSuccess && (
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box display="flex" flexDirection="column" pt={4} gap={3}>
              <ControlSelect
                id="employee-select"
                control={control}
                name="employee"
                errors={errors}
                label="inputs.select_employee"
                selectOptions={employeesOptions}
                disabled={formType === FormTypes.edit}
                required
              />
              <ControlTextField
                control={control}
                errors={errors}
                name="description"
                label="label.description"
                required
              />
              <Stack direction="row" spacing={2}>
                <ControlSelect
                  control={control}
                  name="type"
                  errors={errors}
                  label="label.type"
                  required
                  renderOptions={activeHolidayTypes.map((holidayType) => (
                    <MenuItem key={holidayType.id} value={holidayType.id}>
                      <Stack direction="row" alignItems="center">
                        {getHolidayIcon(holidayType.id)}
                        <Typography ml={1} variant="body1">
                          {holidayType.name}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                />
                <ControlSelect
                  control={control}
                  name="hours"
                  errors={errors}
                  label="label.hours"
                  selectOptions={hoursOptionsToDisplay}
                  disabled={workingDaysDifferenceInDates !== 1}
                  optional
                />
              </Stack>
              <ControlDateRange control={control} getValues={getValues} />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <SubmitButton
                disabled={isDisableSubmission}
                isLoading={isSubmitting}
              />
            </Box>
          </form>
        </DialogContent>
      )}
    </ContentSuspense>
  );
}
