import { FormPayload } from 'components/SubscriptionClientForm/interfaces';

export enum SubscriptionStep {
  modules,
  client,
  payment,
}

export interface StorageSubscription {
  selectedFeatures?: string[];
  userCount?: number;
  step?: SubscriptionStep;
  clientForm?: FormPayload;
}
