import React from 'react';
import { Avatar, Box, TableCell, TableRow, Typography } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import dateFormat from 'dateformat';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useTranslation } from 'react-i18next';
import { ResultProps, CostType } from './interfaces';

const maxDescriptionLength = 30;

export default function Result({ transaction, employees }: ResultProps) {
  const formatAmount = useCurrencyFormat();
  const { t } = useTranslation();

  const shortenedDescription =
    transaction.description.length > maxDescriptionLength
      ? `${transaction.description.substring(0, maxDescriptionLength)}...`
      : transaction.description;

  const transactionEmployee = employees.find(
    (employee) => employee['@id'] === transaction.employee,
  );

  const formattedDate = dateFormat(transaction.execDate, 'dd.mm.yyyy, HH:MM');

  return (
    <TableRow key={transaction.id}>
      <TableCell>
        <Box display="flex" alignItems="center">
          <TitleTooltip
            title={t(`budget.budget_cost_${transaction.type}`)}
            placement="top"
          >
            <Box display="flex">
              {transaction.type === CostType.project && (
                <DesignServicesOutlinedIcon sx={{ marginRight: '0.5rem' }} />
              )}
              {transaction.type === CostType.contractor && (
                <PaymentOutlinedIcon sx={{ marginRight: '0.5rem' }} />
              )}
              {transaction.type === CostType.transaction && (
                <AccountBalanceWalletOutlinedIcon
                  sx={{ marginRight: '0.5rem' }}
                />
              )}
            </Box>
          </TitleTooltip>
          <TitleTooltip title={transaction.description} placement="top">
            <Typography variant="body2">{shortenedDescription}</Typography>
          </TitleTooltip>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{formattedDate}</Typography>
      </TableCell>
      <TableCell>
        {transaction.type === CostType.transaction ? (
          <TitleTooltip
            title={formatAmount(+transaction.amount, transaction.currency)}
          >
            <Typography variant="body2">
              {formatAmount(+transaction.targetAmount)}
            </Typography>
          </TitleTooltip>
        ) : (
          <Typography variant="body2">
            {formatAmount(+transaction.amount, transaction.currency)}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {transactionEmployee ? (
          <Box display="flex" alignItems="center" columnGap={2}>
            <Avatar
              src={transactionEmployee.avatarUrl}
              alt={transactionEmployee.firstname[0]}
            />
            <Typography variant="body2">
              {transactionEmployee.firstname} {transactionEmployee.lastname}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2">-</Typography>
        )}
      </TableCell>
    </TableRow>
  );
}
