const groupTypes = [
  {
    value: 'standard',
    label: 'contractor_groups.type_standard',
  },
  {
    value: 'income',
    label: 'contractor_groups.type_income',
  },
  {
    value: 'dividend',
    label: 'contractor_groups.type_dividend',
  },
  {
    value: 'internal',
    label: 'contractor_groups.type_internal',
  },
];

export default groupTypes;
