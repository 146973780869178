import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import getGrossWorth from 'components/InvoiceForm/Rows/getGrossWorth';
import getTotalVat from 'components/InvoiceForm/helpers/getTotalVat';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'store/incomes/interfaces';
import tableHeaders from './tableHeaders';

interface InvoiceRowsProps {
  invoice: Invoice;
}

export default function InvoiceRows({ invoice }: InvoiceRowsProps) {
  const { t } = useTranslation();
  const { incomeRows, amountNet, amount } = invoice;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography color="text.secondary" variant="tableHeader">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {incomeRows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.productName}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{row.unit}</TableCell>
              <TableCell align="right">{row.netPrice}</TableCell>
              <TableCell align="right">
                {(+row.netPrice * +row.quantity).toFixed(4)}
              </TableCell>
              <TableCell align="right">{row.vatRate}%</TableCell>
              <TableCell align="right">
                {getTotalVat({
                  netPrice: +row.netPrice,
                  quantity: +row.quantity,
                  vatRate: row.vatRate,
                })}
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" fontWeight={700}>
                  {getGrossWorth(+row.netPrice, +row.quantity, row.vatRate)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              colSpan={5}
              align="right"
              sx={{ bgcolor: 'background.other' }}
            >
              <Typography variant="body1">{amountNet.toFixed(4)}</Typography>
            </TableCell>
            <TableCell
              colSpan={2}
              align="right"
              sx={{ bgcolor: 'background.other' }}
            >
              <Typography variant="body1">
                {(amount - amountNet).toFixed(4)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={{ bgcolor: 'background.other' }}>
              <Typography fontWeight={700} variant="body1">
                {amount.toFixed(4)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
