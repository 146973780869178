import React from 'react';
import { WorkingHours } from 'store/workingHours/workingHours';
import { Box, Tooltip, Typography } from '@mui/material';
import ProjectData from 'components/CalendarDayTile/ProjectData/ProjectData';
import {
  Container,
  Item,
} from 'components/CalendarDayTile/LoggedHours/Box.styled';
import Details from 'components/CalendarDayTile/LoggedHours/Details/Details';
import { useTranslation } from 'react-i18next';

interface LoggedHoursProps {
  date: Date;
  dayHours: WorkingHours[];
  weekdayIndex: number;
  setIsEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEntryId: React.Dispatch<React.SetStateAction<number>>;
  userCanEdit: boolean;
}

function LoggedHours({
  dayHours,
  weekdayIndex,
  date,
  setIsEditFormOpen,
  setEntryId,
  userCanEdit,
}: LoggedHoursProps) {
  const { t } = useTranslation();

  if (!dayHours.length) {
    return null;
  }

  if (dayHours.length > 2) {
    const remainingLoggedAmount = dayHours.length - 1;
    const remainingHoursSum = dayHours
      .slice(1)
      .reduce((acc, { hours }) => acc + hours, 0);

    return (
      <Tooltip
        leaveDelay={300}
        placement={weekdayIndex > 3 ? 'left' : 'right'}
        title={
          <Details
            date={date}
            workingHours={dayHours}
            setIsEditFormOpen={setIsEditFormOpen}
            setEntryId={setEntryId}
            userCanEdit={userCanEdit}
          />
        }
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'main.white',
              border: '0.1rem solid',
              borderColor: 'border.main',
              borderRadius: '0.8rem',
              maxWidth: '35.6rem',
              maxHeight: '40rem',
              overflowY: 'auto',
            },
          },
        }}
      >
        <Container>
          <Item bgcolor="main.white">
            <Box display="flex" justifyContent="space-between">
              <ProjectData
                projectIri={dayHours[0].project}
                projectPhaseIri={dayHours[0].projectPhase}
              />
              <Typography variant="bold" color="secondary">
                {dayHours[0].hours}h
              </Typography>
            </Box>
            <Typography variant="body1">{dayHours[0].task}</Typography>
          </Item>
          <Item bgcolor="secondary.light">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="bold" color="secondary">
                + {remainingLoggedAmount} {t('project_tracking.more')}
              </Typography>
              <Typography variant="bold" color="secondary">
                {remainingHoursSum}h
              </Typography>
            </Box>
          </Item>
        </Container>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      leaveDelay={300}
      placement={weekdayIndex > 3 ? 'left' : 'right'}
      title={
        <Details
          date={date}
          workingHours={dayHours}
          setIsEditFormOpen={setIsEditFormOpen}
          setEntryId={setEntryId}
          userCanEdit={userCanEdit}
        />
      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'main.white',
            border: '0.1rem solid',
            borderColor: 'border.main',
            borderRadius: '0.8rem',
            maxWidth: '35.6rem',
            maxHeight: '40rem',
            overflowY: 'auto',
          },
        },
      }}
    >
      <Container>
        {dayHours.map((hours) => (
          <Item key={hours.id} bgcolor="main.white">
            <Box display="flex" justifyContent="space-between">
              <ProjectData
                projectIri={hours.project}
                projectPhaseIri={hours.projectPhase}
              />
              <Typography variant="bold" color="secondary">
                {hours.hours}h
              </Typography>
            </Box>
            <Typography variant="body1">{hours.task}</Typography>
          </Item>
        ))}
      </Container>
    </Tooltip>
  );
}

export default LoggedHours;
