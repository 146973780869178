export default function formatPhoneNumber(phoneNumber: string): string {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  const isPhoneNumberFormat = digitsOnly.length === 11;

  if (!isPhoneNumberFormat) {
    return phoneNumber;
  }

  return `+${digitsOnly.slice(0, 2)} ${digitsOnly.slice(
    2,
    5,
  )} ${digitsOnly.slice(5, 8)} ${digitsOnly.slice(8)}`;
}
