import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import { NavBarContainer, NavBarSubtitle, NavBarTitle } from 'common/NavBar';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import ResourceRequestWizard from 'components/ResourceRequestWizard';
import { HumanResourcesTabValue } from 'pages/HumanResources/HumanResources';

export default function ResourceRequestAdd() {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <NavBarContainer>
          <Stack spacing={1} direction="row" alignItems="center">
            <NavigationBackButton
              to="/human-resources"
              options={{
                state: {
                  tabValue: HumanResourcesTabValue.resources,
                },
              }}
            />
            <Stack>
              <NavBarTitle>{t('human_resources.new_recruitment')}</NavBarTitle>
              <NavBarSubtitle>
                {t('human_resources.resource_request')}
              </NavBarSubtitle>
            </Stack>
          </Stack>
        </NavBarContainer>
      </Grid>
      <ResourceRequestWizard />
    </>
  );
}
