import React from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetPipeDriveSummaryQuery } from 'store/pipeDriveLead/pipeDriveLead';
import { WidgetCaption, WidgetContainer, WidgetTitle } from 'common/Widget';
import dateFormat from 'dateformat';

export default function PipedriveWidget() {
  const { t } = useTranslation();
  const {
    data: summaries = [],
    isLoading: summariesLoading,
    isError: summariesError,
    isSuccess: summariesSuccess,
  } = useGetPipeDriveSummaryQuery();

  return (
    <WidgetContainer
      title={<WidgetTitle>{t('label.pipedrive_leads')}</WidgetTitle>}
      isError={summariesError}
      isLoading={summariesLoading}
    >
      {summariesSuccess && (
        <Grid container alignItems="center" rowSpacing={2} mt={1}>
          {summaries.map((summary) => (
            <React.Fragment key={summary.name}>
              <Grid item xs={5}>
                <WidgetCaption>{summary.name}</WidgetCaption>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {dateFormat(summary.createdAt, 'yyyy-mm-dd')}
                </Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="flex-end">
                <Chip
                  label={
                    <Typography variant="body2">
                      {summary.stage.name}
                    </Typography>
                  }
                  size="small"
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </WidgetContainer>
  );
}
