import React from 'react';
import { DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogHeaderProps {
  title: string;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

export default function DialogHeader({
  title,
  setIsOpen = () => {},
  onClose,
}: DialogHeaderProps) {
  const onClick = onClose || (() => setIsOpen(false));

  return (
    <DialogTitle
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <IconButton
        onClick={onClick}
        sx={{
          width: '3.2rem',
          height: '3.2rem',
          borderRadius: '50%',
        }}
      >
        <CloseIcon sx={{ fontSize: '1.6rem' }} />
      </IconButton>
    </DialogTitle>
  );
}
