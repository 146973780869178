import { useSelector } from 'react-redux';
import { RootState } from 'store';
import checkFeature from 'helpers/checkFeature';
import features from 'config/features/features';
import roles from 'config/roles/roles';
import useCheckRoles from './useCheckRoles';

const useCheckBudgetIsEnabled = () => {
  const organizationFeatures = useSelector(
    (state: RootState) => state.organization.organization.features,
  );
  const isBudgetFeatureEnabled = checkFeature(
    features.budget,
    organizationFeatures,
  );

  const isBudgetManager = useCheckRoles(roles.budgetManager);

  return isBudgetFeatureEnabled && isBudgetManager;
};

export default useCheckBudgetIsEnabled;
