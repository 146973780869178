import React, { useState } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import {
  Box,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useGetProjectsQuery } from 'store/projects/projects';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { useValidate } from 'hooks/useValidate';
import { useAddProjectRateMutation } from 'store/employeeProjectRate/employeeProjectRate';
import { Client } from 'store/clients/clients';
import getProjectWithClientCurrency from 'helpers/getProjectWithClientCurrency';
import { LoadingButton } from '@mui/lab';
import { CustomError } from 'store/api';
import useCheckFeature from 'hooks/useCheckFeature';
import features from 'config/features/features';
import { Project } from 'store/projects/interfaces';

export interface AddProjectRateFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeeIri: string;
  clients: Pick<Client, 'name' | '@id' | 'currency'>[];
}

interface Errors {
  rate: boolean;
  dateFrom: boolean;
}

function AddProjectRateForm({
  setIsOpen,
  setIsError,
  setErrorMessage,
  setIsSuccess,
  employeeIri,
  clients,
}: AddProjectRateFormProps) {
  const { t } = useTranslation();
  const [projectIri, setProjectIri] = useState('');
  const [rate, setRate] = useState('');
  const [defaultPosition, setDefaultPosition] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const {
    data: projects = {
      'hydra:member': [] as Project[],
    },
    isLoading: projectFetching,
    isError: projectFetchError,
    isSuccess: projectsFetched,
  } = useGetProjectsQuery({});
  const { validate, errors } = useValidate<Errors>();
  const [addProjectRate, { isLoading }] = useAddProjectRateMutation();
  const projectsWithClients = projects['hydra:member'].filter(
    (projectData) => projectData.client,
  );
  const isInvoiceFeatureActive = useCheckFeature(features.incomes);

  const disableSubmission = () => !projectIri || !rate || !dateFrom;

  const handleAddProjectRate = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await addProjectRate({
        project: projectIri,
        rate: +rate,
        dateFrom: new Date(dateFrom),
        dateTo: new Date(dateTo) || null,
        employee: employeeIri,
        position: defaultPosition || null,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        title={t('employee_project_rates.add_new')}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        {projectFetching && <Loader />}
        {projectFetchError && <Error />}
        {projectsFetched && (
          <form onSubmit={handleAddProjectRate}>
            <Box display="flex" flexDirection="column" gap={3} pt={3}>
              <FormControl fullWidth required>
                <InputLabel id="project-select">
                  {t('employee_project_rates.project')}
                </InputLabel>
                <Select
                  id="project-select"
                  label={t('employee_project_rates.project')}
                  name="projectSelect"
                  onChange={(event) => setProjectIri(event.target.value)}
                  value={projectIri}
                >
                  {projectsWithClients.map((projectData) => (
                    <MenuItem key={projectData.id} value={projectData['@id']}>
                      {getProjectWithClientCurrency(projectData, clients)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                error={errors.rate}
                fullWidth
                helperText={errors.rate && t('errors.field_required')}
                id="rate"
                label={t('employee_project_rates.rate')}
                name="rate"
                onBlur={(event) =>
                  validate('rate', event.target.value.length > 0)
                }
                onChange={(event) => setRate(event.target.value)}
                required
                type="number"
                value={rate}
              />
              {isInvoiceFeatureActive && (
                <TextField
                  fullWidth
                  id="defaultPosition"
                  label={t('label.default_position')}
                  name="defaultPosition"
                  onChange={(event) => setDefaultPosition(event.target.value)}
                  value={defaultPosition}
                />
              )}
              <Box display="flex" gap={3}>
                <TextField
                  error={errors.dateFrom}
                  fullWidth
                  helperText={errors.dateFrom && t('errors.field_required')}
                  id="date-from"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={t('label.date_from')}
                  name="dateFrom"
                  onBlur={(event) =>
                    validate('dateFrom', event.target.value.length > 0)
                  }
                  onChange={(event) => setDateFrom(event.target.value)}
                  required
                  type="date"
                  value={dateFrom}
                />
                <TextField
                  fullWidth
                  id="date-to"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: dateFrom,
                  }}
                  label={t('label.date_to')}
                  name="dateTo"
                  onChange={(event) => setDateTo(event.target.value)}
                  type="date"
                  value={dateTo}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  disabled={disableSubmission()}
                  endIcon={<AddCircleOutlineIcon />}
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  <span>{t('button.create')}</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </>
  );
}

export default AddProjectRateForm;
