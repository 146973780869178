import dateFormat from 'dateformat';
import { InvoiceClientProps } from './interfaces';

interface GetClientSettersProps
  extends Pick<
    InvoiceClientProps,
    'setDueDate' | 'setCurrencyIri' | 'setInternalComments' | 'clients'
  > {}

export default function getClientSetters({
  setCurrencyIri,
  setDueDate,
  setInternalComments,
  clients,
}: GetClientSettersProps) {
  const currentDate = new Date();

  const setSelectedClientCurrency = (selectedClientIri: string) => {
    const selectedClient = clients.find(
      (item) => item['@id'] === selectedClientIri,
    );
    if (selectedClient && selectedClient.currency) {
      setCurrencyIri(selectedClient.currency);
    }
  };

  const setSelectedClientDueDays = (selectedClientIri: string) => {
    const selectedClient = clients.find(
      (item) => item['@id'] === selectedClientIri,
    );
    if (selectedClient && selectedClient.dueDays) {
      const formattedDueDate = dateFormat(
        currentDate.setDate(currentDate.getDate() + +selectedClient.dueDays),
        'yyyy-mm-dd',
      );
      setDueDate(formattedDueDate);
    }
  };

  const setSelectedInvoiceComment = (selectedClientIri: string) => {
    if (!clients) {
      return;
    }

    const selectedClient = clients.find(
      (item) => item['@id'] === selectedClientIri,
    );

    if (selectedClient?.invoiceComment) {
      setInternalComments({
        current: selectedClient.invoiceComment,
        previous: selectedClient.invoiceComment,
      });
    }
  };

  return {
    setSelectedClientCurrency,
    setSelectedClientDueDays,
    setSelectedInvoiceComment,
  };
}
