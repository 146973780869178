import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogContent, Box } from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import IntegerMaskInput from 'common/MaskInputs/IntegerMaskInput';
import SubmitButton from 'common/SubmitButton/SubmitButton';
import { FormPayload, ProjectPhaseFormProps } from './interfaces';
import schema from './ProjectPhaseFrom.schema';
import defaultValues from './defaultValues';
import projectPhaseTypes from './projectPhaseTypes';

const validate = zodResolver(schema);

export default function ProjectPhaseForm({
  setIsOpen,
  formType,
  onSubmit,
  isSubmitting,
  initFormValues = defaultValues,
  budgets,
  isBudgetEnabled,
}: ProjectPhaseFormProps) {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initFormValues,
  });
  const { name, type, amount, dateFrom } = watch();
  const { t } = useTranslation();

  const handleOnSubmit = handleSubmit(onSubmit);

  const disableSubmission = () =>
    !name ||
    !dateFrom ||
    !amount ||
    !type ||
    Object.values(errors).some((error) => error) ||
    !Object.keys(dirtyFields).length;

  const projectBudgetOptions = budgets.map((budgetData) => ({
    value: budgetData['@id'],
    label: budgetData.name,
  }));

  return (
    <>
      <DialogHeader
        setIsOpen={setIsOpen}
        title={t(`projects.${formType}_project_phase`)}
      />
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleOnSubmit}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gap={3}
          pt={2}
        >
          <ControlTextField
            control={control}
            errors={errors}
            label="label.name"
            name="name"
            required
          />
          <ControlSelect
            control={control}
            errors={errors}
            selectOptions={projectPhaseTypes}
            name="type"
            label="label.type"
            required
          />
          <ControlTextField
            control={control}
            errors={errors}
            label="inputs.amount"
            name="amount"
            InputProps={{
              inputComponent: IntegerMaskInput as any,
            }}
            required
          />
          <ControlTextField
            control={control}
            errors={errors}
            label="label.date_from"
            name="dateFrom"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
          <ControlTextField
            control={control}
            errors={errors}
            label="label.date_to_prediction"
            name="dateToPrediction"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <ControlTextField
            control={control}
            errors={errors}
            label="label.date_to"
            name="dateTo"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {budgets && isBudgetEnabled && (
            <ControlSelect
              control={control}
              label="projects.budget_label"
              name="budget"
              errors={errors}
              selectOptions={projectBudgetOptions}
              optional
            />
          )}
          <SubmitButton
            disabled={disableSubmission()}
            isLoading={isSubmitting}
          />
        </Box>
      </DialogContent>
    </>
  );
}
