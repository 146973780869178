import { select } from 'redux-saga/effects';
import { getApiToken } from '../store/app/selectors';

const API_URL = process.env.REACT_APP_API_URL;

function* defaultHeaders() {
  const state = yield select();

  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getApiToken(state),
  };
}

export { API_URL, defaultHeaders };
