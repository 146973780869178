import { t } from 'i18next';
import { Holiday, HolidayRequest } from 'store/holidays/interfaces';
import { startOfDay } from 'date-fns';
import holidaysSettings from './holidaysSettings';
import fullDateFormat from './fullDateFormat';

const getHolidayHoursFreeTime = ({
  holiday,
  isOneDayTimeRange,
}: {
  holiday: Holiday | HolidayRequest;
  isOneDayTimeRange: boolean;
}) => {
  if (isOneDayTimeRange) {
    return t('holidays.hours_period', {
      hours: holiday.hours,
      dateFrom: fullDateFormat(new Date(holiday.dateFrom)),
    });
  }

  return t('holidays.hours_some_days', {
    hours: holiday.hours,
    dateFrom: fullDateFormat(new Date(holiday.dateFrom)),
    dateTo: fullDateFormat(new Date(holiday.dateTo)),
  });
};

const getHolidayFreeTime = (holiday: Holiday | HolidayRequest) => {
  const isHoursHoliday =
    holiday.hours && holiday.hours % holidaysSettings.workingHoursInDay !== 0;
  const isOneDayTimeRange =
    startOfDay(holiday.dateFrom).getTime() ===
    startOfDay(holiday.dateTo).getTime();

  if (isHoursHoliday) {
    return getHolidayHoursFreeTime({ holiday, isOneDayTimeRange });
  }

  if (isOneDayTimeRange) {
    return t('holidays.one_day_period', {
      date: fullDateFormat(new Date(holiday.dateFrom)),
    });
  }

  if (holiday.requestedDays === 1) {
    return t('holidays.day_period', {
      date: fullDateFormat(new Date(holiday.dateFrom)),
      dateFrom: fullDateFormat(new Date(holiday.dateFrom)),
      dateTo: fullDateFormat(new Date(holiday.dateTo)),
    });
  }

  return t('holidays.days_period', {
    days: holiday.requestedDays,
    dateFrom: fullDateFormat(new Date(holiday.dateFrom)),
    dateTo: fullDateFormat(new Date(holiday.dateTo)),
  });
};

export default getHolidayFreeTime;
