import React from 'react';
import { Client, useGetClientsQuery } from 'store/clients/clients';
import { Typography, TypographyProps } from '@mui/material';

export interface InvoiceClientProps {
  clientIri: string;
  textColor?: TypographyProps['color'];
}

const emptyClients = {} as Client;

export default function InvoiceClient({
  clientIri,
  textColor = 'text.secondary',
}: InvoiceClientProps) {
  const { client } = useGetClientsQuery(
    { properties: ['id', 'name'] },
    {
      selectFromResult: ({ data }) => ({
        client: data?.find((item) => item['@id'] === clientIri) ?? emptyClients,
      }),
    },
  );

  return (
    <Typography variant="body2" color={textColor}>
      {client?.name}
    </Typography>
  );
}
