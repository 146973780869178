import React, { useEffect, useState } from 'react';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import {
  Box,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useValidate } from 'hooks/useValidate';
import { useAddDocumentTypeMutation } from 'store/documentTypes/documentTypes';
import { StatusProps } from 'hoc/Status/Status';
import entities from 'components/AddDocumentTypeForm/entities';

export interface AddDocumentTypeFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Errors {
  documentName: boolean;
  entity: boolean;
}

function AddDocumentTypeForm({
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddDocumentTypeFormProps) {
  const { t } = useTranslation();
  const [documentName, setDocumentName] = useState('');
  const [entity, setEntity] = useState('');
  const [description, setDescription] = useState('');
  const { validate, errors } = useValidate<Errors>();
  const [addType, { isSuccess, isError, error: postError }] =
    useAddDocumentTypeMutation();

  const disableSubmission = () =>
    !documentName || !entity || Object.values(errors).some((error) => error);

  const handleAddDocumentType = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addType({
      name: documentName,
      relationName: entity,
      description,
    });
  };

  useEffect(() => {
    setIsSuccess(isSuccess);
    setIsError(isError);
    if (!isSuccess) {
      return;
    }
    setIsOpen(false);
  }, [setIsSuccess, isSuccess, setIsError, isError, setIsOpen]);

  useEffect(() => {
    if (!postError) {
      return;
    }
    if (!('data' in postError)) {
      return;
    }
    setErrorMessage(postError.data['hydra:description']);
  }, [postError, setErrorMessage]);

  return (
    <>
      <DialogHeader title={t('documents.add_new')} setIsOpen={setIsOpen} />
      <DialogContent>
        <form onSubmit={handleAddDocumentType}>
          <Box display="flex" flexDirection="column" gap={3} py={3}>
            <TextField
              error={errors.documentName}
              helperText={errors.documentName && t('errors.too_short_name')}
              id="document-name"
              fullWidth
              label={t('label.name')}
              name="documentName"
              onChange={(event) => setDocumentName(event.target.value)}
              onBlur={(event) =>
                validate('documentName', event.target.value.length >= 2)
              }
              required
              value={documentName}
            />
            <FormControl fullWidth required>
              <InputLabel id="entity-select">
                {t('documents.label_entity')}
              </InputLabel>
              <Select
                id="entity-select"
                label={t('documents.label_entity')}
                onChange={(event) => setEntity(event.target.value)}
                value={entity}
              >
                {entities.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {t(option.description)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="description"
              fullWidth
              label={t('documents.label_description')}
              name="description"
              onChange={(event) => setDescription(event.target.value)}
              value={description}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={disableSubmission()}
              endIcon={<AddCircleOutlineIcon />}
              type="submit"
              variant="contained"
            >
              {t('button.save')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default AddDocumentTypeForm;
