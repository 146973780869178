import React from 'react';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, Stack, Typography, Box } from '@mui/material';
import theme from 'config/material-ui/theme';
import useSubscriptionContext from 'pages/Subscription/context/useSubscriptionContext';
import { wrapperHeight, wrapperWidth } from './consts';
import FeatureTile from './FeatureTile/FeatureTile';
import features from './features';
import LogoTile from './LogoTile/LogoTile';
import nodeArrows from './nodeArrows';

export default function ModulesFeatures() {
  const { t } = useTranslation();
  const { selectedFeatures } = useSubscriptionContext();

  const isNodeActive = (start: string, end: string) => {
    if (start === 'logo') {
      return selectedFeatures.includes(end);
    }

    return selectedFeatures.includes(start) && selectedFeatures.includes(end);
  };

  return (
    <Stack
      bgcolor="background.other"
      p={5}
      width="75%"
      minWidth="98rem"
      borderRadius="2rem"
      spacing={5}
      alignItems="center"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        maxWidth="90rem"
      >
        <Typography variant="h5">
          {t('subscription.configure_active_modules')}
        </Typography>
        <Select sx={{ width: '24.8rem' }} value="custom">
          <MenuItem value="custom">{t('inputs.custom_set')}</MenuItem>
        </Select>
      </Stack>
      <Xwrapper>
        <Box
          position="relative"
          sx={{
            height: wrapperHeight,
            width: wrapperWidth,
          }}
        >
          {features.map((feature) => (
            <FeatureTile key={feature.label} feature={feature} />
          ))}
          <LogoTile />
          {nodeArrows.map((arrow) => (
            <Xarrow
              key={`${arrow.start}-${arrow.end}`}
              start={arrow.start}
              end={arrow.end}
              startAnchor={arrow.startAnchor}
              endAnchor={arrow.endAnchor}
              color={
                isNodeActive(arrow.start, arrow.end)
                  ? theme.palette.primary.main
                  : theme.palette.rating.empty
              }
              strokeWidth={2}
              showHead={false}
              path="grid"
            />
          ))}
        </Box>
      </Xwrapper>
    </Stack>
  );
}
