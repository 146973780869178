import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'label.name',
    align: 'left',
  },
  {
    label: 'banks.header_swift',
    align: 'left',
  },
  {
    label: 'label.type',
    align: 'right',
  },
  {
    label: 'bank_accounts.bank_number_label',
    align: 'right',
  }
];

export default tableHeaders;
