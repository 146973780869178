import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { useTranslation } from 'react-i18next';
import { useGetBenefitsQuery } from 'store/benefits/benefits';
import { Benefit } from 'store/benefits/interfaces';
import { EmployeeBenefit } from 'store/employeeBenefit/interfaces';
import dateFormat from 'dateformat';
import AllInclusive from '@mui/icons-material/AllInclusive';

interface BenefitsProps {
  employeeBenefits: EmployeeBenefit[];
}

export default function Benefits({ employeeBenefits }: BenefitsProps) {
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();
  const { data: benefits = [] } = useGetBenefitsQuery();

  const getBenefitByBenefitIri = (benefitIri: string) => {
    const benefit = benefits.find(
      (benefitData) => benefitData['@id'] === benefitIri,
    );

    if (!benefit) {
      return {} as Benefit;
    }

    return benefit;
  };

  const isBenefitActive = (employeeBenefit: EmployeeBenefit) => {
    if (!employeeBenefit.dateTo) {
      return true;
    }

    const dateTo = new Date(employeeBenefit.dateTo);
    const currentDate = new Date();

    return dateTo > currentDate;
  };

  return (
    <>
      <Typography color="primary" textTransform="uppercase" variant="h6">
        {t('menu.benefits')}
      </Typography>
      {employeeBenefits.length ? (
        employeeBenefits.map((employeeBenefit) => {
          const benefit = getBenefitByBenefitIri(employeeBenefit.benefit);

          return (
            <Box
              key={employeeBenefit.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                color={
                  isBenefitActive(employeeBenefit)
                    ? 'text.primary'
                    : 'text.secondary'
                }
                variant="bold"
              >
                {benefit?.name}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <TitleTooltip
                  titleComponent={
                    <Typography
                      color={
                        isBenefitActive(employeeBenefit)
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                    >
                      {t('benefits.price')}
                    </Typography>
                  }
                >
                  <Typography
                    color={
                      isBenefitActive(employeeBenefit)
                        ? 'text.primary'
                        : 'text.secondary'
                    }
                    variant="body1"
                  >
                    {benefit?.currentBenefitPrice &&
                      formatAmount(
                        benefit?.currentBenefitPrice
                          .defaultCompanyCoverageAmount,
                      )}
                  </Typography>
                </TitleTooltip>
                <TitleTooltip
                  titleComponent={
                    <Typography
                      color={
                        isBenefitActive(employeeBenefit)
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                    >
                      {t('benefits.coverage')}
                    </Typography>
                  }
                >
                  <Typography
                    color={
                      isBenefitActive(employeeBenefit)
                        ? 'text.primary'
                        : 'text.secondary'
                    }
                    variant="body1"
                  >
                    {benefit?.currentBenefitPrice &&
                      formatAmount(
                        benefit?.currentBenefitPrice
                          .defaultCompanyCoverageAmount,
                      )}
                  </Typography>
                </TitleTooltip>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography
                  color={
                    isBenefitActive(employeeBenefit)
                      ? 'text.primary'
                      : 'text.secondary'
                  }
                  variant="body1"
                >
                  {dateFormat(employeeBenefit.dateFrom, 'yyyy-mm-dd')}
                </Typography>
                {employeeBenefit.dateTo ? (
                  <Typography
                    color={
                      isBenefitActive(employeeBenefit)
                        ? 'text.primary'
                        : 'text.secondary'
                    }
                    variant="body1"
                  >
                    {dateFormat(employeeBenefit.dateTo, 'yyyy-mm-dd')}
                  </Typography>
                ) : (
                  <AllInclusive />
                )}
              </Stack>
            </Box>
          );
        })
      ) : (
        <Typography variant="body1">{t('label.no_data')}</Typography>
      )}
    </>
  );
}
