import React, { useState } from 'react';
import { useGetEmployeeProjectRatesQuery } from 'store/employee/employees';
import { skipToken } from '@reduxjs/toolkit/query';
import Loader from 'common/Loader';
import Error from 'common/Error';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Rate from 'components/EditEmployeeForm/ProjectRates/Rate';
import AddProjectRateForm from 'components/EditEmployeeForm/ProjectRates/AddProjectRateForm';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import { useGetClientsQuery } from 'store/clients/clients';
import useCheckFeature from 'hooks/useCheckFeature';
import features from 'config/features/features';
import getRatesHeaders from 'components/EditEmployeeForm/ProjectRates/headers';
import { Employee } from 'store/employee/interfaces';

export interface ProjectRatesProps {
  employee: Employee;
}

function ProjectRates({ employee }: ProjectRatesProps) {
  const { t } = useTranslation();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const {
    data: projectRates = [],
    isLoading: projectRatesFetching,
    isError: projectRatesFetchError,
  } = useGetEmployeeProjectRatesQuery(+employee.id ?? skipToken);
  const {
    data: clients = [],
    isLoading: clientsFetching,
    isError: clientsFetchError,
  } = useGetClientsQuery({ properties: ['name', 'currency'] });
  const isInvoiceFeatureActive = useCheckFeature(features.incomes);
  const headers = getRatesHeaders(isInvoiceFeatureActive);

  if (projectRatesFetching || clientsFetching) {
    return <Loader />;
  }

  if (projectRatesFetchError || clientsFetchError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(
                (header) =>
                  header.visible && (
                    <TableCell key={header.label} align={header.align}>
                      <Typography variant="tableHeader" color="text.secondary">
                        {t(header.label)}
                      </Typography>
                    </TableCell>
                  ),
              )}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            <RowAddButton
              colSpan={headers.length + 1}
              onClick={() => setIsAddFormOpen(true)}
            >
              <Typography variant="body2">
                {t('employee_project_rates.add_new')}
              </Typography>
            </RowAddButton>
            {projectRates.map((rate) => (
              <Rate key={rate.id} rate={rate} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        onClose={() => setIsAddFormOpen(false)}
        open={isAddFormOpen}
      >
        <AddProjectRateForm
          employeeIri={employee['@id']}
          setIsOpen={setIsAddFormOpen}
          clients={clients}
        />
      </Dialog>
    </>
  );
}

export default ProjectRates;
