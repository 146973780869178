import { Project } from 'store/projects/interfaces';
import { Client } from 'store/clients/clients';
import replaceApiIri from './replaceApiIri';

const getProjectWithClientCurrency = (
  project: Project,
  clients: Pick<Client, 'name' | '@id' | 'currency'>[],
) => {
  if (!project.client) {
    return project.name;
  }

  const clientProject = clients.find(
    (client) => client['@id'] === project.client,
  );

  return clientProject
    ? `${project.name} (${replaceApiIri(clientProject.currency, 'currencies')})`
    : project.name;
};

export default getProjectWithClientCurrency;
