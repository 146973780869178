import React from 'react';
import { Box, Stack } from '@mui/material';
import MonthBlock from './MonthBlock/MonthBlock';
import { yearBlockHeight } from '../consts';
import useBudgetPeriodSelectorContext from '../context/useBudgetPeriodSelectorContext';

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default function MonthsGroup() {
  const { yearGroups } = useBudgetPeriodSelectorContext();

  return (
    <Box position="relative" width="100%" bgcolor="background.other">
      {yearGroups.map((yearItem) => (
        <Stack
          key={yearItem.year}
          width="100%"
          height={yearBlockHeight}
          sx={{
            position: 'absolute',
            left: 0,
            top: yearItem.top,
          }}
        >
          {months.map((month) => (
            <MonthBlock
              key={`${yearItem.year}.${month}`}
              month={month}
              year={yearItem.year}
            />
          ))}
        </Stack>
      ))}
    </Box>
  );
}
