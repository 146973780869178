export const projectTypes = [
  { id: 'fixed-price', description: 'projects.fixed_price' },
  { id: 'time-and-material', description: 'projects.time_and_material' },
  { id: 'non-billable', description: 'projects.non_billable' },
  { id: 'internal', description: 'projects.internal' },
];

export const projectStatuses = [
  { id: 'active', description: 'projects.active' },
  { id: 'finished', description: 'projects.finished' },
];
