import React, { useState } from 'react';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import TransactionSelect from 'components/TransactionsList/TransactionSelect/TransactionSelect';
import {
  Transaction,
  useUpdateTransactionMutation,
} from 'store/transactions/transactions';
import getAmountMonthsBack from 'helpers/getAmountMonthsBack';
import { useTranslation } from 'react-i18next';

interface DatesProps {
  transaction: Transaction;
  size: 'md' | 'lg';
}

const sizeMap = {
  md: 1,
  lg: 3,
};

function Dates({ transaction, size }: DatesProps) {
  const [relatedMonth, setRelatedMonth] = useState(
    transaction.relatedDate
      ? dateFormat(transaction.relatedDate, 'yyyy-mm')
      : '',
  );
  const widthSize = sizeMap[size];
  const { t } = useTranslation();
  const periods = getAmountMonthsBack(new Date(transaction.orderDate), 3);
  const [updateTransaction] = useUpdateTransactionMutation();

  const handleUpdateTransaction = (event: SelectChangeEvent) => {
    setRelatedMonth(event.target.value);
    updateTransaction({
      id: transaction.id,
      relatedDate:
        event.target.value !== '' ? new Date(event.target.value) : null,
    });
  };

  return (
    <>
      <Grid item xs={2} display="flex" flexDirection="column">
        <Typography variant="body1">
          {t('transactions.ordered_shortcut', {
            date: dateFormat(transaction.orderDate, 'yyyy-mm-dd'),
          })}
        </Typography>
        <Typography variant="body1">
          {t('transactions.executed_shortcut', {
            date: dateFormat(transaction.execDate, 'yyyy-mm-dd'),
          })}
        </Typography>
      </Grid>
      <Grid item xs={widthSize} display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <TransactionSelect
            fullWidth
            placeholder="transactions.related_month"
            selectId="related-month-select"
            value={relatedMonth}
            onChangeFn={handleUpdateTransaction}
            selectOptions={periods.reverse().map((period) => ({
              id: period,
              description: period,
            }))}
          />
        </Box>
      </Grid>
    </>
  );
}

export default Dates;
