import { TableCell, TableCellProps, styled } from '@mui/material';
import gradients from 'config/material-ui/gradients';

const TagsCell = styled(TableCell)<TableCellProps>(() => ({
  maxWidth: '37.2rem',
  overflowX: 'hidden',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '12rem',
    background: gradients.TRANSPARENT_TO_WHITE,
  },
}));

export default TagsCell;
