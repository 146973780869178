import React from 'react';
import {
  TableRow,
  TableCell,
  Checkbox,
  Stack,
  Typography,
  Chip,
  IconButton,
} from '@mui/material';
import openFileWithAuth from 'helpers/openFileWithAuth';
import FileSearchIcon from 'assets/icons/FileSearchIcon';
import NavigateButton from 'common/NavigateButton/NavigateButton';
import { Candidate, CandidateRelationName } from 'store/candidates/interfaces';
import TagsCell from './TagsCell.styled';

interface CandidateCellProps {
  candidate: Candidate;
  handleCheckboxClick: (id: string) => void;
  isCheckboxSelected: (id: string) => boolean;
}

export default function CandidateCell({
  candidate,
  handleCheckboxClick,
  isCheckboxSelected,
}: CandidateCellProps) {

  const candidateResume = candidate?.files?.find(
    (candidateFile) =>
      candidateFile.relationName === CandidateRelationName.resume,
  );

  const handleOpenResume = () => {
    if (!candidateResume) {
      return;
    }
    openFileWithAuth(candidateResume.url);
  };

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          onChange={() => handleCheckboxClick(candidate.id)}
          checked={isCheckboxSelected(candidate.id)}
          value={candidate.id}
        />
      </TableCell>
      <TableCell>
        <Stack>
          <Typography variant="body2">
            {candidate.firstname} {candidate.lastname}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {candidate.email}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{candidate.phoneNumber}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="primary.main">
          {candidate.source}
        </Typography>
      </TableCell>
      <TagsCell>
        <Stack direction="row" spacing={1}>
          {candidate.tags?.map((tag) => (
            <Chip
              variant="outlined"
              size="small"
              label={
                <Typography variant="chipLabel">
                  {tag.tagDefinition.name}
                </Typography>
              }
              key={tag.id}
            />
          ))}
        </Stack>
      </TagsCell>
      <TableCell>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <IconButton
            onClick={handleOpenResume}
            sx={{
              width: '4rem',
              height: '4rem',
              opacity: candidateResume ? 1 : 0,
            }}
            disabled={!candidateResume}
          >
            <FileSearchIcon
              sx={{
                fontSize: '2rem',
                color: 'icon.main',
              }}
            />
          </IconButton>
          <NavigateButton to={`/human-resources/candidate/${candidate.id}`} />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
