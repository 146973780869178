/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';

const useNotification = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const notifyUser = (title: string, options?: NotificationOptions) => {
    if (!('Notification' in window)) {
      dispatch(setErrorMessage(t('errors.no_desktop_notifications')));
      dispatch(setErrorStatus(true));
    } else if (Notification.permission === 'granted') {
      const notification = new Notification(title, options);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          const notification = new Notification(title, options);
        }
      });
    }
  };

  return notifyUser;
};

export default useNotification;
