import api from 'store/api';
import { ApiResponse } from 'store/interfaces';

export interface DocumentType {
  ['@id']: string;
  id: number;
  name: string;
  relationName: string;
  description: string;
}

export interface DocumentTypesResponseDto extends ApiResponse {
  'hydra:member': DocumentType[];
}

export const documentTypesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentTypes: builder.query<DocumentType[], void>({
      query: () => '/document-types',
      providesTags: ['DocumentTypes'],
      transformResponse: (response: DocumentTypesResponseDto) =>
        response['hydra:member'].sort((type, comparedType) =>
          type.name.localeCompare(comparedType.name),
        ),
    }),
    getDocumentType: builder.query<DocumentType, number | string>({
      query: (id) => `/document-types/${id}`,
      providesTags: ['DocumentTypes'],
    }),
    addDocumentType: builder.mutation({
      query: (body) => ({
        url: '/document-types',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DocumentTypes'],
    }),
    updateDocumentType: builder.mutation<
      DocumentType,
      Partial<DocumentType> & Pick<DocumentType, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/document-types/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['DocumentTypes'],
    }),
  }),
});

export const {
  useGetDocumentTypesQuery,
  useGetDocumentTypeQuery,
  useAddDocumentTypeMutation,
  useUpdateDocumentTypeMutation,
} = documentTypesApi;
