import React from 'react';
import { Stack } from '@mui/material';

interface SchedulerScrollWrapperProps {
  children: React.ReactNode;
}

const fullHeightVhOfScheduler = '83.8vh';

export default function SchedulerScrollWrapper({
  children,
}: SchedulerScrollWrapperProps) {
  return (
    <Stack
      sx={{
        height: fullHeightVhOfScheduler,
        overflowY: 'auto',
        justifyContent: 'space-between'
      }}
    >
      {children}
    </Stack>
  );
}
