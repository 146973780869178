import {
  styled,
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
} from '@mui/material';

export const StyledAccordion = styled(Accordion)<AccordionProps>(
  ({ theme }) => ({
    border: `0.1rem solid ${theme.palette.border.light}`,
    borderRadius: '1.2rem',
    boxShadow: 'none',
  }),
);

export const StyledSummary = styled(AccordionSummary)<AccordionSummaryProps>({
  flexDirection: 'row-reverse',
  gap: '1.2rem',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
});
