import api, { CustomError } from 'store/api';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';
import { ProjectStatuses } from 'enums';
import {
  Project,
  ProjectsFilters,
  ProjectMutation,
  ProjectPreview,
  ProjectsResponseDto,
  ProjectPreviewsResponseDto,
} from './interfaces';

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectsResponseDto, Partial<ProjectsFilters>>({
      query: (filters) => ({
        url: '/projects',
        params: filters,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result['hydra:member'].map(({ id }) => ({
                type: 'Projects' as const,
                id,
              })),
              { type: 'Projects', id: 'LIST' },
            ]
          : [{ type: 'Projects', id: 'LIST' }],
      transformResponse: (response: ProjectsResponseDto) => ({
        ...response,
        'hydra:member': [...response['hydra:member']].sort(
          (project, comparedProject) => {
            let sortedByStatus = 0;

            if (project.status) {
              sortedByStatus = project.status.localeCompare(
                comparedProject.status,
              );
            }

            const sortedByName = project.name.localeCompare(
              comparedProject.name,
            );

            return sortedByStatus !== 0 ? sortedByStatus : sortedByName;
          },
        ),
      }),
    }),
    getProject: builder.query<Project, string>({
      query: (id) => `/projects/${id}`,

      providesTags: (result, error, id) => [{ type: 'Projects', id }],
    }),
    addProject: builder.mutation({
      query: (body) => ({
        url: '/projects',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
    }),
    updateProject: builder.mutation<
      Project,
      Partial<ProjectMutation> & Pick<ProjectMutation, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/projects/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProject } = await queryFulfilled;
          dispatch(
            projectsApi.util.updateQueryData(
              'getProject',
              String(id),
              () => updatedProject,
            ),
          );
          dispatch(
            projectsApi.util.updateQueryData(
              'getProjects',
              { status: ProjectStatuses.active },
              (draft) => {
                const draftCopy = { ...draft };
                const changedProjectIndex = draftCopy['hydra:member'].findIndex(
                  (item) => item.id === id,
                );
                draftCopy['hydra:member'][changedProjectIndex] = updatedProject;

                return draftCopy;
              },
            ),
          );
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(setErrorMessage((error as CustomError).data.detail));
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    getProjectsPreview: builder.query<
      ProjectPreview[],
      Partial<ProjectsFilters>
    >({
      query: (filters) => ({
        url: '/projects/preview',
        params: filters,
      }),
      transformResponse: (response: ProjectPreviewsResponseDto) =>
        response['hydra:member'].sort((project, comparedProject) =>
          project.name.localeCompare(comparedProject.name),
        ),
      providesTags: ['Projects'],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useGetProjectsPreviewQuery,
} = projectsApi;
