import React from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface CancelButtonProps {
  onClick: () => void;
}

export default function CancelButton({ onClick }: CancelButtonProps) {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} variant="text">
      <Typography color="secondary.main" variant="buttonMedium">
        {t('button.cancel')}
      </Typography>
    </Button>
  );
}
