import React, { useEffect, useState } from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Client, useUpdateClientMutation } from 'store/clients/clients';
import { Country } from 'store/countries/countries';
import { Language } from 'store/languages/languages';
import { Currency } from 'store/currencies/currencies';
import { StatusProps } from 'hoc/Status/Status';
import { useValidate } from 'hooks/useValidate';
import ClientFormSelect from 'components/AddClientForm/ClientFormSelect';
import clientStatuses from 'components/ClientsList/clientsSelectData';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface OverviewProps extends StatusProps {
  client: Client;
  countries: Country[];
  currencies: Currency[];
  languages: Language[];
}

export interface Errors {
  name: boolean;
  status: boolean;
  country: boolean;
  invoiceEmail: boolean;
  vatNumber: boolean;
}

export default function Overview({
  setIsError,
  setIsSuccess,
  setErrorMessage,
  client,
  countries,
  currencies,
  languages,
}: OverviewProps) {
  const [updateClient, { isLoading }] = useUpdateClientMutation();

  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState('');
  const [documentsLanguage, setDocumentsLanguage] = useState('');
  const [formDataChanged, setFormDataChanged] = useState(false);
  const { errors, validate } = useValidate<Errors>();
  const { t } = useTranslation();

  useEffect(() => {
    setName(client.name);
    setStatus(client.status);
    setCountry(client.country);
    setCurrency(client.currency);
    setDocumentsLanguage(client.documentsLanguage);
  }, [client]);

  const disableSubmission = () =>
    Object.values(errors).some((value) => value) ||
    !name ||
    !status ||
    !country ||
    !currency ||
    !documentsLanguage;

  const handleEditClient = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateClient({
        id: client.id,
        country,
        currency,
        status,
        name,
        documentsLanguage,
      }).unwrap();
      setIsSuccess(true);
      setFormDataChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <Box p={4} bgcolor="background.list" borderRadius="1.2rem">
      <Typography variant="h5" mb={4}>
        {t('tab.overview')}
      </Typography>
      <form onSubmit={handleEditClient}>
        <FormControl fullWidth sx={{ rowGap: 2 }}>
          <TextField
            fullWidth
            required
            id="client-name"
            label={t('label.name')}
            value={name}
            error={errors.name}
            helperText={errors.name && t('errors.too_short_or_too_long_name')}
            onChange={(event) => {
              setName(event.target.value);
              setFormDataChanged(true);
            }}
            onBlur={(event) =>
              validate(
                'name',
                event.target.value.length > 0 &&
                  event.target.value.length < 255,
              )
            }
          />
          <ClientFormSelect
            selectId="status-select"
            label={t('label.status')}
            value={status}
            onChangeFn={(event) => {
              setStatus(event.target.value);
              setFormDataChanged(true);
            }}
            selectOptions={clientStatuses.map((clientStatus) => ({
              id: clientStatus.id,
              name: clientStatus.description,
            }))}
          />
          <ClientFormSelect
            selectId="country-select"
            label={t('clients.country')}
            value={country}
            onChangeFn={(event) => {
              setCountry(event.target.value);
              setFormDataChanged(true);
            }}
            selectOptions={countries.map((item) => ({
              id: item['@id'],
              name: item.name,
            }))}
          />
          <ClientFormSelect
            selectId="currency-select"
            label={t('clients.currency')}
            value={currency}
            onChangeFn={(event) => {
              setCurrency(event.target.value);
              setFormDataChanged(true);
            }}
            selectOptions={currencies.map((item) => ({
              id: item['@id'],
              name: item.id,
            }))}
          />
          <ClientFormSelect
            selectId="docs-language-select"
            label={t('clients.document_language')}
            value={documentsLanguage}
            onChangeFn={(event) => {
              setDocumentsLanguage(event.target.value);
              setFormDataChanged(true);
            }}
            selectOptions={languages.map((language) => ({
              id: language.id,
              name: language.id,
            }))}
          />
        </FormControl>
        {formDataChanged && (
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<Add />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
}
