import React from 'react';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import AttachedEmployeesSelect from 'components/EditProjectForm/AttachedEmployeesSelect/AttachedEmployeesSelect';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import { StatusProps } from 'hoc/Status/Status';
import { EmployeePreview } from 'store/employee/interfaces';
import { useAddBudgetEmployeeMutation } from 'store/budget/budget';
import { Typography, Box, FormControl } from '@mui/material';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';
import { Budget } from 'store/budget/interfaces';

export interface AddBudgetEmployeesProps extends StatusProps {
  employees: EmployeePreview[];
  budget: Budget;
}

export default function AddBudgetEmployees({
  setIsSuccess,
  setIsErrorCatch,
  employees,
  budget,
}: AddBudgetEmployeesProps) {
  const { t } = useTranslation();
  const [addBudgetEmployee, { isLoading }] = useAddBudgetEmployeeMutation();
  const {
    selected: selectedEmployeeIris,
    isSelected: isEmployeeIriSelected,
    handleChange,
  } = useMultipleCheckboxSelect<string>([]);

  const employeesToAdd = employees.filter(
    (employee) =>
      !budget?.budgetEmployees?.some(
        (budgetEmployee) => budgetEmployee.employee === employee['@id'],
      ),
  );

  const handleAddBudgetEmployee = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await Promise.all(
        selectedEmployeeIris.map(async (employee) => {
          await addBudgetEmployee({
            budget: budget?.['@id'],
            employee,
          }).unwrap();
        }),
      );

      setIsSuccess(true);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  return (
    <StyledContentBox>
      <form onSubmit={handleAddBudgetEmployee}>
        <FormControl fullWidth>
          <Box p={3}>
            <Typography variant="h5" mb={3}>
              {t('budget.budget_menage_participants')}
            </Typography>

            <AttachedEmployeesSelect
              employees={employeesToAdd}
              selectedEmployees={selectedEmployeeIris}
              isEmployeeSelected={isEmployeeIriSelected}
              onCheckboxChange={(event) => {
                handleChange(event);
              }}
            />
          </Box>
          {selectedEmployeeIris.length > 0 && (
            <Box display="flex" justifyContent="flex-end" p={3}>
              <LoadingButton
                disabled={!selectedEmployeeIris.length}
                endIcon={<Add />}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Box>
          )}
        </FormControl>
      </form>
    </StyledContentBox>
  );
}
