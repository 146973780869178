import api from 'store/api';

export interface DaysFree {
  id: number;
  date: string;
}

export interface DaysFreeResponseDto {
  'hydra:member': DaysFree[];
}

export const bankHolidaysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBankHolidays: builder.query<DaysFree[], void>({
      query: () => '/holiday-days-frees',
      providesTags: ['BankHolidays'],
      transformResponse: (response: DaysFreeResponseDto) =>
        response['hydra:member'].sort((date, comparedDate) =>
          date.date.localeCompare(comparedDate.date),
        ),
    }),
    addBankHoliday: builder.mutation({
      query: (body) => ({
        url: '/holiday-days-frees',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BankHolidays'],
    }),
    deleteBankHoliday: builder.mutation({
      query: (id: number) => ({
        url: `/holiday-days-frees/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BankHolidays'],
    }),
  }),
});

export const {
  useGetBankHolidaysQuery,
  useAddBankHolidayMutation,
  useDeleteBankHolidayMutation,
} = bankHolidaysApi;
