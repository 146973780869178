import React from 'react';
import { Avatar, AvatarGroup } from '@mui/material';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { EmployeePreview } from 'store/employee/interfaces';
import { EmployeeOfProjectMemberEmployee } from 'hooks/useProjectMemberEmployee/interfaces';

interface EmployeesAvatarsProps {
  employees: EmployeePreview[] | EmployeeOfProjectMemberEmployee[];
  key: string;
}

export default function EmployeesAvatars({
  employees,
  key,
}: EmployeesAvatarsProps) {
  return (
    <AvatarGroup
      max={employees.length > 6 ? 6 : 7}
      slotProps={{
        additionalAvatar: {
          sx: {
            width: '3.2rem',
            height: '3.2rem',
          },
        },
      }}
    >
      {employees.map((employee) => (
        <TitleTooltip
          key={`${key}-${employee.id}`}
          title={`${employee.lastname} ${employee.firstname}`}
        >
          <Avatar
            sx={{ width: '3.2rem', height: '3.2rem' }}
            src={employee.avatarUrl}
          />
        </TitleTooltip>
      ))}
    </AvatarGroup>
  );
}
