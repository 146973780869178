import api from 'store/api';
import { EditCostToSend } from 'store/costs/costs';
import { ApiResponse } from 'store/interfaces';

export interface CostGroupProps {
  ['@id']: string;
  id: number;
  name: string;
  type: string;
  contractorsCount: number;
}

export interface CostGroupPropsResponseDto extends ApiResponse {
  'hydra:member': CostGroupProps[];
}

export interface CostCharts {
  groupedTransactions: Record<string, TransactionsValues>;
  sumPerCostGroup: Record<string, TransactionsValues>;
  incomes: Record<string, number>;
  costGroups: Record<string, Pick<CostGroupProps, 'name' | 'type'>>;
}

export interface CostGroupChartData {
  costs: EditCostToSend[];
  groupedTransactions: Record<string, TransactionsValues>;
}

type TransactionsValues = Record<string, number>;

export const costGroupsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCostGroups: builder.query<CostGroupProps[], void>({
      query: () => '/cost-groups',
      providesTags: ['CostGroups'],
      transformResponse: (response: CostGroupPropsResponseDto) =>
        response['hydra:member'].sort((group, comparedGroup) =>
          group.name.localeCompare(comparedGroup.name),
        ),
    }),
    getCostGroup: builder.query<CostGroupProps, number>({
      query: (id) => `/cost-groups/${id}`,
      providesTags: ['CostGroups'],
    }),
    addCostGroup: builder.mutation({
      query: (body) => ({
        url: '/cost-groups',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CostGroups'],
    }),
    updateCostGroup: builder.mutation<
      CostGroupProps,
      Partial<CostGroupProps> & Pick<CostGroupProps, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/cost-groups/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['CostGroups'],
    }),
    getCostsCharts: builder.query<CostCharts, void>({
      query: () => ({
        url: '/cost-groups/chart',
        headers: { accept: 'application/json' },
      }),
      providesTags: ['CostsCharts'],
    }),
    getCostGroupChart: builder.query<CostGroupChartData, string>({
      query: (id) => ({
        url: `/cost-groups/${id}/chart`,
        headers: { accept: 'application/json' },
      }),
      providesTags: ['CostsCharts'],
    }),
  }),
});

export const {
  useGetCostGroupQuery,
  useGetCostGroupsQuery,
  useAddCostGroupMutation,
  useUpdateCostGroupMutation,
  useGetCostsChartsQuery,
  useGetCostGroupChartQuery,
} = costGroupsApi;
