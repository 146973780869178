import { SelectChangeEvent } from '@mui/material';
import { createContext } from 'react';
import { BudgetPeriod } from 'store/budget/interfaces';

export interface PeriodState {
  period: BudgetPeriod | '';
  dateFrom: Date | null;
  dateTo: Date | null;
}

export interface BudgetPeriodContextProps {
  periodState: PeriodState;
  isEmptyState: boolean;
  handleOnSelectChange: (event: SelectChangeEvent<string>) => void;
  handleOnDatePickerChange: (
    date: Date | null,
    key: Omit<keyof PeriodState, 'period'>,
  ) => void;
  handleResetPeriodState: () => void;
  modifyDateToByMonths: (monthCount: number) => void;
  modifyDateFromByMonths: (monthCount: number) => void;
  createMonthPeriodState: (date: Date) => void;
  createQuarterPeriodState: (date: Date) => void;
  createYearPeriodState: (date: Date) => void;
  handleChangePeriod: (period: BudgetPeriod) => void;
}

export const BudgetPeriodContext = createContext<
  BudgetPeriodContextProps | undefined
>(undefined);
