import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { EmployeeParticipant } from 'store/employee/interfaces';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import { StatusProps } from 'hoc/Status/Status';
import Result from 'components/BudgetEmployees/BudgetEmployeesList/Result';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import { CustomError } from 'store/api';
import { useRemoveBudgetEmployeeMutation } from 'store/budget/budget';
import tableHeaders from './tableHeaders';

export interface BudgetEmployeesListProps extends StatusProps {
  isLoading: boolean;
  isFetchError: boolean;
  participants: EmployeeParticipant[];
}

export default function BudgetEmployeesList({
  setIsSuccess,
  setIsErrorCatch,
  participants,
  isLoading,
  isFetchError,
}: BudgetEmployeesListProps) {
  const { t } = useTranslation();
  const [currentParticipant, setCurrentParticipant] =
    useState<EmployeeParticipant | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [removeBudgetEmployee] = useRemoveBudgetEmployeeMutation();

  const handleRemoveBudgetEmployee = async () => {
    if (!currentParticipant?.employeeBudgetId) {
      return;
    }
    try {
      await removeBudgetEmployee(currentParticipant?.employeeBudgetId).unwrap();
      setIsSuccess(true);
      setIsConfirmationDialogOpen(false);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
    }
  };

  const handleDelete = (participant: EmployeeParticipant) => {
    if (!participant) {
      return;
    }
    setIsConfirmationDialogOpen(true);
    setCurrentParticipant(participant);
  };
  return (
    <StyledContentBox>
      <Box p={3}>
        <Typography variant="h5">{t('budget.budget_participants')}</Typography>
        <TableContainer sx={{ mt: 3, maxHeight: '42rem' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header.label} align={header.align}>
                    <Typography variant="tableHeader" color="text.secondary">
                      {t(header.label)}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell colSpan={tableHeaders.length} />
              </TableRow>
            </TableHead>
            <TableBodyContent
              tableHeaders={tableHeaders}
              isError={isFetchError}
              isFetching={isLoading}
            >
              {participants.map((participant) => (
                <Result
                  key={participant.id}
                  participant={participant}
                  handleDelete={handleDelete}
                />
              ))}
            </TableBodyContent>
          </Table>
        </TableContainer>
      </Box>
      <ConfirmationDialog
        handleAccept={() => handleRemoveBudgetEmployee()}
        confirmationText={t('budget.budget_delete_employee', {
          firstName: currentParticipant?.firstname,
          lastName: currentParticipant?.lastname,
        })}
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
      />
    </StyledContentBox>
  );
}
