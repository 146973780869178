import { StackOwnProps } from '@mui/material';
import configFeatures from 'config/features/features';
import { linkIcons } from 'components/Menu/menuLinks';
import { wrapperHeight, wrapperWidth } from './consts';

export interface Feature {
  label: string;
  name: keyof typeof configFeatures;
  sx: StackOwnProps['sx'];
  requiredFeatures?: (keyof typeof configFeatures)[];
  price?: number;
  pricePerUser?: number;
  icon: JSX.Element;
}

// TODO: get features from the API when it's ready
const features: Feature[] = [
  {
    label: 'subscription.feature_holidays',
    name: 'holidays',
    sx: {
      top: 0,
      left: 0,
    },
    requiredFeatures: ['employees'],
    pricePerUser: 2,
    icon: linkIcons.holidays,
  },
  {
    label: 'employees.list_header',
    name: 'employees',
    sx: {
      top: 0,
      left: wrapperWidth / 2,
      transform: 'translateX(-50%)',
    },
    pricePerUser: 2,
    icon: linkIcons.employees,
  },
  {
    label: 'subscription.feature_projects',
    name: 'projects',
    sx: {
      top: 0,
      right: 0,
    },
    pricePerUser: 1,
    icon: linkIcons.projects,
  },
  {
    label: 'subscription.feature_hr',
    name: 'hr',
    sx: {
      top: wrapperHeight / 2,
      left: 0,
      transform: 'translateY(-50%)',
    },
    pricePerUser: 0.75,
    price: 75,
    icon: linkIcons.hr,
  },
  {
    label: 'subscription.feature_costs',
    name: 'costs',
    sx: {
      top: wrapperHeight / 2,
      right: 0,
      transform: 'translateY(-50%)',
    },
    pricePerUser: 3,
    price: 50,
    icon: linkIcons.contractors,
  },
  {
    label: 'subscription.feature_bookings',
    name: 'bookings',
    sx: {
      bottom: 0,
      left: 0,
    },
    price: 75,
    icon: linkIcons.propertyBooking,
  },
  {
    label: 'budget.header',
    name: 'budget',
    sx: {
      bottom: 0,
      left: wrapperWidth / 2,
      transform: 'translateX(-50%)',
    },
    pricePerUser: 0.25,
    icon: linkIcons.budget,
  },
  {
    label: 'subscription.incomes',
    name: 'incomes',
    sx: {
      bottom: 0,
      right: 0,
    },
    pricePerUser: 3,
    icon: linkIcons.invoices,
  },
];

export default features;
