import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import ResourceRequestWizardStepper from 'components/ResourceRequestWizardStepper/ResourceRequestWizardStepper';
import ResourcePositionInformationForm from 'components/ResourcePositionInformationForm/ResourcePositionInformationForm';
import ResourceCandidateRequirement from 'components/ResourceCandidateRequirement/ResourceCandidateRequirement';
import ResourceBudgetAndAllocationForm from 'components/ResourceBudgetAndAllocationForm/ResourceBudgetAndAllocationForm';
import { ResourceRequestMutationRequest } from 'store/resourceRequests/interfaces';
import {
  useAddResourceRequestMutation,
  useUpdateResourceRequestMutation,
} from 'store/resourceRequests/resourceRequests';
import { FormPayload as PositionFormPayload } from 'components/ResourcePositionInformationForm/interfaces';
import { FormPayload as BudgetFormPayload } from 'components/ResourceBudgetAndAllocationForm/interfaces';
import { CustomError } from 'store/api';
import formatAmountPayload from 'helpers/formatAmountPayload';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import StyledStepperContentWrapper from './StepperContentWrapper.styled';
import ResourceRequestWizardProvider from './Context/ResourceRequestWizardProvider';
import { ResourceRequestWizardProps, FormResourceRequest } from './interfaces';

export enum ResourceRequestWizardStep {
  positionInformation,
  candidateRequirements,
  budgetAndAllocation,
}

export default function ResourceRequestWizard({
  setIsSuccess,
  setIsError,
  setErrorMessage,
  resourceRequestId,
  initFormResourceRequest = {} as FormResourceRequest,
}: ResourceRequestWizardProps) {
  const [activeStep, setActiveStep] = useState(
    ResourceRequestWizardStep.positionInformation,
  );
  const [formResourceRequest, setFormResourceRequest] = useState(
    initFormResourceRequest,
  );

  const [createdResourceId, setCreatedResourceId] = useState<string | null>(
    resourceRequestId || null,
  );

  const [addResourceRequest, { isLoading: addResourceLoading }] =
    useAddResourceRequestMutation();
  const [updateResourceRequest, { isLoading: updateResourceLoading }] =
    useUpdateResourceRequestMutation();

  const navigate = useNavigate();
  const handleNextStep = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBackStep = () => setActiveStep((precStep) => precStep - 1);

  const positionInformationSubmit = async (data: PositionFormPayload) => {
    const isFormDataChanged = Object.keys(data).some(
      (key) =>
        formResourceRequest[key as keyof typeof data] !==
        data[key as keyof typeof data],
    );

    if (!isFormDataChanged) {
      handleNextStep();
      return;
    }

    try {
      const { client, ...restData } = data;
      const payload = {
        client: client || null,
        ...restData,
      } as ResourceRequestMutationRequest;

      if (!createdResourceId) {
        const createdResource = await addResourceRequest(payload).unwrap();
        setFormResourceRequest((prev) => ({ ...prev, ...data }));
        setCreatedResourceId(createdResource.id);
      } else {
        await updateResourceRequest({
          id: createdResourceId,
          ...payload,
        }).unwrap();
        setFormResourceRequest((prev) => ({ ...prev, ...data }));
      }

      setIsSuccess(true);
      handleNextStep();
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const budgetAndAllocationSubmit = async (data: BudgetFormPayload) => {
    try {
      const isFormDataChanged = Object.keys(data).some(
        (key) =>
          formResourceRequest[key as keyof typeof data] !==
          data[key as keyof typeof data],
      );

      if (!isFormDataChanged) {
        navigate(`/human-resources/resources/${createdResourceId}`);
        return;
      }

      const { salaryRangeMax, salaryRangeMin, billingType } = data;
      const payload = {
        ...(salaryRangeMax && {
          salaryRangeMax: formatAmountPayload(
            salaryRangeMax,
            BACKEND_AMOUNT_MODIFIER,
          ),
        }),
        ...(salaryRangeMin && {
          salaryRangeMin: formatAmountPayload(
            salaryRangeMin,
            BACKEND_AMOUNT_MODIFIER,
          ),
        }),
        billingType: billingType || null,
      } as ResourceRequestMutationRequest;

      await updateResourceRequest({
        id: createdResourceId as string,
        ...payload,
      }).unwrap();
      setFormResourceRequest((prev) => ({ ...prev, ...data }));
      setIsSuccess(true);
      navigate(`/human-resources/resources/${createdResourceId}`);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const budgetAndAllocationBackStep = (
    payload: Partial<FormResourceRequest>,
  ) => {
    setFormResourceRequest((prev) => ({ ...prev, ...payload }));
    handleBackStep();
  };

  return (
    <ResourceRequestWizardProvider
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      resourceRequestId={createdResourceId}
    >
      <ResourceRequestWizardStepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isResourceRequestCreated={!!createdResourceId}
      />
      <Grid item xs={9}>
        <StyledStepperContentWrapper>
          {activeStep === ResourceRequestWizardStep.positionInformation && (
            <ResourcePositionInformationForm
              onSubmit={positionInformationSubmit}
              isSubmitting={addResourceLoading || updateResourceLoading}
              activeStep={activeStep}
              initFormValues={{
                position: formResourceRequest?.position || '',
                start: formResourceRequest?.start || '',
                description: formResourceRequest?.description || '',
                client: formResourceRequest?.client || '',
                location: formResourceRequest?.location || '',
              }}
            />
          )}
          {activeStep === ResourceRequestWizardStep.candidateRequirements && (
            <ResourceCandidateRequirement
              activeStep={activeStep}
              handleBackStep={handleBackStep}
              handleNextStep={handleNextStep}
            />
          )}
          {activeStep === ResourceRequestWizardStep.budgetAndAllocation && (
            <ResourceBudgetAndAllocationForm
              onSubmit={budgetAndAllocationSubmit}
              isSubmitting={updateResourceLoading}
              handleBackStep={budgetAndAllocationBackStep}
              activeStep={activeStep}
              initFormValues={{
                salaryRangeMax: formResourceRequest?.salaryRangeMax || '',
                salaryRangeMin: formResourceRequest?.salaryRangeMin || '',
                billingType: formResourceRequest?.billingType || '',
              }}
            />
          )}
        </StyledStepperContentWrapper>
      </Grid>
    </ResourceRequestWizardProvider>
  );
}
