import React, { useState } from 'react';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { Box, DialogContent, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  DocumentData,
  useUpdateDocumentMutation,
} from 'store/documents/documents';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface EditDocumentFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  document: DocumentData;
}

function EditDocumentForm({
  setIsOpen,
  document,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: EditDocumentFormProps) {
  const { t } = useTranslation();
  const [notes, setNotes] = useState(document.notes);
  const [updateDocument, { isLoading }] = useUpdateDocumentMutation();

  const handleUpdateDocument = async () => {
    try {
      await updateDocument({
        id: document.id,
        notes,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogHeader
        title={t('employees.document_edit')}
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        <form onSubmit={handleUpdateDocument}>
          <Box display="flex" flexDirection="column" gap={3} py={3}>
            <TextField
              fullWidth
              id="description"
              label={t('label.description')}
              name="description"
              onChange={(event) => setNotes(event.target.value)}
              value={notes}
            />
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default EditDocumentForm;
