/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface TransactionSelectProps {
  selectId: string;
  value: string;
  onChangeFn: (event: SelectChangeEvent) => void;
  placeholder?: string;
  selectOptions: {
    id: string | number;
    description: string;
  }[];
  fullWidth?: boolean;
  notChosen?: boolean;
}

function TransactionSelect({
  selectId,
  value,
  onChangeFn,
  placeholder = '',
  selectOptions,
  fullWidth = false,
  notChosen = false,
}: TransactionSelectProps) {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth={fullWidth}>
      <Select
        displayEmpty
        id={selectId}
        value={value}
        onChange={onChangeFn}
        sx={{ bgcolor: notChosen ? 'status.missingFile.light' : 'transparent' }}
      >
        {placeholder.length > 0 && (
          <MenuItem value="">
            <em>{t(placeholder)}</em>
          </MenuItem>
        )}
        {selectOptions.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {t(item.description)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default TransactionSelect;
