import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import usePagination from 'hooks/usePagination';
import CustomPagination from 'common/CustomPagination/CustomPagination';
import { Grid, Stack, Button } from '@mui/material';
import { useGetBudgetsQuery } from 'store/budget/budget';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import { useGetBudgetGroupsQuery } from 'store/budgetGroups/budgetGroups';
import BudgetGroupAccordion from 'pages/Budget/BudgetGroupAccordion/BudgetGroupAccordion';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import createApiIri from 'helpers/createApiIri';
import BudgetTile from './BudgetTile/BudgetTile';
import BudgetAutocomplete from './BudgetAutocomplete/BudgetAutocomplete';

interface BudgetListProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenBudget: (groupId: string, isOpen: boolean) => void;
}

export default function BudgetList({
  setIsOpen,
  setIsOpenBudget,
}: BudgetListProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchPhrase, setSearchPhrase] = useState('');
  const { page, itemsPerPage, handleOnPageChange, handleOnItemsPerPageChange } =
    usePagination({ searchParams, setSearchParams });

  const {
    data: budgetData,
    isFetching: budgetFetching,
    isError: budgetError,
  } = useGetBudgetsQuery({
    page,
    itemsPerPage,
  });

  const {
    data: budgetGroups,
    isLoading: budgetGroupsFetching,
    isError: budgetGroupsError,
  } = useGetBudgetGroupsQuery({});

  const { data: employees = [] } = useGetEmployeesPreview({ isActive: true });

  const filteredBudgets = budgetData?.['hydra:member']
    ? budgetData?.['hydra:member'].filter((budget) =>
        budget.name.toLowerCase().includes(searchPhrase.toLowerCase()),
      )
    : [];

  const budgetDataToDisplay = searchPhrase
    ? filteredBudgets
    : budgetData?.['hydra:member'];

  const groups = [
    ...new Set(
      budgetGroups?.map((group) => ({
        name: group.name,
        id: group.id,
        '@id': group['@id'],
      })),
    ),
  ];

  const getBudgetByGroup = (id: string) => {
    const budgetWithGroup = budgetDataToDisplay?.filter(
      (group) => group.budgetGroup,
    );

    return (
      budgetWithGroup?.filter(
        (budget) => budget?.budgetGroup === createApiIri('budget-groups', id),
      ) || []
    );
  };

  const budgetsWithoutGroup = budgetDataToDisplay?.filter(
    (group) => !group.budgetGroup,
  );

  return (
    <ContentSuspense
      isLoading={budgetFetching || budgetGroupsFetching}
      isError={budgetError || budgetGroupsError}
    >
      <Grid container spacing={2} px={2}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" />
          <BudgetAutocomplete
            setSearchPhrase={setSearchPhrase}
            budgetData={budgetData?.['hydra:member']}
            searchPhrase={searchPhrase}
          />
        </Grid>
        {budgetsWithoutGroup &&
          budgetsWithoutGroup.map((budget) => (
            <Grid item xs={12} md={3} key={budget.id}>
              <BudgetTile budget={budget} employees={employees} />
            </Grid>
          ))}
      </Grid>
      <Grid container spacing={2} mt={2}>
        {groups.map((group) => (
          <Grid item xs={12} key={group.id}>
            <BudgetGroupAccordion
              group={group}
              employees={employees}
              budgets={getBudgetByGroup(group.id)}
              setIsOpenBudget={setIsOpenBudget}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ width: '100%' }}
            endIcon={<AddCircleOutlineIcon />}
            onClick={() => setIsOpen(true)}
          >
            {t('budget.add_budget_category')}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        {budgetData?.['hydra:view']?.['hydra:last'] && (
          <CustomPagination
            apiData={budgetData}
            page={page}
            itemsPerPage={itemsPerPage}
            handleOnPageChange={handleOnPageChange}
            handleOnItemsPerPageChange={handleOnItemsPerPageChange}
          />
        )}
      </Grid>
    </ContentSuspense>
  );
}
