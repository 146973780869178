import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import { useGetCostsQuery } from 'store/costs/costs';
import Loader from 'common/Loader';
import Error from 'common/Error';
import PaymentDueList from 'components/PaymentDueList/PaymentDueList';
import { CostStatuses } from 'components/CostList/enums';

function PaymentDue() {
  const { t } = useTranslation();
  const {
    data: contractors = [],
    isLoading: contractorsFetching,
    isError: contractorsFetchError,
    isSuccess: contractorsFetched,
  } = useGetCostsQuery({});
  const cyclicContractors = useMemo(
    () =>
      contractors.filter(
        (contractor) =>
          contractor.cyclic && contractor.status === CostStatuses.active,
      ),
    [contractors],
  );

  return (
    <Grid item xs={10} bgcolor="background.list">
      <NavBarContainer>
        <NavBarTitle>{t('payments.header')}</NavBarTitle>
      </NavBarContainer>
      {contractorsFetching && <Loader />}
      {contractorsFetchError && <Error />}
      {contractorsFetched && <PaymentDueList contractors={cyclicContractors} />}
    </Grid>
  );
}

export default PaymentDue;
