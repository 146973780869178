import React, { useMemo, useState, useCallback } from 'react';
import { Tag } from 'store/tags/interfaces';
import { CustomError } from 'store/api';
import { useAddTagMutation, useDeleteTagMutation } from 'store/tags/tags';
import { useGetResourceRequestQuery } from 'store/resourceRequests/resourceRequests';
import {
  ResourceRequestWizardContext,
  ResourceRequestWizardProviderProps,
} from './ResourceRequestWizardContext';

export default function ResourceRequestWizardProvider({
  children,
  resourceRequestId,
  setErrorMessage,
  setIsError,
}: ResourceRequestWizardProviderProps) {
  const { data: resourceRequest } = useGetResourceRequestQuery(
    resourceRequestId as string,
    {
      skip: !resourceRequestId,
    },
  );

  const [resourceRequestTags, setResourceRequestTags] = useState<Tag[]>(
    resourceRequest?.tags || [],
  );
  const [addTag] = useAddTagMutation();
  const [deleteTag] = useDeleteTagMutation();

  const handleAddTag = useCallback(
    async (tagDefinition: string) => {
      try {
        if (!resourceRequestId) {
          return;
        }

        const payload = {
          tagDefinition,
          relationName: 'resource-request',
          relationId: resourceRequestId,
        };
        const createdTag = await addTag(payload).unwrap();
        setResourceRequestTags((prev) => [...prev, createdTag]);
      } catch (error) {
        if ('data' in (error as CustomError)) {
          setErrorMessage((error as CustomError).data['hydra:description']);
        }
        setIsError(true);
      }
    },
    [addTag, resourceRequestId, setErrorMessage, setIsError],
  );

  const handleDeleteTag = useCallback(
    async (tagId: string) => {
      try {
        await deleteTag(tagId).unwrap();
        setResourceRequestTags((prev) =>
          prev.filter((tag) => tag.id !== tagId),
        );
      } catch (error) {
        if ('data' in (error as CustomError)) {
          setErrorMessage((error as CustomError).data['hydra:description']);
        }
        setIsError(true);
      }
    },
    [deleteTag, setErrorMessage, setIsError],
  );

  const contextValue = useMemo(
    () => ({
      resourceRequestTags,
      handleDeleteTag,
      handleAddTag,
    }),
    [resourceRequestTags, handleDeleteTag, handleAddTag],
  );

  return (
    <ResourceRequestWizardContext.Provider value={contextValue}>
      {children}
    </ResourceRequestWizardContext.Provider>
  );
}
