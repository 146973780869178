import React from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAccept: React.MouseEventHandler<HTMLButtonElement>;
  confirmationText?: string;
}

function ConfirmationDialog({
  isOpen,
  setIsOpen,
  handleAccept,
  confirmationText,
}: ConfirmationDialogProps) {
  const { t } = useTranslation();

  const confirmationMessage = confirmationText ?? t('label.confirm_delete');

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogContent>
        <Typography align="center" variant="h5">
          {confirmationMessage}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
          mt={2}
        >
          <Button onClick={handleAccept} variant="contained">
            {t('label.yes')}
          </Button>
          <Button
            color="error"
            onClick={() => setIsOpen(false)}
            variant="contained"
          >
            {t('label.cancel')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationDialog;
