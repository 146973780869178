import React, { useEffect, useState, useRef } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import EditButton from 'common/EditButton/EditButton';

interface InvoiceNameProps {
  name: string;
  setName: (name: string) => void;
}

function InvoiceName({ name, setName }: InvoiceNameProps) {
  const inputName = useRef<HTMLInputElement>(null);
  const [isNameEditing, setIsNameEditing] = useState(false);

  useEffect(() => {
    if (isNameEditing && inputName.current) {
      inputName.current.focus();
    }
  }, [isNameEditing]);

  return (
    <Stack
      px={6}
      direction="row"
      alignItems="center"
      spacing={1}
      height="4.4rem"
      overflow="hidden"
      width="100%"
    >
      {isNameEditing ? (
        <TextField
          inputRef={inputName}
          required
          data-testid="name-input"
          variant="standard"
          id="invoice-name"
          onChange={(event) => setName(event.target.value)}
          onBlur={() => setIsNameEditing(false)}
          value={name}
          sx={{
            width: '32rem',
            '& input': {
              fontSize: '2.4rem',
              fontWeight: '700',
              lineHeight: '3.2rem',
            },
          }}
        />
      ) : (
        <Typography variant="h5" data-testid="name-typography">
          {name}
        </Typography>
      )}
      {!isNameEditing && (
        <EditButton
          v2
          onClick={() => setIsNameEditing(true)}
          testId="name-switch-to-edit-mode"
        />
      )}
    </Stack>
  );
}

export default InvoiceName;
