import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import useCurrencyFormat from 'hooks/useCurrencyFormat';

interface PriceSummaryProps {
  amount: number;
  label: string;
}

export default function PriceSummary({ amount, label }: PriceSummaryProps) {
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      width="100%"
    >
      <Typography>{t(label)}</Typography>
      <Stack alignItems="center">
        <Typography fontWeight={700}>{formatAmount(0)}</Typography>
        {!!amount && (
          <Typography variant="caption" color="text.secondary">
            <del>{formatAmount(amount)}</del>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
