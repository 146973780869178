import React, { useMemo, useState, useCallback, useEffect } from 'react';
import defaultValues from 'components/SubscriptionClientForm/defaultValues';
import { SubscriptionContext } from './Subscription.context';
import getStorageSubscription, {
  updateStorageSubscription,
} from '../storageSubscription';

interface SubscriptionProviderProps {
  children: React.ReactNode;
  handleNextStep: () => void;
}

export default function SubscriptionProvider({
  children,
  handleNextStep,
}: SubscriptionProviderProps) {
  const subscriptionFromLocalStorage = getStorageSubscription();
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>(
    subscriptionFromLocalStorage?.selectedFeatures || [],
  );
  const [userCount, setUserCount] = useState(
    subscriptionFromLocalStorage?.userCount || 0,
  );
  const [clientForm, setClientForm] = useState(
    subscriptionFromLocalStorage?.clientForm || defaultValues,
  );

  const toggleFeature = useCallback((feature: string) => {
    setSelectedFeatures((prevFeatures) => {
      if (prevFeatures.includes(feature)) {
        return prevFeatures.filter((featureItem) => featureItem !== feature);
      }

      return [...prevFeatures, feature];
    });
  }, []);

  const value = useMemo(
    () => ({
      selectedFeatures,
      userCount,
      clientForm,
      setUserCount,
      toggleFeature,
      setClientForm,
      handleNextStep,
    }),
    [
      selectedFeatures,
      userCount,
      clientForm,
      setUserCount,
      toggleFeature,
      handleNextStep,
      setClientForm,
    ],
  );

  useEffect(() => {
    updateStorageSubscription('selectedFeatures', selectedFeatures);
  }, [selectedFeatures]);

  useEffect(() => {
    updateStorageSubscription('userCount', userCount);
  }, [userCount]);

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}
