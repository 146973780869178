export default function checkNip(nip: string) {
  let sum = 0;
  const sanitizedNip = nip.replace(/[ -]/gi, '');

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  const controlNumber = parseInt(sanitizedNip.substring(9, 10), 10);
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(sanitizedNip.substr(i, 1), 10) * weight[i];
  }

  return sum % 11 === controlNumber;
}
