import queryString from 'query-string';
import store from 'store';

const downloadZipFiles = (fileIds: string[], name: string) => {
  const state = store.getState();
  const { organization } = state.organization;
  const token = localStorage?.token as string;

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  const headers = new Headers();
  headers.append('authorization', `Bearer ${token}`);
  headers.append('accept', 'application/zip');

  if (organization.instance) {
    headers.append('instance', organization.instance);
  }

  const queryParams = queryString.stringify(
    {
      id: fileIds,
    },
    {
      arrayFormat: 'bracket',
    },
  );

  const downloadLink = `${process.env.REACT_APP_API_BASE_URL}/incomes?${queryParams}`;

  fetch(downloadLink, { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);
      anchor.href = objectUrl;
      anchor.download = name;
      anchor.click();

      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(objectUrl);
    });
};

export default downloadZipFiles;
