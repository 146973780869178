import React, { useContext } from 'react';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import { EmployeeContext } from 'pages/PropertyBooking/PropertyBooking';
import useBookingSchedulerContext from 'components/BookProperties/BookingForm/context/useBookingScheduler.context';
import { createBookingId } from 'components/BookProperties/BookingForm/consts';
import roles from 'config/roles/roles';
import useCheckRoles from 'hooks/useCheckRoles';
import { isAfter, isBefore, isEqual } from 'date-fns';
import theme from 'config/material-ui/theme';

const halfTimeIndicatorPercentage = 50;

export default function TimeTableCellComponent({
  startDate,
  endDate,
  ...restProps
}: WeekView.TimeTableCellProps) {
  const {
    addBookingToState,
    bookingsInState,
    setIsDraftAppointmentCreating,
    isDraftAppointmentCreating,
    updateDateEndOfDraftBooking,
  } = useBookingSchedulerContext();
  const selectedEmployee = useContext(EmployeeContext) as string;
  const isPropertyManager = useCheckRoles(roles.properties);
  const isWeekendDay =
    (startDate as Date).getDay() === 0 || (startDate as Date).getDay() === 6;
  const currentTimeIndicatorPositionPercentageValue =
    restProps.currentTimeIndicatorPosition?.replace('%', '') || null;
  const isStartDateAfterCurrentTime = isAfter(startDate as Date, new Date());
  const isEndDateAfterCurrentTime = isAfter(endDate as Date, new Date());

  const shouldTileBeShaded =
    isWeekendDay ||
    !isEndDateAfterCurrentTime ||
    (currentTimeIndicatorPositionPercentageValue &&
      Number(currentTimeIndicatorPositionPercentageValue) >
        halfTimeIndicatorPercentage);

  const isSomeBookingForTileTime = bookingsInState.some(
    (booking) =>
      ((isAfter(booking.endDate, endDate as Date) ||
        isEqual(booking.endDate, endDate as Date)) &&
        isBefore(booking.startDate, startDate as Date)) ||
      isEqual(booking.startDate, startDate as Date),
  );

  const isBookingFromTileAllowed =
    isPropertyManager ||
    (isStartDateAfterCurrentTime && !isSomeBookingForTileTime) ||
    (currentTimeIndicatorPositionPercentageValue &&
      Number(currentTimeIndicatorPositionPercentageValue) <
        halfTimeIndicatorPercentage &&
      !isSomeBookingForTileTime);

  const handleAddDraftBooking = () => {
    if (!isBookingFromTileAllowed) {
      return;
    }
    addBookingToState({
      startDate: startDate as Date,
      endDate: endDate as Date,
      title: 'New booking',
      id: createBookingId,
      employee: selectedEmployee,
    });
  };

  return (
    <WeekView.TimeTableCell
      data-end={endDate}
      data-start={startDate}
      {...restProps}
      style={{
        cursor: isBookingFromTileAllowed ? 'pointer' : 'not-allowed',
        backgroundColor: shouldTileBeShaded
          ? theme.palette.background.other
          : 'inherit',
      }}
      onMouseDown={() => {
        setIsDraftAppointmentCreating(true);
        handleAddDraftBooking();
      }}
      onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = event.target as HTMLDivElement;
        if (!isDraftAppointmentCreating || !target.dataset.end) {
          return;
        }
        updateDateEndOfDraftBooking(target.dataset.end);
      }}
      onMouseUp={() => {
        setIsDraftAppointmentCreating(false);
      }}
    />
  );
}
