const createRangeCalendar = (startDate: Date, endDate: Date) => {
  const range: Date[] = [];

  let i = 0;

  while (
    new Date(startDate.getFullYear(), startDate.getMonth(), -i).getDay() > 0
  ) {
    range.unshift(new Date(startDate.getFullYear(), startDate.getMonth(), -i));
    i += 1;
  }

  let currentDate = startDate;

  while (currentDate <= endDate) {
    range.push(currentDate);
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1,
    );
  }

  let datesTillSunday = currentDate;

  while (datesTillSunday.getDay() !== 1) {
    range.push(datesTillSunday);
    datesTillSunday = new Date(
      datesTillSunday.getFullYear(),
      datesTillSunday.getMonth(),
      datesTillSunday.getDate() + 1,
    );
  }

  return [...new Set(range)];
};

export default createRangeCalendar;
