import React from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import { ProjectPreview } from 'store/projects/interfaces';
import { ProjectDataProps } from './interfaces';

function ProjectData({
  projectIri,
  projectPhaseIri,
  isExtended = false,
}: ProjectDataProps) {
  const { data: projectsForDisplayedEmployee = [] } =
    useGetProjectsPreviewQuery({});

  const projectData = projectsForDisplayedEmployee.find(
    (displayedEmployeeProject) =>
      displayedEmployeeProject['@id'] === projectIri,
  );

  const getProjectPhase = (
    project: ProjectPreview,
    phaseIri: string | null,
  ) => {
    if (phaseIri === null || !project?.projectPhases) {
      return null;
    }

    return project.projectPhases.find((phase) => phase['@id'] === phaseIri);
  };

  const getProjectDataText = (
    project: ProjectPreview | undefined,
    phaseIri: string | null,
  ) => {
    if (!project) {
      return '';
    }

    const projectPhase = getProjectPhase(project, phaseIri);
    if (!projectPhase) {
      return project.name;
    }

    if (isExtended) {
      return `${project.name} - ${projectPhase.name}`;
    }

    return _.truncate(`${project.name} - ${projectPhase.name}`, {
      length: 27,
      omission: '...',
    });
  };

  return (
    <Typography variant="bold" color="text.primary">
      {getProjectDataText(projectData, projectPhaseIri)}
    </Typography>
  );
}

export default ProjectData;
