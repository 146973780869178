import React from 'react';
import { createSvgIcon } from '@mui/material';

const SearchIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.58366 3.33341C6.13188 3.33341 3.33366 6.13164 3.33366 9.58342C3.33366 13.0352 6.13188 15.8334 9.58366 15.8334C11.3098 15.8334 12.8711 15.1348 14.0031 14.0028C15.135 12.8709 15.8337 11.3095 15.8337 9.58342C15.8337 6.13164 13.0354 3.33341 9.58366 3.33341ZM1.66699 9.58342C1.66699 5.21116 5.2114 1.66675 9.58366 1.66675C13.9559 1.66675 17.5003 5.21116 17.5003 9.58342C17.5003 11.469 16.8403 13.2017 15.7399 14.5611L18.0896 16.9108C18.415 17.2363 18.415 17.7639 18.0896 18.0893C17.7641 18.4148 17.2365 18.4148 16.9111 18.0893L14.5614 15.7397C13.2019 16.84 11.4693 17.5001 9.58366 17.5001C5.2114 17.5001 1.66699 13.9557 1.66699 9.58342Z"
      fill="currentColor"
    />
  </svg>,
  'SearchIcon',
);

export default SearchIcon;
