import React, { useState } from 'react';
import { isBefore, isAfter, endOfDay } from 'date-fns';
import {
  Avatar,
  Box,
  Dialog,
  IconButton,
  TableRow,
  Typography,
} from '@mui/material';
import {
  useDeleteHolidayMutation,
  useUpdateHolidayMutation,
} from 'store/holidays/holidays';
import { useTranslation } from 'react-i18next';
import EditButton from 'common/EditButton/EditButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { getHolidayIcon, getHolidayName } from 'helpers/getHolidayData';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import StyledCell from 'components/HolidaysList/Result/TableCell.styled';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { Holiday } from 'store/holidays/interfaces';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';
import getHolidayFreeTime from 'helpers/getHolidayFreeTime';
import HolidayForm from 'components/HolidayForm/HolidayForm';
import { FormPayload } from 'components/HolidayForm/interfaces';
import dateFormat from 'dateformat';
import { FormTypes } from 'enums';
import { useAppDispatch } from 'store/hooks';
import { setSuccessStatus, setErrorCatch } from 'store/status/actions';
import holidaysSettings from 'helpers/holidaysSettings';

export interface ResultProps {
  holiday: Holiday;
}

function Result({ holiday }: ResultProps) {
  const [deleteHoliday] = useDeleteHolidayMutation();
  const [editHoliday, { isLoading: editHolidayLoading }] =
    useUpdateHolidayMutation();
  const dispatch = useAppDispatch();
  const { selectedEmployee } = useGetSelectedEmployeePreview({
    employeeIri: holiday.employee,
  });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const { t } = useTranslation();
  const isManager = useCheckRoles(roles.holidaysManager);
  const currentDate = new Date();

  const checkIfHolidayIsActive = (holidayData: Holiday) => {
    const holidayDateFrom = new Date(holidayData.dateFrom);
    const holidayDateTo = endOfDay(new Date(holidayData.dateTo));

    return (
      !isAfter(currentDate, holidayDateTo) &&
      !isBefore(currentDate, holidayDateFrom)
    );
  };

  const handleDeleteHoliday = async (id: number) => {
    try {
      await deleteHoliday(id).unwrap();
      dispatch(setSuccessStatus(true));
      setIsConfirmationDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  const handleEditHoliday = async (data: FormPayload) => {
    const { dateFrom, dateTo, hours, ...restPayload } = data;
    const getFormHours = () => {
      if (!hours) return null;

      return +hours >= holidaysSettings.minLimitHolidayHours &&
        +hours <= holidaysSettings.maxLimitHolidayHours
        ? +hours
        : null;
    };

    try {
      await editHoliday({
        id: holiday.id,
        dateFrom: dateFormat(dateFrom, 'yyyy-mm-dd'),
        dateTo: dateFormat(dateTo, 'yyyy-mm-dd'),
        hours: getFormHours(),
        ...restPayload,
      }).unwrap();
      dispatch(setSuccessStatus(true));
      setIsEditDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  return (
    <>
      <TableRow>
        <StyledCell isActive={checkIfHolidayIsActive(holiday)}>
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar src={selectedEmployee?.avatarUrl} />
            <Typography variant="body2">
              {holiday.employeeReversedFullname}
            </Typography>
          </Box>
        </StyledCell>
        <StyledCell isActive={checkIfHolidayIsActive(holiday)}>
          <Box display="flex" alignItems="center" columnGap={1}>
            {getHolidayIcon(holiday.type)}
            <Typography variant="body2">
              {t(getHolidayName(holiday.type))}
            </Typography>
          </Box>
        </StyledCell>
        <StyledCell isActive={checkIfHolidayIsActive(holiday)}>
          <Typography variant="body2">{getHolidayFreeTime(holiday)}</Typography>
        </StyledCell>
        {isManager && (
          <StyledCell align="right" isActive={checkIfHolidayIsActive(holiday)}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              columnGap={1}
            >
              <EditButton
                onClick={() => {
                  setIsEditDialogOpen(!isEditDialogOpen);
                }}
              />
              <IconButton
                color="error"
                onClick={() => setIsConfirmationDialogOpen(true)}
              >
                <DeleteIcon fontSize="large" />
              </IconButton>
            </Box>
          </StyledCell>
        )}
      </TableRow>
      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(!isEditDialogOpen)}
        fullWidth
      >
        <HolidayForm
          onSubmit={handleEditHoliday}
          isSubmitting={editHolidayLoading}
          formType={FormTypes.edit}
          setIsDialogOpen={setIsEditDialogOpen}
          initialValues={{
            type: holiday.type,
            employee: selectedEmployee['@id'],
            description: holiday.description,
            dateFrom: new Date(holiday.dateFrom),
            dateTo: new Date(holiday.dateTo),
            hours: holiday.hours ? holiday.hours.toString() : '',
          }}
        />
      </Dialog>
      <ConfirmationDialog
        handleAccept={() => handleDeleteHoliday(holiday.id)}
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
      />
    </>
  );
}

export default Result;
