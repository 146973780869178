import React from 'react';
import { Agreement } from 'components/AgreementsList/AgreementsList';
import { differenceInDays, endOfDay, startOfDay } from 'date-fns';
import Badge from 'common/Badge/Badge';
import IconPopover from 'common/IconPopover/IconPopover';
import { useTranslation } from 'react-i18next';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import AgreementStatuses from 'components/AgreementsList/Agreement/Status/enums';

interface AgreementBadgeProps {
  agreement: Agreement;
  employeeId: number;
}

function AgreementBadge({ agreement, employeeId }: AgreementBadgeProps) {
  const { t } = useTranslation();

  if (!agreement) {
    return (
      <Badge
        bgcolor="status.notActive.light"
        iconColor="status.notActive.main"
        border="0.1rem solid"
        borderColor="status.notActive.main"
        navigateTo={`/employees/${employeeId}`}
        tab="4"
      >
        <IconPopover
          Icon={ReportGmailerrorredOutlinedIcon}
          content={t('agreements.finished')}
        />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.active && !agreement.files) {
    return (
      <Badge
        bgcolor="status.missingFile.light"
        iconColor="status.missingFile.main"
        navigateTo={`/employees/${employeeId}`}
        tab="4"
      >
        <IconPopover
          Icon={UploadFileOutlinedIcon}
          content={t('agreements.upload_missing_file')}
        />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.active && agreement.files) {
    return (
      <Badge
        bgcolor="status.active.light"
        iconColor="status.active.main"
        navigateTo={`/employees/${employeeId}`}
        tab="4"
      >
        <IconPopover
          Icon={TaskAltOutlinedIcon}
          content={t('agreements.active')}
        />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.finishing) {
    const startOfCurrentDay = startOfDay(new Date());
    const agreementEndDate = new Date(agreement.dateTo as string);
    const daysLeft = differenceInDays(agreementEndDate, startOfCurrentDay);

    return (
      <Badge
        bgcolor="status.finishing.light"
        iconColor="status.finishing.main"
        navigateTo={`/employees/${employeeId}`}
        tab="4"
      >
        <IconPopover
          Icon={UpdateOutlinedIcon}
          content={t('agreements.finishing', { count: daysLeft })}
        />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.notStarted) {
    return (
      <Badge
        bgcolor="status.notStarted.light"
        iconColor="status.notStarted.main"
        navigateTo={`/employees/${employeeId}`}
        tab="4"
      >
        <IconPopover
          Icon={HistoryOutlinedIcon}
          content={t('agreements.agreement_not_started')}
        />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.freshlyFinished) {
    const endOfCurrentDay = endOfDay(new Date());
    const agreementEndDate = new Date(agreement.dateTo as string);
    const daysLeft = differenceInDays(endOfCurrentDay, agreementEndDate);

    return (
      <Badge
        navigateTo={`/employees/${employeeId}`}
        bgcolor="status.freshlyFinished.light"
        iconColor="status.freshlyFinished.main"
        tab="4"
      >
        <IconPopover
          Icon={ManageHistoryIcon}
          content={t('agreements.freshly_finished', { count: daysLeft })}
        />
      </Badge>
    );
  }

  return null;
}

export default AgreementBadge;
