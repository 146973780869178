export const vatRates = [
  {
    label: '23%',
    value: '23',
  },
  {
    label: '8%',
    value: '8',
  },
  {
    label: '5%',
    value: '5',
  },
  {
    label: '3%',
    value: '3',
  },
  {
    label: 'np.',
    value: 'np.',
  },
  {
    label: 'zw.',
    value: 'zw.',
  },
];

export const units = [
  { id: 'hours', description: 'invoices.hours' },
  { id: 'days', description: 'invoices.days' },
  { id: 'pieces', description: 'invoices.pieces' },
];
