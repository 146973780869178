import React, { useMemo, useState, useCallback } from 'react';
import { isAfter, isSameDay } from 'date-fns';
import { ChangeSet } from '@devexpress/dx-react-scheduler';
import { createBookingId } from 'components/BookProperties/BookingForm/consts';
import { PropertyBooking } from 'store/propertyBooking/interfaces';
import { BookingType } from 'components/Properties/enums';
import { Property } from 'store/properties/interfaces';
import {
  BookingSchedulerContext,
  BookingData,
} from './BookingScheduler.context';

export interface BookingSchedulerProviderProps {
  children: React.ReactNode;
  bookingsForProperty: PropertyBooking[];
  property: Property;
  bookingType: BookingType;
}

export default function BookingSchedulerProvider({
  children,
  bookingsForProperty,
  property,
  bookingType,
}: BookingSchedulerProviderProps) {
  const initialBookings = bookingsForProperty.map((booking) => ({
    startDate: new Date(booking.startDate),
    endDate: new Date(booking.dateTo as string),
    title: booking.property.name,
    id: booking.id.toString(),
    employee: booking.employee,
  }));

  const [bookingsInState, setBookingsInState] =
    useState<BookingData[]>(initialBookings);
  // state for feature booking on adding resize
  const [isDraftAppointmentCreating, setIsDraftAppointmentCreating] =
    useState(false);

  const removeBookingFromState = useCallback((id: string) => {
    setBookingsInState((prev) =>
      prev.filter((appointment) => appointment.id !== id),
    );
  }, []);

  const addBookingToState = useCallback(
    (booking: BookingData) => {
      if (
        bookingsInState.some(
          (bookingItem) => bookingItem.id === createBookingId,
        ) &&
        booking.id === createBookingId
      ) {
        return;
      }

      setBookingsInState((prev) => [...prev, booking]);
    },
    [bookingsInState],
  );

  const onCommitChanges = ({ changed }: ChangeSet) => {
    if (!changed) {
      return;
    }

    setBookingsInState((prev) => {
      const changedAppointment = prev.find(
        (appointment) => changed[appointment.id],
      );
      if (changedAppointment) {
        const updatedAppointment = {
          ...changedAppointment,
          ...changed[changedAppointment.id],
        };

        const updatedAppointments = prev.map((appointment) =>
          appointment.id === updatedAppointment.id
            ? updatedAppointment
            : appointment,
        );
        return updatedAppointments;
      }
      return prev;
    });
  };

  const updateDateEndOfDraftBooking = useCallback(
    (newEndDate: Date | string) => {
      const isEndDateMayChange = (
        appointmentStartDate: Date | string,
      ) => {
        if (bookingType === BookingType.minutes) {
          return (
            isAfter(newEndDate, appointmentStartDate) &&
            isSameDay(newEndDate, appointmentStartDate)
          );
        }

        return isAfter(newEndDate, appointmentStartDate);
      };

      setBookingsInState((prev) =>
        prev.map((appointment) =>
          appointment.id === createBookingId
            ? {
                ...appointment,
                endDate: isEndDateMayChange(appointment.startDate)
                  ? newEndDate
                  : appointment.endDate,
              }
            : appointment,
        ),
      );
    },
    [bookingType],
  );

  const contextValue = useMemo(
    () => ({
      bookingsInState,
      property,
      removeBookingFromState,
      addBookingToState,
      onCommitChanges,
      updateDateEndOfDraftBooking,
      isDraftAppointmentCreating,
      setIsDraftAppointmentCreating,
    }),
    [
      addBookingToState,
      bookingsInState,
      property,
      removeBookingFromState,
      updateDateEndOfDraftBooking,
      isDraftAppointmentCreating,
    ],
  );

  return (
    <BookingSchedulerContext.Provider value={contextValue}>
      {children}
    </BookingSchedulerContext.Provider>
  );
}
