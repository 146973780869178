import { createContext } from 'react';
import { Project } from 'store/projects/interfaces';

export interface EditProjectFormContextProps {
  project: Project;
}

export const EditProjectFormContext = createContext<
  EditProjectFormContextProps | undefined
>(undefined);
