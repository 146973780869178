import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Typography, Stack, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import { NoteFormProps, FormPayload } from './interfaces';
import schema from './NotesForm.schema';

const validate = zodResolver(schema);

export default function NotesForm({
  initValues = { note: '' },
  onSubmit,
  isSubmitting,
}: NoteFormProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initValues,
  });

  const handleOnSubmit = handleSubmit((data) => {
    onSubmit(data);
    reset({ note: data.note });
  });

  const isFormValuesChanged = !!Object.keys(dirtyFields).length;

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleOnSubmit}
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      pt={3}
    >
      <ControlTextField
        control={control}
        label=""
        errors={errors}
        name="note"
        multiline
        minRows={4}
      />
      {isFormValuesChanged && (
        <Stack justifyContent="flex-end" pt={3}>
          <LoadingButton
            loading={isSubmitting}
            endIcon={<CheckIcon />}
            type="submit"
            variant="contained"
          >
            <Typography variant="buttonMedium">
              {t('button.save_changes')}
            </Typography>
          </LoadingButton>
        </Stack>
      )}
    </Box>
  );
}
