import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CompanyInfoProps {
  name: string;
  street?: string;
  postCode?: string;
  city?: string;
  vatNumber: string | null;
  isSeller?: boolean;
}

export default function CompanyInfo({
  name,
  street,
  postCode,
  city,
  vatNumber,
  isSeller = true,
}: CompanyInfoProps) {
  const { t } = useTranslation();
  const header = isSeller ? t('invoices.seller') : t('invoices.buyer');

  return (
    <Grid item xs={4} display="flex" flexDirection="column" rowGap={3}>
      <Typography variant="subtitle1">{header}</Typography>
      <Stack>
        <Typography fontWeight={700} variant="body1">
          {name}
        </Typography>
        <Typography color="text.secondary" variant="body2" mt={1}>
          {street},
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {postCode} {city}
        </Typography>
      </Stack>
      <Typography color="text.secondary" variant="body2">
        NIP:&nbsp;
        <Typography color="text.primary" component="span" fontWeight={700}>
          {vatNumber}
        </Typography>
      </Typography>
    </Grid>
  );
}
