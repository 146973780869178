import React from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, Stack, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { Client, useGetClientsQuery } from 'store/clients/clients';
import ControlTextField from 'common/ControlTextField/ControlTextField';
import ControlSelect from 'common/ControlSelect/ControlSelect';
import StepperStepButtons from 'common/StepperStepButtons/StepperStepButtons';
import {
  ResourcePositionInformationFormProps,
  FormPayload,
} from './interfaces';
import schema from './ResourcePositionInformationForm.schema';

const validate = zodResolver(schema);

export default function ResourcePositionInformationForm({
  onSubmit,
  activeStep,
  initFormValues,
}: ResourcePositionInformationFormProps) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormPayload>({
    resolver: validate,
    mode: 'onChange',
    defaultValues: initFormValues,
  });

  const { t } = useTranslation();
  const handleOnSubmit = handleSubmit(onSubmit);
  const { position } = watch();

  const disableSubmission = () => !!Object.keys(errors).length || !position;

  const {
    data: clients = [] as Client[],
    isLoading: clientsLoading,
    isError: clientsError,
  } = useGetClientsQuery({ properties: ['id', 'name'] });

  if (clientsLoading) {
    return <Loader />;
  }

  if (clientsError) {
    return <Error />;
  }

  const selectClientOptions = clients?.map((client) => ({
    value: client['@id'],
    label: client.name,
  }));

  return (
    <Stack justifyContent="space-between" height="100%" p={6}>
      <Stack spacing={6}>
        <Typography variant="h5">
          {t('human_resources.position_information')}
        </Typography>
        <Divider />
        <form noValidate>
          <FormControl
            fullWidth
            sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}
          >
            <Stack direction="row" spacing={3}>
              <ControlTextField
                control={control}
                errors={errors}
                label="label.position_title"
                name="position"
                required
              />
              <ControlTextField
                control={control}
                errors={errors}
                label="label.start_date"
                name="start"
              />
            </Stack>
            <Stack direction="row" spacing={3}>
              <ControlSelect
                control={control}
                errors={errors}
                label="label.client"
                name="client"
                selectOptions={selectClientOptions}
                optional
              />
              <ControlTextField
                control={control}
                errors={errors}
                label="label.location"
                name="location"
              />
            </Stack>
            <ControlTextField
              control={control}
              errors={errors}
              label="label.position_description"
              name="description"
              minRows={4}
              multiline
            />
          </FormControl>
        </form>
      </Stack>
      <StepperStepButtons
        nextStepDisabled={disableSubmission()}
        handleNextStep={handleOnSubmit}
        activeStep={activeStep}
      />
    </Stack>
  );
}
