import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HolidaysContext, HolidaysContextProps } from './HolidaysContext';

export default function useHolidaysContext() {
  const { t } = useTranslation();
  if (!HolidaysContext) {
    throw new Error(t('errors.use_context_null', { contextName: 'Holidays' }));
    }

  return useContext(HolidaysContext as React.Context<HolidaysContextProps>);
}
