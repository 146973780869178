import React, { useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Grid, Typography, Stack, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import statuses from 'components/Properties/PropertyFrom/statuses';
import { bookingTypes } from 'components/Properties/PropertiesAccordion/propertyTypes';
import { useDeleteParentFromPropertyMutation } from 'store/properties/properties';
import { Property } from 'store/properties/interfaces';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';

export interface ChildDataProps extends StatusProps {
  property: Property;
}

export default function ChildData({
  property,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: ChildDataProps) {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteParentFromProperty] = useDeleteParentFromPropertyMutation();

  const getStatusTranslation = () => {
    const status = statuses.find((item) => item.value === property?.status);

    if (!status) {
      return '';
    }

    return status.label;
  };

  const getBookingTypeTranslation = () => {
    const type = bookingTypes.find(
      (item) => item.value === property?.bookingType,
    );

    if (!type) {
      return '';
    }

    return type.description;
  };

  const handleDeleteParent = async () => {
    try {
      if (!property.parent) {
        return;
      }

      await deleteParentFromProperty({
        id: String(property.id),
        parent: property.parent,
      }).unwrap();
      setIsDialogOpen(false);
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Typography variant="bold">{property?.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">
            {t(getBookingTypeTranslation())}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">{property?.serialNumber}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="flex-end"
          >
            <Typography variant="body1">{t(getStatusTranslation())}</Typography>
            <IconButton onClick={() => setIsDialogOpen(true)}>
              <Delete color="error" fontSize="large" />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        handleAccept={handleDeleteParent}
      />
    </>
  );
}
