import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import { useGetGroupsQuery } from 'store/responsibilityGroups/responsibilityGroups';
import Loader from 'common/Loader';
import Error from 'common/Error';
import ResponsibilityGroup from 'components/ResponsibilityGroup/ResponsibilityGroup';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';

export default function Responsibilities() {
  const { t } = useTranslation();
  const {
    data: groups = [],
    isLoading: groupsFetching,
    isError: groupsFetchError,
    isSuccess: groupsFetched,
  } = useGetGroupsQuery();
  const {
    isLoading: employeesFetching,
    isError: employeesFetchError,
    isSuccess: employeesFetched,
  } = useGetEmployeesPreview();

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('responsibilities.header')}</NavBarTitle>
      </NavBarContainer>
      {(groupsFetching || employeesFetching) && <Loader />}
      {(groupsFetchError || employeesFetchError) && <Error />}
      {groupsFetched && employeesFetched && (
        <Grid container p={2} rowSpacing={3}>
          {groups.map((group) => (
            <ResponsibilityGroup key={group.id} group={group} />
          ))}
        </Grid>
      )}
    </Grid>
  );
}
