import React from 'react';
import { Typography } from '@mui/material';
import { Cost, useGetCostsQuery } from 'store/costs/costs';

export interface DefaultCostValueProps {
  costIri: string;
}

const emptyCost = {} as Cost;

export default function DefaultCostValue({ costIri }: DefaultCostValueProps) {
  const { cost } = useGetCostsQuery(
    {
      properties: ['name', 'id'],
    },
    {
      selectFromResult: ({ data }) => ({
        cost: data?.find((item) => item['@id'] === costIri) ?? emptyCost,
      }),
    },
  );
  return <Typography variant="body2">{cost?.name}</Typography>;
}
