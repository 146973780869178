import { StatusProps } from 'hoc/Status/Status';

export interface BudgetProps extends StatusProps {}

export interface BudgetMenu {
  name: string;
  action: string;
}

export enum BudgetsTabValue {
  list = '0',
  calendar = '1',
}

export enum BudgetMenuAction {
  addBudget = 'addBudget',
  addBudgetCategory = 'addBudgetCategory',
}

export const BudgetMenuOptions = [
  { name: 'budget.budget', action: BudgetMenuAction.addBudget },
  {
    name: 'budget.budget_category',
    action: BudgetMenuAction.addBudgetCategory,
  },
];
