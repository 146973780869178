import React, { useState } from 'react';
import { Avatar, Box, Divider, Grid, Tab, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useGetEmployeeQuery } from 'store/employee/employees';
import { Employee } from 'store/employee/interfaces';
import { StatusProps } from 'hoc/Status/Status';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { TabContext, TabPanel } from '@mui/lab';
import StyledTabList from 'config/material-ui/styled/TabList.styled';
import PersonalInfo from 'components/EditEmployeeForm/PersonalInfo';
import ContactData from 'components/EditEmployeeForm/ContactData';
import Permissions from 'components/EditEmployeeForm/Permissions';
import { useTranslation } from 'react-i18next';
import AnnualLeave from 'components/EditEmployeeForm/AnnualLeave/AnnualLeave';
import Agreements from 'components/EditEmployeeForm/Agreements/Agreements';
import { LocationProps } from 'interfaces';
import Documents from 'components/EditEmployeeForm/Documents/Documents';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useGetDocumentTypesQuery } from 'store/documentTypes/documentTypes';
import getTabs from 'components/EditEmployeeForm/tabs';
import ProjectRates from 'components/EditEmployeeForm/ProjectRates/ProjectRates';
import roles from 'config/roles/roles';
import checkRoles from 'helpers/checkRoles';
import getBgColorForTab from 'helpers/getBgColorForTab';
import Benefits from './Benefits/Benefits';

export type EditEmployeeFormProps = StatusProps;

function EditEmployeeForm() {
  const { id } = useParams<{ id: any }>();
  const { state } = useLocation() as LocationProps;
  const {
    data: employee = {} as Employee,
    isSuccess,
    isLoading,
    isError,
  } = useGetEmployeeQuery(id);
  const [tabValue, setTabValue] = useState(state?.tabValue || '1');
  const listingTabs = ['3', '4', '5', '6', '7'];
  const { t } = useTranslation();
  const organizationRoles = useSelector(
    (appState: RootState) => appState.organization.organization.roles,
  );
  const {
    data: docTypes = [],
    isLoading: docTypesFetching,
    isError: docTypesFetchError,
  } = useGetDocumentTypesQuery(undefined, {
    skip: !checkRoles(roles.agreements, organizationRoles),
  });
  const employeeRelatedDocTypes = docTypes.some(
    (docType) => docType.relationName === 'employee',
  );
  const tabs = getTabs(employeeRelatedDocTypes);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  if (isLoading || docTypesFetching) {
    return <Loader />;
  }

  if (isError || docTypesFetchError) {
    return <Error />;
  }

  return (
    <Grid item xs={10} bgcolor={getBgColorForTab(tabValue, listingTabs)}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        columnGap={3}
        p={3}
      >
        <Avatar
          variant="circular"
          sx={{ width: '8rem', height: '8rem' }}
          src={employee.avatarUrl}
        />
        <Box display="flex" flexDirection="column">
          <Typography variant="h5">
            {`${employee.firstname} ${employee.lastname}`}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {employee.activeAgreement?.position}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <TabContext value={tabValue}>
        <StyledTabList
          onChange={handleTabChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          {tabs.map(
            (tab) =>
              checkRoles(tab.displayRoles, organizationRoles) &&
              tab.visible && (
                <Tab
                  key={tab.description}
                  label={t(tab.description)}
                  value={tab.value}
                />
              ),
          )}
        </StyledTabList>
        <TabPanel value="1">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <PersonalInfo employee={employee} employeeFetched={isSuccess} />
            </Grid>
            <Grid item xs={6}>
              <ContactData employee={employee} employeeFetched={isSuccess} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Permissions employee={employee} />
        </TabPanel>
        <TabPanel value="3">
          <AnnualLeave employee={employee} />
        </TabPanel>
        <TabPanel value="4">
          <Agreements />
        </TabPanel>
        <TabPanel value="5">
          <Documents employeeId={id} />
        </TabPanel>
        <TabPanel value="6">
          <ProjectRates employee={employee} />
        </TabPanel>
        <TabPanel value="7">
          <Benefits />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}

export default EditEmployeeForm;
