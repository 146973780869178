import React, { useState } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import { FormTypes } from 'enums';
import { useAddBenefitPricingMutation } from 'store/benefits/benefits';
import { CustomError } from 'store/api';
import { Benefit } from 'store/benefits/interfaces';
import BenefitPriceForm from '../BenefitPriceForm';

export interface AddBenefitPriceProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  benefit: Benefit;
}

export default function AddBenefitPrice({
  setIsOpen,
  setIsSuccess,
  setIsErrorCatch,
  setIsError,
  benefit,
}: AddBenefitPriceProps) {
  const [benefitPrice, setBenefitPrice] = useState({
    price: '',
    taxPercent: '',
    defaultCompanyCoverageAmount: '',
    validFrom: '',
    validTo: '',
  });
  const [addPrice] = useAddBenefitPricingMutation();

  const handleAddPrice = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addPrice({
        benefit: benefit['@id'],
        price: +benefitPrice.price,
        taxPercent: +benefitPrice.taxPercent,
        defaultCompanyCoverageAmount:
          +benefitPrice.defaultCompanyCoverageAmount,
        validFrom: new Date(benefitPrice.validFrom),
        validTo: benefitPrice.validTo || null,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      setIsErrorCatch(error as CustomError);
      setIsError(true);
    }
  };

  return (
    <BenefitPriceForm
      benefitPrice={benefitPrice}
      formType={FormTypes.add}
      handleChange={(key, value) =>
        setBenefitPrice({ ...benefitPrice, [key]: value })
      }
      handleSubmit={handleAddPrice}
      setIsOpen={setIsOpen}
    />
  );
}
