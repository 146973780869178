import { TableHeadersProps } from 'interfaces';

const tableHeaders: TableHeadersProps[] = [
  {
    label: 'employees.edit_annual_leave_days',
    align: 'left',
  },
  {
    label: 'label.date_from',
    align: 'left',
  },
  {
    label: 'agreements.header_variant',
    align: 'left',
  },
  {
    label: 'label.holiday_type',
    align: 'left',
  }
];

export default tableHeaders;
