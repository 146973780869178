import { FrontendBudgetConfig } from 'pages/BudgetPlanner/context/BudgetPlanner.context';

const calculateTotalAmountBudgetConfig = (
  selectedPeriodData: FrontendBudgetConfig,
) => {
  const baseAmount =
    selectedPeriodData.baseAmount || selectedPeriodData.placeholderBaseAmount;

  const linearGrowth =
    selectedPeriodData.linearGrowth ||
    selectedPeriodData.placeholderLinearGrowth;

  const exponentialGrowth =
    selectedPeriodData.exponentialGrowth ||
    selectedPeriodData.placeholderExponentialGrowth;

  const unitGrowth = selectedPeriodData.unitGrowth || 0;

  return (
    baseAmount * (1 + exponentialGrowth / 100) +
    linearGrowth +
    unitGrowth
  ).toFixed(2);
};

export default calculateTotalAmountBudgetConfig;
