import React, { useState } from 'react';
import { Box, Dialog, Stack, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from 'assets/icons/EditIcon';
import getTranslationKey from 'helpers/getTranslationKey';
import { projectTypes } from 'components/ProjectsList/projectsSelectData';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import { useGetClientsQuery } from 'store/clients/clients';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import { NavBarContainer, NavBarTitle, NavBarSubtitle } from 'common/NavBar';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import ActionOutlinedButton from 'common/ActionOutlinedButton/ActionOutlinedButton';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import useBudgetPreview from 'hooks/useBudgetPreview';
import OverviewForm from '../OverviewForm/OverviewForm';
import getChipOptions from '../getChipOptions';
import useEditProjectFormContext from '../context/useEditProjectFormContext';

export default function ProjectHeader() {
  const { getChipColor, getChipLabel } = getChipOptions();

  const { project } = useEditProjectFormContext();
  const { t } = useTranslation();
  const [isProjectEditModalOpen, setIsProjectEditModalOpen] = useState(false);

  const isAllowedToSetClient = useCheckRoles(roles.clients);

  const {
    data: projectPreviews = [],
    isError: projectPreviewsError,
    isLoading: projectPreviewsLoading,
  } = useGetProjectsPreviewQuery({});

  const {
    data: clients = [],
    isError: clientsError,
    isLoading: clientsLoading,
  } = useGetClientsQuery(
    { properties: ['id', 'name'] },
    {
      skip: !isAllowedToSetClient,
    },
  );

  const {
    budgetPreviews,
    isLoading: budgetsLoading,
    isError: budgetsError,
  } = useBudgetPreview();

  return (
    <>
      <Box bgcolor="main.white">
        <NavBarContainer>
          <Stack direction="row" alignItems="center" py={2.5} spacing={1}>
            <NavigationBackButton to="/projects" />
            <Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <NavBarTitle>{project.name}</NavBarTitle>
                <Chip
                  color={getChipColor(project.status)}
                  size="small"
                  label={
                    <Typography variant="chipLabel">
                      {t(getChipLabel(project.status))}
                    </Typography>
                  }
                />
              </Stack>
              <NavBarSubtitle>
                {t(getTranslationKey(projectTypes, project.type))}
              </NavBarSubtitle>
            </Stack>
          </Stack>
          <ActionOutlinedButton
            onClick={() => setIsProjectEditModalOpen(true)}
            label={t('projects.edit_project')}
            endIcon={
              <EditIcon sx={{ color: 'primary.main', fontSize: '2rem' }} />
            }
          />
        </NavBarContainer>
      </Box>
      <Dialog
        fullWidth
        open={isProjectEditModalOpen}
        onClose={() => setIsProjectEditModalOpen(false)}
      >
        <ContentSuspense
          isError={projectPreviewsError || clientsError || budgetsError}
          isLoading={projectPreviewsLoading || clientsLoading || budgetsLoading}
        >
          <OverviewForm
            budgetPreviews={budgetPreviews}
            projectPreviews={projectPreviews}
            clients={clients}
            setIsOpen={setIsProjectEditModalOpen}
          />
        </ContentSuspense>
      </Dialog>
    </>
  );
}
