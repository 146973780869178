import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { BudgetsTabValue } from 'pages/Budget/interfaces';
import BudgetPlannerChart from 'components/BudgetPlannerChart/BudgetPlannerChart';
import { useGetBudgetQuery } from 'store/budget/budget';
import BudgetPlannerActionButtons from 'components/BudgetPlannerActionButtons';
import BudgetPlannerProvider from './context/BudgetPlannerProvider';

export default function BudgetPlanner() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { data: budgetPreview } = useGetBudgetQuery(id as string);

  return (
    <Grid item xs={12} bgcolor="background.other">
      {budgetPreview && (
        <BudgetPlannerProvider budget={budgetPreview}>
          <NavBarContainer>
            <Stack direction="row" alignItems="center">
              <NavigationBackButton
                to={`/budget/${id}`}
                options={{
                  state: {
                    tabValue: BudgetsTabValue.list,
                  },
                }}
              />
              <Stack>
                <NavBarTitle>{t('budget.add_budget')}</NavBarTitle>
              </Stack>
            </Stack>
            <BudgetPlannerActionButtons />
          </NavBarContainer>
          <Stack p={6} minHeight="calc(100vh - 8rem)">
            <BudgetPlannerChart />
          </Stack>
        </BudgetPlannerProvider>
      )}
    </Grid>
  );
}
