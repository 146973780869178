import api from 'store/api';
import { ApiResponse } from 'store/interfaces';

export interface WorkingHours {
  id: number;
  employee: string;
  project: string;
  task: string;
  date: Date;
  hours: number;
  description: string;
  projectPhase: string | null;
}

export interface WorkingHoursMutationUpdate
  extends Partial<Omit<WorkingHours, 'projectPhase' | 'project'>> {
  projectPhase: string | null;
  project: string;
}

export interface WorkingHoursStats {
  daysOffHourly: number;
  monthHoursFilled: number;
  monthHoursToBeFilled: number;
  monthPossibleHours: number;
}

interface WorkingHoursFilters {
  employee: string[];
  'date[before]': string;
  'date[after]': string;
  project: string[];
}

export interface WorkingHoursResponseDto extends ApiResponse {
  'hydra:member': WorkingHours[];
}

export const workingHoursApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWorkingHours: builder.query<
      WorkingHours[],
      Partial<WorkingHoursFilters>
    >({
      query: (filters) => ({
        url: '/employee-working-hours',
        params: filters,
      }),
      providesTags: ['WorkingHours'],
      transformResponse: (response: WorkingHoursResponseDto) =>
        response['hydra:member'].sort((workingHour, comparedWorkingHour) =>
          workingHour.date
            .toString()
            .localeCompare(comparedWorkingHour.date.toString()),
        ),
    }),
    getWorkingHour: builder.query<WorkingHours, number>({
      query: (id) => `/employee-working-hours/${id}`,
      providesTags: ['WorkingHours'],
    }),
    addWorkingHours: builder.mutation({
      query: (body) => ({
        url: '/employee-working-hours',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['WorkingHours'],
    }),
    updateWorkingHours: builder.mutation<
      WorkingHours,
      WorkingHoursMutationUpdate & Pick<WorkingHours, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `/employee-working-hours/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['WorkingHours'],
    }),
    getWorkingHoursStats: builder.query<
      WorkingHoursStats,
      { id: number; date: string }
    >({
      query: ({ id, date }) => `/employees/${id}/working-hours-stats/${date}`,
      providesTags: ['WorkingHours'],
    }),
    deleteWorkingHours: builder.mutation({
      query: (id: number) => ({
        url: `/employee-working-hours/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['WorkingHours'],
    }),
  }),
});

export const {
  useGetWorkingHoursQuery,
  useGetWorkingHourQuery,
  useAddWorkingHoursMutation,
  useUpdateWorkingHoursMutation,
  useGetWorkingHoursStatsQuery,
  useLazyGetWorkingHoursQuery,
  useDeleteWorkingHoursMutation,
} = workingHoursApi;
