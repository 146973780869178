import React from 'react';
import { useTranslation } from 'react-i18next';
import useMeetingContext from 'pages/Meeting/context/useMeetingContext';
import {
  WidgetContainer,
  WidgetTitle,
  WidgetCaption,
  WidgetCaptionContainer,
} from 'common/Widget';

export default function Information() {
  const { t } = useTranslation();
  const {
    meeting,
    meetingLoading,
    meetingError,
    employees,
    employeesError,
    employeesLoading,
  } = useMeetingContext();

  const employeeIri = meeting.employee;

  const selectedEmployee = employees.find(
    (employee) => employee['@id'] === employeeIri,
  );

  return (
    <WidgetContainer
      isLoading={meetingLoading || employeesLoading}
      isError={meetingError || employeesError}
      title={<WidgetTitle size="lg">{t('label.information')}</WidgetTitle>}
    >
      <WidgetCaptionContainer>
        <WidgetCaption size="lg">{t('label.period')}</WidgetCaption>
        <WidgetCaption size="lg">
          {meeting.dateFrom}/{meeting.dateTo}
        </WidgetCaption>
      </WidgetCaptionContainer>
      <WidgetCaptionContainer>
        <WidgetCaption size="lg">
          {t('employees.header_position')}
        </WidgetCaption>
        <WidgetCaption size="lg">
          {selectedEmployee?.activeAgreement?.position}
        </WidgetCaption>
      </WidgetCaptionContainer>
      <WidgetCaptionContainer>
        <WidgetCaption size="lg">
          {t('employees.header_contract_type')}
        </WidgetCaption>
        <WidgetCaption size="lg">
          {selectedEmployee?.activeAgreement?.variant}
        </WidgetCaption>
      </WidgetCaptionContainer>
    </WidgetContainer>
  );
}
