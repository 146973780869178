export default function checkPassword(
  password: string,
  repeatPassword?: string,
) {
  const passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;

  if (repeatPassword) {
    return passwordPattern.test(password) && repeatPassword === password;
  }
  return passwordPattern.test(password);
}
