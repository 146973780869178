import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import tableHeaders from 'components/EmployeeHolidaysList/tableHeaders';
import TableBodyContent from 'common/TableBodyContent/TableBodyContent';
import HolidayRow from './HolidayRow/HolidayRow';
import { EmployeeHolidaysListProps, HolidayRowType } from './interfaces';

export default function EmployeeHolidaysList({
  holidays,
  holidayRequests,
  dataFetchError,
  dataFetching,
}: EmployeeHolidaysListProps) {
  const { t } = useTranslation();

  return (
      <TableContainer sx={{ mt: 3 }}>
        <Table data-testid="my-days-off-table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell
                  key={header.label}
                  align={header.align}
                  width={header.width}
                >
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={tableHeaders.length} />
            </TableRow>
          </TableHead>
          <TableBodyContent
            isError={dataFetchError}
            isFetching={dataFetching}
            tableHeaders={tableHeaders}
            skeletonRowsLength={5}
          >
            {holidayRequests.map((holidayRequest) => (
              <HolidayRow
                key={holidayRequest.id}
                holidayRow={{
                  ...holidayRequest,
                  rowType: HolidayRowType.holidayRequest,
                }}
              />
            ))}
            {holidays.map((holiday) => (
              <HolidayRow
                key={holiday.id}
                holidayRow={{ ...holiday, rowType: HolidayRowType.holiday }}
              />
            ))}
          </TableBodyContent>
        </Table>
      </TableContainer>
  );
}
