import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import { NavBarContainer, NavBarSubtitle, NavBarTitle } from 'common/NavBar';
import Loader from 'common/Loader';
import ErrorComponent from 'common/Error';
import { useGetResourceRequestQuery } from 'store/resourceRequests/resourceRequests';
import NavigationBackButton from 'common/NavigationBackButton/NavigationBackButton';
import ResourceRequestWizard from 'components/ResourceRequestWizard';
import getAmountMaskFormat from 'helpers/getAmountMaskFormat';
import { ResourceRequestBillingType } from 'store/resourceRequests/interfaces';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import { HumanResourcesTabValue } from 'pages/HumanResources/HumanResources';

export default function ResourceRequestEdit() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const {
    data: resourceRequest,
    isLoading: resourceRequestLoading,
    isError: resourceRequestError,
  } = useGetResourceRequestQuery(id as string);

  if (resourceRequestLoading) {
    return <Loader />;
  }

  if (resourceRequestError) {
    return <ErrorComponent />;
  }

  const initFormResourceRequest = {
    position: resourceRequest?.position || '',
    start: resourceRequest?.start || '',
    location: resourceRequest?.location || '',
    description: resourceRequest?.description || '',
    client: resourceRequest?.client || '',
    salaryRangeMin: resourceRequest?.salaryRangeMin
      ? getAmountMaskFormat(
          resourceRequest.salaryRangeMin / BACKEND_AMOUNT_MODIFIER,
        )
      : '',
    salaryRangeMax: resourceRequest?.salaryRangeMax
      ? getAmountMaskFormat(
          resourceRequest.salaryRangeMax / BACKEND_AMOUNT_MODIFIER,
        )
      : '',
    billingType:
      resourceRequest?.billingType || ('' as ResourceRequestBillingType),
  };

  return (
    <>
      <Grid item xs={12}>
        <NavBarContainer>
          <Stack spacing={1} direction="row" alignItems="center">
            <NavigationBackButton
              to="/human-resources"
              options={{
                state: {
                  tabValue: HumanResourcesTabValue.resources,
                },
              }}
            />
            <Stack>
              <NavBarTitle>{t('human_resources.edit_recruitment')}</NavBarTitle>
              <NavBarSubtitle>
                {t('human_resources.resource_request')}
              </NavBarSubtitle>
            </Stack>
          </Stack>
        </NavBarContainer>
      </Grid>
      <ResourceRequestWizard
        resourceRequestId={id}
        initFormResourceRequest={initFormResourceRequest}
      />
    </>
  );
}
