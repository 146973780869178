import { CalendarType } from 'enums';

const getCalendarType = (value: number) => {
  switch (value) {
    case CalendarType.us:
      return 'US'
    case CalendarType.iso:
      return 'ISO 8601'
    case CalendarType.arabic:
      return 'Arabic'
    default:
      return 'ISO 8601'
  }
}

export default getCalendarType;
