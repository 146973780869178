import React, { useState } from 'react';
import {
  AppBar,
  CardMedia,
  Drawer,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import menuLinks, { Link } from 'components/Menu/menuLinks';
import MenuLink from 'components/Menu/styled/MenuLink.styled';
import checkRoles from 'helpers/checkRoles';
import checkFeature from 'helpers/checkFeature';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import Logo from 'components/Menu/styled/Logo.styled';
import LogoUrl from 'assets/img/logo.svg';
import checkIsHiddenMenuPage from 'config/routing/checkIsHiddenMenuPage';
import { useLocation } from 'react-router';

export interface NavBarContainerProps {
  children: React.ReactNode;
}

export default function NavBarContainer({ children }: NavBarContainerProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const isHiddenMenuPage = checkIsHiddenMenuPage(location.pathname);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const organizationRoles = useSelector(
    (state: RootState) => state.organization.organization.roles,
  );
  const organizationName = useSelector(
    (state: RootState) => state.organization.organization.name,
  );
  const organizationFeatures = useSelector(
    (state: RootState) => state.organization.organization.features,
  );

  const shouldDisplayLink = (link: Link) => {
    if (link.displayFeature) {
      return (
        checkFeature(link.displayFeature, organizationFeatures) &&
        checkRoles(link.displayRoles, organizationRoles)
      );
    }
    return checkRoles(link.displayRoles, organizationRoles);
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          {children}
          {isMobile && !isHiddenMenuPage && (
            <IconButton onClick={() => setIsDrawerOpen(true)}>
              <MenuIcon color="primary" fontSize="large" />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Logo>
          <CardMedia
            component="img"
            loading="lazy"
            src={LogoUrl}
            alt={t('menu.logo')}
          />
          <Typography variant="body1">{organizationName}</Typography>
        </Logo>
        {menuLinks.map((link) =>
          shouldDisplayLink(link) ? (
            <MenuLink key={link.to} to={link.to}>
              {link.icon}
              <Typography color="text.primary">{t(link.name)}</Typography>
            </MenuLink>
          ) : null,
        )}
      </Drawer>
    </>
  );
}
