import React, { useState } from 'react';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  useAddCountryMutation,
  useGetCountriesQuery,
} from 'store/countries/countries';
import { StatusProps } from 'hoc/Status/Status';
import countrySelectOptions from 'components/AddCountryForm/countrySelectOptions';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface AddCountryFormProps extends StatusProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddCountryForm({
  setIsOpen,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AddCountryFormProps) {
  const { data: countries = [] } = useGetCountriesQuery();
  const [selectedCountry, setSelectedCountry] = useState('');
  const { t } = useTranslation();
  const [addCountry, { isLoading }] = useAddCountryMutation();

  const filteredOptions = countrySelectOptions
    .filter(
      (country) =>
        !countries.some((countryData) => countryData.id === country.id),
    )
    .sort((country, comparedCountry) =>
      t(`countries.country_${country.id}`).localeCompare(
        t(`countries.country_${comparedCountry.id}`),
      ),
    );

  const handleAddCountry = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const countryTempObject = {
      id: selectedCountry,
      name: '_',
    };

    try {
      await addCountry(countryTempObject).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">{t('countries.add')}</Typography>
        <IconButton
          sx={{ bgcolor: 'secondary.light' }}
          onClick={() => setIsOpen(false)}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddCountry}>
          <FormControl required fullWidth sx={{ mt: 4 }}>
            <InputLabel id="country-select">
              {t('countries.select_country')}
            </InputLabel>
            <Select
              labelId="country-select"
              id="country-select"
              label={t('countries.select_country')}
              value={selectedCountry}
              onChange={(event) => setSelectedCountry(event.target.value)}
            >
              {filteredOptions.length ? (
                filteredOptions.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {t(`countries.country_${country.id}`)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>{t('countries.no_options')}</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <LoadingButton
              disabled={!selectedCountry}
              endIcon={<AddCircleOutlineIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}

export default AddCountryForm;
