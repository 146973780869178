import { Box, BoxProps, styled } from '@mui/material';

const StyledOrganizationButton = styled(Box)<BoxProps>(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '1.2rem',
  border: `0.1rem solid ${theme.palette.icon.light}`,
  cursor: 'pointer',
}));

export default StyledOrganizationButton;
