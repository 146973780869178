import React from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import { LoggedEmployee } from 'store/employee/interfaces';
import { HolidayTypes } from 'enums';
import displayDuration from './displayDuration';

interface HolidaySummaryProps {
  requestedHours: number;
  type: HolidayTypes;
}

export default function HolidaySummary({
  requestedHours,
  type,
}: HolidaySummaryProps) {
  const { data: currentUser = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery();
  const { t } = useTranslation();

  const balanceAfterHoliday = [
    HolidayTypes.vacations,
    HolidayTypes.onDemand,
  ].includes(type)
    ? currentUser.holidayHoursLeft - requestedHours
    : currentUser.holidayHoursLeft;

  return (
    <Stack spacing={3} direction="row" alignItems="center">
      <Stack spacing={0.5}>
        <Typography variant="caption" color="text.secondary">
          {t('holidays.leave_balance')}
        </Typography>
        <Typography variant="body1">
          {displayDuration(currentUser.holidayHoursLeft)}
        </Typography>
      </Stack>
      <Divider orientation="vertical" sx={{ height: '2.4rem' }} />
      <Stack spacing={0.5}>
        <Typography variant="caption" color="text.secondary">
          {t('holidays.holiday_requested_period')}
        </Typography>
        <Typography variant="body1">
          {displayDuration(requestedHours)}
        </Typography>
      </Stack>
      <Divider orientation="vertical" sx={{ height: '2.4rem' }} />
      <Stack spacing={0.5}>
        <Typography variant="caption" color="text.secondary">
          {t('holidays.balance_after_holiday')}
        </Typography>
        <Typography variant="body1" fontWeight={700}>
          {displayDuration(balanceAfterHoliday)}
        </Typography>
      </Stack>
    </Stack>
  );
}
