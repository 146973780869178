import React from 'react';
import { IconButton } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';

interface NavigateButtonProps {
  to: To;
  options?: NavigateOptions;
  tooltipLabel?: string;
}

function NavigateButton({ to, options, tooltipLabel }: NavigateButtonProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <TitleTooltip
      title={
        tooltipLabel
          ? t(tooltipLabel)
          : t('label.navigate_to', { path: String(to) })
      }
    >
      <IconButton onClick={() => navigate(to, options)}>
        <ChevronRight sx={{ fontSize: '2rem', color: 'icon.main' }} />
      </IconButton>
    </TitleTooltip>
  );
}

export default NavigateButton;
