import React, { ReactNode } from 'react';
import {
  Alert,
  Grid,
  Snackbar,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useLocation } from 'react-router';
import Menu from 'components/Menu/Menu';
import { StatusProps } from 'hoc/Status/Status';
import { useTranslation } from 'react-i18next';
import checkIsHiddenMenuPage from 'config/routing/checkIsHiddenMenuPage';

export interface LayoutProps extends StatusProps {
  children: ReactNode;
  isAuthorized: boolean;
}

export default function Layout({
  children,
  isAuthorized,
  isSuccess,
  isError,
  setIsSuccess,
  setIsError,
  errorMessage,
  setErrorMessage,
}: LayoutProps) {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const location = useLocation();

  const isHiddenMenuPage = checkIsHiddenMenuPage(location.pathname);

  return (
    <Grid container>
      {isAuthorized && isDesktop && !isHiddenMenuPage && <Menu />}
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <Alert
          variant="filled"
          onClose={() => setIsSuccess(false)}
          severity="success"
          sx={{ '& svg': { fontSize: '2rem' } }}
        >
          <Typography data-testid="snackbar-typography-success" variant="body1">
            {t('general.success_alert')}
          </Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isError}
        onClose={() => {
          setIsError(false);
          setErrorMessage('general.error_alert');
        }}
        autoHideDuration={5000}
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setIsError(false);
            setErrorMessage('general.error_alert');
          }}
          sx={{ '& svg': { fontSize: '2rem' } }}
        >
          <Typography data-testid="snackbar-typography-error" variant="body1">
            {t(errorMessage)}
          </Typography>
        </Alert>
      </Snackbar>
    </Grid>
  );
}
