/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { setIsAuthorized, setToken } from 'store/authorization/actions';
import defaultStatus from 'store/authorization/defaultState';

export default createReducer(defaultStatus, (builder) => {
  builder
    .addCase(setIsAuthorized, (state, action) => {
      state.isAuthorized = action.payload;
    })
    .addCase(setToken, (state, action) => {
      state.token = action.payload;
    });
});
