import React from 'react';
import { Invoice } from 'store/incomes/interfaces';
import { Client } from 'store/clients/clients';
import { BankAccount } from 'store/accounts/accounts';
import Menu from './Menu/Menu';
import InvoiceData from './InvoiceData/InvoiceData';

interface PreviewProps {
  invoice: Invoice;
  client: Client;
  bankAccount: BankAccount;
}

export default function Preview({
  invoice,
  client,
  bankAccount,
}: PreviewProps) {
  return (
    <>
      <Menu invoice={invoice} />
      <InvoiceData
        bankAccount={bankAccount}
        invoice={invoice}
        client={client}
      />
    </>
  );
}
