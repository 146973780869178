import React, { useState } from 'react';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import headers from 'components/EditCostForm/Documents/headers';
import { useTranslation } from 'react-i18next';
import RowAddButton from 'common/RowAddButton/RowAddButton';
import AddDocumentForm from 'components/EditCostForm/Documents/AddDocumentForm';
import EditDocumentForm from 'components/EditCostForm/Documents/EditDocumentForm';
import { useGetCostsDocumentsQuery } from 'store/costs/costs';
import { useParams } from 'react-router-dom';
import CostDocument from 'components/EditCostForm/Documents/CostDocument';
import Loader from 'common/Loader';
import Error from 'common/Error';

function Documents() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: any }>();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [chosenDocId, setChosenDocId] = useState(0);
  const {
    data: documents = [],
    isLoading,
    isError,
  } = useGetCostsDocumentsQuery(id);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align}>
                  <Typography variant="tableHeader" color="text.secondary">
                    {t(header.label)}
                  </Typography>
                </TableCell>
              ))}
              <TableCell colSpan={headers.length} />
            </TableRow>
          </TableHead>
          <TableBody>
            <RowAddButton
              onClick={() => setIsAddFormOpen(true)}
              colSpan={headers.length + 1}
            >
              <Typography variant="bold">
                {t('employees.documents_add_new')}
              </Typography>
            </RowAddButton>
            {documents.map((document) => (
              <CostDocument
                key={document.id}
                setIsEditFormOpen={setIsEditFormOpen}
                document={document}
                file={document.storage}
                setChosenDocId={setChosenDocId}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        onClose={() => setIsAddFormOpen(false)}
        open={isAddFormOpen}
      >
        <AddDocumentForm setIsOpen={setIsAddFormOpen} />
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => setIsEditFormOpen(false)}
        open={isEditFormOpen}
      >
        <EditDocumentForm docId={chosenDocId} setIsOpen={setIsEditFormOpen} />
      </Dialog>
    </>
  );
}

export default Documents;
