import React, { useState } from 'react';
import { Box, Popover, Typography } from '@mui/material';

interface ContentPopoverProps {
  children: React.ReactNode;
  text: string;
  id: string;
}

export default function ContentPopover({
  children,
  text,
  id,
}: ContentPopoverProps) {
  const [isHovered, setIsHovered] = useState<Element | null>(null);

  return (
    <Box
      aria-owns={isHovered ? 'hovered-icon' : undefined}
      aria-haspopup="true"
      onMouseEnter={(event: {
        currentTarget: React.SetStateAction<Element | null>;
      }) => setIsHovered(event.currentTarget)}
      onMouseLeave={() => setIsHovered(null)}
    >
      {children}
      <Popover
        id={`booking-${id}`}
        open={Boolean(isHovered)}
        sx={{ pointerEvents: 'none' }}
        anchorEl={isHovered}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={() => setIsHovered(null)}
        disableRestoreFocus
      >
        <Typography variant="body1" p={1}>
          {text}
        </Typography>
      </Popover>
    </Box>
  );
}
