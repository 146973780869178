import React, { useState } from 'react';
import { isAfter, isBefore } from 'date-fns';
import dateFormat from 'dateformat';
import {
  useGetFeedbackRequestsQuery,
  useGetFeedbacksQuery,
} from 'store/feedbacks/feedbacks';
import { Box, Dialog, Stack } from '@mui/material';
import replaceApiIri from 'helpers/replaceApiIri';
import { useTranslation } from 'react-i18next';
import TextButton from 'common/TextButton/TextButton';
import { FeedbackRequestData, FeedbackData } from 'store/feedbacks/interfaces';
import {
  WidgetContainer,
  WidgetTitle,
  WidgetCaptionContainer,
  WidgetCaption,
} from 'common/Widget';
import useMeetingContext from 'pages/Meeting/context/useMeetingContext';
import RequestFeedbackForm from 'components/RequestFeedbackForm';

export default function PendingRequests() {
  const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);
  const { t } = useTranslation();
  const {
    meeting,
    meetingLoading,
    meetingError,
    employees,
    employeesError,
    employeesLoading,
  } = useMeetingContext();

  const employeeMeetingId = replaceApiIri(meeting.employee ?? '', 'employees');

  const {
    data: feedbackRequestsData = {} as FeedbackRequestData,
    isLoading: feedbackRequestsFetching,
    isError: feedbackRequestsFetchError,
  } = useGetFeedbackRequestsQuery(
    {
      employeeFor: employeeMeetingId,
    },
    {
      skip: !meeting.employee,
    },
  );

  const feedbackRequestsForMeetingEmployee =
    feedbackRequestsData?.['hydra:member']?.filter(
      (feedbackRequest) =>
        new Date(feedbackRequest.updatedAt).getTime() >=
          new Date(meeting.dateFrom).getTime() &&
        new Date(feedbackRequest.updatedAt).getTime() <=
          new Date(meeting.dateTo).getTime(),
    ) || [];

  const {
    data: feedbackData = {} as FeedbackData,
    isLoading: feedbacksFetching,
    isError: feedbacksFetchError,
  } = useGetFeedbacksQuery(
    {
      employee: employeeMeetingId,
    },
    {
      skip: !meeting.employee,
    },
  );

  const meetingEmployeeFeedbacks =
    feedbackData?.['hydra:member']?.filter(
      (feedback) =>
        !isBefore(feedback.updatedAt, meeting.dateFrom) &&
        !isAfter(feedback.updatedAt, meeting.dateTo),
    ) || [];
  const meetingEmployeeFeedbacksMissing =
    feedbackRequestsForMeetingEmployee.filter(
      (request) =>
        !meetingEmployeeFeedbacks.find(
          (feedback) => feedback.authorEmployee === request.requestedEmployee,
        ),
    );

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find(
      (employeeData) =>
        employeeData.id === +replaceApiIri(employeeId, 'employees'),
    );

    if (!employee) {
      return '';
    }

    return `${employee.lastname} ${employee.firstname}`;
  };

  return (
    <>
      <WidgetContainer
        isError={
          feedbackRequestsFetchError ||
          feedbacksFetchError ||
          employeesError ||
          meetingError
        }
        isLoading={
          feedbackRequestsFetching ||
          feedbacksFetching ||
          employeesLoading ||
          meetingLoading
        }
        title={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <WidgetTitle size="lg">{t('meeting.pending_feedback')}</WidgetTitle>
            <Box mb={2}>
              <TextButton
                onClick={() => {
                  setIsRequestFormOpen(true);
                }}
                label="feedbacks.request"
                iconPosition="start"
              />
            </Box>
          </Stack>
        }
      >
        {meetingEmployeeFeedbacksMissing.map((request) => (
          <WidgetCaptionContainer key={request.id}>
            <WidgetCaption>
              {getEmployeeName(request.requestedEmployee)}
            </WidgetCaption>
            <WidgetCaption size="lg">
              {dateFormat(request.createdAt, 'yyyy-mm-dd')}
            </WidgetCaption>
          </WidgetCaptionContainer>
        ))}
      </WidgetContainer>
      <Dialog
        fullWidth
        open={isRequestFormOpen}
        onClose={() => setIsRequestFormOpen(false)}
      >
        <RequestFeedbackForm
          setIsOpen={setIsRequestFormOpen}
          employeeFor={+employeeMeetingId}
        />
      </Dialog>
    </>
  );
}
