import { ApiResponse } from './interfaces';

const emptyApiResponse: ApiResponse = {
  'hydra:view': {
    'hydra:first': '',
    'hydra:last': '',
    'hydra:previous': '',
    'hydra:next': '',
  },
  'hydra:totalItems': 0,
};

export default emptyApiResponse;
