import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  {
    label: 'employees.document_type',
    align: 'left',
  },
  {
    label: 'inputs.notes',
    align: 'left',
  },
  {
    label: 'employees.documents_attached_file',
    align: 'center',
  },
];

export default headers;
