import { useSelector } from 'react-redux';
import { RootState } from 'store';

const useCheckRoles = (roles: string[]) => {
  const organizationRoles = useSelector(
    (state: RootState) => state.organization.organization.roles,
  );

  return roles.some((role) => organizationRoles.includes(role));
};

export default useCheckRoles;
