import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import getMonthsRange from 'helpers/getMonthsRange';
import { RangeConditions } from 'store/condition/interfaces';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import Loader from 'common/Loader';
import Error from 'common/Error';
import dateFormat from 'dateformat';
import Project from './Project/Project';
import Balance from './Balance/Balance';
import { getMonthlyProjectsBalance } from './utils';

interface ProjectsConditionProps {
  rangeData: RangeConditions;
}

export default function ProjectsCondition({
  rangeData,
}: ProjectsConditionProps) {
  const { t } = useTranslation();
  const headers = ['label.cost', 'label.income', 'label.balance'];
  const currentDate = new Date();
  const currentMonth = dateFormat(currentDate, 'yyyy-mm');
  const amountMonthsBefore = 2;
  const amountMonthsAfter = 1;
  const dateRange = getMonthsRange(
    currentDate,
    amountMonthsBefore,
    amountMonthsAfter,
  );
  const { isLoading: projectsFetching, isError: projectsFetchError } =
    useGetProjectsPreviewQuery({});
  const projectsValues = Object.values(rangeData).flatMap((item) =>
    Object.keys(item),
  );
  const uniqueProjects = [...new Set(projectsValues)];

  if (projectsFetching) {
    return <Loader />;
  }

  if (projectsFetchError) {
    return <Error />;
  }

  return (
    <Grid container columns={13} p={2} rowSpacing={2}>
      <Grid item xs={1} />
      {dateRange.map((month) => (
        <Grid
          key={month}
          item
          xs={3}
          bgcolor={month === currentMonth ? 'primary.light' : 'transparent'}
          display="flex"
          justifyContent="center"
        >
          <Typography color="text.secondary" variant="body2">
            {month}
          </Typography>
        </Grid>
      ))}
      <Grid item xs={1}>
        <Typography
          color="text.secondary"
          textTransform="uppercase"
          variant="body2"
        >
          {t('label.projects')}
        </Typography>
      </Grid>
      {dateRange.map((range) =>
        headers.map((header) => (
          <Grid
            key={`${range} - ${header}`}
            item
            xs={header === 'label.balance' ? 1.2 : 0.9}
            bgcolor={range === currentMonth ? 'primary.light' : 'transparent'}
          >
            <Typography
              color="text.secondary"
              textTransform="uppercase"
              variant="body2"
            >
              {t(header)}
            </Typography>
          </Grid>
        )),
      )}
      {uniqueProjects.map((project) => (
        <Project
          key={project}
          rangeConditions={rangeData}
          projectId={+project}
          dateRange={dateRange}
        />
      ))}
      <Grid container columns={13} pt={2}>
        <Grid item xs={1} />
        {dateRange.map((month) => (
          <Balance
            key={month}
            month={month}
            monthlyBalance={getMonthlyProjectsBalance(
              month,
              rangeData,
              uniqueProjects,
            )}
          />
        ))}
      </Grid>
    </Grid>
  );
}
