import { ProjectPhaseTypes } from 'store/projectPhases/interfaces';

interface ProjectPhaseType {
  label: string;
  value: ProjectPhaseTypes;
}

const projectPhaseTypes: ProjectPhaseType[] = [
  {
    label: 'projects.type_fixed_price',
    value: ProjectPhaseTypes.fixedPrice,
  },
  {
    label: 'projects.type_time_and_material',
    value: ProjectPhaseTypes.timeAndMaterial,
  },
  {
    label: 'projects.type_non_billable',
    value: ProjectPhaseTypes.nonBillable,
  },
  {
    label: 'projects.type_internal',
    value: ProjectPhaseTypes.internal,
  },
];

export default projectPhaseTypes;
