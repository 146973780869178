import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import { SetURLSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import {
  TextField,
  Autocomplete,
  Stack,
  IconButton,
  BoxProps,
} from '@mui/material';
import FilterIcon from 'assets/icons/FilterIcon';
import { CandidatesData } from 'store/candidates/interfaces';
import CandidateAutocompletePopper from 'components/CandidateAutocompletePopper/CandidateAutocompletePopper';
import StyledOptionWrapper from './OptionWrapper.styled';
import AutocompleteOption from './AutocompleteOption/AutocompleteOption';

interface HrCandidateAutocompleteProps {
  setSearchParams: SetURLSearchParams;
  setSearchPhrase: (phrase: string) => void;
  candidatesDataByPhrase: CandidatesData | undefined;
  searchPhrase: string;
  searchPhraseQueryParam: string | null;
}

export default function HrCandidateAutocomplete({
  setSearchParams,
  setSearchPhrase,
  candidatesDataByPhrase,
  searchPhrase,
  searchPhraseQueryParam,
}: HrCandidateAutocompleteProps) {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setSearchPhrase(event.target.value);
  const handleSearchPhrase = debounce(handleChangeInput, 250);

  const { t } = useTranslation();

  const autocompleteOptions =
    candidatesDataByPhrase?.['hydra:member'] && searchPhrase.length > 1
      ? candidatesDataByPhrase['hydra:member']
      : [];

  const handleUpdateSearchPhraseQuery = () => {
    setSearchParams(() => ({
      ...(searchPhrase.length > 1 && { phrase: searchPhrase }),
    }));
  };

  const handleOnClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setSearchPhrase('');
    setSearchParams((prevSearchParams) => {
      prevSearchParams.delete('phrase');

      return prevSearchParams;
    });
  };

  useEffect(() => {
    if (inputRef?.current && searchPhraseQueryParam) {
      inputRef.current.value = searchPhraseQueryParam;
    }
  }, [searchPhraseQueryParam]);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Autocomplete
        id="hr-candidates-autocomplete"
        freeSolo
        options={autocompleteOptions}
        filterOptions={(options) => options}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            return;
          }
          navigate(`/human-resources/candidate/${newValue.id}`);
        }}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : `${option.firstname} ${option.lastname}`
        }
        disableClearable
        sx={{
          width: '36rem',
        }}
        PopperComponent={CandidateAutocompletePopper}
        renderOption={(props, candidateOption) => (
          <StyledOptionWrapper
            component="li"
            {...(props as BoxProps)}
            key={candidateOption.id}
          >
            <AutocompleteOption candidateOption={candidateOption} />
          </StyledOptionWrapper>
        )}
        renderInput={(params) => (
          <Stack direction="row" alignItems="center">
            <TextField
              {...params}
              inputRef={inputRef}
              label={t('label.search')}
              inputProps={{
                ...params.inputProps,
                value: inputRef?.current?.value ?? '',
              }}
              placeholder={t('human_resources.candidates_search_placeholder')}
              onChange={handleSearchPhrase}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleUpdateSearchPhraseQuery();
                }
              }}
            />
            {inputRef?.current?.value && (
              <IconButton
                onClick={handleOnClear}
                sx={{
                  marginLeft: '-4rem',
                  width: '3rem',
                  height: '3rem',
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        )}
      />
      <IconButton
        sx={{
          width: '4rem',
          height: '4rem',
        }}
        onClick={handleUpdateSearchPhraseQuery}
      >
        <FilterIcon
          sx={{
            fontSize: '2rem',
            color: 'icon.dark',
          }}
        />
      </IconButton>
    </Stack>
  );
}
