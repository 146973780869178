import React, { createContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import EmployeeBookings from 'components/EmployeeBookings/EmployeeBookings';
import BookProperties from 'components/BookProperties/BookProperties';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import { LoggedEmployee } from 'store/employee/interfaces';
import createApiIri from 'helpers/createApiIri';
import roles from 'config/roles/roles';
import useCheckRoles from 'hooks/useCheckRoles';
import ContentSuspense from 'common/ContentSuspense/ContentSuspense';
import { useGetPropertiesQuery } from 'store/properties/properties';

export const EmployeeContext = createContext<string | null>(null);

export default function PropertyBooking() {
  const [selectedEmployeeIri, setSelectedEmployeeIri] = useState<string>('');

  const isPropertiesManager = useCheckRoles(roles.properties);
  const { t } = useTranslation();

  const {
    data: currentUser = {} as LoggedEmployee,
    isSuccess: currentUserFetched,
  } = useGetLoggedEmployeeQuery();

  const {
    data: employees = [],
    isLoading: employeesLoading,
    isError: employeesError,
  } = useGetEmployeesPreview({}, { skip: !isPropertiesManager });

  const {
    data: properties = [],
    isLoading: propertiesLoading,
    isError: propertiesError,
  } = useGetPropertiesQuery({
    ...(!isPropertiesManager && { bookingAllowed: true }),
  });

  useEffect(() => {
    if (!currentUserFetched) {
      return;
    }
    setSelectedEmployeeIri(createApiIri('employees', `${currentUser.id}`));
  }, [currentUserFetched, currentUser]);

  return (
    <Grid item xs={12} md={10} bgcolor="background.list">
      <NavBarContainer>
        <NavBarTitle>{t('booking.header')}</NavBarTitle>
      </NavBarContainer>
      <Box display="flex" flexDirection="column" gap={3} py={2} px={3}>
        <ContentSuspense
          isError={employeesError || propertiesError}
          isLoading={employeesLoading || propertiesLoading}
        >
          <EmployeeBookings
            employeeIri={selectedEmployeeIri}
            setEmployeeIri={setSelectedEmployeeIri}
            employees={employees}
          />
          <EmployeeContext.Provider value={selectedEmployeeIri}>
            <BookProperties properties={properties} />
          </EmployeeContext.Provider>
        </ContentSuspense>
      </Box>
    </Grid>
  );
}
