import React from 'react';
import { Stack, TableBody, TableContainer, TableRow } from '@mui/material';
import { useGetCostsPaymentDueQuery } from 'store/costs/costs';
import { skipToken } from '@reduxjs/toolkit/query';
import Loader from 'common/Loader';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import FileTooltip from '../FileTooltip/FileTooltip';
import TransactionTooltip from '../TransactionTooltip/TransactionTooltip';
import { StyledTable, StyledTableCell } from '../Table.styled';

interface PeriodPaymentProps {
  period: string;
  contractor: string;
}

function PeriodPayment({ period, contractor }: PeriodPaymentProps) {
  const { data: payments = [], isLoading } = useGetCostsPaymentDueQuery(
    period ?? skipToken,
  );
  const contractorPayments = payments.filter(
    (payment) => payment.name === contractor,
  );

  if (isLoading) {
    return <Loader />;
  }

  if (
    !contractorPayments.length ||
    (!contractorPayments[0]?.transactions?.length &&
      !contractorPayments[0]?.transactionAttachments?.length)
  ) {
    return <RequestQuoteIcon color="disabled" fontSize="large" />;
  }

  const transactionsAttachmentIds =
    contractorPayments[0].transactions?.flatMap((transaction) =>
      transaction.transactionAttachments?.map((attachment) => attachment.id),
    ) ?? [];
  const notPinnedAttachments =
    contractorPayments[0].transactionAttachments?.filter(
      (attachment) => !transactionsAttachmentIds.includes(attachment.id),
    );

  return (
    <Stack>
      {contractorPayments[0].transactions.length > 0 && (
        <TableContainer sx={{ mt: 3 }}>
          <StyledTable>
            <TableBody>
              {contractorPayments[0].transactions.map((payment) => (
                <TableRow key={payment.id}>
                  <StyledTableCell align="center">
                    <TransactionTooltip transaction={payment} />
                  </StyledTableCell>
                  {!!payment.transactionAttachments.length &&
                    payment.transactionAttachments.map((attachment) => (
                      <StyledTableCell key={attachment.id} align="center">
                        <FileTooltip attachment={attachment} />
                      </StyledTableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}
      {!!notPinnedAttachments.length && (
        <Stack direction="row" justifyContent="center" spacing={1}>
          {notPinnedAttachments.map((attachment) => (
            <FileTooltip key={attachment.id} attachment={attachment} />
          ))}
        </Stack>
      )}
    </Stack>
  );
}

export default PeriodPayment;
