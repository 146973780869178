import { TableHeadersProps } from 'interfaces';

const headers: TableHeadersProps[] = [
  {
    align: 'left',
    label: 'label.property',
  },
  {
    align: 'center',
    label: 'label.date_from',
  },
  {
    align: 'center',
    label: 'label.date_to',
  },
];

export default headers;
