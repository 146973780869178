import React from 'react';
import {
  useAcceptHolidayRequestMutation,
  useRejectHolidayRequestMutation,
} from 'store/holidays/holidays';
import { HolidayRequest } from 'store/holidays/interfaces';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { StatusProps } from 'hoc/Status/Status';
import dateFormat from 'dateformat';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getHolidayIcon, getHolidayName } from 'helpers/getHolidayData';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import IconPopover from 'common/IconPopover/IconPopover';
import { CustomError } from 'store/api';
import { useGetLoggedEmployeeQuery } from 'store/employee/employees';
import { LoggedEmployee } from 'store/employee/interfaces';
import { HolidayTypes } from 'enums';
import getHolidayDuration from 'helpers/getHolidayDuration';
import getHolidayRequestTimeRange from 'helpers/getHolidayRequestTimeRange';

export interface RequestProps extends StatusProps {
  request: HolidayRequest;
}

function Request({
  request,
  setIsError,
  setIsSuccess,
  setErrorMessage,
}: RequestProps) {
  const { data: currentUser = {} as LoggedEmployee } =
    useGetLoggedEmployeeQuery();
  const approvedByCurrentUser = request.approvers.some(
    (approver) => Number(approver.id) === currentUser.id,
  );
  const [acceptRequest, { isLoading: acceptLoading }] =
    useAcceptHolidayRequestMutation();
  const [rejectHoliday, { isLoading: rejectLoading }] =
    useRejectHolidayRequestMutation();
  const requestedAtDetails = dateFormat(
    request.requestedAt,
    'yyyy-mm-dd HH:MM',
  );
  const { t } = useTranslation();

  const handleAcceptRequest = async (requestId: number) => {
    try {
      await acceptRequest(requestId).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleRejectRequest = async (requestId: number) => {
    try {
      await rejectHoliday(requestId).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const getStatus = (requestId: number) => {
    if (acceptLoading || rejectLoading) {
      return <CircularProgress />;
    }

    return (
      <Box display="flex" columnGap={1} justifyContent="flex-end">
        <IconButton
          data-testid="accept_holiday"
          color="primary"
          onClick={() => handleAcceptRequest(requestId)}
        >
          <CheckIcon fontSize="large" />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => handleRejectRequest(requestId)}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
    );
  };

  return (
    <TableRow>
      <TableCell>
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar src={request.employee.avatarUrl} />
          <Typography variant="body2">
            {request.employee.lastname} {request.employee.firstname}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" columnGap={1}>
            {getHolidayIcon(request.type)}
            <Typography variant="body2">
              {t(getHolidayName(request.type))}
            </Typography>
          </Box>
          <Typography variant="body2">{request.description}</Typography>
        </Box>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">
          {[HolidayTypes.vacations, HolidayTypes.onDemand].includes(
            request.type as HolidayTypes,
          )
            ? `${getHolidayDuration({
                holidayItem: request,
                displayShort: true,
              })}/${request.daysLeft}d`
            : `${getHolidayDuration({
                holidayItem: request,
                displayShort: true,
              })}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {getHolidayRequestTimeRange(request)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{requestedAtDetails}</Typography>
      </TableCell>
      <TableCell
        align={
          approvedByCurrentUser || acceptLoading || rejectLoading
            ? 'center'
            : 'right'
        }
      >
        {approvedByCurrentUser ? (
          <IconPopover
            Icon={TimerOutlinedIcon}
            content={t('holidays.request_pending')}
          />
        ) : (
          getStatus(request.id)
        )}
      </TableCell>
    </TableRow>
  );
}

export default Request;
