import { z } from 'zod';

const schema = z.object({
  type: z.string().min(1, { message: 'errors.field_required' }),
  description : z.string().min(1, { message: 'errors.field_required' }),
  dateFrom: z.date(),
  dateTo: z.date(),
  hours: z.string(),
  employee: z.string().min(1, { message: 'errors.field_required' }),
});

export default schema;
