import React from 'react';
import { Grid } from '@mui/material';
import { NavBarContainer, NavBarTitle } from 'common/NavBar';
import { useTranslation } from 'react-i18next';
import Activation from 'components/GoogleCalendarSettings/Activation';
import CalendarId from 'components/GoogleCalendarSettings/CalendarId';

function CalendarSettings() {
  const { t } = useTranslation();

  return (
    <Grid item xs={10} bgcolor="background.other">
      <NavBarContainer>
        <NavBarTitle>{t('settings.calendar')}</NavBarTitle>
      </NavBarContainer>
      <Grid container justifyContent="center" gap={3} p={2}>
        <Activation />
        <CalendarId />
      </Grid>
    </Grid>
  );
}

export default CalendarSettings;
