import React from 'react';
import { Grid, Stack, Typography, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Client } from 'store/clients/clients';
import LogoUrl from 'assets/img/logo.svg';
import { Invoice } from 'store/incomes/interfaces';
import { BankAccount } from 'store/accounts/accounts';
import Contractors from './Contractors/Contractors';
import InvoiceRows from './InvoiceRows/InvoiceRows';
import Summary from './Summary/Summary';
import PaymentInfo from './PaymentInfo/PaymentInfo';

interface InvoiceDataProps {
  invoice: Invoice;
  client: Client;
  bankAccount: BankAccount;
}

export default function InvoiceData({
  invoice,
  client,
  bankAccount,
}: InvoiceDataProps) {
  const { t } = useTranslation();

  return (
    <Grid bgcolor="background.other" item xs={10} p={4}>
      <Stack bgcolor="background.list" borderRadius={4} p={6} spacing={6}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Stack spacing={1}>
            <Typography variant="h5">{t('invoices.vat_invoice')}</Typography>
            <Typography color="primary" variant="body1">
              {invoice.name}
            </Typography>
          </Stack>
          <CardMedia
            component="img"
            loading="lazy"
            src={LogoUrl}
            alt={t('menu.logo')}
            sx={{ width: '10.6rem', height: '2.9rem' }}
          />
        </Stack>
        <Contractors client={client} invoice={invoice} />
        <PaymentInfo bankAccount={bankAccount} />
        <InvoiceRows invoice={invoice} />
        <Summary invoice={invoice} />
      </Stack>
    </Grid>
  );
}
