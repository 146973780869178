import { FrontendBudgetConfig } from './BudgetPlanner.context';

const calculateBaseAmountFromBudgetConfig = (
  budgetConfig: FrontendBudgetConfig,
) => {
  const baseAmount =
    budgetConfig.baseAmount || budgetConfig.placeholderBaseAmount;
  const exponentialGrowth =
    budgetConfig.exponentialGrowth ||
    budgetConfig.placeholderExponentialGrowth;
  const linearGrowth =
    budgetConfig.linearGrowth || budgetConfig.placeholderLinearGrowth;

  return +(baseAmount * (1 + exponentialGrowth / 100) + linearGrowth).toFixed(
    2,
  );
};
export default calculateBaseAmountFromBudgetConfig;
