import React from 'react';
import { CardMedia, Stack, Typography } from '@mui/material';
import AddButton from 'common/AddButton/AddButton';
import BankingUrl from 'assets/img/banking.svg';
import { useTranslation } from 'react-i18next';

export interface EmptyBanksListProps {
  setIsAddBankOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EmptyBanksList({
  setIsAddBankOpen,
}: EmptyBanksListProps) {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={8}
      alignItems="center"
      bgcolor="background.other"
      minHeight="calc(100vh - 8rem)"
      pt={24}
      px={12}
      pb={12}
    >
      <CardMedia
        sx={{
          width: '25.6rem',
          height: '25.6rem',
        }}
        component="img"
        loading="lazy"
        src={BankingUrl}
        alt="Bank"
      />
      <Stack spacing={2} alignItems="center">
        <Typography textAlign="center" variant="h5">
          {t('banks.no_bank_added')}
        </Typography>
        <Typography
          textAlign="center"
          variant="subtitle1"
          color="text.secondary"
        >
          {t('banks.add_bank_description')}
        </Typography>
      </Stack>
      <AddButton
        onClick={() => setIsAddBankOpen(true)}
        label="banks.add_bank"
      />
    </Stack>
  );
}
