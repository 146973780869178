import { ResourceRequestForCandidate } from 'hooks/useGetResourceRequestsForCandidate';
import { createContext } from 'react';

import { Candidate } from 'store/candidates/interfaces';

export interface CandidateContextProps {
  candidate: Candidate;
  resourceRequestsForCandidate: ResourceRequestForCandidate[]
}

export const CandidateContext = createContext<CandidateContextProps | undefined>(undefined);
