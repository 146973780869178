import React, { useState } from 'react';
import {
  Box,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { LoadingButton } from '@mui/lab';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import {
  Transaction,
  TransactionAttachmentsData,
  useGetTransactionAttachmentsQuery,
  useUpdateTransactionMutation,
  useDeleteTransactionAttachmentMutation,
} from 'store/transactions/transactions';
import Loader from 'common/Loader';
import Error from 'common/Error';
import AwaitingAttachments from 'components/TransactionsList/Attachment/AttachmentsDialog/AwaitingAttachments';
import UploadAttachment from 'components/TransactionsList/Attachment/AttachmentsDialog/UploadAttachment';
import { StatusProps } from 'hoc/Status/Status';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import { CustomError } from 'store/api';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import openFileWithAuth from 'helpers/openFileWithAuth';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';

export interface AttachmentsDialogProps extends StatusProps {
  transaction: Transaction;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AttachmentsDialog({
  setIsOpen,
  transaction,
  setIsSuccess,
  setErrorMessage,
  setIsError,
}: AttachmentsDialogProps) {
  const [notes, setNotes] = useState(transaction.note ?? '');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<number>();
  const { t } = useTranslation();
  const {
    data: attachments = {} as TransactionAttachmentsData,
    isLoading,
    isError,
    isSuccess,
  } = useGetTransactionAttachmentsQuery({ 'exists[transaction]': false });
  const [updateTransaction, { isLoading: updatingTransaction }] =
    useUpdateTransactionMutation();
  const [deleteAttachment] = useDeleteTransactionAttachmentMutation();
  const handleUpdateTransaction = async () => {
    try {
      await updateTransaction({
        id: transaction.id,
        note: notes,
      }).unwrap();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleDeleteAttachment = async (id: number | undefined) => {
    if (!id) return;
    try {
      await deleteAttachment(id).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      <DialogHeader title="Transaction attachments" setIsOpen={setIsOpen} />
      <DialogContent>
        <Divider />
        {isLoading && <Loader />}
        {isError && <Error />}
        {isSuccess && (
          <Grid container justifyContent="center" spacing={4} py={3}>
            <AwaitingAttachments
              attachments={attachments['hydra:member']}
              transactionIri={transaction['@id']}
              setIsOpen={setIsOpen}
            />
            <Grid
              item
              xs={6}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              gap={2}
            >
              <TextField
                fullWidth
                label="Notes"
                name="notes"
                onChange={(event) => setNotes(event.target.value)}
                value={notes}
              />
              <LoadingButton
                disabled={!notes}
                loading={updatingTransaction}
                onClick={handleUpdateTransaction}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
            <UploadAttachment transaction={transaction} setIsOpen={setIsOpen} />
            <Grid item xs={6} display="flex" flexDirection="column" gap={3}>
              <Typography variant="h5">
                {t('transactions.attachments_list')}
              </Typography>
              {transaction.transactionAttachments.length > 0 && (
                <Stack alignItems="flex-start" gap={2}>
                  {transaction.transactionAttachments.map((attachment) => (
                    <Box display="flex" alignItems="center" key={attachment.id}>
                      <TitleTooltip title={t('label.download')}>
                        <IconButton
                          onClick={() =>
                            downloadFileWithAuth(
                              attachment.file!.url,
                              attachment.file!.id,
                            )
                          }
                        >
                          <CloudDownloadIcon fontSize="large" />
                        </IconButton>
                      </TitleTooltip>
                      <TitleTooltip title={t('label.preview')}>
                        <IconButton
                          onClick={() => openFileWithAuth(attachment.file!.url)}
                        >
                          <RemoveRedEyeOutlinedIcon fontSize="large" />
                        </IconButton>
                      </TitleTooltip>
                      <TitleTooltip title={t('label.delete')}>
                        <IconButton
                          data-testid="delete-transaction-attachment-button"
                          onClick={() => {
                            setIsConfirmationDialogOpen(true);
                            setSelectedAttachment(attachment.id);
                          }}
                        >
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </TitleTooltip>
                      <Typography variant="body1">
                        {attachment.name} - {attachment.description}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              )}
            </Grid>
          </Grid>
        )}
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          setIsOpen={setIsConfirmationDialogOpen}
          handleAccept={() => handleDeleteAttachment(selectedAttachment)}
        />
      </DialogContent>
    </>
  );
}

export default AttachmentsDialog;
