import React from 'react';
import { createSvgIcon } from '@mui/material';

const FileSearchIcon = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M3.83333 2.50016C2.91286 2.50016 2.16667 3.24635 2.16667 4.16683V15.8335C2.16667 16.754 2.91286 17.5002 3.83333 17.5002H8C8.46024 17.5002 8.83333 17.8733 8.83333 18.3335C8.83333 18.7937 8.46024 19.1668 8 19.1668H3.83333C1.99238 19.1668 0.5 17.6744 0.5 15.8335V4.16683C0.5 2.32588 1.99238 0.833496 3.83333 0.833496H9.39728C9.93965 0.833496 10.4606 1.04501 10.8495 1.42309L14.8689 5.33083C15.2724 5.72308 15.5 6.26186 15.5 6.82457V9.16683C15.5 9.62707 15.1269 10.0002 14.6667 10.0002C14.2064 10.0002 13.8333 9.62707 13.8333 9.16683V8.05572H10.2024C9.05179 8.05572 8.11905 7.12298 8.11905 5.97239V2.50016H3.83333ZM9.78572 2.71335V5.97239C9.78572 6.2025 9.97226 6.38905 10.2024 6.38905H13.5664L9.78572 2.71335ZM12.5833 13.3335C11.893 13.3335 11.3333 13.8931 11.3333 14.5835C11.3333 15.2739 11.893 15.8335 12.5833 15.8335C12.9287 15.8335 13.2401 15.6945 13.4672 15.4674C13.6943 15.2403 13.8333 14.9289 13.8333 14.5835C13.8333 13.8931 13.2737 13.3335 12.5833 13.3335ZM9.66667 14.5835C9.66667 12.9727 10.9725 11.6668 12.5833 11.6668C14.1942 11.6668 15.5 12.9727 15.5 14.5835C15.5 15.0855 15.3728 15.5583 15.1493 15.9709L16.0893 16.9109C16.4147 17.2363 16.4147 17.764 16.0893 18.0894C15.7638 18.4149 15.2362 18.4149 14.9107 18.0894L13.9708 17.1494C13.5582 17.3729 13.0853 17.5002 12.5833 17.5002C10.9725 17.5002 9.66667 16.1943 9.66667 14.5835Z" fill="currentColor"/>
</svg>,
  'FileSearchIcon',
);

export default FileSearchIcon;
