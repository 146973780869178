import { z } from 'zod';

const schema = z.object({
  name: z.string().min(2, { message: 'errors.too_short_value' }),
  type: z.string().min(1, { message: 'errors.field_required' }),
  status: z.string().min(1, { message: 'errors.field_required' }),
  budget: z.string(),
});

export default schema;
