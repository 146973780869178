/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import isCustomError from 'helpers/isCustomError';
import {
  setSuccessStatus,
  setErrorStatus,
  setErrorMessage,
  setErrorCatch,
} from 'store/status/actions';
import defaultStatus from 'store/status/defaultState';

export default createReducer(defaultStatus, (builder) => {
  builder
    .addCase(setSuccessStatus, (state, action) => {
      state.success = action.payload;
    })
    .addCase(setErrorStatus, (state, action) => {
      state.error = action.payload;
    })
    .addCase(setErrorMessage, (state, action) => {
      state.errorMessage = action.payload;
    })
    .addCase(setErrorCatch, (state, action) => {
      if (isCustomError(action.payload)) {
        state.errorMessage = action.payload.data['hydra:description'];
      }
      state.error = true;
    });
});
