import api from 'store/api';
import { setErrorCatch } from 'store/status/actions';
import updateTransactionsCache from 'store/transactions/updateTransactionsCache';
import {
  BudgetFilters,
  BudgetResponse,
  Budget,
  BudgetPatchMutationRequest,
  BudgetPostMutationRequest,
  BudgetTransaction,
  BudgetTransactionPostRequest,
  BudgetProject,
  BudgetProjectsResponse,
  BudgetProjectPostRequest,
  BudgetContractorsResponse,
} from './interfaces';

export const budgetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBudgets: builder.query<BudgetResponse, Partial<BudgetFilters>>({
      query: (filters) => ({
        url: '/budgets',
        params: filters,
      }),
      providesTags: ['Budgets'],
      transformResponse: (response: BudgetResponse) => {
        const sortedBudget = response['hydra:member']
          .sort((budget, comparedBudget) =>
            budget.name.localeCompare(comparedBudget.name),
          )
          .reverse();
        return { ...response, 'hydra:member': sortedBudget };
      },
    }),
    addBudget: builder.mutation<Budget, BudgetPostMutationRequest>({
      query: (body) => ({
        url: '/budgets',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Budgets'],
    }),
    getBudget: builder.query<Budget, string>({
      query: (id) => ({ url: `/budgets/${id}` }),
      providesTags: ['Budget'],
    }),
    updateBudget: builder.mutation<Budget, BudgetPatchMutationRequest>({
      query: ({ id, ...body }) => ({
        url: `/budgets/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedBudget } = await queryFulfilled;

          const budgetCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Budget' },
          ]);
          budgetCache.forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getBudget' && originalArgs === id) {
              dispatch(
                budgetApi.util.updateQueryData(
                  'getBudget',
                  originalArgs,
                  () => updatedBudget,
                ),
              );
            }
          });
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
    addBudgetEmployee: builder.mutation({
      query: (body) => ({
        url: '/budget-employees',
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: addedBudgetEmployee } = await queryFulfilled;

          const budgetCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Budget' },
          ]);

          const budgetsCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Budgets' },
          ]);

          budgetCache
            .filter(({ endpointName }) => endpointName === 'getBudgetPreview')
            .forEach(({ originalArgs }) => {
              dispatch(
                budgetApi.util.updateQueryData(
                  'getBudget',
                  originalArgs,
                  (draft) => {
                    draft?.budgetEmployees.unshift(addedBudgetEmployee);
                  },
                ),
              );
            });

          budgetsCache
            .filter(({ endpointName }) => endpointName === 'getBudgets')
            .forEach(({ originalArgs }) => {
              dispatch(
                budgetApi.util.updateQueryData(
                  'getBudgets',
                  originalArgs,
                  (draft) => {
                    const budgetIndex = draft?.['hydra:member'].findIndex(
                      (budget: Budget) => budget.id === id,
                    );
                    if (budgetIndex !== -1) {
                      draft?.['hydra:member']?.splice(
                        budgetIndex,
                        1,
                        addedBudgetEmployee,
                      );
                    }
                  },
                ),
              );
            });
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
    removeBudgetEmployee: builder.mutation({
      query: (id: string) => ({
        url: `/budget-employees/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Budget', 'Budgets'],
    }),
    addBudgetTransaction: builder.mutation<
      BudgetTransaction,
      BudgetTransactionPostRequest
    >({
      query: (body) => ({
        url: '/budget-transactions',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data: updatedTransaction } = await queryFulfilled;
          updateTransactionsCache(updatedTransaction);
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
    updateBudgetTransaction: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/budget-transactions/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data: updatedTransaction } = await queryFulfilled;
          updateTransactionsCache(updatedTransaction);
        } catch (error) {
          setErrorCatch(error);
        }
      },
    }),
    removeBudgetTransaction: builder.mutation({
      query: ({ id }) => ({
        url: `/budget-transactions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Budget', 'Budgets'],
    }),
    getBudgetContractors: builder.query<BudgetContractorsResponse, void>({
      query: () => ({
        url: '/budget-contractors',
      }),
      providesTags: ['Budget'],
    }),
    addBudgetContractor: builder.mutation({
      query: (body) => ({
        url: '/budget-contractors',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Budget', 'Budgets'],
    }),
    removeBudgetContractors: builder.mutation({
      query: ({ id }) => ({
        url: `/budget-contractors/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Budget', 'Budgets'],
    }),
    getBudgetProjects: builder.query<BudgetProjectsResponse, void>({
      query: () => ({
        url: '/budget-projects',
      }),
      providesTags: ['Budget'],
    }),
    addBudgetProjects: builder.mutation<
      BudgetProject,
      BudgetProjectPostRequest
    >({
      query: (body) => ({
        url: '/budget-projects',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Budget', 'Budgets'],
    }),
    removeBudgetProjects: builder.mutation({
      query: ({ id }) => ({
        url: `/budget-projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Budgets', 'Budget'],
    }),
  }),
});

export const {
  useGetBudgetsQuery,
  useAddBudgetMutation,
  useGetBudgetQuery,
  useUpdateBudgetMutation,
  useAddBudgetEmployeeMutation,
  useRemoveBudgetEmployeeMutation,
  useAddBudgetTransactionMutation,
  useUpdateBudgetTransactionMutation,
  useRemoveBudgetTransactionMutation,
  useGetBudgetContractorsQuery,
  useAddBudgetContractorMutation,
  useRemoveBudgetContractorsMutation,
  useGetBudgetProjectsQuery,
  useAddBudgetProjectsMutation,
  useRemoveBudgetProjectsMutation,
} = budgetApi;
