import { z } from 'zod';

const schema = z.object({
  days: z.string().min(1, { message: 'errors.field_required' }),
  dateFrom: z.string().min(1, { message: 'errors.field_required' }),
  variant: z.string().min(1, { message: 'errors.field_required' }),
  holidayType: z.string().min(1, { message: 'errors.field_required' }),
  // allowRequestWhenUsed: z.boolean(), // TODO: uncomment this when backend will add logic for this field
});

export default schema;
