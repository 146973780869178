import { createContext } from 'react';
import { StatusProps } from 'hoc/Status/Status';
import { Tag } from 'store/tags/interfaces';

export interface ResourceRequestWizardContextProps {
  resourceRequestTags: Tag[];
  handleDeleteTag: (tagId: string) => Promise<void>;
  handleAddTag: (tagDefinition: string) => Promise<void>;
}

export interface ResourceRequestWizardProviderProps
  extends Pick<StatusProps, 'setErrorMessage' | 'setIsError'> {
  children: React.ReactNode;
  resourceRequestId: string | null;
}

export const ResourceRequestWizardContext = createContext<
  ResourceRequestWizardContextProps | undefined
>(undefined);
