import { useState, useEffect } from 'react';

export default function useCurrentDateEvery(milliseconds: number) {
  const [currentDateEvery, setCurrentDateEvery] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateEvery(new Date());
    }, milliseconds);

    return () => {
      clearInterval(interval);
    };
  }, [milliseconds]);

  return currentDateEvery;
}
