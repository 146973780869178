import { TableHeadersProps } from 'interfaces';

interface HeadersProps extends TableHeadersProps {
  width: string;
}

const tableHeaders: HeadersProps[] = [
  {
    label: 'label.name',
    align: 'left',
    width: '33%'
  },
  {
    label: 'projects.phase_type',
    align: 'left',
    width: '33%'
  },
  {
    label: 'label.date_from_to',
    align: 'left',
    width: '20%'
  },
];

export default tableHeaders;
