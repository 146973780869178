import { PropertyStatus } from '../enums';

const statuses = [
  {
    label: 'property.in_stock',
    value: PropertyStatus.inStock,
  },
  {
    label: 'property.sold',
    value: PropertyStatus.sold,
  },
  {
    label: 'property.damaged',
    value: PropertyStatus.damaged,
  },
];

export default statuses;
