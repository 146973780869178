import { TableHeadersProps } from 'interfaces';

interface HeadersProps extends TableHeadersProps {
  columns: number;
}

const headers: HeadersProps[] = [
  {
    align: 'left',
    label: 'label.name',
    columns: 4,
  },
  {
    align: 'center',
    label: 'label.serial_number',
    columns: 3,
  },
  {
    align: 'center',
    label: 'inputs.booking_type',
    columns: 2,
  },
  {
    align: 'center',
    label: 'inputs.visible',
    columns: 1,
  },
  {
    align: 'center',
    label: 'inputs.location',
    columns: 2,
  },
];

export default headers;
