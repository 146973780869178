import React from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetContainer, WidgetTitle, WidgetCaption } from 'common/Widget';
import { Avatar, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EmployeePreview } from 'store/employee/interfaces';

interface AddFeedbackWidgetProps {
  suggestedEmployee: EmployeePreview;
  suggestedEmployeeLoading: boolean;
}

function AddFeedbackWidget({
  suggestedEmployee,
  suggestedEmployeeLoading,
}: AddFeedbackWidgetProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <WidgetContainer
      isLoading={suggestedEmployeeLoading}
      title={<WidgetTitle>{t('menu.feedback')}</WidgetTitle>}
    >
      <Box display="flex" alignItems="center" columnGap={2} mb={2}>
        <Avatar src={suggestedEmployee.avatarUrl} />
        <WidgetCaption>
          {suggestedEmployee.lastname} {suggestedEmployee.firstname}
        </WidgetCaption>
      </Box>
      <Typography
        variant="body2"
        sx={{
          cursor: 'pointer',
          color: 'primary.main',
        }}
        onClick={() =>
          navigate('/feedback', {
            state: { employeeIri: suggestedEmployee['@id'] },
          })
        }
      >
        {t('feedback.add_feedback')}
      </Typography>
    </WidgetContainer>
  );
}

export default AddFeedbackWidget;
