import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, CardMedia } from '@mui/material';
import LogoLightUrl from 'assets/img/logo-light.svg';
import { wrapperHeight, wrapperWidth, featureWidth } from '../consts';

export default function LogoTile() {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        height: '19.9rem',
        width: featureWidth,
        backgroundColor: 'primary.main',
        borderRadius: '1.2rem',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        top: wrapperHeight / 2,
        left: wrapperWidth / 2,
        transform: 'translate(-50%, -50%)',
      }}
      id="logo"
    >
      <CardMedia
        component="img"
        loading="lazy"
        src={LogoLightUrl}
        alt={t('menu.logo')}
        sx={{
          width: '10.5rem',
          userSelect: 'none',
        }}
      />
    </Stack>
  );
}
