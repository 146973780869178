import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Box, Typography, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  useGetOrganizationBillingAddressQuery,
  useUpdateOrganizationBillingAddressMutation,
} from 'store/config/config';
import { ConfigResponse } from 'store/config/interfaces';
import { useValidate } from 'hooks/useValidate';
import Error from 'common/Error';
import { CustomError } from 'store/api';
import { OrganizationBillingAddressProps, Errors } from './interfaces';

function OrganizationBillingAddress({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: OrganizationBillingAddressProps) {
  const { t } = useTranslation();
  const {
    data: organizationBillingAddress = {} as ConfigResponse<string>,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
  } = useGetOrganizationBillingAddressQuery();

  const [updateOrganizationBillingAddress, { isLoading: isMutationLoading }] =
    useUpdateOrganizationBillingAddressMutation();

  const { errors, validate } = useValidate<Errors>();

  const [selectedBillingAddress, setSelectedBillingAddress] = useState('');
  const isOrganizationBillingAddressChanged =
    selectedBillingAddress !== organizationBillingAddress?.value;

  const handleUpdateOrganizationBillingAddress = async () => {
    try {
      await updateOrganizationBillingAddress({
        value: selectedBillingAddress,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const isUpdateButtonShouldBeVisible =
    isOrganizationBillingAddressChanged &&
    !Object.values(errors).some((error) => error);

  useEffect(() => {
    if (isQuerySuccess) {
      setSelectedBillingAddress(organizationBillingAddress.value);
    }
  }, [isQuerySuccess, organizationBillingAddress, setSelectedBillingAddress]);

  if (isQueryError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.organization_billing_address')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('label.billing_address')}
              error={errors.selectedBillingAddress}
              id="organization-billing-address"
              helperText={
                errors.selectedBillingAddress && t('errors.field_required')
              }
              name="organizationBillingAddress"
              onBlur={(event) =>
                validate('selectedBillingAddress', event.target.value !== '')
              }
              onChange={(event) =>
                setSelectedBillingAddress(event.target.value)
              }
              value={selectedBillingAddress}
              fullWidth
            />
          </Grid>
          {isUpdateButtonShouldBeVisible && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isMutationLoading}
                onClick={handleUpdateOrganizationBillingAddress}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default OrganizationBillingAddress;
