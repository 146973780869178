import React from 'react';
import { Typography } from '@mui/material';

export interface NavBarTitleProps {
  children: React.ReactNode;
  dataTestId?: string
}

export default function NavBarTitle({ dataTestId, children }: NavBarTitleProps) {
  return <Typography variant="h6" data-testid={dataTestId}>{children}</Typography>;
}
