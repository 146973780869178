import React from 'react';
import {
  Scheduler,
  WeekView,
  Appointments,
  DragDropProvider,
  CurrentTimeIndicator,
  DateNavigator,
  Toolbar,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  ViewState,
  EditingState,
  IntegratedEditing,
  AppointmentModel,
} from '@devexpress/dx-react-scheduler';
import { Stack, Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useBookPropertiesContext from 'components/BookProperties/context/useBookPropertiesContext';
import { useGetLocationsQuery } from 'store/locations/locations';
import {
  createBookingId,
  oneMinuteInMs,
} from 'components/BookProperties/BookingForm/consts';
import useBookingSchedulerContext from 'components/BookProperties/BookingForm/context/useBookingScheduler.context';
import SchedulerScrollWrapper from 'components/BookProperties/BookingForm/SchedulerScrollWrapper/SchedulerScrollWrapper';
import useOrganizationStartWeekDay from 'hooks/useOrganizationStartWeekDay';
import TimeTableCellComponent from './WeekView/TimeTableCellComponent/TimeTableCellComponent';
import AppointmentContentComponent from './Appointments/AppointmentContentComponent';
import AppointmentContainerComponent from './Appointments/AppointmentContainerComponent/AppointmentContainerComponent';
import TimeScaleTickCellComponent from './WeekView/TimeScaleTickCellComponent/TimeScaleTickCellComponent';
import TimeScaleLabelComponent from './WeekView/TimeScaleLabelComponent/TimeScaleLabelComponent';
import DragDropContainerComponent from './DragDropProvider/ContainerComponent/ContainerComponent';
import DraftAppointmentComponent from './DragDropProvider/DraftAppointmentComponent/DraftAppointmentComponent';
import SourceAppointmentComponent from './DragDropProvider/SourceAppointmentComponent/SourceAppointmentComponent';
import { MinutesBookingSchedulerProps } from './interfaces';

const allowEdit = (data: AppointmentModel) =>
  !!data.id && createBookingId === data.id;

const defaultStartOfficeHour = 7;
const defaultEndOfficeHour = 17;

export default function MinutesBookingScheduler({
  setIsOpen,
}: MinutesBookingSchedulerProps) {
  const { t } = useTranslation();
  const { selectedDate } = useBookPropertiesContext();
  const startWeekDay = useOrganizationStartWeekDay();
  const { bookingsInState, onCommitChanges, property } =
    useBookingSchedulerContext();

  const currentDate = selectedDate || new Date();

  const { data: locations = [] } = useGetLocationsQuery();
  const propertyLocation = locations.find(
    (location) => location['@id'] === property.location,
  );

  const propertyLocationOpeningHours = {
    start: propertyLocation?.officeOpenHour || defaultStartOfficeHour,
    end: propertyLocation?.officeCloseHour || defaultEndOfficeHour,
  };

  return (
    <SchedulerScrollWrapper>
      <Box sx={{ border: '1px solid', borderColor: 'border.main' }}>
        <Scheduler data={bookingsInState} firstDayOfWeek={startWeekDay}>
          <EditingState onCommitChanges={onCommitChanges} />
          <IntegratedEditing />
          <ViewState defaultCurrentDate={currentDate} />
          <WeekView
            startDayHour={propertyLocationOpeningHours.start}
            endDayHour={propertyLocationOpeningHours.end}
            timeTableCellComponent={TimeTableCellComponent}
            timeScaleTickCellComponent={TimeScaleTickCellComponent}
            timeScaleLabelComponent={TimeScaleLabelComponent}
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments
            appointmentContentComponent={
              AppointmentContentComponent as React.ComponentType<Appointments.AppointmentContentProps>
            }
            containerComponent={
              AppointmentContainerComponent as React.ComponentType<Appointments.ContainerProps>
            }
          />
          <DragDropProvider
            allowDrag={allowEdit}
            allowResize={allowEdit}
            containerComponent={DragDropContainerComponent}
            draftAppointmentComponent={
              DraftAppointmentComponent as React.ComponentType<DragDropProvider.DraftAppointmentProps>
            }
            sourceAppointmentComponent={
              SourceAppointmentComponent as React.ComponentType<DragDropProvider.SourceAppointmentProps>
            }
          />
          <CurrentTimeIndicator updateInterval={oneMinuteInMs} />
        </Scheduler>
      </Box>
      <Stack direction="row" justifyContent="flex-end" p={3}>
        <Button onClick={() => setIsOpen(false)}>
          <Typography variant="buttonMedium" color="text.secondary">
            {t('label.back')}
          </Typography>
        </Button>
      </Stack>
    </SchedulerScrollWrapper>
  );
}
