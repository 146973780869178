import React from 'react';
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import ChurchOutlinedIcon from '@mui/icons-material/ChurchOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import { HolidayTypes } from 'enums';

const holidayTypes = [
  {
    type: HolidayTypes.vacations,
    description: 'holidays.vacations',
    icon: <BeachAccessOutlinedIcon key="vacations" />,
  },
  {
    type: HolidayTypes.disease,
    description: 'holidays.disease',
    icon: <CoronavirusOutlinedIcon key="disease" />,
  },
  {
    type: HolidayTypes.pickUpDay,
    description: 'holidays.pick_up_day',
    icon: <InsertInvitationOutlinedIcon key="pick-up-day" />,
  },
  {
    type: HolidayTypes.marriage,
    description: 'holidays.marriage',
    icon: <ChurchOutlinedIcon key="marriage" />,
  },
  {
    type: HolidayTypes.army,
    description: 'holidays.army',
    icon: <MilitaryTechOutlinedIcon key="army" />,
  },
  {
    type: HolidayTypes.notPaid,
    description: 'holidays.not_paid',
    icon: <MoneyOffOutlinedIcon key="not-paid" />,
  },
  {
    type: HolidayTypes.remote,
    description: 'holidays.remote',
    icon: <HomeOutlinedIcon key="remote" />,
  },
  {
    type: HolidayTypes.onDemand,
    description: 'holidays.on_demand',
    icon: <AnnouncementOutlinedIcon key="on-demand" />,
  },
  {
    type: HolidayTypes.familyMatters,
    description: 'holidays.family_matters',
    icon: <FamilyRestroomOutlinedIcon key="family-matters" />,
  },
  {
    type: HolidayTypes.coaching,
    description: 'holidays.coaching',
    icon: <SchoolOutlinedIcon key="coaching" />,
  },
  {
    type: HolidayTypes.clientOffice,
    description: 'holidays.client_office',
    icon: <ApartmentOutlinedIcon key="client-office" />,
  },
];

export default holidayTypes;
