import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  useGetHolidaysDaysLimitQuery,
  useUpdateHolidaysDaysLimitMutation,
} from 'store/config/config';
import { ConfigResponse } from 'store/config/interfaces';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import { useValidate } from 'hooks/useValidate';
import Error from 'common/Error';
import { LoadingButton } from '@mui/lab';

export interface HolidaysDaysLimitProps extends StatusProps {}

interface Errors {
  count: boolean;
}

function HolidaysDaysLimit({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: HolidaysDaysLimitProps) {
  const { t } = useTranslation();
  const {
    data: approvals = {} as ConfigResponse<string>,
    isError,
    isSuccess,
  } = useGetHolidaysDaysLimitQuery();
  const [daysLimit, setDaysLimit] = useState('');
  const [limitChanged, setLimitChanged] = useState(false);
  const [updateLimit, { isLoading }] = useUpdateHolidaysDaysLimitMutation();
  const { errors, validate } = useValidate<Errors>();

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    setDaysLimit(approvals.value);
  }, [approvals, isSuccess]);

  const handleCountChange = () => {
    if (+daysLimit < 0) {
      setDaysLimit('0');
    } else if (+daysLimit > 30) {
      setDaysLimit('30');
    }
  };

  const handleCountUpdate = async () => {
    try {
      await updateLimit({
        value: daysLimit,
      }).unwrap();
      setIsSuccess(true);
      setLimitChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (isError) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.holidays_days_limit')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              error={errors.count}
              fullWidth
              helperText={
                errors.count &&
                t('errors.wrong_numerical_values', {
                  min: 0,
                  max: 30,
                })
              }
              id="approval-count"
              label={t('label.holidays_days_limit')}
              type="number"
              inputProps={{
                min: 0,
                max: 30,
              }}
              onBlur={(event) => {
                handleCountChange();
                validate(
                  'count',
                  +event.target.value >= 0 && +event.target.value <= 30,
                );
              }}
              onChange={(event) => {
                setDaysLimit(event.target.value);
                setLimitChanged(true);
              }}
              value={daysLimit}
            />
          </Grid>
          {limitChanged && (
            <Grid item xs={1}>
              <LoadingButton
                disabled={!daysLimit}
                endIcon={<CheckIcon />}
                loading={isLoading}
                onClick={handleCountUpdate}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default HolidaysDaysLimit;
