import React from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Cost } from 'store/costs/costs';
import EditButton from 'common/EditButton/EditButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Description from 'components/CostList/Description/Description';

interface ResultProps {
  cost: Cost;
}

export default function Result({ cost }: ResultProps) {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body2">{cost.name}</Typography>
          {cost.cyclic && <RefreshIcon />}
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{cost.costGroup?.name}</Typography>
      </TableCell>
      <TableCell>
        {cost.description && <Description details={cost.description} />}
      </TableCell>
      <TableCell align="right">
        <EditButton onClick={() => navigate(`/contractors/${cost.id}`)} />
      </TableCell>
    </TableRow>
  );
}
