import { IncomeTransaction } from 'store/incomeTransactions/interfaces';
import { ApiResponse, ApiFilters } from 'store/interfaces';
import { StorageResponse } from 'store/storage/storage';

export interface InvoicesData extends ApiResponse {
  'hydra:member': Invoice[];
}

export interface Invoice {
  ['@id']: string;
  id: number;
  name: string;
  client: string;
  saleDate: string;
  issueDate: string;
  dueDate: string;
  incomeRows: InvoiceRow[];
  currency: string;
  bankAccount: string;
  cost: number;
  conversionRate: number;
  notes: string;
  amountNet: number;
  amount: number;
  paymentMethod: string;
  type: string;
  incomeTransactions: IncomeTransaction[];
  targetAmount: number;
  targetAmountNet: number;
  status: string;
  file: StorageResponse | null;
}

export interface InvoiceRow {
  id: string;
  netPrice: string;
  project: string;
  quantity: string;
  unit: string;
  vatRate: string;
  productName: string;
  classificationNumber: string;
}

export interface InvoiceUpdate
  extends Omit<Invoice, 'incomeRows' | 'client' | 'bankAccount'> {
  incomeRows: InvoiceRowUpdate[];
  client: string;
  bankAccount: string;
}

export interface InvoiceRowUpdate
  extends Omit<InvoiceRow, 'id' | 'netPrice' | 'quantity' | 'project'> {
  id: string | null;
  netPrice: string;
  quantity: number;
  project: string | null;
}

export interface DraftInvoice {
  holidays?: string[];
  rows: DraftRow[];
}

export interface DraftRow {
  name: string;
  projectId: number;
  project: string;
  quantity: number;
  unitAmountNet: number;
  amountNet: number;
  unit: string;
  tax: string;
}

export interface DraftIncomeFilters {
  client: string;
  dateFrom: string;
  dateTo: string;
  projects: string[];
}

export interface IncomesFilters extends ApiFilters<Invoice>{
  client: number;
  page: number;
  itemsPerPage: number;
  'saleDate[before]': string;
  'saleDate[strictly_before]': string;
  'saleDate[after]': string;
  'saleDate[strictly_after]': string;
}

export interface EmailData {
  address: EmailAddress;
  ccs: EmailCcs;
  mailContent: string;
}

interface EmailAddress {
  name: string;
  email: string;
}

type EmailCcs = [string, string];

export enum InvoiceStatus {
  draft = 'created',
  sent = 'sent',
  edited = 'edited',
}
