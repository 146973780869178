import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Dialog,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import {
  useDeleteProjectMemberMutation,
  useUpdateProjectMemberMutation,
} from 'store/projectMembers/projectMembers';
import { setSuccessStatus, setErrorCatch } from 'store/status/actions';
import DeleteButton from 'common/DeleteButton/DeleteButton';
import { FormTypes } from 'enums';
import EditButton from 'common/EditButton/EditButton';
import { ProjectMemberEmployee } from 'hooks/useProjectMemberEmployee/interfaces';
import { FormPayload } from '../ProjectEmployeeForm/interfaces';
import ProjectEmployeeForm from '../ProjectEmployeeForm/ProjectEmployeeForm';

interface ProjectEmployeeRowProps {
  projectMemberEmployee: ProjectMemberEmployee;
}

export default function ProjectEmployeeRow({
  projectMemberEmployee,
}: ProjectEmployeeRowProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [deleteProjectMember] = useDeleteProjectMemberMutation();
  const [updateProjectMember, { isLoading: updateProjectMemberLoading }] =
    useUpdateProjectMemberMutation();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleDeleteProjectMember = async () => {
    try {
      await deleteProjectMember(projectMemberEmployee.id).unwrap();
      dispatch(setSuccessStatus(true));
      setIsDeleteDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  const handleEditProjectMember = async ({ role }: FormPayload) => {
    try {
      await updateProjectMember({
        id: projectMemberEmployee.id,
        position: role,
      }).unwrap();
      dispatch(setSuccessStatus(true));
      setIsEditDialogOpen(false);
    } catch (error) {
      dispatch(setErrorCatch(error));
    }
  };

  const employeeFullName = `${projectMemberEmployee.employee.firstname} ${projectMemberEmployee.employee.lastname}`;

  return (
    <>
      <TableRow key={projectMemberEmployee.employee.id}>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar src={projectMemberEmployee.employee.avatarUrl} />
            <Stack>
              <Typography variant="body2">
                {employeeFullName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {projectMemberEmployee.employee.position}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          {t(`projects.role_${projectMemberEmployee.position}`)}
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <EditButton
              v2
              onClick={() => {
                setIsEditDialogOpen(true);
              }}
            />
            <DeleteButton onClick={() => setIsDeleteDialogOpen(true)} />
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        handleAccept={handleDeleteProjectMember}
      />
      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        fullWidth
      >
        <ProjectEmployeeForm
          formType={FormTypes.edit}
          selectEmployees={[projectMemberEmployee.employee]}
          setIsDialogOpen={setIsEditDialogOpen}
          onSubmit={handleEditProjectMember}
          isSubmitting={updateProjectMemberLoading}
          initialValues={{
            employee: projectMemberEmployee.employee['@id'],
            role: projectMemberEmployee.position,
          }}
        />
      </Dialog>
    </>
  );
}
