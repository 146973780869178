import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useGetProjectsQuery } from 'store/projects/projects';
import { Project } from 'store/projects/interfaces';
import ClientFormSelect from 'components/AddClientForm/ClientFormSelect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import fullDateFormat from 'helpers/fullDateFormat';
import { Client, useGetClientsQuery } from 'store/clients/clients';
import { ProjectStatuses } from 'enums';

interface ClientSelectProps {
  selectedProjects: string[];
  dateRange: [Date, Date];
}

const emptyClients: Client[] = [];

function ClientSelect({ selectedProjects, dateRange }: ClientSelectProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: projects = {
      'hydra:member': [] as Project[],
    },
  } = useGetProjectsQuery({
    status: ProjectStatuses.active,
  });

  const filteredProjects = projects['hydra:member'].filter((projectItem) =>
    selectedProjects.includes(projectItem.id.toString()),
  );
  const [chosenClientIri, setChosenClientIri] = useState('');
  const projectsWithClient = filteredProjects.filter(
    (project) => project.client,
  );
  const { clients } = useGetClientsQuery(
    { properties: ['id', 'name'] },
    {
      selectFromResult: ({ data }) => ({
        clients:
          data?.filter((item) =>
            projectsWithClient
              .map((project) => project.client)
              .includes(item['@id']),
          ) ?? emptyClients,
      }),
    },
  );

  useEffect(() => {
    if (clients.length === 1) {
      setChosenClientIri(clients[0]['@id']);
    }
  }, [clients]);

  const handleRedirectToIncome = () => {
    navigate('/invoices/add', {
      state: {
        clientIri: chosenClientIri,
        dateFrom: fullDateFormat(dateRange[0]),
        dateTo: fullDateFormat(dateRange[1]),
        projects: selectedProjects,
        createDraft: true,
      },
    });
  };

  return (
    <Grid item xs={4} display="flex" alignItems="center" gap={1}>
      <ClientFormSelect
        selectId="client-select"
        label={t('projects.client')}
        value={chosenClientIri}
        onChangeFn={({ target }) => setChosenClientIri(target.value)}
        selectOptions={clients?.map((client) => ({
          id: client['@id'],
          name: client.name,
        }))}
      />
      <Button
        disabled={
          !selectedProjects || !projectsWithClient.length || !chosenClientIri
        }
        onClick={handleRedirectToIncome}
        variant="contained"
        data-testid="hours-view-generate-invoice-button"
      >
        {t('hours_view.generate')}
      </Button>
    </Grid>
  );
}

export default ClientSelect;
