import React, { useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import useMultipleCheckboxSelect from 'hooks/useMultipleCheckboxSelect';
import useHolidaysContext from 'pages/HolidaysManagement/context/useHolidaysContext';
import getOneMonthAgoDate from 'helpers/getOneMonthAgoDate/getOneMonthAgoDate';
import { HolidayType } from 'store/holidayTypes/interfaces';

interface FiltersProps {
  holidayTypes: HolidayType[];
}

function Filters({ holidayTypes }: FiltersProps) {
  const { t } = useTranslation();
  const { data: employees = [] } = useGetEmployeesPreview({
    isActive: true,
  });
  const { searchParams, setSearchParams } = useHolidaysContext();
  const selectedEmployee = searchParams.get('employee') ?? '';

  const {
    selected: selectedTypes,
    setSelected: setSelectedTypes,
    isSelected: isTypeSelected,
    handleChangeWithSelectedCheckboxesCallback,
  } = useMultipleCheckboxSelect<string>([]);

  const getSelectedTypes = (selectedTypesIds: string[]) =>
    holidayTypes
      .filter(({ id }) => selectedTypesIds.includes(id))
      .map(({ name }) => name)
      .join(', ');

  useEffect(() => {
    setSelectedTypes(searchParams.getAll('type[]'));
  }, [searchParams, setSelectedTypes]);

  return (
    <Box display="flex" gap={2} alignItems="center" my={3}>
      <FormControl fullWidth>
        <InputLabel id="employee-select">
          {t('inputs.select_employee')}
        </InputLabel>
        <Select
          label={t('inputs.select_employee')}
          labelId="employee-select"
          name="employeeSelect"
          onChange={(event) => {
            const newSelectedEmployee = event.target.value;
            setSearchParams((prevSearchParams) => ({
              ...prevSearchParams,
              ...(newSelectedEmployee
                ? { employee: newSelectedEmployee }
                : { 'dateTo[after]': getOneMonthAgoDate() }),
              'type[]': selectedTypes,
            }));

            return newSelectedEmployee;
          }}
          value={selectedEmployee}
        >
          <MenuItem
            value=""
            data-testid="holidays-filter-clear-employee-option"
          >
            <em>{t('inputs.select_employee')}</em>
          </MenuItem>
          {employees.map((employee) => (
            <MenuItem key={employee.id} value={employee.id}>
              {employee.lastname} {employee.firstname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="type-select">{t('label.type')}</InputLabel>
        <Select
          label={t('label.type')}
          labelId="type-select"
          name="typeSelect"
          multiple
          renderValue={(selected) => getSelectedTypes(selected)}
          value={selectedTypes}
        >
          {holidayTypes.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={id}
                    onChange={(event) => {
                      handleChangeWithSelectedCheckboxesCallback(
                        event,
                        (selectedCheckboxes) => {
                          setSearchParams((prevSearchParams) => ({
                            ...prevSearchParams,
                            ...(selectedEmployee
                              ? { employee: selectedEmployee }
                              : { 'dateTo[after]': getOneMonthAgoDate() }),
                            'type[]': selectedCheckboxes,
                          }));
                        },
                      );
                    }}
                    checked={isTypeSelected(id)}
                  />
                }
                label={name}
                sx={{ width: '100%' }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default Filters;
