import React, { useState } from 'react';
import getMonthsRange from 'helpers/getMonthsRange';
import { TaxesCondition as TaxesProps } from 'store/condition/interfaces';
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { StyledAccordion } from 'components/ResponsibilityGroup/Accordion.styled';
import { useTranslation } from 'react-i18next';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { useGetCostsQuery } from 'store/costs/costs';
import Loader from 'common/Loader';
import Error from 'common/Error';
import ConditionBalance from 'common/ConditionBalance/ConditionBalance';
import ContractorTaxes from './ContractorTaxes/ContractorTaxes';
import calculateTaxesSum from './utils';

interface TaxesConditionProps {
  taxes: TaxesProps;
  order: string[];
  section: string;
}

export default function TaxesCondition({
  taxes,
  order,
  section,
}: TaxesConditionProps) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const currentDate = new Date();
  const { isLoading: costsFetching, isError: costsFetchError } =
    useGetCostsQuery({});
  const dateRange = getMonthsRange(currentDate, 2, 1);
  const formatAmount = useCurrencyFormat();
  const uniqueContractors = [
    ...new Set(
      Object.values(taxes).flatMap((contractor) => Object.keys(contractor)),
    ),
  ];

  if (costsFetching) {
    return <Loader />;
  }

  if (costsFetchError) {
    return <Error />;
  }

  return (
    <Grid container p={2}>
      <Grid item xs={12}>
        <StyledAccordion disableGutters expanded={expanded} square>
          <AccordionSummary
            onClick={() => setExpanded((prevState) => !prevState)}
            sx={{ px: 1, py: 0 }}
          >
            <Grid container columns={13} alignItems="center">
              <Grid item xs={1}>
                <Typography variant="body2">
                  {t('label.taxes')}
                </Typography>
              </Grid>
              {dateRange.map((month) => (
                <React.Fragment key={month}>
                  <Grid item xs={1.8} />
                  <Grid item xs={1.2}>
                    <Typography variant="smallBody">
                      {formatAmount(calculateTaxesSum(month, taxes))}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              px: 1,
              py: 1,
              bgcolor: 'secondary.light',
            }}
          >
            {uniqueContractors.map((contractor) => (
              <ContractorTaxes
                key={contractor}
                contractorId={contractor}
                dateRange={dateRange}
                taxes={taxes}
              />
            ))}
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid container columns={13} pt={2}>
        <Grid item xs={1} />
        {dateRange.map((month) => (
          <ConditionBalance
            key={month}
            month={month}
            order={order}
            section={section}
            sectionBalance={calculateTaxesSum(month, taxes)}
          />
        ))}
      </Grid>
    </Grid>
  );
}
