import React, { useState } from 'react';
import { BenefitPrice } from 'store/benefits/interfaces';
import {
  Dialog,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import fullDateFormat from 'helpers/fullDateFormat';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import EditButton from 'common/EditButton/EditButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
import { StatusProps } from 'hoc/Status/Status';
import { useDeleteBenefitPricingMutation } from 'store/benefits/benefits';
import { CustomError } from 'store/api';
import EditBenefitPrice from 'components/BenefitPriceForm/EditBenefitPrice';
import useCurrencyFormat from 'hooks/useCurrencyFormat';

export interface PriceProps extends StatusProps {
  pricing: BenefitPrice;
}

export default function Price({
  pricing,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: PriceProps) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [deletePrice] = useDeleteBenefitPricingMutation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const formatAmount = useCurrencyFormat();

  const handleDelete = async (priceId: number) => {
    try {
      await deletePrice(priceId).unwrap();
      setIsSuccess(true);
      setIsConfirmationDialogOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Typography variant="body2">{formatAmount(pricing.price)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{pricing.taxPercent}%</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {formatAmount(pricing.defaultCompanyCoverageAmount)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {fullDateFormat(pricing.validFrom)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {pricing.validTo ? (
            <Typography variant="body2">
              {fullDateFormat(pricing.validTo)}
            </Typography>
          ) : (
            <AllInclusiveIcon fontSize="large" />
          )}
        </TableCell>
        <TableCell align="right">
          <EditButton onClick={() => setIsEditDialogOpen(true)} />
          <IconButton onClick={() => setIsConfirmationDialogOpen(true)}>
            <DeleteIcon color="error" fontSize="large" />
          </IconButton>
        </TableCell>
      </TableRow>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <EditBenefitPrice price={pricing} setIsOpen={setIsEditDialogOpen} />
      </Dialog>
      <ConfirmationDialog
        handleAccept={() => handleDelete(pricing.id)}
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
      />
    </>
  );
}
