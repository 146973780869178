import React from 'react';
import { Agreement } from 'components/AgreementsList/AgreementsList';
import AgreementStatuses from 'components/AgreementsList/Agreement/Status/enums';
import Badge from 'components/AgreementsList/Agreement/Badge/Badge';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { startOfDay, differenceInDays, endOfDay } from 'date-fns';

interface StatusProps {
  agreement: Agreement;
}

function Status({ agreement }: StatusProps) {
  if (agreement.status === AgreementStatuses.active) {
    return (
      <Badge
        bgcolor="status.active.light"
        iconColor="status.active.main"
        title="agreements.active"
      >
        <TaskAltOutlinedIcon fontSize="large" />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.finishing) {
    const startOfCurrentDay = startOfDay(new Date());
    const agreementEndDate = new Date(agreement.dateTo as string);
    const daysLeft = differenceInDays(agreementEndDate, startOfCurrentDay);

    return (
      <Badge
        bgcolor="status.finishing.light"
        iconColor="status.finishing.main"
        title="agreements.finishing"
        titleOptions={{ count: daysLeft }}
      >
        <UpdateOutlinedIcon fontSize="large" />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.notStarted) {
    return (
      <Badge
        bgcolor="status.notStarted.light"
        iconColor="status.notStarted.main"
        title="agreements.agreement_not_started"
      >
        <HistoryOutlinedIcon fontSize="large" />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.freshlyFinished) {
    const endOfCurrentDay = endOfDay(new Date());
    const agreementEndDate = new Date(agreement.dateTo as string);
    const daysLeft = differenceInDays(endOfCurrentDay, agreementEndDate);

    return (
      <Badge
        bgcolor="status.freshlyFinished.light"
        iconColor="status.freshlyFinished.main"
        title="agreements.freshly_finished"
        titleOptions={{ count: daysLeft }}
      >
        <ManageHistoryIcon fontSize="large" />
      </Badge>
    );
  }

  if (agreement.status === AgreementStatuses.inactive) {
    return (
      <Badge
        bgcolor="status.notActive.light"
        border="0.1rem solid"
        borderColor="status.notActive.main"
        iconColor="status.notActive.main"
        title="agreements.finished"
      >
        <ReportGmailerrorredOutlinedIcon fontSize="large" />
      </Badge>
    );
  }

  return null;
}

export default Status;
