import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Box, Typography, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  useGetInvoiceSendCcsQuery,
  useUpdateInvoiceSendCcsMutation,
} from 'store/config/config';
import { InvoiceSendCcsResponse } from 'store/config/interfaces';
import { useValidate } from 'hooks/useValidate';
import Error from 'common/Error';
import { CustomError } from 'store/api';
import checkEmail from 'helpers/validators/checkEmail/checkEmail';
import { InvoiceSendCcsEmailProps, Errors } from './interfaces';

function InvoiceSendCcsEmail({
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: InvoiceSendCcsEmailProps) {
  const { t } = useTranslation();
  const {
    data: invoiceSendCcs = {} as InvoiceSendCcsResponse,
    isError: isErrorInvoiceSendCcsQuery,
    isSuccess: isSuccessInvoiceSendCcsQuery,
  } = useGetInvoiceSendCcsQuery();

  const [updateInvoiceSendCcs, { isLoading: isLoadingInvoiceSendCcsMutation }] =
    useUpdateInvoiceSendCcsMutation();

  const { errors, validate } = useValidate<Errors>();

  const [selectedInvoiceSendCcsEmail, setSelectedInvoiceSendCcsEmail] =
    useState('');
  const isInvoiceSendCcsEmailChanged =
    selectedInvoiceSendCcsEmail !== invoiceSendCcs?.value?.email;

  const handleUpdateInvoiceSendCcsEmail = async () => {
    const updatedInvoiceSendCcs = {
      name: invoiceSendCcs?.value?.name,
      email: selectedInvoiceSendCcsEmail,
    };

    try {
      await updateInvoiceSendCcs({
        value: JSON.stringify(updatedInvoiceSendCcs),
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  const isUpdateButtonShouldBeVisible =
    isInvoiceSendCcsEmailChanged &&
    !Object.values(errors).some((error) => error);

  useEffect(() => {
    if (isSuccessInvoiceSendCcsQuery && invoiceSendCcs?.value?.email) {
      setSelectedInvoiceSendCcsEmail(invoiceSendCcs.value.email);
    }
  }, [isSuccessInvoiceSendCcsQuery, invoiceSendCcs]);

  if (isErrorInvoiceSendCcsQuery) {
    return <Error />;
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="main.white"
        borderRadius="1.6rem"
      >
        <Grid container alignItems="center" columnSpacing={1} p={2}>
          <Grid item xs={7}>
            <Typography variant="body1">
              {t('label.invoice_send_ccs_email')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="tel"
              label={t('label.ccs_email')}
              error={errors.selectedInvoiceCcsEmail}
              id="invoice-send-ccs-email"
              helperText={
                errors.selectedInvoiceCcsEmail && t('errors.wrong_email')
              }
              name="invoiceSendCcsEmail"
              onBlur={(event) =>
                validate(
                  'selectedInvoiceCcsEmail',
                  checkEmail(event.target.value) || event.target.value === '',
                )
              }
              onChange={(event) =>
                setSelectedInvoiceSendCcsEmail(event.target.value)
              }
              value={selectedInvoiceSendCcsEmail}
              fullWidth
            />
          </Grid>
          {isUpdateButtonShouldBeVisible && (
            <Grid item xs={1}>
              <LoadingButton
                endIcon={<CheckIcon />}
                loading={isLoadingInvoiceSendCcsMutation}
                onClick={handleUpdateInvoiceSendCcsEmail}
                variant="contained"
              >
                <span>{t('button.save')}</span>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default InvoiceSendCcsEmail;
