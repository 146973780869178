import React from 'react';
import { useGetDocumentTypesQuery } from 'store/documentTypes/documentTypes';
import Error from 'common/Error';
import Loader from 'common/Loader';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditButton from 'common/EditButton/EditButton';
import headers from 'components/DocumentTypesList/headers';
import NoDataRow from 'common/NoDataRow/NoDataRow';

interface DocumentTypesListProps {
  setIsEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setChosenDocType: React.Dispatch<React.SetStateAction<number>>;
}

function DocumentTypesList({
  setIsEditFormOpen,
  setChosenDocType,
}: DocumentTypesListProps) {
  const { t } = useTranslation();
  const {
    data: documentTypes = [],
    isLoading,
    isError,
  } = useGetDocumentTypesQuery();

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography variant="tableHeader" color="text.secondary">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={headers.length} />
          </TableRow>
        </TableHead>
        <TableBody>
          {documentTypes.length ? (
            documentTypes.map((type) => (
              <TableRow key={type.id}>
                <TableCell>
                  <Typography variant="body2">{type.name}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" color="secondary">
                    {t(`documents.entity_${type.relationName}s`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="secondary">
                    {type.description}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <EditButton
                    onClick={() => {
                      setChosenDocType(type.id);
                      setIsEditFormOpen(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoDataRow headers={headers} label="label.no_data" />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DocumentTypesList;
