export enum PropertyType {
  room = 'room',
  desk = 'desk',
  parking = 'parking',
  monitor = 'monitor',
  notebook = 'notebook',
  accessory = 'accessory',
  headphone = 'headphone',
  keyboard = 'keyboard',
  mouse = 'mouse',
  network = 'network',
  other = 'other',
  pc = 'pc',
  phone = 'phone',
  printer = 'printer',
  shredder = 'shredder',
  chairs = 'chairs',
  workstation = 'workstation',
  router = 'router',
}

export enum BookingType {
  days = 'days',
  minutes = 'minutes',
  permanently = 'permanently',
}

export enum PropertyStatus {
  inStock = 'in-stock',
  damaged = 'damaged',
  sold = 'sold',
}
