import React, { useEffect, useState } from 'react';
import DateSelect from 'components/AddAgreementForm/DateSelect';
import { useValidate } from 'hooks/useValidate';
import { Errors } from 'components/AddAgreementForm/interfaces';
import { useUpdateAgreementMutation } from 'store/agreements/agreements';
import { Agreement } from 'components/AgreementsList/AgreementsList';
import { StatusProps } from 'hoc/Status/Status';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { CustomError } from 'store/api';
import { LoadingButton } from '@mui/lab';

export interface DatesProps extends StatusProps {
  agreement: Agreement;
  agreementFetched: boolean;
}

function Dates({
  agreement,
  agreementFetched,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: DatesProps) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { errors, validate } = useValidate<Errors>();
  const [updateAgreement, { isLoading }] = useUpdateAgreementMutation();
  const [changed, setChanged] = useState(false);
  const { t } = useTranslation();

  const disableSubmission = () => Object.values(errors).some((error) => error);

  useEffect(() => {
    if (!agreementFetched) {
      return;
    }
    setStartDate(dateFormat(agreement.dateFrom, 'yyyy-mm-dd'));
    if (!agreement.dateTo) {
      return;
    }
    setEndDate(dateFormat(agreement.dateTo, 'yyyy-mm-dd'));
  }, [agreementFetched, agreement]);

  const handleEditAgreement = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    try {
      await updateAgreement({
        id: agreement.id,
        dateFrom: startDate,
        dateTo: endDate || null,
      }).unwrap();
      setIsSuccess(true);
      setChanged(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  return (
    <Box p={4} bgcolor="background.list" borderRadius="1.2rem">
      <Typography variant="h5" mb={4}>
        {t('agreements.edit_dates')}
      </Typography>
      <form onSubmit={handleEditAgreement}>
        <DateSelect
          errors={errors}
          startDate={startDate}
          endDate={endDate}
          validate={validate}
          onStartDateChange={(event) => {
            setStartDate(event.target.value);
            setChanged(true);
          }}
          onEndDateChange={(event) => {
            setEndDate(event.target.value);
            setChanged(true);
          }}
        />
        {changed && (
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <LoadingButton
              disabled={disableSubmission()}
              endIcon={<CheckIcon />}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save_changes')}</span>
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
}

export default Dates;
