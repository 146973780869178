import React from 'react';
import { Skeleton } from '@mui/material';
import Error from 'common/Error';
import { useTranslation } from 'react-i18next';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import WidgetCaption from './WidgetCaption';

interface WidgetContainerProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  isError?: boolean;
  isLoading?: boolean;
}

export default function WidgetContainer({
  children,
  title,
  isError = false,
  isLoading = false,
}: WidgetContainerProps) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <StyledContentBox p={3}>
        <Skeleton sx={{ mb: 2, width: '50%' }} />
        <Skeleton sx={{ mb: 1 }} />
        <Skeleton sx={{ mb: 1 }} />
        <Skeleton sx={{ mb: 1 }} />
      </StyledContentBox>
    );
  }

  if (isError) {
    return (
      <StyledContentBox p={3}>
        {title}
        <Error />
      </StyledContentBox>
    );
  }

  if (children && !React.Children.count(children)) {
    return (
      <StyledContentBox p={3}>
        {title}
        <WidgetCaption>{t('label.no_data')}</WidgetCaption>
      </StyledContentBox>
    );
  }

  return (
    <StyledContentBox p={3}>
      {title}
      {children}
    </StyledContentBox>
  );
}
