const calendarType = [
  {
    value: '0',
    type: 'us',
    description: 'calendar_type.us'
  },
  {
    value: '6',
    type: 'arabic',
    description: 'calendar_type.arabic'
  },
  {
    value: '1',
    type: 'iso',
    description: 'calendar_type.iso'
  },
]

export default calendarType;
