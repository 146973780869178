import { Box, Button, DialogContent, TextField } from '@mui/material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import { FormTypes } from 'enums';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export interface BenefitPriceFormProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formType: FormTypes;
  benefitPrice: Benefit;
  handleChange: (key: string, value: string) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

interface Benefit {
  price: string;
  taxPercent: string;
  defaultCompanyCoverageAmount: string;
  validFrom: string;
  validTo: string;
}

export default function BenefitPriceForm({
  formType,
  setIsOpen,
  benefitPrice,
  handleChange,
  handleSubmit,
}: BenefitPriceFormProps) {
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.name, event.target.value);
  };

  return (
    <>
      <DialogHeader
        title={
          formType === FormTypes.add
            ? t('benefits.add_pricing')
            : t('benefits.edit_pricing')
        }
        setIsOpen={setIsOpen}
      />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={3}
            pt={3}
          >
            <Box display="flex" gap={3} width="100%">
              <TextField
                fullWidth
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
                label={t('benefits.price')}
                name="price"
                onChange={handleInputChange}
                required
                type="number"
                value={benefitPrice.price}
              />
              <TextField
                fullWidth
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 0.01,
                }}
                label={t('benefits.tax')}
                name="taxPercent"
                required
                onChange={handleInputChange}
                type="number"
                value={benefitPrice.taxPercent}
              />
              <TextField
                fullWidth
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
                label={t('benefits.coverage')}
                name="defaultCompanyCoverageAmount"
                required
                onChange={handleInputChange}
                type="number"
                value={benefitPrice.defaultCompanyCoverageAmount}
              />
            </Box>
            <Box display="flex" gap={3} width="100%">
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label={t('benefits.valid_from')}
                name="validFrom"
                type="date"
                required
                onChange={handleInputChange}
                value={benefitPrice.validFrom}
              />
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label={t('benefits.valid_to')}
                name="validTo"
                onChange={handleInputChange}
                type="date"
                value={benefitPrice.validTo}
              />
            </Box>
            <Button
              endIcon={<AddCircleOutlineIcon />}
              type="submit"
              variant="contained"
            >
              {t('button.save')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
