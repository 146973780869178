import React from 'react';
import { Box, Stack } from '@mui/material';
import { quarters, yearBlockHeight, yearBlockWrapperWidth } from '../consts';
import useBudgetPeriodSelectorContext from '../context/useBudgetPeriodSelectorContext';
import QuarterBlock from './QuarterBlock/QuarterBlock';

export default function QuartersGroup() {
  const { yearGroups } = useBudgetPeriodSelectorContext();

  return (
    <Box
      position="relative"
      width={yearBlockWrapperWidth}
      bgcolor="background.other"
    >
      {yearGroups.map((yearItem) => (
        <Stack
          key={yearItem.year}
          width="100%"
          height={yearBlockHeight}
          sx={{
            position: 'absolute',
            left: 0,
            top: yearItem.top,
          }}
        >
          {quarters.map((quarter) => (
            <QuarterBlock
              key={`quarter-${yearItem.year}-${quarter}`}
              year={yearItem.year}
              quarter={quarter}
            />
          ))}
        </Stack>
      ))}
    </Box>
  );
}
