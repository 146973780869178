import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import DayTile from 'components/HoursViewProject/DayTile/DayTile';
import fullDateFormat from 'helpers/fullDateFormat';
import EmployeeDetails from 'components/HoursViewProject/EmployeeDetails/EmployeeDetails';
import createRangeCalendar from 'helpers/createRangeCalendar';
import {
  useGetWorkingHoursStatsQuery,
  WorkingHours,
  WorkingHoursStats,
} from 'store/workingHours/workingHours';
import { useGetBankHolidaysQuery } from 'store/bankHolidays/bankHolidays';
import Loader from 'common/Loader';
import Error from 'common/Error';
import dateFormat from 'dateformat';
import { EmployeeSingleProjectRate } from 'store/employeeProjectRate/employeeProjectRate';
import { HolidayTypes } from 'enums';
import { Holiday } from 'store/holidays/interfaces';
import { startOfDay, endOfDay } from 'date-fns';
import { EmployeeOfProjectMemberEmployee } from 'hooks/useProjectMemberEmployee/interfaces';

interface WorkingHoursDataProps {
  employee: EmployeeOfProjectMemberEmployee;
  dateRange: [Date, Date];
  workingHours: WorkingHours[];
  projectIri: string;
  holidays: Holiday[];
  projectRates: EmployeeSingleProjectRate[];
  projectRatesLoading: boolean;
  projectRatesFetchError: boolean;
}

function WorkingHoursData({
  dateRange,
  workingHours,
  employee,
  projectIri,
  holidays,
  projectRates,
  projectRatesLoading,
  projectRatesFetchError,
}: WorkingHoursDataProps) {
  const { isLoading: holidaysFetching, isError: holidaysFetchError } =
    useGetBankHolidaysQuery();

  const {
    data: employeeStats = {} as WorkingHoursStats,
    isLoading: employeeWorkingStatsFetching,
    isError: employeeWorkingStatsFetchError,
  } = useGetWorkingHoursStatsQuery(
    {
      id: +employee.id,
      date: dateFormat(dateRange[0], 'yyyy-mm'),
    },
    {
      skip: !employee.id,
    },
  );
  const noRemoteHolidays = holidays.filter(
    (holiday) =>
      holiday.employee === employee['@id'] &&
      holiday.type !== HolidayTypes.remote,
  );
  const range = useMemo(
    () => createRangeCalendar(dateRange[0], dateRange[1]),
    [dateRange],
  );

  const getEmployeeWorkingHours = (employeeIri: string) =>
    workingHours.filter(
      (hours) => hours.employee === employeeIri && hours.project === projectIri,
    );

  const checkIfProjectRateExist = (rates: EmployeeSingleProjectRate[]) =>
    rates.some((rate) => {
      if (!rate.dateTo) {
        return new Date(rate.dateFrom) <= dateRange[0];
      }

      return (
        startOfDay(new Date(rate.dateFrom)) <=
          startOfDay(new Date(dateRange[0])) &&
        endOfDay(new Date(rate.dateTo)) >= endOfDay(new Date(dateRange[1]))
      );
    });

  if (holidaysFetching || employeeWorkingStatsFetching || projectRatesLoading) {
    return <Loader />;
  }

  if (
    holidaysFetchError ||
    employeeWorkingStatsFetchError ||
    projectRatesFetchError
  ) {
    return <Error />;
  }

  const currentProjectRates = projectRates.filter(
    (rate) => rate.project === projectIri,
  );

  return (
    <Grid
      key={employee.id}
      container
      alignItems="center"
      mb={2}
      columnSpacing={2}
    >
      <Grid item xs={6}>
        <Grid container columns={14}>
          {range.map((day) => (
            <DayTile
              key={fullDateFormat(day)}
              dateRange={dateRange}
              day={day}
              employeeHolidays={noRemoteHolidays}
              workingHours={getEmployeeWorkingHours(employee['@id'])}
            />
          ))}
        </Grid>
      </Grid>
      <EmployeeDetails
        employee={employee}
        stats={employeeStats}
        workingHours={getEmployeeWorkingHours(employee['@id'])}
        ratesExist={checkIfProjectRateExist(currentProjectRates)}
      />
    </Grid>
  );
}

export default WorkingHoursData;
