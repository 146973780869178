import React from 'react';
import { Box } from '@mui/material';
import {
  AgreementsData,
  useGetFilteredAgreementsQuery,
} from 'store/agreements/agreements';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import Link from 'common/Link/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  WidgetCaption,
  WidgetCaptionContainer,
  WidgetContainer,
  WidgetTitle,
} from 'common/Widget';
import replaceApiIri from 'helpers/replaceApiIri';
import useMeetingContext from 'pages/Meeting/context/useMeetingContext';

export default function Agreements() {
  const { t } = useTranslation();
  const { meeting, meetingLoading, meetingError } = useMeetingContext();
  const {
    data: agreements = {} as AgreementsData,
    isLoading: agreementsLoading,
    isError: agreementsError,
  } = useGetFilteredAgreementsQuery(
    { employee: replaceApiIri(meeting.employee ?? '', 'employees') },
    { skip: !meeting.employee },
  );

  return (
    <WidgetContainer
      isLoading={agreementsLoading || meetingLoading}
      isError={agreementsError || meetingError}
      title={<WidgetTitle size="lg">{t('menu.agreements')}</WidgetTitle>}
    >
      {agreements?.['hydra:member']?.map((agreement) => (
        <Link
          key={agreement.id}
          to={`/agreements/${agreement.id}`}
          color="text.primary"
          underline="none"
        >
          <WidgetCaptionContainer>
            <Box display="flex" alignItems="center" gap={2}>
              <WidgetCaption size="lg">{agreement.variant}</WidgetCaption>
              <WidgetCaption size="lg">{agreement.type}</WidgetCaption>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              {agreement.dateTo ? (
                <WidgetCaption size="lg">
                  {`${dateFormat(
                    agreement.dateFrom,
                    'yyyy-mm-dd',
                  )}/${dateFormat(agreement.dateTo, 'yyyy-mm-dd')}`}
                </WidgetCaption>
              ) : (
                <WidgetCaption size="lg">
                  {dateFormat(agreement.dateFrom, 'yyyy-mm-dd')}/&infin;
                </WidgetCaption>
              )}
              <TitleTooltip title={t('label.go_to_agreement')}>
                <OpenInNewIcon />
              </TitleTooltip>
            </Box>
          </WidgetCaptionContainer>
        </Link>
      ))}
    </WidgetContainer>
  );
}
