import React, { useState, useMemo } from 'react';
import { isAfter, isBefore } from 'date-fns';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Property } from 'store/properties/interfaces';
import { useGetPropertyBookingsQuery } from 'store/propertyBooking/propertyBooking';
import formatDateToUtcFormat from 'helpers/formatDateToUtcFormat';
import PropertyTypesAccordion from './PropertyTypesAccordion/PropertyTypesAccordion';
import BookPropertiesDatePicker from './BookPropertiesDatePicker/BookPropertiesDatePicker';
import getDateState from './getDateState';
import { BookPropertiesContext } from './context/BookProperties.context';

interface BookPropertiesProps {
  properties: Property[];
}

export default function BookProperties({ properties }: BookPropertiesProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(getDateState());
  const { t } = useTranslation();

  const types = [
    ...new Set(properties.map((property) => property.type)),
  ].sort();
  const { data: bookings = [] } = useGetPropertyBookingsQuery(
    {
      'startDate[before]': formatDateToUtcFormat(selectedDate as Date),
      'dateTo[after]': formatDateToUtcFormat(selectedDate as Date),
    },
    {
      skip: !selectedDate,
    },
  );

  const getPropertiesByType = (type: string) =>
    properties.filter((property) => property.type === type);

  const bookingsFilteredByStartDateTime = bookings.filter((booking) => {

    const startDate = new Date(booking.startDate);
    const endDate = new Date(booking.dateTo);
    // in case one booking ends when other starts
    const endDateMinusSecond = new Date(endDate.setSeconds(endDate.getSeconds() - 1));

    if (!selectedDate) {
      return true
    }

    return !isAfter(startDate, selectedDate) && !isBefore(endDateMinusSecond, selectedDate)
  });

  const activeBookingsInSelectedDateRange =
    bookingsFilteredByStartDateTime.filter((booking) => !booking.cancelledAt);

  const contextValue = useMemo(
    () => ({
      selectedDate,
      setSelectedDate,
    }),
    [selectedDate],
  );

  return (
    <BookPropertiesContext.Provider value={contextValue}>
      <Typography variant="subtitle2">{t('properties.header')}</Typography>
      <BookPropertiesDatePicker />
      <Grid container gap={2}>
        {types.map((type) => (
          <PropertyTypesAccordion
            key={type}
            type={type}
            properties={getPropertiesByType(type)}
            activeBookingsInSelectedDateRange={
              activeBookingsInSelectedDateRange
            }
          />
        ))}
      </Grid>
    </BookPropertiesContext.Provider>
  );
}
