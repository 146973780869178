import React, { useContext } from 'react';
import { differenceInSeconds, isAfter } from 'date-fns';
import {
  useAddPropertyBookingMutation,
  useCancelPropertyBookingMutation,
} from 'store/propertyBooking/propertyBooking';
import { CustomError } from 'store/api';
import useBookingSchedulerContext from 'components/BookProperties/BookingForm/context/useBookingScheduler.context';
import ContentPopover from 'common/ContentPopover/ContentPopover';
import { createBookingId } from 'components/BookProperties/BookingForm/consts';
import roles from 'config/roles/roles';
import useCheckRoles from 'hooks/useCheckRoles';
import useCurrentDateEvery from 'hooks/useCurrentDateEvery';
import dateFormat from 'dateformat';
import { EmployeeContext } from 'pages/PropertyBooking/PropertyBooking';
import checkIsSomeBookingInDateRangeDraftAppointment from 'components/BookProperties/BookingForm/checkIsSomeBookingInDateRangeDraftBooking';
import formatDateToUtcFormat from 'helpers/formatDateToUtcFormat';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';
import { AppointmentContentProps } from './interfaces';
import AppointmentContentShort from './AppointmentContentShort/AppointmentContentShort';
import AppointmentContentMiddle from './AppointmentContentMiddle/AppointmentContentMiddle';
import AppointmentContentLong from './AppointmentContentLong/AppointmentContentLong';

const minuteInMilliseconds = 60 * 1000;

export default function AppointmentContentComponent({
  data,
  durationType,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: AppointmentContentProps) {
  const {
    removeBookingFromState,
    property,
    addBookingToState,
    bookingsInState,
  } = useBookingSchedulerContext();
  const [cancelPropertyBooking] = useCancelPropertyBookingMutation();
  const [addBooking] = useAddPropertyBookingMutation();
  const isPropertyManager = useCheckRoles(roles.properties);
  const currentDateEveryMinute = useCurrentDateEvery(minuteInMilliseconds);
  const selectedEmployee = useContext(EmployeeContext);

  const dateFrom = dateFormat(data.startDate, 'HH:MM');
  const dateTo = dateFormat(data.endDate, 'HH:MM');
  const isBookingFinished = isAfter(
    currentDateEveryMinute,
    data.endDate as Date,
  );
  const isInDraftMode = data.id === createBookingId;

  const isSomeBookingInDateRangeDraftAppointment = isInDraftMode
    ? checkIsSomeBookingInDateRangeDraftAppointment({
        bookingsInState: bookingsInState.filter(
          (booking) => booking.id !== createBookingId,
        ),
        draftBookingStartDate: data.startDate as Date,
        draftBookingEndDate: data.endDate as Date,
      })
    : false;

  const isStartDateAfterCurrentTime = isAfter(
    data.startDate as Date,
    currentDateEveryMinute,
  );

  const isDraftBookingPossibleToAdd =
    isPropertyManager ||
    (isStartDateAfterCurrentTime && !isSomeBookingInDateRangeDraftAppointment);

  const isBookingRemovable =
    isPropertyManager ||
    isInDraftMode ||
    (!isBookingFinished && data.employee === selectedEmployee);

  const { selectedEmployee: employeeData } = useGetSelectedEmployeePreview({
    employeeIri: data.employee,
    filters: { isActive: true },
  });

  const handleCancelPropertyBooking = async () => {
    try {
      if (!data.id) {
        return;
      }
      if (isInDraftMode) {
        removeBookingFromState(data.id as string);
        return;
      }

      await cancelPropertyBooking({ id: data.id });
      removeBookingFromState(data.id as string);
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const handleAddPropertyBooking = async () => {
    try {
      const newBooking = await addBooking({
        employee: data.employee,
        property: property['@id'],
        startDate: formatDateToUtcFormat(data.startDate as Date),
        duration: differenceInSeconds(data.endDate as Date, data.startDate),
      }).unwrap();
      removeBookingFromState(data.id as string);
      addBookingToState({
        startDate: new Date(newBooking.startDate),
        endDate: new Date(newBooking.dateTo),
        title: newBooking.property.name,
        id: newBooking.id.toString(),
        employee: newBooking.employee,
      });
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  if (durationType === 'short') {
    return (
      <ContentPopover
        text={`${employeeData.firstname} ${employeeData.lastname}`}
        id={data.id as string}
      >
        <AppointmentContentShort
          dateFrom={dateFrom}
          handleAddPropertyBooking={handleAddPropertyBooking}
          handleCancelPropertyBooking={handleCancelPropertyBooking}
          isBookingRemovable={isBookingRemovable}
          isInDraftMode={isInDraftMode}
          isDraftBookingPossibleToAdd={isDraftBookingPossibleToAdd}
        />
      </ContentPopover>
    );
  }

  if (durationType === 'middle') {
    return (
      <ContentPopover
        text={`${employeeData.firstname} ${employeeData.lastname}`}
        id={data.id as string}
      >
        <AppointmentContentMiddle
          dateFrom={dateFrom}
          dateTo={dateTo}
          employeeData={employeeData}
          handleAddPropertyBooking={handleAddPropertyBooking}
          handleCancelPropertyBooking={handleCancelPropertyBooking}
          isBookingRemovable={isBookingRemovable}
          isInDraftMode={isInDraftMode}
          isDraftBookingPossibleToAdd={isDraftBookingPossibleToAdd}
        />
      </ContentPopover>
    );
  }

  return (
    <ContentPopover
      text={`${employeeData.firstname} ${employeeData.lastname}`}
      id={data.id as string}
    >
      <AppointmentContentLong
        dateFrom={dateFrom}
        dateTo={dateTo}
        employeeData={employeeData}
        handleAddPropertyBooking={handleAddPropertyBooking}
        handleCancelPropertyBooking={handleCancelPropertyBooking}
        isBookingRemovable={isBookingRemovable}
        isInDraftMode={isInDraftMode}
        isDraftBookingPossibleToAdd={isDraftBookingPossibleToAdd}
      />
    </ContentPopover>
  );
}
