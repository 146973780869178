import React, { useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { CostGroupProps } from 'store/costGroups/costGroups';
import { StyledAccordion } from 'components/ResponsibilityGroup/Accordion.styled';
import getMonthsRange from 'helpers/getMonthsRange';
import { ContractorGroupsCondition } from 'store/condition/interfaces';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import { useGetCostsQuery } from 'store/costs/costs';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { getGroupName } from '../utils';
import CostData from './CostData/CostData';

interface GroupConditionProps {
  groupsCondition: ContractorGroupsCondition;
  groups: CostGroupProps[];
  groupId: number;
}

export default function GroupCondition({
  groupsCondition,
  groups,
  groupId,
}: GroupConditionProps) {
  const { isLoading: costsFetching, isError: costsFetchError } =
    useGetCostsQuery({ properties: ['name', 'id'] });
  const [expanded, setExpanded] = useState(false);
  const currentDate = new Date();
  const dateRange = getMonthsRange(currentDate, 2, 1);
  const formatAmount = useCurrencyFormat();
  const groupCosts = Object.values(groupsCondition).flatMap((costGroup) =>
    Object.keys(costGroup[groupId] || {}),
  );
  const uniqueGroupCosts = [...new Set(groupCosts)];

  const calculateMonthlyCost = (month: string) => {
    let sum = 0;

    if (!groupsCondition[month] || !groupsCondition[month][groupId]) {
      return sum;
    }

    Object.values(groupsCondition[month][groupId]).forEach(({ cost }) => {
      sum += cost;
    });

    return sum;
  };

  if (costsFetching) {
    return <Loader />;
  }

  if (costsFetchError) {
    return <Error />;
  }

  return (
    <Grid item xs={13}>
      <StyledAccordion disableGutters expanded={expanded} square>
        <AccordionSummary
          onClick={() => setExpanded((prevState) => !prevState)}
          sx={{ px: 0, m: 0 }}
        >
          <Grid container columns={13} alignItems="center">
            <Grid item xs={2.8}>
              <Typography pl={1} variant="body2">
                {getGroupName(groups, groupId)}
              </Typography>
            </Grid>
            {dateRange.map((month) => (
              <React.Fragment key={month}>
                <Grid item xs={1.2}>
                  <Typography variant="smallBody">
                    {formatAmount(calculateMonthlyCost(month))}
                  </Typography>
                </Grid>
                <Grid item xs={1.8} />
              </React.Fragment>
            ))}
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            px: 1,
            py: 1,
            bgcolor: 'secondary.light',
          }}
        >
          {uniqueGroupCosts.map((cost) => (
            <CostData
              key={cost}
              costId={+cost}
              dateRange={dateRange}
              groupId={groupId}
              groupsCondition={groupsCondition}
            />
          ))}
        </AccordionDetails>
      </StyledAccordion>
    </Grid>
  );
}
