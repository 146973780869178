import {
  styled,
  Table,
  TableCell,
  TableCellProps,
  TableProps,
} from '@mui/material';

export const StyledTable = styled(Table)<TableProps>({
  borderCollapse: 'collapse',
  borderSpacing: 0,
});

export const StyledTableCell = styled(TableCell)<TableCellProps>({
  borderBottom: 'none',
  borderTop: 'none',
  background: 'transparent',
  padding: '0.4rem',

  '&:first-of-type': {
    borderLeft: 'none',
  },

  '&:last-of-type': {
    borderRight: 'none',
  },
});
