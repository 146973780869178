import React from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography,
} from '@mui/material';
import StyledCalendar from 'components/EditHolidayForm/DateRange/styled/Calendar.styled';
import { useTranslation } from 'react-i18next';
import { useGetProjectsPreviewQuery } from 'store/projects/projects';
import { ViewCallbackProperties } from 'react-calendar';
import { ProjectStatuses } from 'enums';
import getCalendarType from 'helpers/getCalendarType';
import useOrganizationStartWeekDay from 'hooks/useOrganizationStartWeekDay';

export interface HoursViewFiltersProps {
  dateRange: [Date, Date];
  setDateRange: React.Dispatch<React.SetStateAction<[Date, Date]>>;
  selectedProjects: string[];
  handleProjectsSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function HoursViewFilters({
  dateRange,
  setDateRange,
  selectedProjects,
  handleProjectsSelect,
}: HoursViewFiltersProps) {
  const { t } = useTranslation();
  const { data: projects = [] } = useGetProjectsPreviewQuery({
    status: ProjectStatuses.active,
  });
  const startWeekDay = useOrganizationStartWeekDay();
  const calendarTypeName = getCalendarType(startWeekDay);

  const handleMonthChange = (value: ViewCallbackProperties) => {
    const activeDate = value.activeStartDate;
    const activeDateYear = activeDate.getFullYear();
    const activeDateMonth = activeDate.getMonth();
    const lastActiveMonthDay = new Date(activeDateYear, activeDateMonth + 1, 0);
    setDateRange([activeDate, lastActiveMonthDay]);
  };

  const handleDateChange = (date: Date | [Date, Date]) => {
    if (Array.isArray(date)) {
      setDateRange(date);
    }
  };

  const sortedProjects = [...projects].sort((currentProject, nextProject) =>
    currentProject.name.localeCompare(nextProject.name),
  );

  return (
    <>
      <Grid item xs={8}>
        <Typography variant="body1">{t('label.date_range')}</Typography>
        <StyledCalendar
          onActiveStartDateChange={(value) => handleMonthChange(value)}
          onChange={handleDateChange}
          selectRange
          value={dateRange}
          calendarType={calendarTypeName}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth sx={{ height: '100%' }}>
          <InputLabel htmlFor="projects-select" shrink>
            {t('label.projects')}
          </InputLabel>
          <Select
            inputProps={{
              id: 'projects-select',
            }}
            label={t('label.projects')}
            multiple
            native
            // @ts-ignore
            onChange={handleProjectsSelect}
            sx={{
              height: '100%',
              '& .MuiNativeSelect-select[multiple]': {
                height: '-webkit-fill-available',
              },
            }}
            value={selectedProjects}
          >
            {sortedProjects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export default HoursViewFilters;
