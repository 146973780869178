import React from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BankAccount, useGetAccountsQuery } from 'store/accounts/accounts';
import tableHeaders from 'components/AccountsList/tableHeaders';
import Error from 'common/Error';
import Loader from 'common/Loader';
import EditButton from 'common/EditButton/EditButton';
import { useTranslation } from 'react-i18next';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate } from 'react-router-dom';
import useCheckRoles from 'hooks/useCheckRoles';
import roles from 'config/roles/roles';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import replaceApiIri from 'helpers/replaceApiIri';

export interface AccountsListProps {
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setChosenAccount: React.Dispatch<React.SetStateAction<string>>;
}

export default function AccountsList({
  setIsEditOpen,
  setChosenAccount,
}: AccountsListProps) {
  const {
    data: accounts = [],
    isError: accountsFetchError,
    isLoading: accountsLoading,
  } = useGetAccountsQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTransactionsManager = useCheckRoles(roles.transactionsManager);
  const isAccountsManager = useCheckRoles(roles.banksManager);

  if (accountsFetchError) {
    return <Error />;
  }

  if (accountsLoading) {
    return <Loader />;
  }

  const handleOpenAccountEdit = (account: BankAccount) => {
    setChosenAccount(account.id);
    setIsEditOpen(true);
  };

  return (
    <TableContainer sx={{ px: 3, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.label} align={header.align}>
                <Typography color="text.secondary" variant="tableHeader">
                  {t(header.label)}
                </Typography>
              </TableCell>
            ))}
            <TableCell colSpan={tableHeaders.length} />
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((account) => (
            <TableRow key={account.id}>
              <TableCell>
                <Typography variant="body2">{account.bank.name}</Typography>
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body2">{account.name}</Typography>
                  {account.default && (
                    <TitleTooltip
                      title={t('bank_accounts.default_popover')}
                      placement="right"
                    >
                      <FavoriteBorderIcon />
                    </TitleTooltip>
                  )}
                </Box>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="center">
                  {account.type}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="center">
                  {replaceApiIri(account.currency, 'currencies').toUpperCase()}{' '}
                  {'**** '.repeat(3)}
                  {account.number.slice(-4)}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={2}
                >
                  {isTransactionsManager && (
                    <Button
                      onClick={() => navigate(`/transactions/${account.id}`)}
                      variant="contained"
                    >
                      {t('transactions.header')}
                    </Button>
                  )}
                  {isAccountsManager && (
                    <EditButton
                      onClick={() => handleOpenAccountEdit(account)}
                    />
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
