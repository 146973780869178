import { styled } from '@mui/material';
import Calendar from 'react-calendar';

const StyledCalendar = styled(Calendar)(({ theme }) => ({
  borderRadius: '0.8rem',
  width: '100%',

  '& button': {
    borderRadius: '0.8rem',
    fontFamily: 'Plus Jakarta Sans',
  },

  '& .react-calendar__tile': {
    '&:hover': {
      background: theme.palette.secondary.light,
    },
    '&:disabled': {
      background: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
    },
    '&.react-calendar__tile--booked': {
      background: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      '&.react-calendar__tile--active, .react-calendar__tile--hasActive': {
        background: theme.palette.primary.main,
        color: theme.palette.main.white,
      },
    },
  },

  '& .react-calendar__tile--now': {
    background: 'none',
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  },

  '& .react-calendar__tile--active, .react-calendar__tile--hasActive': {
    background: theme.palette.primary.main,
    color: theme.palette.main.white,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

export default StyledCalendar;
