import React, { useMemo, useState, useCallback } from 'react';
import { Budget, BudgetConfig } from 'store/budget/interfaces';
import createBudgetConfigData from 'pages/BudgetPlanner/context/createBudgetConfigData';
import { OnUpdateBudgetPeriodProps } from 'components/BudgetPlannerChart/interfaces';
import BACKEND_AMOUNT_MODIFIER from 'config/backendAmountModifier';
import { BudgetPlannerContext } from './BudgetPlanner.context';

interface BudgetPlannerProviderProps {
  children: React.ReactNode;
  budget: Budget;
}

export default function BudgetPlannerProvider({
  children,
  budget,
}: BudgetPlannerProviderProps) {
  const [budgetConfigData, setBudgetConfigData] = useState(
    createBudgetConfigData(budget, true),
  );

  const onUpdateBudgetConfig = useCallback(
    ({ period, field, value }: OnUpdateBudgetPeriodProps) => {
      const updatedConfigItems = budgetConfigData.map((configItem) =>
        configItem.period === period
          ? {
              ...configItem,
              [field]: value === '' ? undefined : +value,
            }
          : configItem,
      );

      const filteredConfigItems = updatedConfigItems.filter((configItem) =>
        [
          configItem.baseAmount,
          configItem.linearGrowth,
          configItem.exponentialGrowth,
          configItem.unitGrowth,
        ].some((configField) => configField !== undefined),
      );

      const convertConfigItem = (config: BudgetConfig) => ({
        period: config.period.replaceAll('.', ''),
        ...(config.baseAmount !== undefined && {
          baseAmount: config.baseAmount * BACKEND_AMOUNT_MODIFIER,
        }),
        ...(config.linearGrowth !== undefined && {
          linearGrowth: config.linearGrowth,
        }),
        ...(config.exponentialGrowth !== undefined && {
          exponentialGrowth: config.exponentialGrowth,
        }),
        ...(config.unitGrowth !== undefined && {
          unitGrowth: config.unitGrowth,
        }),
      });

      const convertedBudgetConfig = filteredConfigItems.map(convertConfigItem);

      const budgetWithNewConfig = {
        ...budget,
        config: convertedBudgetConfig,
      };

      const newBudgetConfigData = createBudgetConfigData(budgetWithNewConfig);

      setBudgetConfigData(newBudgetConfigData);
    },
    [budget, budgetConfigData],
  );

  const handleResetBudgetConfig = useCallback(() => {
    setBudgetConfigData(createBudgetConfigData(budget, true));
  }, [budget]);

  const contextValue = useMemo(
    () => ({
      budget,
      budgetConfigData,
      onUpdateBudgetConfig,
      handleResetBudgetConfig,
    }),
    [budget, budgetConfigData, handleResetBudgetConfig, onUpdateBudgetConfig],
  );

  return (
    <BudgetPlannerContext.Provider value={contextValue}>
      {children}
    </BudgetPlannerContext.Provider>
  );
}
