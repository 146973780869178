import React, { useState } from 'react';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { useTranslation } from 'react-i18next';
import {
  useGetPropertiesQuery,
  useAddParentToPropertyMutation,
} from 'store/properties/properties';
import { Property } from 'store/properties/interfaces';
import { StatusProps } from 'hoc/Status/Status';
import { CustomError } from 'store/api';
import replaceApiIri from 'helpers/replaceApiIri';
import ChildData from './ChildData';

export interface PropertyChildrenProps extends StatusProps {
  property: Property;
}

export default function PropertyChildren({
  property,
  setIsSuccess,
  setIsError,
  setErrorMessage,
}: PropertyChildrenProps) {
  const { t } = useTranslation();
  const {
    data: properties = [],
    isError: propertiesFetchError,
    isLoading: propertiesFetching,
  } = useGetPropertiesQuery({});
  const [addParenToProperty] = useAddParentToPropertyMutation();
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const propertyHasNoChildren = (propertyItem: Property) =>
    !propertyItem.children?.length;
  const propertyHasNoParent = (propertyItem: Property) => !propertyItem.parent;

  const availableProperties = properties.filter(
    (propertyItem) =>
      (!propertyItem.id || propertyItem.id !== property?.id) &&
      propertyHasNoChildren(propertyItem) &&
      propertyHasNoParent(propertyItem),
  );

  const getProperty = (propertyIri: string | undefined) => {
    const propertyData = {} as Property;

    if (!propertyIri) {
      return propertyData;
    }

    return (
      properties.find((item) => item['@id'] === propertyIri) || propertyData
    );
  };

  if (propertiesFetching) {
    return <Loader />;
  }

  if (propertiesFetchError) {
    return <Error />;
  }

  const handleAddChild = async (propertyIri: string | null) => {
    if (!propertyIri) {
      return;
    }
    try {
      await addParenToProperty({
        id: replaceApiIri(propertyIri, 'properties'),
        parent: property['@id'],
      }).unwrap();
      setSelectedValue(null);
      setIsSuccess(true);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data['hydra:description']);
      }
      setIsError(true);
    }
  };

  const propertyChildren =
    property?.children?.map((child) => getProperty(child)) || [];

  const sortedPropertyChildren = propertyChildren.sort(
    (currentChildren, nextChildren) =>
      currentChildren.name.localeCompare(nextChildren.name),
  );

  return (
    <Box bgcolor="main.white" borderRadius={4}>
      <Stack p={3} spacing={3}>
        <Autocomplete
          ChipProps={{
            sx: {
              fontSize: '1.2rem',
            },
          }}
          fullWidth
          getOptionLabel={(option) => getProperty(option).name}
          onChange={(event, newValue) => {
            setSelectedValue(newValue);
            handleAddChild(newValue);
          }}
          options={availableProperties.map((item) => item['@id'])}
          renderInput={(params) => (
            <TextField {...params} label={t('label.children')} />
          )}
          value={selectedValue}
        />
        <Stack spacing={2}>
          {sortedPropertyChildren.map((childProperty) => (
            <ChildData key={childProperty.id} property={childProperty} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
