import React from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import InvoiceClient from 'components/InvoicesList/InvoiceClient/InvoiceClient';
import Link from 'common/Link/Link';
import dateFormat from 'dateformat';
import TitleTooltip from 'common/TitleTooltip/TitleTooltip';
import { useTranslation } from 'react-i18next';
import useCurrencyFormat from 'hooks/useCurrencyFormat';
import ProgressChart from 'common/ProgressChart/ProgressChart';
import { Invoice } from 'store/incomes/interfaces';
import DownloadIcon from 'assets/icons/DownloadIcon';
import downloadFileWithAuth from 'helpers/downloadFileWithAuth';
import InvoiceMenu from '../MonthlyAmounts/InvoiceMenu/InvoiceMenu';
import StatusChip from './StatusChip/StatusChip';

const invoiceSumToPaidMultiplier = 100;

interface InvoiceProps {
  income: Invoice;
  isSelected: (value: string) => boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function InvoiceComponent({ income, isSelected, handleChange }: InvoiceProps) {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { t } = useTranslation();
  const formatAmount = useCurrencyFormat();

  const invoiceAmountSum = income.amount * invoiceSumToPaidMultiplier;
  const invoicePaidAmount = income.incomeTransactions?.reduce(
    (acc, { amount }) => acc + amount,
    0,
  );
  const invoicePaymentPercentage = (invoicePaidAmount / invoiceAmountSum) * 100;

  return (
    <TableRow>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Checkbox
            checked={isSelected(String(income.id))}
            name={income.name}
            onChange={handleChange}
            value={String(income.id)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: '2.4rem' } }}
          />
          <Stack>
            <Link
              variant="body1"
              color="text.primary"
              to={`/invoices/${income.id}/edit`}
              underline="none"
            >
              {income.name}
            </Link>
            <InvoiceClient
              clientIri={income.client}
              textColor="text.secondary"
            />
          </Stack>
        </Stack>
      </TableCell>
      <TableCell align="right">
        <StatusChip status={income.status} />
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">
          {dateFormat(income.saleDate, 'yyyy-mm-dd')}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
          <ProgressChart percentageValue={invoicePaymentPercentage} />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Typography fontWeight={700} variant="body2">
          {formatAmount(income.amountNet, income.currency)}
          <Typography component="span" variant="body2" color="text.secondary">
            &nbsp;netto
          </Typography>
        </Typography>
        <Typography fontWeight={700} variant="caption" color="text.secondary">
          {formatAmount(income.amount, income.currency)}
          <Typography component="span" variant="caption" color="text.secondary">
            &nbsp;brutto
          </Typography>
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={1}
          flexWrap="wrap"
          minWidth="11.6rem"
        >
          <TitleTooltip title={t('label.download')}>
            <IconButton
              onClick={() =>
                downloadFileWithAuth(
                  `${apiUrl}/incomes/${income.id}`,
                  income.name,
                  true,
                )
              }
            >
              <DownloadIcon color="primary" fontSize="large" />
            </IconButton>
          </TitleTooltip>
          <InvoiceMenu income={income} />
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default InvoiceComponent;
