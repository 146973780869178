import React from 'react';
import { Grid, IconButton, Stack, Typography, Chip, Box } from '@mui/material';
import { Client, useGetClientQuery } from 'store/clients/clients';
import { useNavigate } from 'react-router';
import StyledContentBox from 'config/material-ui/styled/ContentBox.styled';
import DoubleDotIcon from 'assets/icons/DoubleDotIcon';
import ResourceMockIcon from 'assets/icons/ResourceMockIcon';
import CircleIcon from '@mui/icons-material/Circle';
import { ResourceRequest } from 'store/resourceRequests/interfaces';
import replaceApiIri from 'helpers/replaceApiIri';
import TileTags from 'components/TileTags/TileTags';

export interface ResourceTileProps {
  resourceRequest: ResourceRequest;
}

export default function ResourceRequestTile({
  resourceRequest,
}: ResourceTileProps) {
  const navigate = useNavigate();
  const { data: resourceClient = {} as Client } = useGetClientQuery(
    replaceApiIri(resourceRequest.client ?? '', 'clients'),
    {
      skip: !resourceRequest.client,
    },
  );

  return (
    <Grid item xs={4}>
      <StyledContentBox>
        <Stack py={3} spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={3}
          >
            <Chip
              color="primary"
              label={
                <Typography variant="chipLabel" color="main.white">
                  {resourceRequest.status}
                </Typography>
              }
            />
            <IconButton
              onClick={() =>
                navigate(`/human-resources/resources/${resourceRequest.id}`)
              }
              sx={{
                width: '4rem',
                height: '4rem',
              }}
            >
              <DoubleDotIcon
                sx={{
                  fontSize: '2rem',
                  color: 'icon.dark',
                }}
              />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={2} px={3} alignItems="center">
            <IconButton
              sx={{
                width: '4rem',
                height: '4rem',
              }}
            >
              <ResourceMockIcon sx={{ fontSize: '4rem' }} />
            </IconButton>
            <Stack>
              <Typography variant="body1" fontWeight={700}>
                {resourceRequest.position}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2" color="text.secondary">
                  {resourceRequest.location}
                </Typography>
                {resourceRequest.client && resourceClient.name && (
                  <>
                    <CircleIcon
                      sx={{
                        fontSize: '0.5rem',
                        color: 'border.divider',
                      }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {resourceClient.name}
                    </Typography>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
          {!!resourceRequest.tags?.length && (
            <Box px={3}>
              <TileTags tags={resourceRequest.tags} />
            </Box>
          )}
        </Stack>
      </StyledContentBox>
    </Grid>
  );
}
