import api, { CustomError } from 'store/api';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';
import { StorageResponse } from 'store/storage/storage';
import { DocumentData, DocumentsResponseDto } from 'store/documents/documents';
import { ApiFilters, ApiResponse } from 'store/interfaces';

export interface Client {
  ['@id']: string;
  id: number;
  name: string;
  status: string;
  country: string;
  currency: string;
  defaultCost: string | null;
  uploadAgreement: string;
  documentsLanguage: string;
  vatNumber: string | null;
  dueDays: string;
  files: StorageResponse[];
  addressCity?: string;
  addressCountry?: string;
  addressPhoneNumber?: string;
  addressPostCode?: string;
  addressStreetLine?: string;
  invoiceComment: string;
}

export interface ClientsResponseDto extends ApiResponse {
  'hydra:member': Client[];
}

export interface ClientMutation
  extends Omit<
    Client,
    | 'dueDays'
    | 'id'
    | 'addressCity'
    | 'addressCountry'
    | 'addressPhoneNumber'
    | 'addressPostCode'
    | 'addressStreetLine'
  > {
  dueDays: number;
  id: string | number;
  addressCity: string | null;
  addressCountry: string | null;
  addressPhoneNumber: string | null;
  addressPostCode: string | null;
  addressStreetLine: string | null;
}

export interface ClientFilters extends ApiFilters<Client> {}

export const clientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<Client[], Partial<ClientFilters>>({
      query: (filters) => ({
        url: '/clients',
        params: filters,
      }),
      providesTags: ['Clients'],
      transformResponse: (response: ClientsResponseDto) =>
        response['hydra:member'].sort((client, comparedClient) =>
          client.name.localeCompare(comparedClient.name),
        ),
    }),
    getClient: builder.query<Client, string>({
      query: (id) => `/clients/${id}`,
      providesTags: ['Clients'],
    }),
    addClient: builder.mutation({
      query: (body) => ({
        url: '/clients',
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: createdClient } = await queryFulfilled;

          const clientsCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Clients' },
          ]);

          clientsCache
            .filter(({ endpointName }) => endpointName === 'getClients')
            .forEach(({ originalArgs }) => {
              dispatch(
                clientsApi.util.updateQueryData(
                  'getClients',
                  originalArgs,
                  (draft) => {
                    draft?.push(createdClient);
                    draft?.sort((client, comparedClient) =>
                      client.name.localeCompare(comparedClient.name),
                    );
                  },
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    updateClient: builder.mutation<Client, Partial<ClientMutation>>({
      query: ({ id, ...body }) => ({
        url: `/clients/${id}`,
        headers: { 'content-type': 'application/merge-patch+json' },
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedClient } = await queryFulfilled;

          const clientsCache = api.util.selectInvalidatedBy(getState(), [
            { type: 'Clients' },
          ]);

          clientsCache
            .filter(({ endpointName }) => endpointName === 'getClients')
            .forEach(({ originalArgs }) => {
              dispatch(
                clientsApi.util.updateQueryData(
                  'getClients',
                  originalArgs,
                  (draft) => {
                    const clientIndex = draft?.findIndex(
                      (client) => client.id === updatedClient.id,
                    );
                    if (clientIndex !== -1) {
                      draft?.splice(clientIndex, 1, updatedClient);
                      draft?.sort((client, comparedClient) =>
                        client.name.localeCompare(comparedClient.name),
                      );
                    }
                  },
                ),
              );
            });

          clientsCache
            .filter(
              ({ endpointName, originalArgs }) =>
                endpointName === 'getClient' && originalArgs === String(id),
            )
            .forEach(({ originalArgs }) => {
              dispatch(
                clientsApi.util.updateQueryData(
                  'getClient',
                  originalArgs,
                  () => updatedClient,
                ),
              );
            });
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    getClientDocuments: builder.query<DocumentData[], number>({
      query: (id) => `/clients/${id}/documents`,
      providesTags: ['Documents'],
      transformResponse: (response: DocumentsResponseDto) =>
        response['hydra:member'],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useGetClientDocumentsQuery,
} = clientsApi;
