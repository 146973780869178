import { MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import { setIsAuthorized, setToken } from 'store/authorization/actions';
import { setOrganization } from 'store/organizations/actions';
import api from 'store/api';
import { Config } from 'store/organizations/defaultState';

const unauthorizedErrorHandler =
  (middlewareAPI: MiddlewareAPI) =>
  (next: any) =>
  (action: { payload: { status: number } }) => {
    const { isAuthorized } = middlewareAPI.getState().authorization;
    if (
      isRejectedWithValue(action) &&
      action.payload.status === 401 &&
      isAuthorized
    ) {
      localStorage.removeItem('token');
      localStorage.removeItem('selectedOrganizationId');
      middlewareAPI.dispatch(setIsAuthorized(false));
      middlewareAPI.dispatch(setToken(null));
      middlewareAPI.dispatch(api.util.resetApiState());
      middlewareAPI.dispatch(
        setOrganization({
          roles: [],
          possibleRoles: [],
          instance: null,
          name: null,
          defaultCurrency: null,
          features: [],
          config: {} as Config,
          id: '',
        }),
      );
    }
    return next(action);
  };

export default unauthorizedErrorHandler;
