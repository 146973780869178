import useBudgetPeriodContext from 'pages/BudgetPeriod/context/useBudgetPeriodContext';
import { useState } from 'react';
import { BudgetPeriod } from 'store/budget/interfaces';
import useBudgetPeriodSelectorContext from '../context/useBudgetPeriodSelectorContext';
import { monthBlockHeight } from '../consts';

interface UseIndicatorResizeProps {
  boxRef: React.RefObject<HTMLDivElement>;
  monthPeriodWidth: number;
  quarterPeriodWidth: number;
  yearPeriodWidth: number;
  top: number;
  setTop: (top: number) => void;
}

export default function useIndicatorResize({
  boxRef,
  monthPeriodWidth,
  quarterPeriodWidth,
  yearPeriodWidth,
  top,
  setTop,
}: UseIndicatorResizeProps) {
  const { setIsCreatingPeriod } = useBudgetPeriodSelectorContext();
  const { modifyDateToByMonths, modifyDateFromByMonths, handleChangePeriod } =
    useBudgetPeriodContext();
  const [resizableHeight, setResizableHeight] = useState<number | null>(null);
  const [resizableWidth, setResizableWidth] = useState<number | null>(null);

  const handleResizeBottom = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const startY = event.clientY;
    if (!boxRef.current) {
      return;
    }
    const startHeight = boxRef.current.offsetHeight;

    const handleMouseMove = (eventMove: MouseEvent) => {
      const newHeight = startHeight + (eventMove.clientY - startY);
      if (newHeight < monthBlockHeight) {
        return;
      }
      setResizableHeight(newHeight);
    };

    const handleMouseUp = (eventUp: MouseEvent) => {
      const endY = eventUp.clientY;
      const monthDiff = Math.round((endY - startY) / monthBlockHeight);

      modifyDateToByMonths(monthDiff);
      setResizableHeight(null);
      setIsCreatingPeriod(false);

      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleResizeTop = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const startY = event.clientY;
    if (!boxRef.current) {
      return;
    }
    const startHeight = boxRef.current.offsetHeight;
    const startTop = top;

    const handleMouseMove = (eventMove: MouseEvent) => {
      const newHeight = startHeight + (startY - eventMove.clientY);
      const newTop = startTop - (startY - eventMove.clientY);

      if (newHeight < monthBlockHeight) {
        return;
      }
      setResizableHeight(newHeight);
      setTop(newTop);
    };

    const handleMouseUp = (eventUp: MouseEvent) => {
      const endY = eventUp.clientY;
      const monthDiff = -1 * Math.round((startY - endY) / monthBlockHeight);

      modifyDateFromByMonths(monthDiff);
      setResizableHeight(null);

      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleResizeRight = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const startX = event.clientX;

    if (!boxRef.current) {
      return;
    }
    const startWidth = boxRef.current.offsetWidth;

    const handleMouseMove = (eventMove: MouseEvent) => {
      const newWidth = startWidth + (eventMove.clientX - startX);
      if (newWidth < monthPeriodWidth || newWidth > yearPeriodWidth) {
        return;
      }
      setResizableWidth(newWidth);
    };

    const handleMouseUp = (eventUp: MouseEvent) => {
      const newWidth = startWidth + (eventUp.clientX - startX);

      let period = BudgetPeriod.month;

      if (newWidth > quarterPeriodWidth) {
        period = BudgetPeriod.year;
      } else if (newWidth > monthPeriodWidth) {
        period = BudgetPeriod.quarter;
      }

      handleChangePeriod(period);
      setResizableWidth(null);

      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return {
    handleResizeBottom,
    handleResizeTop,
    handleResizeRight,
    resizableHeight,
    resizableWidth,
  };
}
