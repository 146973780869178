import { BookingType } from 'components/Properties/enums';
import dateFormat from 'dateformat';

const getBookingDateToFormat = (bookingType: string, bookingDate: string) => {
  const convertedBookingDate = new Date(bookingDate);

  if (bookingType === BookingType.minutes) {
    return dateFormat(convertedBookingDate, 'yyyy-mm-dd HH:MM');
  }

  convertedBookingDate.setSeconds(convertedBookingDate.getSeconds() - 1);
  return dateFormat(convertedBookingDate, 'yyyy-mm-dd');
};

export default getBookingDateToFormat;
