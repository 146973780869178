import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  AccordionDetails,
  Button,
  Dialog,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ResponsibilityGroup as GroupProps } from 'store/responsibilityGroups/interfaces';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useGetResponsibilitiesQuery } from 'store/responsibilityGroups/responsibilityGroups';
import useGetEmployeesPreview from 'hooks/useGetEmployeesPreview';
import Loader from 'common/Loader';
import Error from 'common/Error';
import { StyledAccordion, StyledSummary } from './Accordion.styled';
import Details from './Details/Details';
import AddResponsibility from './AddResponsibility';
import EmployeeBadge from './EmployeeBadge/EmployeeBadge';

export interface ResponsibilityGroupProps {
  group: GroupProps;
}

export default function ResponsibilityGroup({
  group,
}: ResponsibilityGroupProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const {
    data: responsibilities = [],
    isLoading: responsibilitiesFetching,
    isError: responsibilitiesFetchError,
    isSuccess: responsibilitiesFetched,
  } = useGetResponsibilitiesQuery(
    {
      responsibilityGroup: [String(group.id)],
    },
    { skip: !group || !expanded },
  );
  const { data: employees = [] } = useGetEmployeesPreview();
  let responsibleEmployees: string[] = [];

  if (group.fillStats) {
    responsibleEmployees = Object.keys(group.fillStats).filter(
      (employeeStat) => +employeeStat !== group.responsibleEmployee.id,
    );
  }

  const getEmployeeName = (employeeId: string) => {
    const employeeData = employees.find(
      (employee) => employee.id === +employeeId,
    );
    if (!employeeData) {
      return '';
    }

    return `${employeeData.lastname} ${employeeData.firstname}`;
  };

  const getEmployeeStat = (employeeId: number) => {
    if (!group.fillStats) {
      return 0;
    }
    const employeeStat = Object.keys(group.fillStats).find(
      (employee) => +employee === employeeId,
    );

    if (!employeeStat) {
      return 0;
    }

    return Math.round(group.fillStats[+employeeStat]);
  };

  const getEmployeeAvatar = (employeeId: string) => {
    const employeeData = employees.find(
      (employee) => employee.id === +employeeId,
    );
    if (!employeeData) {
      return undefined;
    }

    return employeeData.avatarUrl;
  };

  return (
    <>
      <Grid item xs={12}>
        <StyledAccordion
          expanded={expanded}
          square
          TransitionProps={{
            unmountOnExit: true,
          }}
        >
          <StyledSummary
            expandIcon={<ArrowForwardIosIcon sx={{ color: 'icon.light' }} />}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => setExpanded((prevState) => !prevState)}
              mr={2}
              width="100%"
              sx={{ cursor: 'pointer' }}
            >
              <Typography variant="h6">{group.name}</Typography>
              <Box display="flex" alignItems="center" gap={3}>
                {group.fillStats &&
                  responsibleEmployees.map((stat) => (
                    <EmployeeBadge
                      key={stat}
                      employeeName={getEmployeeName(stat)}
                      percentage={getEmployeeStat(+stat)}
                      avatarUrl={getEmployeeAvatar(stat)}
                    />
                  ))}
              </Box>
              <EmployeeBadge
                employeeName={`${group.responsibleEmployee.lastname} ${group.responsibleEmployee.firstname}`}
                percentage={getEmployeeStat(group.responsibleEmployee.id)}
                avatarUrl={group.responsibleEmployee.avatarUrl}
              />
            </Box>
            {expanded && (
              <Button
                endIcon={<AddCircleOutlineIcon />}
                onClick={() => setIsAddFormOpen(true)}
                variant="contained"
                sx={{ minWidth: 'fit-content' }}
              >
                {t('responsibilities.add_new')}
              </Button>
            )}
          </StyledSummary>
          <AccordionDetails>
            {responsibilitiesFetching && <Loader />}
            {responsibilitiesFetchError && <Error />}
            {responsibilitiesFetched &&
              responsibilities.map((responsibility) => (
                <Details
                  key={responsibility.id}
                  responsibility={responsibility}
                />
              ))}
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Dialog
        fullWidth
        onClose={() => setIsAddFormOpen(false)}
        open={isAddFormOpen}
      >
        <AddResponsibility group={group} setIsOpen={setIsAddFormOpen} />
      </Dialog>
    </>
  );
}
