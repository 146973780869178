import { useContext } from 'react';
import {
  BudgetPlannerContext,
  BudgetPlannerContextProps,
} from './BudgetPlanner.context';

function useBudgetPlannerContext(): BudgetPlannerContextProps {
  const context = useContext(BudgetPlannerContext);

  if (!context) {
    throw new Error(
      'useBudgetPlannerContext must be used within a BudgetPlannerProvider',
    );
  }

  return context;
}

export default useBudgetPlannerContext;
