import api, { CustomError } from 'store/api';
import { setErrorMessage, setErrorStatus } from 'store/status/actions';
import replaceApiIri from 'helpers/replaceApiIri';
import {
  Property,
  PropertyBookingFilters,
  PropertiesResponseDto,
  PropertyPostRequestDto,
  PropertyPatchRequestDto,
  PropertyTypesResponseDto,
  UpdatePropertyParentRequestDto,
} from './interfaces';

export const propertiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query<Property[], Partial<PropertyBookingFilters>>({
      query: (params) => ({
        url: '/properties',
        params,
      }),
      providesTags: ['Properties'],
      transformResponse: (response: PropertiesResponseDto) =>
        response['hydra:member'].sort((property, comparedProperty) =>
          property.name.localeCompare(comparedProperty.name),
        ),
    }),
    getProperty: builder.query<Property, string>({
      query: (id) => `/properties/${id}`,
      providesTags: ['Properties'],
    }),
    addProperty: builder.mutation<Property, PropertyPostRequestDto>({
      query: (body) => ({
        url: '/properties',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Properties'],
    }),
    updateProperty: builder.mutation<Property, PropertyPatchRequestDto>({
      query: ({ id, ...body }) => ({
        url: `/properties/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProperty } = await queryFulfilled;
          dispatch(
            propertiesApi.util.updateQueryData(
              'getProperty',
              String(id),
              () => updatedProperty,
            ),
          );
          dispatch(
            propertiesApi.util.updateQueryData('getProperties', {}, (draft) => {
              const propertyIndex = draft?.findIndex(
                (property) => property.id === updatedProperty.id,
              );
              if (propertyIndex !== -1) {
                draft?.splice(propertyIndex, 1, updatedProperty);
              }
            }),
          );
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    addParentToProperty: builder.mutation<
      Property,
      UpdatePropertyParentRequestDto
    >({
      query: ({ id, ...body }) => ({
        url: `/properties/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify(body),
      }),
      async onQueryStarted(
        { id: propertyId, parent: parentIri },
        { dispatch, queryFulfilled },
      ) {
        const propertyParentId = replaceApiIri(parentIri, 'properties');

        try {
          const { data: updatedProperty } = await queryFulfilled;
          dispatch(
            propertiesApi.util.updateQueryData(
              'getProperty',
              String(propertyId),
              () => updatedProperty,
            ),
          );

          dispatch(
            propertiesApi.util.updateQueryData(
              'getProperty',
              String(propertyParentId),
              (draft) => {
                if (!draft.children) {
                  // eslint-disable-next-line no-param-reassign
                  draft.children = [];
                }
                draft.children.push(updatedProperty['@id']);
              },
            ),
          );

          const propertyParentCache = await dispatch(
            propertiesApi.endpoints.getProperty.initiate(propertyParentId),
          );

          dispatch(
            propertiesApi.util.updateQueryData('getProperties', {}, (draft) => {
              const propertyIndex = draft?.findIndex(
                (property) => property.id === updatedProperty.id,
              );
              const propertyParentIndex = draft?.findIndex(
                (property) => property['@id'] === parentIri,
              );

              if (propertyIndex !== -1) {
                draft?.splice(propertyIndex, 1, updatedProperty);
              }

              if (propertyParentIndex !== -1 && propertyParentCache.data) {
                draft?.splice(propertyParentIndex, 1, propertyParentCache.data);
              }
            }),
          );
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    deleteParentFromProperty: builder.mutation<
      Property,
      UpdatePropertyParentRequestDto
    >({
      query: ({ id }) => ({
        url: `/properties/${id}`,
        method: 'PATCH',
        headers: { 'content-type': 'application/merge-patch+json' },
        body: JSON.stringify({ parent: null }),
      }),
      async onQueryStarted(
        { id: propertyId, parent: parentIri },
        { dispatch, queryFulfilled },
      ) {
        const propertyParentId = replaceApiIri(parentIri, 'properties');

        try {
          const { data: updatedProperty } = await queryFulfilled;
          dispatch(
            propertiesApi.util.updateQueryData(
              'getProperty',
              String(propertyId),
              () => updatedProperty,
            ),
          );

          dispatch(
            propertiesApi.util.updateQueryData(
              'getProperty',
              String(propertyParentId),
              (draft) => {
                if (draft.children) {
                  const childIndex = draft.children.findIndex(
                    (childIri) => childIri === updatedProperty['@id'],
                  );

                  if (childIndex !== -1) {
                    draft.children.splice(childIndex, 1);
                  }
                }
              },
            ),
          );

          const propertyParentCache = await dispatch(
            propertiesApi.endpoints.getProperty.initiate(propertyParentId),
          );

          dispatch(
            propertiesApi.util.updateQueryData('getProperties', {}, (draft) => {
              const propertyIndex = draft?.findIndex(
                (property) => property.id === updatedProperty.id,
              );
              const propertyParentIndex = draft?.findIndex(
                (property) => property['@id'] === parentIri,
              );

              if (propertyIndex !== -1) {
                draft?.splice(propertyIndex, 1, updatedProperty);
              }

              if (propertyParentIndex !== -1 && propertyParentCache.data) {
                draft?.splice(propertyParentIndex, 1, propertyParentCache.data);
              }
            }),
          );
        } catch (error) {
          if ('data' in (error as CustomError)) {
            dispatch(
              setErrorMessage((error as CustomError).data['hydra:description']),
            );
          }
          dispatch(setErrorStatus(true));
        }
      },
    }),
    getPropertyTypes: builder.query<string[], void>({
      query: () => '/properties/types',
      transformResponse: (response: PropertyTypesResponseDto) =>
        response['hydra:member'].sort(),
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useGetPropertyQuery,
  useAddPropertyMutation,
  useUpdatePropertyMutation,
  useAddParentToPropertyMutation,
  useDeleteParentFromPropertyMutation,
  useGetPropertyTypesQuery,
} = propertiesApi;
