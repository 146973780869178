import React from 'react';
import useGetSelectedEmployeePreview from 'hooks/useGetSelectedEmployeePreview';
import { Avatar, Box, TableCell, Typography } from '@mui/material';

interface EmployeeNameProps {
  employeeIri: string;
}

function EmployeeName({ employeeIri }: EmployeeNameProps) {
  const { selectedEmployee } = useGetSelectedEmployeePreview({ employeeIri });

  return (
    <TableCell>
      <Box display="flex" alignItems="center">
        <Avatar src={selectedEmployee?.avatarUrl} />
        <Typography variant="body2" ml={2}>
          {selectedEmployee?.firstname} {selectedEmployee?.lastname}
        </Typography>
      </Box>
    </TableCell>
  );
}

export default EmployeeName;
