import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useValidate } from 'hooks/useValidate';
import { useTranslation } from 'react-i18next';
import { useGetCurrenciesQuery } from 'store/currencies/currencies';
import {
  BankAccount,
  useGetAccountsQuery,
  useUpdateAccountMutation,
} from 'store/accounts/accounts';
import { StatusProps } from 'hoc/Status/Status';
import { Add } from '@mui/icons-material';
import DialogHeader from 'common/DialogHeader/DialogHeader';
import Error from 'common/Error';
import Loader from 'common/Loader';
import { CustomError } from 'store/api';
import ImportFormats from 'components/AddAccountForm/enums';
import { LoadingButton } from '@mui/lab';

export interface Errors {
  accountname: boolean;
  prefix: boolean;
  type: boolean;
  isDefault: boolean;
  accountnumber: boolean;
  currency: boolean;
}

export interface EditAccountFormProps extends StatusProps {
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accountId: string;
}

const emptyAccount = {} as BankAccount;

export default function EditAccountForm({
  setIsSuccess,
  setIsError,
  setErrorMessage,
  setIsEditOpen,
  accountId,
}: EditAccountFormProps) {
  const { account } = useGetAccountsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      account: data?.find((item) => item.id === accountId) ?? emptyAccount,
    }),
  });
  const [accountName, setAccountName] = useState(account?.name ?? '');
  const [type, setType] = useState(account?.type ?? '');
  const [isDefault, setIsDefault] = useState(account?.default ?? false);
  const [accountNumber, setAccountNumber] = useState(account?.number ?? '');
  const [prefix, setPrefix] = useState(account?.prefix ?? '');
  const [currencyIri, setCurrencyIri] = useState(account?.currency ?? '');
  const { errors, validate } = useValidate<Errors>();
  const [editAccount, { isLoading }] = useUpdateAccountMutation();
  const {
    data: currencies = [],
    isLoading: currenciesFetching,
    isError: currenciesFetchError,
  } = useGetCurrenciesQuery();
  const { t } = useTranslation();

  const disableSubmission = () =>
    Object.values(errors).some((error) => error) ||
    !accountName ||
    !type ||
    prefix.length < 2 ||
    accountNumber.length < 26 ||
    !currencyIri;

  const handleEditAccount = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await editAccount({
        bank: account?.bank['@id'],
        type,
        default: isDefault,
        name: accountName,
        number: accountNumber,
        prefix,
        currency: currencyIri,
        defaultImportFormat: ImportFormats.mt940,
        id: account?.id,
      }).unwrap();
      setIsSuccess(true);
      setIsEditOpen(false);
    } catch (error) {
      if ('data' in (error as CustomError)) {
        setErrorMessage((error as CustomError).data.detail);
      }
      setIsError(true);
    }
  };

  const handleSetCurrency = () => {
    if (!prefix) {
      return;
    }
    const prefixPattern = new RegExp(prefix, 'i');
    setCurrencyIri(
      currencies
        .map((currencyData) =>
          prefixPattern.test(currencyData.id) ? currencyData.id : null,
        )
        .filter((item) => item)[0] || '',
    );
  };

  if (currenciesFetching) {
    return <Loader />;
  }

  if (currenciesFetchError) {
    return <Error />;
  }

  return (
    <>
      <DialogHeader
        title={t('bank_accounts.edit_account')}
        setIsOpen={setIsEditOpen}
      />
      <DialogContent>
        <form onSubmit={handleEditAccount}>
          <Box
            pt={3}
            display="flex"
            flexDirection="column"
            gap={3}
            flexWrap="wrap"
          >
            <TextField
              fullWidth
              error={errors.accountname}
              helperText={
                errors.accountname && t('errors.too_short_account_name')
              }
              name="accountname"
              variant="outlined"
              label={t('bank_accounts.account_name_label')}
              id="accountname"
              value={accountName}
              onChange={(event) => setAccountName(event.target.value)}
              onBlur={(event) =>
                validate('accountname', event.target.value.length >= 2)
              }
            />
            <Box display="flex" columnGap={3} width="100%">
              <TextField
                error={errors.prefix}
                helperText={errors.prefix && t('errors.too_short_prefix')}
                name="prefix"
                variant="outlined"
                label={t('bank_accounts.prefix_label')}
                id="prefix"
                value={prefix}
                onChange={(event) => setPrefix(event.target.value)}
                onBlur={(event) => {
                  validate('prefix', event.target.value.length >= 2);
                  handleSetCurrency();
                }}
                sx={{ width: '30%' }}
              />
              <TextField
                fullWidth
                error={errors.accountnumber}
                helperText={
                  errors.accountnumber && t('errors.too_short_account_number')
                }
                name="accountnumber"
                variant="outlined"
                label={t('bank_accounts.account_number_label')}
                id="accountnumber"
                value={accountNumber}
                onChange={(event) => setAccountNumber(event.target.value)}
                onBlur={(event) =>
                  validate('accountnumber', event.target.value.length >= 2)
                }
              />
            </Box>
            <Box display="flex" columnGap={3}>
              <FormControl sx={{ width: '30%' }}>
                <InputLabel id="currency-label">
                  {t('bank_accounts.currency_label')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="currency-label"
                  id="currency"
                  value={currencyIri}
                  label={t('bank_accounts.currency_label')}
                  onChange={(event) => setCurrencyIri(event.target.value)}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency.id} value={currency['@id']}>
                      {currency.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="account-type-label">
                  {t('label.type')}
                </InputLabel>
                <Select
                  labelId="account-type-label"
                  id="account-type"
                  value={type}
                  label={t('label.type')}
                  onChange={(event) => setType(event.target.value)}
                >
                  <MenuItem value="regular">
                    {t('bank_accounts.account_type_option_1')}
                  </MenuItem>
                  <MenuItem value="vat">
                    {t('bank_accounts.account_type_option_2')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDefault}
                    onChange={() => setIsDefault((prevState) => !prevState)}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '2.5rem',
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    {t('bank_accounts.is_default_label')}
                  </Typography>
                }
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              disabled={disableSubmission()}
              loading={isLoading}
              endIcon={<Add />}
              type="submit"
              variant="contained"
            >
              <span>{t('button.save')}</span>
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </>
  );
}
