import { VatRates } from 'enums';

const getGrossWorth = (netPrice: number, quantity: number, vat: string) => {
  if (vat === VatRates.zw || vat === VatRates.np) {
    return (netPrice * quantity).toFixed(4);
  }
  return (netPrice * quantity * (1 + +vat / 100)).toFixed(4);
};

export default getGrossWorth;
