import { anchorType } from 'react-xarrows';

interface NodeArrow {
  start: string;
  end: string;
  startAnchor: anchorType;
  endAnchor: anchorType;
}

const distanceBetweenNodes = 46;

const nodeArrows: NodeArrow[] = [
  {
    start: 'holidays',
    end: 'employees',
    startAnchor: 'auto',
    endAnchor: 'auto',
  },
  { start: 'logo', end: 'employees', startAnchor: 'auto', endAnchor: 'auto' },
  {
    start: 'logo',
    end: 'projects',
    startAnchor: { position: 'right', offset: { y: -distanceBetweenNodes } },
    endAnchor: 'left',
  },
  { start: 'logo', end: 'hr', startAnchor: 'auto', endAnchor: 'auto' },
  { start: 'logo', end: 'costs', startAnchor: 'auto', endAnchor: 'auto' },
  {
    start: 'logo',
    end: 'bookings',
    startAnchor: { position: 'left', offset: { y: distanceBetweenNodes } },
    endAnchor: 'right',
  },
  { start: 'logo', end: 'budget', startAnchor: 'auto', endAnchor: 'auto' },
  {
    start: 'logo',
    end: 'incomes',
    startAnchor: { position: 'right', offset: { y: distanceBetweenNodes } },
    endAnchor: 'left',
  },
];

export default nodeArrows;
